<div class="content-wrapper">
    <section class="content">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">groups</i></div>
        <h3 class="box-title main-header-top">Payment List</h3>
      </div>
      <div class="box-body">
        <div class="box bottom-height">
          
          <div class="box-body">
            <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal"
            data-placement="bottom"  (click)="changeToReset()" data-target="#myModal"><!--<i
              class="fa fa-external-link-square" aria-hidden="true"></i>--> Add New Payment</button>
                  
              <!-- <div class="form-group">
                  <label>Select Client</label>
                  <select class="form-control"  (change)="onSelectClient($event.target.value)" >
                  <option [value]="">--Select--</option>
                  <option *ngFor="let data of clientList" [value]="data.userId">{{data.clientShowName}}</option> 
                  </select>
              </div> -->
              <!-- <div class="form-group">
                <label>Select Case</label>
                <select class="form-control" (change)="onSelectCase($event.target.value)">
                <option [value]="">--Select--</option>
                <option *ngFor="let data of case" [value]="data.caseNo" >{{data.caseTitle}}</option> 
                </select>
            </div> -->
        
            <div class="row" *ngIf="teamsetupPermission!='view'" style="margin-bottom: 23px;">
              
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 rolebox1">
                <div class="row form-group">
                  <div class="col-md-6">
                <input type="text" class="form-control" style="margin-right: 1%;" [(ngModel)]="fromDate" id="fromDate" placeholder="Enter From Date" onfocus="(this.type='date')"/>
                </div>
                <div class="col-md-6">
                <input type="text" class="form-control" style="margin-right: 1%;" [(ngModel)]="toDate" (change)="checkDD()" id="toDate" onfocus="(this.type='date')" placeholder="Enter To Date"/>
                </div>
                </div>
                <div class="row form-goup">
                  <div class="col-md-12">
                <button type="button" class="btn btn-primary" style="margin-right: 1%;" (click)='searchInvoice()'>Search</button>
                <button type="button" class="btn btn-primary" style="margin-right: 1%;" (click)='resetSearch()' >Reset</button>
                </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">

                <!-- <a href="/invoicegeneration" class="btn btn-primary pull-right Role"> 
                  <span> &nbsp;Generate New Invoice</span> </a> -->
                <!-- <button type="button" class="btn btn-primary pull-right Role" href="/invitemember">+ ADD</button> -->
                <!-- <button type="button" class="btn btn-primary pull-right Role" (click)='checkUserCount(2)' style="margin-right: 1%;" >Invite team partner</button> -->
                <!-- <a href="/invitemember" class="btn btn-primary pull-right Role" style="margin-right: 1%;"> 
                  <span> &nbsp;Invite team partner</span> </a> -->
                
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive ">
                  <!-- <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>Payment Receipt No.</th>
                        <th>Invoice No.</th>
                        <th>Case No.</th>
                      
                        <th>Paid Amount(£)</th>
                       
                        <th>Paid On</th>
                      
                      </tr>
                    </thead>
                    <tbody> -->
                      <ul *ngFor="let item of invoicePaymentListList" class="team-view" >
                        <li><i class="fa-solid fa-money-bill"></i> Payment Receipt No. : {{item.invoicePaymentNo}} </li>
                        <li><i class="fa-solid fa-file-invoice"></i> Invoice No. :  {{item.invoiceId}} </li>
                        <li><i class="fa-solid fa-file-lines"></i> Case No. : {{item.caseNo}}</li>
                        <!-- <td> {{item.amount}} </td> -->
                        <li><i class="fa-solid fa-money-bill"></i> Paid Amount(£) : {{item.paidAmount}} </li>
                        <!-- <td> {{item.remAmount}} </td> -->
                        <li><i class="fa-solid fa-calendar"></i> Paid On : {{item.createdAt  |  date:'dd-MM-yyyy,hh:mm:ss aa'}} </li>
                        <!-- <td>
                          <button type="button" class="btn btn-success" (click)="generatePaymentInvoice(item.invoicePaymentId)"><i class="fa fa-download" aria-hidden="true" *ngIf="saveMode" ></i></button>
                        </td> -->
                        <!-- <td> {{item.status}} </td> -->
                      </ul>
                    <!-- </tbody>
                  </table> -->
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="button" class="btn btn-grey">Back</button>
              </div>
            </div> -->
  
          </div>
        </div>
      </div>
  
  
  
    </section>
  </div>
  <!-- Modal -->
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)='modalclose()'>&times;</button>
          <h4 class="modal-title">New Payment Entry</h4>
        </div>
        <form [formGroup]="myform">
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Client Name<span class="text-danger">*</span></label>
                  <!-- <input type="text" formControlName="clientName" readonly> -->
                  <select class="form-control" (change)="onSelectClient($event.target.value)" formControlName="clientName" required>
                    <option [value]="" disabled>--Select--</option>
                    <option *ngFor="let data of clientList" [value]="data.userId">{{data.clientShowName}}</option> 
                  </select>
                    <small class="text-danger"
                    *ngIf="myform.get('clientName').invalid && myform.get('clientName').touched">Please
                    choose client first.</small>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Case Name<span class="text-danger">*</span></label>
                  <select class="form-control" (change)="onSelectCase($event.target.value)" formControlName="caseTitle" required>
                    <option value="">--Select--</option>
                    <option *ngFor="let case of caseList " value={{case.caseNo}}>{{case.caseTitle}}</option>
                  </select>
                  <small class="text-danger"
                    *ngIf="myform.get('caseTitle').invalid && myform.get('caseTitle').touched">Please
                    choose case first.</small>
                </div>
              </div>  
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Invoice Number<span class="text-danger">*</span></label>
                  <select class="form-control" (change)="onSelectInvoice($event.target.value)" formControlName="invoiceId" required>
                    <option value="">--Select--</option>
                    <option *ngFor="let invoice of invoicePaymentListdd" value={{invoice.invoiceNo}}+{{invoice.dueAmount}}>{{invoice.invoiceNo}}</option>
                  </select>
                  <small class="text-danger"
                    *ngIf="myform.get('invoiceId').invalid && myform.get('invoiceId').touched">Please
                    choose case first.</small>
                </div>
              </div> 
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Due Amount(£) <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="rateView" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly> -->
                  <input type="text" class="form-control" formControlName="amount" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly required>
                  <small class="text-danger"
                    *ngIf="myform.get('amount').invalid && myform.get('amount').touched">Please
                    provide rate in number format only. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                    correct email.</small> -->
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Paid Amount(£) <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="rateView" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly> -->
                  <input type="text" class="form-control" formControlName="paidAmount" (change)="onEnterAmount($event.target.value)" placeholder="Enter ..."
                    pattern="^[0-9]+$" required>
                  <small class="text-danger"
                    *ngIf="myform.get('paidAmount').invalid && myform.get('paidAmount').touched">Please
                    provide rate in number format only. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                    correct email.</small> -->
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Remaining Amount(£) <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="rateView" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly> -->
                  <input type="text" class="form-control" formControlName="remAmount" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly required>
                  <small class="text-danger"
                    *ngIf="myform.get('remAmount').invalid && myform.get('remAmount').touched">Please
                    provide rate in number format only. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                    correct email.</small> -->
                </div>
              </div>
  
            </div>

            <div class="row">
  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Description <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="description" placeholder="Enter ..."
                    pattern="^[0-9a-zA-Z\s]+$" required>
                  <small class="text-danger"
                    *ngIf="myform.get('description').invalid && myform.get('description').touched">Please
                    provide description in alpha-numeric format only. </small>
                  </div>
                </div>
                <input type="hidden" class="form-control" formControlName="invoicePaymentListId" />
    
              </div>
            
            <div class="row">
              <div class="modal-footer">
                  <button type="submit" *ngIf="action == 'save'" ng class="btn btn-primary pull-left" (click)="invoicePaymentSave()">Submit</button>
                  <!-- <button type="submit" *ngIf="action == 'update'" ng class="btn btn-primary pull-left" (click)="invoiceListEdit()">Update</button> -->
                
                  <button type="button" class="btn btn-grey pull-right" data-dismiss="modal" (click)='modalclose()'>Close</button>
              </div>
              
            </div>
          </div>
        </form>
  
  
        <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">
  
          <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp;
            . </span>
          <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
  
  
        </div> -->
      </div>
  
    </div>
  </div>

  <!--Modal: Name-->
<div class="modal fade" id="Preview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body mb-0 p-0">

        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          
          <iframe #iframe class="embed-responsive-item" allowfullscreen></iframe>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">

        <button type="button" class="btn btn-default" data-dismiss="modal" >Generate final invoice and send to client.</button>

        <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Close</button>

      </div>

    </div>
    <!--/.Content-->

  </div>
</div>

  
  
  
  
  
   