<div class="content-wrapper">


  <div class="content">
    <div class="bottom-height">

      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">supervised_user_circle</i></div>
        <h3 class="box-title main-header-top">Client Management</h3>
      </div>

      <div class="box-body">
        <div class="box">
          <div class="box-body">
            <div class="row">
              <!-- <div class="col-xs-6 col-sm-10 col-md-10 col-lg-10 "> </div> -->

              <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <button type="button" class="btn btn-primary modalbox2 " data-toggle="modal"
            data-target="#myModa2">Invite New Client</button>
        </div> -->
        <div *ngIf="clientaddpermission!='view'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
          <a *ngIf="dataUser.usertype == 'managingpartner' || dataUser.usertype == 'advocate' ||  dataUser.usertype == 'seniorpartner' || dataUser.usertype == 'associatepartner'" routerLink="/clientprofile/" routerLinkActive="router-link-active" data-placement="bottom"
            title="Add New Client Offline">
            <button type="button" class="btn btn-primary pull-right Role"> <!--<i class="material-icons md-18 md-light">add</i>-->Add New Offline Client</button>
          </a>

          <button *ngIf = "dataUser.usertype == 'managingpartner' || dataUser.usertype == 'advocate' ||  dataUser.usertype == 'seniorpartner' || dataUser.usertype == 'associatepartner'" type="button" class="btn btn-primary pull-right Role" data-toggle="modal"
            data-placement="bottom" title="Invite New Client" (click)="changeToReset()" data-target="#myModa2"><!--<i
              class="fa fa-external-link-square" aria-hidden="true"></i>--> Add New Online Client</button>



        </div>

              <!-- <div *ngIf="clientaddpermission!='view'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                <a routerLink="/clientprofile/" routerLinkActive="router-link-active" data-placement="bottom"
                  title="Add New Client Offline">
                  <button type="button" class="btn btn-primary pull-right Role"> <i class="material-icons md-18 md-light">add</i></button>
                </a>

                <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal"
                  data-placement="bottom" title="Invite New Client" (click)="changeToReset()" data-target="#myModa2"><i
                    class="fa fa-external-link-square" aria-hidden="true"></i></button>



              </div> -->
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive table-striped">
                  <!-- <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Phone</th>
                        <th>Invitation Type</th>
                        <th align="center" width="150">Action</th>
                      </tr>
                    </thead>
                    <tbody> -->
                      <ul *ngFor="let item of userlist" class="team-view">
                        <li *ngIf="item.orgDesignation=='Individual Client'"><h4>{{item.firstName}} {{item.lastName}}</h4></li>
                        <li><i class="fa-solid fa-user"></i> Type : {{item.orgDesignation}} </li>                        
                        <li *ngIf="item.orgDesignation=='Corporate Client'"><i class="fa-solid fa-building"></i>  {{item.corporateClientCompanyName}}</li>
                        <li><i class="fa-solid fa-at"></i> Email :  {{item.emailId}} </li>
                        <li><i class="fa-solid fa-phone"></i> Phone No. : {{item.phoneNo}} </li>
                        <li><i class="fa-solid fa-envelope-circle-check"></i> Invitation Type : {{item.clientinvitationType}} </li>

                        <li class="act-btn">
                          <div>
                            <!-- <a [routerLink]="['/clientprofile',item.userId,item.userType,'view']" routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom" title="view">
                      <button type="button" class="btn btn-primary"><i class="fa fa-eye"
                          aria-hidden="true"></i></button>
                      </a> -->
                            <a [routerLink]="['/clientprofile',item.userId,item.userType]"  *ngIf="clientaddpermission!='view'"
                              routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom"
                              title="edit">
                              <button type="button" class="btn btn-info btn-success"><i
                                  class="material-icons md-14 md-dark ">edit</i></button>
                            </a>
                            <!-- <button type="button" class="btn btn-danger"><i class="fa fa-trash-o"
                          aria-hidden="true" (click)="deleteCorfirmation(item.userId)" data-toggle="modal" data-target="#saveIntent"></i></button> -->
                            <a [routerLink]="['/caselist',item.userId]" routerLinkActive="router-link-active"
                              data-toggle="tooltip" data-placement="bottom" title="Case List">
                              <button type="button" class="btn btn-info pull-right Role"><i  class="material-icons md-14 md-dark">list_alt</i></button>
                            </a>
                            <a [routerLink]="['/contractlist',item.userId]" routerLinkActive="router-link-active"
                              data-toggle="tooltip" data-placement="bottom" title="Contract List ">
                              <button type="button" class="btn btn-info pull-right Role"><i  class="material-icons md-14 md-dark">reorder</i></button>
                            </a>
                            <a *ngIf="item.orgDesignation=='Corporate Client'" (click)="goFeeAgreementList(item.corporateClientCompanyName,null)" [routerLink]="['/agreementlist',item.userId]" routerLinkActive="router-link-active"
                              data-toggle="tooltip" data-placement="bottom" title="Fee Agreement list">
                              <button type="button" class="btn btn-info pull-right Role"><i
                                  class="material-icons md-14 md-dark ">list</i></button>
                            </a>
                            <a *ngIf="item.orgDesignation=='Individual Client'" (click)="goFeeAgreementList(item.firstName,item.lastName)" [routerLink]="['/agreementlist',item.userId]" routerLinkActive="router-link-active"
                              data-toggle="tooltip" data-placement="bottom" title="Fee Agreement list">
                              <button type="button" class="btn btn-info pull-right Role"><i
                                  class="material-icons md-14 md-dark ">list</i></button>
                            </a>
                          </div>
                        </li>
                      </ul>
                    <!-- </tbody>
                  </table> -->
                </div>
              </div>
            </div>
            <!-- <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
          <button class="btn btn-primary pull-left Back1"> Back </button>
      </a>
      </div>

     </div> -->

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- Modal start -->
  <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm to deactivate</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure to deactivate?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="deleteData()">Yes</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal end -->
</div>
<!-- Modal -->






<div id="myModa2" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content Modalbox1">

      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="modalclose()">&times;</button>
        <h4 class="modal-title">Invite new client </h4>
      </div>

      <div class="modal-body">
        <form [formGroup]="inviteNewClient">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Title </label>
                <select class="form-control" formControlName="title" placeholder="Title">
                  <option value="" disabled>--Select--</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
                <small class="text-danger"
                  *ngIf="inviteNewClient.controls.title.hasError('required') && ( inviteNewClient.controls.title.dirty || inviteNewClient.controls.title.touched || (!inviteNewClient.controls.title.valid))">This
                  field is required.</small>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>First Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="firstname" placeholder="First Name"
                  pattern="^[a-zA-Z\s]+$">
                <small class="text-danger"
                  *ngIf="inviteNewClient.get('firstname').invalid && inviteNewClient.get('firstname').touched">Please provide First
                  name in text format only. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewClient.get('firstname').errors?.pattern">Please provide only
                  text for first name.</small> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Middle Name </label>
                <input type="text" class="form-control" formControlName="middlename" placeholder="Middle Name"
                  pattern="^[a-zA-Z\s]+$">
                <small class="text-danger" *ngIf="inviteNewClient.get('middlename').errors?.pattern">Please provide only
                  text for middle name.</small>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Last Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="surname" placeholder="Surname"
                  pattern="^[a-zA-Z\s]+$">
                <small class="text-danger"
                  *ngIf="inviteNewClient.get('surname').invalid && inviteNewClient.get('surname').touched">Please provide Surname in text format only. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewClient.get('surname').errors?.pattern">Please provide only
                  text for surname.</small> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <input type="email" class="form-control" formControlName="mailid" placeholder="Email"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                <small class="text-danger"
                  *ngIf="inviteNewClient.get('mailid').invalid && inviteNewClient.get('mailid').touched">Please provide correct email. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewClient.get('mailid').errors?.pattern">Please provide correct
                  email.</small> -->
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Mobile Number <span class="text-danger">*</span></label>
                
                  <!-- <div style="display: flex">
                    <app-country-code-dropdown
                      [countryCode]="countryCodeMobilePh"
                      (onCountryChange)="onCountryCodeMobilePhChange($event)"
                      [withBorder]="true"
                    >
                    </app-country-code-dropdown>
                    <input type="tel" class="form-control  pull-right " formControlName="phoneno" placeholder="Phone"
                    pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$">
                  </div> -->
                  <div style="display: flex">
                  <ngx-intl-tel-input 
                  [cssClass]="'custom'" 
                  style="margin-left: -2%"
                  placeholder="Enter a valid phone number. "
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="false" 
                  [enablePlaceholder]="true" 
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="true" 
                  [selectedCountryISO]="selectedCountry"
                  [maxLength]="15" 
                  [tooltipField]="TooltipLabel.Name" 
                  [phoneValidation]="true" 
                  [separateDialCode]="true"
                  name="phone" formControlName="phoneno" required>
              </ngx-intl-tel-input>
            </div>
                  <small class="text-danger"
                    *ngIf="inviteNewClient.get('phoneno').invalid && inviteNewClient.get('phoneno').touched">Please provide
                    correct mobile number. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewClient.get('phoneno').errors?.pattern">Please provide
                    correct phone number.</small> -->
                  
                
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="userType=='corporateclient'">
              <div class="form-group">
                <label>Company Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="companyname" placeholder="Company Name"
                  required>
                <small class="text-danger"
                  *ngIf="inviteNewClient.get('companyname').invalid && inviteNewClient.get('companyname').touched">Please provide
                  company name. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewClient.get('companyname').errors?.pattern">Please provide
                  only text for company name.</small> -->
              </div>
            </div>

          </div>
            

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="form-group">
                <label><b>Client Type:</b> </label>
                <span class="radio check1" *ngFor="let userT of userTypeArr">
                  <label><input type="radio" style="width: unset;" [checked]="userType===userT[1]" name="userType"
                      (change)="chageuser(userT[1])">&nbsp;{{userT[0]}} </label>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="modal-footer">
              <button type="submit" (click)="registration()" class="btn btn-primary pull-left">Submit</button>
              <button type="button" class="btn btn-grey pull-right" (click)="modalclose()" data-dismiss="modal">Close</button>

            </div>
          </div>

          <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group">
              <button type="submit" (click)="registration()" class="btn btn-primary">Submit</button>
            </div>
          </div>-->

        </form>




      </div>


      <!--<div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button>
      </div>-->

      <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">

        <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
        <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>


      </div> -->

    </div>

  </div>
</div>