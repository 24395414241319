<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon"><i class="fa fa-server" aria-hidden="true"></i></div>
          <h3 class="box-title">Serves</h3>
        </div>

        <div class="box-body">
            <div class="breadcrumbbox">
                <ol class="breadcrumb">
                  <li><a routerLink="/clientcasedetails"><i class="fa fa-asterisk" aria-hidden="true"></i> Client Case Details </a></li>
                  <li><a routerLink="/feeagreement"><i class="fa fa-files-o" aria-hidden="true"></i> Free Agreement</a></li>
                  <li class="active"><a routerLink="/serves"><i class="fa fa-server" aria-hidden="true"></i> Serves</a></li>
                </ol>
            </div>
          </div>

        <div class="box-body">
          <div class="box">
            <div class="box-body">
              
			    <div class="table-responsive">          
                    <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th width="390">COURTS/<br>TRIBUNALS </th>
                            <th width="120">DRAFTING <br> (INR)</th>
                            <th width="120">FILING <br> (INR)</th>
                            <th width="130" >APPEARANCE <br> (INR)</th>
                            <th width="120">text</th>
                            <th width="120">text</th>
                            <th width="120">text</th>

                            
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td><input type="checkbox"></td>
                            <td>SUPREME COURT OF INDIA (SCI)</td>
                            <td>50,000/-</td>
                            <td>50,000/-</td>
                            <td>50,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >HIGH COURT AT CALCUTTA</td>
                            <td >30,000/-</td>
                            <td >15,000/-</td>
                            <td >10,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >OTHER HIGH COURTS</td>
                            <td >20,000/-</td>
                            <td >22,000/-</td>
                            <td >35,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >DISTRICT COURTS (Barasat, Alipore, City Civil Court)</td>
                            <td >20,000/-</td>
                            <td >17,000/-</td>
                            <td >15,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >ANY OTHER DISTRICT COURTS</td>
                            <td >20,000/-</td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >REVENUE TRIBUNAL</td>
                            <td >20,000/-</td>
                            <td >20,000/-</td>
                            <td >15,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >TRIBUNALS WITHIN KOLKATA</td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td >15,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >APPELLATE TRIBUNALS WITHIN KOLKATA </td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td >15,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >TRIBUNAL OUTSIDE KOLKATA</td>
                            <td >20,000/-</td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >APPELLATE  TRIBUNALS OUTSIDE KOLKATA</td>
                            <td >20,000/-</td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >TRIBUNALS OUTSIDE </td>
                            <td >20,000/-</td>
                            <td >30,000/-</td>
                            <td >25,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >ARBITRATION</td>
                            <td >20,000/-</td>
                            <td >18,000/-</td>
                            <td >18,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>

                        </tr>
                        <tr>
                            <td><input type="checkbox"></td>
                            <td >ARBITRATION OUTSTATION</td>
                            <td >20,000/-</td>
                            <td >25,000/-</td>
                            <td >25,000/-</td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td >MONTHLY RETAINERSHIP (includes Legal and    Transactional Advisory ,<br>
                                 Legal Governance and vetting of third party    contracts)</td>
                            <td colspan="3">&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td >Contract Drafting (Standard)</td>
                            <td colspan="3">&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td >Contract Drafting (Transaction Specific)</td>
                            <td colspan="3">&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </table>
            </div>
		
				
				
				
            </div>
            <div class="box-footer">
              <button class="btn btn-primary pull-left"> Back </button>
              <button type="submit" class="btn btn-primary pull-right">Submit</button>
            </div>
          </div>
        </div>        
      </div>
    </div>
</div>


