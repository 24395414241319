import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { Http, Headers } from '@angular/http';

import { CaseService } from '../service/case.service';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Subject } from 'rxjs';

declare var $: any;
declare var jQuery: any;

import { HttpClient, HttpResponse } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';

import { ToastrService } from 'ngx-toastr';

import { LegalbellService } from '../service/legalbell.service'



@Component({
  selector: 'app-casecreation',
  templateUrl: './casecreation.component.html',
  styleUrls: ['./casecreation.component.css']
})
export class CasecreationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  assignTeamForm: FormGroup;
  //partyForm: FormGroup;
  //TeamForm: FormArray;
  userlist=[];

  assignTeamEditForm: FormGroup;
  TeamEditForm: FormArray;
  clientUserId: string;

  //empForm: FormGroup;



  insChatForm: FormGroup;
  caseForm: FormGroup;
  newHearingForm: FormGroup;
  caseDetailForm: FormGroup;
  hearingDtlVo: FormArray;
  //partyDtlVo: FormArray ;
  submittedForm: boolean;
  subInsChatForm: boolean = false;
  subAssignTeamForm: boolean = false;
  modalDisplay: boolean = false;
  insFormModal: boolean = false;
  stages: any = [];
  statelist: any = [];
  districtlist: any = [];
  courtlist: any = [];
  casedetailedit: boolean;
  stateId: string;
  memberNameList: any = [];
  districtid : string;
  nextDate : string;
  caseCourtUpdated: string;
  loader : boolean = false;
  caseTitleUpdated: string;
  caseDescUpdated: string;
  caseTypeUpdated: string;
  courtCaseNoUpdated : string;
  superUserId : string;
  loaderEditCase:boolean= false;
  loginUserType : string;
  insChatFormSubmit:boolean = false;
  uploadedDocumentModelName:string;
  typeOfDocumentList:string;
  modelDocumentList:any = [];
  subNewHearingForm:boolean = false
  editNewHearingForm:boolean = false
  newHearingOrderDocValue:any
  newHearingOrderDocName:string
  newHearingId:string
  deleteHiringDetails :any= {}
  createdByUser : any;

  openDate : string;
  pendingDate : string;
  closedDate : string;
  openDateUpdated : string;
  pendingDateUpdated : string;
  closedDateUpdated : string;

  responsibleAttorney : string;
  originatingAttorney : string;
  status : 'Open';
  permission : string;
  location : string;
  responsibleAttorneyUpdated : string;
  originatingAttorneyUpdated : string;
  statusUpdated : 'Open';
  permissionUpdated : string;
  locationUpdated : string;
  otherRefNo : string;
  otherRefNoUpdated : string;
  refferedBy: string;
  refferedByUpdated: string;


  notificationCount:string;
  caseFolderPermission :any = [{upload:false, view:false, delete: false},{upload:false, view:false, delete: false}];

 

  clientName: any = [
  ];

  instructionToList : any = [];

  caseType: any = [
    { 'caseTypeId': '1', 'caseType': 'Case1' },
    { 'caseTypeId': '2', 'caseType': 'Case2' },
    { 'caseTypeId': '3', 'caseType': 'Case3' },
    { 'caseTypeId': '4', 'caseType': 'Case4' }
  ];

  insMsgChatType: any = [
    { 'msgTypeId': 'query', 'caseType': 'Query' },
    { 'msgTypeId': 'reply', 'caseType': 'Reply' },
    { 'msgTypeId': 'instruction', 'caseType': 'Instruction' }
  ];

  memberName: any = [
    // { 'clientUserId': '1', 'clientName': 'User1' },
    // { 'clientUserId': '2', 'clientName': 'User2' }
  ];

  memberUser: any = [
    // { 'userId': '1', 'userName': 'User1' },
    // { 'userId': '2', 'userName': 'User2' }
  ];


  fileToUpload: File = null;
  caseNo: any;
  userId: any;
  docType: any;
  displayCaseDocument = 'none';

  showMsg: boolean = false;
  hearingDtlSubmit : boolean = false;
  mes = '';
  mes1 = '';
  mes2 = '';
  mes3 = '';

  insChatId: any;

  url: any;
  btnSppiner: boolean = false;
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;
  disabled: boolean = false;
  caseDocList: any = [];
  caseHearingList: any = [];
  conversationList: any = [];
  memberList: any = [];
  assignTeamList: any = [];
  hearingList : any = []
  newHearingList : any = []
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  teamDtOptions: DataTables.Settings = {};
  teamTrigger: Subject<any> = new Subject();

  memberUserfn: any;
  memberUserln: any;
  memberUserType: any;


  @ViewChild('variable')
  variable: ElementRef;

  constructor(private fb: FormBuilder,
    private CaseService: CaseService,
    private router: Router,
    private toastr: ToastrService,dateTimeAdapter: DateTimeAdapter<any>,
    private legalbellService: LegalbellService,
    private route: ActivatedRoute, private http: HttpClient) { dateTimeAdapter.setLocale('en-IN');}

  ngOnInit(): void {
    this.loaderEditCase = true;
    //this.caseNo="";
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip',
    };
    this.teamDtOptions = {
      ordering: false,
      searching: false,

      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip'
    };

    localStorage.setItem("dtTrigger", "dtTrigger");
    localStorage.setItem("teamTrigger", "teamTrigger");

    if (localStorage.getItem('refresh')) {
      localStorage.removeItem("refresh");
      //location.reload();
    };


    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }


    this.loginUserType = localStorage.getItem('profileInfo') ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;

    this.load();

    console.log("Route", this.router);
    // this.router.navigate(['/dashboard']);

  }

  ngAfterViewInit(): void {this.dtTrigger.next();}
  //ng on init end
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    localStorage.removeItem('currentCaseNo');
    this.dtTrigger.unsubscribe();
    this.teamTrigger.unsubscribe();
  }

  


  //load start
  async load() {

    this.getUserData();
    /* this.getStageofCase();
    this.getStateList(); */
    this.url = this.router.url;
    const caseView = this.url.search("case-view");
    const caseEdit = this.url.search("case-edit");
    const casecreation = this.url.search("casecreation");

    if (caseView == 1) {
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }

    if (caseEdit == 1) {
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }

    if (casecreation == 1) {
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
    }

    // console.log(this.url, this.viewMode, this.editMode, this.createMode);
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.userId = userData.userIdlogin;
    this.caseForm = this.fb.group({
      userId: new FormControl('',[Validators.required]),
      caseNo: new FormControl(this.caseNo),
      otherRefNo: new FormControl(''),
      refferedBy: new FormControl(''),
      caseTitle: new FormControl('', [Validators.required]),
      caseType: new FormControl('', [Validators.required]),
      caseDesc: new FormControl('', [Validators.required]),
      courtCaseNo: new FormControl(''),
      superUserId: new FormControl(this.superUserId),
      createdBy: new FormControl(this.userId),
      updatedBy: new FormControl(this.userId),
      openDate: new FormControl(''),
      pendingDate: new FormControl(''),
      closedDate: new FormControl(''),
      responsibleAttorney: new FormControl(''),
      originatingAttorney: new FormControl(''),
      status: new FormControl('Open'),
      permission: new FormControl(''),
      location: new FormControl(''),
      // caseCourt: new FormControl( ''),
      // nextDate: new FormControl( ''),
      // stageOfCase: new FormControl( ''),
      // statussOfCase: new FormControl( ''),
      //hearingDtlVo: new FormArray( [] ),
    });

    this.caseDetailForm = this.fb.group({
      caseNo: new FormControl(this.caseNo),
      stateId: new FormControl(''),
      districtId: new FormControl(''),
      caseCourt: new FormControl(''),
      nextDate: new FormControl(''),
      stageOfCase: new FormControl(''),
      statussOfCase: new FormControl(''),
      hearingDtlVo: new FormArray([])

    });
    this.insChatForm = this.fb.group({
      toUser: new FormControl('', [Validators.required]),
      msgType: new FormControl('', [Validators.required]),
      msgContent: new FormControl('', [Validators.required])
    });

    this.newHearingForm = this.fb.group({
      hearingJudje: new FormControl('', [Validators.required]),
      hearingDate: new FormControl('', [Validators.required])
     
    });
    

    // this form is used to submit the data first time
    this.assignTeamForm = this.fb.group({
      TeamForms: this.fb.array([])
    })
    
    // this form is used to submit the data after edit
    this.assignTeamEditForm = this.fb.group({
      TeamEditForms: this.fb.array([]),
    })

    // this.partyForm = this.fb.group({
    //   partyDtlVo: this.fb.array([]),
    // })

    if (!this.viewMode) { this.t.push(this.newSkill()); }


    if (this.viewMode == true || this.editMode == true) {
      this.hearingDtlSubmit = true;
      this.displayCaseDocument = 'block';

      this.route.params.subscribe(params => {
        this.clientUserId = params['userId'];
        this.caseNo = params['caseNo'];
      });
      // this.caseForm.patchValue( { 'userId': this.userId } );

      await this.viewCaseDetails(this.caseNo);
      //await this.viewPartyDetails(this.caseNo);
      await this.memberPermission()

    }

    this.TeamForms().push(this.newTeams());
    //this.partyDtlVo().push(this.newParty());
    // this.teamPermissions( 0 ).push( this.newPermission() );
    // this.teamPermissions( 0 ).push( this.newPermission() );
    this.prmissionVal(0);
    //this.assignTeamList();

    // this.assignTeamEditList();


    if (this.createMode === true) {
      // $('.nav li').not('.active').addClass('disabled');
      // $('.nav li').not('.active').find('a').removeAttr("data-toggle");
      // $('.nav li').not('.active').find('a').removeAttr("href");

    } else {

      // $('.nav li').not('.active').addClass('disabled');
      // $('.nav li').not('.active').find('a').removeAttr("data-toggle");
      // $('.nav li').not('.active').find('a').removeAttr("href");


      // $(".nav-tabs a").click(function () {
      //   $(this).tab('show');
      // });

      // $('.nav-tabs a').on('shown.bs.tab', function (event) {
      //   var x = $(event.target).text(); // active tab
      //   localStorage.setItem('activeTab', x.toLowerCase());
      //   console.log(x)
      // });

      // var tabName = localStorage.getItem('activeTab');
      // if (tabName) {
      //   $('.nav-tabs a[href="#' + tabName + '"]').tab('show')
      // } else {
      //   $('.nav-tabs a:first').tab('show')
      // }
    }


    //######### Show The Msg after create case
    if(localStorage.getItem('caseCreateMsg')) {
        this.mes = localStorage.getItem('caseCreateMsg');
        localStorage.removeItem("caseCreateMsg");
    };

    //this.getTotalHiringList()

  }
  //load end

  openNextTab(tab) {
    if(tab == 'tab_1'||tab =='tab_2'||tab == 'tab_3'){
    $('.nav li.active').next('li').removeClass('disabled');
    $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");

    $('.nav li.active').next('li').find('a').attr("href", "#" + tab);
    }

    $( '.nav-tabs a[href="#'+tab+'"]' ).tab( 'show' );

  }

  // convenience getters for easy access to form fields
  get f() { return this.caseForm.controls; }
  get d() { return this.caseDetailForm.controls; }
  get t() { return this.d.hearingDtlVo as FormArray; }




  newSkill(): FormGroup {
    return this.fb.group({
      hearingId : '',
      hearingJudje: ['', Validators.required],
      hearingDate: ['', Validators.required],
      hearingOrder: '',
      orderDocId : '',
      orderDocName : ''
    })
  }

  addSkills() {
    this.t.push(this.newSkill());
  }

  // newParty(): FormGroup {
  //   return this.fb.group({
  //     partyType: ['', Validators.required],
  //     clientUserId: [this.userId, Validators.required],
  //     caseNo: [this.caseNo, Validators.required],
  //     id: ['', Validators.required],
  //     partyName: ['', Validators.required],
  //     partyEmailId: ['', Validators.required],
  //     partyPhoneNo: ['', Validators.required]
  //   })
  // }

  // addParty() {
  //   this.partyDtlVo().push(this.newParty());
  // }

  removeSkill(i: number,hearingId: string) {
    
    if(hearingId == '' || hearingId == null){
    this.t.removeAt(i);
    } else{
      console.log("hearingId in remove skill::",hearingId);
      //alert("are u sure to delete?")
      let formVal = {
        "userId": this.clientUserId,
        "caseNo": this.caseNo,
        "hearingId": hearingId
      };
  
      console.log('removeSkill', formVal);
  
      this.CaseService.removeHearing(JSON.stringify(formVal)).subscribe((data) => {
        console.log('postCaseData response', data);
        // alert( data.responseMessage );
        this.showMsg = true;
        this.mes = data.responseMessage;
        this.toastr.success(this.mes, '');
        
        this.mes1 = '';
        //this.viewCaseDetails(this.caseNo);
        setTimeout(()=>{
          location.reload();
        },2000);
  
      }, (err: any) => {
        console.log(err);
        this.showMsg = true;
        this.mes1 = 'Failed to delete Hearing.';
        this.toastr.error(this.mes1, '');
      }, () => {
        // this.modalDisplay = !this.modalDisplay;
      }
      );
    }
  }

  // removeParty(teamIndex: number) {
  //   this.partyDtlVo().removeAt(teamIndex);

  // }

  caseConfirmation() {
        this.submittedForm = true;

        //if ( this.caseForm.invalid && this.hearingDtlVo.invalid )
        if (this.caseForm.invalid) {
          this.modalDisplay = false;
          // console.log("modalDisplay:::", this.modalDisplay);
          return;
        } else {
          this.modalDisplay = true;
          // console.log("modalDisplay:::", this.modalDisplay);
        }

        // let hearingDate=this.t.value[this.t.length-1].hearingDate;
        // if ( hearingDate!='' ) {
        //   var nextDate=this.nextDateFormate( this.t.value[this.t.length-1].hearingDate );
        //   this.caseForm.patchValue( { 'nextDate': nextDate } );
        // }

        let formVal = this.caseForm.value;
        if (this.editMode) {
          formVal.caseNo = this.caseNo;
          this.loaderEditCase = true
          this.caseUpdate(formVal);
        } else {
          this.loaderEditCase = true
          this.caseCreate(formVal);
        }
  }

//this method is called for first part of case creation
  caseSubmit() {
        this.submittedForm = true;
        let formVal = this.caseForm.value;
        
        console.log('caseSubmit <<===>>', formVal);

        if (this.caseForm.invalid) {
          this.modalDisplay = false;
          return;
        } else {
          this.modalDisplay = true;
        }

        //this.userId=formVal.userId;


        console.log('casesubmit form value::', formVal);

        if (this.editMode) {
          formVal.caseNo = this.caseNo;
          this.caseUpdate(formVal);
        } else {
          this.caseCreate(formVal);
        }

  }
 //this is called by clicking save case details
  caseDetailSubmit() {
          this.submittedForm = true;
          let formVal = this.caseDetailForm.value;
          console.log('caseSubmit', formVal);
          this.loader = true
          // this.openNextTab('tab_2');
          // this.openNextTab('tab_3');

          // if ( this.caseDetailForm.invalid && this.hearingDtlVo.invalid ) {
          //   this.modalDisplay=false;
          //   return;
          // }


          formVal.userId = this.clientUserId;
          console.log("")
          formVal.nextDate = this.getFormattedDate(formVal.nextDate);
          /* formVal.hearingDtlVo = formVal.hearingDtlVo.map((p) => {
            p.hearingDate = this.getFormattedDate(p.hearingDate);
            return p;
          }); */
          formVal.hearingDtlVo =  this.newHearingList;
          formVal.stateId = this.stateId;
          formVal.districtId = this.districtid;
          formVal.caseCourt = formVal.caseCourt

          // This part start is for first part case cration
          formVal.caseNo = this.caseNo;
          formVal.caseTitle = this.caseTitleUpdated;
          formVal.caseType = this.caseTypeUpdated;
          formVal.caseDesc = this.caseDescUpdated;
          formVal.courtCaseNo = this.courtCaseNoUpdated;

          formVal.openDate = this.getFormattedDate(formVal.openDateUpdated);
          formVal.pendingDate = this.getFormattedDate(formVal.pendingDateUpdated);
          formVal.closedDate = this.getFormattedDate(formVal.closedDateUpdated);
          formVal.responsibleAttorney = this.responsibleAttorneyUpdated;
          formVal.originatingAttorney = this.originatingAttorneyUpdated;
          formVal.status = 'Open';
          formVal.permission = this.permissionUpdated;
          formVal.location = this.locationUpdated;
          // end for first part case cration
          console.log('after map', formVal);
          this.caseUpdate(formVal,1);

  }


  caseCreate(formVal) {

 

    this.CaseService.saveCaseData(JSON.stringify(formVal)).subscribe((data) => {
      console.log('saveCaseData response', data);
      this.loaderEditCase = true;
      if (data.responseMessage == 'success') {
        this.displayCaseDocument = 'block';
        this.caseNo = data.respData;
        localStorage.setItem('currentCaseNo', this.caseNo); // Set case number time of add case
        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Case Created successfully. For further details you can fill up sections below.';
        this.toastr.success(this.mes, '');
        
        localStorage.setItem("caseCreateMsg", "Case Created successfully. For further details you can fill up sections below.");
        // this.router.navigate(['/dashboard']);
        
        this.casedetailedit = true;
        this.createMode = false;
        this.editMode   = true;
        console.log("casedetailedit::", this.casedetailedit);
        this.viewCaseDetails(this.caseNo);
        this.getAllNotification(this.userId);

        setTimeout(()=>{
          this.router.navigate(['/case-edit', this.userId, this.caseNo]);
        },2000);

      }
    }, (err: any) => {
      console.log(err["status"]);
      if(err["status"]==406) {
        this.mes1 = 'Court Case Number already exist.';  
      }
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Case Creation failed.';
      this.loaderEditCase = false
      this.toastr.error(this.mes1, '');
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }
  caseUpdate(formVal, isDetails = 0) {
    this.CaseService.updateCaseData(JSON.stringify(formVal)).subscribe((data) => {
      this.loader = true;
      
      console.log('updateCaseData response', data);
      if (data.responseMessage == 'success') {
        this.displayCaseDocument = 'block';
        this.caseNo = data.respData;
        if(this.caseNo){
        // alert( 'Case updated successfully.' );
        }
        this.showMsg = true;
        if(isDetails == 1){
          this.mes2 = 'Case updated successfully.';
          console.log("1")
          this.toastr.success(this.mes2);
          this.loader = false;
          this.loaderEditCase = false;
        }else{  
          
          this.mes = 'Case updated successfully.';
          this.toastr.success(this.mes);
          this.loaderEditCase = false;
          this.loader = false;
        }
        
        
        this.mes3 = '';
        this.hearingDtlSubmit == true;
        
        // setTimeout(()=>{
        //   location.reload();
        // },2000);
        this.viewCaseDetails(this.caseNo, 'update');

        
        // $('.nav li.active').next('li').removeClass('disabled');
        // $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");
        // $('.nav li.active').next('li').find('a').attr("href", "#tab_3");
        // this.openNextTab('tab_3');

        // this.openNextTab('tab_2');
        // $('.assign_team_li').removeClass('disabled');
        // $('.assign_team_li').find('a').attr("data-toggle", "tab");
        // $('.assign_team_li').find('a').attr("href", "#tab_3");
      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes2 = '';
      this.mes3 = 'Case update failed.';
      this.loaderEditCase = false;
      this.loader = false;
      this.toastr.error(this.mes3);
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }
  // partyUpdate() {

  //   let formVal = this.partyForm.value;

  //   console.log('partyUpdate', formVal);

  //   console.log('partyDtlVo in partyUpdate', formVal.partyDtlVo);
  //   formVal.partyDtlVo = formVal.partyDtlVo.filter(function (obj) {
  //     return obj.field !== 'id';
  //   });
  //   let mergeData: any = [...formVal.partyDtlVo];
  //   console.log('mergeData', mergeData);

  //   this.CaseService.updatePartyData(JSON.stringify(mergeData)).subscribe((data) => {
  //     console.log('updateCaseData response', data);
  //     if (data.responseMessage == 'success') {
  //       this.displayCaseDocument = 'block';
  //       this.caseNo = data.respData;


  //       this.showMsg = true;
  //       this.mes = 'Case updated successfully.';
  //       this.mes1 = '';

  //     }
  //   }, (err: any) => {
  //     console.log(err);
  //     this.showMsg = true;
  //     this.mes = '';
  //     this.mes1 = 'Case update failed.';

  //   }, () => {
  //     // this.modalDisplay = !this.modalDisplay;
  //   }
  //   );
  // }
  onReset() {
    // reset whole form back to initial state
    this.submittedForm = false;
    this.caseForm.reset();
    this.t.clear();
  }

  onClear() {
    // clear errors and reset ticket fields
    this.submittedForm = false;
    this.t.reset();
  }

  OnInput(event: any) {
    var nextDate = this.getFormattedDate(this.caseDetailForm.value.nextDate);
    var openDate = this.getFormattedDate(this.caseForm.value.openDate);
    var pendingDate = this.getFormattedDate(this.caseForm.value.pendingDate);
    var closedDate = this.getFormattedDate(this.caseForm.value.closedDate);
    // console.log("next date in oninput::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  onChange(data) {
    // alert( "Triggered"+data );
    console.log("Triggered", data);
    var nextDate = this.getFormattedDate(this.caseDetailForm.value.nextDate);
    
    var openDate = this.getFormattedDate(this.caseForm.value.openDate);
    var pendingDate = this.getFormattedDate(this.caseForm.value.pendingDate);
    var closedDate = this.getFormattedDate(this.caseForm.value.closedDate);
    // console.log("next date in onChange::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  async getUserData() {
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.CaseService.getUserData(this.superUserId).
      subscribe(
        async data => {
          // console.log('getUserData', this.clientName,);
          this.clientName = await data.respData;
          this.getStageofCase()
        }
      );
  }
  onselectmemberType(membertype) {
    // console.log('membertype', membertype);
    this.CaseService.getMemberListbyType(membertype, this.userId).
      subscribe(
        async data => {
          this.memberNameList = await data.respData;
          // console.log('getMemberData', this.memberNameList);
        }
      );

  }

  async getStageofCase() {
    this.CaseService.getStageofCase().
      subscribe(
        async data => {
          console.log('getStageofCase', this.stages);
          this.stages = await data.respData;
          this.getStateList()
        }
      );
  }

  getFormattedDate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;

  }

  nextDateFormate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return month + '-' + day + '-' + year;

  }

  openDateFormate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return month + '-' + day + '-' + year;

  }

  pendingDateFormate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return month + '-' + day + '-' + year;

  }

  closedDateFormate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return month + '-' + day + '-' + year;

  }

//this method is called after first part of case creation,as for case detail updation need to send first part value also
//this method is also called first time loading
  async viewCaseDetails(caseno, type= 'insert') {
    let data = { caseNo: caseno }

    this.CaseService.caseDetails(JSON.stringify(data)).subscribe(async (data) => {
      console.log('caseDetails response', data);
      if (data.responseMessage == 'success') {
        // console.log(data.respData);
        console.log("is called")
        this.disabled = true;
        const res = data.respData;
        console.log(res)
        this.clientUserId = res.caseDtl.userId
        this.createdByUser = res.caseDtl.createdBy
        
        this.caseForm.patchValue({ 'userId': this.clientUserId });
        localStorage.setItem("CIDVALUE",this.clientUserId)
        this.caseForm.patchValue({ 'caseNo': caseno });
        //first part of case creation set values start
        this.caseTitleUpdated = res.caseDtl.caseTitle
        this.caseForm.patchValue({ 'caseTitle': this.caseTitleUpdated });

        this.caseDescUpdated = res.caseDtl.caseDesc
        this.caseForm.patchValue({ 'caseDesc': this.caseDescUpdated });

        this.courtCaseNoUpdated = res.caseDtl.courtCaseNo
        this.caseForm.patchValue({ 'courtCaseNo': this.courtCaseNoUpdated });
        
        this.otherRefNoUpdated = res.caseDtl.otherRefNo
        this.caseForm.patchValue({ 'otherRefNo': this.otherRefNoUpdated });
        
        this.refferedByUpdated = res.caseDtl.refferedBy
        this.caseForm.patchValue({ 'refferedBy': this.refferedByUpdated });

        this.caseTypeUpdated = res.caseDtl.caseType
        this.caseForm.patchValue({ 'caseType': this.caseTypeUpdated });

        this.responsibleAttorneyUpdated = res.caseDtl.responsibleAttorney
        this.caseForm.patchValue({ 'responsibleAttorney': this.responsibleAttorneyUpdated });

        this.originatingAttorneyUpdated = res.caseDtl.originatingAttorney
        this.caseForm.patchValue({ 'originatingAttorney': this.originatingAttorneyUpdated });

        this.statusUpdated = res.caseDtl.status
        this.caseForm.patchValue({ 'status': this.statusUpdated });

        this.permissionUpdated = res.caseDtl.permission
        this.caseForm.patchValue({ 'permission': this.permissionUpdated });

        this.locationUpdated = res.caseDtl.location
        this.caseForm.patchValue({ 'location': this.locationUpdated });

        this.openDateUpdated = res.caseDtl.openDate
        if(res.caseDtl.openDate!= null){
        this.caseForm.patchValue({ 'openDate': new Date(this.openDateUpdated) });
        }

        this.pendingDateUpdated = res.caseDtl.pendingDate
        if(res.caseDtl.pendingDate!= null){
        this.caseForm.patchValue({ 'pendingDate': new Date(this.pendingDateUpdated) });
        }

        this.closedDateUpdated = res.caseDtl.closedDate
        if(res.caseDtl.closedDate!= null){
        this.caseForm.patchValue({ 'closedDate': new Date(this.closedDateUpdated) });
        }

        //first part of case creation set values end
        console.log("View Response=============")
        console.log(res)
        console.log(res.caseDtl.stateId)
        if(res.caseDtl.stateId){
        
        this.stateId = res.caseDtl.stateId
        }else{
          this.stateId = ""
        }
        // console.log("stateid in get::",this.stateId);
        if(type != "update"){
          this.caseDetailForm.patchValue({ 'stateId': this.stateId });
          this.onSelectState(this.stateId);
        }
        

        this.districtid = res.caseDtl.districtId
        // console.log("districtid in get data::",this.districtid);
        this.caseDetailForm.patchValue({ 'districtId': this.districtid });
        this.onSelectDistrict(this.districtid);

        this.caseCourtUpdated = res.caseDtl.caseCourt
        // console.log("caseCourtUpdated in get data::",this.caseCourtUpdated);
        if(this.caseCourtUpdated){
        this.caseDetailForm.patchValue({ 'caseCourt': this.caseCourtUpdated });
        }else{
          this.caseDetailForm.patchValue({ '': this.caseCourtUpdated });
        }
        if(res.caseDtl.nextDate!= null){
        this.caseDetailForm.patchValue({ 'nextDate': new Date(res.caseDtl.nextDate) });
        }

        let stageOfCase = res.caseDtl.stageOfCase;
        if(stageOfCase){
        this.caseDetailForm.patchValue({ 'stageOfCase': stageOfCase });
        }else{
          this.caseDetailForm.patchValue({ '': stageOfCase });
        }
        let statussOfCase = res.caseDtl.statussOfCase;
        this.caseDetailForm.patchValue({ 'statussOfCase': statussOfCase });

        this.caseHearingList = res.caseHearingList;

        // console.log('caseHearingList', this.caseHearingList);


        let caseHearing: any = [];

        if (res.caseHearingList.length > 0) {
          //this is to remove the first blank row 
          this.t.removeAt(0);

          await res.caseHearingList.map((chrd) => {
            
            let hearingData = {
              hearingId : chrd.hearingId,
              hearingJudje: chrd.hearingJudje,
              hearingDate: new Date(chrd.hearingDate),
              hearingOrder: chrd.hearingOrder,
              orderDocId : chrd.orderDocId,
              orderDocName : chrd.orderDocName
              // hearingOrder:"https://aingenious-legalbell.s3.amazonaws.com/individual/Tester(U-9679687322206493)/CC-9683286088417411-2020nulldocument.pdf"
            };
            caseHearing.push(hearingData);
            this.t.push(this.newSkill());

          });
          // console.log('caseHearing', caseHearing);
          this.caseDetailForm.patchValue({ 'hearingDtlVo': caseHearing });
        }


        /***:--doc type:***/

        // console.log('caseDocList', this.caseDocList);
        this.caseDocList = await res.caseDocList;
        console.log("Doc List")
        console.log(this.caseDocList)
        this.getTotalHiringList();
        //console.log( 'docType', this.groupByArrayKey( res.caseDocList, 'docType' ) );

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }

  // async viewPartyDetails(formVal) {
  //   let data = { caseNo: formVal }

  //   this.CaseService.partyDetails(JSON.stringify(data)).subscribe(async (data) => {
  //     console.log('caseDetails response', data);
  //     if (data.responseMessage == 'success') {
  //       console.log(data.respData);

  //       this.disabled = true;
  //       const res = data.respData;


  //       console.log('res in viewPartyDetails', res);


  //       let partydetails: any = [];

  //       if (res.length > 0) {
  //         this.partyDtlVo().removeAt(0);

  //         await res.map((chrd) => {
  //           let dd = {
  //             id: chrd.id,
  //             caseNo: chrd.caseNo,
  //             clientUserId: chrd.clientUserId,
  //             partyType: chrd.partyType,
  //             partyEmailId: chrd.partyEmailId,
  //             partyName: chrd.partyName,
  //             partyPhoneNo: chrd.partyPhoneNo
  //           };
  //           partydetails.push(dd);
  //           this.partyDtlVo().push(this.newParty());

  //         });
  //         console.log('partyDtlVo', this.partyDtlVo);
  //         this.partyForm.patchValue({ 'partyDtlVo': partydetails });

  //       }

  //     }
  //   }, (err: any) => {
  //     console.log(err);
  //   }, () => {
  //     // this.modalDisplay = !this.modalDisplay;
  //   }
  //   );
  // }

  removeDoc(clientUserId, caseNo, docId, docType, docName, hearingId) {
    let formVal = {
      "userId": this.clientUserId,
      "caseNo": this.caseNo,
      "docType": docType,
      "docId": docId,
      "docName": docName,
      "hearingId": hearingId
    };

    console.log('removeDoc', formVal);
    this.loader = true;
    this.CaseService.removeDoc(JSON.stringify(formVal)).subscribe((data) => {
      console.log('postCaseData response', data);
      // alert( data.responseMessage );
      this.showMsg = true;
      this.mes = data.responseMessage;
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      this.loader = false;
     
      if(this.modelDocumentList.length > 0){
       
        for(var i = 0;i<this.modelDocumentList.length;i++){
          
            if(this.modelDocumentList[i].docId == formVal.docId){
              
              this.modelDocumentList.splice(i,1);
            }
        }
      //this.caseDocList = this.caseDocList.filter((item)=> item.docId !== formVal.docId));
      }
      if(this.modelDocumentList.length == 0){
        $("#fileuploadDialog").modal("hide");
      }
      setTimeout(()=>{
        this.mes = "";
      },5000);
      if(this.editMode == true){
      setTimeout(()=>{
        // location.reload();
        this.load();
      },1000);
     }else{
      this.viewCaseDetails(this.caseNo);
     }


    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes1 = 'Failed to delete file.';
      this.toastr.error(this.mes1, '');
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }


  handleFileInput(files: FileList, type, hearingId) {
    console.log("handleFileInput hearingId:::",hearingId);
    //this.openNextTab('tab_3');
    this.loader = true;
    let myFiles = []
    //this.fileToUpload = files.item(0);
    for (var i = 0; i < files.length; i++) { 
      myFiles.push(files[i]);
    }
    let data = { docType: type, caseNo: this.caseNo, userId: this.clientUserId , hId: hearingId};
    // console.log('handleFileInput data *******************'+JSON.stringify(data));
    this.CaseService.postFile(myFiles, data).subscribe(data => {
      // location.reload();
      this.loader = false;
      this.showMsg = true;
      this.mes = 'File uploaded succcessfully.';
      this.mes1 = '';
      this.toastr.success(this.mes, '');
      setTimeout(()=>{
        this.mes = "";
      },5000);

      //this.viewCaseDetails(this.caseNo);
      // alert( 'File upload success.' );
      if(this.editMode == true){
      setTimeout(()=>{
        // location.reload();
        this.load();
      },1000);
      }else{
        this.viewCaseDetails(this.caseNo);
      }

      // console.log( 'file upload response', data );
      // do something, if upload success
    }, error => {
      console.log(error);
    });

  }


  historyFileInput(files: FileList, type) {
    this.fileToUpload = files.item(0);

    console.log('fie name', this.fileToUpload.name);
    //let data={ docType: type, caseNo: this.caseNo, userId: this.userId };

    /*this.CaseService.postFile( this.fileToUpload, data ).subscribe( data => {
      this.showMsg=true;
      this.mes='File upload success.';
      this.mes1='';
      this.viewCaseDetails( this.caseNo );
    }, error => {
      console.log( error );
    } );*/

  }


  postInsChat() {
          this.subInsChatForm = true;
          this.insChatFormSubmit = true;
          let formVal         = this.insChatForm.value;
          let userData        = JSON.parse(localStorage.getItem('profileInfo'));


          // formVal.toUserEmailId = this.clientName[formVal.toUser].emailId;
          // formVal.toUserId      = this.clientName[formVal.toUser].userId;
          

          // formVal.caseNo = this.caseNo;


          // formVal.fromUserEmailId = userData.emailId;
          // formVal.fromUserId = userData.userIdlogin;

          // if (this.insChatId != '' || this.insChatId != null) {
          //   formVal.id = this.insChatId;
          // }
          // delete formVal.toUser;
          console.log('insChatForm', formVal,userData);

          if(this.insChatForm.invalid) {
              return false;
          }else{

              let requestPayload = {fromUserEmailId:userData.emailId, fromUserId:userData.userIdlogin, toUserEmailId: this.instructionToList[formVal.toUser].emailId,  toUserId: this.instructionToList[formVal.toUser].userId, caseNo: this.caseNo, msgType: formVal.msgType, msgContent: formVal.msgContent};
              console.log(requestPayload);
              // return false;
              //this.insChatBoxTriggerRender();
              this.CaseService.saveConversation(requestPayload).subscribe((data) => {
                console.log('saveCaseData response', data);
                if(data.responseMessage == 'success'){
                    // this.insFormModal = false;
                    $("#myInstructionModal").modal("hide");
      
                    // this.mes1 = '';
                    // this.showMsg = true;
                     this.mes = 'Conversation added successfully.';
                     this.toastr.success(this.mes, '');
                    this.insChatBoxList();
                }
              }, (err: any) => {
                console.log(err);
                this.toastr.error("Conversation add failed.", '');
                  // this.showMsg = true;
                  // this.mes = '';
                  // this.mes1 = 'Case Creation failed.';
    
              }, () => {
                // this.modalDisplay = !this.modalDisplay;
              }
              );

          }

          
  }

  deleteSingleConversation(ins_id) {
    this.CaseService.deleteSingleConversation(JSON.stringify({ id: ins_id })).subscribe((data) => {
      console.log('saveCaseData response', data);
      if (data.responseMessage == 'success') {

        //$( "#myModal" ).modal( "hide" );

        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Conversation deleted successfully.';
        this.toastr.success(this.mes, '');
        this.insChatBoxList();

      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Case Creation failed.';
      this.toastr.error(this.mes1, '');
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );


  }


  

  //########## Instraction ChatBot Functionality Start Here 

  displayInsFormModal(ins_id = null) {

    this.insChatForm.reset();
    this.insChatForm.patchValue({ 'toUser': "" });
    this.insChatForm.patchValue({ 'msgType': "" });
    this.insChatFormSubmit = false;
    this.subInsChatForm = false
      this.CaseService.getCaseToUserList({caseNo:this.caseNo}).subscribe(async data => {

            console.log('To List', data.respData);
            this.instructionToList = await data.respData.map((currentValue, index, arr) => {
                currentValue.sl = index + 1;
                return currentValue;
            });

      });

        // this.insFormModal = true;
        $("#myInstructionModal").modal("show");

    // // $( "#myModal" ).modal( "show" );
    // this.insChatId = ins_id;

    // if (ins_id != null) {
    //   let case_no = this.caseNo;
    //   this.CaseService.getSingleConversation({ id: ins_id }).
    //     subscribe(
    //       async data => {

    //         let res = await data.respData;
    //         console.log('getSingleConversation', res,);

    //         let toUserId = res.toUserId;
    //         var index = this.clientName.findIndex(p => p.userId == toUserId);
    //         this.insChatForm.patchValue({ 'toUser': index });

    //         let msgType = res.msgType;
    //         this.insChatForm.patchValue({ 'msgType': msgType });

    //         let msgContent = res.msgContent;
    //         this.insChatForm.patchValue({ 'msgContent': msgContent });

    //       }
    //     );
    // } else {
    //   this.insChatForm.patchValue({ 'toUser': null });
    //   this.insChatForm.patchValue({ 'msgType': null });
    //   this.insChatForm.patchValue({ 'msgContent': null });
    // }
  }

  async insChatBoxList() {
      this.loader = true;
      let case_no = this.caseNo;
      
      this.CaseService.getConversationList({caseNo:case_no,loginUserId: this.userId }).subscribe(async data => {

        //console.log('conversationList', data.respData);
        
        this.conversationList = await data.respData.map((currentValue, index, arr) => {
          currentValue.sl = index + 1;
          return currentValue;
        });
        //this.dtTrigger.next();
        console.log("zzzzzz")
        console.log(this.conversationList)
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();     
      });
        /* if (localStorage.getItem('dtTrigger')) {
          this.dtTrigger.next();
          localStorage.removeItem('dtTrigger')
        }; */
        this.loader = false;

      }
      );
  }
  //########## Instraction ChatBot Functionality Ends Here 

  privilegemyModal(index, status = "show") {
    if (status == 'hide') {
      $('#privilegemyModal' + index).modal('hide');
    } else {
      $('#privilegemyModal' + index).modal('show');
    }
  }
  privilegemyEditModal(index, status = "show") {
    if (status == 'hide') {
      $('#privilegemyEditModal' + index).modal('hide');
    } else {
      $('#privilegemyEditModal' + index).modal('show');
    }
  }

  // TeamForm first time creation start

  TeamForms(): FormArray {
    return this.assignTeamForm.get("TeamForms") as FormArray
  }

  // partyDtlVo(): FormArray {
  //   return this.partyForm.get("partyDtlVo") as FormArray
  // }
  //newteams added in addTeam
  newTeams(): FormGroup {
    return this.fb.group({
      memberUserId: [''],
      clientUserId: [''],
      caseNo: this.caseNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }


  addTeam(teamIndex) {
    console.log("Adding a team *********", teamIndex);
    this.TeamForms().push(this.newTeams());
    this.prmissionVal(teamIndex + 1);
  }

  removeTeam(teamIndex: number) {
    console.log("removing a team", teamIndex);
    this.TeamForms().removeAt(teamIndex);
  }

  teamPermissions(teamIndex: number): FormArray {
    return this.TeamForms().at(teamIndex).get("permission") as FormArray
  }

  newPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }


  async prmissionVal(empIndex = 0) {
    let per_val: any = [{
      id: null,
      folderName: 'financial',
      delete: false,
      view: false,
      upload: false,
    }, {
      id: null,
      folderName: 'casefolder',
      delete: false,
      view: false,
      upload: false,
    }];
    let permis: any = [];

    await per_val.map((chrd, index) => {
      console.log('chrd ------------: ', chrd);
      permis.push(chrd);
      this.teamPermissions(empIndex).push(this.newPermission());
    });

    (this.assignTeamForm.get('TeamForms') as FormArray).at(empIndex).get('permission').patchValue(permis);
  }

  addTeamPermission(empIndex: number) {
    console.log('this.newPermission**************', this.newPermission());
    this.teamPermissions(empIndex).push(this.newPermission());
  }


  removeTeamPermission(empIndex: number, permissionIndex: number) {
    this.teamPermissions(empIndex).removeAt(permissionIndex);
  }

  async editTeam(teamIndex) {
    console.log("editTeam", teamIndex);

    let tl: any = this.assignTeamList;
    this.assignTeamList = await tl.map((p, i) => {
      if (i == teamIndex) {
        p.disabled = false;
      }
      // else {
      //   p.disabled=true;
      // }
      return p;
    });
    // this.TeamForms().push( this.newTeams() );
  }

  /****:- listing edit section for team assignment-:****/

  TeamListingEditForms(): FormArray {
    return this.assignTeamEditForm.get("TeamEditForms") as FormArray
  }
  //in edit mode set form value
  newTeamListingEditForm(): FormGroup {
    return this.fb.group({
      memberUserId: ['', Validators.required],
      clientUserId: ['', Validators.required],
      caseNo: this.caseNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }

  teamListingEditFormPermissions(teamIndex: number): FormArray {
    return this.TeamListingEditForms().at(teamIndex).get("permission") as FormArray
  }
  //this is for modal permission
  newListingEditFormPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }

  assignTeamSubmit() {
    this.subAssignTeamForm = true;

    let formVal = this.assignTeamForm.value;
    let formValEdit = this.assignTeamEditForm.value;
    console.log('assignTeamSubmit formVal------ ', formVal);
    console.log('assignTeamForm ------ ', formVal.TeamForms);
    console.log('TeamEditForms', formValEdit.TeamEditForms);

    let mergeData: any = [...formVal.TeamForms, ...formValEdit.TeamEditForms,];
    console.log('mergeData', mergeData);
    if (this.assignTeamForm.invalid) {
      return;
    }
    this.btnSppiner = true;

    this.CaseService.saveMemberPermission(JSON.stringify(mergeData)).subscribe((data) => {
      console.log('saveMemberPermission response', data);
      if (data.responseMessage == 'success') {
        this.displayCaseDocument = 'block';
        this.assignTeamEditList();
        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Team assigned successfully.';
        this.toastr.success(this.mes, '');
        this.btnSppiner = false;
      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Case Creation failed.';
      this.toastr.error(this.mes1, '');
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    });

  }

  async assignTeamEditList() {
    console.log("hit")
    // const arr = <FormArray>this.assignTeamEditForm.controls.TeamEditForms;
    // arr.controls = [];
    // let case_no = this.caseNo;
    // this.CaseService.memberList({ caseNo: case_no }).subscribe(async data => {
    //   console.log('assignTeamList', data.respData);
    //   let list = await data.respData;
    //   if (list.length > 0) {
    //     await list.map((currentValue, index, arr) => {
    //       console.log("currentValue::", currentValue);
    //       currentValue.disabled = true;
    //       this.TeamListingEditForms().push(this.newTeamListingEditForm());
    //       currentValue.permission.map(async () => {
    //         await this.teamListingEditFormPermissions(index).push(this.newListingEditFormPermission())
    //       });
    //       return currentValue;
    //     });
    //     setTimeout(() => {
    //       (this.assignTeamEditForm.get('TeamEditForms') as FormArray).patchValue(list)
    //     }, 1000);
    //   }
    //   this.assignTeamList = list;
    //   console.log('this.assignTeamList', this.assignTeamList);

    //   // ( this.assignTeamForm.get( 'TeamForms' ) as FormArray ).at( index ).get( 'permission' ).patchValue( permis );

    //   // this.teamPermissions( teamIndex+1 ).push( this.newPermission() );

    //   if (localStorage.getItem('teamTrigger')) {
    //     setTimeout(() => {
    //       this.teamTrigger.next();
    //     }, 1000);
    //     localStorage.removeItem('teamTrigger')
    //   };
    // }
    // );
  }
  teamTriggerRender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again

      this.dtTrigger.next();
    });
  }
  clickmemberName(memberId) {
    console.log("clickmemberId::", memberId);
    let memberNewList: any[] = [];
    memberNewList = this.memberNameList.filter((item) => item.userId == memberId);
    console.log('memberNewList ====== ', memberNewList[0].firstName);
    this.newTeams().patchValue({
      memberUserName: memberNewList[0].firstName,
    });
    console.log('this.newTeams() ====== ', this.newTeams().value);
  }
  //assign team edit end

  getStateList() {
    this.CaseService.getSateList().
      subscribe(
        data => {
          this.statelist = JSON.parse(this.CaseService.userData._body).respData;
          this.loaderEditCase = false;
          // console.log("getStateList comp res--" + JSON.stringify(this.statelist));
        }
      );
  }

  onSelectState(stateid) {
    console.log("onSelectState--", stateid);
    this.stateId = stateid;
    // this.CaseService.getDistrictList(stateid).
    //   subscribe(
    //     data => {
    //       this.districtlist = JSON.parse(this.CaseService.userData._body).respData;
    //       // console.log("getDistrictList comp res--" + JSON.stringify(this.districtlist));
    //     }
    //   );
    // //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);


    // this.districtid = districtid;
    this.CaseService.getCourtList(this.stateId).
      subscribe(
        data => {
          this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
          // console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
        }
      );

  }

  onSelectDistrict(districtid) {
    // console.log("onSelectDistrict--", districtid);
    // this.districtid = districtid;
    // this.CaseService.getCourtList(this.stateId, districtid).
    //   subscribe(
    //     data => {
    //       this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
    //       // console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
    //     }
    //   );
    //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }

  async memberPermission() {

    //const arr=<FormArray>this.assignTeamEditForm.controls.TeamEditForms;
    //arr.controls=[];
    // console.log("memberPermission");
    let case_no = this.caseNo;
    this.CaseService.memberPermissionList({ caseNo: case_no, memberUserId: this.userId }).subscribe(async data => {

      console.log('assignpermissionListResponse', data.respData);
      let permissionlist        = await data.respData;
      this.caseFolderPermission = permissionlist.permission;

      console.log('caseFolderPermission', this.caseFolderPermission);

      if (permissionlist.length > 0) {

        await permissionlist.map((currentValue, index) => {
          // console.log("currentValue::", currentValue);
          // currentValue.disabled=true;
          // this.TeamListingEditForms().push( this.newTeamListingEditForm() );

          // currentValue.permission.map( async () => {
          //   await this.teamListingEditFormPermissions( index ).push( this.newListingEditFormPermission() )
          // } );

          return currentValue;
        });

        // console.log('permissionList', permissionlist);


      }


      // ( this.assignTeamForm.get( 'TeamForms' ) as FormArray ).at( index ).get( 'permission' ).patchValue( permis );

      // this.teamPermissions( teamIndex+1 ).push( this.newPermission() );


    }, (err: any) => {
      console.log('memberPermission', err);

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }


  
  openDialog(){
    this.submittedForm = true;
    if (this.caseForm.valid) {
    $('#saveIntent').modal('show');
    }
  }


  displayDocumentsModel(modelName,listName) {
    this.modelDocumentList = []
    this.uploadedDocumentModelName = modelName;
    this.typeOfDocumentList = listName;
    this.modelDocumentList = this.caseDocList[listName]
    $('#fileuploadDialog').modal('show');
  }

  getClientId(opt){
    console.log(opt)
    localStorage.setItem("CIDVALUE","")
    localStorage.setItem("CIDVALUE",opt)
  }

  newHearingFIleUpload(files: FileList){
    this.fileToUpload = files.item(0);
  }

  newHearingSave(){
    this.loader = true;
    this.subNewHearingForm = true
    if (this.newHearingForm.invalid) {
      this.toastr.error('Blank Inputs not allowed', '');
      setTimeout(()=>{
       location.reload();
        //this.load();
      },2000);
      //this.loader = false;

      return false;
    } else {
      this.subNewHearingForm =false
      $("#hearingInsertDialog").modal("hide");
      console.log(this.newHearingForm.value)
      var hearingDate = this.getFormattedDate(this.newHearingForm.value.hearingDate);
      
      let data
       if(!this.editNewHearingForm){
        data = { hearingDate: hearingDate, caseNo: this.caseNo, userId: this.userId , hearingJudje: this.newHearingForm.value.hearingJudje,hearingId:null};
       }else{
        data = { hearingDate: hearingDate, caseNo: this.caseNo, userId: this.userId , hearingJudje: this.newHearingForm.value.hearingJudje,hearingId:this.newHearingId};
       }
    // console.log('handleFileInput data *******************'+JSON.stringify(data));
    this.CaseService.saveNewHearingData(this.fileToUpload, data).subscribe(data => {
      // location.reload();
      
      this.showMsg = true;
      this.mes = 'Hearing details saved succcessfully.';
      this.mes1 = '';
      this.toastr.success(this.mes, '');
      this.getTotalHiringList()
      setTimeout(()=>{
        this.mes = "";
      },5000);

      //this.viewCaseDetails(this.caseNo);
      // alert( 'File upload success.' );
      if(this.editMode == true){
      setTimeout(()=>{
        // location.reload();
        //this.load();
      },1000);
      }else{
        //this.viewCaseDetails(this.caseNo);
      }

      // console.log( 'file upload response', data );
      // do something, if upload success
    }, error => {
      this.toastr.error('Hearing details not saved.', '');
      console.log(error);
    });
    }

  }

  getTotalHiringList(){
    this.CaseService.getHiringList({ caseNo: this.caseNo}).subscribe(async data => {
      this.newHearingList = data.respData;
      if(this.loader){
      this.loader = false;
      }
    }, (err: any) => {  
      if(this.loader){
        this.loader = false;
        }
    }, () => {
    }
    );
  
  }

  deleteHearing(row){
    $('#deleteHiringNew').modal('show');
    //this.deleteNewHearingConfirmation(row)
    this.deleteHiringDetails = row;
  }

  edit(row){
      this.editNewHearingForm = true;
      this.newHearingOrderDocValue = row.hearingOrder
       this.newHearingOrderDocName = row.orderDocName
       this.newHearingId = row.hearingId
      this.newHearingForm.patchValue({ 'hearingDate': new Date(row.hearingDate) });
      this.newHearingForm.patchValue({ 'hearingJudje': row.hearingJudje });
      $('#hearingInsertDialog').modal('show');
     
  }

  addNewHearingDetails(){
    this.editNewHearingForm = false;
    this.newHearingForm.reset()
    this.variable.nativeElement.value = "";
  }

  deleteNewHearingConfirmation(){
    this.loader = true;
    let row = this.deleteHiringDetails
    this.CaseService.deleteHiringList({ hearingId: row.hearingId,userId:localStorage.getItem("CIDVALUE"),caseNo:row.caseNo}).subscribe(async data => {
      this.getTotalHiringList();
      this.toastr.success("Hearing deleted successfull", '');
      $('#deleteHiringNew').modal('hide');
    }, (err: any) => {  
      this.toastr.error("Hearing delete unsuccessfull", '');
      $('#deleteHiringNew').modal('hide');
      this.loader = false;
    }, () => {
    }
    );
  }

  getAllNotification(userId){
  
    this.legalbellService.getNotificationDetails(userId).
    subscribe(
      data => {
        this.notificationCount = JSON.parse(this.legalbellService.userData._body).searchCount;
       
      this.legalbellService.updateMessage(this.notificationCount)
      }
    );
  }






}


