import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service'
import { ToastrService } from 'ngx-toastr';
import { trim } from 'jquery';
@Component({
  selector: 'app-clientexpenses',
  templateUrl: './clientexpenses.component.html',
  styleUrls: ['./clientexpenses.component.css']
})
export class ClientexpensesComponent implements OnInit {

  myform: FormGroup;
  modalDisplay: boolean = false;
  fareaDisplay: boolean = false;
  superUserId : string;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  resultBtnShow: boolean = false;
  caseNo : string;
  clientUserId : string;
  clientName : string;
  particulars : string;
  particularsId : string;
  amount : number;
  expenselistcasespecific: FormArray;
  loginUserId : string;
  showMsg: boolean = false;
  mes = '';
  mes1 = '';
  caseValid: boolean = false;
  userId : string;
  caseList:any[]=[];
  clientList:any[]=[];
  clientexpensepermission: string;
  courtCaseNo: string;



  constructor(private legalbellService: LegalbellService,private router: Router,private route: ActivatedRoute,public fb: FormBuilder,private toastr:ToastrService) {
   
    this.myform = this.fb.group({
      expensesArr: new FormArray([])
    })}
   
  ngOnInit(): void {
    //this.clientExpenseMaster();
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.clientexpensepermission = localStorage.getItem('clientexpensepermission')?localStorage.getItem('clientexpensepermission'): null;
    this.userId = userData.userIdlogin;
    this.getAllClientsOfSuperUser();
  }
  getAllClientsOfSuperUser(){
    this.legalbellService.getUserList(this.superUserId).subscribe((res: any) => {
      this.clientList = JSON.parse(this.legalbellService.userData._body).respData;
      //this.clientData=this.mes;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
 
    });
  }
  onSelectClient(clientUserId){
    this.showMsg=false;
      this.mes1="";
    if(clientUserId !== '--Select--'){
      this.legalbellService.getCaseList(clientUserId).subscribe((res: any) => {
        this.caseList = JSON.parse(this.legalbellService.userData._body).respData;
        //this.stc=false;
      }, (err: any) => {
        
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        
      });
    }
    
  }
  //this method is used fetch all the saved client master expenses first time,before chossing any case
  clientExpenseMaster() {
    
    let requestdata = {};

    this.legalbellService.clientExpenseMaster(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('clientExpenseMaster response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);

        const res = data.respData;
        console.log('res in clientExpenseMaster', res);

        if (res.length > 0) {
           res.map((chrd) => {
            const expenselist = <FormArray>this.myform.get('expensesArr');
            expenselist.push(new FormGroup({
              id:new FormControl(chrd.id),
              orgId : new FormControl("ORG0001"),
              createdBy: new FormControl(this.userId),
              updatedBy: new FormControl(this.userId), 
              particularsId: new FormControl(chrd.particularsId),
              clientUserId: new FormControl(this.clientUserId),
              caseNo : new FormControl(this.caseNo,[Validators.required]),
              amount : new FormControl(0),
              particularName : new FormControl(chrd.particularName),
            }))
            
          });
         
          console.log("my form",this.myform.get('expensesArr'));


        }

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  clientExpenseCasespecific() {
    let requestdata = {"orgId" :  "ORG0001",
    "clientUserId" : this.clientUserId,
    "caseNo" : this.caseNo}

    this.legalbellService.clientExpenseCaseSpecific(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('clientExpenseCasespecific response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);

        const res = data.respData;
        console.log('res length in clientExpenseCasespecific', res.length);
        this.myform = this.fb.group({
          expensesArr: new FormArray([])
       });
        if (res.length > 0) {
  
          res.map((chrd) => {
            
            this.expenselistcasespecific = <FormArray>this.myform.get('expensesArr');
            this.expenselistcasespecific.push(new FormGroup({
              id: new FormControl(chrd.id),
              orgId : new FormControl("ORG0001"),
              createdBy: new FormControl(this.userId),
              updatedBy: new FormControl(this.userId), 
              particularsId: new FormControl(chrd.particularsId),
              clientUserId: new FormControl(this.clientUserId),
              caseNo : new FormControl(this.caseNo),
              amount : new FormControl(chrd.amount),
              particularName : new FormControl(chrd.particularName),
            }))
            
          });
         
          console.log("my form in clientExpenseCasespecific",this.myform.get('expensesArr'));

        }else{
          this.clientExpenseMaster();
        }

        

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  saveIntentCorfirmation() {
    console.log('this.caseNo', this.caseNo);
    if(typeof this.caseNo=="undefined"){
      this.caseValid = true;
      console.log('abc', this.myform);
    } else {
      this.modalDisplay = true;
      console.log("modalDisplay::", this.modalDisplay);
    }
  }
  saveAllData() {

    var checkValues =  this.myform.get('expensesArr').value;
    //console.log("Request Body :::>>> "+checkValues.length);
    var counter = 0;
    for(var i = 0; i<checkValues.length; i++)
    {
      if(trim(checkValues[i].particularName) == '')
      {counter += 1}
    }
    //console.log('Counter Value :::>>> '+counter);
    if(counter>0){
      this.toastr.error('Blank Values will not be stored in Master');
    }
    
     this.legalbellService.saveClientExpensesMasterData(JSON.stringify(this.myform.get('expensesArr').value)).
        subscribe(
          (data) => {
            this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
            if (this.mydata.status == '200') {
              this.showMsg = true;
              this.mes = this.mydata.responseMessage;
              this.toastr.success("Expenses Updated Succssfully")
              this.mes1 = '';
              this.resultShow = 'Execution Result : ';
              this.btnShow = '(Details)';
              this.statusCode = "Success";
              this.caseValid = false;
            }
            else {
              this.statusCode = "Fail";
            }
            // setTimeout( () => {
            //   location.reload();
            // }, 1000 );
            this.clientExpenseCasespecific();
          }, (err: any) => {
            console.log(err);
            this.showMsg = true;
            this.mes1 = this.mydata.responseMessage;
            this.toastr.error(this.mes1)
          }, () => {
            // this.modalDisplay = !this.modalDisplay;
          }
        );
        //this.modalDisplay = false;

  }
 //Show output
 showOutput() {
   this.resultBtnShow = !this.resultBtnShow;
 }
  abc() {
    console.log(this.myform);
  }
  //this method is not used now
  getAllCaseList() {
    // this.loginUserId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
    //  console.log("loginUserId in client ::profile", this.loginUserId);
    this.legalbellService.getAllCaseList(this.loginUserId ).
      subscribe(
        data => {
         // this.caselist=JSON.parse( this.legalbellService.userData._body ).respData;
          //console.log( "caselist comp res--"+JSON.stringify( this.caselist ) );
        }
      );
  }
//this method is used to get all the information depend on case and set case specific form value
  onSelectCase(causeTitle) {
    console.log("onSelectCase--",causeTitle);
     let caseNewList : any[] = [];
     caseNewList = this.caseList.filter((item) => item.caseTitle == causeTitle);
     this.caseNo = caseNewList[0].caseNo;
     this.courtCaseNo = caseNewList[0].courtCaseNo;
     console.log('onSelectCase ====== ',this.caseNo+"amount"+this.amount + "particulars"+this.particulars);
     this.clientName = caseNewList[0].userName;
     this.clientUserId = caseNewList[0].userId;
     
    
     this.myform.get('expensesArr')['controls'].forEach(element => {
      console.log("expensesArr form control",element);
      element.controls['clientUserId'].setValue(this.clientUserId);
      element.controls['caseNo'].setValue(this.caseNo);
    });

    //this form value is set only when the user select a case and fetch the data saved for this case
    this.clientExpenseCasespecific();
    
  }

  addNewClientExpense(){
    this.expenselistcasespecific = <FormArray>this.myform.get('expensesArr');
    this.expenselistcasespecific.insert(0,new FormGroup({
      orgId : new FormControl("ORG0001"),
      createdBy: new FormControl(this.userId),
      updatedBy: new FormControl(this.userId), 
      particularsId: new FormControl(""),
      clientUserId: new FormControl(this.clientUserId),
      caseNo : new FormControl(this.caseNo),
      amount : new FormControl(0),
      particularName : new FormControl(""),
    }))
  }
}