import { Component, OnInit,ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service'
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
declare var jQuery: any;

import { DataTableDirective } from 'angular-datatables';
import { trim } from 'jquery';

@Component({
  selector: 'app-schedulefees',
  templateUrl: './schedulefees.component.html',
  styleUrls: ['./schedulefees.component.css']
})
export class SchedulefeesComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })

  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  myform: FormGroup;
  modalDisplay: boolean = true; 
  fareaDisplay: boolean = false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  resultBtnShow: boolean = false;
  userId : string;
  userType : string;
  pricelist : FormArray;
  showMsg: boolean = false;
  mes = '';
  mes1 = '';
  servicemasterpermission : string;
  superUserType : string;
  superUserId : string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loader : boolean;
  
  constructor(private toastr: ToastrService, private legalbellService: LegalbellService,private router: Router,private route: ActivatedRoute,public fb: FormBuilder) {

    this.myform = this.fb.group({
      serviceArr: new FormArray([])
   })}
   
  ngOnInit(): void {
    this.userId = localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
    this.userType = localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
    this.servicemasterpermission = localStorage.getItem('servicemasterpermission')?localStorage.getItem('servicemasterpermission'): null;
    console.log("servicemasterpermission:::",this.servicemasterpermission);
    this.superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    this.getserviceMaster();
    this.dtOptions = {
      lengthMenu : [[10, 25, 50], [10, 25, 50]],
      pagingType: 'full_numbers',
      pageLength: 25,
      dom: 'lfBrtip',
    };

    this.myform.setValue({
      hour: 'No', 
    });
    
  }
  
   getserviceMaster() {
    //for lawfirm need to send lawfirm id based on usertype
    this.loader = true;
    let requestdata = { "superUserId": this.superUserId,
                        "userType": this.userType };
 
    this.legalbellService.serviceMaster(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('serviceMaster response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);
        const res = data.respData;
        
       
        console.log('res in serviceMaster', res);

        if (res.length > 0) {
           res.map((chrd) => {
            this.pricelist = <FormArray>this.myform.get('serviceArr');
            
            this.pricelist.push(new FormGroup({
              id : new FormControl(chrd.scheduleOfFeesMstIdentity.id),
              superUserId: new FormControl(this.userId),
              tribunals: new FormControl(chrd.tribunals),
              particularsOfServices: new FormControl(chrd.particularsOfServices),
              hour: new FormControl(chrd.hour),
              amount : new FormControl((chrd.amount)),
              userType : new FormControl(this.userType)
            }))
          //   if(this.isDtInitialized) {
          //     this.dtElement.dtInstance.then( (dtInstance: DataTables.Api ) => {
          //       // Destroy the table first
          //       dtInstance.destroy();
                
          //       // Call the dtTrigger to rerender again
          //       this.dtTrigger.next();
          //   } );
            
          // }else{
          //   this.isDtInitialized = true;
           
          //   this.dtTrigger.next();
          // }
            
          });
          this.loader = false;
          console.log("my form",this.myform.get('serviceArr'));

        }

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  saveAllData() {
    //console.log("Request Body :::>>> " +JSON.stringify(this.myform.get('serviceArr').value));
    
    var checkValues =  this.myform.get('serviceArr').value;
    //console.log("Request Body :::>>> "+checkValues.length);
    var counter = 0;
    for(var i = 0; i<checkValues.length; i++)
    {
      if(trim(checkValues[i].tribunals) == '' || trim(checkValues[i].particularsOfServices) == '' || trim(checkValues[i].amount) == '' || trim(checkValues[i].hour) == '')
      {counter += 1}
    }
    //console.log('Counter Value :::>>> '+counter);
    if(counter>0){
      this.toastr.error('Blank Values will not be stored in Master');
    }

    this.legalbellService.saveScheduleOfFees(JSON.stringify(this.myform.get('serviceArr').value)).
    subscribe(
      (data) => {
        this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
        //console.log("RESPONSE FOR EMPTY DATA :::>>> "+this.mydata.status);
        if (this.mydata.status == '200') {
          this.showMsg = true;
          this.mes = this.mydata.responseMessage;
          this.mes1 = '';
          this.resultShow = 'Execution Result : ';
          this.btnShow = '(Details)';
          this.statusCode = "Success";
        }
        else {
          //this.statusCode = "Fail";

          this.toastr.error('Cannot save blank values.');
        }
        setTimeout( () => {
          location.reload();
        }, 2000 );
        //this.getserviceMaster();
      }, (err: any) => {
        console.log(err);
        this.showMsg = true;
        this.mes1 = this.mydata.responseMessage;
      }, () => {
        // this.modalDisplay = !this.modalDisplay;
      }
    );
    this.modalDisplay = false;

  }
 //Show output
 showOutput() {
   this.resultBtnShow = !this.resultBtnShow;
 }
  abc() {
    console.log(this.myform);
  }

  addNewFees(){
    this.pricelist.insert(0,new FormGroup({
      id : new FormControl(""),
      superUserId: new FormControl(this.userId),
      tribunals: new FormControl(""),
      particularsOfServices: new FormControl(""),
      hour : new FormControl(0),
      amount : new FormControl(0.0),
      userType : new FormControl(this.userType)
    }))
  }
  removeFees(index:number){
    this.pricelist.removeAt(index);
  }
  //ng on init end
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  validate(value) {
    var paramValue = trim(value);
    if(paramValue == ''){
      this.toastr.error('Cannot be blank');
      return false;
    }
    /* else if(paramValue.match("^[0-9\s]$")){
      this.toastr.error('Cannot be number');
      return false;
    } */
  }
}
