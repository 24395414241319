<div class="content-wrapper">
    <div class="content">
        <div class="bottom-height">
            <div class="box-header with-border">
                <h3 class="box-title main-header-top">Notification Mangement</h3>
            </div>
            <div class="box-body">
                <div class="box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="table-responsive table-striped">
                                <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Notification For</th>
                                            <th>Case No</th>
                                            <th>Case Title</th>
                                            <th>Case Type</th>
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of notificationlist">
                                            <td> {{item.entity}} {{item.status}} </td>
                                            <td> {{item.obj.courtCaseNo}} </td>
                                            <td> {{item.obj.caseTitle}} </td>
                                            <td> {{item.obj.caseType}} </td>
                                            
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
