import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { Http, Headers } from '@angular/http';

import { CaseService } from '../service/case.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

declare var $: any;
declare var jQuery: any;

import { HttpClient, HttpResponse } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';

import { LegalbellService } from '../service/legalbell.service'

@Component({
  selector: 'app-role-team-assignment',
  templateUrl: './role-team-assignment.component.html',
  styleUrls: ['./role-team-assignment.component.css']
})
export class RoleTeamAssignmentComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })

  dtElement: DataTableDirective;
  caseNo: any;
  userId: any;
  memberNameListA: any = [];
  memberNameListE: any = [];
  memberName: any = [
  ];

  memberUser: any = [
    ];

  showMsg: boolean = false;
  mes = '';
  mes1 = '';

  assignTeamForm: FormGroup;
  assignTeamEditForm: FormGroup;
  TeamEditForm: FormArray;
  url: any;
  btnSppiner: boolean = false;
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;
  disabled: boolean = false;
  caseDocList: any = [];
  caseHearingList: any = [];
  conversationList: any = [];
  memberList: any = [];
  assignTeamList: any = [];
  subAssignTeamForm: boolean = false;
  modalDisplay: boolean = false;
  clientUserId: string;
  btnDisabled:boolean = true;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  teamDtOptions: DataTables.Settings = {};
  teamTrigger: Subject<any> = new Subject();
  index: number;
  loginUserType: string;

  // Check DT initialise in assign team
  isAssignTeamDtInitialized:boolean = false;

  memberTypelist  = [];
  usertype:string = "";

  assignTeamFormTemp      :any = [];
  assignTeamEditFormTemp  :any = [];
  removeConfirmIndex      :number = 0;

  addTeamIndex    : any = 0;
  editTeamIndex   : any = 0;

  loader : boolean;
  // addTeamTotalOpt : any = 0;

  constructor(private fb: FormBuilder,
    private CaseService: CaseService,
    private router: Router,
    private legalService: LegalbellService,
    private toastr: ToastrService,
    private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip',
    };
    this.teamDtOptions = {
      ordering: false,
      searching: false,

      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip'
    };

    localStorage.setItem("dtTrigger", "dtTrigger");
    localStorage.setItem("teamTrigger", "teamTrigger");

    if (localStorage.getItem('refresh')) {
      localStorage.removeItem("refresh");
      location.reload();
    };


    this.load();

    // Init option of select
    // this.onselectmemberType('LawyerAssociate', 0);
    // this.onselectmemberType('LawyerAssociate', 1);
    // Load the List Time of Init Page
    this.assignTeamEditList();
    this.index = 0;

  }

  //ng on init end
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.teamTrigger.unsubscribe();
  }


  //load start
  async load() {
    this.url = this.router.url;
    const caseView = this.url.search("case-view");
    const caseEdit = this.url.search("case-edit");
    const casecreation = this.url.search("casecreation");

    if (caseView == 1) {
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }

    if (caseEdit == 1) {
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }

    if (casecreation == 1) {
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
    }

    // console.log(this.url, this.viewMode, this.editMode, this.createMode);
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    
    this.userId = userData.userIdlogin;
    this.loginUserType = userData.usertype;
    

    // this form is used to submit the data first time
    this.assignTeamForm = this.fb.group({
      TeamForms: this.fb.array([])
    })

    // this form is used to submit the data after edit
    this.assignTeamEditForm = this.fb.group({
      TeamEditForms: this.fb.array([]),
    })

    if (this.viewMode == true || this.editMode == true) {

      this.route.params.subscribe(params => {
        if(localStorage.getItem("CIDVALUE") !== ""){
          this.clientUserId = localStorage.getItem("CIDVALUE");
        }else{
        this.clientUserId = params['userId'];
        }
        this.caseNo = params['caseNo'];
        localStorage.setItem('currentCaseNo', this.caseNo);
      });

      await this.memberPermission()

    }

    this.TeamForms().push(this.newTeams());

    this.prmissionVal(0);

    //##### call the member list tile of load
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.getTeamMemberType(this.usertype);


  }

  privilegemyModal(index, status = "show") {
    console.log('modal call');
    
    
    let memberId=$('#memberUserId' + index).val();
    let memberType=$('#memberUserType' + index).val();
    // console.log(memberId);
    // console.log(memberType);
    if(memberId !='' && memberType!=''){
      if (status == 'hide') {
        $('#privilegemyModal' + index).modal('hide');
        this.assignTeamForm.value.TeamForms[index].caseNo = localStorage.getItem('currentCaseNo');

        for(let i=0;i<this.memberNameListA[index].length;i++){
          if(memberId == this.memberNameListA[index][i].userId) {
            this.assignTeamForm.value.TeamForms[index].memberUserName = this.memberNameListA[index][i].firstName+' '+this.memberNameListA[index][i].lastName;
            //console.log(this.assignTeamForm.value);
            //this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;
          }
        }

        //this.assignTeamForm.value.TeamForms[index].memberUserName = this.memberNameListA[index][0].firstName+' '+this.memberNameListA[index][0].lastName;
        this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;
      } else {
        $('#privilegemyModal' + index).modal('show');
      }
    }else{
      if(memberType == ''){
        $('#infoType' + index).html('Please select member type');
      }
      if(memberId == ''){
        $('#infoID' + index).html('Please select member');
      }
      
      // console.log('please add member first');
    }
    console.log(this.assignTeamForm.value.TeamForms);
    for(var i=0;i<this.assignTeamForm.value.TeamForms.length;i++){
        for(var j = 0;j<this.assignTeamForm.value.TeamForms[i].permission.length;j++){
            console.log(this.assignTeamForm.value.TeamForms[i].permission[j].delete)
            console.log(this.assignTeamForm.value.TeamForms[i].permission[j].upload)
            console.log(this.assignTeamForm.value.TeamForms[i].permission[j].view)
            if(this.assignTeamForm.value.TeamForms[i].permission[j].delete || this.assignTeamForm.value.TeamForms[i].permission[j].upload || this.assignTeamForm.value.TeamForms[i].permission[j].view){
                this.btnDisabled = false;
               break;
            }else{
              this.btnDisabled = true;
              
            }
        }
    }
   
    
  }
  privilegemyEditModal(index, status = "show") {
    let memberId=$('#editmemberUserId' + index).val();
    let memberType=$('#editmemberUserType' + index).val();
    console.log(memberId);
    console.log(memberType);
    console.log(this.memberNameListE);
    if(memberId !='' && memberType!=''){
    if (status == 'hide') {
        $('#privilegemyEditModal' + index).modal('hide');
        this.assignTeamEditForm.value.TeamEditForms[index].caseNo = localStorage.getItem('currentCaseNo');
        for(let i=0;i<this.memberNameListA[index].length;i++){
          if(memberId == this.memberNameListA[index][i].userId) {
            this.assignTeamForm.value.TeamForms[index].memberUserName = this.memberNameListA[index][i].firstName+' '+this.memberNameListA[index][i].lastName;
            //console.log(this.assignTeamForm.value);
            //this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;
          }
        }
        //this.assignTeamEditForm.value.TeamEditForms[index].memberUserName = this.memberNameListE[index][1].firstName+' '+this.memberNameListE[index][1].lastName;
        this.assignTeamEditFormTemp = this.assignTeamEditForm.value.TeamEditForms;
    } else {
      $('#privilegemyEditModal' + index).modal('show');
    }
  }else{
    if(memberType == ''){
      $('#infoTypeEdit' + index).html('Please select member type');
    }
    if(memberId == ''){
      $('#infoIDEdit' + index).html('Please select member');
    }
    
    console.log('please add member first');
  }
  for(var i=0;i<this.assignTeamEditForm.value.TeamEditForms.length;i++){
    for(var j = 0;j<this.assignTeamEditForm.value.TeamEditForms[i].permission.length;j++){
        
        if(this.assignTeamEditForm.value.TeamEditForms[i].permission[j].delete || this.assignTeamEditForm.value.TeamEditForms[i].permission[j].upload || this.assignTeamEditForm.value.TeamEditForms[i].permission[j].view){
            this.btnDisabled = false;
           break
        }else{
          this.btnDisabled = true;
          
        }
    }
}
  }

  // TeamForm first time creation start

  TeamForms(): FormArray {
    return this.assignTeamForm.get("TeamForms") as FormArray
  }


  TeamEditForms(): FormArray {
    return this.assignTeamEditForm.get("TeamEditForms") as FormArray
  }

  // partyDtlVo(): FormArray {
  //   return this.partyForm.get("partyDtlVo") as FormArray
  // }
  //newteams added in addTeam
  newTeams(): FormGroup {
    return this.fb.group({
      memberUserId: [''],
      // clientUserId: [''],
      clientUserId: this.clientUserId,
      loginUserId: this.userId,
      caseNo: this.caseNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }


  addTeam(teamIndex) {
    this.btnDisabled = true;
    console.log("Adding a team *********",this.index);
    this.TeamForms().push(this.newTeams());
    // this.TeamEditForms().push(this.newTeams());
    
    this.prmissionVal(this.index + 1);
    this.index++;

    this.addTeamIndex++;
  }

  removeTeam(teamIndex: number, type = "A") {
    console.log("removing a team", teamIndex);
    console.log(this.assignTeamList[teamIndex]);
    
    if(type == 'A'){
        this.TeamForms().removeAt(teamIndex);
        this.addTeamIndex--;
    }else{  

        $("#removeConfirm").modal("show");
        this.removeConfirmIndex = teamIndex;

        // this.CaseService.removeMemberPermission({ caseNo: this.caseNo, memberUserId: this.assignTeamList[teamIndex].memberUserId }).subscribe(async data => {
        //     let responseData = await data.respData;
        //     console.log(responseData);

        // });

    }

    
    
    for(var i=0;i<this.assignTeamForm.value.TeamForms.length;i++){
      for(var j = 0;j<this.assignTeamForm.value.TeamForms[i].permission.length;j++){
          
          if(this.assignTeamForm.value.TeamForms[i].permission[j].delete || this.assignTeamForm.value.TeamForms[i].permission[j].upload || this.assignTeamForm.value.TeamForms[i].permission[j].view){
              this.btnDisabled = false;
             break
          }else{
            this.btnDisabled = true;
            
          }
      }
  }
    // this.index--;
  }


  removeConfirm(removeConfirmIndex:number){
        this.CaseService.removeMemberPermission({ caseNo: this.caseNo, memberUserId: this.assignTeamList[removeConfirmIndex].memberUserId }).subscribe(async data => {
            let responseData = await data.respData;
            console.log(responseData);
            // location.reload();
            this.assignTeamEditList('update');

        });
    }

  teamPermissions(teamIndex: number): FormArray {
    // console.log("teamIndex", teamIndex);
    return this.TeamForms().at(teamIndex).get("permission") as FormArray
  }

  newPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }


  async prmissionVal(empIndex = 0) {
    let per_val: any = [{
      id: null,
      folderName: 'financial',
      delete: false,
      view: false,
      upload: false,
    }, {
      id: null,
      folderName: 'casefolder',
      delete: false,
      view: false,
      upload: false,
    }];
    let permis: any = [];

    await per_val.map((chrd, index) => {
      // console.log('chrd ------------: ', chrd);
      // console.log('chrd ------------: ', empIndex);
      permis.push(chrd);
      this.teamPermissions(empIndex).push(this.newPermission());
    });

    (this.assignTeamForm.get('TeamForms') as FormArray).at(empIndex).get('permission').patchValue(permis);
  }

  addTeamPermission(empIndex: number) {
    console.log('this.newPermission**************', this.newPermission());
    this.teamPermissions(empIndex).push(this.newPermission());
  }


  removeTeamPermission(empIndex: number, permissionIndex: number) {
    this.teamPermissions(empIndex).removeAt(permissionIndex);
  }

  async editTeam(teamIndex) {
    console.log("editTeam", teamIndex);

    let tl: any = this.assignTeamList;
    this.assignTeamList = await tl.map((p, i) => {
      if (i == teamIndex) {
        p.disabled = false;
      }
      // else {
      //   p.disabled=true;
      // }
      return p;
    });
    // this.TeamForms().push( this.newTeams() );
  }

  /****:- listing edit section for team assignment-:****/

  TeamListingEditForms(): FormArray {
    return this.assignTeamEditForm.get("TeamEditForms") as FormArray
  }
  //in edit mode set form value
  newTeamListingEditForm(): FormGroup {
    return this.fb.group({
      memberUserId: ['', Validators.required],
      // clientUserId: ['', Validators.required],
      clientUserId: this.clientUserId,
      loginUserId: this.userId,
      caseNo: this.caseNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }

  teamListingEditFormPermissions(teamIndex: number): FormArray {
    return this.TeamListingEditForms().at(teamIndex).get("permission") as FormArray
  }
  //this is for modal permission
  newListingEditFormPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }

  assignTeamSubmitNew() {
        this.subAssignTeamForm = true;
        let formVal     = this.assignTeamForm.value;
        let formValEdit = this.assignTeamEditForm.value;
      
        // let mergeData: any = [...formVal.TeamForms, ...formValEdit.TeamEditForms,];
        let mergeData: any = [...this.assignTeamFormTemp, ...this.assignTeamEditFormTemp,];
        // let mergeData: any = formValEdit.TeamEditForms;
        
        /* if (this.assignTeamForm.invalid) {
          return;
        } */
        console.log(this.assignTeamFormTemp);
        console.log(this.assignTeamEditFormTemp);
        console.log('mergeData', mergeData);
        // console.log(this.assignTeamFormTemp);
        // return false;

        this.btnSppiner = true;

        this.loader = true;
        
        this.CaseService.saveMemberPermission(JSON.stringify(mergeData)).subscribe((data) => {
          if (data.responseMessage == 'success') {
             this.btnDisabled = true;
            //######### Re Init the add form After final submit Start Here ############
            this.assignTeamForm = this.fb.group({
                TeamForms: this.fb.array([])
            })

            this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;

            this.memberPermission()

            this.TeamForms().push(this.newTeams());
            this.prmissionVal(0);
            //######### Re Init the add form After final submit ends Here ############

            this.assignTeamEditForm = this.fb.group({
              TeamEditForms: this.fb.array([]),
            })

            this.assignTeamEditFormTemp = this.assignTeamEditForm.value.TeamEditForms;

            this.assignTeamEditList('update');
            this.mes1       = '';
            this.showMsg    = true;
            this.mes        = 'Team assigned successfully.';
            this.toastr.success(this.mes, '');
            this.btnSppiner = false;

            // this.loader     = false;
          }
        }, (err: any) => {
          console.log(JSON.parse(err._body));
          let errResponse = JSON.parse(err._body);
          this.showMsg    = true;
          this.mes        = '';
          this.mes1       = errResponse.responseMessage;
          this.toastr.error(this.mes1, '');
          this.btnSppiner = false;
          this.loader     = false;
        }, () => {
          // this.modalDisplay = !this.modalDisplay;
        });

        setTimeout(() => {
          this.mes  ='';
          this.mes1 = "";
        },10000);

  }

  async assignTeamEditList(loadType = "init") {
        // console.log("I am into role");
        const arr = <FormArray>this.assignTeamEditForm.controls.TeamEditForms;
        arr.controls = [];
        let case_no = this.caseNo = localStorage.getItem('currentCaseNo');
        console.log("case_no", case_no);
        if(case_no !== undefined){
              this.CaseService.memberList({ caseNo: case_no }).subscribe(async data => {
                let list = await data.respData;
                // console.log("I am in List", list);
                if (list.length > 0) {
      
                    this.editTeamIndex = 0;
                    await list.map((currentValue, index, arr) => {
                      currentValue.disabled = true;
      
                      this.TeamListingEditForms().push(this.newTeamListingEditForm());
      
                      this.onselectmemberType(currentValue.memberUserType, index, 'E');
                      currentValue.permission.map(async () => {
                        await this.teamListingEditFormPermissions(index).push(this.newListingEditFormPermission())
                      });
                      // console.log(currentValue);
      
                      this.editTeamIndex++;
      
                      return currentValue;
                    });
      
      
                    setTimeout(() => {
                      
                      if(loadType === 'update') {
                            console.log('UPDATE', list);
                            
                            (this.assignTeamEditForm.get('TeamEditForms') as FormArray).patchValue(list,{onlySelf:true,emitEvent:true});
                            this.assignTeamList  = list;
                            console.log(this.assignTeamList)
                            this.loader     = false;
                            // $('.nav li.active').next('li').removeClass('disabled');
                            // $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");
                            // $('.nav li.active').next('li').find('a').attr("href", "#tab_4");
                            // $( '.nav-tabs a[href="#tab_4"]' ).tab( 'show' );
      
                      } else {
                            console.log('ADD', list);
                            (this.assignTeamEditForm.get('TeamEditForms') as FormArray).patchValue(list,{onlySelf:true,emitEvent:true});
                            this.assignTeamList  = list;
                            console.log("assignTeamList", this.assignTeamList);

                            // $('.nav li.active').next('li').removeClass('disabled');
                            // $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");
                            // $('.nav li.active').next('li').find('a').attr("href", "#tab_4");
                            // $( '.nav-tabs a[href="#tab_4"]' ).tab( 'show' );
      
                          
                      }
                      
                      
                      
                    }, 1000);
                }
              });
        }
        

        // console.log(this.TeamListingEditForms().controls);
  }


  rerender(): void {
    console.log("I am inside rander");
  }


  teamTriggerRender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again

      this.dtTrigger.next();
    });
  }
  clickmemberName(memberId, teamIndex, type ='A') {
      this.btnDisabled = true;
      $('#infoType' + teamIndex).html('');
      $('#infoID' + teamIndex).html('');
      $('#infoTypeEdit' + teamIndex).html('');
      $('#infoIDEdit' + teamIndex).html('');

      if(type == "A"){

            // // console.log("clickmemberId::", memberId);
            // let memberNewListA: any[] = [];
            // memberNewListA = this.memberNameListA[teamIndex].filter((item) => item.userId == memberId);
            // // console.log('memberNewList ====== ', memberNewListA);
            // console.log('memberNewList ====== ', memberNewListA[teamIndex]);
            // this.newTeams().patchValue({
            //   memberUserName: memberNewListA[teamIndex].firstName+' '+memberNewListA[teamIndex].lastName,
            // });

            // this.memberNameListA[teamIndex].memberUserName = memberNewListA[teamIndex].firstName+' '+memberNewListA[teamIndex].lastName;
            // console.log('this.newTeams() ====== ', this.newTeams().value);
            // console.log(teamIndex);
            // console.log(this.assignTeamForm.value);
            // console.log(this.memberNameListA);

            console.log(memberId);
            console.log(this.memberNameListA);
            console.log(teamIndex);
            // $('#clickmemberName_'+teamIndex).val();
            for(let i=0;i<this.memberNameListA[teamIndex].length;i++){
              if(memberId == this.memberNameListA[teamIndex][i].userId) {
                this.assignTeamForm.value.TeamForms[teamIndex].memberUserName = this.memberNameListA[teamIndex][i].firstName+' '+this.memberNameListA[teamIndex][i].lastName;
                console.log(this.assignTeamForm.value);
                this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;
              }
            }
            /* this.assignTeamForm.value.TeamForms[teamIndex].memberUserName = this.memberNameListA[teamIndex].firstName+' '+this.memberNameListA[teamIndex].lastName;
            console.log(this.assignTeamForm.value);
            this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms; */

      }else{
          // console.log("clickmemberId::", memberId);
          // let memberNewListE: any[] = [];
          // memberNewListE = this.memberNameListE[teamIndex].filter((item) => item.userId == memberId);
          // // console.log('memberNewList ====== ', memberNewList[0].firstName);
          // console.log('memberNewList ====== ', memberNewListE[teamIndex]);
          // if( typeof memberNewListE[teamIndex] != 'undefined'){
          //   this.newTeams().patchValue({
          //     memberUserName: memberNewListE[teamIndex].firstName+' '+memberNewListE[teamIndex].lastName,
          //   });

          //   this.memberNameListE[teamIndex].memberUserName = memberNewListE[teamIndex].firstName+' '+memberNewListE[teamIndex].lastName;
          // }
          // console.log('this.newTeams() ====== ', this.newTeams().value);
          // console.log('this.memberNameListE ====== ', this.memberNameListE);

          for(let i=0;i<this.memberNameListE[teamIndex].length;i++){
            if(memberId == this.memberNameListE[teamIndex][i].userId) {
              this.assignTeamForm.value.TeamForms[teamIndex].memberUserName = this.memberNameListE[teamIndex][i].firstName+' '+this.memberNameListE[teamIndex][i].lastName;
              console.log(this.assignTeamForm.value);
              this.assignTeamFormTemp = this.assignTeamForm.value.TeamForms;
            }
          }
            /* this.assignTeamEditForm.value.TeamEditForms[teamIndex].memberUserName = this.memberNameListE[teamIndex].firstName+' '+this.memberNameListE[teamIndex].lastName;
            console.log(this.assignTeamEditForm.value);
            this.assignTeamEditFormTemp = this.assignTeamEditForm.value.TeamEditForms; */
      }
  }
  //assign team edit end

  async memberPermission() {

    let case_no = this.caseNo;
    this.CaseService.memberPermissionList({ caseNo: case_no, memberUserId: this.userId }).subscribe(async data => {

      // console.log('assignpermissionList', data.respData);
      let permissionlist = await data.respData;
      if (permissionlist.length > 0) {

        await permissionlist.map((currentValue, index) => {
          // console.log("currentValue::", currentValue);

        });

        


      }

      // this.loader     = false;

    }
    );
  }
  
  onselectmemberType(membertype,teamIndex, type) {
    this.btnDisabled = true;
    $('#infoType' + teamIndex).html('');
    $('#infoID' + teamIndex).html('');
    $('#infoTypeEdit' + teamIndex).html('');
    $('#infoIDEdit' + teamIndex).html('');
      // console.log("Test",type);
      if(type == "A"){
        // console.log('ADD DD');
                  // console.log('membertype', membertype);
              this.CaseService.getMemberListbyType(membertype, this.userId).
              subscribe(
                async data => {
                  this.memberNameListA[teamIndex] = await data.respData;
                  console.log('TestA', this.memberNameListA);
                }
              );
      }else{
        // console.log('EDIT');
              // console.log('membertype', membertype);
              $('#editmemberUserId'+ teamIndex).val('');
              this.CaseService.getMemberListbyType(membertype, this.userId).
              subscribe(
                async data => {
                  this.memberNameListE[teamIndex] = await data.respData;
                  console.log('TestE', this.memberNameListE);
                  // console.log('getMemberData', this.memberNameListE[teamIndex]);
                }
              );
      }
  }


  //############# Get Member type start here ##############
  getTeamMemberType( userType : string) {
    this.legalService.getTeamMemberType(userType).
      subscribe(
        data => {
          this.memberTypelist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamMemberType comp res--" + JSON.stringify(this.memberTypelist)); 
        }
      );
  }
  //############# Get Member type ends here ##############


}
