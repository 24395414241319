<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">receipt_long</i> </div>
          <h3 class="box-title main-header-top">Invoice Generation</h3>
        </div>

        <div class="box-body">
          <div class="box">
            
            <div class="box-header with-border">
                <h5 *ngIf="viewMode2">{{clientName}}</h5>
                <!-- <h3 class="box-title card-text">Client & Case Specific :</h3>
                <div class="box-tools pull-right">
                  <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                </div> -->
            </div> 
            <div class="box-body" *ngIf="viewMode" > 
              <div class="form-group">
              <a href="/invoiceList" class="btn btn-primary pull-right Role"> 
                <span> &nbsp;View Invoice List</span> </a>
                </div>

                <div class="form-group">
                    <label>Select Client</label>
                    <select class="form-control"  (change)="onSelectClient($event.target.value)" >
                    <option [value]="">--Select--</option>
                    <option *ngFor="let data of mes" [value]="data.userId">{{data.clientShowName}}</option> 
                    </select>
                </div>
                <div class="form-group">
                  <label>Select Case</label>
                  <select class="form-control" (change)="onSelectCase($event.target.value)">
                  <option [value]="">--Select--</option>
                  <option *ngFor="let data of case" [value]="data.caseNo" >{{data.caseTitle}}</option> 
                  </select>
              </div>
            </div>
            <div class="box-body" *ngIf="viewMode1">
              <form [formGroup]="myform" (ngSubmit)="scheduleofFeesSubmit()" *ngIf="hasCheckedList">
                <div class="box"  class="">
                    <div class="box-body">
                        <div class="table-responsive form-group">  
                            <h4 *ngIf="sfa">Select services for the invoice</h4>        
                            <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th width="300">ID </th>
                                    <th width="300">Category </th>
                                    <th width="305">Particulars Of Services( from system )</th>
                                    <th width="305">Invoice Particulars</th>
                                    <th width="100">Amount <br> (£)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fees of listLength let i=index">   
                                    <td><input type="checkbox" (click)="feesId(i)" ></td>
                                    <td><input type="text" class="form-control id_{{i}}" formControlName="id_{{i}}"  readonly></td>
                                    <td><input type="text" class="form-control tribunals_{{i}}" formControlName="tribunals_{{i}}"  readonly></td>
                                    <td><input type="text" class="form-control particularsOfServices_{{i}}" formControlName="particularsOfServices_{{i}}" [readonly]="checkRevise(fees.agreedByClient)"> </td>
                                    <td><input type="text" class="form-control particularNamePrint_{{i}}" formControlName="particularNamePrint_{{i}}"> </td>
                                    <td><input type="text" class="form-control amount_{{i}}" formControlName="amount_{{i}}" > </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>  
                    </div>
                    
                    <div class="box-footer">
                        <!-- <button class="btn btn-primary pull-left"> Back </button> -->
                        <!-- data-toggle="modal" data-target="#Preview" -->
                         <button type="submit" class="btn btn-primary pull-left" data-toggle="modal" data-target="#Preview"  [disabled]="!stc" >Preview Invoice</button>
                        <!--<button type="button" class="btn btn-primary pull-right" [disabled]="gfI" (click)="generateFinalInvoice()">Generate final invoice and send to client.</button> -->
                        <!-- <button type="button" class="btn btn-primary pull-right" (click)="generateInvoice()" [disabled]="!stc" >Generate Invoice and Send To Client</button> -->
                    </div>
                </div>
                </form>
            </div>  
          </div>
        </div>
        <!-- <div class="box-body" *ngIf="showMsg">
            <div class="box">
              <div class="box-body">
                <div>
                  <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="message!=''">&nbsp; {{message}}</span>
                  <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
                </div>
              </div>
            </div>
            
          </div> -->
        
      </div>
    </div>
</div>

<!--Modal: Name-->
<div class="modal fade" id="Preview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 90%;">

    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body mb-0 p-0">

        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          
          <iframe class="embed-responsive-item" [src]="src" allowfullscreen></iframe>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">

        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="generateFinalInvoice()">Generate final invoice and send to client.</button>

        <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Close</button>

      </div>

    </div>
    <!--/.Content-->

  </div>
</div>


<!-- <div class="modal fade"  [ngClass]="{'in' : showMsg}" [style.display]="showMsg ? 'block' : 'none'" id="successModal" role="dialog" >
  <div class="modal-dialog">
    
     <div class="modal-content">
        <div class="modal-header">
           <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
           <p>Invoice generated successfully</p>
        </div>
        <div class="modal-footer">
           <button type="button" class="btn btn-default" data-dismiss="modal" (click)="close()">Ok
             
           </button>
           
        </div>
     </div>
  </div>
</div> -->