<div class="content-wrapper"> 
  <section class="content">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">group</i></div>
        <h3 class="box-title main-header-top">Invite {{pageTitle}}</h3>
      </div>
      <div class="box-body">
          <div class="box bottom-height">
            <div class="box-body">
              <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                      <button type="button" class="btn btn-primary pull-right Role" (click)="openModel()"  data-toggle="modal" data-target="#myModal" *ngIf="saveMode" >Invite a New {{pageTitle}}</button>
                  </div>
              </div>
              <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead class="thead-dark">
                              <tr>
                                <th>Designation</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>E-mail</th>
                                <th>Phone</th>
                                <!-- <th align="center" width="130" >Action</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of userlist"> 
                                <td> {{item.orgDesignation}} </td>
                                <td> {{item.firstName}}</td>
                                <td> {{item.lastName}}</td>
                                <td> {{item.emailId}} </td>
                                <td> {{item.phoneNo}} </td>
                                <!-- <td>
                                  <div class="pull-right"> 
                                    <a [routerLink]="['/teamprofile',item.userId,item.userType,'view']" routerLinkActive="router-link-active" >
                                    <button type="button" class="btn btn-primary"><i class="fa fa-eye"
                                        aria-hidden="true"></i></button>
                                    </a>
                                    <a [routerLink]="['/teamprofile',item.userId,item.userType]" routerLinkActive="router-link-active" >
                                    <button type="button" class="btn btn-success"><i class="fa fa-pencil-square-o"
                                        aria-hidden="true"></i></button>
                                    </a>
                                  <button type="button" class="btn btn-danger"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                  </div>
                                </td> -->
                              </tr>
                              
                              
                            </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <!-- <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <button type="button" class="btn btn-primary">Back</button>
                  </div>
              </div> -->
              
            </div>
          </div>
        </div>
  
  
  
  </section>
  </div>
  <!-- Modal -->
  <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
    
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Invite a New {{pageTitle}}</h4>
          </div>
          <div class="modal-body">
            <form [formGroup]="myform" *ngIf="myform" (ngSubmit)="teamRegistration();" >
              <!-- <div class="row"> -->
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Designation<span class="text-danger">*</span></label>
                      <select class="form-control" formControlName="orgDesignation" [(ngModel)]="orgDesignation" required>
                        <option value="" selected>--Select--</option>
                        <!-- <option value="" >Senior Partner</option>
                        <option value="" >Associate Partner</option> -->
                        <option *ngFor="let designation of DesignnationList " [value]="designation.value">{{designation.name}}</option> 
                        <!-- <option *ngFor="let membertype of memberTypelist " [value]="membertype.teamUserType">{{membertype.teamUserType}}</option> -->
                      </select>
                      <small class="text-danger" *ngIf="myform.get('orgDesignation').invalid && submitted">Please enter designation</small>
                    </div>
                  </div>
  
                  
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>First Name<span class="text-danger">*</span></label>
                      <input type="text" class="form-control"  formControlName="firstname" [(ngModel)]="firstname" placeholder="Enter ..." required pattern="^[a-zA-Z\s]+$">
                    </div>
                    <small class="text-danger"
                      *ngIf="myform.get('firstname').invalid && submitted">Please enter first name</small>
                  </div>	
              </div>
              <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Last Name<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" formControlName="lastname" [(ngModel)]="lastname" placeholder="Enter ..." required pattern="^[a-zA-Z\s]+$">
                    </div>
                    <small class="text-danger"
                      *ngIf="myform.get('lastname').invalid && submitted">Please enter last name</small>
                  </div>	
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>E-mail address<span class="text-danger">*</span></label>
                      <input type="email" class="form-control" formControlName="mailid" [(ngModel)]="mailid" placeholder="Enter ..." pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                    </div>
                    <small class="text-danger"
                      *ngIf="myform.get('mailid').invalid && submitted">Please enter email</small>
                  </div>	
  
              </div>
              <div class="row">
  
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Phone<span class="text-danger">*</span></label>
                      <!-- <div style="display: flex;">
                        <app-country-code-dropdown
                          [countryCode]="countryCodeMobilePh"
                          (onCountryChange)="onCountryCodeMobilePhChange($event)"
                        ></app-country-code-dropdown>
                        <input type="tel" class="form-control" formControlName="phoneno" [(ngModel)]="phoneno" placeholder="Enter ..." pattern="^(?:\(?([0-9]{3})\)?[-.●]?)?([0-9]{3})[-.●]?([0-9]{4})$"  required>
                      </div> -->
                      <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="phoneno" required>
                              </ngx-intl-tel-input>
                    </div>
                    <small class="text-danger"
                      *ngIf="myform.get('phoneno').invalid && submitted">Please enter phone number</small>
                  </div>		
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <button type="submit" class="btn btn-primary" >Submit</button>
                  </div>		
  
                </div>
                <!-- </div> -->
              </form>
          </div>
          <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">
  
            <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp; This member is invited to your team.</span>
            <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
    
    
          </div> -->
    
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button>
          </div>
        </div>
    
      </div>
    </div>