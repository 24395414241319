import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {

  //loadAPI: Promise<any>;

  constructor(private router: Router,) { 
    /* this.loadAPI = new Promise((resolve) => {
        this.loadScript();
        resolve(true);
    }); */
  }

  /* public loadScript() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["//netdna.bootstrapcdn.com/bootstrap/3.1.0/js/bootstrap.min.js"];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
} */

  ngOnInit(): void {
    this.loadScript('//code.jquery.com/jquery-1.11.1.min.js');
    this.loadScript('//netdna.bootstrapcdn.com/bootstrap/3.1.0/js/bootstrap.min.js');
    this.loadScript('https://code.jquery.com/jquery-3.3.1.slim.min.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/aos/aos.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/bootstrap/js/bootstrap.bundle.min.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/glightbox/js/glightbox.min.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/isotope-layout/isotope.pkgd.min.js');
    //this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/php-email-form/validate.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/purecounter/purecounter.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/swiper/swiper-bundle.min.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/waypoints/noframework.waypoints.js');
    this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js');
    this.loadScript('https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/js/main-web.js');
    this.loadScript('//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    
    $.noConflict();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    //alert(script);
  }

  public navigateToSection(section: string) {
      window.location.hash = '';
      window.location.hash = section;
  }

  openModal() {
    console.log("Open Modal Clicked");
   // $("#LutfurModal").modal("show");
  }

  

}
