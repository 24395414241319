<div class="content-wrapper">
   <div class="content">
      <div class="bottom-height">
         <div class="box-header with-border">
            <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-18 md-dark">add_task</i> </div>
            <h3 class="box-title main-header-top">Task List</h3>
         </div>
         <div class="box-body">
            <div class="box">

               <div class="box-body TaskCreationbox ">
                  <div class="row">
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">

                        <div *ngIf="taskpermission!='view'">
                           
                           <a class="btn btn-primary pull-right Role" href="/addtask"><i class="material-icons md-14 md-light ">add</i></a>
                        </div>

                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="table-responsive">
                           <!-- <table class="table tagsbox table-striped">
                              <thead class="thead-dark">
                                 <tr>
                                    <th>Sl No.</th>
                                    <th>Task Brief</th>
                                    <th>Case Title</th>
                                    <th align="center" width="130">Action</th>
                                 </tr>
                              </thead>
                              <tbody> -->
                                 <ul *ngFor="let item of tasklist let indx=index;" class="team-view task">
                                    <!-- <td width="70" align="center">{{indx + 1}}</td> -->
                                    <!-- <td><input type="checkbox"></td> -->
                                    <li>
                                       <i class="fa-solid fa-list-check"></i> Task Brief : {{item.taskBrief}} <br>
                                       <!-- <p> Id nulla nulla proident deserunt deserunt proident in quis. Cillum reprehenderit labore id anim laborum. </p>
                                      <div class="tags"><button type="button"><i class="fa fa-dot-circle-o  taskbox" aria-hidden="true"></i> {{item.taskBrief}} </button></div> -->
                                       <div class="tags"><button type="button"><i class="fa fa-dot-circle-o pendingbox"
                                                aria-hidden="true"></i> {{item.taskStatus}} </button></div>
                                       <div class="tags" *ngIf="item.causeTitle"><button type="button"><i
                                                class="fa fa-dot-circle-o casebox" aria-hidden="true"></i>
                                             {{item.taskPriority}}</button></div>
                                    </li>
                                    <li>
                                       <i class="fa-solid fa-briefcase"></i> Case Title : {{item.causeTitle}}
                                    </li>
                                    <!-- <td> <button type="button" class="btn btn-default exclamationbox">!</button></td>
                                   <td> <button type="button" class="btn btn-default starbox"> <i class="fa fa-star" aria-hidden="true"></i> </button> </td>
                                   <td> <button type="button" class="btn btn-default ellipsisbox" data-toggle="modal" data-target="#myModal"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button></td> -->
                                    <li width="100" class="act-btn">
                                       <a [routerLink]="['/addtask','view',item.id]"
                                          routerLinkActive="router-link-active">
                                          <button type="button" class="btn  btn-edit  btn-primary"><i class="material-icons md-14 md-dark ">visibility</i></button>
                                       </a>
                                       <a [routerLink]="['/addtask','edit',item.id]"
                                          routerLinkActive="router-link-active">
                                          <button type="button" class="btn btn-edit btn-success"><i class="material-icons md-14 md-dark ">edit</i></button>
                                       </a>
                                    </li>
                                 </ul>
                              <!-- </tbody>
                           </table> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="box-footer">
                  <a [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                     <button class="btn pull-left btn-grey Back1"> Back </button>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
   <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Task Creation</h4>
         </div>
         <div class="modal-body">
            <label> <input type="checkbox">
               Mark as done </label>
            <label><button type="button" class="btn btn-default exclamationbox2">!</button>
               Mark as Important </label>
            <label> <button type="button" class="btn btn-default starbox2"> <i class="fa fa-star-o"
                     aria-hidden="true"></i> </button>
               Remove Star </label>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<div id="myModal2" class="modal fade" role="dialog">
   <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Task Creation</h4>
         </div>
         <div class="modal-body">
            <label> <input type="checkbox">
               Mark as done </label>
            <label><button type="button" class="btn btn-default exclamationbox2">!</button>
               Remove Important </label>
            <label> <button type="button" class="btn btn-default starbox2"> <i class="fa fa-star-o"
                     aria-hidden="true"></i> </button>
               Add Star </label>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<div id="myModal3" class="modal fade" role="dialog">
   <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Task Creation</h4>
         </div>
         <div class="modal-body">
            <label> <input type="checkbox">
               Mark as done </label>
            <label><button type="button" class="btn btn-default exclamationbox2">!</button>
               Remove Important </label>
            <label> <button type="button" class="btn btn-default starbox2"> <i class="fa fa-star-o"
                     aria-hidden="true"></i> </button>
               Remove Star </label>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<div id="myModal4" class="modal fade" role="dialog">
   <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Task Creation</h4>
         </div>
         <div class="modal-body">
            <label> <input type="checkbox">
               Mark as done </label>
            <label><button type="button" class="btn btn-default exclamationbox2">!</button>
               Mark as Important </label>
            <label> <button type="button" class="btn btn-default starbox2"> <i class="fa fa-star-o"
                     aria-hidden="true"></i> </button>
               Add Star </label>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
</div>