import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
//import { config } from './../config';
//import { config } from '../';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject } from 'rxjs';
//import {AuthGuard} from './auth.guard';

@Injectable({
  providedIn: 'root'
})
export class LegalbellService {

  mainUtterRes: any;
  roleData: any;
  userData: any;
  addressData : any;
  timeKeeperData: any;
  invoiceListData: any ;
  //Save all data into data base
  saveResponse: any;
  resultShow = 'Executing function...';
  private data = new BehaviorSubject('');
    currentData = this.data.asObservable()

  constructor(private http: Http) { }


  //get address list 
  getAddressDetails(code) {
    console.log(code);
    return this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/'+code+'/?api_key=ak_kue1uy5o5V41YRdC2Xd5yGbzLBzyJ', {
    }).
      pipe(map(res => { res.json(), this.addressData = res }));
      
  }

  saveUserRoleData(payload: any) {
    console.log("saveAllData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'adminService/addUser',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }
  saveAllData(payload: any) {
   // console.log("saveAllData service", payload);
  
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.put(environment.api + 'user/updateUserProfile',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }
  saveOrganizationData(payload: any) {
    console.log("saveAllData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.put(environment.api + 'user/updateOrgProfile',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }

  //get team list
  getTeamList(userType : string,superUserId : string) {

    return this.http.get(environment.api + 'user/getTeam?superuserType='+userType+'&superUserId='+superUserId, {

    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get getInvoicePaymentDetails
  getInvoicePaymentDetails(invoicePaymentId: string) {
    return this.http.get(environment.api + 'finance/getInvoicePaymentDetailsById/'+invoicePaymentId, {}).
      pipe(map(res => { res.json(), this.userData = res, console.log(this.userData) }));
      
  }

  //get case list of a user
  getInvoiceListByCaseId(caseId: string) {

    return this.http.get(environment.api + 'finance/getInvoiceListByCaseId/'+caseId, {}
    ).pipe(map(res => { res.json(), this.userData = res }));
  }

  //get getSingleInvoice
  getSingleInvoice(invoiceId: string) {
    return this.http.get(environment.api + 'finance/getInvoiceById/'+invoiceId, {}).
      pipe(map(res => { res.json(), this.userData = res }));
  }

   //get invoice Contract list
   getinvoiceContractList(superUserId) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/getContractInvoiceListByUserId/'+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //search contract invoice list by date range
  searchContractInvoiceListByDate(superUserId, fromDate, toDate) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/searchContractInvoiceListByUserIdAndDR/'+superUserId+'/'+fromDate+'/'+toDate, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //get invoice list
  getinvoiceListList(superUserId) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/getInvoiceListByUserId/'+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //deactivate invoice 
  deactivateInvoiceByInvoiceNo(superUserId, invoiceNo) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/deactivateInvoiceById/'+superUserId+'/'+invoiceNo, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //search invoice list by date range
  searchInvoiceListByDate(superUserId, fromDate, toDate) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/searchInvoiceListByUserIdAndDR/'+superUserId+'/'+fromDate+'/'+toDate, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  

  //search invoice list by invoiceParam
  searchInvoiceList(superUserId, invoiceParam) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/searchInvoiceListByUserIdAndParam/'+superUserId+'/'+invoiceParam, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  saveInvoicePayment(body): Observable<any> {
    
    //let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
    const url = environment.api + 'finance/saveInvoicePayment';
    console.log("request body in invoice payment service:", body);
    // return false;
    return this.http.post(url, body);
  }

  //get invoice list
  getinvoicePaymentList(superUserId) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/getInvoicePaymentListByUserId/'+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }


  //get invoice list by DR
  searchInvoicePaymentListByDR(superUserId, fromDate, toDate) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/searchInvoicePaymentListByUserIdAndDR/'+superUserId+'/'+fromDate+'/'+toDate, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //get invoice list
  searchInvoicePaymentList(superUserId, invoiceParam) {
    console.log("Hit time keeper angular")
    return this.http.get(environment.api + 'finance/searchInvoicePaymentListByUserIdAndInvoiceParam/'+superUserId+'/'+invoiceParam, { 

    }).
      pipe(map(res => { res.json(), this.invoiceListData = res }));
  }

  //get time keeper list
  getTimeKeeperList(superUserId) {
    console.log("Hit time keep[er angular")
    return this.http.get(environment.api + 'finance/getTimeKeeperList/'+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.timeKeeperData = res }));
  }

  //get time keeper list
  getTimeKeeperListByCase(superUserId, caseRef) {
    console.log("Hit fetch by case sevice :::: "+superUserId+"::::"+caseRef)
    return this.http.get(environment.api + 'finance/getTimeKeeperListByCase/'+superUserId+'/'+caseRef, { 

    }).
      pipe(map(res => { res.json(), this.timeKeeperData = res }));
  }

  
  //get user list
  getUserList(superUserId) {

    return this.http.get(environment.api + 'user/getAllClients?superUserId='+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  //get user list roster
  getUserListRoster(superUserId) {

    return this.http.get(environment.api + 'user/getAllClientsInRoster?superUserId='+superUserId, { 

    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get case list of a user
  getCaseList(userId: string) {

    return this.http.post(environment.api + 'user/caseListOfUser', {
      "userId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  authCheck(body: any): Observable<any> {

    const url = environment.api + 'adminService/checkUserExistance';

    return this.http.post(url, body);

  }

  deactivateRole(roleId: string) {
    let userEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email.toLowerCase() : null;
    return this.http.post(environment.api + 'adminService/deactivateRole', {
      //"orgId":environment.orgId,
      "updatedBy": userEmail,
      "roleId": roleId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  deactivateUser(userId: string) {
    let userEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email.toLowerCase() : null;
    return this.http.post(environment.api + 'adminService/deactivateUser', {
      //"orgId":environment.orgId,
      "userEmail": userEmail,
      "userId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  deactivateAnnouncement(announcementID: string) {
    let userEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email.toLowerCase() : null;
    return this.http.post(environment.api + 'announcement/deactivate', {
      "deactivatedBy": userEmail,
      "announcementID": announcementID
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  postAllreminderData(body: any): Observable<any> {

    const url = environment.api + 'qaService/updateReminder';
    //const url =  "http://3.84.157.124:8087/qaService/updateReminder";
    return this.http.post(url, body);
  }
  activeAnnouncement(announcementID: string) {
    let userEmail = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).email.toLowerCase() : null;
    return this.http.post(environment.api + 'announcement/reactivate', {
      "reactivatedBy": userEmail,
      "announcementID": announcementID
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  
  setPassword(body1): Observable<any> {
    console.log("body1 in service:", body1);
    const url = environment.api + 'user/updatePassword';

    let body = {
      loginPwd: body1.userPwd,
      token: body1.userToken,
      loginUserName: body1.userName,
      callType: body1.callType
    }
    return this.http.post(url, body);
  }
  resetPassword(body1): Observable<any> {
    console.log("body1 in service:", body1);
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    const url = environment.api + 'user/resetPassword';
    let body = {
      //"orgId":environment.orgId,
      userId: userData.userIdlogin,
      loginPwdNew: body1.userPwd,
      loginPwd: body1.userPwdOld
    }
    return this.http.post(url, body);
  }
  login(body1): Observable<any> {
    console.log("login in service::", body1);
    const url = environment.api + 'user/userLogIn';

    return this.http.post(url, body1);
  }

  forgetPassword(mailid): Observable<any> {
    console.log("body1 in service:", mailid);
    const url = environment.api + 'user/forgetPassword';

    let body = {
      //"orgId":environment.orgId,
      emailId: mailid
    }
    return this.http.post(url, body);
  }

  registration(emailId, phoneNo,title, firstName,middlename,surname, usertype,callType,superUserType,companyname, countryCode=environment.defaultCountryCode): Observable<any> {
    console.log("body1 in registration service:", usertype);
    console.log("superUserType body1 in registration service:", superUserType);
    let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;

    const url = environment.api + 'user/registration';

    let body = {
      "emailId": emailId,
      "phoneNo": phoneNo,
      "userTitle" : title,
      "firstName": firstName,
      "middleName": middlename,
      "lastName" : surname,
      "userType": usertype,
      "loginUserId" : userId,
      "callType" : callType,
      "superUserType": superUserType,
      "corporateClientCompanyName" : companyname,
      "countryCode": countryCode

    }
    console.log("body1 in registration service:", body);
    return this.http.post(url, body);
  }

   registrationNew(body): Observable<any> {
    
    let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
    const url = environment.api + 'user/registration';
   
    
    console.log("body1 in registration service:", body);
    // return false;
    return this.http.post(url, body);
  }

  saveTimeKeeper(body): Observable<any> {
    
    //let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
    const url = environment.api + 'finance/saveTimeKeeper';
    console.log("request body in time keeper service:", body);
    // return false;
    return this.http.post(url, body);
  }

  editTimeKeeper(body): Observable<any> {
    
    //let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
    const url = environment.api + 'finance/updateTimeKeeper';
    console.log("request body in time keeper service:", body);
    // return false;
    return this.http.post(url, body);
  }

  //get getSingleAdress
  getSingleTimeKeeper(timeKeeperId: string) {
    return this.http.get(environment.api + 'finance/getTimeKeeperById/'+timeKeeperId, {}).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get getProfileData
  getProfileData(userId: string) {
    return this.http.post(environment.api + 'user/getUser', {
      "userId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  getOrganizationProfileData(orgId: string) {
    console.log(orgId);
    return this.http.post(environment.api + 'user/getOrgProfile', {
      "orgId": orgId,
      "loginUserId" :localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }



  postLawyerFile(fileToUpload: File, jsonData): Observable<boolean> {

    var headers = new Headers();
 
    // headers.append( 'x-tag', '1' );
    const formData: FormData = new FormData();
    formData.append('uploadFile', fileToUpload, fileToUpload.name);
    formData.append('userId', jsonData.userId);
    formData.append('docType', jsonData.docType);
     console.log(formData);
    return this.http.post(environment.api + 'user/uploadUserInfo', formData, {
      headers: headers
    }).pipe(map(res => {
      console.log("Serv response--" + res);
      return res.json();
    }));
  }
  
  trademarkFile(fileToUpload: File, jsonData): Observable<boolean> {

    var headers = new Headers();

    // headers.append( 'x-tag', '1' );
    const formData: FormData = new FormData();
    formData.append('uploadFile', fileToUpload, fileToUpload.name);
    formData.append('orgId', jsonData.orgId);
    formData.append('docType', jsonData.docType);
    console.log(formData);
    return this.http.post(environment.api + 'user/uploadOrgInfo', formData, {
      headers: headers
    }).pipe(map(res => {
      console.log("Serv response--" + res);
      return res.json();
    }));
  }
  saveAddressData(payload: any) {
    console.log("saveAddressData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'user/saveAddress',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }

  //get getProfileData
  getProfileAdress(userId: string) {
    return this.http.post(environment.api + 'user/addressList', {
      "userId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get getSingleAdress
  getSingleAdress(adressid: string) {
    return this.http.post(environment.api + 'user/getSingleAddress', {
      "id": adressid
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  lawyerPracticeAreas() {
    return this.http.get(environment.api + 'user/getPracticeAreas', {}).pipe(map(res => {
      let resData = res.json();
      return resData;
    }));
  }

  saveTaskData(payload: any) {
    console.log("saveTaskData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'task/saveTask',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }

  //======================= SD Start ====================

  //get Task list based on different criteria
  getTaskList(userId: string) {   //userId: string
    return this.http.post(environment.api + 'task/getTasksByCriteria', {
      "toUserId": userId,
      "createdBy": userId

    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //get Task list for admin
  getTaskListAll() {   //userId: string
    return this.http.post(environment.api + 'task/getTasksByCriteria', {

    }).pipe(map(res => { res.json(), this.userData = res }));
  }

  //get getTaskData
  getTaskData(id: number) {
    return this.http.post(environment.api + 'task/getSingleTask', {
      "id": id
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get Task list for admin
  getTaskTrail(id: number) {   //userId: string
    return this.http.post(environment.api + 'task/getTasksByCriteria', {
      "relatedTaskId": id
    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //======================= SD End ======================

  //get Permitted Case list 
  getPermittedCaseList(loginuserId) {  
    console.log("userid in caselist 3rd ::",loginuserId);
    return this.http.get(environment.api + 'case/permittedCaseList?loginUserId='+loginuserId, {
      

    }).pipe(map(res => { res.json(), this.userData = res }));
  }

  //get Case list 
  getAllCaseList(loginuserId) {  
    console.log("userid in caselist 2nd ::",loginuserId);
    return this.http.get(environment.api + 'case/totalCaseList?loginUserId='+loginuserId, {
      

    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //get contract list 
  getAllContractList(logInuserId: string) {  
    return this.http.get(environment.api + 'contract/getContractListOfLoginUser?loginUserId='+logInuserId, {
      

    }).pipe(map(res => { res.json(), this.userData = res }));
  }

  //get Task list By Criteria Start Here 
  getTasksByCriteria(id: string) {   //userId: string
    return this.http.post(environment.api + 'task/getTasksByCriteria', {
      "toUserId": null, 
      "createdBy": id
    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //get Task list By Criteria Ends Here 
  
  serviceMaster( payload: any ) {
    console.log( "serviceMaster", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'master/getServicesScheduleOfFees',
    json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  clientExpenseMaster( payload: any ) {
    console.log( "clientExpenseMaster", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.get( environment.api+'master/getClientExpenses',
    
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  clientExpenseCaseSpecific( payload: any ) {
    console.log( "clientExpenseCaseSpecific", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'finance/getClientExpenses',
    json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  clientExpenseContractSpecific( payload: any ) {
    console.log( "clientExpenseContractSpecific", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/getContractClientExpenses',
    json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveScheduleOfFees(payload:any) {
    console.log("saveScheduleOfFees service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'master/saveScheduleOfFees',
      json, {
        headers: headers
      }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
       res.json(), this.saveResponse = res }));
  }

  saveClientExpensesMasterData(payload:any) {
    console.log("saveClientExpensesMasterData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'finance/saveClientExpensesDtl',
      json, {
        headers: headers
      }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
       res.json(), this.saveResponse = res }));
  }

  saveClientExpensesContractData(payload:any) {
    console.log("saveClientExpensesContractData service", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'contract/contractSaveClientExpensesDtl',
      json, {
        headers: headers
      }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
       res.json(), this.saveResponse = res }));
  }
   //get User list By Criteria Start Here 
   getUserListByCriteria(startDateStr: string, endDateStr: string) {
    return this.http.post(environment.api + 'user/getUserListByCriteria', {
      "startDateStr": startDateStr,
      "endDateStr":endDateStr
    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //get User list By Criteria Ends Here 

  //remove upload pic/pan/adhar starts

  removeDoc( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/removeUserInfo',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }



  //get Get Subscription Plan Start here
  getSubscriptionPlan(userType: string) {
    return this.http.post(environment.api + 'master/getSubscriptionPlan', {
      "userType": userType
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  //get Get Subscription Plan Ends  here

  getfinanceOverheadExpense( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'finance/getOverheadExpenses',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  // getMasterOverheadExpense() {
  //   var headers=new Headers();
  //   headers.append( 'Content-type', 'application/json' );
  //   return this.http.get( environment.api+'master/getOverheadExpenses',
  //   {
  //     headers: headers
  //   }
  //   ).pipe( map( res => {
  //     console.log( "Serv response--"+res );
  //     return res.json();
  //   } ) );
  // }
  getMasterOverheadExpense( payload: any ) {
    console.log( "getMasterOverheadExpense", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.get( environment.api+'master/getOverheadExpenses',
    
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveOverheadExpensesData(payload:any) {
    console.log("saveOverheadExpensesData", payload);
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'finance/saveOverheadExpensesDtl',
      json, {
        headers: headers
      }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
       res.json(), this.saveResponse = res }));
  }
  getDynamicMenu(payload:any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'master/getDynamicMenu',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  //get team member type
  getTeamMemberType(superUserType: string) {
    console.log('getmemberlistbytype========', superUserType);
    if (superUserType == 'advocate' || superUserType == 'counsel' || superUserType == 'managingpartner' || superUserType == 'directorlegal') {
      superUserType = superUserType;
    }
    else {
      superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;
    }
    console.log('getmemberlistbytype========', superUserType);
    return this.http.post(environment.api + 'master/getTeamMemberType', {
      "superUserType": superUserType
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  // //get Contract Member type for Contract role team
  // getContractManagementStages(superUserType: string) {

  //   return this.http.post(environment.api + 'master/getContractManagementStages', {
  //     "superUserType": superUserType
  //   }).
  //     pipe(map(res => { res.json(), this.userData = res }));
  // }

  deleteAddress(addressId:string){

    return this.http.post(environment.api + 'user/deleteAddress', {
      "id": addressId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  getAllClientsOfSuperUser(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'user/getAllClientsOfSuperUser',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  getAllClients(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'user/getAllClients',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  getFessAgreementPrice(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'finance/getFeesAgreementPrice',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  getServicesScheduleOfFees(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'master/getServicesScheduleOfFees',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveFessAgreementPrice(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'finance/saveFeesAgreementPrice',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  finalSaveFessAgreementPrice(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'finance/saveFeeAgreementS3',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  agreedByClient(payload : any){
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'finance/updateAgreedByClient',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
    //get team list
    getInviteMemberList(userType : string,superUserId : string) {

      return this.http.get(environment.api + 'user/getOrgMember?superUserType='+userType+'&superUserId='+superUserId, {
  
      }).
        pipe(map(res => { res.json(), this.userData = res }));
    }
    
    getCourtsList(stateId : any){
      var json=stateId;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'master/getDistrictCourts',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
    }
    getAgreedFeesAgreementList(payload : any){
      var json=payload;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'finance/getAgreedFeesAgreementList',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
    }
    saveClientInvoice(payload : any){
      var json=payload;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'finance/saveClientInvoice',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
    }
    generateInvoice(payload : any){
      var json=payload;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'finance/generateClientInvoive',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
    }

    generateFinalInvoice(payload : any){
      var json=payload;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'finance/saveinvoiceInS3',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
    }

    //get contract list of user
  getContractListUser(clientUserId: string) {  
    return this.http.get(environment.api + 'contract/getContractListOfClient?clientUserId='+clientUserId, {
      

    }).pipe(map(res => { res.json(), this.userData = res }));
  }
  //get agreement list of a user
  getAgreementList(userId: string) {

    return this.http.post(environment.api + 'finance/getClientFeeAgreements', {
      "clientUserId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

   //get country code
   getCountryCode() {

    return this.http.get(environment.api + '/master/getCountries', { 

    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  savePaymentData(payload: any) {
    console.log("savePaymentData service", payload);
  
    var json = payload;
    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    return this.http.post(environment.api + 'payment/payment-details',
      json, {
      headers: headers
    }
    ).pipe(map(res => {
      console.log("Serv response--" + res);
      res.json(), this.saveResponse = res
    }));
  }
  //get taskcount
  getTaskCount(userId) {

    return this.http.post(environment.api + 'task/getTaskforToday', {
      "toUserId": userId
    }).
      pipe(map(res => { res.json(), this.userData = res }));

  }

  getBillDetails(payload) {

    var json=payload;
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api + 'finance/getClientInvoiveInfo',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
       
        return res.json();
      } ) );

}

getCaseDetailsDashboard(userId,type) {
  
  var headers = new Headers();
  headers.append('Content-type', 'application/json');

  return this.http.get(environment.api + 'case/dashboard/getCaeDetails?loginUserId='+userId+'&userType='+type, { 
    headers: headers
  }).
    pipe(map(res => { res.json(), this.userData = res }));
  
 

}

getOverhaidDetailsDashboard(userId) {
  
  var headers = new Headers();
  headers.append('Content-type', 'application/json');

  /* return this.http.get(environment.api + 'case/dashboard/getCaeDetails?loginUserId='+userId, { 
    headers: headers
  }).
    pipe(map(res => { res.json(), this.userData = res })); */

    return this.http.get(environment.api + 'finance/dashboard/overheadExpence?loginUserId='+userId, { 
      headers: headers
    }).
      pipe(map(res => { res.json(), this.userData = res })); 
  
 

}

getNotificationDetails(userId) {
  
  var headers = new Headers();
  headers.append('Content-type', 'application/json');

    return this.http.get(environment.api + 'notification/list?loginUserId='+userId, { 
      headers: headers
    }).
      pipe(map(res => { res.json(), this.userData = res })); 
  
 

}

checkNoOfUser(userType : string,superUserId:string,paymentStatus:string){
   
  var headers=new Headers();
  headers.append( 'Content-type', 'application/json' );
  return this.http.post( environment.api + 'user/getTeamMemberCount?superuserType='+userType+'&superUserId='+superUserId+'&paymentStatus='+paymentStatus,
     null,{headers: headers}
  ).pipe( map( res => {
    console.log( "Serv response--"+res );
    return res.json();
  } ) );
}

getSubscriptionPlanList(userType : string,superUserId : string) {

  return this.http.get(environment.api + 'master/getSubscriptionPlanByUserId?userType='+userType+'&userId='+superUserId, {

  }).
    pipe(map(res => { res.json(), this.userData = res }));
}

updateNotification(payload) {

  var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api + 'notification/list/update',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
     
      return res.json();
    } ) );

}

updateMessage(item: any) {
  this.data.next(item);
}

getContractList(userId: string) {

  return this.http.get(environment.api + 'contract/getContactNumber?clientId='+userId, {
    
  }).
    pipe(map(res => { res.json(), this.userData = res }));
}

getTaskCaseTeamList(caseNo : string) {

  return this.http.get(environment.api + 'case/assign/member?caseNo='+caseNo, {

  }).
    pipe(map(res => { res.json(), this.userData = res }));
}

}


