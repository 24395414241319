import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamcreation',
  templateUrl: './teamcreation.component.html',
  styleUrls: ['./teamcreation.component.css']
})
export class TeamcreationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
