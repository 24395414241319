import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-payresponse',
  templateUrl: './payresponse.component.html',
  styleUrls: ['./payresponse.component.css']
})
export class PayresponseComponent implements OnInit {

  respmsg : string;
  constructor(private router: Router,private route: ActivatedRoute,private toastr: ToastrService) { }

  ngOnInit(): void {
    
   
  
    this.route.queryParams.subscribe(params => {
      this.respmsg = params['resp'];
      console.log(this.respmsg); // Print the parameter to the console. 
  });
    console.log("respmsg::", this.respmsg);
    if(this.respmsg != 'Transaction failed.'){
      if(localStorage.getItem('isSubscriptionValid') == 'N'){
        this.toastr.success('Your payment has been done successfully', '');
     localStorage.setItem('isSubscriptionValid', 'Y');
    
     setTimeout(()=>{
      
      location.reload()
      
    },2000);
      }
      this.router.navigate(['/dashboard']);
    }else{
      this.toastr.success('Payment Failure', '');
      this.router.navigate(['/payment']);
    }
    
  }

  successPayment(){
    
  }

}
