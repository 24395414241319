<div class="content-wrapper"> 
    <section class="content-header">
      <h1>Team Creation <small> </small> </h1>
      <ol class="breadcrumb">
        <li><i class="fa fa-users" aria-hidden="true"></i>  Team Creation </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-info bottom-height">

        <div class="box-body">
            
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td><div class="form-group">
                    <label>Team Name</label>
                    <input type="text" class="form-control" placeholder="Enter ...">
                  </div></td>
                  <td>                    <div class="form-group">
                    <label>Team Description</label>
                    <input type="text" class="form-control" placeholder="Enter ...">
                  </div></td>
                  <td><div class="form-group">
                    <label>Case no</label>
                    <select class="form-control">
                      <option>--Select--</option>  
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </select>
                  </div></td>
                  
                </tr>

              </tbody>
            </table>



          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Member Type</th>
                  <th>Member Name</th>
                  <th>Member Role</th>
                  <th width="100" >Action</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <button type="button" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i></button>
                  <button type="button" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>                
                
              </tr>
              <tr>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <select class="form-control">
                    <option>--Select--</option>  
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </select>
                </td>
                <td>
                  <button type="button" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i></button>
                  <button type="button" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>                
              </tr>
              </tbody>
            </table>
            </div>
        </div>

        <div class="box-footer">
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <button type="submit" class="btn btn-primary pull-left">Back</button>
                </div>              
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <button type="submit" class="btn btn-primary pull-right">Create Team</button>
                </div>
            </div>
        
        </div>

      </div>

    </section>
  </div>
