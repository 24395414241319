<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>WpcLawyer.com</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/WPC-LOGO.jpg" rel="icon">
  <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <!-- <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"> -->
  <!-- Vendor CSS Files -->

  <!-- <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet"> -->

  <!-- <link href="//netdna.bootstrapcdn.com/bootstrap/3.1.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css"> -->

  <!-- <link rel="stylesheet" type="text/css" href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/css/main.css"> -->
  <!-- Template Main CSS File -->
  <!-- <link href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/css/style.css" rel="stylesheet"> -->

  <!-- <script type="text/javascript"
    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> -->
</head>

<body>
  <!-- ======= Top Bar ======= -->
  <section id="topbar" class="d-flex align-items-center">
    <div class="container d-flex justify-content-center justify-content-md-between">
      <div class="contact-info d-flex align-items-center">
        <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:info@wpclawyer.com ">info@wpclawyer.com
          </a></i>
        <i class="bi bi-phone d-flex align-items-center ms-4"><a href="tel:020-8087-2343"> <strong>Phone:</strong> 020
            8087 2343 <br></a></i>
      </div>
      <div class="social-links d-none d-md-flex align-items-center">
        <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
        <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
        <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
      </div>
    </div>
  </section>




  <!-- ======= Header ======= -->
  <header id="header" class="d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">

      <h1 class="logo"><a href="index.html">WpcLawyer<span>.com</span></a></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo"><img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/logo.png" alt=""></a>-->

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#">Home</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('about')">About</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('team')">Team</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('services')">Services</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('pricing')">Pricing</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('contact')">Contact</a></li>
          <li><a class="nav-link scrollto" (click)="navigateToSection('gdpr')">GDPR Policy</a></li>
          <li><a id="google_translate_element"></a></li>
          <li><a class="nav-link scrollto" href="https://webapp.wpclawyer.com/login">Log in </a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->

    </div>

  </header>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div class="container" data-aos="zoom-out" data-aos-delay="100">
      <h1>Welcome to <span>WpcLawyer</span>.com</h1>
      <h2>We make your life easy</h2>
      <div class="d-flex">
        <a href="#about" class="btn-get-started scrollto">Get Started</a>
        <!-- =======Video path  ======= -->
        <!-- <a href="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/WPC-HD.mp4" type="video/mp4" class="glightbox btn-watch-video"><i
            class="bi bi-play-circle"></i><span>Watch Video</span></a> -->
      </div>
    </div>
  </section><!-- End Hero -->



  <main id="main1">

    <section id="featured-services" class="featured-services">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><img src="https://img.icons8.com/color/48/000000/total-sales-1.png" /></div>
              <h4 class="title"><a href="">Accounting </a></h4>
              <p class="description">The process of recording financial transactions pertaining to a business</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><img src="https://img.icons8.com/officel/40/000000/search-client.png" /></div>
              <h4 class="title"><a href=""> Client Management </a></h4>
              <p class="description">Overseeing and coordinating an organization's interactions with its clients and
                potential clients.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><img src="https://img.icons8.com/color/48/000000/calendar--v1.png" /></div>
              <h4 class="title"><a href="">Calendar Management </a></h4>
              <p class="description">The systematic process of organizing meetings, events, and tasks with the ultimate
                goal of maximizing </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><img src="https://img.icons8.com/color/48/000000/document--v1.png" /></div>
              <h4 class="title"><a href="">Document Management</a></h4>
              <p class="description">System used to receive, track, manage and store documents and reduce paper, keeping
                a record of the various versions </p>
            </div>
          </div>

        </div>

      </div>
    </section>


    <!-- ======= About Section ======= -->
    <section id="about" class="about section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>About</h2>
          <h3>Find Out More <span>About Us</span></h3>
          <p>We make your life easy</p>
        </div>

        <div class="row">
          <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/about.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up"
            data-aos-delay="100">
            <h3>We strive to offer best Legal Software services in the UK.</h3>

            <ul>
              <li>
                <i class="bx bxs-chevron-right"></i>
                <div>
                  <h5>WpcLawyer.com </h5>
                  <p>The WpcLawyer.com will help your law firms enhance many functions, such as billing, documentation,
                    client records, scheduling, and appointments, computer files in order to facilitate any compliance
                    requirements
                    such as with document retention policies, courts' electronic filing system and ease the learning
                    curve and reduce clerical errors. <br> Our client does not have to make capital investment for the
                    hardware or the software. Also, they do not have to worry about installation, configuration,
                    hardware upgrades as well as software and mobile application updates. Moreover, it gives them quick
                    and easy access to expert IT and WpcLawyer.com services with a nominal subscription. </p>
                </div>
              </li>

            </ul>

          </div>
        </div>

      </div>
    </section>
    <!-- End About Section -->





    <!-- ======= Clients Section ======= -->
    <section id="clients" class="clients section-bg">
      <div class="container" data-aos="zoom-in">

        <div class="row">

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-1.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-4.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-5.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/clients/client-6.png" class="img-fluid" alt="">
          </div>

        </div>

      </div>
    </section><!-- End Clients Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <h3>Check our <span> Software's Services</span></h3>

        </div>

        <div class="row">
          <!-- ======= Access Controls/Permissions  Section ======= -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Case Management</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i> Case Management System
                  </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> List of courts and tribunals </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Progress tracking </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Case document management </p>
                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/clouds/100/000000/law-document---v2.png" /></div>
                  <h4><a href="">Case Management</a></h4>
                  <p class="card-text">It handle all cases and it is to facilitate the just resolution of disputes
                    according to law as quickly, inexpensively and efficiently as possible with our system</p>
                </div>
              </div>
            </div>
          </div>
          <!-- END  Access Controls/Permissions  Section  -->

          <!-- ======= Accouting  Section ======= -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
            data-aos-delay="200">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Billing & Invoicing</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i> Online Payment
                  </p>
                  <p class="card-text"> <i class="bx bxs-chevron-right"></i> Financial Reporting </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Tracking System Payment</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Bank Accounting Management</p>

                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/fluency/48/000000/receipt-and-change.png" /></div>
                  <h4><a href="">Billing & Invoicing</a></h4>
                  <p class="card-text">Perform accounting and financial tasks and to automate your routine tasks,
                    eliminate manual data entry, and make sure your accounting figures are precise.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- END Accouting  Section  -->

          <!-- ======= Billing & Invoicing ======= -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
            data-aos-delay="300">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Client Management</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i> Client Portal
                  </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Billable Clients Management</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Tracking System Payment</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> New System Payment</p>
                  
                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/officel/40/000000/search-client.png" /></div>
                  <h4><a href="">Client Management</a></h4>
                  <p class="card-text">It is the process of overseeing and coordinating an organization's interactions
                    with its clients and to improve customer experience and strengthen the relationship between a
                    business </p>
                </div>
              </div>
            </div>
          </div>
          <!-- END Billing & Invoicing  -->

          <!-- ======= Client Portal ======= -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Calendar Management</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i>Dashboard
                  </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Task System </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Real-Time Notifications</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> TimeKeeper</p>

                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/color/48/000000/calendar--v1.png" /></div>
                  <h4><a href="">Calendar Management</a></h4>
                  <p class="card-text">It is the systematic process of organizing meetings, events, and tasks with the
                    ultimate goal of maximizing the return on investment</p>
                </div>
              </div>
            </div>
          </div>
          <!-- END Client Portal -->

          <!-- ======= Calendar Management ======= -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Accounting</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i> Invoice Management
                  </p>

                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Hourly & Fixed Fee Billing</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Billing Management</p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Trust accounting </p>

                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/color/48/000000/total-sales-1.png" /></div>
                  <h4><a href="">Accounting</a></h4>
                  <p class="card-text">It is the process of recording financial transactions pertaining to a business of
                    “identification, measurement, analysis </p>
                </div>
              </div>
            </div>
          </div>
          <!-- END Calendar Management  -->
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div class="card card-flip h-100">
              <div class="card-back text-white bg-dark">
                <div class="card-body">
                  <br />
                  <h3 class="card-title">Document Management</h3>
                  <p class="card-text">
                    <br />
                    <i class="bx bxs-chevron-right"></i>Document Generator Template
                  </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Unlimited Document Storage </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> Document Classification </p>
                  <p class="card-text"><i class="bx bxs-chevron-right"></i> E-Signature</p>

                </div>
              </div>
              <div class="card-front bg-white">
                <div class="icon-box">
                  <div class="icon"><img src="https://img.icons8.com/color/48/000000/document--v1.png" /></div>
                  <h4><a href="">Document Management</a></h4>
                  <p class="card-text">It is used to automatically organize, secure, digitize and classify company
                    documents, making them easy to access, edit and share</p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- End Services Section -->




    <!-- Start LutfurModal-->
    <div class="container eit-check-bio">
      <div class="modal fade" id="LutfurModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content eit-bio-content">
            <div class="modal-header">
              <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button> -->
              <h4 class="modal-title" id="myModalLabel">About Mr Lutfur Rahman</h4>
            </div>
            <div class="modal-body eit-bio-body">
              <div style="text-align:center">
                <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-1.jpg" name="aboutme" width="200" height="200" border="4"
                  class="img-circle">
                <h3 class="media-heading">Lutfur Rahman <br> <small>Immigration Advisor and Commissioner of Oath</small></h3>

              </div>
              <hr>
              <div style="text-align:center">
                <p class="text-left"><strong>Bio: </strong><br>
                  Md Luftur Rahman (FCILEx) is a prominent lawyer, businessman and community activist widely known in the diaspora Bangladeshi community in the UK.

He was called to the bar as a barrister at the Honourable Society of Lincoln’s Inn in 2000. Later, he started legal practice with a firm of solicitors in East London in 2002. He eventually established own legal practice with two other practitioners and achieved tremendous success. Their firm Universal Legal Centre was recognised with the best representative award from the Home Office for Sector Based Scheme (SBS) project introduced in 2003.
                </p>
                <br>
              </div>
            </div>
            <div class="modal-footer">
              <div style="text-align:center">
                <button type="button" class="btn btn-default eit-bio-button" data-dismiss="modal">I've heard enough about
                  Lutfur</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End LutfurModal-->


    <!-- Start NahidModal -->
    <div class="container eit-check-bio">
    <div class="modal fade" id="NahidModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content eit-bio-content">
          <div class="modal-header">
            <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button> -->
            <h4 class="modal-title" id="myModalLabel">About Mrs Nahid Parvin</h4>
          </div>
          <div class="modal-body eit-bio-body">
            <div style="text-align:center">
              <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-2.jpg" name="aboutme" width="200" height="200" border="0"
                class="img-circle">
              <h3 class="media-heading">Nahid Parvin <br><small>Head of sale and service</small></h3>

            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Bio: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Experience: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
          </div>
          <div class="modal-footer">
            <div style="text-align:center">
              <button type="button" class="btn btn-default eit-bio-button" data-dismiss="modal">I've heard enough about Nahid</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="container eit-check-bio">
    <div class="modal fade" id="DanielModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content eit-bio-content">
          <div class="modal-header">
            <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button> -->
            <h4 class="modal-title" id="myModalLabel">About Mr Daniel Ahmetaj</h4>
          </div>
          <div class="modal-body eit-bio-body">
            <div style="text-align:center">
              <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-3.jpg" name="aboutme" width="200" height="200" border="0"
                class="img-circle">
              <h3 class="media-heading">Daniel Ahmetaj <br><small> Product Manager </small></h3>

            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Bio: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Experience: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
          </div>
          <div class="modal-footer">
            <div style="text-align:center">
              <button type="button" class="btn btn-default eit-bio-button" data-dismiss="modal">I've heard enough about Daniel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="container eit-check-bio">
    <div class="modal fade" id="IshakuModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content eit-bio-content">
          <div class="modal-header">
           <!--  <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button> -->
            <h4 class="modal-title" id="myModalLabel">About Mr Ishaku </h4>
          </div>
          <div class="modal-body eit-bio-body">
            <div style="text-align:center">
              <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-3.jpg" name="aboutme" width="200" height="200" border="0"
                class="img-circle">
              <h3 class="media-heading">Ishaku <br><small> IT Administrator </small></h3>

            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Bio: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
            <hr>
            <div style="text-align:center">
              <p class="text-left"><strong>Experience: </strong><br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem dui, tempor sit amet commodo a,
                vulputate vel tellus.</p>
              <br>
            </div>
          </div>
          <div class="modal-footer">
            <div style="text-align:center">
              <button type="button" class="btn btn-default eit-bio-button" data-dismiss="modal">I've heard enough about Ishaku</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- End NahidModal -->
    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Team</h2>
          <h3>Our Dynamic <span>Team</span></h3>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="member">
              <div class="member-img">
                <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-1.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Mr. Lutfur Rahman</h4>
                <p>Immigration Advisor and Commissioner of Oath</p>
                <br>
                <button type="button" class="btn btn-success" data-toggle="modal" data-target="#LutfurModal">Check
                  Bio</button>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <div class="member-img">
                <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-2.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Nahid Parvin </h4>
                <p>Head of sale and service</p>
                <br>
                <button type="button" class="btn btn-success" data-toggle="modal" data-target="#NahidModal">Check
                  Bio</button>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <div class="member-img">
                <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-3.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Daniel Ahmetaj</h4>
                <p>Product Manager</p>
                <br>
                <button type="button" class="btn btn-success" data-toggle="modal" data-target="#DanielModal">Check
                  Bio</button>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div class="member">
              <div class="member-img">
                <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/team/team-4.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Mr. Ishaku </h4>
                <p>IT Administrator</p>
                <br>
                <button type="button" class="btn btn-success" data-toggle="modal" data-target="#IshakuModal">Check
                  Bio</button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- End Team Section -->

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Pricing</h2>
          <h3>Check our <span>Pricing</span></h3>

        </div>

        <div class="row">



          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <div class="box">
              <h3>Small</h3>
              <h4> £ 100 +VAT<span> per month </span> </h4>
              <h5> up to 4 users</h5>
              <br />
              <ul>
                <li>Contract with no commitment</li>
                <li>Support 24/7</li>
                <li>Android and iOS Device </li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <div class="box">
              <h3>Medium</h3>
              <h4> £ 200 +VAT<span> per month</span> </h4>
              <h5> up to 10 users</h5> <br />
              <ul>
                <li>Contract with no commitment</li>
                <li>Support 24/7</li>
                <li>Android and iOS Device</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div class="box">
              <span class="advanced">Advanced </span>
              <h3>Large</h3>
              <h4>£ 300 + VAT <span> per month</span> </h4>
              <h5> up to 30 users </h5> <br />

              <ul>
                <li>Contract with no commitment</li>
                <li>Support 24/7</li>
                <li>Android,iOS Device and customisation</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- End Pricing Section -->



    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
          <h3><span>Contact Us</span></h3>

        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-6">
            <div class="info-box mb-4">
              <i class="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>2nd Floor, 112-116 Whitechapel Road London, E1 1JE, United Kingdom </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
              <i class="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>info@wpclawyer.com </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
              <a href="tel:020-8087-2343"> <i class="bx bx-phone-call"></i>
                <h3>Call Us</h3>
                <p>020 8087 2343 </p>
              </a>
            </div>
          </div>

        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">

          <div class="col-lg-6 ">
            <iframe class="mb-4 mb-lg-0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.7218022146844!2d-0.06554318434258866!3d51.518319679636825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ccc5d9a52b5%3A0x1d9e8daa94bf42f9!2sWhitechapel%20Rd%2C%20London%2C%20UK!5e0!3m2!1sen!2sin!4v1633329750643!5m2!1sen!2sin"
              style="border:0; width: 100%; height: 540px;" allowfullscreen></iframe>
          </div>


          <div class="col-lg-6">
            <div class="container-contact100 cont">
              <div class="wrap-contact100 cont-form">
                <button class="btn-hide-contact100">
                  <i class="zmdi zmdi-close"></i>
                </button>

                <div class="contact100-form-title" style="background-image: url(https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/1280.jpg);">
                  <span>Contact Us</span>
                </div>

                <form class="contact100-form validate-form" id="contact" method="post">
                  <div class="wrap-input100 validate-input">
                    <input id="name" class="form-control input100" type="text" class="username" name="name" placeholder="Full name">
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="name">
                      <span class="lnr lnr-user m-b-2"></span>
                    </label>
                  </div>


                  <div class="wrap-input100 validate-input">
                    <input id="email" class="form-control input100" type="text" name="email" class="email"
                      placeholder="Eg. example@email.com">
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="email">
                      <span class="lnr lnr-envelope m-b-5"></span>
                    </label>
                  </div>


                  <div class="wrap-input100 validate-input msg">
                    <textarea id="message" class="form-control input100" name="message" class="message"
                      placeholder="Your comments..."></textarea>
                    <span class="focus-input100"></span>
                    <label class="label-input100 rs1" for="message">
                      <span class="lnr lnr-bubble"></span>
                    </label>
                  </div>

                  <div class="container-contact100-form-btn">
                    <button class="contact100-form-btn" id="contact-submit" name="submit" class="submit">
                      Send Now
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="loader">


      </div>
    </section>
    <!-- End Contact Section -->

    <!-- ======= GDPR Section ======= -->
    <section id="gdpr" class="gdpr section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>GDPR Policy</h2>
          <!-- <h3>Find Out More <span>About Us</span></h3>
          <p>We make your life easy</p> -->
        </div>

        <div class="row">
          <!-- <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
            <img src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/img/about.jpg" class="img-fluid" alt="">
          </div> -->
          <div class="col-lg-12 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up"
            data-aos-delay="100">
            <h3>Data protection information notice for customers</h3>

            <ul>
              <li>
                <i class="bx bxs-chevron-right"></i>
                <div>
                  <!-- <h5>WpcLawyer.com </h5> -->
                  <h3> Last updated: 25th Nov. 2021</h3>
                    
                  <h5>  1.    Introduction</h5>
                    
                  <p><span>1.1 </span>   We are committed to safeguarding the privacy of our website visitors, service users, individual customers, and customer personnel; in this notice we explain how we will handle your personal data.
                    
                  <p><span>1.2</span>   This notice applies where we are acting as a data controller with respect to data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.
                    
                  <p> <span>1.3 </span>   In this notice, "we", "us" and "our" refer to data controller name. For more information about us, see section 12.
                    
                  <h5> 2. The personal data that we collect</h5>
                    
                  <p> <span> 2.1 </span> In this section 2 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.
                    
                  <p> <span> 2.2 </span>  We may process data enabling us to get in touch with you ("contact data"). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you and/or your employer. If you log into our website using a social media account, we will obtain elements of the contact data from the relevant social account provider.
                    
                  <p> <span>2.3 </span>    We may process information contained in or relating to any communication that you send to us or that we send to you ("communication data"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.
                    
                  <p> <span>2.4</span>     We may process information relating to our customer relationships ("customer relationship data"). The customer relationship data may include your name, the name of your business or employer, your job title or role, your contact details, your classification/categorisation within our HRMS and WPC Lawyer system and information contained in or relating to communications between us and you, or between us and your employer. The source of the customer relationship data is you and/or your employer.
                    
                  <p> <span> 2.5 </span>   We may process your personal data that are provided in the course of the use of our services and generated by our services in the course of such use ("service data"). The service data may include:
                    
                  <p>  For HRMS, employment contract, leave, and holiday information, bank details, recruitment, and all kind of HR-related information.
                    
                  <p>  For WPC lawyers, all kinds of data related to the law firm, clients, and case management and billing information services.
                    
                  <p>  For Visa and Immigration Services, all kinds of data related to visa applications and immigration service delivery.
                    
                  <p>  The source of the service data is you and/or your employer and/or our services.
                    
                  <p> <span>2.6 </span>    We may process [information relating to transactions, including purchases of goods and/or services, that you enter into with us and /or through our website ("transaction data"). The transaction data may include your name, your contact details, your payment card details (or other payment details) and the transaction details. The source of the transaction data is [you and/or our payment services provider.
                    
                  <p> <span>2.7</span>     We may process data about your use of our website and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.
                    
                  <p> <span>2.8</span>     Please do not supply any other person's personal data to us, unless we prompt you to do so.
                    
                  <h5>  3.      Purposes of processing and legal bases</h5>
                    
                  <p> <span>3.1</span>     In this section 3, we have set out the purposes for which we may process personal data and the legal bases of the processing.
                    
                  <p> <span>3.2 </span>    Operations - We may process your personal data for the purposes of operating our website, processing and fulfilling orders, providing our services, supplying our goods, generating invoices, bills and other payment-related documentation, and credit control. The legal basis for this processing is our legitimate interests, namely the proper administration of our website, services and business.
                    
                  <p> <span>3.3</span>     Relationships and communications - We may process contact data, customer relationship data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of our relationships, enabling the use of our services, and the proper administration of our services, and the proper administration of our website, services, and business.
                    
                  <p> <span>3.4 </span>    Direct marketing - We may process contact data, account data, profile data, customer relationship data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is consent.
                    
                  <p> <span>3.5 </span>    Research and analysis - We may process usage data, service data, customer relationship data and/or transaction data for the purposes of researching and analysing the use of our services, as well as researching and analysing other interactions with our business. The legal basis for this processing is consent.
                    
                  <p> <span>3.6</span>     Record keeping - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this notice.
                    
                  <p> <span>3.7</span>     Security - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our services and business, and the protection of others.
                    
                  <p> <span> 3.8 </span>   Insurance and risk management - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.
                    
                  <p> <span>3.9 </span>    Legal claims - We may process your personal data where necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.
                    
                  <p> <span>3.10</span>   Legal compliance and vital interests - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.
                    
                  <h5>  4.      Providing your personal data to others </h5>
                    
                  <p> <span>4.1 </span>    We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this notice.
                    
                  <p> <span> 4.2</span>    We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.
                    
                  <p> <span>4.2</span>     Your personal data held in our website database will be stored on the servers of our hosting services providers.
                    
                  <p> <span>4.3</span>     We may disclose personal data category or categories as specified in Section 2.5 to our suppliers or subcontractors insofar as reasonably necessary.
                    
                  <p> <span> 4.4</span>    Financial transactions relating to our goods and services are handled by our payment services providers. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers' privacy policies and practices at their respective websites.
                    
                  <p> <span>4.5</span>     We may disclose contact data along with any other personal data contained in enquiries made through our website or services to one or more of those selected third party suppliers of goods and/or services identified on our website for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the personal data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.
                    
                  <p> <span>4.6</span>     In addition to the specific disclosures of personal data set out in this section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.
                    
                  <h5>  5.      International transfers of your personal data </h5>
                    
                  <p> <span> 5.1 </span>   In this section 5, we provide information about the circumstances in which your personal data may be transferred to a third country under UK data protection law.
                    
                  <p> <span>5.2</span>     We may transfer your personal data from the European Economic Area (EEA) to the UK and process that personal data in the UK for the purposes set out in this notice, and may permit our suppliers and subcontractors to do so, during any period with respect to which the UK is not treated as a third country under EU data protection law or benefits from an adequacy decision under EU data protection law; and we may transfer your personal data from the UK to the EEA and process that personal data in the EEA for the purposes set out in this notice, and may permit our suppliers and subcontractors to do so, during any period with respect to which EEA states are not treated as third countries under UK data protection law or benefit from adequacy regulations under UK data protection law.
                    
                  <p> <span>5.3 </span>    The hosting facilities for our website are situated in UK. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries.
                    
                  <p> <span>5.4</span>     You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.
                    
                  <h5>  6.      Retaining and deleting personal data </h5>
                    
                  <p> <span>6.1  </span>   This section 6 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.
                    
                  <p> <span>6.2</span>     Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
                    
                  <p> <span>6.3 </span>    Notwithstanding the other provisions of this section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
                    
                  <h5>  7.      Security of personal data </h5>
                    
                  <p> <span>7.1 </span>    We will take appropriate technical and organisational precautions to secure your personal data and to prevent the loss, misuse or alteration of your personal data.
                    
                  <p> <span>7.2</span>     We will store your personal data on secure servers, personal computers and mobile devices, and in secure manual record-keeping systems.
                    
                  <p> <span>7.3</span>     The following personal data will be stored by us in encrypted form: your name, contact information, password(s) and cardholder data.
                    
                  <p> <span>7.4</span>     Data relating to your enquiries and financial transactions that is sent from your web browser to our web server, or from our web server to your web browser, will be protected using encryption technology.
                    
                  <p> <span>7.5</span>     You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
                    
                  <p> <span>7.6 </span>    You should ensure that your passwords used to access our software and IT systems are not susceptible to being guessed, whether by a person or a computer program. You are responsible for keeping the passwords confidential and we will not ask you for your passwords (except when you log in to our software and IT systems).    
                    
                  <h5>  8.      Your rights </h5>
                    
                  <p> <span>8.1</span>     In this section 9, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights. You can learn more about the rights of data subjects by visiting https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en and https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
                    
                  <p> <span>8.2</span>     Your principal rights under data protection law are:
                    
                  <p>  (a)    the right to access - you can ask for copies of your personal data;
                    
                  <p>  (b)    the right to rectification - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;
                    
                  <p>  (c)    the right to erasure - you can ask us to erase your personal data;
                    
                  <p>  (d)    the right to restrict processing - you can ask us to restrict the processing of your personal data;
                    
                  <p>  (e)    the right to object to processing - you can object to the processing of your personal data;
                    
                  <p>  (f)    the right to data portability - you can ask that we transfer your personal data to another organisation or to you;
                    
                  <p>  (g)    the right to complain to a supervisory authority - you can complain about our processing of your personal data; and
                    
                  <p>  (h)    the right to withdraw consent - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.
                    
                  <p> <span>8.3</span>     You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, we will try our best to respond to You as soon as possible. You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.
                    
                  <h5>  9.      Third party websites </h5>
                    
                  <p> <span>9.1</span>  Our website includes hyperlinks to, and details of, third party websites.
                    
                  <p> <span>9.2</span>  In general we have no control over, and are not responsible for, the privacy policies and practices of third parties.
                    
                  <h5>  10.    Updating information </h5>
                    
                  <p><span>10.1</span>  Please let us know if the personal information that we hold about you needs to be corrected or updated.
                    
                  <h5>  11.    Amendments </h5>
                    
                  <p> <span>11.1</span>   We may update this notice from time to time by publishing a new version on our website.
                    
                  <p> <span>11.2</span>   You should check this page occasionally to ensure you are happy with any changes to this notice.
                    
                  <p> <span> 11.3</span>  We may notify you of significant changes to this notice by email.
                    
                  <h5>  12.    Our details </h5>
                    
                  <p> <span>12.1</span>   Our full legal name is WorkPermitCloud Limited.
                    
                  <p> <span>12.2 </span>  We are registered in England and Wales under registration number 12909694, and our registered office is at 130 Old Street, London, England, EC1V 9BD.
                    
                  <p> <span>12.3</span>    Our principal place of business is at 2nd Floor, 112-116 Whitechapel Road, London, E1 1JE.
                    
                  <p> <span>12.4</span>   You can contact us:
                    
                  <p>  (a)    by post, to the postal address given above;
                    
                  <p>  (b)    using our website contact form at https://www.workpermitcloud.co.uk/contact;
                    
                  <p>  (c)    by telephone, on the contact number published on our website; or
                    
                  <p>  (d)    by email, using the email address published on our website. </p>
                </div>
              </li>

            </ul>

          </div>
        </div>

      </div>
    </section>
    <!-- End GDPR Section -->

  </main><!-- End #main -->
  <!-- <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@10"></script>
  <script type="text/javascript">
    (function () {
      emailjs.init("user_l0e9EFZqVbyxkV2K3AtvE");
    })();

    /**
     emailjs.send("SERVICE ID", "TEMPLATE NAME", {
            to_name: "USERNAME",
            from_name: "FROM NAME",
            message: "MESSAGE",
          });
     **/
    function validate() {
      let loader = document.querySelector(".loader");
      let name = document.querySelector(".username");
      let email = document.querySelector(".email");
      let msg = document.querySelector(".message");
      let btn = document.querySelector(".submit");

      btn.addEventListener("click", (e) => {
        e.preventDefault();
        if (name.value == "" || email.value == "" || msg.value == "") {
          emptyerror();
        } else {
          loader.style.display = "flex";
          sendmail(name.value, email.value, msg.value);
          success();
          loader.style.display = "none";
        }
      });
    }
    validate();

    function sendmail(name, email, msg) {
      emailjs.send("service_zsulkvh", "template_polllfb", {
        to_name: name,
        from_name: email,
        message: msg,
      });
    }

    function emptyerror() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fields cannot be empty!",
      });
    }

    function error() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }

    function success() {
      Swal.fire({
        icon: "success",
        title: "Success...",
        text: "Successfully sent message",
      });
    }


  </script> -->
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>WpcLawyer<span>.com</span></h3>
            <p>
              2nd Floor <br> 112-116 Whitechapel Road London<br> E1 1JE, United Kingdom <br><br>
              <a href="tel:020-8087-2343"> <strong>Phone:</strong> 020 8087 2343 <br></a>
              <strong>Email:</strong> info@workpermitcloud.co.uk,<br>
            </p>
          </div>



          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Software's Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Accounting</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Billing & Invoicing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Client Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Calendar Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Case Management </a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Document Management </a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>Follow us!</p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="copyright">
        &copy; Copyright <strong><span>WpcLawyer</span>.com</strong> All Rights Reserved
      </div>

    </div>
  </footer>
  <!-- End Footer -->
  <!-- End Footer -->

  <div id="preloader" ></div>
  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->

  <!-- <script src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/vendor/glightbox/js/glightbox.min.js"></script>


  <script src='https://code.jquery.com/jquery-3.3.1.slim.min.js'></script> -->

  <!-- <script src="//netdna.bootstrapcdn.com/bootstrap/3.1.0/js/bootstrap.min.js"></script>
  <script src="//code.jquery.com/jquery-1.11.1.min.js"></script> -->

  <!-- Template Main JS File -->
  <!-- <script src="https://wpclegal.s3.eu-west-2.amazonaws.com/web-assets/js/main.js"></script> -->

  <script type="text/javascript">
    function googleTranslateElementInit() {
      new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }
  </script>

</body>

</html>