<div class="content-wrapper">

  <section class="content ">
    <!-- <div class="col-md-6" *ngIf="myform.get('profilePicSrc').value">
      <img src="{{dataArr.profilePicSrc}}" alt="" class="img-thumbnail pull-right profile-pic" height="50px"
        width="60px">
    </div> -->

    <div class="bottom-height">

      <!-- <div class="box-header with-border">
      <div class="box-header-icon"><i class="fa fa-users" aria-hidden="true"></i></div>
      <h3 class="box-title">Client Profile</h3>
    </div> -->


      <div class="box-header">
        <div class="row">
          <div class="col-md-6" [attr.disabled]="viewMode">
            <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">groups</i></div>
            <h3 class="box-title main-header-top">Client Profile</h3>
          </div>
          <!-- this.dataArr&&this.dataArr.emailId -->
          <div class="col-md-6" *ngIf="myform.get('profilePicSrc').value">
            <img src="{{dataArr.profilePicSrc}}" alt="" class="img-thumbnail pull-right profile-pic" height="50px"
              width="60px">
          </div>
        </div>
        <div class="box-body" *ngIf="profilestatus=='N'">
          <div class="box alert-danger">
            <div class="box-body">
              Welcome to WpcLawyer, please complete and update your profile to access E-BRIEFCASE.
            </div>
          </div>
        </div>


      </div>

      <div class="box-body">
        <div class="breadcrumbbox">
          <ol class="breadcrumb">
            <li class="active"><a [routerLink]="['/clientprofile',userId,usertype]"><i
                  class="material-icons md-24 md-dark">groups</i> Client Profile</a></li>
            <!-- <li [ngClass]="{'active':userId !== 'undefined', 'disabled': (userId === 'undefined') || !userId}"><a
                [routerLink]="['/addresses',userId,usertype]"><i class="fa fa-map-marker" aria-hidden="true"></i> Client
                Addresses</a></li> -->
          </ol>

        </div>
      </div>

      <form [formGroup]="myform" *ngIf="myform" (ngSubmit)="ngSubmit();">
        <div class="box-body">
          <div class="box">
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Client Type</label>
                    <select class="form-control" (change)="changeUsertype()" formControlName="userType"
                      [attr.disabled]="viewMode">
                      <option value="-1">--select--</option>
                      <option value="individualclient">Individual</option>
                      <option value="corporateclient">Corporate</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                  *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>Company Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="corporateClientCompanyName" placeholder="Enter Company Name" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientCompanyName').invalid && myform.get('corporateClientCompanyName').touched">Please
                    enter a company
                    name</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                  *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>Trading Name</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="corporateClientNatureOfBusiness" placeholder="Enter Name Trader">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientNatureOfBusiness').invalid && myform.get('corporateClientNatureOfBusiness').touched">Please
                    enter a Nature of business</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                  *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>Company Email</label>
                    <input type="email" class="form-control" formControlName="corporateEmailId" placeholder="Enter company email"
                             pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                    <small class="text-danger" *ngIf="myform.get('corporateEmailId').invalid && myform.get('corporateEmailId').touched">Please enter correct email
                    id. </small>
                  </div>
                  
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>CIN</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="corporateClientCin" placeholder="Enter CIN">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientCin').invalid && myform.get('corporateClientCin').touched">Please
                    enter CIN</small>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>Company PAN</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="corporateClientPan" placeholder="Enter PAN" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientPan').invalid && myform.get('corporateClientPan').touched">Please
                    enter PAN number</small>
                </div>
                
              </div> -->
              
              
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  *ngIf="myform.get('userType').value=='corporateclient'">
                  <div class="form-group">
                    <label>Company Linkedin</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="corporateClientLinkedinProfile" placeholder="Enter LinkedIn">
                  </div>
                  <!-- <small class="text-danger"
                  *ngIf="myform.get('lawFirmName').invalid && myform.get('lawFirmName').touched">Please enter a law firm
                  name</small> -->
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="myform.get('userType').value=='corporateclient'"
                [attr.disabled]="viewMode">
                <div class="form-group">
                  <label>Website</label>
                  <input type="text" class="form-control" formControlName="corporateClientWebsite"
                    placeholder="Enter Website">
                </div>
                </div>

                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <label>Office Phone number</label>
                          <!-- <div style="display: flex;">
                            <app-country-code-dropdown
                            [countryCode]="countryCodeOfficePh"
                            (onCountryChange)="onCountryCodeOfficePhChange($event)"
                          >
                          </app-country-code-dropdown> 
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="officePhoneNo"
                            placeholder="Enter office phone number" pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$">
                          </div> -->
                          <div style="display: flex;">
                            <ngx-intl-tel-input 
                                    [cssClass]="'custom'" 
                                    [attr.disabled]="viewMode"
                                    placeholder="Enter a valid phone number. "
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="false" 
                                    [enablePlaceholder]="true" 
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="true" 
                                    [selectedCountryISO]="selectedCountry1"
                                    [maxLength]="15" 
                                    [tooltipField]="TooltipLabel.Name" 
                                    [phoneValidation]="true" 
                                    [separateDialCode]="true"
                                    name="phone" formControlName="officePhoneNo" >
                                </ngx-intl-tel-input>
                              </div>
                        </div>
                        <small class="text-danger"
                          *ngIf="myform.get('officePhoneNo').invalid && myform.get('officePhoneNo').touched">Please enter a valid mobile
                          number</small>
                      </div>

                <div class="row" *ngIf="userId">
                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    *ngIf="myform.get('userType').value=='corporateclient'">
                    <div class="form-group">
                      <label>Corporate Profile Upload</label>

                      <input type="file" name="aadhar"
                        (change)="handleFileInput($event.target.files,'corporateclientprofile')" />
                      <small
                        *ngIf="myform.get('corporateClientProfilePathInS3').value"><b>{{dataArr.corporateClientProfilePathInS3|fileName}}</b></small><br>
                      <div id="drop-zone" class="btn btn-default"
                        *ngIf="myform.get('corporateClientProfilePathInS3').value">
                        <div id="clickHere">
                          <a href="{{dataArr.corporateClientProfilePathInS3}}" target="_blank"
                            rel="noopener noreferrer"><i class="fa fa-eye"></i></a>
                        </div>
                      </div>
                      <div id="drop-zone" class="btn btn-default"
                        *ngIf="myform.get('corporateClientProfilePathInS3').value">
                        <div id="clickHere"><i class="fa fa-trash"></i>
                          <input type="buton" name="delImg" (click)="removeDoc('corporateclientprofile')" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!--ADDRESS BLOCK END-->
                <!-- <div class="row"> -->
                <!-- <span *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'"> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa">
                          <div class="form-group">
                            <label>Title</label>
                            <select class="form-control" formControlName="userTitle" [attr.disabled]="viewMode">
                              <option value="" disabled>--Select--</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa">
                          <div class="form-group">
                            <label>First Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="firstName" placeholder="Enter name"
                              [attr.disabled]="viewMode">
                          </div>
                          <small class="text-danger"
                            *ngIf="myform.get('firstName').invalid && myform.get('firstName').touched">Please enter a first
                            name</small>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa">
                          <div class="form-group">
                            <label>Last Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="lastName" placeholder="Enter name"
                              [attr.disabled]="viewMode">
                          </div>
                          <small class="text-danger"
                            *ngIf="myform.get('lastName').invalid && myform.get('lastName').touched">Please enter a last
                            name</small>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa"
                          *ngIf="myform.get('userType').value=='individualclient'">
                          <div class="form-group">
                            <label>Profession</label>
                            <input type="text" class="form-control" formControlName="profession" [attr.disabled]="viewMode"
                              placeholder="Enter Profession">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa"
                          *ngIf="myform.get('userType').value=='corporateclient'">
                          <div class="form-group">
                            <label>Designation</label>
                            <select class="form-control" formControlName="ccDesignation" placeholder="Select designation"
                              [attr.disabled]="viewMode">
                              <option value="" disabled>--Select--</option>
                              <option value="ADVOCATE ON RECORD">ADVOCATE ON RECORD</option>
                              <option value="COUNSEL">COUNSEL</option>
                              <option value="SENIOR COUNSEL">SENIOR COUNSEL</option>
                              <option value="CHARTERED ACCOUNTANT">CHARTERED ACCOUNTANT</option>
                              <option value="COMPANY SECRETARY">COMPANY SECRETARY</option>
                              <option value="CONSULTANT">CONSULTANT</option>
                              <option value="CLERK">CLERK</option>
                              <option value="PARTNER">PARTNER</option>
                              <option value="ASSOCIATE PARTNER">ASSOCIATE PARTNER</option>
                              <option value="SENIOR ASSOCIATE">SENIOR ASSOCIATE</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 aa">
                          <div class="form-group">
                            <label>Linkedin Profile Link</label>
                            <input type="text" class="form-control" formControlName="linkedinProfileSrc"
                              placeholder="Enter linkedin profile link" [attr.disabled]="viewMode">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 bb">
                          <div class="form-group">
                            <label>Phone no</label>
                            <input type="text" class="form-control" formControlName="phoneNo" placeholder="Enter phone no"
                              [attr.disabled]="viewMode">
                              <small class="text-danger"
                            *ngIf="myform.get('phoneNo').invalid && myform.get('phoneNo').touched">Please enter phone number.</small>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 bb">
                          <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" formControlName="emailId" placeholder="Enter email"
                              [attr.disabled]="viewMode">
                              <small class="text-danger"
                            *ngIf="myform.get('emailId').invalid && myform.get('emailId').touched">Please enter email id.</small>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 cc">
                          <div class="form-group">
                            <label>Pan Number</label>
                            <input type="text" class="form-control" formControlName="panNo" placeholder="Enter pan number"
                              [attr.disabled]="viewMode">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 cc">
                          <div class="form-group">
                            <label>Aadhar Number</label>
                            <input type="text" class="form-control" formControlName="aadharNo"
                              placeholder="Enter aadhar number" [attr.disabled]="viewMode">
                          </div>
                        </div>
                        
                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="mode!='view'">
                          <div class="form-group">
                            <label>Upload Profile Photo</label>
                            <input type="file" name="profilepic"
                              (change)="handleFileInput($event.target.files,'profilepic')" />
                            <div id="drop-zone" class="btn btn-default" *ngIf="myform.get('profilePicSrc').value">
                              <div id="clickHere">
                                <a href="{{dataArr.profilePicSrc}}" target="_blank" rel="noopener noreferrer"><i
                                    class="fa fa-eye"></i></a>
                              </div>
                            </div>
                            <div id="drop-zone" class="btn btn-default" *ngIf="myform.get('profilePicSrc').value">
                              <div id="clickHere"><i class="fa fa-trash"></i>
                                <input type="buton" name="delImg" id="file" (click)="removeDoc('profilepic')"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="myform.get('userType').value=='corporateclient'"
                          [attr.disabled]="viewMode">
                          <div class="form-group">
                            <label>Website</label>
                            <input type="text" class="form-control" formControlName="chWebsite" placeholder="Enter Website">
                          </div>
                        </div> -->
                <!-- </span> -->

                <!-- </div> -->
              </div>
              
              <mat-vertical-stepper  #clientProfileStepper *ngIf="isValidTypeOfUser()">
                <mat-step>
                  <ng-template matStepLabel>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <h4>AUTHORISED PERSON INFO</h4>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="stepper-body">
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <label>First Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="firstName"
                            placeholder="Enter first name" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <small class="text-danger"
                          *ngIf="myform.get('firstName').invalid && myform.get('firstName').touched">Please enter a first
                          name in text format. </small>
                        <!-- <small class="text-danger" *ngIf="myform.get('firstName').errors?.pattern">Please provide only text
                          for firstname.</small> -->
                      </div>

                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <label>Middle Name
                            <!--span>*</span--></label>
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="middleName"
                            placeholder="Enter middle name" pattern="^[a-zA-Z\s]+$">
                        </div>
                        <small class="text-danger" *ngIf="myform.get('middleName').errors?.pattern">Please provide only text
                          for middle name.</small>
                      </div>

                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <label>Last Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lastName"
                            placeholder="Enter surname" pattern="^[a-zA-Z\s]+$" required>
                        </div>
                        <small class="text-danger"
                          *ngIf="myform.get('lastName').invalid && myform.get('lastName').touched">Please enter a
                          surname in text format. </small>
                        <!-- <small class="text-danger" *ngIf="myform.get('lastName').errors?.pattern">Please provide only text
                          for surname.</small> -->
                      </div>
                    
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        
                        <div class="form-group">
                          <label>Phone Number <span class="text-danger">*</span></label>
                          <!-- <div style="display: flex;">
                            <app-country-code-dropdown
                            [countryCode]="countryCodeMobilePh"
                            (onCountryChange)="onCountryCodeMobilePhChange($event)"
                          >
                          </app-country-code-dropdown> 
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="phoneNo"
                            placeholder="Enter mobile number" pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$"
                            required>
                          </div> -->
                          <div style="display: flex;">
                          <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  [attr.disabled]="viewMode"
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="phoneNo" required>
                              </ngx-intl-tel-input>
                            </div>
                        </div>
                        <small class="text-danger"
                          *ngIf="myform.get('phoneNo').invalid && myform.get('phoneNo').touched">Please enter a valid mobile
                          number</small>
                        <!-- <small class="text-danger" *ngIf="myform.get('phoneNo').errors?.pattern">Please provide only
                          number.</small> -->
                      </div>

                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <div class="form-group">
                            <label>Date of Birth <span class="text-danger">*</span></label>
                            <!-- <div style="display: flex;"><input type="date" formControlName="dob"/></div> -->
                            <div class="input-group date">
                              <div class="input-group-addon" [owlDateTimeTrigger]="odt"> <i class="fa fa-calendar"></i>
                              </div>
                              <input [owlDateTimeTrigger]="odt" [max]="todayDate" [owlDateTime]="odt" type="text"
                              class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                              formControlName="dob" (keyup.enter)="OnInput($event)"
                              (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                           </div>
                           <owl-date-time #odt [pickerType]="'calendar'"></owl-date-time>
                            </div>
                          </div>
                          <!-- <label>Office Phone number</label> -->
                          <!-- <div style="display: flex;">
                            <app-country-code-dropdown
                            [countryCode]="countryCodeOfficePh"
                            (onCountryChange)="onCountryCodeOfficePhChange($event)"
                          >
                          </app-country-code-dropdown> 
                          <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="officePhoneNo"
                            placeholder="Enter office phone number" pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$">
                          </div> -->
                          <!-- <div style="display: flex;">
                            <ngx-intl-tel-input 
                                    [cssClass]="'custom'" 
                                    [attr.disabled]="viewMode"
                                    placeholder="Enter a valid phone number. "
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="false" 
                                    [enablePlaceholder]="true" 
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="true" 
                                    [selectedCountryISO]="selectedCountry1"
                                    [maxLength]="15" 
                                    [tooltipField]="TooltipLabel.Name" 
                                    [phoneValidation]="true" 
                                    [separateDialCode]="true"
                                    name="phone" formControlName="officePhoneNo" >
                                </ngx-intl-tel-input>
                              </div>
                        
                        <small class="text-danger"
                          *ngIf="myform.get('officePhoneNo').invalid && myform.get('officePhoneNo').touched">Please enter a valid mobile
                          number</small> -->
                      </div>

                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                        *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                        <div class="form-group">
                          <label>Website</label>
                          <input type="text" class="form-control" [attr.disabled]="viewMode"
                            formControlName="websiteIndividual" placeholder="Enter Website">
                        </div>
                      </div>


                    <div
                      *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 bb">
                        <div class="form-group">
                          <label>Email <span class="text-danger">*</span></label>
                          <input type="email" class="form-control" formControlName="emailId" placeholder="Enter email"
                            [attr.disabled]="viewMode ||mode" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                          <small class="text-danger"
                            *ngIf="myform.get('emailId').invalid && myform.get('emailId').touched">Please enter correct email
                            id. </small>
                          <!-- <small class="text-danger" *ngIf="myform.get('emailId').errors?.pattern">Please provide correct
                            email. </small> -->
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                          <label>Linkedin</label>
                          <input type="text" class="form-control" [attr.disabled]="viewMode"
                            formControlName="linkedinIndividual" placeholder="Enter LinkedIn">
                        </div>
                        <!-- <small class="text-danger"
                        *ngIf="myform.get('lawFirmName').invalid && myform.get('lawFirmName').touched">Please enter a law firm
                        name</small> -->
                      </div>
                      
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="userId">
                        <div class="form-group">
                          <label>Upload Profile Photo</label>

                          <input type="file" name="profilepic" (change)="handleFileInput($event.target.files,'profilepic')"
                            accept="image/*" />

                          <small
                            *ngIf="myform.get('profilePicSrc').value"><b>{{dataArr.profilePicSrc|fileName}}</b><br></small>
                          <div id="drop-zone" class="btn btn-default" *ngIf="myform.get('profilePicSrc').value">
                            <div id="clickHere">
                              <a href="{{dataArr.profilePicSrc}}" target="_blank" rel="noopener noreferrer"><i
                                  class="fa fa-eye"></i></a>
                            </div>
                          </div>
                          <div id="drop-zone" class="btn btn-default" *ngIf="myform.get('profilePicSrc').value">
                            <div id="clickHere"><i class="fa fa-trash"></i>
                              <input type="buton" name="delImg" (click)="removeDoc('profilepic')" />
                            </div>
                          </div>
                          <small class="text-danger" *ngIf="errorOnImgType">Only image file can upload here.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
                
                <mat-step *ngIf="userId">
                  <ng-template matStepLabel>
                    <h4>ADDRESS INFO</h4>
                  </ng-template>
                  <div class="stepper-body">
                    <div *ngIf="usertype=='individualclient' && userId">
                      <!--ADDRESS BLOCK START-->
                      <div class=""> 
                        <div class="">
                          <app-addresses varName="individualclient" clientUserId={{userId}}></app-addresses>
                        </div>
                      </div>
                    </div>
    
                    <div *ngIf="usertype=='corporateclient' && userId">
                      <!--ADDRESS BLOCK START-->
                      <div class=""> 
                        <div class="">
                          <app-addresses varName="corporateclient" clientUserId={{userId}}></app-addresses>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
              </mat-vertical-stepper>

              <div class="box-footer"
                *ngIf="myform.get('userType').value=='individualclient'||myform.get('userType').value=='corporateclient'">
                <a *ngIf="loginUserType != 'individualclient' && loginUserType != 'corporateclient'" [routerLink]="['/userlist']" routerLinkActive="router-link-active">
                  <button class="btn btn-grey pull-left Back1"> Back </button>
                </a>

                <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                  data-toggle="modal" data-target="#saveIntent">Save</button>

              </div>
              <!-- <div class="box-body" *ngIf="userId && mode!='edit' && !uploadFile">
                <div class="box">
                  <div class="box-body">
                    <div>
                      <span style="color: green;font-weight: bold;font-size: 15px;">You can now upload documents,profile
                        picture and can add address.</span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </form>
      <!-- Resullt section start -->
      <!--<div class="box-body" id="resultsection" *ngIf="resultShow!=undefined">
        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">

                  <div class="card-header1" for="staticEmail">
                    <b>Result Section :</b>
                  </div>
                  <div class="card-header2">
                    <i class="mdi mdi-check-circle"></i>
                    <b> {{resultShow}} {{statusCode}}
                      <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
                    </b>
                  </div>
                  <ng-container *ngIf="resultBtnShow">
                    <div class="card-body2">
                      <h5>Output</h5>
                      <p>{{mydata.responseMessage}} </p>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Result section end -->
    </div>
  </section>


  <!-- Modal section end -->
  <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm to Submit</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure to submit?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveAllData()">Yes</button>
          <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
        </div>
      </div>

    </div>

    <!-- Modal section end -->

  <!-- <div class="box-body" *ngIf="showMsg">
    <div class="box">
      <div class="box-body">
        <div>
          <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
          <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
        </div>
      </div>
    </div>
  </div> -->