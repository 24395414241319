<div class="content-wrapper">
  <!-- <section class="content-header">
      <h1> Lawyer Profile <small></small> </h1> 
      <ol class="breadcrumb">
        <li><i class="fa fa-users" aria-hidden="true"></i>  Lawyer Profile</li>
      </ol>
    </section> -->
  <section class="content">

    <div class="box-header with-border">
      <div class="row">
        <div class="col-md-6">
          <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">group</i></div>
          <h3 class="box-title main-header-top">{{pageTitle}} Profile</h3>
        </div>

        <!-- this.dataArr&&this.dataArr.emailId -->
        <div class="col-md-6" *ngIf='dataArr&&dataArr.orgLogo'>
          <img src="{{dataArr.orgLogo}}" alt="" class="img-thumbnail pull-right profile-pic" height="50px"
            width="60px">
        </div>
      </div>
    </div>
    <div class="box-body" *ngIf="profilestatus=='N'">
      <div class="box alert-danger">
        <div class="box-body">
          Welcome to WpcLawyer, please complete and update your profile to access E-BRIEFCASE.
        </div>
      </div>
    </div>

    <div class="box-body">
      <div class="breadcrumbbox">
        <ol class="breadcrumb">
          <li class="active"><a [routerLink]="['/lawyerprofile',userId,usertype]"><i class="material-icons md-18 md-dark">group</i> {{pageTitle}} Profile</a></li>
          <!-- <li [ngClass]="{'active':userId !== 'undefined', 'disabled': (userId === 'undefined') || !userId}"><a
              [routerLink]="['/addresses',userId,usertype]"><i class="fa fa-map-marker" aria-hidden="true"></i> Lawyer
              Addresses</a></li> -->
        </ol>

      </div>
    </div>

    <div class="bottom-height">
      <form  [formGroup]="myform"  *ngIf="myform" (ngSubmit)="ngSubmit();">
        <div class="box-body">
          <div class="box">
            <div class="box-body">
              <!-- <div class="row"> -->
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                      <!-- <h4 *ngIf="loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'assistantlegalmanager'">PROFILE INFO:</h4> -->
                      <!-- <h4 *ngIf="loginUserType != 'corporateclient'">PROFILE INFO:</h4> -->
                      
                      <h4 *ngIf="loginUserType == 'corporateclient'">CORPORATE CLIENT:</h4>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>{{pageTitle}} Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmName"
                      placeholder="Enter {{pageTitle}} Name" [value]="lawFirmName" readonly>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmName').invalid && myform.get('lawFirmName').touched">Please enter a {{pageTitle}}
                    name</small>
                    <!-- <small class="text-danger" *ngIf="myform.controls.lawFirmName.hasError('required') && (
                                   myform.controls.lawFirmName.dirtmyformorm.controls.lawFirmName.touched ||
                                   (!myform.controls.lawFirmName.valid && submittedForm))">This
                                field is required.</small> -->
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Nature Of Business <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="orgNatureOfBusiness"
                      placeholder="Enter Nature of business" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('orgNatureOfBusiness').invalid && myform.get('orgNatureOfBusiness').touched">Please enter a Nature of business</small>
                </div>

                

              </div>
                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Nature Of Business <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="orgNatureOfBusiness"
                      placeholder="Enter nature of Business" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('orgNatureOfBusiness').invalid && myform.get('orgNatureOfBusiness').touched">Please enter nature of business</small>
                    <small class="text-danger" *ngIf="myform.controls.orgNatureOfBusiness.hasError('required') && (
                                   myform.controls.orgNatureOfBusiness.dirtmyformorm.controls.orgNatureOfBusiness.touched ||
                                   (!myform.controls.orgNatureOfBusiness.valid && submittedForm))">This
                                field is required.</small>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal' || loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Company Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateCompanyName"
                      placeholder="Enter Company Name">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateCompanyName').invalid && myform.get('corporateCompanyName').touched">Please enter a Company
                    name</small>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal' || loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Nature Of Business <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateNatureOfBusiness"
                      placeholder="Enter Nature of business">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateNatureOfBusiness').invalid && myform.get('corporateNatureOfBusiness').touched">Please enter a Nature of business</small>
                </div> -->

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Phone Number<span class="text-danger">*</span></label>
                   <!--  <div style="display: flex;">
                      <app-country-code-dropdown
                        [countryCode]="countryCodeMobilePh"
                        (onCountryChange)="onCountryCodeMobilePhChange($event)"
                      ></app-country-code-dropdown>
                      <input type="tel" class="form-control" [attr.disabled]="viewMode" pattern="^[7-9][0-9]{9}$" formControlName="lawFirmPartnerPhoneNo"
                      placeholder="Enter Partner Mobile Number" required>
                    </div>  --> 
                    <div style="display: flex;">
                    <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  [attr.disabled]="viewMode"
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="lawFirmPartnerPhoneNo" required>
                              </ngx-intl-tel-input>                  
                  </div>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmPartnerPhoneNo').invalid && myform.get('lawFirmPartnerPhoneNo').touched">Please enter valid Phone Number</small>
                </div>

              </div>  

              <div class="row">

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Registered Office Address <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateRegistredAddress"
                      placeholder="Enter registered address" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateRegistredAddress').invalid && myform.get('corporateRegistredAddress').touched">Please enter a registered office address</small>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Branch Office Address <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateBranchAddress"
                      placeholder="Enter Branch Office address" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateBranchAddress').invalid && myform.get('corporateBranchAddress').touched">Please enter a branch office address</small>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Website</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" pattern="^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$" formControlName="lawFirmWebsite"
                      placeholder="Enter Website" >
                  </div>
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('lawFirmWebsite').invalid && myform.get('lawFirmWebsite').touched">Please enter a website name</small> -->
                </div>

                 <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Email<span class="text-danger">*</span></label>
                    <input type="email" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerEmail"
                      placeholder="Enter Email Id" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmPartnerEmail').invalid && myform.get('lawFirmPartnerEmail').touched">Please enter Email</small>
                    <small class="text-danger" *ngIf="myform.get('lawFirmPartnerEmail').errors?.pattern">Please provide correct email.</small>
                </div>

              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                  <div class="form-group">
                    <label><strong>Business Account Details</strong> </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankName" placeholder="Enter Account Name" required pattern="^[a-zA-Z\s]+$">
                  </div>
                  <small class="text-danger" *ngIf="myform.get('lawyerBankName').invalid && myform.get('lawyerBankName').touched">Please enter account name.</small>
                  <small class="text-danger" *ngIf="myform.get('lawyerBankName').errors?.pattern">Please provide only text.</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankAccount" placeholder="Enter Account Number" required pattern="^\d{6,8}$">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankAccount').invalid && myform.get('lawyerBankAccount').touched">Enter an account number’. </small>
                    <small class="text-danger" *ngIf="myform.get('lawyerBankAccount').errors?.pattern">Account number must be between 6 and 8 digits.</small>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Reference Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankBranch" placeholder="Enter reference number" required> <!-- pattern="^[a-zA-Z\s]+$" -->
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankBranch').invalid && myform.get('lawyerBankBranch').touched">Please enter reference number. </small>
                     <!-- <small class="text-danger" *ngIf="myform.get('lawyerBankBranch').errors?.pattern">Please provide only number.</small> -->
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Sort Code <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankIfscCode" minlength="6" maxlength="6" placeholder="Enter Sort Code" required pattern="^\d{6}$">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankIfscCode').invalid && myform.get('lawyerBankIfscCode').touched">Enter SORT code. </small>
                    <small class="text-danger" *ngIf="myform.get('lawyerBankIfscCode').errors?.pattern">Enter a valid sort code like "309430".</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Type <span class="text-danger"></span></label>
                    <select class="form-control" formControlName="accountType">
                      <option value="Buisiness">Buisiness</option>
                      <option value="Personal" >Personal</option>
                    </select>
                  </div>
                </div>
                

              </div>



              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                  <div class="form-group">
                    <label><strong>Transaction Account Details</strong> </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankNameT" placeholder="Enter Account Name" required pattern="^[a-zA-Z\s]+$">
                  </div>
                  <small class="text-danger" *ngIf="myform.get('lawyerBankNameT').invalid && myform.get('lawyerBankNameT').touched">Please enter account name.</small>
                  <small class="text-danger" *ngIf="myform.get('lawyerBankNameT').errors?.pattern">Please provide only text.</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankAccountT" placeholder="Enter Account Number" required pattern="^\d{6,8}$">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankAccountT').invalid && myform.get('lawyerBankAccountT').touched">Enter an account number’. </small>
                    <small class="text-danger" *ngIf="myform.get('lawyerBankAccountT').errors?.pattern">Account number must be between 6 and 8 digits.</small>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Reference Number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankBranchT" placeholder="Enter reference number" required> <!-- pattern="^[a-zA-Z\s]+$" -->
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankBranchT').invalid && myform.get('lawyerBankBranchT').touched">Please enter reference number. </small>
                     <!-- <small class="text-danger" *ngIf="myform.get('lawyerBankBranch').errors?.pattern">Please provide only number.</small> -->
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Sort Code <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankIfscCodeT" minlength="6" maxlength="6" placeholder="Enter Sort Code" required pattern="^\d{6}$">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawyerBankIfscCodeT').invalid && myform.get('lawyerBankIfscCodeT').touched">Enter SORT code. </small>
                    <small class="text-danger" *ngIf="myform.get('lawyerBankIfscCodeT').errors?.pattern">Enter a valid sort code like "309430".</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Account Type <span class="text-danger"></span></label>
                    <select class="form-control" formControlName="accountTypeT">
                      <option value="Buisiness">Buisiness</option>
                      <option value="Personal" >Personal</option>
                    </select>
                  </div>
                </div>
                

              </div>



                


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'lawfirm' || loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Law Firm Profile</label>
                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'lawFirmProfile')" />
                    <small *ngIf='dataArr.lawFirmProfileSrc'><b>{{dataArr.lawFirmProfileSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.lawFirmProfileSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.lawFirmProfileSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.lawFirmProfileSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('lawFirmProfile')" />
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Corporate Profile</label>
                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'corporateProfile')" />
                    <small *ngIf='dataArr.corporateProfileSrc'><b>{{dataArr.corporateProfileSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.corporateProfileSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.corporateProfileSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.corporateProfileSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('lawFirmProfile')" />
                      </div>
                    </div>
                  </div>
                </div> -->


                
              <div class="row"> 

                


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>CIN</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmCin"
                      placeholder="Enter CIN" >
                  </div> -->
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('lawFirmCin').invalid && myform.get('lawFirmCin').touched">Please enter CIN</small> -->
                <!-- </div> -->
              </div>

              <!-- <div class="row"> 
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>PAN<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" [attr.disabled]="viewMode" formControlName="lawFirmPan"
                      placeholder="Enter PAN" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmPan').invalid && myform.get('lawFirmPan').touched">Please enter valid PAN Number</small>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>TAN</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmTan"
                      placeholder="Enter TAN" >
                  </div> -->
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('lawFirmTan').invalid && myform.get('lawFirmTan').touched">Please enter TAN</small> -->
                <!-- </div>

              </div> -->

              <div class="row"> 

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Insurance </label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmFax"
                      placeholder="Enter Insurance" >
                  </div> 
                   <small class="text-danger"
                    *ngIf="myform.get('lawFirmFax').invalid && myform.get('lawFirmFax').touched">Please enter Insurance</small> 
                </div> -->

                

                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType != 'managingpartner'">
                  <div class="form-group">
                    <label>Partners</label>
                    <select class="form-control" formControlName="lawFirmPartners">
                        <option value="0">--Select--</option>
                        <option *ngFor="let partner of partnersList " [value]="partner">{{partner}}</option>
                    </select>
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType != 'managingpartner'">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerName"
                      placeholder="Enter Partner Name">
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType != 'managingpartner'">
                  <div class="form-group">
                    <label>Designation</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerDesignation"
                      placeholder="Enter Partner Designation">
                  </div>
                </div> -->

               


                


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Legal Managers</label>
                    <select class="form-control" formControlName="corporateManagers">
                        <option value="0">--Select--</option>
                        <option *ngFor="let manager of legalManagers " [value]="manager">{{manager}}</option>
                    </select>
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerName"
                      placeholder="Enter Manager Name">
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Designation</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerDesignation"
                      placeholder="Enter Manager Designation">
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerEmail"
                      placeholder="Enter Manager Email">
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'directorlegal'">
                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerPhoneNo"
                      placeholder="Enter Manager Mobile Number">
                  </div>
                  
                </div> -->

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner'">
                  <div class="form-group">
                    <label>Practice Areas<span class="text-danger">*</span></label>

                    <ng-select class="form-controll" [virtualScroll]=true [items]="practiceArea" bindLabel="subArea"
                      groupBy="mainArea" [multiple]="true" [closeOnSelect]="false" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [compareWith]="compareAccounts"
                      formControlName="lawFirmPracticeAreas" required>
                      <!-- <ng-template ng-optgroup-tmp let-item="item"> 
                        {{item.mainArea || 'Unnamed group'}}
                      </ng-template> -->
                      <ng-template ng-header-tmp>
                        <div class="btn btn-link"><span class="ng-value-label" (click)="onSelectAll()">Select All</span>
                        </div>
                        <div class="btn btn-link"><span class="ng-value-label" (click)="onClearAll()">Clear All</span>
                        </div>
                      </ng-template>

                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

                        <div class="ng-value" *ngFor="let item of items | slice:0:3">
                          <!-- <div class="ng-value" *ngFor="let item of items"> -->
                          <span class="ng-value-label">
                            {{item.subArea || 'Unnamed group'}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 3">
                          <span class="ng-value-label">{{items.length - 3}} more...</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <small class="text-danger"
                    *ngIf="myform.get('lawFirmPracticeAreas').invalid && myform.get('lawFirmPracticeAreas').touched">Please enter lawFirm practice areas</small>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
                  <div class="form-group">
                    <label>Linkedin</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmLinkedin"
                      placeholder="Enter LinkedIn">
                  </div>
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('lawFirmLinkedin').invalid && myform.get('lawFirmLinkedin').touched">Please enter Linkedin link</small> -->
                </div>

              </div>


              <div class="row"> 

<!-- 
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'managingpartner'">
                  <div class="form-group">
                    <label>Law Firm Address<span class="text-danger">*</span></label>
                    <textarea class="form-control" [attr.disabled]="viewMode" formControlName="address"
                      rows="3" placeholder="Enter address" required></textarea>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('address').invalid && myform.get('address').touched">Please
                    enter address.</small>
                </div> -->
                

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Trademark/Logo</label>

                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'logo')" accept="image/*" />
                    <small *ngIf='dataArr.orgLogo'><b>{{dataArr.orgLogo|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.orgLogo'>
                      <div id="clickHere">
                        <a href="{{dataArr.orgLogo}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.orgLogo'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('logo')"  required />
                      </div>
                    </div>
                    
                    <small class="text-danger" *ngIf="errorOnImgType">Only image file can upload here.</small>
                  </div>
                </div>
              </div>

              <div class="box-header">
                <h3 class="box-title" style="margin-left: -1%;font-size: 17px;"> <strong>Insurance Details</strong></h3>
             </div>
             <div class="table-responsive" *ngIf="!viewMode">
                <a [routerLink]="" style="text-decoration: underline;font-size: 15px;font-weight: 600;" data-toggle="modal" (click)="addNewHearingDetails()" data-target="#hearingInsertDialog" ><i class="fa fa-plus"></i > Add Insurance Details </a>
             
                <table class="table" *ngIf="newHearingList.length > 0">
                   <thead>
                      <tr>
                         <th width="50">Insurance Number</th>
                         <th width="50">Dates </th>
                         <th width="130">Documents</th>
                         <th width="20" >Action</th>
                      </tr>
                   </thead>
                   <tbody>
                      <tr *ngFor="let val of newHearingList; let i=index">
                         <td>
                            {{val.hearingJudje}}
                         </td>
                         <td>
                            {{val.hearingDate | date: 'dd/MM/yyyy'}}
                         </td>
                         <td>
                            
                            <!-- <div id="drop-zone" class="btn btn-default"> -->
                            <div id="clickHere" *ngIf="val.hearingOrder !=''">
                               <span *ngIf="val.orderDocName != 'null'">
                               <a href="{{val.hearingOrder !=''?val.hearingOrder:'#'}}"
                                  target="_blank">{{val.orderDocName !=''?val.orderDocName:'NA'}}</a>
                               </span>
                               <span *ngIf="val.orderDocName == 'null'">
                                  <span>N/A</span>
                               </span>
                            </div>
                            <a *ngIf="val.hearingOrder ==''" href="#">NA</a>
                            <!-- </div> -->
                         
                      </td>
                         <td>
                            <a  style="color: blue;" [routerLink]=""  (click)="edit(val)"><i class="fa fa-pencil"></i></a>
                            <a  style="color: red;margin-left:12%;" [routerLink]=""  (click)="deleteHearing(val)"><i class="fa fa-trash"></i></a>
                            
                               
                         </td>
                      </tr>
                   </tbody>
                </table>
             </div>

      <!--Hearing Details Model-->
      <div id="hearingInsertDialog" class="modal fade" role="dialog">
        <div class="modal-dialog">
           <div class="modal-content" >
              <div class="modal-header">
                 <h4 class="modal-title" *ngIf="!editNewHearingForm">Insert Insurance Details</h4>
                 <h4 class="modal-title" *ngIf="editNewHearingForm">Update Insurance Details</h4>
              </div>
              <div class="modal-body">
                 <form [formGroup]="newHearingForm">
                    
                       <div class="form-group">
                          <label>Insurance Number <span class="text-danger">*</span></label>
                          <input type=" text" class="form-control" placeholder="Enter Insurance Number..."
                                            formControlName="hearingJudje"/>
                          <small class="text-danger" *ngIf="newHearingForm.controls.hearingJudje.hasError('required') && (
                             newHearingForm.controls.hearingJudje.dirty || newHearingForm.controls.hearingJudje.touched ||
                                               (!newHearingForm.controls.hearingJudje.valid && subNewHearingForm))">Please Enter
                                            the Insurance Number.</small>
                          </div>
                    

                    
                       <div class="form-group">
                          <label>Date <span class="text-danger">*</span></label>
                          <input type="date" class="form-control" formControlName="hearingDate" required>
                          <!-- <div class="input-group date">
                             <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                             </div>
                          <input [owlDateTimeTrigger]="dt"  [owlDateTime]="dt" type="text"
                                            class="form-control pull-right" id="datepicker" placeholder="00/00/0000"
                                            formControlName="hearingDate" (keyup.enter)="OnInput($event)"
                                            (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                          </div>           
                                         <owl-date-time style="z-index: 999999999 !important;" #dt [pickerType]="'calendar'"></owl-date-time>

                                         <small class="text-danger" *ngIf="newHearingForm.controls.hearingDate.hasError('required') && (
                             newHearingForm.controls.hearingDate.dirty || newHearingForm.controls.hearingDate.touched ||
                                               (!newHearingForm.controls.hearingDate.valid && subNewHearingForm))">Please 
                                            enter the hearing date.</small>-->
                             
                          </div> 
                    
                          <div class="form-group" style="margin-top:3%" *ngIf = "editNewHearingForm">
                             <label>Previous Uploaded File</label>
                                <br>
                                <a *ngIf="newHearingOrderDocName != 'null'" href="{{newHearingOrderDocValue}}"
                                   target="_blank">{{newHearingOrderDocName}}
                                </a>
                                <span *ngIf="newHearingOrderDocName == 'null'">N/A</span>
                             
                          </div>
                       <div class="form-group">
                          <label>File Upload</label>
                          
                          <input type="file" #variable name="file" id="file" (change)="newHearingFIleUpload($event.target.files)" />
                          
                       </div>
                    
                 </form>
              </div>
              <div class="modal-footer">
                 <button type="submit" class="btn btn-primary pull-right" (click)="newHearingSave()">Submit </button>
                 <button type="button" class="btn btn-default pull-right" style="margin-right:5px;" data-dismiss="modal">Close</button>
              </div>
           </div>
        </div>
     </div>
     <!-- Confirm Hearing Delete Details-->
     <div class="modal fade in" id="deleteHiringNew" role="dialog">
        <div class="modal-dialog">
           <!-- Modal content-->
           <div class="modal-content">
              <div class="modal-header">
                 <h4 class="modal-title">Confirm to Delete Hearing</h4>
              </div>
              <div class="modal-body">
                 <p>Are you sure want to delete this hearing?</p>
              </div>
              <div class="modal-footer">
                 <button type="button" class="btn btn-primary" data-dismiss="modal" (click)='deleteNewHearingConfirmation()'>Yes</button>
                 <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
              </div>
           </div>
        </div>
     </div>
              

                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                     <label>Upload Profile Photo</label> 
                      <h4 *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'zonalmanager' || loginUserType == 'clerk' || loginUserType == 'accountant' ">PERSONAL INFO:</h4>
                      <h4 *ngIf="loginUserType != 'managingpartner'">MANAGING PARTNER INFO:</h4>
                      <h4 *ngIf="loginUserType == 'directorlegal'">DIRECTOR-LEGAL / VP LEGAL INFO:</h4>
                      <h4 *ngIf="loginUserType == 'individualclient'">INDIVIDUAL CLIENT:</h4>
                      <h4 *ngIf="loginUserType == 'corporateclient'">DIRECTOR/ PRINCIPAL OFFICER/AUTHORISED REPRESENTATIVE INFO:</h4>
                      
                      
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="firstName"
                      placeholder="Enter name">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('firstName').invalid && myform.get('firstName').touched">Please enter a first
                    name</small>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Middle Name </label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="middleName"
                      placeholder="Enter name">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('middleName').invalid && myform.get('middleName').touched">Please enter a middle
                    name</small>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Surname <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lastName"
                      placeholder="Enter name">
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lastName').invalid && myform.get('lastName').touched">Please enter a
                    surname</small>
                </div>
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'zonalmanager' || loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Designation</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="orgDesignation"
                      placeholder="Enter Designation name">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Mobile no</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="phoneNo"
                      placeholder="Enter phone no">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Office Phone no</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="officePhoneNo"
                      placeholder="Enter phone no">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Qualification</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="qualification"
                      placeholder="Enter Qualification">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>University</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="university"
                      placeholder="Enter University">
                  </div>
                </div> -->

                <!-- 
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm'">
                  <div class="form-group">
                    <label>Chember Address<span class="text-danger">*</span></label>
                    <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                      formControlName="chamberAddress" placeholder="Enter chamber address"></textarea>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('chamberAddress').invalid && myform.get('chamberAddress').touched">Please
                    enter chamber office.</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Office Address<span class="text-danger">*</span></label>
                    <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                      formControlName="officeAddress" placeholder="Enter office address"></textarea>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('officeAddress').invalid && myform.get('officeAddress').touched">Please
                    enter office address.</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Residencial Address<span class="text-danger">*</span></label>
                    <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                      formControlName="residencialAddress" placeholder="Enter recidential address"></textarea>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('residencialAddress').invalid && myform.get('residencialAddress').touched">Please
                    enter residential address.</small>
                </div>
                
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>State</label>
                      <select (change)="onSelectState($event.target.value, 1)" class="form-control" formControlName="stateId">
                        <option value="0">--Select--</option>
                        <option *ngFor="let state of statelist " [value]="state.id">{{state.stateName}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>District</label>
                      <select (change)="onSelectDistrict($event.target.value, 1)" class="form-control" formControlName="districtId">
                            <option value="0">--Select--</option>
                            <option *ngFor="let district of districtlist" [value] = "district.id">{{district.districtName}}</option>
                      </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>City</label>
                      <select (change)="onSelectState($event.target.value,0)" class="form-control" formControlName="cityId">
                        <option value="0">--Select--</option>
                        <option *ngFor="let city of cityList " [value]="city.id">{{city.stateName}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Flat/House No/Building/Floor/Room No</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="houseNo"
                      placeholder="Enter house no">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Street/Road</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="streetName"
                      placeholder="Enter street name">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Landmark</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="landMark"
                      placeholder="Enter Landmark">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Pincode</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="pinCode"
                      placeholder="Enter pincode">
                  </div>
                </div> -->

              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel'  || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                    <div class="form-group">
                      <label>Upload Profile Photo</label>

                      <input type="file" name="profilepic" (change)="handleFileInput($event.target.files,'profilepic')" required/>

                      <small *ngIf='dataArr.profilePicSrc'><b>{{dataArr.profilePicSrc|fileName}}</b><br></small>
                      <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.profilePicSrc'>
                        <div id="clickHere">
                          <a href="{{dataArr.profilePicSrc}}" target="_blank" rel="noopener noreferrer"><i
                              class="fa fa-eye"></i></a>
                        </div>
                      </div>
                      <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.profilePicSrc'>
                        <div id="clickHere"><i class="fa fa-trash"></i>
                          <input type="buton" name="delImg" (click)="removeDoc('profilepic')" required/>
                        </div>
                      </div>

                    </div>
                </div>
              </div>

                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Upload Profile Photo</label>
                      <h4 *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">PROFESSIONAL INFO:</h4>
                     
                     <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                      data-toggle="modal" data-target="#saveIntent">Add</button> 
                  
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'clerk'">
                  <div class="form-group">
                    <label>State Bar Council</label>
                      <select (change)="onSelectState($event.target.value, 2)" class="form-control" formControlName="stateBarCouncilId">
                        <option value="0">--Select--</option>
                        <option *ngFor="let state of statelist " [value]="state.id">{{state.stateName}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'clerk'">
                  <div class="form-group">
                    <label>District</label>
                      <select (change)="onSelectDistrict($event.target.value, 2)" class="form-control" formControlName="districtBarId">
                            <option value="0">--Select--</option>
                            <option *ngFor="let district of districtbarlist" [value] = "district.id">{{district.districtName}}</option>
                      </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'clerk'">
                  <div class="form-group">
                    <label>Courts Of Practice</label>
                      <select [attr.disabled]="viewMode ? '' : null" (change)="updateCourtsPractice($event.target.value)" class="form-control" formControlName="courtsOfPractice" [attr.disabled]="viewMode ? '' : null">
                              <option value=""><strong>Select court</strong></option>
                              <option *ngFor="let opt of courtBarlist" [value]="opt.id">
                              <strong>{{opt.courtName}}</strong>
                              </option>
                      </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel'">
                  <div class="form-group">
                    <label>Enrollment No</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="enrollmentNo"
                      placeholder="Enter enrollment number">
                  </div>
                </div>
                 -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'managingpartner'">
                  <div class="form-group">
                    <label>Practice Areas</label>
                    <ng-select class="form-controll" [virtualScroll]=true [items]="practiceArea" bindLabel="subArea"
                      groupBy="mainArea" [multiple]="true" [closeOnSelect]="false" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [compareWith]="compareAccounts"
                      formControlName="lawyerPracticeAreas">
                      
                      <ng-template ng-header-tmp>
                        <div class="btn btn-link"><span class="ng-value-label" (click)="onSelectAll()">Select All</span>
                        </div>
                        <div class="btn btn-link"><span class="ng-value-label" (click)="onClearAll()">Clear All</span>
                        </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:3">
                          
                          <span class="ng-value-label">
                            {{item.subArea || 'Unnamed group'}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 3">
                          <span class="ng-value-label">{{items.length - 3}} more...</span>
                        </div>
                      </ng-template>
                    </ng-select>
                   
                  </div>
                </div> -->



                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'individualclient'">
                  <div class="form-group">
                    <label>Profession</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="profession" placeholder="Enter profession">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>ICAI REG. No.</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="icaiRegNo" placeholder="Enter ICAI Reg Number">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Linkedin Profile Link</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode"
                      formControlName="linkedinProfileSrc" placeholder="Enter linkedin profile link">
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Upload Resume </label>
                    <input type="file" name="resume" (change)="handleFileInput($event.target.files,'resume')" />
                    <small *ngIf='dataArr.resumePathToS3'><b>{{dataArr.resumePathToS3|fileName}}</b><br></small>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.resumePathToS3'>
                      <div id="clickHere">
                        <a href="{{dataArr.resumePathToS3}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.resumePathToS3'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Upload Adhar Card</label>
                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'aadhar')" />
                    <small *ngIf='dataArr.aadharNoSrc'><b>{{dataArr.aadharNoSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.aadharNoSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.aadharNoSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.aadharNoSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('aadhar')" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="form-group">
                    <label>Upload PAN Card </label>
                    <input type="file" name="pan" (change)="handleFileInput($event.target.files,'pan')" />
                    <small *ngIf='dataArr.panNoSrc'><b>{{dataArr.panNoSrc|fileName}}</b><br></small>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.panNoSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.panNoSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.panNoSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('pan')" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                      <h4 *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">ADDRESS INFO:</h4>
                  </div>
                </div>
                ADDRESS BLOCK START
                  <div class="" *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                  <div class="">
                    <app-addresses></app-addresses>
                  </div>
                </div>  -->
                  
                <!--ADDRESS BLOCK END-->
                <!-- <h1>OLD</h1>
                
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" [attr.disabled]="viewMode" formControlName="emailId"
                      placeholder="Enter email">
                  </div>
                </div> -->
                

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Pan Number</label>
                    <input type="text" [attr.disabled]="viewMode" class="form-control" formControlName="panNoSrc"
                      placeholder="Enter pan number">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Aadhar Number</label>
                    <input type="text" [attr.disabled]="viewMode" class="form-control" formControlName="aadharNoSrc"
                      placeholder="Enter aadhar number">
                  </div>
                </div> -->




                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Bar Council of India Id</label>
                    <input type="text" class="form-control" formControlName="barCouncilOfIndiaId"
                      placeholder="Enter bar council of india id">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>State Bar Council Id</label>
                    <input type="text" class="form-control" formControlName="stateBarCouncilId"
                      placeholder="Enter state bar council id">
                  </div>
                </div>
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Courts of Practice</label>
                    <select class="form-control">
                      <option>--select--</option>
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="myform.get('userType').value=='lawyerassociate'||myform.get('userType').value=='lawyeroffcouncil'">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Designation</label>
                      <input type="text" class="form-control" formControlName="memberDesignation"
                        placeholder="Enter designation">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Account Number (to be connected to the financial section)</label>
                      <input type="text" class="form-control" formControlName="lawyerBankAccount"
                        placeholder="Enter bank account number">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Name</label>
                      <input type="text" class="form-control" formControlName="lawyerBankName"
                        placeholder="Enter bank name">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Branch Name</label>
                      <input type="text" class="form-control" formControlName="lawyerBankBranch"
                        placeholder="Enter bank branch name">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank IFSC Code</label>
                      <input type="text" class="form-control" formControlName="lawyerBankIfscCode"
                        placeholder="Enter bank ifsc code">
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <!--ADDRESS BLOCK START-->
                  <div class=""  *ngIf="loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'managingpartner'">
                    <div class="">
                      <org-addresses></org-addresses>
                    </div>
                  </div> 
                </div>
                
                


              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group top-height">
                    <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                      data-toggle="modal" data-target="#saveIntent" *ngIf="saveMode">Save</button>
                  </div>
                </div>
              </div>

              
              
              <!-- </div> -->



            </div>
          </div>
        </div>
      </form>


      <!-- <div class="box-body" id="resultsection" *ngIf="resultShow!=undefined">
        <div class="box">
          <div class="box-body">
           
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">

                  <div class="card-header1" for="staticEmail">
                    <b>Result Section :</b>
                  </div>
                  <div class="card-header2">
                    <i class="mdi mdi-check-circle"></i>
                    <b> {{resultShow}} {{statusCode}}
                      <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
                    </b>
                  </div>
                  <ng-container *ngIf="resultBtnShow">
                    <div class="card-body2">
                      <h5>Output</h5>
                      <p>{{mydata.responseMessage}} </p>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div> -->


    </div> 

  </section>
  <!-- Modal section end -->
  <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm to Submit</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure to submit?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="saveAllData()">Yes</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
        </div>
      </div>

    </div>

  </div>
  <!-- Modal section end -->
  <!-- <div id="privilegemyModal{{teamIndex}}" class="modal fade" role="dialog">
    <div class="modal-dialog">
       <div class="modal-content">
          <div class="modal-header">
             <button type="button" class="close" data-dismiss="modal">&times;</button>
             <h4 class="modal-title">Assign Privilege {{memberName}}</h4>
          </div>
          <div class="modal-body">
             <div class="table-responsive">
                <table class="table">
                   <thead>
                      <tr>
                         <th>Folder</th>
                         <th>Upload</th>
                         <th>Delete</th>
                         <th>View</th>
                      </tr>
                   </thead>
                   <tbody formArrayName="permission">
                      <tr
                      *ngFor="let perimission of teamPermissions(teamIndex).controls; let
                      permissionIndex=index"
                      [formGroupName]="permissionIndex">
                      <td>
                         
                         <input class="form-control" type="hidden" placeholder="folder name"
                            formControlName="id">
                         <input class="form-control" type="text" placeholder="folder name"
                            formControlName="folderName" readonly>
                      </td>
                      <td>
                         <input type="checkbox" value="upload" formControlName="upload">
                      </td>
                      <td>
                         <input type="checkbox" value="delete" formControlName="delete">
                      </td>
                      <td>
                         <input type="checkbox" value="view" formControlName="view">
                      </td>
                      </tr>
                   </tbody>
                </table>
             </div>
          </div>
          <div class="modal-footer">
             <button type="button" class="btn btn-primary pull-left"
                (click)="privilegemyModal(teamIndex,'hide')">Submit</button>
             <button type="button" class="btn btn-default pull-right"
                data-dismiss="modal">Close</button>
          </div>
       </div>
    </div>
 </div> -->

 


 <div class="modal fade in" id="removeFileConfirm" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm to Delete</h4>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="removeFileConfirm('Y')">Yes</button>
        <button type="button" class="btn btn-default" data-dismiss="modal"  (click)="removeFileConfirm('N')">No</button>
      </div>
    </div>
  </div>
</div>