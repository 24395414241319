import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { ActivatedRoute, RouteReuseStrategy, Router } from '@angular/router';
@Component( {
  selector: 'app-caselistAll',
  templateUrl: './caselistAll.component.html',
  styleUrls: ['./caselistAll.component.css']
} )
export class CaseListAllComponent implements OnInit {

  userlist=[];
  permittedCaseList=[];
  dataArr: any[]=[];
  saveDisplay='none';
  mydata: any;
  userId: string;
  modalDisplay: boolean=false;
  caseviewpermission:string;
  loginUserType = JSON.parse(localStorage.getItem('profileInfo')).usertype;
  loginuserId:string;
  superUserId:string;

  constructor( private legalbellService: LegalbellService, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit() {
  /* this.caseviewpermission = localStorage.getItem('caseviewpermission')?localStorage.getItem('caseviewpermission'): null;
  console.log("caseviewpermission:::",this.caseviewpermission);
  if(this.caseviewpermission=='view'){
    this.loginuserId=localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("login userId:::",this.userId); 
  }  */
    this.loginuserId=localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
      console.log("login userId:::",this.userId);
      this.superUserId=localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    this.loginUserType=localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;

    this.route.paramMap.subscribe( params => {
      this.userId=params.get( 'id' );
    } );
    /* if(this.userId!=null){
    this.getCaseList( this.userId );
    }else{
      this.getCaseList( this.loginuserId );
    } */
    

    if(this.loginUserType == 'managingpartner'){
      this.getAllCaseList();
    } else {
      this.getPermittedCaseList();
    }
  }

  //get Permitted Cases
  getPermittedCaseList() {
    this.legalbellService.getPermittedCaseList( this.loginuserId ).
      subscribe(
        data => {
          this.permittedCaseList=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "Get All Permitted Cases List comp res--"+JSON.stringify( this.permittedCaseList ) );
        }
      );
      //console.log("CASE 1"+JSON.stringify(this.permittedCaseList["0"]));

      
  }

  //get All Cases List
  getAllCaseList() {

    this.legalbellService.getAllCaseList( this.loginuserId ).
      subscribe(
        data => {
          this.permittedCaseList=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "Get All Cases List comp res--"+JSON.stringify( this.permittedCaseList ) );
        }
      );
  }
  
  /* getCaseList( userid: string ) {
    console.log("userid in caselist::",userid);
    this.legalbellService.getCaseList( userid ).
      subscribe(
        data => {
          this.userlist=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "getCaseList comp res--"+JSON.stringify( this.userlist ) );
        }
      );
      //location.reload();
  } */
  closeSaveModal() {
    console.log( 'closeSaveModal---' );
    this.saveDisplay='none';
  }
  deleteCorfirmation( userid: string ) {
    console.log( "userid to delete::", userid );
    this.userId=userid;
    this.modalDisplay=true;
  }
  deleteData() {
    this.legalbellService.deactivateUser( this.userId ).
      subscribe(
        data => {
          this.mydata=JSON.parse( this.legalbellService.userData._body );
          console.log( "statusCode comp res--"+this.mydata.status );
          if ( this.mydata.status=='200' ) {
            window.alert( "User deactivated successfully." );
            //this.getUserList(); 
            this.router.navigate( ['/userlist'] );
          }
          else {
            window.alert( "User not deactivated successfully." );
          }
        }
      );
    this.closeSaveModal();
  }

  goToPage( pageName: string ) {

    localStorage.setItem( "refresh", 'refresh' );
  }
}
