import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import countryCodeList from '../../static/phone-country-code.list';

const DEFAULT_COUNTRY_CODE = '+44';
@Component({
  selector: 'app-country-code-dropdown',
  templateUrl: './country-code-dropdown.component.html',
  styleUrls: ['./country-code-dropdown.component.css']
})
export class CountryCodeDropdownComponent implements OnInit {

  public countryCodeList: Array<any> = countryCodeList;
  public currentSelection: any;
  @Input() defaultCountryName: string;
  @Input() countryCode: string;
  @Input() withBorder: boolean = false;
  @Output() onCountryChange = new EventEmitter(); 
  constructor() { }
  
  ngOnInit(): void {
    this.countryCode = this.countryCode ? this.countryCode : DEFAULT_COUNTRY_CODE;
    this.onCountryChange.emit(this.countryCode);
  }

  onChnage(evt) {
      this.onCountryChange.emit(evt.target.value);
  }

  isThisDefaultCountry(currentCountryCode) {
    return this.isSameCountry(currentCountryCode, this.countryCode);
  }

  isSameCountry(countryOneCode, countryTwoCode) {
    return countryOneCode === countryTwoCode;
  }

}
