import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
//import {PharasalsearchComponent} from '../../pharasalsearch/pharasalsearch.component'
import { LegalbellService } from '../../service/legalbell.service';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

import { MessageService } from '../../service/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  
  showMenu: boolean = true;
  subscription: Subscription;
  usertype: String;
  isDashboard : boolean = false;
  privilegeList : any[] = [];
  privilegeArray: String[] = [];
  allPrivilege : boolean = false;
  addPrivilege : boolean = false;
  editPrivilege : boolean = false;
  fadPrivilege : boolean = false;
  downloadBaseUrl: any;
  userTitle:string;
  firstName:string;
  lastName:string;
  orgDesignation:string;
  userId:string;
  notificationCount:string;
  constructor(private router: Router,private LegalbellService: LegalbellService, private dataService: MessageService ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
      ////console.log("router: ", event.url);
      
      if(event.url == event.urlAfterRedirects){
		
			if(event.url == '/' || event.url == '/login'){
				this.showMenu = false;
			} else {
				this.showMenu = true;
			}
		} else {
			this.showMenu = false;
		}
      
      });
   }
  

  ngOnInit() {
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.userId = userData.userIdlogin;
    this.getAllNotification(this.userId)
    this.LegalbellService.currentData.subscribe(currentData => this.notificationCount = currentData)
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    //console.log(JSON.parse(localStorage.getItem('profileInfo')));
    this.userTitle = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userTitle:'';
    this.firstName = localStorage.getItem( 'profileInfo' ) ? ' '+JSON.parse( localStorage.getItem( 'profileInfo' ) ).firstName:'';
    this.lastName = localStorage.getItem( 'profileInfo' ) ? ' '+JSON.parse( localStorage.getItem( 'profileInfo' ) ).lastName:'';
    this.orgDesignation = localStorage.getItem( 'profileInfo' ) && JSON.parse( localStorage.getItem( 'profileInfo' ) ).orgDesignation!='' ? '-'+JSON.parse( localStorage.getItem( 'profileInfo' ) ).orgDesignation:'';
    

    // this.downloadBaseUrl = environment.api;

    // this.subscription = this.dataService.getMessage().subscribe(message => {
    //   //console.log("privilegeuser")
    //   if (message.event == 'privilegeuser') {
    //     this.getPrivilegeUser('');
    //   }
    // });
    // this.getPrivilegeUser('');
  }
  
  logout(){
    //console.log("remove::");
    console.log("remove in header component::");
    localStorage.removeItem("caseviewpermission");
    localStorage.removeItem("contractpermission");
    localStorage.removeItem("profileInfo");
    // window.location.href='/login';
    this.router.navigate(['/login']);
    //window.open('/login');
  }
  // getPrivilegeUser(faId : any){
  //   //console.log("functional area id:",faId);
  //   this.LegalbellService.getPrivilegeOfUser(faId).
  //     subscribe(
  //       data => {
  //         this.privilegeList = JSON.parse(this.LegalbellService.userData._body).respData;
  //         //console.log("getPrivilegeUser in header--" + JSON.stringify(this.privilegeList));
  //         this.privilegeList.forEach(privilege=>{
  //           const privilegeId = privilege.privId;
  //           this.privilegeArray.push(privilegeId);
  //         })
  //         //console.log("privilegeArray in header--" + JSON.stringify(this.privilegeArray));
  //         if(this.privilegeArray.includes("ADM")){
  //           this.allPrivilege = true;
  //           this.fadPrivilege = true;
  //           this.editPrivilege = true;
  //           this.addPrivilege = true;
  //           //console.log("allPrivilege in header::",this.allPrivilege);
  //         }
  //         if(this.privilegeArray.includes("CR")){
  //           this.addPrivilege = true;
  //           //console.log("addPrivilege in header::",this.addPrivilege);
  //         }
  //         if(this.privilegeArray.includes("ED")){
  //           this.editPrivilege = true;
  //           //console.log("editPrivilege in header::",this.editPrivilege);
  //         }
  //         if(this.privilegeArray.includes("FAD")){
  //           this.fadPrivilege = true;
  //           this.addPrivilege = true;
  //           //console.log("fadPrivilege in header::",this.fadPrivilege);
  //         }
  //       }
  //     );
      
  //     ////console.log("allPrivilege::",this.allPrivilege);
  // }

  getAllNotification(userId){
  
    this.LegalbellService.getNotificationDetails(userId).
    subscribe(
      data => {
        this.notificationCount = JSON.parse(this.LegalbellService.userData._body).searchCount;
       
      
      }
    );
    
  
  }
}