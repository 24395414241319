<div class="content-wrapper">

  <section class="content-header">
    <h1> Reset Password <small> </small> </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-user-circle-o"></i> Reset Password </li>
    </ol>
  </section>


  <section class="content">
    <div class="box card-body box-info ">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4"></div>

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <form [formGroup]="resetForm">
          <div class="Passwordbox">
            <label>Current Password</label>
            <div class="form-group pass_show">
              <input type="password" class="form-control" placeholder="Current Password" formControlName="userPwdOld">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                <small class="text-danger" *ngIf="resetForm.controls.userPwdOld.hasError('required') && ( resetForm.controls.userPwdOld.dirty || resetForm.controls.userPwdOld.touched || (!resetForm.controls.userPwdOld.valid && submittedForResetpassword))">This field is required.</small>
            </div>
            <label>New Password</label>
            <div class="form-group pass_show">
              <input type="password" class="form-control" placeholder="New Password" formControlName="userPwd">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                <small class="text-danger" *ngIf="resetForm.controls.userPwd.hasError('required') && ( resetForm.controls.userPwd.dirty || resetForm.controls.userPwd.touched || (!resetForm.controls.userPwd.valid && submittedForResetpassword))">This field is required.</small>
                <small class="text-danger" *ngIf="resetForm.controls.userPwd.hasError('pattern')">Password should
                  contain at least one lower case and upper case,one special character, one number and should be 8
                  characters or more.</small>
            </div>
            <label>Confirm Password</label>
            <div class="form-group pass_show">
              <input type="password" class="form-control" placeholder="Confirm password" formControlName="cpassword">
                  <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                  <small class="text-danger" *ngIf="resetForm.controls.cpassword.hasError('required') && ( resetForm.controls.cpassword.dirty || resetForm.controls.cpassword.touched || (!resetForm.controls.cpassword.valid && submittedForResetpassword))">This field is required.</small>
               
                  <small class="text-danger"  *ngIf="resetForm.controls.cpassword.value!='' && resetForm.controls.cpassword.value!=resetForm.controls.userPwd.value &&  resetForm.controls.cpassword.errors">Passwords must match</small>
            </div>
            <button type="button" (click)="formSubmit()" class="btn btn-primary btn-block btn-flat">RESET</button>
          </div>
                <div class="card-body box-info" *ngIf="mes !='' || mes1!=''" style="margin-top: 15px;">
            
                  <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">{{mes}}</span>
                  <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>

                  
              </div>
          </form>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4"></div>

      </div>

    </div>
  </section>
</div>