<div class="content-wrapper">
  <div class="content">
    <div *ngIf="loader" class="spinnerbox">  
      <div class="spinner"></div>
   </div> 
    <div class="bottom-height">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-24 md-dark">payments</i></div>
        <h3 class="box-title main-header-top">Overhead Expenses</h3>
      </div>
      <div class="box-body">
          
         <div class="box">
          <div class="box-body">
          <form [formGroup]="myform" *ngIf="myform">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th width="50">Sl No </th>
                    <th width="200">Particulars</th>
                    <th width="200">Rate <small>(£)</small></th>
                    <th width="200">Reminder</th>
                    <th width="50"><button type="button"  *ngIf="show" class="btn btn-primary" (click)="addNewExpense()">Add</button></th>
                  </tr>
                </thead>
                <tbody>
                  <tbody formArrayName='expensesArr' *ngIf="myform.get('expensesArr')">
                          
                    <tr *ngFor="let item of myform.get('expensesArr')['controls']; let i=index;"> 
                      <ng-container [formGroup]="item">
                        <td item-width="100%" >
                            {{i+1}}
                        </td> 
                        <td item-width="20%">
                            <input type="text" formControlName ="particularName" oninput="this.value = this.value.toUpperCase()" (keyup)="sendit($event.target.value)">
                            <input type="hidden" formControlName="particularsId"> 
                        </td>  
                        <td item-width="20%">
                            <input type="number" formControlName ="amount" min="0"> 
                        </td> 
                        <td item-width="20%">
                          <div class="input-group owl-date-time">
                            <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                            </div>
                            <input [min] = "todayDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" class="form-control pull-right"  formControlName="reminderDateTimeString" readonly>
                           <!-- formControlName="reminderDateTimeString" -->
                         </div>
                         <owl-date-time #dt ></owl-date-time>
                        </td>
                        <!-- <td></td> -->
                       </ng-container>  
                    </tr>
                    <!-- <tr>
                      <td>#</td>
                      <td><strong>Total</strong></td>
                      <td>
                        <div class="form-control"> </div>
                      </td>
                    </tr> -->
                  </tbody>

              </table>
            </div>
           </form>
          </div>
         <!--  <div><p class="text-danger">{{mes}}</p>
            <p class="text-success">{{mes1}}</p></div> -->
          <div class="box-footer">
            <!-- <button class="btn btn-primary pull-left"> Back </button> -->
            <button type="submit" class="btn btn-primary pull-right" data-toggle="modal" data-target="#saveIntent">Update</button>
            
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- Resullt section start -->
<div class="row" id="resultsection" *ngIf="resultShow!=undefined">
  <div class="col-sm-12">
    <div class="form-group">  

      <div class="card-header1" for="staticEmail">
        <b>Result Section :</b>
      </div>
      <div class="card-header2">
        <i class="mdi mdi-check-circle"></i>
        <b> {{resultShow}} {{statusCode}}
          <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
        </b>
      </div>
      <!-- <ng-container *ngIf="resultBtnShow">
        <div class="card-body2">
          <h5>Output</h5>
          <p>{{mydata.responseMessage}} </p>
        </div>
      </ng-container> -->
   
  </div>
  </div>
</div>
<!-- Result section end -->
<div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay" >
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm to Submit</h4>
      </div>
      <div class="modal-body">
        <p>Are you sure to submit?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveAllData()">Yes</button>
        <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
      </div>
    </div>   
  </div>
</div>

