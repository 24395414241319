<div class="content-wrapper">
  <div *ngIf="loader" class="spinnerbox">  
    <div class="spinner"></div>
  </div>
  <div class="content">
    <div class="bottom-height">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-18 md-dark">miscellaneous_services</i></div>
        <h3 class="box-title main-header-top">Schedule of Fees</h3>
      </div>
      <div class="box-body">
        <div class="box">

          <div class="box-body">
            <div class="box-body" *ngIf="showMsg">
              <div class="box">
                <div class="box-body">
                  <div>
                    <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; Schedule of Fees updated successfully.</span>
                    <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-body" *ngIf="servicemasterpermission!='view'">
              <!-- <button type="submit" class="btn btn-primary pull-right"  data-toggle="modal" data-target="#saveIntent">Submit</button> -->
              
              
              <button type="button" class="btn mr-2 btn-primary pull-right" (click)="addNewFees()"><i class="material-icons md-14 md-light ">add</i>ADD</button>
            </div>
            <form [formGroup]="myform">
              <div class="table-responsive">
                  <table class="table  table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead  class="thead-dark">
                          <tr>
                          <th>SL NO</th>
                          <th>Category <span class="text-danger">*</span></th>
                          <th>Description <span class="text-danger">*</span></th>
                          
                          <th> Rate <small>(£)</small> <span class="text-danger">*</span></th>
                          <th>VAT Applicable <span class="text-danger">*</span></th>
                          <th>ACTION</th>
                          <!-- <th width="50"><button type="button" class="btn btn-primary" (click)="addNewFees()">Add</button></th>
                          <th width="50"><button type="submit" class="btn btn-primary pull-right" class="btn btn-primary pull-right" data-toggle="modal" data-target="#saveIntent">Submit</button></th> -->
                          </tr>
                      </thead>
                        
                        <tbody formArrayName='serviceArr' *ngIf="myform.get('serviceArr')">
                        
                          <tr *ngFor="let item of myform.get('serviceArr')['controls']; let i=index;"> 
                            <ng-container [formGroup]="item">
                            <td>{{i+1}}</td>
                            <td>
                                <input type="text" formControlName ="tribunals" (keyup)="validate($event.target.value)" (keyup)="abc();" size="30" required>

                            </td> 
                            <td>
                                <input type="text" formControlName ="particularsOfServices" (change)="validate($event.target.value)" size="30" > 
                            </td>
                            
                            <td>
                                <input type="number" formControlName ="amount" size="8" min="0"> 
                            </td> 
                            <td>
                              <select formControlName ="hour">
                                <option value="N">No</option>
                                <option value="Y">Yes</option>
                              </select>
                              <!-- <input type="text" formControlName ="hour" size="8">  -->
                             </td>  
                            <td style="text-align: center;">
                            <button type="button"  class="btn btn-danger"
                              (click)="removeFees(i)">
                              <i class="fa fa-minus" aria-hidden="true"></i>
                           </button>
                           </td>
                          </ng-container>  
                          </tr>
                        </tbody>
                  </table>
                </div> 
            </form>
          </div>
          <div class="box-footer">
            <button class="btn  btn-grey pull-left"> Back </button>
            <button type="submit" class="btn btn-primary pull-right" class="btn btn-primary pull-right" data-toggle="modal" data-target="#saveIntent" *ngIf="servicemasterpermission!='view'">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Resullt section start -->
<div class="row" id="resultsection" *ngIf="resultShow!=undefined">
<div class="col-sm-12">
  <div class="form-group">  

    <div class="card-header1" for="staticEmail">
      <b>Result Section :</b>
    </div>
    <div class="card-header2">
      <i class="mdi mdi-check-circle"></i>
      <b> {{resultShow}} {{statusCode}}
        <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
      </b>
    </div>
    <ng-container *ngIf="resultBtnShow">
      <div class="card-body2">
        <h5>Output</h5>
        <p>{{mydata.responseMessage}} </p>
      </div>
    </ng-container>
 
</div>
</div>
</div>
<!-- Result section end -->
<div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay" >
<div class="modal-dialog">
  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Confirm to Submit</h4>
    </div>
    <div class="modal-body">
      <p>Are you sure to submit?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveAllData()">Yes</button>
      <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
    </div>
  </div>   
</div>
</div>