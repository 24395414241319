<div class="content-wrapper">
  <section class="content">
    <div class="box-header with-border">
      <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">groups</i></div>
      <h3 class="box-title main-header-top">Team Setup</h3>
    </div>
    <div class="box-body">
      <div class="box bottom-height">
        <div class="box-body">
          <div class="row" *ngIf="teamsetupPermission!='view'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
              <button type="button" class="btn btn-primary pull-right Role" (click)='checkUserCount(1)' >Add new team member</button>
              <button type="button" class="btn btn-primary pull-right Role" (click)='checkUserCount(2)' style="margin-right: 1%;" >Add new team partner</button>
              <!-- <a href="/invitemember" class="btn btn-primary pull-right Role" style="margin-right: 1%;"> 
                <span> &nbsp;Invite team partner</span> </a> -->
              
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive ">
               
                  <!-- <thead class="thead-dark">
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>E-mail</th>
                      <th>Phone</th>
                      <th>Invitation Sent</th>
                      <th>Account Activated</th>
                      <th *ngIf="teamsetupPermission!='view'">Action</th>
                    </tr>
                  </thead> -->
                  
                    <ul *ngFor="let item of userlist" class="team-view">
                      <li><h4>{{item.orgDesignation}}</h4></li>
                      <li><i class="fa-solid fa-user"></i> Name : {{item.firstName}} {{item.lastName}}</li>
                      <li><i class="fa-solid fa-at"></i> Email : {{item.emailId}} </li>
                      <li><i class="fa-solid fa-phone"></i> Phone No. : {{item.phoneNo}} </li>
                      <li><i class="fa-solid fa-envelope-circle-check"></i> Invitation Sent : {{item.invitationSent}} </li>
                      <li><i class="fa-solid fa-user-check"></i> Account activated : {{item.activeAccount}} </li>
                      <li class="act-btn">
                        <div>
                          <!-- <a [routerLink]="['/teamprofile',item.userId,item.userType,'view']" routerLinkActive="router-link-active" >
                                  <button type="button" class="btn btn-primary"><i class="fa fa-eye"
                                      aria-hidden="true"></i></button>
                                  </a> -->
                          <a [routerLink]="['/teamprofile',item.userId,item.userType]"
                            routerLinkActive="router-link-active">
                            <button *ngIf="teamsetupPermission!='view'" type="button" class="btn btn-edit btn-success"><i class="material-icons md-14 md-dark">edit</i></button>
                          </a>
                          <!-- <button type="button" class="btn btn-danger"><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                        </div>
                      </li>
                    </ul>


                  
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <button type="button" class="btn btn-grey">Back</button>
            </div>
          </div> -->

        </div>
      </div>
    </div>



  </section>
</div>
<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)='modalclose()'>&times;</button>
        <h4 class="modal-title">New Team Member</h4>
      </div>
      <form [formGroup]="inviteNewMemberForm">
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Type Of Role<span class="text-danger">*</span></label>
                <select class="form-control" formControlName="teamUserType" required>
                  <option value="" disabled>--Select--</option>
                  <option *ngFor="let membertype of memberTypelist " [value]="membertype.teamUserType">
                    {{membertype.teamUserTypeName}}</option>
                </select>
                <small class="text-danger"
                  *ngIf="inviteNewMemberForm.get('teamUserType').invalid && inviteNewMemberForm.get('teamUserType').touched">Please
                  choose type first.</small>
              </div>
            </div>


            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>First Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="firstname" placeholder="Enter ..."
                  pattern="^[a-zA-Z\s]+$">
                <small class="text-danger"
                  *ngIf="inviteNewMemberForm.get('firstname').invalid && inviteNewMemberForm.get('firstname').touched">Please
                  provide member's first name in text format only. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('firstname').errors?.pattern">Please provide
                  only text for member name.</small> -->
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Last Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="lastname" placeholder="Enter ..."
                  pattern="^[a-zA-Z\s]+$">
                <small class="text-danger"
                  *ngIf="inviteNewMemberForm.get('lastname').invalid && inviteNewMemberForm.get('lastname').touched">Please
                  provide member's last name in text format only. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('lastname').errors?.pattern">Please provide
                  only text for member name.</small> -->
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>E-mail address <span class="text-danger">*</span></label>
                <input type="email" class="form-control" formControlName="mailid" placeholder="Enter ..."
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                <small class="text-danger"
                  *ngIf="inviteNewMemberForm.get('mailid').invalid && inviteNewMemberForm.get('mailid').touched">Please
                  provide valid email address. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                  correct email.</small> -->
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Phone Number <span class="text-danger">*</span></label>
                <!-- <div style="display: flex;">
                    <app-country-code-dropdown
                      [countryCode]="countryCodeMobilePh"
                      (onCountryChange)="onCountryCodeMobilePhChange($event)"
                      [withBorder]="true"
                    ></app-country-code-dropdown>
                  <input type="tel" class="form-control" formControlName="phoneno" placeholder="Enter ..."
                  pattern="^(?:\(?([0-9]{3})\)?[-.●]?)?([0-9]{3})[-.●]?([0-9]{4})$">
                </div> -->
                <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="phoneno" required>
                              </ngx-intl-tel-input>
                <!-- <small class="text-danger"
                  *ngIf="inviteNewMemberForm.controls.phoneno.hasError('required') && ( inviteNewMemberForm.controls.phoneno.dirty&&!submittedForinviteMember || inviteNewMemberForm.controls.phoneno.touched&&!submittedForinviteMember || (!inviteNewMemberForm.controls.phoneno.valid && inviteNewMemberForm&&!submittedForinviteMember))">Please
                  provide phone nunber</small> -->
                  <small class="text-danger"
                  *ngIf="inviteNewMemberForm.get('phoneno').invalid && inviteNewMemberForm.get('phoneno').touched">Please
                  provide a valid mobile number. </small>
                <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('phoneno').errors?.pattern">Please enter
                  only number. </small> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary pull-left" (click)="teamRegistration()">Submit</button>
              
                <button type="button" class="btn btn-grey pull-right" data-dismiss="modal" (click)='modalclose()'>Close</button>
            </div>
            
          </div>
        </div>
      </form>


      <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">

        <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp;
          . </span>
        <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>


      </div> -->
    </div>

  </div>
</div>

<!-- Modal For Partner Invite -->
<div id="myModal1" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Invite a New Partner</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="myform" *ngIf="myform" (ngSubmit)="teamRegistration2();" >
          <!-- <div class="row"> -->
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Designation<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="orgDesignation" [(ngModel)]="orgDesignation" required>
                    <option value="" selected>--Select--</option>
                    <!-- <option value="" >Senior Partner</option>
                    <option value="" >Associate Partner</option> -->
                    <option *ngFor="let designation of DesignnationList " [value]="designation.value">{{designation.name}}</option> 
                    <!-- <option *ngFor="let membertype of memberTypelist " [value]="membertype.teamUserType">{{membertype.teamUserType}}</option> -->
                  </select>
                  <small class="text-danger" *ngIf="myform.get('orgDesignation').invalid && submitted">Please enter designation</small>
                </div>
              </div>

              
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control"  formControlName="firstname" [(ngModel)]="firstname" placeholder="Enter ..." required pattern="^[a-zA-Z\s]+$">
                </div>
                <small class="text-danger"
                  *ngIf="myform.get('firstname').invalid && submitted">Please enter first name</small>
              </div>	
          </div>
          <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="lastname" [(ngModel)]="lastname" placeholder="Enter ..." required pattern="^[a-zA-Z\s]+$">
                </div>
                <small class="text-danger"
                  *ngIf="myform.get('lastname').invalid && submitted">Please enter last name</small>
              </div>	
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>E-mail address<span class="text-danger">*</span></label>
                  <input type="email" class="form-control" formControlName="mailid" [(ngModel)]="mailid" placeholder="Enter ..." pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                </div>
                <small class="text-danger"
                  *ngIf="myform.get('mailid').invalid && submitted">Please enter email</small>
              </div>	

          </div>
          <div class="row">

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Phone<span class="text-danger">*</span></label>
                  <!-- <div style="display: flex;">
                    <app-country-code-dropdown
                      [countryCode]="countryCodeMobilePh"
                      (onCountryChange)="onCountryCodeMobilePhChange($event)"
                    ></app-country-code-dropdown>
                    <input type="tel" class="form-control" formControlName="phoneno" [(ngModel)]="phoneno" placeholder="Enter ..." pattern="^(?:\(?([0-9]{3})\)?[-.●]?)?([0-9]{3})[-.●]?([0-9]{4})$"  required>
                  </div> -->
                  <ngx-intl-tel-input 
                              [cssClass]="'custom'" 
                              
                              placeholder="Enter a valid phone number. "
                              [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="false" 
                              [enablePlaceholder]="true" 
                              [searchCountryFlag]="true"
                              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                              [selectFirstCountry]="true" 
                              [selectedCountryISO]="selectedCountry"
                              [maxLength]="15" 
                              [tooltipField]="TooltipLabel.Name" 
                              [phoneValidation]="true" 
                              [separateDialCode]="true"
                              name="phone" formControlName="phoneno" required>
                          </ngx-intl-tel-input>
                </div>
                <small class="text-danger"
                  *ngIf="myform.get('phoneno').invalid ">Please enter phone number</small>
              </div>		
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary" >Submit</button>
              </div>		

            </div>
            <!-- </div> -->
          </form>
      </div>
      <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">

        <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp; This member is invited to your team.</span>
        <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>


      </div> -->

      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>


 