<div class="content-wrapper">
    <section class="content">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">groups</i></div>
        <h3 class="box-title main-header-top">Invoice List</h3>
      </div>
      <div class="box-body">
        <div class="box bottom-height">
          <div class="box-body">
            
                
              <a href="/invoicegeneration" class="btn btn-primary pull-right Role"> 
                <span> &nbsp;Generate New Invoice</span> </a>
              <!-- <button type="button" class="btn btn-primary pull-right Role" href="/invitemember">+ ADD</button> -->
              <!-- <button type="button" class="btn btn-primary pull-right Role" (click)='checkUserCount(2)' style="margin-right: 1%;" >Invite team partner</button> -->
              <!-- <a href="/invitemember" class="btn btn-primary pull-right Role" style="margin-right: 1%;"> 
                <span> &nbsp;Invite team partner</span> </a> -->
              
            
            <div class="row" *ngIf="teamsetupPermission!='view'">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 rolebox1">
                <div class="row form-group">
                  <div class="col-md-6">
                <input type="text" class="form-control" style="margin-right: 1%;" [(ngModel)]="fromDate" id="fromDate" placeholder="Enter From Date" onfocus="(this.type='date')"/>
                </div>
                <div class="col-md-6">
                <input type="text" class="form-control" style="margin-right: 1%;" [(ngModel)]="toDate" (change)="checkDD()" id="toDate" onfocus="(this.type='date')" placeholder="Enter To Date"/>
              </div>
                </div>
                <div class="form-group">
                <button type="button" class="btn btn-primary" style="margin-right: 1%;" (click)='searchInvoice()'>Search</button>
                <button type="button" class="btn btn-primary" style="margin-right: 1%;" (click)='resetSearch()' >Reset</button>
                </div>
              </div>
              
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive ">
                  <!-- <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>Invoice No.</th>
                        <th>Case No.</th>
                        <th>User Type</th>
                        <th>Amount(£)</th>
                        <th>Due Amount(£)</th>
                        <th>Generated On</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody> -->
                      <ul *ngFor="let item of invoiceListList" class="team-view">
                        <li><i class="fa-solid fa-file-invoice"></i> Invoice No. : {{item.invoiceNo}} </li>
                        <li><i class="fa-solid fa-receipt"></i> Case No. : {{item.caseNo}}</li>
                        <li><i class="fa-solid fa-user"></i> User Type : {{item.userType}} </li>
                        <li><i class="fa-solid fa-credit-card"></i> Amount(£) : {{item.totalAmount}} </li>
                        <li><i class="fa-solid fa-money-bill"></i> Due Amount(£) : {{item.dueAmount}} </li>
                        <li><i class="fa-solid fa-calendar-days"></i> Generated On : {{item.createdAt  |  date:'dd-MM-yyyy,hh:mm:ss aa'}} </li>
                        <li><i class="fa-solid fa-bars"></i> Status : {{item.status}}</li>
                        <!-- <td> {{item.pathInS3}} </td> -->
                        <li class="act-btn">
                          <a type="button" class="btn btn-success" href="{{item.pathInS3}}"><i class="fa fa-download" aria-hidden="true" *ngIf="saveMode" ></i></a>
                          <a type="button" class="btn btn-danger" (click)='deactivateInvoice(item.invoiceNo)' *ngIf="item.status == 'pending'"><i class="fa fa-trash" aria-hidden="true" ></i></a>
                        </li>
                      </ul>
                    <!-- </tbody>
                  </table> -->
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="button" class="btn btn-grey">Back</button>
              </div>
            </div> -->
  
          </div>
        </div>
      </div>
  
  
  
    </section>
  </div>
  <!-- Modal -->
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)='modalclose()'>&times;</button>
          <h4 class="modal-title">New Time Entry</h4>
        </div>
        <form [formGroup]="myform">
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Client Name<span class="text-danger">*</span></label>
                  <select class="form-control" (change)="onSelectClient($event.target.value)" formControlName="clientName" >
                    <option [value]="">--Select--</option>
                    <option *ngFor="let data of clientList" [value]="data.userId">{{data.clientShowName}}</option> 
                    </select>
                   <!--  <small class="text-danger"
                    *ngIf="myform.get('clientName').invalid && myform.get('clientName').touched">Please
                    choose client first.</small> -->
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Case Name<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="caseTitle">
                    <option value="">--Select--</option>
                    <option *ngFor="let case of caseList " value={{case.caseNo}}>{{case.caseTitle}}</option>
                  </select>
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('caseId').invalid && myform.get('caseTitle').touched">Please
                    choose case first.</small> -->
                </div>
              </div>
  
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Service <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="" placeholder="Enter ..."
                    pattern="^[a-zA-Z\s]+$"> -->
                    <select class="form-control" formControlName="serviceName" (change)="onSelectService($event.target.value)">
                      <option value="">--Select--</option>
                      <option *ngFor="let service of serviceList" value={{service.particularsOfServices}}+{{service.amount}} >{{service.particularsOfServices}} ({{service.amount}} £)</option>
                    </select>
                  <small class="text-danger"
                    *ngIf="myform.get('serviceName').invalid && myform.get('serviceName').touched">Please
                    select a service. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('firstname').errors?.pattern">Please provide
                    only text for member name.</small> -->
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Billable Time (in Hours) <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="billableTime" required>
                    <option value="" disabled>--Select--</option>
                    <option [value]="1">1</option>
                    <option [value]="2">2</option>
                    <option [value]="3">3</option>
                    <option [value]="4">4</option>
                    <option [value]="5">5</option>
                    <option [value]="6">6</option>
                    <option [value]="7">7</option>
                    <option [value]="8">8</option>
                  </select>
                  <!-- <small class="text-danger"
                    *ngIf="inviteNewMemberForm.get('billableTime').invalid && inviteNewMemberForm.get('billableTime').touched">Please
                    choose billable time.</small> -->
                </div>
              </div>
  
            </div>
            <div class="row">
    

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Member Name<span class="text-danger">*</span></label>
                  <select name="memberUserId" class="form-control" formControlName="userName"
                           id="">
                           <option value=""><strong>--Select--</strong></option>
                           <option *ngFor="let item of userlist" [value]="item.userId">
                              <strong>{{item.firstName}} {{item.lastName}}</strong>
                           </option>
                        </select>
                        <!-- <small class="text-danger" id="infoID{{teamIndex}}"></small> -->
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('userId').invalid && myform.get('userId').touched">Please
                    choose user's name.</small> -->
                </div>
              </div>



              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Rate(£) <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="rateView" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly> -->
                  <input type="text" class="form-control" formControlName="rate" placeholder="Enter ..."
                    pattern="^[0-9]+$">
                  <small class="text-danger"
                    *ngIf="myform.get('rate').invalid && myform.get('rate').touched">Please
                    provide rate in number format only. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                    correct email.</small> -->
                </div>
              </div>
  
            </div>

            <div class="row">
  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Description<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="description" placeholder="Enter ..."
                    pattern="^[0-9a-zA-Z\s]+$">
                  <small class="text-danger"
                    *ngIf="myform.get('description').invalid && myform.get('description').touched">Please
                    provide description in alpha-numeric format only. </small>
                  </div>
                </div>
  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <input class="form-control" type="date" formControlName="date"/>
                    <!-- <input [owlDateTimeTrigger]="odt" [owlDateTime]="odt" type="text"
                                    class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                    formControlName="nextDate" (keyup.enter)="OnInput($event)"
                                    (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                 
                                 <owl-date-time #odt [pickerType]="'calendar'"></owl-date-time> -->
                    <!-- <small class="text-danger"
                      *ngIf="inviteNewMemberForm.get('date').invalid && inviteNewMemberForm.get('date').touched">Please
                      provide valid date. </small> -->
                    <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                      correct email.</small> -->
                  </div>
                </div>
                <input type="hidden" class="form-control" formControlName="invoiceListId" />
    
              </div>
            
            <div class="row">
              <div class="modal-footer">
                  <button type="submit" *ngIf="action == 'save'" ng class="btn btn-primary pull-left" (click)="invoiceListSave()">Submit</button>
                  <button type="submit" *ngIf="action == 'update'" ng class="btn btn-primary pull-left" (click)="invoiceListEdit()">Update</button>
                
                  <button type="button" class="btn btn-grey pull-right" data-dismiss="modal" (click)='modalclose()'>Close</button>
              </div>
              
            </div>
          </div>
        </form>
  
  
        <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">
  
          <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp;
            . </span>
          <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
  
  
        </div> -->
      </div>
  
    </div>
  </div>
  
  
  
  
   