import { Component, OnInit , ViewChild,Input } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import {FormArray, FormBuilder, FormControl, FormGroup, Validators, NgForm} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { AddressSchema } from '../models/profile.model';
import { Location } from '@angular/common';
import { CaseService } from '../service/case.service';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';

import countrylist from '../shared/static/phone-country-code.list';

declare var $: any;

@Component({
  selector: 'org-addresses',
  templateUrl: './orgaddresses.component.html',
  styleUrls: ['./orgaddresses.component.css']
})
export class OrgAddressesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  dataArr: AddressSchema;
  myform: FormGroup;
  isSubmitted = false;
  modalDisplay: boolean = false;
  fareaDisplay: boolean = false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  btnClciked: any;
  isDisabled : boolean = false;
  userdataArr: any[] = [];
  dataArrList: any[]
  usertype : string;
  clientType : string;
  userId : string;
  viewMode : boolean;
  statelist: any = [];
  districtlist: any = [];
  cityList: any = [];
  countrylist: Array<any> = countrylist;
  addressList: any = [];

  stateId: string;
  districtId : string;
  addressState :string;
  cityId : string;

  stateBarCouncilId : string;
  districtBarId     : string;
  districtbarlist: any = [];
  courtlist:any = [];
  courtBarlist:any = [];
  loginUserType     : string;
  addModalTxt='Add Addresses';
  pinerror='';
  deleteId :any;
  saveMode: boolean = true;
  
  @Input() public varName: string;
  @Input() public clientUserId: string;
  
  constructor(private legalbellService: LegalbellService, private router: Router,private route: ActivatedRoute,public fb: FormBuilder,private location: Location,private CaseService: CaseService, private navigationLinkParameterService: NavigationLinkParameterService) { 
  
  }
  createForm() {
    this.myform = this.fb.group({
      id: new FormControl(this.dataArr && this.dataArr.id ? this.dataArr.id: null),
      addressType: new FormControl(this.dataArr && this.dataArr.addressType ? this.dataArr.addressType: null),
      addressLine1: new FormControl(this.dataArr && this.dataArr.addressLine1 ? this.dataArr.addressLine1: null),
      addressLine2: new FormControl(this.dataArr && this.dataArr.addressLine2 ? this.dataArr.addressLine2: null),
      addressLine3: new FormControl(this.dataArr && this.dataArr.addressLine3 ? this.dataArr.addressLine3: null),
      addressState: new FormControl(this.dataArr && this.dataArr.addressState ? this.dataArr.addressState: null),
      addressCity: new FormControl(this.dataArr && this.dataArr.addressCity ? this.dataArr.addressCity: null),
      addressCountry: new FormControl(this.dataArr && this.dataArr.addressCountry ? this.dataArr.addressCountry: 'United Kingdom'),
      addressPin: new FormControl(this.dataArr && this.dataArr.addressPin ? this.dataArr.addressPin: null,[Validators.required]),
      districtId: new FormControl(this.dataArr && this.dataArr.districtId ? this.dataArr.districtId: null),
      landMark:new FormControl(this.dataArr && this.dataArr.landMark ? this.dataArr.landMark: null),
      orgId : new FormControl(this.dataArr&&this.dataArr.orgId?this.dataArr.orgId:null),
     
      // userId: new FormControl(this.userId)
      })
  }
  ngOnInit() {

      this.userId = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
      this.usertype = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
      //console.log("userId in route client address::", this.usertype);
      if(this.varName!=null){
        this.usertype = this.varName;
      }
      if(this.usertype =='individualclient'){
        // this.userdataArr.push('OFFICE');
        this.userdataArr.push('RESIDENCE');
        //console.log("userdataArr in client address",this.userdataArr[0])
      }else if(this.usertype=='corporateclient'){
         this.userdataArr.push('RESIDENCE');
         this.userdataArr.push('OFFICE');
         this.userdataArr.push('REGISTERED OFFICE');
         this.userdataArr.push('BRANCH OFFICE');
      }else if(this.usertype=='zonalmanager' || this.usertype=='directorlegal' || this.usertype == 'assistantlegalmanager'){
        //  this.userdataArr.push('RESIDENCE');
        //  this.userdataArr.push('OFFICE');
        this.userdataArr.push('REGISTERED OFFICE');
         this.userdataArr.push('BRANCH OFFICE');
      }else if(this.usertype=='clerk' || this.usertype=='accountant'){
        this.userdataArr.push('OFFICE')
        this.userdataArr.push('BRANCH OFFICE');
      // }else if(this.usertype =='lawyeradmin'||this.usertype =='lawyerassociate'){
        }else if(this.usertype =='advocate' || this.usertype =='junioradvocate' || this.usertype =='seniorpartner' || this.usertype=='associatepartner' || this.usertype =='managingpartner' || this.usertype=='counsel'){
        //console.log("user type in lawyer address:",this.usertype)
        // this.userdataArr.push('OFFICE');
        // this.userdataArr.push('CHAMBER');
        // this.userdataArr.push('RESIDENCE');
        this.userdataArr.push('REGISTERED OFFICE');
        this.userdataArr.push('BRANCH OFFICE');
        this.userdataArr.push('Trading OFFICE');
      }
      //this.myform.get('userEmail').setValue(this.userEmail);
      this.getStateList();
      //console.log("varName::",this.varName);
      console.log("userId::",this.userId);
      // if ((this.userId != null && this.varName!='individualclient')||(this.userId != null && this.varName!='corporateclient')) {
        this.getProfileData(this.userId);
      // }
      // if(this.clientUserId != null){
      //   this.userId = this.clientUserId;
      //   //console.log("clientUserId to get profile address::",this.clientUserId);
      //   this.getProfileAdress(this.clientUserId);
      // }
      if(this.userId ==='view'){
        this.viewMode = true;
        //console.log("viewMode:",this.viewMode);
      }else{
        this.createForm();
      }

    this.loginUserType    = localStorage.getItem('profileInfo') ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
    this.ngForm();
    //this.getProfileData();
    setTimeout( () => {
      this.saveMode=this.navigationLinkParameterService.saveMode;
      //console.log(this.saveMode);
    }, 500 );
  }
  // convenience getter for easy access to form fields
  get f() { return this.myform.controls; }

  
  //get profile data
  getProfileData(userid:string) {
    this.legalbellService.getProfileData(userid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          
          this.dataArr=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log(this.dataArr);
          this.getOrganizationProfileData(this.dataArr['orgId']);
          //console.log("getProfileAdress comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
            //this.createForm();
          } else {
          //this.createForm();  
      }
          //console.log("getProfileAdress comp res--" + JSON.stringify(this.dataArr));
          //console.log(this.dataArr);
        }
      );
  }
  getOrganizationProfileData(orgId) {
    //console.log( "userId in getProfileData::", orgId );
    this.legalbellService.getOrganizationProfileData(orgId).
      subscribe(
        data => {
          let res=JSON.parse( this.legalbellService.userData._body );
          this.dataArrList = JSON.parse(this.legalbellService.userData._body).respData;
          this.dataArrList=this.dataArrList['orgAddress'];
          console.log(this.dataArrList);
          console.log( 'sdssdsd', this.dataArrList);
          if ( res.status===200&&res.searchCount===1 ) {

            // if(this.dataArr.courtsOfPractice.length > 0){
            //   // let selectedPractice =  this.dataArr.courtsOfPractice[0].stateId;
            //   this.onSelectState(this.dataArr.courtsOfPractice[0].stateId, 2)
            //   this.onSelectDistrict(this.dataArr.courtsOfPractice[0].districtId, 2)
            // }

            //let lawyerPracticeAreas_val=( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null;
            // this.myform.patchValue( { 'lawyerPracticeAreas': lawyerPracticeAreas_val } );
          }
          //this.createForm();
          // //console.log( "getProfileData comp res--"+JSON.stringify( this.dataArr.emailId ) );
        }
      );
  }

  getAddressAPI(param) {
    this.legalbellService.getAddressDetails(param).
      subscribe(
        data => {
          this.addressList = JSON.parse(this.legalbellService.addressData._body).result;
          console.log("getAddressList comp res--" + JSON.stringify(this.addressList));
        }
      );
  }

  onSelectAddress(selectedAddress) {
    console.log(selectedAddress);
    var splittedAddress = selectedAddress.split("/");
    var splittedAddressLine1 = splittedAddress[0].split(":");
    
    console.log(splittedAddressLine1[1]+"|"+splittedAddress[1]+"|"+splittedAddress[2]+"|"+splittedAddress[3]);

    this.myform.controls["addressLine1"].setValue(splittedAddressLine1[1]);
    this.myform.controls["addressLine2"].setValue(splittedAddress[1]);
    this.myform.controls["addressLine3"].setValue(splittedAddress[2]);
    this.myform.controls["addressCity"].setValue(splittedAddress[3]);
  }
 
  saveAllData() {
    if ( !this.myform.valid ) {
      return false;
    }else{
        // const formValue = this.myform.value.addressPin;
        //  if(formValue.toString().length!=6){
        //     this.pinerror="Pin must be 6 digit";
        //     return false;
        // }
      
     this.resultShow = 'Executing function...';
     //console.log(this.userId);
     this.myform.controls['orgId'].setValue(this.dataArr['orgId']);
     //console.log(this.myform.value);
    // return false;
     this.legalbellService.saveAddressData(JSON.stringify(this.myform.value)).
        subscribe(
          (data) => {
            this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
            // //console.log("saveArr comp res--" + this.saveArr);
            //console.log("statusCode comp res--" + this.mydata + 'btn clk--' + this.btnClciked);
            //this.logResult = 'The questions and answer for the Intent PrinterEdit are saved in to the database successfuly.The same has been mailed to the adminstrator for modification in skills.';
            if (this.mydata.status == '200') {
              this.resultShow = 'Execution Result : ';
              this.btnShow = '(Details)';
              this.statusCode = "Success";
              this.getProfileData(this.userId);
              var existing=localStorage.getItem( 'profileInfo' );

              // If no existing data, create an array
              // Otherwise, convert the localStorage string to an array
              existing=existing? JSON.parse( existing ):{};

              // Add new data to localStorage Array
              existing['profilestatus']='Y';

              // Save back to localStorage
              localStorage.setItem( 'profileInfo', JSON.stringify( existing ) );
              setTimeout( () => {
                // location.reload();
                this.modalclose();
              }, 1000 );
            }
            
            else {
              this.statusCode = "Fail";
            }
          }, (err: any) => {
            //console.log(err);
          }, () => {
            // this.modalDisplay = !this.modalDisplay;
          }
        );
        this.modalDisplay = false;
    }

  }
  ngForm() {
    new FormGroup({
      
    })
  }
  
  //Show output
  resultBtnShow: boolean = false;
  showOutput() {
    this.resultBtnShow = !this.resultBtnShow;
  }
  
  ngSubmit() {
    //console.log("form value",this.myform.value);
  }

  saveIntentCorfirmation() {
    //console.log('abc', this.myform);
    this.isSubmitted = true;
    this.markFormGroupTouched(this.myform);
    if(!this.myform.valid) {
      alert("Please put the required details");
      // this.myform.get('phrasalArr')['controls'].forEach(element => {
      //   element.get('value').markAsTouched()
      // });
    }else{
      this.modalDisplay = true;
      //console.log("modalDisplay::",this.modalDisplay);
      }
    }

  
  abc() {
    //console.log(this.myform);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getProfileAdressedit(){
    //console.log("start");
  }

  //get profile data
  getSingleAdress(adressid:string) {
    
    // this.getStateList();
    this.legalbellService.getSingleAdress(adressid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          this.dataArr = JSON.parse(this.legalbellService.userData._body).respData;
    
          // //console.log(this.dataArr);
          this.addModalTxt='Address Details';
          // //console.log(this.addModalTxt);
          if (res.status === 200 && res.searchCount === 1) {
            //this.userdataArr.push(this.dataArr.addressType);
            
            
            this.onSelectState(this.dataArr.addressState,1);

            setTimeout( () => {
                this.districtId = this.dataArr.districtId;
                //console.log("I m inside");
            }, 2000 );
            
            // this.districtId = this.dataArr.districtId;
            // this.onSelectDistrict(this.dataArr.districtId, 1);
            // //console.log("userdataArr in getSingleAdress ",this.userdataArr[0])
            //console.log("Inside If", this.dataArr.districtId);
            this.createForm();
            $("#myModal").modal("show");
            $('#myModal').modal({backdrop: 'static', keyboard: false});
          } else {
            //console.log("Inside Ense");
              this.createForm();
              $("#myModal").modal("show");
              $('#myModal').modal({backdrop: 'static', keyboard: false});
          }
          // //console.log("getSingleAdress addressType--" + JSON.stringify(this.dataArr.addressType));
        }
      );
  }
  reinit(){
    this.addModalTxt='Add Address';
    this.myform.controls['districtId'].setValue('null');
    this.myform.controls['addressState'].setValue('null');
    this.myform.controls['addressType'].setValue('null');
    
    this.districtId='';
    this.addressState='';
   this.myform.reset();
  }
  modalclose(){
      this.addModalTxt='';
      //console.log('modal close');
      this.myform.reset();
      this.closebutton.nativeElement.click();
  }
    //########### Get State List Start Here
  getStateList() {
    this.CaseService.getSateList().
      subscribe(
        data => {
          this.statelist = JSON.parse(this.CaseService.userData._body).respData;
          // //console.log("getStateList comp res--" + JSON.stringify(this.statelist));
        }
      );
  }

//#################### deleteAddress
deleteAddressId(addressId){
  this.deleteId = addressId;

}
deleteAddress(confval){
   if (confval === 'yes') {
    this.legalbellService.deleteAddress(this.deleteId).subscribe(
      data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          //console.log(res);
          this.getProfileData(this.userId);
          this.deleteId = '';
      }
    );
   } else {
     this.deleteId = '';
     return false;
   }
}

    //########### Get District List On State Selection Start Here
  onSelectState(stateid,index = 0) {
    //console.log("onSelectState--", stateid);
    // //console.log("onSelectState--", index);
    if(index == 1){
        this.stateId = stateid;
    }else if(index == 2){
        this.stateBarCouncilId = stateid;
    }
    this.CaseService.getDistrictList(stateid).
      subscribe(
        data => {
            if(index == 1){
              
              this.districtlist     = JSON.parse(this.CaseService.userData._body).respData;
            }else if(index == 2){
              
              this.districtbarlist  = JSON.parse(this.CaseService.userData._body).respData;
            }
          
          // //console.log("getDistrictList comp res--" + JSON.stringify(this.districtlist));
        }
      );
    //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }

    onSelectDistrict(districtid, index) {
    //console.log("onSelectDistrict--", districtid);
    if(index == 1){
        this.districtId = districtid;

        this.CaseService.getCourtList(this.stateId, districtid).subscribe(
          data => {


              this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
           
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }else if(index == 2){
        this.districtBarId = districtid;

        this.CaseService.getCourtList(this.stateBarCouncilId, districtid).
        subscribe(
          data => {
            
              this.courtBarlist = JSON.parse(this.CaseService.userData._body).respData;
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }
    
    
    //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }

 
}

