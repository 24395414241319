import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'fileName'
} )
export class FileNamePipe implements PipeTransform {

  transform( value: any, args?: any ): any {
    var res=value.split( "||" );
    return res[1];
  }

}
