<div class="content-wrapper">
    <section class="content">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">groups</i></div>
        <h3 class="box-title main-header-top">Time Keeper</h3>
      </div>
      <div class="box-body">
        <div class="box bottom-height">
          <div class="box-body">
            <div class="row" *ngIf="teamsetupPermission!='view'">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal" data-target="#myModal">+ ADD</button>
                <!-- <button type="button" class="btn btn-primary pull-right Role" (click)='checkUserCount(2)' style="margin-right: 1%;" >Invite team partner</button> -->
                <!-- <a href="/invitemember" class="btn btn-primary pull-right Role" style="margin-right: 1%;"> 
                  <span> &nbsp;Invite team partner</span> </a> -->
                
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive ">
                  <!-- <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>Client</th>
                        <th>Case</th>
                        <th>User</th>
                        <th>Rate(£)</th>
                        <th>Time(Mins.)</th>
                        <th>Total Cost(£)</th>
                        <th>Billed</th>
                        <th>Logged On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody> -->
                      <ul *ngFor="let item of timeKeeperList" class="team-view task">
                        <li><h4>{{item.clientName}}</h4></li>
                        <li><i class="fa-solid fa-briefcase"></i> Case : {{item.caseTitle}}</li>
                        <li><i class="fa-solid fa-user"></i> User : {{item.userName}} </li>
                        <li><i class="fa-solid fa-money-bill"></i> Rate(£) : {{item.rate}} </li>
                        <li><i class="fa-solid fa-timer"></i> Time(Mins.) : {{item.billableTime}}</li>
                        <li><i class="fa-solid fa-money-bill"></i> Total Cost(£) : {{item.rate * (item.billableTime/60) | number:'1.0-2'}} </li>
                        <li><i class="fa-solid fa-money-bill"></i> Billed : {{item.status}} </li>
                        <li><i class="fa-solid fa-calendar"></i> Logged On : {{item.createdAt  |  date:'dd-MM-yyyy,hh:mm:ss aa'}}</li>
                        <li class="act-btn">
                          <button type="button" class="btn btn-success" (click)="getSingleTimeKeeper(item.timeKeeperId)"><i class="fa fa-pencil-square-o" aria-hidden="true" *ngIf="saveMode" ></i></button>
                        </li>
                      </ul>
                    <!-- </tbody>
                  </table> -->
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="button" class="btn btn-grey">Back</button>
              </div>
            </div> -->
  
          </div>
        </div>
      </div>
  
  
  
    </section>
  </div>
  <!-- Modal -->
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)='modalclose()'>&times;</button>
          <h4 class="modal-title">New Time Entry</h4>
        </div>
        <form [formGroup]="myform">
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Client Name<span class="text-danger">*</span></label>
                  <select class="form-control" (change)="onSelectClient($event.target.value)" formControlName="clientName" required>
                    <option [value]="" disabled>--Select--</option>
                    <option *ngFor="let data of clientList" [value]="data.userId">{{data.clientShowName}}</option> 
                    </select>
                    <small class="text-danger"
                    *ngIf="myform.get('clientName').invalid && myform.get('clientName').touched">Please
                    choose client first.</small>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Case Name<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="caseTitle" required>
                    <option value="" disabled>--Select--</option>
                    <option *ngFor="let case of caseList " value={{case.caseNo}}>{{case.caseTitle}}</option>
                  </select>
                  <small class="text-danger"
                    *ngIf="myform.get('caseTitle').invalid && myform.get('caseTitle').touched">Please
                    choose case first.</small>
                </div>
              </div>
  
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Service <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="" placeholder="Enter ..."
                    pattern="^[a-zA-Z\s]+$"> -->
                    <select class="form-control" formControlName="serviceName" (change)="onSelectService($event.target.value)" required>
                      <option value="" disabled>--Select--</option>
                      <option *ngFor="let service of serviceList" value={{service.particularsOfServices}}+{{service.amount}} >{{service.particularsOfServices}} ({{service.amount}} £)</option>
                    </select>
                  <small class="text-danger"
                    *ngIf="myform.get('serviceName').invalid && myform.get('serviceName').touched">Please
                    select a service. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('firstname').errors?.pattern">Please provide
                    only text for member name.</small> -->
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Billable Time <span class="text-danger">*</span></label>
                  <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <input type="number" class="form-control" placeholder="Hrs." min="0" max="23" formControlName="hours" required/>
                  <small class="text-danger" *ngIf="myform.get('hours').invalid && myform.get('hours').touched">Please enter hours.</small>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <input type="number" class="form-control" placeholder="Mins." min="0" max="59" formControlName="minutes" required/>
                  <small class="text-danger" *ngIf="myform.get('minutes').invalid && myform.get('minutes').touched">Please enter minutes.</small>
                  </div>
                  </div>
                  <!-- <select class="form-control" formControlName="billableTime" required>
                    <option value="" disabled>--Select--</option>
                    <option [value]="60">1hr</option>
                    <option [value]="75">1hr 15min</option>
                    <option [value]="90">1hr 30min</option>
                    <option [value]="105">1hr 45</option>
                    <option [value]="120">2hr</option>
                    <option [value]="135">2hr 15min</option>
                    <option [value]="150">2hr 30min</option>
                    <option [value]="165">2hr 45</option>
                    <option [value]="180">3hr</option>
                    <option [value]="195">3hr 15min</option>
                    <option [value]="210">3hr 30min</option>
                    <option [value]="225">3hr 45</option>
                    <option [value]="240">4hr</option>
                    <option [value]="255">4hr 15min</option>
                    <option [value]="270">4hr 30min</option>
                    <option [value]="285">4hr 45</option>
                    <option [value]="300">5hr</option>
                    <option [value]="315">5hr 15min</option>
                    <option [value]="330">5hr 30min</option>
                    <option [value]="345">5hr 45</option>
                    <option [value]="360">6hr</option>
                    <option [value]="375">6hr 15min</option>
                    <option [value]="390">6hr 30min</option>
                    <option [value]="405">6hr 45</option>
                    <option [value]="420">7hr</option>
                    <option [value]="435">7hr 15min</option>
                    <option [value]="450">7hr 30min</option>
                    <option [value]="465">7hr 45</option>
                    <option [value]="480">8hr</option>
                  </select> -->
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('billableTime').invalid && myform.get('billableTime').touched">Please
                    choose billable time.</small> -->
                </div>
              </div>
  
            </div>
            <div class="row">
    

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Member Name<span class="text-danger">*</span></label>
                  <select name="memberUserId" class="form-control" formControlName="userName"
                           id="" required>
                           <option value="" disabled><strong>--Select--</strong></option>
                           <option *ngFor="let item of userlist" [value]="item.userId">
                              <strong>{{item.firstName}} {{item.lastName}}</strong>
                           </option>
                        </select>
                        <!-- <small class="text-danger" id="infoID{{teamIndex}}"></small> -->
                  <small class="text-danger"
                    *ngIf="myform.get('userName').invalid && myform.get('userName').touched">Please
                    choose member's name.</small>
                </div>
              </div>



              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Rate(£) <span class="text-danger">*</span></label>
                  <!-- <input type="text" class="form-control" formControlName="rateView" placeholder="Enter ..."
                    pattern="^[0-9]+$" readonly> -->
                  <input type="text" class="form-control" formControlName="rate" placeholder="Enter ..."
                    pattern="^[0-9]+$" required>
                  <small class="text-danger"
                    *ngIf="myform.get('rate').invalid && myform.get('rate').touched">Please
                    provide rate in number format only. </small>
                  <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                    correct email.</small> -->
                </div>
              </div>
  
            </div>

            <div class="row">
  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Description<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="description" placeholder="Enter ..."
                    pattern="^[0-9a-zA-Z\s]{10,500}$" required>
                  <small class="text-danger"
                    *ngIf="myform.get('description').invalid && myform.get('description').touched">Please
                    provide description in alpha-numeric format only, between 10 & 500 characters </small>
                  </div>
                </div>
  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <input class="form-control" type="date" formControlName="date" required/>
                    <!-- <input [owlDateTimeTrigger]="odt" [owlDateTime]="odt" type="text"
                                    class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                    formControlName="nextDate" (keyup.enter)="OnInput($event)"
                                    (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                 
                                 <owl-date-time #odt [pickerType]="'calendar'"></owl-date-time> -->
                    <small class="text-danger"
                      *ngIf="myform.get('date').invalid && myform.get('date').touched">Please
                      provide valid date. </small>
                    <!-- <small class="text-danger" *ngIf="inviteNewMemberForm.get('mailid').errors?.pattern">Please provide
                      correct email.</small> -->
                  </div>
                </div>
                <input type="hidden" class="form-control" formControlName="timeKeeperId" />
    
              </div>
            
            <div class="row">
              <div class="modal-footer">
                  <button type="submit" *ngIf="action == 'save'" ng class="btn btn-primary pull-left" (click)="timeKeeperSave()">Submit</button>
                  <button type="submit" *ngIf="action == 'update'" ng class="btn btn-primary pull-left" (click)="timeKeeperEdit()">Update</button>
                
                  <button type="button" class="btn btn-grey pull-right" data-dismiss="modal" (click)='modalclose()'>Close</button>
              </div>
              
            </div>
          </div>
        </form>
  
  
        <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">
  
          <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp;
            . </span>
          <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
  
  
        </div> -->
      </div>
  
    </div>
  </div>
  
  
  
  
   