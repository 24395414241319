import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../service/message.service';
import { LegalbellService } from '../service/legalbell.service'
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';


import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { trim } from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('myModa3') myModa3;
  @ViewChild('openRegSuccess') openRegSuccess;

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"
  public countryCodeList: Array<any> = countryCodeList;

  loginUserName: string;
  loginPwd: string;
  emaiid: string;
  mailsent: boolean = true;
  mailid: string;
  phoneno: string;
  title: string;
  firstname: string;
  middlename: string;
  surname: string;
  orgName: string;
  validate: boolean;
  isLoginUISkipped: boolean;
  // userType : string = 'Advocate';
  //userTypeArr: any[] = ['Individual', 'Corporate','LawyerAdmin','LawyerAssociate'];
  // userTypeArr: any[] = ['Advocate', 'Counsel','Lawfirm','Corporate'];

  //userType : string   = 'advocate';
  //userTypeArr: any[]  = [['Advocate', 'advocate'],['Law Firm', 'managingpartner']];
  userType: string = 'managingpartner';
  userTypeArr: any[] = [['Law Firm', 'managingpartner']];
  planType: string = "";

  loginForm: FormGroup;
  setloginForm: FormGroup;
  signUpForm: FormGroup;

  planLists: any = [];

  loader: boolean;
  loginError: boolean;
  submittedForlogin: boolean = false;
  submittedForSignUp: boolean = false;
  viewMode: boolean = false;
  mes = '';
  mes1 = '';
  userEmail: String;

  mesSignUp = "";
  mes1SignUp = "";
  showBox: boolean = true;
  countrylist: any = [];
  countryCode: string;
  countryCodeMobilePh: string = environment.defaultCountryCode;

  constructor(
    private router: Router,
    private dataService: MessageService,
    private toastr: ToastrService,
    private legalService: LegalbellService,


    private fb: FormBuilder,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.loginUserName = this.route.snapshot.paramMap.get('username');
    this.loginPwd = this.route.snapshot.paramMap.get('pwd');
    console.log(this.loginUserName + ', ' + this.loginPwd);
    if (this.loginUserName != null && this.loginPwd != null) {
      this.isLoginUISkipped = true;
      this.loginForm = this.fb.group({});
      this.setloginForm = this.fb.group({});
      this.signUpForm = this.fb.group({});
      console.log('skipping normal login based on route params...');
      this.loginForward();
    } else {
      this.isLoginUISkipped = false;
      this.selectedCountry = CountryISO[this.country.toString()]
      this.getCountryCode();
      this.loginForm = this.fb.group({
        loginUserName: new FormControl('', [Validators.required]),
        loginPwd: new FormControl('', [Validators.required]),

      });


      this.setloginForm = this.fb.group({

      });


      this.getSubscriptionPlan('managingpartner')
      this.signUpForm = this.fb.group({
        title: new FormControl(''),
        firstname: new FormControl('', [Validators.required]),
        middlename: new FormControl(''),
        surname: new FormControl('', [Validators.required]),
        mailid: new FormControl('', [Validators.required]),
        phoneno: new FormControl('', [Validators.required]),
        orgName: new FormControl('',),
        countryCode: new FormControl('+91'),


      });
    }
  }


  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else
      return false;
  }


  loginForward() {
    localStorage.clear();
    this.submittedForlogin = true;
    if (this.loginUserName == null && this.loginPwd == null) {
      if (this.loginForm.valid) {
        let userInfo = {};
        this.loader = true;

        let emailReturn = this.ValidateEmail(this.loginForm.value.loginUserName)


        if (emailReturn) {

          this.setloginForm = this.fb.group({

            emailId: new FormControl('', [Validators.required]),

            loginPwd: new FormControl('', [Validators.required]),



          });

          this.setloginForm.value.loginPwd = btoa(this.loginForm.value.loginPwd);
          this.setloginForm.value.orgId = this.loginForm.value.orgId;
          this.setloginForm.value.emailId = this.loginForm.value.loginUserName;

        } else {

          this.setloginForm = this.fb.group({

            loginUserName: new FormControl('', [Validators.required]),

            loginPwd: new FormControl('', [Validators.required])


          });
          this.setloginForm.value.loginPwd = btoa(this.loginForm.value.loginPwd);
          this.setloginForm.value.orgId = this.loginForm.value.orgId;
          this.setloginForm.value.loginUserName = this.loginForm.value.loginUserName;

        }
      }
    } else {
      this.setloginForm.value.loginPwd = this.loginPwd;
      this.setloginForm.value.loginUserName = this.loginUserName;
      console.log('making rest call with ' + this.setloginForm.value.loginPwd + this.setloginForm.value.loginUserName);
    }


    this.legalService.login(this.setloginForm.value).subscribe((res: any) => {

      // this.mes= res.responseMessage;
      let ress: any = JSON.parse(res._body);

      // let userInfo = {"email":this.loginForm.value.userName};
      console.log("user id in after login ::", ress.respData.userid);
      let profileInfo = {
        "profilestatus": ress.respData.profilestatus,
        "usertype": ress.respData.usertype,
        "userIdlogin": ress.respData.userid,
        "emailId": ress.respData.emailId,
        "superUserId": ress.respData.superUserId,
        "superUserType": ress.respData.superUserType,
        "userTitle": ress.respData.userTitle,
        "firstName": ress.respData.firstName,
        "lastName": ress.respData.lastName,
        "orgDesignation": ress.respData.orgDesignation,
        "middleName": ress.respData.middleName,
        "orgId": ress.respData.orgId
      };
      localStorage.setItem('profileInfo', JSON.stringify(profileInfo));


      //this.dataService.sendMessage('privilegeuser', {});
      if (ress.searchCount == 1) {
        if (ress.respData.profilestatus == 'Y') {
          this.router.navigate(['/dashboard']);
        } else if (ress.respData.usertype == "individualclient" || ress.respData.usertype == "corporateclient") {
          this.router.navigate(['/clientprofile', ress.respData.userid, ress.respData.usertype]);
        } else if (ress.respData.usertype == "managingpartner") {
          this.router.navigate(['/orgprofile']);
        } else {
          this.router.navigate(['/lawyerprofile']);
        }
      }
      if (ress.searchCount == 3) {
        localStorage.setItem('isSubscriptionValid', 'N');
        this.router.navigate(['/payment']);
      } else {
        localStorage.setItem('isSubscriptionValid', 'Y');
      }

      console.log("responsedata in login::", ress.respData);

      setTimeout(() => {
        this.mes = '';
        this.mes1 = '';
        //this.router.navigate(['/form']);
      }, 2000);
      this.loader = false;


    }, (err: any) => {

      // console.log(err);
      this.loginError = true;
      this.loader = false;
      let erro: any = JSON.parse(err._body);
      //console.log(erro);
      this.mes1 = erro.responseMessage;

      //this.mes1 = "Please provide valid Username/Email and password.";

      this.toastr.error(this.mes1, '');

      // setTimeout(() => {
      //   this.mes = '';
      //   this.mes1 = '';
      // }, 2000);

    });
    //this.router.navigate(['/userlist']);


  }

  sendMail() {
    console.log("mail id to sent::", this.mailid);
    this.legalService.forgetPassword(this.mailid).subscribe((res: any) => {

      // this.mes= res.responseMessage;
      let ress: any = JSON.parse(res._body);
      console.log(ress.responseMessage)
      this.mes = ress.responseMessage;

      this.toastr.success(this.mes, '');
      this.mes1 = '';
      this.viewMode = false;
      location.reload();
      //this.mailsent = false;
      //window.alert(ress.responseMessage);
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.mes = '';
      this.toastr.error(this.mes1, '');
      //this.mailsent = false;
      //window.alert(erro.responseMessage);
    });

  }



  registration() {
    console.log(this.signUpForm)
    this.submittedForSignUp = true;
    if (this.signUpForm.valid) {
      console.log('call');
      let body: any;
      // console.log("mail id to sent::", this.userType, this.planType);
      // console.log(this.signUpForm.value);

      this.mailid = trim(this.signUpForm.value.mailid);
      //this.phoneno    = this.signUpForm.value.phoneno.number;
      this.phoneno = trim(this.signUpForm.value.phoneno.number.replace(/\s/g, ""));
      this.phoneno = this.phoneno.replace(/-/g, "");
      this.title = trim(this.signUpForm.value.title);
      this.firstname = trim(this.signUpForm.value.firstname);
      this.middlename = trim(this.signUpForm.value.middlename);
      this.surname = trim(this.signUpForm.value.surname);
      this.orgName = trim(this.signUpForm.value.orgName);
      //this.countryCode = this.signUpForm.value.countryCode;
      this.countryCode = this.signUpForm.value.phoneno.dialCode;

      if (this.firstname == '' || this.firstname === undefined) {
        // window.alert("Please provide a valid first name.");
        return false;
      } else if (this.surname == '' || this.surname === undefined) {
        // window.alert("Please provide a valid surname.");
        return false;
      } else if (this.mailid == '' || this.mailid === undefined) {
        // window.alert("Please provide a valid mail id.");
        return false;
      } else if (this.phoneno == '' || this.phoneno === undefined) {
        // window.alert("Please provide a valid phone number.");
        return false;
      } else if (this.userType !== 'advocate' && this.userType !== 'counsel' && (this.orgName == '' || this.orgName === undefined)) {
        // window.alert("Please provide your organization name.");
        this.toastr.error('Please provide your organization name.');
        return false;
      }
      else {
        //let countryCode='+91';
        let countryCode = this.countryCodeMobilePh;
        console.log('inside');
        // console.log(this.mailid+' -mail- '+this.phoneno+' phn '+this.title+' ttl '+this.firstname+'  frst  '+this.middlename+'  mdl  '+this.surname+'  sur  '+this.planType+'  pln  '+this.userType);
        // if(!(/^[a-zA-Z\s]+$/.test(this.firstname))){
        //     window.alert("Please provide only text for first name.")
        //     return false;
        // }

        // if(!(/^[a-zA-Z\s]+$/.test(this.surname))){
        //     window.alert("Please provide only text for sur name.")
        //     return false;
        // }

        // if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mailid))){
        //     window.alert("Please provide correct email address.")
        //     return false;
        // }

        // if(!(/^(?:\(?([0-9]{3})\)?[-.●]?)?([0-9]{3})[-.●]?([0-9]{4})$/.test(this.phoneno))){
        //     window.alert("Please provide correct phone number.")
        //     return false;
        // }

        // if(!(/^[a-zA-Z\s]+$/.test(this.orgName))){
        //     // window.alert("Please provide only text for organization name.")
        //     return false;
        // }

        // console.log(this.mailid);
        // return false;
        if (this.userType == "managingpartner") {
          body = {
            "emailId": this.mailid,
            "countryCode": this.countryCode,
            "phoneNo": this.phoneno,
            "userTitle": this.title,
            "firstName": this.firstname,
            "middleName": this.middlename,
            "lastName": this.surname,
            "userType": "managingpartner",
            "orgName": this.orgName,
            "subscriptionPlanId": this.planType // <<===== THIS NEEDS TO BE CHECKED =====>>
          }
        } else if (this.userType == "corporatelegaldepartment") {
          body = {
            "emailId": this.mailid,
            "countryCode": this.countryCode,
            "phoneNo": this.phoneno,
            "userTitle": this.title,
            "firstName": this.firstname,
            "middleName": this.middlename,
            "lastName": this.surname,
            "userType": "corporatelegaldepartment",
            "orgName": this.orgName,
            "subscriptionPlanId": this.planType
          }
        } else {
          body = {
            "emailId": this.mailid,
            "countryCode": this.countryCode,
            "phoneNo": this.phoneno,
            "userTitle": this.title,
            "firstName": this.firstname,
            "middleName": this.middlename,
            "lastName": this.surname,
            "userType": this.userType,
            "loginUserId": null,
            "subscriptionPlanId": this.planType,
          }
        }
        this.legalService.registrationNew(body).subscribe((res: any) => {

          // this.mes= res.responseMessage;
          let ress: any = JSON.parse(res._body);
          console.log(ress);
          let userId = { "userId": ress.respData };
          this.mesSignUp = ress.responseMessage;
          this.toastr.success(this.mesSignUp, '');
          this.mes1SignUp = '';

          this.modalclose();

          setTimeout(() => {
            // location.reload();
            this.openRegSuccessModal(ress.userEmail);
          }, 1000);



          // this.viewMode = true;
          //this.mailsent = false;
          //window.alert(ress.responseMessage);
          // this.sendMail();
        }, (err: any) => {
          let erro: any = JSON.parse(err._body);
          this.mes1SignUp = erro.responseMessage;
          this.toastr.error(this.mes1SignUp, '');
          this.mesSignUp = '';
          //this.mailsent = false;
          //window.alert(erro.responseMessage);
        });
      }

    }
  }



  modalclose() {
    this.signUpForm.reset();
    this.signUpForm.get('orgName').patchValue('');
    this.validate = false;
    this.submittedForSignUp = false;
    this.viewMode = false;

    /* console.log("view mode::",this.viewMode); */
    //this.addModalTxt='';
    //console.log('modal close');
    //this.signUpForm.reset();
    //$('#signUpForm')[0].reset();


    this.closebutton.nativeElement.click();
  }

  openRegSuccessModal(userRegEmail) {
    this.userEmail = userRegEmail;
    this.openRegSuccess.nativeElement.click();
  }

  chageuser(usertype) {
    console.log("userType::", usertype);
    this.userType = usertype;
  }


  //########### Subscription Plan List Start Here #############
  getSubscriptionPlan(planFor: string) {

    console.log(planFor);
    this.userType = planFor;
    if (planFor == 'corporatelegaldepartment' || planFor == 'managingpartner') {
      this.showBox = true;
    } else {
      this.showBox = false;
    }
    if (planFor == 'client') {
      this.planLists = [{ planId: "Free-Trial", price: 0, planType: "Free Trial" }];
      this.planType = "Free-Trial";

    } else {

      this.legalService.getSubscriptionPlan(planFor).
        subscribe(
          data => {
            // let res = JSON.parse( this.legalService.userData._body );
            this.planLists = JSON.parse(this.legalService.userData._body).respData;
            if (this.planLists.length > 0) {
              this.planType = this.planLists[0].planId;
            } else {
              this.planType = "";
            }

          })
    }



  }
  //########### Subscription Plan List Ends Here #############

  //########### On Select Plan Start Here #############
  onSelectPlanType(selectedPlan: string) {
    this.planType = selectedPlan;
    console.log(this.planType);
  }
  //########### On Select Plan Ends  Here #############

  getCountryCode() {
    this.legalService.getCountryCode().
      subscribe(
        data => {
          this.countrylist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getCountryCode comp res--" + JSON.stringify(this.countrylist));
        }
      );
  }

  onCountryCodeMobilePhChange(selectedCountryCode) {
    this.countryCodeMobilePh = selectedCountryCode;
  }
}