import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LogDataService {

  userData : any;

  constructor(
    private http: Http
  ) { 
  }

  fetchLogData(fromDate:string,toDate:string) {
    
    return this.http.post(environment.api+'adminService/getAllLogs',{
      //"orgId":environment.orgId, 
      "logType" : null,
      "fromDate": fromDate,
      "toDate" : toDate
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
  
  fetchFeedbackData(feedbak:boolean) {
    
    return this.http.post(environment.api+'analytics/getFeedbackData',{
      "feedbak":feedbak
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  fetchFunctionalArea(params) {
    
    return this.http.post(environment.api+'analytics/getFACount',params).
      pipe(map(res => { res.json(), this.userData = res }));
}

}
