import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { LegalbellService } from '../service/legalbell.service'
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MustMatch } from '../pipes/must-match.validator';

import { ToastrService } from 'ngx-toastr';



@Component( {
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
} )
export class SetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  loader: boolean;
  loginError: boolean;

  submittedForResetpassword: boolean=false;

  userToken: any;
  mes='';
  mes1='';
  userEmail: any;
  verified: string='';

  constructor(
    private fb: FormBuilder,
    private legalbellService: LegalbellService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {


    this.route.queryParams
      .subscribe( params => {
        console.log( "params::", params );
        this.userToken=params.userToken;
        this.userEmail=params.userEmail;
        this.verified=params.v
      } );

    console.log( "verfiy value::", this.verified );
  }

  ngOnInit() {
    this.resetForm=this.fb.group( {

      userPwd: new FormControl( '', [Validators.required, Validators.pattern( /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/ )] ),

      cpassword: new FormControl( '', [Validators.required] ),

      userName: new FormControl( '', [Validators.required] )


    },
      {
        validator: MustMatch( 'userPwd', 'cpassword' )
      } );
  }



  formSubmit() {
    this.submittedForResetpassword=true;

    console.log( this.resetForm );
    if ( this.resetForm.valid ) {
      let userInfo={};
      this.loader=true;

      this.resetForm.value.userToken=this.userToken;
      this.resetForm.value.userEmail=this.userEmail;
      this.resetForm.value.userPwd=btoa( this.resetForm.value.userPwd );



      this.legalbellService.setPassword( this.resetForm.value ).subscribe( ( res: any ) => {
        let ress: any=JSON.parse( res._body );
        console.log( "in reset password:", ress.responseMessage )
        this.mes=ress.responseMessage;
        this.toastr.success(this.mes, '');
        this.mes1='';
        setTimeout( () => {
          // this.mes = '';
          // this.mes1 = '';
          this.router.navigate(['/login']);
        }, 1500 );
        this.loader=false;


      }, ( err: any ) => {
        console.log( err );
        let ress: any=JSON.parse( err._body );
        console.log( "error in reset password:", ress.responseMessage )
        this.loginError=true;
        this.loader=false;
        this.mes='';
        this.mes1=ress.responseMessage;
        if(this.mes1.includes("Duplicate")){
          this.toastr.error('This User Name already taken', '');
        }else{
        this.toastr.error(this.mes1, '');
        }
        // this.mes1= "Network connection error";

        setTimeout( () => {
          // this.mes = '';
          //this.mes1 = '';
        }, 2000 );

      } );
    } else {
      this.resetForm.get( 'userName' ).markAsTouched();
      this.resetForm.get( 'userPwd' ).markAsTouched();
    }
  }
}
