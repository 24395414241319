<div class="content-wrapper" style="overflow-x: scroll;">
    <section class="content-header">
    <h2>Payment Details</h2>
   </section>
   <section class="content">
    <i *ngIf="selectedPlanType == 'N'"class="fa fa-info-circle" style="font-size:18px;color:#0a82b7">Your free trial period has expired please upgrade your plan.</i>
     
    <form ngNoForm action="{{payuUrl}}" name="payuform" method="POST">
      <!-- <div class="form-group">
        <label for="productInfo">Product Name:</label>
        <input type="text" class="form-control" id="productInfo" name="productinfo" [(ngModel)]="payuform.productinfo">
      </div> -->
      <input type="text" hidden id="productInfo" name="productinfo" [(ngModel)]="productinfo">
      <div class="form-group">
        <label for="firstname">Name:</label>
        <input type="text" class="form-control" readonly id="firstname" name="firstname" [(ngModel)]="firstName">
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" class="form-control" id="email" readonly name="email" [(ngModel)]="useremail">
      </div>
      <div class="form-group">
        <label for="email">Phone:</label>
        <input type="number" class="form-control" id="phone" readonly name="phone" [(ngModel)]="contactNo">
      </div>
        <div class="form-group" *ngIf="planLists.length > 0">
          <label><b>Subscription Plan:</b> </label>
          <span class="radio check1" *ngFor="let eachPlan of planLists; let planIndex = index">
            <!-- <label><input type="radio" style="width: unset;" [checked]="planIndex == 0" name="planType" [value]="eachPlan.planId" (change)="onSelectPlanType(eachPlan.planId)">&nbsp;{{eachPlan.price}}/{{eachPlan.planType}} <span style="color: #f07614;" *ngIf="eachPlan.discountPrice > 0"><b>(off {{eachPlan.discountPrice}})</b></span> </label> -->
            <label><input type="radio" style="width: unset;" [checked]="selectedPlan == eachPlan.planId" name="planId" [value]="eachPlan.planId" (change)="onSelectPlanType(eachPlan.planId,eachPlan.planType,eachPlan.price)">&nbsp;{{eachPlan.planDetails}} </label>
          </span>
        </div>
      <div class="form-group" *ngIf="planType!='FREE'">
        <label for="amount">Amount:</label>
        <input type="number" class="form-control" id="amount" readonly name="amount" [(ngModel)]="planAmount">
      </div>
      
      
      <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden [(ngModel)]="payuform.surl"></textarea>
      <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden [(ngModel)]="payuform.furl"></textarea>
      <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden [(ngModel)]="payuform.key"></textarea>
      <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden [(ngModel)]="payuform.hash"></textarea>
      <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden [(ngModel)]="payuform.txnid"></textarea>
      <textarea name="service_provider" id="service_provider" ng-model="service_provider" rows="2" cols="2"  [(ngModel)]="payuform.service_provider" hidden></textarea>
      <button *ngIf="disablePaymentButton && planType!='FREE'" type="button"  class="btn btn-default" (click)="confirmPayment()">Confirm</button>
      <button *ngIf="!disablePaymentButton"  type="submit" class="btn btn-danger">Pay</button>
    </form>
</section>
  </div>
