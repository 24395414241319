import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service'

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
import { ClientProfileSchema } from '../models/profile.model';
declare var $: any;
@Component({
  selector: 'app-invoiceList',
  templateUrl: './invoiceList.component.html',
  styleUrls: ['./invoiceList.component.css']
})

export class InvoiceListComponent implements OnInit {
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"
public countryCodeList: Array<any> = countryCodeList;
  myform: FormGroup;
  mes = '';
  mes1 = '';
  mailid: string;
  userType : string;
  firstname : string;
  lastname : string;
  phoneno: string;
  viewMode: boolean = false;
  userlist = [];
  memberTypelist = [];
  teamUserType : string;
  superUserId : string;
  superUserType : string;
  loginUserId : string;
  teamsetupPermission : string;
  userlistLawFarm = [];
  mergeUserList = []
  inviteNewMemberForm  : FormGroup;
  submittedForinviteMember : boolean;
  countryCodeMobilePh: string = environment.defaultCountryCode;
  orgDesignation:any;
  dataArr: ClientProfileSchema;
  invoiceListDetail:any;
  saveMode: boolean = true;
  submitted:boolean;
  usertype : string;
  pageTitle='Invite Partner';
  DesignnationList = [];

  caseList:any[]=[];
  clientList:any[]=[];
  serviceList:any[]=[];
  invoiceListList:any[]=[];
  showMsg: boolean = false;

  invoiceListId:string;
	clientId:string;
	clientName:string;
	caseId:string;
	caseTitle:string;
	//serviceId;
	serviceName:string;
	billableTime:string;
	userIdT:string;
	userName:string;
	rate:string;
	description:string;
  date:string;
  createdAt:string;
  createdBy:string;
  updatedAt:string;
  updatedBy:string;
  action="save";
  fromDate;
  toDate;

  
  
  constructor(private legalService: LegalbellService, private toastr: ToastrService, private fb: FormBuilder,private navigationLinkParameterService: NavigationLinkParameterService) { }

  createForm() {
    let userId = localStorage.getItem('UserID')?JSON.parse(localStorage.getItem('UserID')).userId: null;
    console.log("userId in address::",userId); 
    this.myform = this.fb.group({
      clientName:new FormControl(''),
      caseTitle:new FormControl(''),
      serviceName:new FormControl(''),
      billableTime:new FormControl(''),
      userName:new FormControl(''),
      rate:new FormControl(''),
      //rateView:new FormControl(''),
      description:new FormControl(''),
      date:new FormControl(''),
      invoiceListId:new FormControl(''),
      });
  }

  ngOnInit(): void {
    //window.location.reload();
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.userType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;
    this.teamsetupPermission = localStorage.getItem('menupermission')?JSON.parse(localStorage.getItem('menupermission')).teamsetup: null;
    
    console.log("this.usertype in teamsetup:::",this.userType);
    console.log("this.teamsetupPermission:::",this.teamsetupPermission);
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    if(this.userType=='advocate'||this.userType=='counsel'||this.userType=='directorlegal'||this.userType=='managingpartner'
       ||this.userType=='zonalmanager'){
      this.getUserList(this.userType,this.loginUserId);
    }else{
      this.getUserList(this.superUserType,this.superUserId);
    }
    this.getTeamMemberType(this.userType);

    this.selectedCountry = CountryISO[this.country.toString()] 
    this.orgDesignation='';
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    if(this.usertype === 'managingpartner'){
      this.pageTitle='Partner';
    }else{
      this.pageTitle='Manager';
    }
    console.log("this.usertype in teamsetup:::",this.usertype);
    if(this.usertype=='managingpartner'){
      this.DesignnationList=[{name:'Senior Partner',value:'seniorpartner'},{name:'Associate Partner',value:'associatepartner'}];
    }else{
      this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'}];
      // this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'},{name:'Legal Manager',value:'legalmanager'},{name:'Assistant Legal Manager',value:'assistantlegalmanager'}];
    }
    
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    /* if(this.usertype=='advocate'||this.usertype=='counsel'||this.usertype=='directorlegal'||this.usertype=='managingpartner'){
      this.getUserList(this.usertype,this.loginUserId);
    }else{
      this.getUserList(this.usertype,this.superUserId);
    } */
    this.getTeamMemberType(this.usertype);
    this.createForm();

    this.legalService.getProfileData(this.loginUserId).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
      setTimeout( () => {
        this.saveMode=this.navigationLinkParameterService.saveMode;
        console.log(this.saveMode);
      }, 500 );


      /* this.inviteNewMemberForm = this.fb.group({
            teamUserType        : new FormControl(''),
            firstname       : new FormControl('', [Validators.required]),
            lastname       : new FormControl('', [Validators.required]),
            mailid          : new FormControl('', [Validators.required]),
            phoneno         : new FormControl('', [Validators.required]),


      }); */

      //this.submittedForinviteMember = false;
      this.getserviceMaster();
      this.getAllClientsOfSuperUser();
      this.getAllSavedTimeKeeper();

      this.createForm();

  }

  getAllClientsOfSuperUser(){
    this.legalService.getUserList(this.superUserId).subscribe((res: any) => {
      this.clientList = JSON.parse(this.legalService.userData._body).respData;
      //this.clientData=this.mes;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
 
    });
  }

  checkDD(){
    let tDate = new Date(this.toDate);
    let fDate = new Date(this.fromDate);
    let dd = tDate.getTime() - fDate.getTime();
    console.log(dd);
    if(dd <0) {
      this.mes1 = "To Date must be greater or equal to From Date";
      this.toastr.error(this.mes1, '');
      setTimeout( () => {
        location.reload();
       }, 2000 );
    }
  }

  resetSearch(){
    location.reload();
  }

  deactivateInvoice(invoiceNo){
    console.log(invoiceNo);
    this.legalService.deactivateInvoiceByInvoiceNo(this.superUserId, invoiceNo).subscribe((res: any) => {
      this.invoiceListList = JSON.parse(this.legalService.invoiceListData._body).respData;
      this.mes = 'Invoice Cancelled Successfully';
      this.toastr.success(this.mes, '');
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
 
    });
  }

  searchInvoice() {
    
    this.checkDD();

    console.log(this.fromDate+"::::"+this.toDate);
    if(this.fromDate != null && this.toDate != null) {
      this.legalService.searchInvoiceListByDate(this.superUserId, this.fromDate, this.toDate).subscribe((res: any) => {
        this.invoiceListList = JSON.parse(this.legalService.invoiceListData._body).respData;
        this.mes1 = '';
      
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
   
      });
    } else {
      this.getAllSavedTimeKeeper();    
    }
    
  }

  getAllSavedTimeKeeper(){
    this.legalService.getinvoiceListList(this.superUserId).subscribe((res: any) => {
      this.invoiceListList = JSON.parse(this.legalService.invoiceListData._body).respData;
      //console.log("Time KEEPER List ::>> " +this.invoiceListList[0].clientName);
      //this.clientData=this.mes;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
 
    });
  }

  onSelectClient(clientUserId){
    this.showMsg=false;
      this.mes1="";
    if(clientUserId !== '--Select--'){
      this.legalService.getCaseList(clientUserId).subscribe((res: any) => {
        this.caseList = JSON.parse(this.legalService.userData._body).respData;
        //this.stc=false;
      }, (err: any) => {
        
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        
      });
    }
    
  }

  getserviceMaster() {
    //for lawfirm need to send lawfirm id based on usertype
    //this.loader = true;
    let requestdata = { "superUserId": this.superUserId,
                        "userType": this.userType };
 
    
    
    this.legalService.serviceMaster(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('serviceMaster response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);
        const res = data.respData;
        
       
        console.log('res in serviceMaster', res);

        this.serviceList = res;

        //if (res.length > 0) {
           //res.map((chrd) => {
            //this.pricelist = <FormArray>this.myform.get('serviceArr');
            
            /* this.pricelist.push(new FormGroup({
              id : new FormControl(chrd.scheduleOfFeesMstIdentity.id),
              superUserId: new FormControl(this.userId),
              tribunals: new FormControl(chrd.tribunals),
              particularsOfServices: new FormControl(chrd.particularsOfServices),
              hour: new FormControl(chrd.hour),
              amount : new FormControl((chrd.amount)),
              userType : new FormControl(this.userType)
            })) */
          //   if(this.isDtInitialized) {
          //     this.dtElement.dtInstance.then( (dtInstance: DataTables.Api ) => {
          //       // Destroy the table first
          //       dtInstance.destroy();
                
          //       // Call the dtTrigger to rerender again
          //       this.dtTrigger.next();
          //   } );
            
          // }else{
          //   this.isDtInitialized = true;
           
          //   this.dtTrigger.next();
          // }
            
          //});
          //this.loader = false;
          //console.log("my form",this.myform.get('serviceArr'));

        //}

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  onSelectService(service) {
    console.log("Hit !!!");
    
    console.log(service);

    var arr = service.split('+');

    console.log(arr[0]+':::'+arr[1]);
    //this.myform.controls['rateView'].setValue(arr[2]);
    this.myform.controls['rate'].setValue(arr[1]);

    /* this.myform = this.fb.group({
      rate:new FormControl(arr[2]),
      }); */


  }

  invoiceListSave(){
    
    let body:any;
    //var serviceName=this.myform.value.serviceName;
    //console.log(serviceName);
    //
    console.log("hit the method save");
    if (this.myform.invalid) {
      this.toastr.error("Please enter proper values", '');
      body = {
        "clientId" : this.myform.value.clientName,
        "caseId":this.myform.value.caseTitle,
        "serviceName":this.myform.value.serviceName,
        "billableTime":this.myform.value.billableTime,
        "userId":this.myform.value.userName,
        "rate":this.myform.value.rate,
        "description":this.myform.value.description,
        "date":this.myform.value.date,
        "createdBy":this.superUserId,
        "updatedBy":this.superUserId
      }
      console.log("Invalid condition ::>> "+body);
      return
    }
    else{
      body = {
              "clientId" : this.myform.value.clientName,
              "caseId":this.myform.value.caseTitle,
              "serviceName":this.myform.value.serviceName,
              "billableTime":this.myform.value.billableTime,
              "userId":this.myform.value.userName,
              "rate":this.myform.value.rate,
              "description":this.myform.value.description,
              "date":this.myform.value.date,
              "createdBy":this.superUserId,
              "updatedBy":this.superUserId
            }
            console.log(body);

      this.legalService.saveTimeKeeper(body).subscribe((res: any) => {
        // this.mes= res.responseMessage;
        let ress: any = JSON.parse(res._body);
        console.log(ress.responseMessage);
        let userId = { 'userId': ress.respData };
        this.mes = ress.responseMessage;
        this.mes1 = '';
        this.viewMode = true;
  
        // $("#myModal").modal("hide");
        // setTimeout( () => {
        //   location.reload();
        // }, 2000 );
  
        this.getUserList(this.usertype,this.superUserId);
        this.toastr.success(this.mes, '');
        this.myform.reset();
        $('#myModal').modal('hide');
        setTimeout( () => {
         location.reload();
        }, 2000 );
        
        //this.mailsent = false;
        //window.alert(ress.responseMessage);
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.myform.reset();
        this.toastr.error(this.mes1, '');
         $('#myModal').modal('hide');
        this.mes = '';
        // this.mailsent = false;
        //window.alert(erro.responseMessage);
      });
    
   }
  }

  getSingleTimeKeeper(invoiceListId){
    console.log(invoiceListId);
    this.legalService.getSingleTimeKeeper(invoiceListId).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.invoiceListDetail = JSON.parse(this.legalService.userData._body).respData;
    
          console.log(this.invoiceListDetail);
          //this.addModalTxt='Time Keeper Details';
          // //console.log(this.addModalTxt);
          if (res.status === 200 && res.searchCount === 1) {
            //this.userdataArr.push(this.dataArr.addressType);
            
            
            /* this.onSelectState(this.dataArr.addressState,1);

            setTimeout( () => {
                this.districtId = this.dataArr.districtId;
                console.log("I m inside");
            }, 2000 ); */
            
            // this.districtId = this.dataArr.districtId;
            // this.onSelectDistrict(this.dataArr.districtId, 1);
            // //console.log("userdataArr in getSingleAdress ",this.userdataArr[0])
            //console.log("Inside If", this.dataArr.districtId);
            //this.createForm();
            this.onSelectClient(this.invoiceListDetail.clientId);
            this.action = 'update';
            console.log(this.invoiceListDetail.date);
            this.myform = this.fb.group({
              clientName:new FormControl(this.invoiceListDetail.clientId),
              caseTitle:new FormControl(this.invoiceListDetail.caseId),
              serviceName:new FormControl(this.invoiceListDetail.serviceName),
              billableTime:new FormControl(this.invoiceListDetail.billableTime),
              userName:new FormControl(this.invoiceListDetail.userId),
              rate:new FormControl(this.invoiceListDetail.rate),
              description:new FormControl(this.invoiceListDetail.description),
              date:new FormControl(new Date(this.invoiceListDetail.date)),
              invoiceListId:new FormControl(this.invoiceListDetail.invoiceListId)
              });

            $("#myModal").modal("show");
            $('#myModal').modal({backdrop: 'static', keyboard: false});
          } else {
            //console.log("Inside Ense");
              this.createForm();
              $("#myModal").modal("show");
              $('#myModal').modal({backdrop: 'static', keyboard: false});
          }
          // //console.log("getSingleAdress addressType--" + JSON.stringify(this.dataArr.addressType));
        }
      );

  }

  invoiceListEdit(){
    
    let body:any;
    console.log("hit the method edit");
    if (this.myform.invalid) {
      this.toastr.error("Please enter proper values", '');
      body = {
        "invoiceListId" : this.myform.value.invoiceListId,
        "clientId" : this.myform.value.clientName,
        "caseId":this.myform.value.caseTitle,
        "serviceName":this.myform.value.serviceName,
        "billableTime":this.myform.value.billableTime,
        "userId":this.myform.value.userName,
        "rate":this.myform.value.rate,
        "description":this.myform.value.description,
        "date":this.myform.value.date,
        "createdBy":this.superUserId,
        "updatedBy":this.superUserId
      }
      console.log("Invalid condition ::>> "+body);
      return
    }
    else{
      body = {
              "invoiceListId" : this.myform.value.invoiceListId,
              "clientId" : this.myform.value.clientName,
              "caseId":this.myform.value.caseTitle,
              "serviceName":this.myform.value.serviceName,
              "billableTime":this.myform.value.billableTime,
              "userId":this.myform.value.userName,
              "rate":this.myform.value.rate,
              "description":this.myform.value.description,
              "date":this.myform.value.date,
              "createdBy":this.superUserId,
              "updatedBy":this.superUserId
            }
            console.log(body);

      this.legalService.editTimeKeeper(body).subscribe((res: any) => {
        // this.mes= res.responseMessage;
        let ress: any = JSON.parse(res._body);
        console.log(ress.responseMessage);
        let userId = { 'userId': ress.respData };
        this.mes = ress.responseMessage;
        this.mes1 = '';
        this.viewMode = true;
  
        // $("#myModal").modal("hide");
        // setTimeout( () => {
        //   location.reload();
        // }, 2000 );
  
        this.getUserList(this.usertype,this.superUserId);
        this.toastr.success(this.mes, '');
        this.myform.reset();
        $('#myModal').modal('hide');
        setTimeout( () => {
         location.reload();
        }, 2000 );
        
        //this.mailsent = false;
        //window.alert(ress.responseMessage);
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.myform.reset();
       this.toastr.error(this.mes1, '');
         $('#myModal').modal('hide');
        this.mes = '';
        // this.mailsent = false;
        //window.alert(erro.responseMessage);
      });
    
   }
  }

  teamRegistration(){
      
      // if(this.mailid==''||this.mailid===undefined) {
      //     window.alert("Please give a valid mail id.")
      // }else if(this.firstname==''||this.firstname===undefined){
      //     window.alert("Please give a valid name.")
      // }
      // // else{

          // return false;
          this.markFormGroupTouched(this.inviteNewMemberForm);
          if(this.inviteNewMemberForm.valid) {
              this.submittedForinviteMember = true;
              this.mailid       = this.inviteNewMemberForm.value.mailid;
              this.phoneno      = this.inviteNewMemberForm.value.phoneno.number.replace(/\s/g, "");
             
              this.phoneno =  this.phoneno.replace(/-/g, "");
              this.teamUserType     = this.inviteNewMemberForm.value.teamUserType;
              this.firstname    = this.inviteNewMemberForm.value.firstname;
              this.lastname    = this.inviteNewMemberForm.value.lastname;
              this.countryCodeMobilePh = this.inviteNewMemberForm.value.phoneno.dialCode
                //when user type is clerk/accountant,no provision for team seatup,only for super user save method is called and login usertype is passes as a super usertype.
                this.legalService.registration(this.mailid,this.phoneno,'',this.firstname,'',this.lastname,this.teamUserType,"invite",this.userType,null, this.countryCodeMobilePh).subscribe((res: any) => {

                // this.mes= res.responseMessage;
                let ress: any = JSON.parse(res._body);
                console.log("registration response message",ress.responseMessage);
                let userId = { "userId": ress.respData };
                this.mes = ress.responseMessage;
                this.toastr.success('TEAM MEMBER INVITED SUCCESSFULLY', '');
                this.mes1 = '';
                this.viewMode = true;
                //this.getUserList(this.teamUserType,this.superUserId);
                //this.mailsent = false;
                //window.alert(ress.responseMessage);
                setTimeout( () => {
                  location.reload();
                }, 2000 );
              }, (err: any) => {
                let erro: any = JSON.parse(err._body);
                this.mes1 = erro.responseMessage;
                this.toastr.error(this.mes1, '');
                this.mes = '';
                //this.mailsent = false;
                //window.alert(erro.responseMessage);
              });

        }else{
          this.submittedForinviteMember = false;
        }
      // }
  }
  getUserList( userType : string,superUserId : string) { 
    this.legalService.getTeamList(userType,superUserId).
      subscribe(
        data => {
          let userlistarray = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
          for(var user of userlistarray){
            if(user.activeAccount=='Y'){
              user.activeAccount ='Yes';
            }else if(user.activeAccount=='N'){
              user.activeAccount ='No';
            }
            if(user.invitationSent=='Y'){
              user.invitationSent ='Yes';
            }else if(user.invitationSent=='N'){
              user.invitationSent ='No';
            }
            
            if(this.userType == 'managingpartner'){
              this.userlist.push(user);
              
            }else{
              this.userlist.push(user);
            }
            console.log("get user comp res--" + JSON.stringify(user.activeAccount));
          }
          if(this.userType == 'managingpartner'){
          //this.getUserListLawFarm();
          }

          
          //let invitationSent = 
           
        }
      );
  }

  OnInput(event: any) {
    var nextDate = this.getFormattedDate(this.myform.value.nextDate);
    // console.log("next date in oninput::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  onChange(data) {
    // alert( "Triggered"+data );
    console.log("Triggered", data);
    var nextDate = this.getFormattedDate(this.myform.value.nextDate);
    // console.log("next date in onChange::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  getFormattedDate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;

  }

  getUserListLawFarm(){
    this.legalService.getInviteMemberList(this.userType, this.loginUserId).
    subscribe(
      data => {
        let userlistarray = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
          for(var user of userlistarray){
            if(user.activeAccount=='Y'){
              user.activeAccount ='Yes';
            }else if(user.activeAccount=='N'){
              user.activeAccount ='No';
            }
            if(user.invitationSent=='Y'){
              user.invitationSent ='Yes';
            }else if(user.invitationSent=='N'){
              user.invitationSent ='No';
            }
            this.userlistLawFarm.push(user)
          }
          
        //this.userlistLawFarm = JSON.parse(this.legalService.userData._body).respData;
        this.userlist = [ ...this.userlistLawFarm, ...this.mergeUserList];
        console.log("getTeamList comp res--" + JSON.stringify(this.userlist)); 
      }
    );
  }

  getTeamMemberType( userType : string) {
    this.legalService.getTeamMemberType(userType).
      subscribe(
        data => {
          this.memberTypelist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamMemberType comp res--" + JSON.stringify(this.memberTypelist)); 
        }
      );
  }

  modalclose(){
    //this.viewMode = false;
    this.inviteNewMemberForm.reset();
    console.log("view mode::",this.viewMode);
  }

  onCountryCodeMobilePhChange(selectedCountryCode) {
    this.countryCodeMobilePh = selectedCountryCode;
  }

  private markFormGroupTouched( formGroup: FormGroup ) {
    ( <any>Object ).values( formGroup.controls ).forEach( control => {
      control.markAsTouched();

      if ( control.controls ) {
        this.markFormGroupTouched( control );
      }
    } );
  }

  checkUserCount(flag){
    var userType
    console.log("FLAG => "+flag);
    if(this.superUserType){
      userType = this.superUserType;
    }else{
      userType = this.userType;
    }
    this.legalService.checkNoOfUser(userType,this.superUserId,"N").
      subscribe(
        data => {
          console.log(data)
          
         
           if(data.searchCount  == 2){
            this.toastr.error('To add more user please upgrade your plan', '');
           }else{
             if(flag == 1) {
              $('#myModal').modal('show');   
             }
             if(flag == 2) {
              $('#myModal1').modal('show');   
             }
            
           }
          
        }
      );
  }
}