<section class="headersection">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

        <div class="logobox2">
          <a href="http://wpclawyer.com/" target="_blank"><img src="assets/images/logo.png" alt=""></a>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <h1>WpcLawyer</h1>

      </div>



    </div>
  </div>
</section>

<section class="login-page">
  <div *ngIf="!isLoginUISkipped" class="container">
    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-7 col-lg-7"> </div>

      <div class="col-xs-12 col-sm-6 col-md-5 col-lg-5">
        <div class="login_box">
          <div class="login-box-body">
            <!-- <div class="login-logo"> <a href="#"><img src="../../assets/images/GVSU_logo.png" alt=""></a> </div> -->
            <form [formGroup]="loginForm">
              <div class="form-group has-feedback">
                <label>Username</label>

                <input type="text" class="form-control" placeholder="UserName" formControlName="loginUserName">
                <!-- <span class="glyphicon glyphicon-lock form-control-feedback"></span> -->

                <span class=" form-control-feedback"><i class="material-icons md-24 md-dark">person_outline</i></span>




                <small class="text-danger"
                  *ngIf="loginForm.controls.loginUserName.hasError('required') && ( loginForm.controls.loginUserName.dirty || loginForm.controls.loginUserName.touched || (!loginForm.controls.loginUserName.valid && submittedForlogin))">Please enter email/Username.</small>

              </div>

              <div class="form-group has-feedback">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="loginPwd">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                <small class="text-danger"
                  *ngIf="loginForm.controls.loginPwd.hasError('required') && ( loginForm.controls.loginPwd.dirty || loginForm.controls.loginPwd.touched || (!loginForm.controls.loginPwd.valid && submittedForlogin))">Please enter Password.</small>

              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="checkbox icheck checkbox2">
                    <label>
                      <input type="checkbox">
                      Remember Me </label>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <button type="submit" (click)='loginForward()' class="btn btn-newblue btn-block ">
                    LogIn</button>
                </div>
                <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">
               
                  <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
                  <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
        
                  
              </div> -->

              </div>

              <hr>
              <div class="row">


                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <button type="button" class="btn btn-newblue modalbox1 " data-toggle="modal" data-target="#myModal">
                    Forgot your password</button>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <button type="button" class="btn btn-newblue modalbox2 " data-toggle="modal"
                    data-target="#myModa2">Signup</button>
                </div>


              </div>





              <!-- <div class="card-body box-info" *ngIf=" mes1!=''" style="margin-top: 15px;">

                <span style="color: green;font-weight: bold;font-size: 14px;" *ngIf="mes!=''">{{mes}}</span>
                <span style="color: red;font-weight: bold;font-size: 14px;" *ngIf="mes1!=''">{{mes1}}</span>


              </div> -->



            </form>

          </div>

        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoginUISkipped" class="container">
    <p>Loading....</p>
  </div>
</section>





<!-- Modal -->
<div class="modal fade" id="myModal" role="dialog" *ngIf="mailsent">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Forgot your password? </h4>
      </div>
      <div class="modal-body">
        <p>Please provide your registered email address.</p>

        <div class="form-group input-group">
          <span class="input-group-addon">
            @
          </span>
          <input class="form-control" placeholder="Email" name="email" [(ngModel)]="mailid" type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" #email="ngModel" email required>
        </div>
        <button [disabled]="viewMode==true || (email.invalid && email.untouched)" type="submit" (click)="sendMail()" class="btn btn-blue">
          Continue
        </button>

        <!-- <div class="card-body box-info" *ngIf="mes1!=''||mes!=''" style="margin-top: 15px;">

          <span style="color: green;font-weight: bold;font-size: 16px;" *ngIf="mes!=''">{{mes}}</span>
          <span style="color: red;font-weight: bold;font-size: 16px;" *ngIf="mes1!=''">{{mes1}}</span>


        </div> -->

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="modalclose()">Close</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div id="myModa2" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content Modalbox1">
      <form [formGroup]="signUpForm">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" (click)="modalclose()">&times;</button>
            <h4 class="modal-title">Sign Up</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Title </label>
                  <select class="form-control" formControlName="title" placeholder="Title">
                    <option value="" disabled>--Select--</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                      <small class="text-danger" *ngIf="signUpForm.controls.title.hasError('required') && ( signUpForm.controls.title.dirty || signUpForm.controls.title.touched || (!signUpForm.controls.title.valid && submittedForSignUp))">Title is required.</small>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>First Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="firstname" placeholder="First Name" pattern="^(?!\s*$)[a-zA-Z\s]+$">
                  <small class="text-danger" *ngIf="signUpForm.controls.firstname.hasError('required') && ( signUpForm.controls.firstname.dirty || signUpForm.controls.firstname.touched || (!signUpForm.controls.firstname.valid && submittedForSignUp))">First name is required.</small>
                  <small class="text-danger" *ngIf="signUpForm.get('firstname').errors?.pattern">Please provide only text for first name.</small>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Middle Name </label>
                  <input type="text" class="form-control" formControlName="middlename" placeholder="Middle Name" pattern="^(?!\s*$)[a-zA-Z\s]+$">
                  <small class="text-danger" *ngIf="signUpForm.get('middlename').errors?.pattern">Please provide only text for middle name.</small>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Surname <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="surname" placeholder="Surname" pattern="^(?!\s*$)[a-zA-Z\s]+$">
                  <small class="text-danger" *ngIf="signUpForm.controls.surname.hasError('required') && ( signUpForm.controls.surname.dirty || signUpForm.controls.surname.touched || (!signUpForm.controls.surname.valid && submittedForSignUp))">Surname is required.</small>
                  <small class="text-danger" *ngIf="signUpForm.get('surname').errors?.pattern">Please provide only text for surname.</small>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Email <span class="text-danger">*</span></label>
                  <input type="text"  class="form-control" formControlName="mailid" placeholder="Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <small class="text-danger" *ngIf="signUpForm.controls.mailid.hasError('required') && ( signUpForm.controls.mailid.dirty || signUpForm.controls.mailid.touched || (!signUpForm.controls.mailid.valid && submittedForSignUp))">Email is required.</small>
                  <small class="text-danger" *ngIf="signUpForm.get('mailid').errors?.pattern">Please provide correct email.</small>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Personal Mobile Number <span class="text-danger">*</span></label>
                  <div>   
                  <!-- <select class="form-control  pull-left indiabox1" formControlName="countryCode">
                      <option data-countryCode="IN" *ngFor="let countrycode of countrylist " [value]="countrycode.countryCode">{{countrycode.countryName}}</option>   
                  </select> -->
                  <!-- <input type="tel" class="form-control  pull-right indiabox2"  [(ngModel)]="phoneno" placeholder="Phone"> -->
                  <div style="display: flex;" >
                    <!-- <app-country-code-dropdown
                      [countryCode]="countryCodeMobilePh"
                      (onCountryChange)="onCountryCodeMobilePhChange($event)"
                      [withBorder]="true"
                    ></app-country-code-dropdown> -->
                    <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  [attr.disabled]="viewMode"
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="phoneno" required>
                              </ngx-intl-tel-input>
                    <!-- <input type="tel" class="form-control  pull-right "  formControlName="phoneno" placeholder="Mobile Number" pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$"> -->
                  </div>
                 <!--  <small class="text-danger" *ngIf="signUpForm.controls.phoneno.hasError('required') && ( signUpForm.controls.phoneno.dirty || signUpForm.controls.phoneno.touched || (!signUpForm.controls.phoneno.valid && submittedForSignUp))">Please provide correct mobile number. </small> -->
                  <small class="text-danger" *ngIf="!signUpForm.controls.phoneno.valid && submittedForSignUp">Please provide correct mobile number.</small>
                  <div class="clearfix"></div>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label><b>Registered as:</b> </label>

                  <span class="radio check1" *ngFor="let userT of userTypeArr">
                    <label><input type="radio" style="width: unset;" [checked]="userT[1]==='managingpartner'" name="userType" (change)="getSubscriptionPlan(userT[1])"
                        >&nbsp;{{userT[0]}}</label>
                  </span>

                </div>
              </div>


              <!--############### Plan Details Start Here ##############-->
              <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" *ngIf="planLists.length > 0">
                <div class="form-group">
                  <label><b>Subscription Plan:</b> </label>
                  <span class="radio check1" *ngFor="let eachPlan of planLists; let planIndex = index"> -->
                    <!-- <label><input type="radio" style="width: unset;" [checked]="planIndex == 0" name="planType" [value]="eachPlan.planId" (change)="onSelectPlanType(eachPlan.planId)">&nbsp;{{eachPlan.price}}/{{eachPlan.planType}} <span style="color: #f07614;" *ngIf="eachPlan.discountPrice > 0"><b>(off {{eachPlan.discountPrice}})</b></span> </label> -->
                    <!-- <label><input type="radio" style="width: unset;" [checked]="planIndex == 0" name="planType" [value]="eachPlan.planId" (change)="onSelectPlanType(eachPlan.planId)">&nbsp;{{eachPlan.planDetails}} </label>
                  </span>
                </div>
              </div> -->
              <!--############### Plan Details Ends Here ##############-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="showBox">
                <div class="form-group">
                  <label>Organization Name  <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="orgName" placeholder="Organization Name" [required] ="!showBox">
                  <small class="text-danger" *ngIf="showBox && signUpForm.controls.orgName.hasError('required')  && ( signUpForm.controls.orgName.dirty || signUpForm.controls.orgName.touched || (!signUpForm.controls.orgName.valid && submittedForSignUp))">Organization is required.</small>
                  <small class="text-danger" *ngIf="!signUpForm.controls.orgName.value && submittedForSignUp">Please provide only text for organization.</small>
                </div>
              </div>

              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <button type="submit" (click)="registration()" class="btn btn-blue">Submit</button>
                </div>
              </div> -->

            
            </div>


          </div>
          <!-- <div class="card-body box-info" *ngIf="mes1SignUp!=''||mesSignUp!=''" style="margin-top: 15px;">

            <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mesSignUp!=''"> {{mesSignUp}}</span>
            <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1SignUp!=''">{{mes1SignUp}}</span>


          </div> -->

          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button> -->
            <button type="button" #closebutton class="btn btn-default pull-right" (click)="modalclose()" data-dismiss="modal">Close</button>
            <button type="submit" (click)="registration()" [disabled]="validate" class="btn btn-primary pull-right" style="margin-right: 5px;" >Submit</button>
            <button type="button" #openRegSuccess data-toggle="modal" data-target="#myModa3" style="display: none;"></button>
          </div>

      </form>

    </div>

  </div>
</div>

<div id="myModa3" class="modal fade" role="dialog" #myModa3>
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content Modalbox1">
      <form [formGroup]="signUpForm">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Registration Successfull</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label>You have successfully registered! </label>
                  <label>Detailed instruction have been sent to your email : <strong>"{{userEmail}}"</strong><span class="text-danger">*</span></label>
                  <label>Please check your email and click on the link given to activate your account.<span class="text-danger">*</span></label>
                  <span></span>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button> -->
            <button type="button" class="btn btn-default pull-right" (click)="modalclose()" data-dismiss="modal">Close</button>
            
          </div>

      </form>

    </div>

  </div>
</div>







<section class="footersection">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <p> 
        </p>
      </div>
    </div>
  </div>
</section>