export const environment = {
  production: false,
  //pythonAPI: 'http://18.207.107.171/api/'
  //api: 'http://52.207.235.72:8088/',
  //api:'http://localhost:8083/',
  api:'https://wpclawyer.com:8083/',
  //api:'https://3.11.195.85:8083/',
  //api:'https://webapp.wpclawyer.com:8083/',
  //api: 'http://52.207.208.160:8083/',
  //api: 'http://18.204.227.69:8083/', 
  //api: 'http://34.238.73.29:8083/',
  //payuUrl:'https://test.payu.in/_payment',
  payuUrl:'https://sandboxsecure.payu.in/_payment',
  defaultCountryCode: '+44'
 };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.