import { Component, Input, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { LawyerProfileSchema,AddressSchema } from '../models/profile.model';
import { Location } from '@angular/common';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { CaseService } from '../service/case.service';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
import { ToastrService } from 'ngx-toastr';

import { CountryCodeDropdownComponent } from '../shared/components/country-code-dropdown/country-code-dropdown.component';
import countryCodeList from '../shared/static/phone-country-code.list';
//import { RxwebValidators } from '@rxweb/reactive-form-validators';

declare var $: any;
// declare var jQuery: any;

@Component( {
  selector: 'app-lawyer-profile',
  templateUrl: './lawyer-profile.component.html',
  styleUrls: ['./lawyer-profile.component.css']
} )
export class LawyerProfileComponent implements OnInit {
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
  selectedCountry1:any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"

  dataArr: LawyerProfileSchema;
  // dataArrAddress: AddressSchema;
  myform: FormGroup;
  // addressform:FormGroup;
  isSubmitted=false;
  modalDisplay: boolean=false;
  fareaDisplay: boolean=false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  btnClciked: any;
  isDisabled: boolean=false;
  userdataArr: any[]=[];
  usertype: string;
  userId: string;
  viewMode: boolean;
  profilestatus : string;
  deletedIndex=[];
  courtsOfPracticeMsg:boolean=false;
  public countryCodeList: Array<any> = countryCodeList;

  profilepic: string;
  aadhar: string;
  pan: string;


  practiceArea: any=[];
  showMsg: boolean = false;
  mes = '';
  mes1 = '';

  statelist: any = [];
  districtlist: any = [];
  districtbarlist: any = [];
  cityList: any = [];
  courtlist:any = [];
  courtBarlist:any = [];
  
  stateId: any = [];
  districtId : string;
  cityId : string;
  selectedCourtId : any = [];

  stateBarCouncilId : any = [];
  districtBarId     : any = [];
  loginUserType     : string;
  partnersList      : any = [];
  legalManagers     : any = [];
  // dataArrList       : any[];
  showCustomCourt: any  = [];
  customCourtName: any  = [];

  allStateLists : any = [];

  errorOnImgType: boolean = false;
  errorOnAdhaarType: boolean = false;
  errorOnPanType: boolean =false;
  errorresume:boolean = false;

  removeDocType : string = "";
  saveMode: boolean = true;
  countryCodeMobilePh: string = '+91';
  countryCodeOfficePh: string = '+91';
  

  constructor( private legalbellService: LegalbellService, private router: Router, private toastr: ToastrService, private route: ActivatedRoute, public fb: FormBuilder, private location: Location, private CaseService: CaseService, private navigationLinkParameterService: NavigationLinkParameterService ) {

  }


  compareAccounts = ( item, selected ) => {

    // //console.log( 'compareAccounts', item, selected );
    /*if ( selected.mainArea&&item.mainArea ) {
         return item.mainArea===selected.mainArea;
       }*/
    if ( item.subArea && selected.subArea &&  item.mainArea === selected.mainArea) {

      return item.subArea === selected.subArea;
    }

    return false;
  }
  public onSelectAll() {
    //console.log('OnSelectAll');
    // const selected = this.practiceArea.map(item => item);
    const selected = this.practiceArea;
    // //console.log(selected);
    this.myform.get('lawyerPracticeAreas').patchValue(selected);
  }
  public onClearAll() {
    //console.log('onClearAll');
    this.myform.get('lawyerPracticeAreas').patchValue([]);
  }

  async lawyerPracticeAreas() {

    this.legalbellService.lawyerPracticeAreas().
      subscribe(
        async data => {
          this.practiceArea = await data.respData;
          // //console.log( 'practiceArea', this.practiceArea, );
        }
      );
  }

  createForm() {
    //console.log( "userType in createForm", this.usertype );
    if(this.usertype === 'corporateclient'){
      this.dataArr.lawFirmWebsite = this.dataArr.corporateClientWebsite;
      this.dataArr.lawFirmLinkedin = this.dataArr.corporateClientLinkedinProfile;
      this.dataArr.lawFirmCin = this.dataArr.corporateClientCin;
      this.dataArr.lawFirmPartnerDesignation=this.dataArr.corporateClientDesignation;
    }
    
    // reqbody.corporateClientPan=this.myform.value.lawFirmPan;
    // reqbody.corporateClientDesignation=this.myform.value.lawFirmPartnerDesignation;
    // reqbody.corporateClientNatureOfBusiness=this.myform.value.corporateNatureOfBusiness;
    //  //console.log(this.dataArr.lawyerPracticeAreas);
    // //console.log(this.dataArr);
    // //console.log(this.dataArr.courtsOfPractice.length);

    if(this.dataArr.courtsOfPractice.length < 1){
      this.getStateList((this.dataArr.courtsOfPractice.length));
      this.dataArr.courtsOfPractice.push({stateId:null, districtId: "", courtId: null,courtType:null,courtName:null});
    }
    
    
    let formGroupStructure = {

      firstName       : new FormControl( this.dataArr&&this.dataArr.firstName? this.dataArr.firstName:null),
      middleName      : new FormControl( this.dataArr&&this.dataArr.middleName? this.dataArr.middleName:null),
      lastName        : new FormControl( this.dataArr&&this.dataArr.lastName? this.dataArr.lastName:null),
      phoneNo         : new FormControl( this.dataArr&&this.dataArr.phoneNo? this.dataArr.phoneNo:null),
      officePhoneNo   : new FormControl( this.dataArr&&this.dataArr.officePhoneNo? this.dataArr.officePhoneNo:null),
      address         : new FormControl( this.dataArr&&this.dataArr.address? this.dataArr.address:null),
      chamberAddress  : new FormControl( this.dataArr&&this.dataArr.chamberAddress? this.dataArr.chamberAddress:null),
      officeAddress         : new FormControl( this.dataArr&&this.dataArr.officeAddress? this.dataArr.officeAddress:null),
      residencialAddress    : new FormControl( this.dataArr&&this.dataArr.residencialAddress? this.dataArr.residencialAddress:null),
      stateId       : new FormControl( this.dataArr&&this.dataArr.stateId? this.dataArr.stateId:null),
      districtId    : new FormControl( this.dataArr&&this.dataArr.districtId? this.dataArr.districtId:null),
      cityId        : new FormControl( this.dataArr&&this.dataArr.cityId? this.dataArr.cityId:null),
      houseNo       : new FormControl( this.dataArr&&this.dataArr.houseNo? this.dataArr.houseNo:null),
      streetName    : new FormControl( this.dataArr&&this.dataArr.streetName? this.dataArr.streetName:null),
      landMark      : new FormControl( this.dataArr&&this.dataArr.landMark? this.dataArr.landMark:null),
      pinCode       : new FormControl( this.dataArr&&this.dataArr.pinCode? this.dataArr.pinCode:null),
      profilePicSrc : new FormControl( this.dataArr&&this.dataArr.profilePicSrc? this.dataArr.profilePicSrc:null ),
      stateBarCouncilId   : new FormControl( this.dataArr&&this.dataArr.stateBarCouncilId? this.dataArr.stateBarCouncilId:null),
      // stateBarCouncilId   : new FormControl( this.dataArr&& this.dataArr.courtsOfPractice[0].stateId? this.dataArr.ourtsOfPractice[0].stateId:null),
      // districtBarId       : new FormControl( this.dataArr&&this.dataArr.districtBarId? this.dataArr.districtBarId:null),
      // districtBarId       : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice[0].districtId:null),

      // customCourtName     : this.customCourtName,

      
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice? this.dataArr.courtsOfPractice:null),
      courtsOfPractice    : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice :[]),
      // districtLists       : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice :[]),
      // courtName           : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice[0].courtId:null),
      // districtBarId       : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice[0].districtId? this.dataArr.courtsOfPractice[0].districtId:null),

      
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice? this.dataArr.courtsOfPractice:null),
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice[0].courtId? this.dataArr.courtsOfPractice[0].courtId:null),
      // courtsOfPractice    : this.fb.array(this.courtsOfPracticeFrm()),
      enrollmentNo        : new FormControl( this.dataArr&&this.dataArr.enrollmentNo? this.dataArr.enrollmentNo:null),
      lawyerPracticeAreas : new FormControl( ( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null ),
      linkedinProfileSrc  : new FormControl( this.dataArr&&this.dataArr.linkedinProfileSrc? this.dataArr.linkedinProfileSrc:null ),
      resumePic           : new FormControl( this.dataArr&&this.dataArr.resumePathToS3 ? this.dataArr.resumePathToS3:null ),
      panNoSrc            : new FormControl( this.dataArr&&this.dataArr.panNoSrc? this.dataArr.panNoSrc:null ),
      aadharNoSrc         : new FormControl( this.dataArr&&this.dataArr.aadharNoSrc? this.dataArr.aadharNoSrc:null ),
      panNo               : new FormControl( this.dataArr&&this.dataArr.panNo? this.dataArr.panNo:null ),
      aadharNo            : new FormControl( this.dataArr&&this.dataArr.aadharNo? this.dataArr.aadharNo:null ), 




      userTitle           : new FormControl(this.dataArr && this.dataArr.userTitle ? this.dataArr.userTitle: null),
      userId              : new FormControl( this.userId ),
      userType            : new FormControl( this.usertype ),



      
      lawFirmName         : new FormControl(this.dataArr && this.dataArr.lawFirmName ? this.dataArr.lawFirmName: null),
      lawFirmWebsite      : new FormControl(this.dataArr && this.dataArr.lawFirmWebsite ? this.dataArr.lawFirmWebsite: null),
      trademarkLogoSrc    : new FormControl( this.dataArr&&this.dataArr.trademarkLogoSrc? this.dataArr.trademarkLogoSrc:null ),
      lawFirmProfileSrc   : new FormControl( this.dataArr&&this.dataArr.lawFirmProfileSrc? this.dataArr.lawFirmProfileSrc:null ),
      lawFirmLinkedin     : new FormControl( this.dataArr&&this.dataArr.lawFirmLinkedin? this.dataArr.lawFirmLinkedin:null ),
      corporateClientCin          : new FormControl( this.dataArr&&this.dataArr.corporateClientCin? this.dataArr.corporateClientCin:null ),
      corporateClientPan          : new FormControl( this.dataArr&&this.dataArr.corporateClientPan? this.dataArr.corporateClientPan:null ),
      lawFirmTan          : new FormControl( this.dataArr&&this.dataArr.lawFirmTan? this.dataArr.lawFirmTan:null ),
      lawFirmPartners     : new FormControl( this.dataArr&&this.dataArr.lawFirmPartners? this.dataArr.lawFirmPartners:null ),
      lawFirmPartnerName  : new FormControl( this.dataArr&&this.dataArr.lawFirmPartnerName? this.dataArr.lawFirmPartnerName:null ),
      lawFirmPartnerDesignation   : new FormControl( this.dataArr&&this.dataArr.lawFirmPartnerDesignation? this.dataArr.lawFirmPartnerDesignation:null ),
      lawFirmPartnerEmail         : new FormControl( this.dataArr&&this.dataArr.emailId? this.dataArr.emailId:null ),
      lawFirmPartnerPhoneNo       : new FormControl( this.dataArr&&this.dataArr.lawFirmPartnerPhoneNo? this.dataArr.lawFirmPartnerPhoneNo:null ),
      lawFirmPracticeAreas        : new FormControl( this.dataArr&&this.dataArr.lawFirmPracticeAreas? this.dataArr.lawFirmPracticeAreas:null ),


      corporateCompanyName        : new FormControl( this.dataArr&&this.dataArr.corporateClientCompanyName? this.dataArr.corporateClientCompanyName:null ),
      corporateClientNatureOfBusiness   : new FormControl( this.dataArr&&this.dataArr.corporateClientNatureOfBusiness? this.dataArr.corporateClientNatureOfBusiness:null ),
      corporateProfileSrc         : new FormControl( this.dataArr&&this.dataArr.corporateProfileSrc? this.dataArr.corporateProfileSrc:null ),
      corporateManagers           : new FormControl( this.dataArr&&this.dataArr.corporateManagers? this.dataArr.corporateManagers:null ),
      corporateManagerName        : new FormControl( this.dataArr&&this.dataArr.corporateManagerName? this.dataArr.corporateManagerName:null ),
      corporateManagerDesignation : new FormControl( this.dataArr&&this.dataArr.corporateManagerDesignation? this.dataArr.corporateManagerDesignation:null ),
      corporateManagerEmail       : new FormControl( this.dataArr&&this.dataArr.corporateManagerEmail? this.dataArr.corporateManagerEmail:null ),
      corporateManagerPhoneNo     : new FormControl( this.dataArr&&this.dataArr.corporateManagerPhoneNo? this.dataArr.corporateManagerPhoneNo:null ),


      corporateRegistredAddress   : new FormControl( this.dataArr&&this.dataArr.corporateRegistredAddress? this.dataArr.corporateRegistredAddress:null ),
      corporateBranchAddress      : new FormControl( this.dataArr&&this.dataArr.corporateBranchAddress? this.dataArr.corporateBranchAddress:null ),

      icaiRegNo                   : new FormControl( this.dataArr&&this.dataArr.icaiRegNo? this.dataArr.icaiRegNo:null ),


      clientProfession          : new FormControl( this.dataArr&&this.dataArr.clientProfession? this.dataArr.clientProfession:null ),

      orgDesignation      : new FormControl(this.dataArr && this.dataArr.orgDesignation ? this.dataArr.orgDesignation: null),
      qualification      : new FormControl(this.dataArr && this.dataArr.qualification ? this.dataArr.qualification: null),
      university      : new FormControl(this.dataArr && this.dataArr.university ? this.dataArr.university: null),
      
      emailId         : new FormControl( this.dataArr&&this.dataArr.emailId? this.dataArr.emailId:null),


      //mainUtterance: new FormControl('',Validators.pattern('^[a-zA-Z0-9 ]+$')),

      
      // lawyarRegisteredOfficeAddress: new FormControl( this.dataArr&&this.dataArr.lawyarRegisteredOfficeAddress? this.dataArr.lawyarRegisteredOfficeAddress:null, [Validators.required] ),
      // lawyarResidenceAddress: new FormControl( this.dataArr&&this.dataArr.lawyarResidenceAddress? this.dataArr.lawyarResidenceAddress:null, [Validators.required] ),
      // lawyarChamberAddress: new FormControl( this.dataArr&&this.dataArr.lawyarChamberAddress? this.dataArr.lawyarChamberAddress:null, [Validators.required] ),
      // createdBy: new FormControl(userEmail),
      // userEmail: new FormControl(userEmail),
      
      
      
      barCouncilOfIndiaId: new FormControl( this.dataArr&&this.dataArr.barCouncilOfIndiaId? this.dataArr.barCouncilOfIndiaId:null ),
      // stateBarCouncilId: new FormControl( this.dataArr&&this.dataArr.stateBarCouncilId? this.dataArr.stateBarCouncilId:null ),
      lawyerBankAccount: new FormControl( this.dataArr&&this.dataArr.lawyerBankAccount? this.dataArr.lawyerBankAccount:null ),
      memberDesignation: new FormControl( this.dataArr&&this.dataArr.memberDesignation? this.dataArr.memberDesignation:null ),
      lawyerBankName: new FormControl( this.dataArr&&this.dataArr.lawyerBankName? this.dataArr.lawyerBankName:null ),
      lawyerBankIfscCode: new FormControl( this.dataArr&&this.dataArr.lawyerBankIfscCode? this.dataArr.lawyerBankIfscCode:null ),
      lawyerBankBranch: new FormControl( this.dataArr&&this.dataArr.lawyerBankBranch? this.dataArr.lawyerBankBranch:null ),
      
    }




    this.allStateLists = this.dataArr.courtsOfPractice;
    console.log(this.allStateLists);
    // if(this.loginUserType == 'advocate' ||  this.loginUserType == 'seniorassociate' ||  this.loginUserType == 'juniorassociate' ||  this.loginUserType == 'junioradvocate' || this.loginUserType =='associatepartner' || this.loginUserType == 'seniorpartner' || this.loginUserType == 'counsel' || this.loginUserType == 'managingpartner' || this.loginUserType == 'clerk'){

      this.allStateLists.forEach((eachList, eachIndex)=>{
        formGroupStructure["stateId_"+eachIndex]          =  new FormControl(eachList.stateId);  
        formGroupStructure["districtId_"+eachIndex]       =  new FormControl(eachList.districtId);  
        formGroupStructure["courtId_"+eachIndex]          =  new FormControl((eachList.courtId != null) ? eachList.courtId : "");  
        formGroupStructure["customCourtName_"+eachIndex]  =  new FormControl("");
        
      })

    // }
  
      ///
      let country 
       country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.countryCode)
       console.log("Selected Country")
       console.log(country)
       this.country = country[0].name
      this.selectedCountry = CountryISO[this.country.toString()] 
      this.countryCodeMobilePh = this.dataArr.countryCode;
      if(this.dataArr && this.dataArr.officeCountryCode){
        this.countryCodeOfficePh = this.dataArr['officeCountryCode'];
        let country 
        country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.officeCountryCode)
        //this.country = country[0].name
        this.selectedCountry1 = CountryISO[country[0].name.toString()] 
        this.countryCodeOfficePh = this.dataArr.officeCountryCode;
      }
      this.myform= this.fb.group(formGroupStructure);

      //console.log("test", this.myform.value);
    // this.myform.value.courtsOfPractice = [{stateId: this.dataArr.stateBarCouncilId, districtId: this.dataArr.courtsOfPractice[0].districtId, courtId: this.dataArr.courtsOfPractice[0].courtId}];
    // //console.log(this.myform.value);
  
  }
  // createFormAddress() {
  //   let userId = localStorage.getItem('UserID')?JSON.parse(localStorage.getItem('UserID')).userId: null;
  //   //console.log("userId in address::",userId); 
  //   this.addressform = this.fb.group({
  //     id: new FormControl(this.dataArrAddress && this.dataArrAddress.id ? this.dataArrAddress.id: null),
  //     addressType: new FormControl(this.dataArrAddress && this.dataArrAddress.addressType ? this.dataArrAddress.addressType: null),
  //     addressLine1: new FormControl(this.dataArrAddress && this.dataArrAddress.addressLine1 ? this.dataArrAddress.addressLine1: null),
  //     addressLine2: new FormControl(this.dataArrAddress && this.dataArrAddress.addressLine2 ? this.dataArrAddress.addressLine2: null),
  //     addressState: new FormControl(this.dataArrAddress && this.dataArrAddress.addressState ? this.dataArrAddress.addressState: null),
  //     addressCity: new FormControl(this.dataArrAddress && this.dataArrAddress.addressCity ? this.dataArrAddress.addressCity: null),
  //     addressPin: new FormControl(this.dataArrAddress && this.dataArrAddress.addressPin ? this.dataArrAddress.addressPin: null,[Validators.required]),
     
  //     userId: new FormControl(this.userId)
  //     })
  // }
  ngOnInit() {
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.selectedCountry1 = CountryISO[this.country.toString()] 
    this.profilestatus = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).profilestatus: null;
    //console.log("Profile status in dashboard::",this.profilestatus);
    this.saveMode=this.navigationLinkParameterService.saveMode;
    //console.log(this.saveMode);
    //Get function area on page loading
    this.route.paramMap.subscribe( params => {
      this.userId=params.get( 'id' );
      let userTypepar=params.get( 'id1' );
      let viewmode=params.get( 'id2' );
      //console.log( "userId in lawyer profile::", this.userId );
      //console.log( "userType in lawyer profile::", userTypepar );
      //console.log( "viewmode in lawyer profile::", viewmode );

      if ( userTypepar!=null && userTypepar=='lawyerassociate'||userTypepar=='lawyeroffcouncil' ) {
        this.usertype=userTypepar;
        //this.createForm();
      } else {
        this.usertype = localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
        //console.log( "usertype in seesion::", this.usertype );
      }
      if ( viewmode === 'view' ) {
        this.viewMode = true;
        //console.log( 'viewMode:', this.viewMode );
      }

      if ( this.userId!=null ) {
        this.getProfileData( this.userId );
      } else {
        let userId    = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
        this.userId   = userId;
        this.getProfileData( userId );
      }

    //   if(this.usertype =='individual'){
    //     this.userdataArr.push('OFFICE');
    //     this.userdataArr.push('RESIDENCE');
    //     //console.log("userdataArr in client address",this.userdataArr[0])
    //   }else if(this.usertype=='corporate'){
    //     this.userdataArr.push('REGISTERED OFFICE')
    //     this.userdataArr.push('BRANCH OFFICE');
    //   // }else if(this.usertype =='lawyeradmin'||this.usertype =='lawyerassociate'){
    //     }else if(this.usertype =='advocate'||this.usertype =='advocate'){
    //     //console.log("user type in lawyer address:",this.usertype)
    //     this.userdataArr.push('REGISTERED OFFICE');
    //     this.userdataArr.push('CHAMBER');
    //     this.userdataArr.push('RESIDENCE');
    //   }
    //  //console.log(this.userdataArr);

    } );
     //this.myform.get('userEmail').setValue(this.userEmail);
      // if (this.userId != null) {
      //   this.getProfileAdress(this.userId);
      // }
    this.ngForm();

    this.lawyerPracticeAreas();

    // this.getStateList();


    this.partnersList     = [1,2,3,4,5,6,7,8,9,10];
    this.legalManagers    = [1,2,3,4,5,6,7,8,9,10];
    this.loginUserType    = localStorage.getItem('profileInfo') ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
    
    // this.loginUserType    = 'accountant';
    // //console.log(this.loginUserType);
    setTimeout( () => {
      this.saveMode=this.navigationLinkParameterService.saveMode;
      //console.log(this.saveMode);
    }, 500 );
    

  }

  onCountryCodeMobilePhChange (currentSelection: string) {
    this.countryCodeMobilePh = currentSelection;
  }

  onCountryCodeOfficePhChange (currentSelection: string) {
    this.countryCodeOfficePh = currentSelection;
  }

  addStateRow(){
    // this.myform().push(this.newStateRow());
    this.getStateList(this.dataArr.courtsOfPractice.length);
    
    // this.dataArr.courtsOfPractice.push({stateId:null, districtId: null, courtId: null,courtType:null,courtName:null});
    this.dataArr.courtsOfPractice.push({stateId:null, districtId: "", courtId: null,courtType:null,courtName:null});
    this.onSelectState(null,2, this.dataArr.courtsOfPractice.length);
    // this.allStateLists.push({stateId:null, districtId: null, courtId: null});
    // this.createForm();
    
  }

  removeCourt(removeIndex){
    let element = document.getElementById('div_'+removeIndex);
    element.parentNode.removeChild(element);
    this.deletedIndex.push(removeIndex);
    console.log(this.deletedIndex);
    // //console.log(removeIndex);
    // //console.log(this.allStateLists);
    // this.dataArr.courtsOfPractice.splice(removeIndex, 1);
    //  this.allStateLists.splice(removeIndex, 1);
    //  console.log(this.allStateLists);
//     this.dataArr.courtsOfPractice.forEach((eachElement, elementIndex) => {
//       // element.get('value').markAsTouched()
//       //console.log(eachElement, elementIndex);
//       // this.myform.addControl(dynamicState[elementIndex], new FormControl(eachElement.stateId));
//       this.getStateList(elementIndex);

//       this.onSelectState(this.dataArr.courtsOfPractice[elementIndex].stateId, 2, elementIndex);
//       this.onSelectDistrict(this.dataArr.courtsOfPractice[elementIndex].districtId, 2, elementIndex);
//       this.selectedCourtId[elementIndex] = this.dataArr.courtsOfPractice[elementIndex].courtId;
//       this.showCustomCourt[elementIndex] = false;

// });
    // this.createForm();
    // this.ngForm();
    
  }
  // convenience getter for easy access to form fields
  get f() { return this.myform.controls; }


  //get profile data
  getProfileData( userid: string ) {
    //console.log( "userId in getProfileData::", userid );
    this.legalbellService.getProfileData( userid ).
      subscribe(
        data => {
          let res=JSON.parse( this.legalbellService.userData._body );
          this.dataArr=JSON.parse( this.legalbellService.userData._body ).respData;

          // //console.log( 'sdssdsd', this.dataArr.profilePicSrc );
          if ( res.status===200&&res.searchCount===1 ) {

            if(this.dataArr.courtsOfPractice.length > 0){
              // let selectedPractice =  this.dataArr.courtsOfPractice[0].stateId;
              this.dataArr.courtsOfPractice.forEach((eachElement, elementIndex) => {
                // element.get('value').markAsTouched()
                //console.log(eachElement, elementIndex);
                // this.myform.addControl(dynamicState[elementIndex], new FormControl(eachElement.stateId));
                this.getStateList(elementIndex);

                this.onSelectState(this.dataArr.courtsOfPractice[elementIndex].stateId, 2, elementIndex);
                // this.onSelectDistrict(this.dataArr.courtsOfPractice[elementIndex].districtId, 2, elementIndex);
                this.selectedCourtId[elementIndex] = this.dataArr.courtsOfPractice[elementIndex].courtId;
                this.showCustomCourt[elementIndex] = false;
          
      });
            }

            this.createForm();

            

            //let lawyerPracticeAreas_val=( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null;
            // this.myform.patchValue( { 'lawyerPracticeAreas': lawyerPracticeAreas_val } );
          }
          //this.createForm();
          // //console.log( "getProfileData comp res--"+JSON.stringify( this.dataArr.emailId ) );
        }
      );
  }


  handleFileInput( files: FileList, type ) {
    let data={ docType: type, userId: this.userId };

    //console.log( 'data', data );
    //console.log(files);
    this.errorOnImgType = false;
    this.errorresume = false;
    this.errorOnAdhaarType = false;
    this.errorOnPanType = false;
    let ext = null;
    if (files.length > 0) {
      ext = files
      .item(0)
      .name.substr(files.item(0).name.lastIndexOf(".") + 1);
    }
    //console.log(ext);
    if(type === 'resume' && (ext != "jpeg"  && ext != "jpg" && ext != "gif" && ext != "png" && ext !="doc" && ext !="docx" && ext !="pdf" )){
      this.errorresume=true;
      return false;
    } else if (
      type !== "resume" &&
      files.item(0).type != "image/jpeg" &&
      files.item(0).type != "image/jpg" &&
      files.item(0).type != "image/gif" &&
      files.item(0).type != "image/png" && 
      files.item(0).type != "application/pdf"
    ) {
      if (type === "profilepic") {
        this.errorOnImgType = true;
      }else if(type==='aadhar') {
        this.errorOnAdhaarType = true;
      }else if(type==='pan'){
        this.errorOnPanType = false;
      }
      return false;
    }
    this.legalbellService.postLawyerFile( files.item( 0 ), data ).subscribe( ( fData ) => {
      if ( this.userId!=null ) {
        // this.getProfileData( this.userId );
      } else {
        let userId=localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
        this.userId=userId;
        // this.getProfileData( userId );
      }

    }, error => {
      //console.log( error );
    } );

  }

  //################## Save Function Call Start Here
  saveAllData() {

          this.resultShow='Executing function...';
          //console.log(this.myform.value.courtsOfPractice);

        
          let finalCourtsOfPractice = [];
          console.log(this.showCustomCourt);
          this.showCustomCourt.forEach((eachCourt, elementCourtIndex) => {
            if(this.deletedIndex.indexOf(elementCourtIndex)===-1)
            {
                    if(eachCourt ===  true){
                        finalCourtsOfPractice.push({stateId: this.stateBarCouncilId[elementCourtIndex], districtId: 18, courtId: null, courtType: "District", courtName:this.customCourtName[elementCourtIndex]});
                    }else{
                        finalCourtsOfPractice.push({stateId: this.stateBarCouncilId[elementCourtIndex], districtId: 18, courtId: this.selectedCourtId[elementCourtIndex]});
                    }
            }
          })

          this.myform.controls['courtsOfPractice'].setValue(finalCourtsOfPractice);
        
          //console.log(this.myform.value);
          
          // if(!(/^[a-zA-Z\s]+$/.test(this.myform.value.firstName))){
          //   window.alert("Please provide only text for first name.")
          //   return false;
          // }

          // if(!(/^[a-zA-Z\s]+$/.test(this.myform.value.middleName))){
          //   window.alert("Please provide only text for middle name.")
          //   return false;
          // }

          // if(!(/^[a-zA-Z\s]+$/.test(this.myform.value.lastName))){
          //   window.alert("Please provide only text for sur name.")
          //   return false;
          // }

          // if(!(/^\d{9,18}$/.test(this.myform.value.lawyerBankAccount))){
          //   window.alert("Please provide correct bank number.")
          //   return false;
          // }

          // if(!(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/.test(this.myform.value.lawyerBankIfscCode))){
          //   window.alert("Please provide correct IFSC code.")
          //   return false;
          // }

          // return false;

          let reqbody=this.myform.value;

          if (reqbody.phoneNo) {
            
    
            reqbody.countryCode = this.myform.value.phoneNo.dialCode;
            reqbody.phoneNo = this.myform.value.phoneNo.number.replace(/\s/g, "");
            reqbody.phoneNo = reqbody.phoneNo.replace(/-/g, "");
            console.log("Phone Number")
            console.log(reqbody.countryCode)
            console.log(reqbody.phoneNo)
          }

          if (reqbody.officePhoneNo) {
            
            reqbody.officeCountryCode = this.myform.value.officePhoneNo.dialCode;
            //reqbody.countryCode = this.myform.value.officePhoneNo.dialCode;
            reqbody.officePhoneNo = this.myform.value.officePhoneNo.number.replace(/\s/g, "");
            reqbody.officePhoneNo = reqbody.officePhoneNo.replace(/-/g, "");
            
          }

          if(this.usertype =='corporateclient'){
            reqbody.corporateClientCompanyName=this.myform.value.corporateCompanyName;
            reqbody.corporateClientWebsite=this.myform.value.lawFirmWebsite;
            reqbody.corporateClientLinkedinProfile=this.myform.value.lawFirmLinkedin;
            reqbody.corporateClientCin=this.myform.value.lawFirmCin;
            reqbody.corporateClientPan=this.myform.value.lawFirmPan;
            reqbody.corporateClientDesignation=this.myform.value.lawFirmPartnerDesignation;
            reqbody.corporateClientNatureOfBusiness=this.myform.value.corporateNatureOfBusiness;
          }
      
          
          console.log(reqbody);
          // return false;
          this.legalbellService.saveAllData( JSON.stringify( reqbody ) ).subscribe( ( data ) => {
                this.mydata=JSON.parse( this.legalbellService.saveResponse._body );
                // //console.log("saveArr comp res--" + this.saveArr);
                //console.log( "statusCode comp res--"+this.mydata+'btn clk--'+this.btnClciked );
                //this.logResult = 'The questions and answer for the Intent PrinterEdit are saved in to the database successfuly.The same has been mailed to the adminstrator for modification in skills.';
                if ( this.mydata.status=='200' ) {
                  this.resultShow='Execution Result : ';
                  this.btnShow='(Details)';
                  this.statusCode="Success";
                  this.toastr.success('Profile is saved successfully', '');
                  // this.getProfileData( "" );

                  if ( this.userId!=null ) {
                    this.getProfileData( this.userId );
                  } else {
                    let userId=localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
                    this.userId=userId;
                    this.getProfileData( userId );
                  }



                  var existing=localStorage.getItem( 'profileInfo' );

                  // If no existing data, create an array
                  // Otherwise, convert the localStorage string to an array
                  existing=existing? JSON.parse( existing ):{};

                  // Add new data to localStorage Array
                  existing['profilestatus']='Y';

                  // Save back to localStorage
                  localStorage.setItem( 'profileInfo', JSON.stringify( existing ) );
                  setTimeout( () => {
                    location.reload();
                  }, 2000 );
                }
                else {
                  this.statusCode="Fail";
                  this.toastr.error('Profile can not be saved', '');
                }
          }, ( err: any ) => {
            this.toastr.error('Profile can not be saved', '');
                //console.log( err );
          }, () => {
                // this.modalDisplay = !this.modalDisplay;
          }
          );


          this.modalDisplay=false;

  }

  //################ Update the courtsOfPractice on change
  updateCourtsPractice(courtId, rowIndex){

    this.selectedCourtId[rowIndex] = courtId;
    if(courtId == 'newCourt'){
        this.myform.get('customCourtName_'+rowIndex).valueChanges.subscribe(  
          value=> {  
            // //console.log(value);  
            this.customCourtName[rowIndex] = value;
          }  
      ); 
      this.showCustomCourt[rowIndex] = true;
      this.dataArr.courtsOfPractice[rowIndex].stateId=this.stateBarCouncilId[rowIndex];
          this.dataArr.courtsOfPractice[rowIndex].districtId=this.districtBarId[rowIndex];
          this.dataArr.courtsOfPractice[rowIndex].courtId=null;
          this.dataArr.courtsOfPractice[rowIndex].courtType='District';
          this.dataArr.courtsOfPractice[rowIndex].courtName=this.customCourtName[rowIndex];
    }else{
          this.showCustomCourt[rowIndex] = false;
          //console.log(this.selectedCourtId, this.districtBarId, this.stateBarCouncilId);

          // let finalCourtsOfPractice = [];

          // this.stateBarCouncilId.forEach((eachElement, elementIndex) => {
          //       // //console.log(eachElement, elementIndex);
          //       finalCourtsOfPractice.push({stateId: this.stateBarCouncilId[elementIndex], districtId: this.districtBarId[elementIndex], courtId: this.selectedCourtId[elementIndex]});
              
          // });

          // // this.myform.courtsOfPractice = [{stateId: this.stateBarCouncilId, districtId: this.districtBarId, courtId: courtId}];
          // this.myform.controls['courtsOfPractice'].setValue(finalCourtsOfPractice);
          // //console.log(this.myform.value);
          this.dataArr.courtsOfPractice[rowIndex].stateId=this.stateBarCouncilId[rowIndex];
          this.dataArr.courtsOfPractice[rowIndex].districtId=this.districtBarId[rowIndex];
          this.dataArr.courtsOfPractice[rowIndex].courtId=courtId;
    }
  }

  

  



  ngForm() {
    new FormGroup( {

    } )
  }

  //Show output
  resultBtnShow: boolean=false;
  showOutput() {
    this.resultBtnShow=!this.resultBtnShow;
  }

  ngSubmit() {
    //console.log( "form value", this.myform.value );
  }

  saveIntentCorfirmation() {
    // //console.log( "form value", this.myform.value);
    //console.log('abc',this.myform );
    this.courtsOfPracticeMsg=false;
    let areCourtsvalid = true;
    this.isSubmitted=true;
    this.markFormGroupTouched(this.myform);
      let versions = this.myform.controls['courtsOfPractice'].value;
      versions.forEach((eachCourt, elementCourtIndex) => {
        console.log(eachCourt);//
        if(this.deletedIndex.indexOf(elementCourtIndex)===-1)
        {
                console.log('2nd');
                if(eachCourt.courtId===null || eachCourt.courtId == "")
                {
                  console.log('3rd');
                  if(eachCourt.courtName===null || eachCourt.courtName== ""){ 
                    console.log('4th');
                    areCourtsvalid = false;
                    if(this.loginUserType == 'advocate' ||  this.loginUserType == 'seniorassociate' ||  this.loginUserType == 'juniorassociate' ||  this.loginUserType == 'junioradvocate' || this.loginUserType =='associatepartner' || this.loginUserType == 'seniorpartner' || this.loginUserType == 'counsel' || this.loginUserType == 'managingpartner' || this.loginUserType == 'clerk'){
                      this.courtsOfPracticeMsg=true;
                      areCourtsvalid = false;
                    }
                  }
                }  
                
        }
      });
      console.log(areCourtsvalid)
      if(this.loginUserType =="accountant"){
        areCourtsvalid = true
      }
    if (!areCourtsvalid) {
      return false;
    }
    if(!this.myform.valid){
      // console.log(JSON.stringify(this.myform.value));
      console.log(this.myform.value);
      // console.log(this.myform.controls['courtsOfPractice'].value);
      console.log('Invalid Form');
      // alert( "Please put the required details" );
      // this.myform.get('phrasalArr')['controls'].forEach(element => {
      //   element.get('value').markAsTouched()
      // });
      //console.log("I m if");
    }else if(this.errorOnImgType==true || this.errorresume == true || this.errorOnAdhaarType == true || this.errorOnPanType == true){
      console.log("error else if ==============");
      return false;
    }else{
      this.modalDisplay=true;
      //console.log("I m else");
      $("#saveIntent").modal('show');
      // //console.log( "modalDisplay::", this.modalDisplay );
    }
  }


  abc() {
    //console.log( this.myform );
  }

  private markFormGroupTouched( formGroup: FormGroup ) {
    ( <any>Object ).values( formGroup.controls ).forEach( control => {
      control.markAsTouched();
      // console.log(control.value, control.status);
      if ( control.controls ) {
        this.markFormGroupTouched( control );
      }
    } );
  }


  removeDoc(docType) {
            
          $("#removeFileConfirm").modal("show");
          this.removeDocType = docType;
            
  }


  removeFileConfirm(confirmValue){


        if(confirmValue == 'Y'){

              let formVal = {
                "userId": this.userId,
                "docType": this.removeDocType,
              };
              //console.log('removeDoc', formVal);
              this.legalbellService.removeDoc(JSON.stringify(formVal)).subscribe((data) => {
                //console.log('postCaseData response', data);
                // alert( data.responseMessage );
                this.showMsg = true;
                this.mes = data.responseMessage;
                this.mes1 = '';
                setTimeout(()=>{
                  location.reload();
                },1000);

              }, (err: any) => {
                //console.log(err);
                this.showMsg = true;
                this.mes1 = 'Failed to delete file.';

              }, () => {
                // this.modalDisplay = !this.modalDisplay;
              }
              );
        }



  }



  //########### Get State List Start Here
  getStateList(stateIndex) {
    this.CaseService.getSateList().
      subscribe(
        data => {
          this.statelist[stateIndex] = JSON.parse(this.CaseService.userData._body).respData;
          // //console.log("getStateList comp res--" + JSON.stringify(this.statelist));
        }
      );
  }

  //########### Get District List On State Selection Start Here
  onSelectState(stateid,index = 0, rowIndex) {
      console.log("onSelectState--", stateid+' -- '+index+'  -- '+rowIndex);
      
      if(index == 1){
        //this.courtsOfPracticeMsg = false;
          this.stateId = stateid;
      }else if(index == 2){
       // this.courtsOfPracticeMsg = false;
          this.stateBarCouncilId[rowIndex] = stateid;
      }

      if(index == 1){
          // this.districtId = districtid;
          this.districtId = this.districtId;

          this.CaseService.getCourtList(this.stateId).subscribe(
            data => {


                this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
                //console.log(JSON.parse(this.CaseService.userData._body).respData);
            
              
              // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
            }
          );
      }else if(index == 2){
          // this.districtBarId[rowIndex] = districtid;
          console.log(this.stateBarCouncilId[rowIndex]);
          this.districtBarId[rowIndex] = this.districtId;
          this.CaseService.getCourtList(this.stateBarCouncilId[rowIndex]).
          subscribe(
            data => {
              
                this.courtBarlist[rowIndex] = JSON.parse(this.CaseService.userData._body).respData;
                //console.log(JSON.parse(this.CaseService.userData._body).respData);
              
              // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
            }
          );
      }


      // this.CaseService.getDistrictList(stateid).
      // subscribe(
      //   data => {
      //       if(index == 1){
      //         this.districtlist[rowIndex]     = JSON.parse(this.CaseService.userData._body).respData;
      //       }else if(index == 2){
      //         this.districtbarlist[rowIndex]  = JSON.parse(this.CaseService.userData._body).respData;
      //       }
          
      //     // //console.log("getDistrictList comp res--" + JSON.stringify(this.districtlist));
      //   }
      // );
      // //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }


  onSelectDistrict(districtid, index, rowIndex) {
    // console.log("onSelectDistrict--", districtid+' -- '+index+'  -- '+rowIndex);
    if(index == 1){
        this.districtId = districtid;

        this.CaseService.getCourtList(this.stateId, districtid).subscribe(
          data => {


              this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
              //console.log(JSON.parse(this.CaseService.userData._body).respData);
           
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }else if(index == 2){
        this.districtBarId[rowIndex] = districtid;

        this.CaseService.getCourtList(this.stateBarCouncilId[rowIndex], districtid).
        subscribe(
          data => {
            
              this.courtBarlist[rowIndex] = JSON.parse(this.CaseService.userData._body).respData;
              //console.log(JSON.parse(this.CaseService.userData._body).respData);
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }

  }
  


 //get profile data
  // getProfileAdress(userid:string) {
  //   this.legalbellService.getProfileAdress(userid).
  //     subscribe(
  //       data => {
  //         let res = JSON.parse(this.legalbellService.userData._body);
  //         this.dataArrList = JSON.parse(this.legalbellService.userData._body).respData;
    
  //         //console.log("getProfileAdress comp res--" + res.status);
  //         if (res.status === 200 && res.searchCount === 1) {
  //           //this.createForm();
  //         } else {
  //         //this.createForm();  
  //     }
  //         // //console.log("getProfileAdress comp res--" + JSON.stringify(this.dataArrList));
  //         //console.log(this.dataArrList);
  //       }
  //     );
  // }
  //  //get profile data
  // getSingleAdress(adressid:string) {
  //   this.legalbellService.getSingleAdress(adressid).
  //     subscribe(
  //       data => {
  //         let res = JSON.parse(this.legalbellService.userData._body);
  //         this.dataArrAddress = JSON.parse(this.legalbellService.userData._body).respData;
    
  //         //console.log("getSingleAdress comp res--" + res.status);
  //         if (res.status === 200 && res.searchCount === 1) {
  //           //this.userdataArr.push(this.dataArr.addressType);
  //           //console.log("userdataArr in getSingleAdress ",this.userdataArr[0])
  //           this.createFormAddress();
  //         } else {
  //         this.createFormAddress();
  //     }
  //         // //console.log("getSingleAdress addressType--" + JSON.stringify(this.dataArr.addressType));
  //       }
  //     );
  // }
  // modalclose(){
  //   //console.log("modal close");
  //   this.addressform.reset();
  // }

}