import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators , NgForm} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { LawyerProfileSchema,AddressSchema } from '../models/profile.model';
import { Location } from '@angular/common';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';
import { CaseService } from '../service/case.service';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter } from 'ng-pick-datetime';
//import { RxwebValidators } from '@rxweb/reactive-form-validators';

declare var $: any;
// declare var jQuery: any;

@Component( {
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.css']
} )
export class OrganizationProfileComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"

  dataArr: LawyerProfileSchema;
  // dataArrAddress: AddressSchema;
  myform: FormGroup;
  // addressform:FormGroup;

  editNewHearingForm:boolean = false
  newHearingForm: FormGroup;
  @ViewChild('variable')
  variable: ElementRef;
  fileToUpload: File = null;
  subNewHearingForm:boolean = false
  editMode: boolean = false;
  loader : boolean = false;
  newHearingId:string
  newHearingList : any = []
  deleteHiringDetails :any= {}
  newHearingOrderDocValue:any
  newHearingOrderDocName:string


  isSubmitted=false;
  modalDisplay: boolean=false;
  fareaDisplay: boolean=false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  btnClciked: any;
  isDisabled: boolean=false;
  userdataArr: any[]=[];
  usertype: string;
  userId: string;
  viewMode: boolean;
  profilestatus : string;


  profilepic: string;
  aadhar: string;
  pan: string;


  practiceArea: any=[];
  showMsg: boolean = false;
  mes = '';
  mes1 = '';

  statelist: any = [];
  districtlist: any = [];
  districtbarlist: any = [];
  cityList: any = [];
  courtlist:any = [];
  courtBarlist:any = [];
  
  stateId: string;
  districtId : string;
  cityId : string;

  stateBarCouncilId : string;
  districtBarId     : string;
  loginUserType     : string;
  partnersList      : any = [];
  legalManagers     : any = [];
  // dataArrList       : any[];
  orgId             : string;
  lawFirmName       : string;
  submittedForm     : boolean;
  pageTitle         : string;

  errorOnImgType    : boolean =false;
  public countryCodeList: Array<any> = countryCodeList;

  removeDocType : string = "";
  saveMode: boolean = true;
  countryCodeMobilePh: string = environment.defaultCountryCode;

  constructor(dateTimeAdapter: DateTimeAdapter<any>, private legalbellService: LegalbellService,private toastr: ToastrService, private router: Router, private route: ActivatedRoute, public fb: FormBuilder, private location: Location, private CaseService: CaseService, private navigationLinkParameterService: NavigationLinkParameterService) {
    dateTimeAdapter.setLocale('en-IN');
  }


  compareAccounts = ( item, selected ) => {

    // //console.log( 'compareAccounts', item, selected );
    /*if ( selected.mainArea&&item.mainArea ) {
         return item.mainArea===selected.mainArea;
       }*/
    if ( item.subArea && selected.subArea &&  item.mainArea === selected.mainArea) {

      return item.subArea === selected.subArea;
    }

    return false;
  }
  public onSelectAll() {
    //console.log('OnSelectAll');
    // const selected = this.practiceArea.map(item => item);
    //console.log(this.practiceArea);
    const selected = this.practiceArea;
    // //console.log(selected);
    // this.myform.controls['lawyerPracticeAreas'].setValue(selected);
    this.myform.get('lawFirmPracticeAreas').patchValue(selected);
  }
  public onClearAll() {
    //console.log('onClearAll');
    this.myform.get('lawFirmPracticeAreas').patchValue([]);
  }

  async lawyerPracticeAreas() {

    this.legalbellService.lawyerPracticeAreas().
      subscribe(
        async data => {
          this.practiceArea = await data.respData;
          // //console.log( 'practiceArea', this.practiceArea, );
        }
      );
  }

  createForm() {
    //console.log( "userType in createForm", this.usertype );
    //  //console.log(this.dataArr.lawyerPracticeAreas);
    //console.log(this.dataArr);
    // let dataArr1=JSON.stringify(this.dataArr);
    //console.log(this.dataArr['orgId']);
    // //console.log(dataArr1.orgId);
    // //console.log(this.dataArr.courtsOfPractice.length);
    this.newHearingForm = this.fb.group({
      hearingJudje: new FormControl('', [Validators.required]),
      hearingDate: new FormControl('', [Validators.required])
     
    });

    this.myform=this.fb.group({

      firstName       : new FormControl( this.dataArr&&this.dataArr.firstName? this.dataArr.firstName:null),
      middleName      : new FormControl( this.dataArr&&this.dataArr.middleName? this.dataArr.middleName:null),
      lastName        : new FormControl( this.dataArr&&this.dataArr.lastName? this.dataArr.lastName:null),
      phoneNo         : new FormControl( this.dataArr&&this.dataArr.phoneNo? this.dataArr.phoneNo:null),
      officePhoneNo   : new FormControl( this.dataArr&&this.dataArr.officePhoneNo? this.dataArr.officePhoneNo:null),
      address         : new FormControl( this.dataArr&&this.dataArr.address? this.dataArr.address:null),
      chamberAddress  : new FormControl( this.dataArr&&this.dataArr.chamberAddress? this.dataArr.chamberAddress:null),
      officeAddress         : new FormControl( this.dataArr&&this.dataArr.officeAddress? this.dataArr.officeAddress:null),
      residencialAddress    : new FormControl( this.dataArr&&this.dataArr.residencialAddress? this.dataArr.residencialAddress:null),
      stateId       : new FormControl( this.dataArr&&this.dataArr.stateId? this.dataArr.stateId:null),
      districtId    : new FormControl( this.dataArr&&this.dataArr.districtId? this.dataArr.districtId:null),
      cityId        : new FormControl( this.dataArr&&this.dataArr.cityId? this.dataArr.cityId:null),
      houseNo       : new FormControl( this.dataArr&&this.dataArr.houseNo? this.dataArr.houseNo:null),
      streetName    : new FormControl( this.dataArr&&this.dataArr.streetName? this.dataArr.streetName:null),
      landMark      : new FormControl( this.dataArr&&this.dataArr.landMark? this.dataArr.landMark:null),
      pinCode       : new FormControl( this.dataArr&&this.dataArr.pinCode? this.dataArr.pinCode:null),
      profilePicSrc : new FormControl( this.dataArr&&this.dataArr.profilePicSrc? this.dataArr.profilePicSrc:null ),
      stateBarCouncilId   : new FormControl( this.dataArr&&this.dataArr.stateBarCouncilId? this.dataArr.stateBarCouncilId:null),
      // stateBarCouncilId   : new FormControl( this.dataArr&& this.dataArr.courtsOfPractice[0].stateId? this.dataArr.ourtsOfPractice[0].stateId:null),
      // districtBarId       : new FormControl( this.dataArr&&this.dataArr.districtBarId? this.dataArr.districtBarId:null),
      // districtBarId       : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice[0].districtId:null),

      
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice? this.dataArr.courtsOfPractice:null),
      // courtsOfPractice    : new FormControl( this.dataArr&&(this.dataArr.courtsOfPractice.length>0)? this.dataArr.courtsOfPractice[0].courtId:null),
      // districtBarId       : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice[0].districtId? this.dataArr.courtsOfPractice[0].districtId:null),

      
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice? this.dataArr.courtsOfPractice:null),
      // courtsOfPractice    : new FormControl( this.dataArr&&this.dataArr.courtsOfPractice[0].courtId? this.dataArr.courtsOfPractice[0].courtId:null),
      // courtsOfPractice    : this.fb.array(this.courtsOfPracticeFrm()),
      enrollmentNo        : new FormControl( this.dataArr&&this.dataArr.enrollmentNo? this.dataArr.enrollmentNo:null),
      lawyerPracticeAreas : new FormControl( ( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null ),
      linkedinProfileSrc  : new FormControl( this.dataArr&&this.dataArr.linkedinProfileSrc? this.dataArr.linkedinProfileSrc:null ),
      resumePic           : new FormControl( this.dataArr&&this.dataArr.resumePathToS3 ? this.dataArr.resumePathToS3:null ),
      panNoSrc            : new FormControl( this.dataArr&&this.dataArr.panNoSrc? this.dataArr.panNoSrc:null ),
      aadharNoSrc         : new FormControl( this.dataArr&&this.dataArr.aadharNoSrc? this.dataArr.aadharNoSrc:null ),
      



      userTitle           : new FormControl(this.dataArr && this.dataArr.userTitle ? this.dataArr.userTitle: null),
      userId              : new FormControl( this.userId ),
      userType            : new FormControl( this.usertype ),



      
      lawFirmName         : new FormControl(this.dataArr && this.dataArr.orgName ? this.dataArr.orgName: null),
      lawFirmWebsite      : new FormControl(this.dataArr && this.dataArr.orgWebsite ? this.dataArr.orgWebsite: null),
      trademarkLogoSrc    : new FormControl( this.dataArr&&this.dataArr.trademarkLogoSrc? this.dataArr.trademarkLogoSrc:null ),
      lawFirmProfileSrc   : new FormControl( this.dataArr&&this.dataArr.lawFirmProfileSrc? this.dataArr.lawFirmProfileSrc:null ),
      lawFirmLinkedin     : new FormControl( this.dataArr&&this.dataArr.orgLinkedinProfile? this.dataArr.orgLinkedinProfile:null ),
      lawFirmCin          : new FormControl( this.dataArr&&this.dataArr.orgCin? this.dataArr.orgCin:null ),
      lawFirmPan          : new FormControl( this.dataArr&&this.dataArr.orgPan? this.dataArr.orgPan:null ),
      lawFirmTan          : new FormControl( this.dataArr&&this.dataArr.orgTan? this.dataArr.orgTan:null ),
      lawFirmPartners     : new FormControl( this.dataArr&&this.dataArr.lawFirmPartners? this.dataArr.lawFirmPartners:null ),
      lawFirmPartnerName  : new FormControl( this.dataArr&&this.dataArr.lawFirmPartnerName? this.dataArr.lawFirmPartnerName:null ),
      lawFirmPartnerDesignation   : new FormControl( this.dataArr&&this.dataArr.lawFirmPartnerDesignation? this.dataArr.lawFirmPartnerDesignation:null ),
      lawFirmPartnerEmail         : new FormControl( this.dataArr&&this.dataArr.orgEmailId? this.dataArr.orgEmailId:null ),
      lawFirmPartnerPhoneNo       : new FormControl( this.dataArr&&this.dataArr.orgPhoneNo? this.dataArr.orgPhoneNo:null ),
      lawFirmPracticeAreas        : new FormControl( this.dataArr&&this.dataArr.orgPracticeAreas? this.dataArr.orgPracticeAreas:null ),


      corporateCompanyName        : new FormControl( this.dataArr&&this.dataArr.corporateCompanyName? this.dataArr.corporateCompanyName:null ),
      corporateNatureOfBusiness   : new FormControl( this.dataArr&&this.dataArr.corporateNatureOfBusiness? this.dataArr.corporateNatureOfBusiness:null ),
      corporateProfileSrc         : new FormControl( this.dataArr&&this.dataArr.corporateProfileSrc? this.dataArr.corporateProfileSrc:null ),
      corporateManagers           : new FormControl( this.dataArr&&this.dataArr.corporateManagers? this.dataArr.corporateManagers:null ),
      corporateManagerName        : new FormControl( this.dataArr&&this.dataArr.corporateManagerName? this.dataArr.corporateManagerName:null ),
      corporateManagerDesignation : new FormControl( this.dataArr&&this.dataArr.corporateManagerDesignation? this.dataArr.corporateManagerDesignation:null ),
      corporateManagerEmail       : new FormControl( this.dataArr&&this.dataArr.corporateManagerEmail? this.dataArr.corporateManagerEmail:null ),
      corporateManagerPhoneNo     : new FormControl( this.dataArr&&this.dataArr.corporateManagerPhoneNo? this.dataArr.corporateManagerPhoneNo:null ),


      corporateRegistredAddress   : new FormControl( this.dataArr&&this.dataArr.corporateRegistredAddress? this.dataArr.corporateRegistredAddress:null ),
      corporateBranchAddress      : new FormControl( this.dataArr&&this.dataArr.corporateBranchAddress? this.dataArr.corporateBranchAddress:null ),

      icaiRegNo                   : new FormControl( this.dataArr&&this.dataArr.icaiRegNo? this.dataArr.icaiRegNo:null ),


      
      
      
      
      profession          : new FormControl( this.dataArr&&this.dataArr.profession? this.dataArr.profession:null ),

      orgDesignation      : new FormControl(this.dataArr && this.dataArr.orgDesignation ? this.dataArr.orgDesignation: null),
      qualification      : new FormControl(this.dataArr && this.dataArr.qualification ? this.dataArr.qualification: null),
      university      : new FormControl(this.dataArr && this.dataArr.university ? this.dataArr.university: null),
      

      

      




      emailId         : new FormControl( this.dataArr&&this.dataArr.orgEmailId? this.dataArr.orgEmailId:null),


      //mainUtterance: new FormControl('',Validators.pattern('^[a-zA-Z0-9 ]+$')),
      
      
      
      
      // lawyarRegisteredOfficeAddress: new FormControl( this.dataArr&&this.dataArr.lawyarRegisteredOfficeAddress? this.dataArr.lawyarRegisteredOfficeAddress:null, [Validators.required] ),
      // lawyarResidenceAddress: new FormControl( this.dataArr&&this.dataArr.lawyarResidenceAddress? this.dataArr.lawyarResidenceAddress:null, [Validators.required] ),
      // lawyarChamberAddress: new FormControl( this.dataArr&&this.dataArr.lawyarChamberAddress? this.dataArr.lawyarChamberAddress:null, [Validators.required] ),
      // createdBy: new FormControl(userEmail),
      // userEmail: new FormControl(userEmail),
      
      
      
      barCouncilOfIndiaId: new FormControl( this.dataArr&&this.dataArr.barCouncilOfIndiaId? this.dataArr.barCouncilOfIndiaId:null ),
      // stateBarCouncilId: new FormControl( this.dataArr&&this.dataArr.stateBarCouncilId? this.dataArr.stateBarCouncilId:null ),
      lawyerBankAccount: new FormControl( this.dataArr&&this.dataArr.lawyerBankAccount? this.dataArr.lawyerBankAccount:null ),
      memberDesignation: new FormControl( this.dataArr&&this.dataArr.memberDesignation? this.dataArr.memberDesignation:null ),
      lawyerBankName: new FormControl( this.dataArr&&this.dataArr.lawyerBankName? this.dataArr.lawyerBankName:null ),
      lawyerBankIfscCode: new FormControl( this.dataArr&&this.dataArr.lawyerBankIfscCode? this.dataArr.lawyerBankIfscCode:null ),
      lawyerBankBranch: new FormControl( this.dataArr&&this.dataArr.lawyerBankBranch? this.dataArr.lawyerBankBranch:null ),
      accountType: new FormControl( this.dataArr&&this.dataArr.accountType? this.dataArr.accountType:null ),
      lawyerBankAccountT: new FormControl( this.dataArr&&this.dataArr.lawyerBankAccountT? this.dataArr.lawyerBankAccountT:null ),
      lawyerBankNameT: new FormControl( this.dataArr&&this.dataArr.lawyerBankNameT? this.dataArr.lawyerBankNameT:null ),
      lawyerBankIfscCodeT: new FormControl( this.dataArr&&this.dataArr.lawyerBankIfscCodeT? this.dataArr.lawyerBankIfscCodeT:null ),
      lawyerBankBranchT: new FormControl( this.dataArr&&this.dataArr.lawyerBankBranchT? this.dataArr.lawyerBankBranchT:null ),
      accountTypeT: new FormControl( this.dataArr&&this.dataArr.accountTypeT? this.dataArr.accountTypeT:null ),
      lawFirmFax:new FormControl( this.dataArr&&this.dataArr.orgFax? this.dataArr.orgFax:null ),
      orgNatureOfBusiness: new FormControl( this.dataArr&&this.dataArr.orgNatureOfBusiness? this.dataArr.orgNatureOfBusiness:null ),
      
      
      

    } )

    if (this.dataArr.countryCode) {
      let country 
      country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.countryCode)
      console.log("Selected Country")
      console.log(country)
      this.country = country[0].name
     this.selectedCountry = CountryISO[this.country.toString()] 
     this.countryCodeMobilePh = this.dataArr.countryCode;
      //this.countryCodeMobilePh = this.dataArr.countryCode;
    }
  }
  // createFormAddress() {
  //   let userId = localStorage.getItem('UserID')?JSON.parse(localStorage.getItem('UserID')).userId: null;
  //   //console.log("userId in address::",userId); 
  //   this.addressform = this.fb.group({
  //     id: new FormControl(this.dataArrAddress && this.dataArrAddress.id ? this.dataArrAddress.id: null),
  //     addressType: new FormControl(this.dataArrAddress && this.dataArrAddress.addressType ? this.dataArrAddress.addressType: null),
  //     addressLine1: new FormControl(this.dataArrAddress && this.dataArrAddress.addressLine1 ? this.dataArrAddress.addressLine1: null),
  //     addressLine2: new FormControl(this.dataArrAddress && this.dataArrAddress.addressLine2 ? this.dataArrAddress.addressLine2: null),
  //     addressState: new FormControl(this.dataArrAddress && this.dataArrAddress.addressState ? this.dataArrAddress.addressState: null),
  //     addressCity: new FormControl(this.dataArrAddress && this.dataArrAddress.addressCity ? this.dataArrAddress.addressCity: null),
  //     addressPin: new FormControl(this.dataArrAddress && this.dataArrAddress.addressPin ? this.dataArrAddress.addressPin: null,[Validators.required]),
     
  //     userId: new FormControl(this.userId)
  //     })
  // }
  ngOnInit() {
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.profilestatus = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).profilestatus: null;
    //console.log("Profile status in dashboard::",this.profilestatus);

    //Get function area on page loading
    this.route.paramMap.subscribe( params => {
      this.userId=params.get( 'id' );
      let userTypepar=params.get( 'id1' );
      let viewmode=params.get( 'id2' );
      //console.log( "userId in lawyer profile::", this.userId );
      //console.log( "userType in lawyer profile::", userTypepar );
      //console.log( "viewmode in lawyer profile::", viewmode );

      if ( userTypepar!=null && userTypepar=='lawyerassociate'||userTypepar=='lawyeroffcouncil' ) {
        this.usertype=userTypepar;
        //this.createForm();
      } else {
        this.usertype = localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
        //console.log( "usertype in seesion::", this.usertype );
      }
      if ( viewmode === 'view' ) {
        this.viewMode = true;
        //console.log( 'viewMode:', this.viewMode );
      }

      if ( this.userId!=null ) {
        this.getProfileData( this.userId );
      } else {
        let userId    = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
        this.userId   = userId;
        this.getProfileData( userId );
      }

    //   if(this.usertype =='individual'){
    //     this.userdataArr.push('OFFICE');
    //     this.userdataArr.push('RESIDENCE');
    //     //console.log("userdataArr in client address",this.userdataArr[0])
    //   }else if(this.usertype=='corporate'){
    //     this.userdataArr.push('REGISTERED OFFICE')
    //     this.userdataArr.push('BRANCH OFFICE');
    //   // }else if(this.usertype =='lawyeradmin'||this.usertype =='lawyerassociate'){
    //     }else if(this.usertype =='advocate'||this.usertype =='advocate'){
    //     //console.log("user type in lawyer address:",this.usertype)
    //     this.userdataArr.push('REGISTERED OFFICE');
    //     this.userdataArr.push('CHAMBER');
    //     this.userdataArr.push('RESIDENCE');
    //   }
    //  //console.log(this.userdataArr);

    } );
     //this.myform.get('userEmail').setValue(this.userEmail);
      // if (this.userId != null) {
      //   this.getProfileAdress(this.userId);
      // }
    this.ngForm();
    
    this.lawyerPracticeAreas();

    this.getTotalHiringList();
    this.getClientId(this.userId);

    this.getStateList(); 
    if(this.usertype==='managingpartner' || this.usertype === 'associatepartner' || this.usertype=='seniorpartner'){
      this.pageTitle='Law Firm';
    }else{
      this.pageTitle='Organization';
    }

    this.partnersList     = [1,2,3,4,5,6,7,8,9,10];
    this.legalManagers    = [1,2,3,4,5,6,7,8,9,10];
    this.loginUserType    = localStorage.getItem('profileInfo') ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;
    // this.loginUserType    = 'accountant';
    // //console.log(this.loginUserType);
    setTimeout( () => {
      this.saveMode=this.navigationLinkParameterService.saveMode;
      //console.log(this.saveMode);
    }, 500 );

    console.log(this.dataArr);
  }
  // convenience getter for easy access to form fields
  get f() { return this.myform.controls; }

  onCountryCodeMobilePhChange (currentSelection: string) {
    this.countryCodeMobilePh = currentSelection;
  }

  //get profile data
  getProfileData( userid: string ) {
    //console.log( "userId in getProfileData::", userid );
    this.legalbellService.getProfileData( userid ).
      subscribe(
        data => {
          let res=JSON.parse( this.legalbellService.userData._body );
          this.dataArr=JSON.parse( this.legalbellService.userData._body ).respData;
          //console.log(this.dataArr['orgId']);
          this.getOrganizationProfileData(this.dataArr['orgId']);
          this.orgId=this.dataArr['orgId'];
          this.lawFirmName=this.dataArr['orgName']
          // //console.log( 'sdssdsd', this.dataArr.profilePicSrc );
          if ( res.status===200&&res.searchCount===1 ) {

            if(this.dataArr.courtsOfPractice.length > 0){
              // let selectedPractice =  this.dataArr.courtsOfPractice[0].stateId;
              this.onSelectState(this.dataArr.courtsOfPractice[0].stateId, 2)
              this.onSelectDistrict(this.dataArr.courtsOfPractice[0].districtId, 2)
            }

            if (this.dataArr.countryCode) {
              let country 
               country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.countryCode)
               console.log("Selected Country")
                console.log(country)
              this.country = country[0].name
              this.selectedCountry = CountryISO[this.country.toString()] 
              this.countryCodeMobilePh = this.dataArr.countryCode;
             // this.countryCodeMobilePh = this.dataArr.countryCode;
            }

            // this.createForm();

            

            //let lawyerPracticeAreas_val=( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null;
            // this.myform.patchValue( { 'lawyerPracticeAreas': lawyerPracticeAreas_val } );
          }
          //this.createForm();
          // //console.log( "getProfileData comp res--"+JSON.stringify( this.dataArr.emailId ) );
        }
      );
  }
    //get profile data
  getOrganizationProfileData(orgId) {
    //console.log( "userId in getProfileData::", orgId );
    this.legalbellService.getOrganizationProfileData(orgId).
      subscribe(
        data => {
          let res=JSON.parse( this.legalbellService.userData._body );
          this.dataArr=JSON.parse( this.legalbellService.userData._body ).respData;

          console.log( 'sdssdsd', this.dataArr );
          if ( res.status===200&&res.searchCount===1 ) {

            // if(this.dataArr.courtsOfPractice.length > 0){
            //   // let selectedPractice =  this.dataArr.courtsOfPractice[0].stateId;
            //   this.onSelectState(this.dataArr.courtsOfPractice[0].stateId, 2)
            //   this.onSelectDistrict(this.dataArr.courtsOfPractice[0].districtId, 2)
            // }

            this.createForm();
            

            //let lawyerPracticeAreas_val=( this.dataArr&&this.dataArr.lawyerPracticeAreas )? this.dataArr.lawyerPracticeAreas:null;
            // this.myform.patchValue( { 'lawyerPracticeAreas': lawyerPracticeAreas_val } );
          }
          //this.createForm();
          // //console.log( "getProfileData comp res--"+JSON.stringify( this.dataArr.emailId ) );
        }
      );
  }


  handleFileInput( files: FileList, type ) {

        let data={ docType: type, orgId : this.orgId };
        
        //console.log( 'data', data );
        //console.log( 'files', files.item(0).type);
        this.errorOnImgType = false;
        if(files.item(0).type != "image/jpeg"  && files.item(0).type != "image/jpg" && files.item(0).type != "image/gif" && files.item(0).type != "image/png" && files.item(0).type != "application/pdf"){
            this.errorOnImgType = true;
            return false;
        }
        this.legalbellService.trademarkFile( files.item( 0 ), data ).subscribe( ( fData ) => {
          if ( this.userId!=null ) {
            // this.getProfileData( this.userId );
          } else {
            let userId=localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
            this.userId=userId;
            // this.getProfileData( userId );
          }

        }, error => {
          //console.log( error );
        } );

  }

  //################## Save Function Call Start Here
  saveAllData() {

        this.resultShow='Executing function...';


        //console.log( this.myform.value );
        //console.log(this.myform.value.userId);
        let obj : any ={}
        let phno = this.myform.value.lawFirmPartnerPhoneNo.number.replace(/\s/g, "")
        phno = phno.replace(/-/g, "");
        let reqbody ={
                        "orgId" : this.orgId,
                        "loginUserId" :this.myform.value.userId,
                        "orgWebsite" : this.myform.value.lawFirmWebsite,
                        "orgLinkedinProfile" : this.myform.value.lawFirmLinkedin,
                        "orgCin" : this.myform.value.lawFirmCin,
                        "orgPan" : this.myform.value.lawFirmPan,
                        "orgTan" : this.myform.value.lawFirmTan,
                        "orgFax" : this.myform.value.lawFirmFax,
                        "noOfPartner" : null,
                        "orgEmailId" : this.myform.value.lawFirmPartnerEmail,
                        "orgPhoneNo" : phno,
                        "orgPracticeAreas" :  this.myform.value.lawFirmPracticeAreas,
                        'orgNatureOfBusiness':this.myform.value.orgNatureOfBusiness,
                        //"accountType":this.myform.value.accountType,
                        /* 'lawyerBankAccount':this.myform.value.lawyerBankAccount,
                        'lawyerBankName':this.myform.value.lawyerBankName,
                        'lawyerBankIfscCode':this.myform.value.lawyerBankIfscCode,
                        'lawyerBankBranch':this.myform.value.lawyerBankBranch, */
                        }
                        obj.organizationMst = reqbody
                        obj.lawyerBankAccount =this.myform.value.lawyerBankAccount
                        obj.lawyerBankName =this.myform.value.lawyerBankName
                        obj.lawyerBankIfscCode =this.myform.value.lawyerBankIfscCode
                        obj.lawyerBankBranch =this.myform.value.lawyerBankBranch
                        obj.countryCode = this.myform.value.lawFirmPartnerPhoneNo.dialCode
                        obj.accountType = this.myform.value.accountType
                        obj.lawyerBankAccountT =this.myform.value.lawyerBankAccountT
                        obj.lawyerBankNameT =this.myform.value.lawyerBankNameT
                        obj.lawyerBankIfscCodeT =this.myform.value.lawyerBankIfscCodeT
                        obj.lawyerBankBranchT =this.myform.value.lawyerBankBranchT
                        obj.accountTypeT = this.myform.value.accountTypeT
                        console.log(obj)
                        console.log("Request Body ===>> "+reqbody);

        this.legalbellService.saveOrganizationData( JSON.stringify( obj ) ).subscribe( ( data ) => {
              this.mydata=JSON.parse( this.legalbellService.saveResponse._body );
              // //console.log("saveArr comp res--" + this.saveArr);
              //console.log( "statusCode comp res--"+this.mydata+'btn clk--'+this.btnClciked );
              //this.logResult = 'The questions and answer for the Intent PrinterEdit are saved in to the database successfuly.The same has been mailed to the adminstrator for modification in skills.';
              if ( this.mydata.status=='200' ) {
                this.resultShow='Execution Result : ';
                this.btnShow='(Details)';
                this.statusCode="Success";
                this.toastr.success('Profile  successfully updated', '');
                // this.getProfileData( "" );
                // location.reload();
                if ( this.userId!=null ) {
                  this.getProfileData( this.userId );
                } else {
                  let userId=localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
                  this.userId=userId;
                  this.getProfileData( userId );
                }

                this.router.navigate(['/lawyerprofile']);

                // var existing=localStorage.getItem( 'profileInfo' );

                // // If no existing data, create an array
                // // Otherwise, convert the localStorage string to an array
                // existing=existing? JSON.parse( existing ):{};

                // // Add new data to localStorage Array
                // existing['profilestatus']='Y';

                // // Save back to localStorage
                // localStorage.setItem( 'profileInfo', JSON.stringify( existing ) );
                setTimeout( () => {
                  //location.reload();
                }, 2000 );
              }
              else {
                this.statusCode="Fail";
                this.toastr.error('Profile update unsuccessfull', '');
              }
        }, ( err: any ) => {
              //console.log( err );
              this.toastr.error('Profile update unsuccessfull', '');
        }, () => {
              // this.modalDisplay = !this.modalDisplay;
        }
        );


        this.modalDisplay=false;

  }

  //################ Update the courtsOfPractice on change
  updateCourtsPractice(courtId){
        //console.log(courtId, this.districtBarId, this.stateBarCouncilId);
        this.myform.value.courtsOfPractice = [{stateId: this.stateBarCouncilId, districtId: this.districtBarId, courtId: courtId}];
  }

  



  ngForm() {
    new FormGroup( {

    } )
  }

  //Show output
  resultBtnShow: boolean=false;
  showOutput() {
    this.resultBtnShow=!this.resultBtnShow;
  }

  ngSubmit() {
    //console.log( "form value", this.myform.value );
  }

  saveIntentCorfirmation() {
    //console.log( "form value", this.myform.value );
    //console.log( 'abc', this.myform );
    // this.isSubmitted=true;
    this.submittedForm = true;
    //if ( this.caseForm.invalid && this.hearingDtlVo.invalid )

    //this.markFormGroupTouched( this.myform );
    //if ( !this.myform.valid ) {
      // alert( "Please put the required details" );
      // this.myform.get('phrasalArr')['controls'].forEach(element => {
      //   element.get('value').markAsTouched()
      // });
    //} else {
      this.modalDisplay=true;
      //console.log( "modalDisplay::", this.modalDisplay );
      //return;
    //}
    
  }


  abc() {
    //console.log( this.myform );
  }

  private markFormGroupTouched( formGroup: FormGroup ) {
    ( <any>Object ).values( formGroup.controls ).forEach( control => {
      control.markAsTouched();

      if ( control.controls ) {
        this.markFormGroupTouched( control );
      }
    } );
  }


  removeDoc(docType) {
            
          $("#removeFileConfirm").modal("show");
          this.removeDocType = docType;
            
  }


  removeFileConfirm(confirmValue){


        if(confirmValue == 'Y'){

              let formVal = {
                "userId": this.userId,
                "docType": this.removeDocType,
              };
              //console.log('removeDoc', formVal);
              this.legalbellService.removeDoc(JSON.stringify(formVal)).subscribe((data) => {
                //console.log('postCaseData response', data);
                // alert( data.responseMessage );
                this.showMsg = true;
                this.mes = data.responseMessage;
                this.mes1 = '';
                setTimeout(()=>{
                  location.reload();
                },1000);

              }, (err: any) => {
                //console.log(err);
                this.showMsg = true;
                this.mes1 = 'Failed to delete file.';

              }, () => {
                // this.modalDisplay = !this.modalDisplay;
              }
              );
        }



  }



  //########### Get State List Start Here
  getStateList() {
    this.CaseService.getSateList().
      subscribe(
        data => {
          this.statelist = JSON.parse(this.CaseService.userData._body).respData;
          // //console.log("getStateList comp res--" + JSON.stringify(this.statelist));
        }
      );
  }

  //########### Get District List On State Selection Start Here
  onSelectState(stateid,index = 0) {
    //console.log("onSelectState--", stateid);
    
    if(index == 1){
        this.stateId = stateid;
    }else if(index == 2){
        this.stateBarCouncilId = stateid;
    }
    this.CaseService.getDistrictList(stateid).
      subscribe(
        data => {
            if(index == 1){
              this.districtlist     = JSON.parse(this.CaseService.userData._body).respData;
            }else if(index == 2){
              this.districtbarlist  = JSON.parse(this.CaseService.userData._body).respData;
            }
          
          // //console.log("getDistrictList comp res--" + JSON.stringify(this.districtlist));
        }
      );
    //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }


  onSelectDistrict(districtid, index) {
    //console.log("onSelectDistrict--", districtid);
    if(index == 1){
        this.districtId = districtid;

        this.CaseService.getCourtList(this.stateId, districtid).subscribe(
          data => {


              this.courtlist = JSON.parse(this.CaseService.userData._body).respData;
           
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }else if(index == 2){
        this.districtBarId = districtid;

        this.CaseService.getCourtList(this.stateBarCouncilId, districtid).
        subscribe(
          data => {
            
              this.courtBarlist = JSON.parse(this.CaseService.userData._body).respData;
            
            // //console.log("getCourtList comp res--" + JSON.stringify(this.courtlist));
          }
        );
    }
    
    
    //this.districtlist = this.CaseService.getSateList().filter((item) => item.countryid == countryid);
  }


 //get profile data
  // getProfileAdress(userid:string) {
  //   this.legalbellService.getProfileAdress(userid).
  //     subscribe(
  //       data => {
  //         let res = JSON.parse(this.legalbellService.userData._body);
  //         this.dataArrList = JSON.parse(this.legalbellService.userData._body).respData;
    
  //         //console.log("getProfileAdress comp res--" + res.status);
  //         if (res.status === 200 && res.searchCount === 1) {
  //           //this.createForm();
  //         } else {
  //         //this.createForm();  
  //     }
  //         // //console.log("getProfileAdress comp res--" + JSON.stringify(this.dataArrList));
  //         //console.log(this.dataArrList);
  //       }
  //     );
  // }
  //  //get profile data
  // getSingleAdress(adressid:string) {
  //   this.legalbellService.getSingleAdress(adressid).
  //     subscribe(
  //       data => {
  //         let res = JSON.parse(this.legalbellService.userData._body);
  //         this.dataArrAddress = JSON.parse(this.legalbellService.userData._body).respData;
    
  //         //console.log("getSingleAdress comp res--" + res.status);
  //         if (res.status === 200 && res.searchCount === 1) {
  //           //this.userdataArr.push(this.dataArr.addressType);
  //           //console.log("userdataArr in getSingleAdress ",this.userdataArr[0])
  //           this.createFormAddress();
  //         } else {
  //         this.createFormAddress();
  //     }
  //         // //console.log("getSingleAdress addressType--" + JSON.stringify(this.dataArr.addressType));
  //       }
  //     );
  // }
  // modalclose(){
  //   //console.log("modal close");
  //   this.addressform.reset();
  // }

  getClientId(opt){
    console.log(opt)
    localStorage.setItem("CIDVALUE","")
    localStorage.setItem("CIDVALUE",opt)
  }

  OnInput(event: any) {
    //var hearingDate = this.getFormattedDate(this.newHearingForm.value.hearingDate);
    /* var nextDate = this.getFormattedDate(this.caseDetailForm.value.nextDate);
    var openDate = this.getFormattedDate(this.caseForm.value.openDate);
    var pendingDate = this.getFormattedDate(this.caseForm.value.pendingDate);
    var closedDate = this.getFormattedDate(this.caseForm.value.closedDate); */
    // console.log("next date in oninput::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
    //this.newHearingForm.patchValue({'hearingDate': hearingDate});
  }

  onChange(data) {
    //var hearingDate = this.getFormattedDate(this.newHearingForm.value.hearingDate);
    // alert( "Triggered"+data );
    /* console.log("Triggered", data);
    var nextDate = this.getFormattedDate(this.caseDetailForm.value.nextDate);
    
    var openDate = this.getFormattedDate(this.caseForm.value.openDate);
    var pendingDate = this.getFormattedDate(this.caseForm.value.pendingDate);
    var closedDate = this.getFormattedDate(this.caseForm.value.closedDate); */
    // console.log("next date in onChange::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
    //this.newHearingForm.patchValue({'hearingDate': hearingDate});
  }

  newHearingFIleUpload(files: FileList){
    this.fileToUpload = files.item(0);
  }

  newHearingSave(){
    this.loader = true;
    this.subNewHearingForm = true
    if (this.newHearingForm.invalid) {
      this.toastr.error('Blank Inputs not allowed', '');
      setTimeout(()=>{
       location.reload();
        //this.load();
      },2000);
      //this.loader = false;

      return false;
    } else {
      this.subNewHearingForm =false
      $("#hearingInsertDialog").modal("hide");
      console.log(this.newHearingForm.value)
      var hearingDate = this.getFormattedDate(this.newHearingForm.value.hearingDate);
      
      let data
       if(!this.editNewHearingForm){
        data = { hearingDate: hearingDate, caseNo: this.userId, userId: this.userId , hearingJudje: this.newHearingForm.value.hearingJudje,hearingId:null};
       }else{
        data = { hearingDate: hearingDate, caseNo: this.userId, userId: this.userId , hearingJudje: this.newHearingForm.value.hearingJudje,hearingId:this.newHearingId};
       }
    // console.log('handleFileInput data *******************'+JSON.stringify(data));
    this.CaseService.saveNewInsuranceData(this.fileToUpload, data).subscribe(data => {
      // location.reload();
      
      this.showMsg = true;
      this.mes = 'Hearing details saved succcessfully.';
      this.mes1 = '';
      this.toastr.success(this.mes, '');
      this.getTotalHiringList()
      setTimeout(()=>{
        this.mes = "";
      },5000);

      //this.viewCaseDetails(this.caseNo);
      // alert( 'File upload success.' );
      if(this.editMode == true){
      setTimeout(()=>{
        // location.reload();
        //this.load();
      },1000);
      }else{
        //this.viewCaseDetails(this.caseNo);
      }

      // console.log( 'file upload response', data );
      // do something, if upload success
    }, error => {
      this.toastr.error('Hearing details not saved.', '');
      console.log(error);
    });
    }

  }

  getTotalHiringList(){
    this.CaseService.getHiringList({ caseNo: this.userId}).subscribe(async data => {
      console.log(data.respData);
      this.newHearingList = data.respData;
      if(this.loader){
      this.loader = false;
      }
    }, (err: any) => {  
      if(this.loader){
        this.loader = false;
        }
    }, () => {
    }
    );
  
  }

  deleteHearing(row){
    $('#deleteHiringNew').modal('show');
    //this.deleteNewHearingConfirmation(row)
    this.deleteHiringDetails = row;
  }

  edit(row){
      this.editNewHearingForm = true;
      this.newHearingOrderDocValue = row.hearingOrder
       this.newHearingOrderDocName = row.orderDocName
       this.newHearingId = row.hearingId
      this.newHearingForm.patchValue({ 'hearingDate': new Date(row.hearingDate) });
      this.newHearingForm.patchValue({ 'hearingJudje': row.hearingJudje });
      $('#hearingInsertDialog').modal('show');
     
  }

  addNewHearingDetails(){
    this.editNewHearingForm = false;
    this.newHearingForm.reset()
    this.variable.nativeElement.value = "";
  }

  deleteNewHearingConfirmation(){
    this.loader = true;
    let row = this.deleteHiringDetails
    this.CaseService.deleteHiringList({ hearingId: row.hearingId,userId:localStorage.getItem("CIDVALUE"),caseNo:row.caseNo}).subscribe(async data => {
      this.getTotalHiringList();
      this.toastr.success("Hearing deleted successfull", '');
      $('#deleteHiringNew').modal('hide');
    }, (err: any) => {  
      this.toastr.error("Hearing delete unsuccessfull", '');
      $('#deleteHiringNew').modal('hide');
      this.loader = false;
    }, () => {
    }
    );
  }

  historyFileInput(files: FileList, type) {
    this.fileToUpload = files.item(0);

    console.log('fie name', this.fileToUpload.name);
    //let data={ docType: type, caseNo: this.caseNo, userId: this.userId };

    /*this.CaseService.postFile( this.fileToUpload, data ).subscribe( data => {
      this.showMsg=true;
      this.mes='File upload success.';
      this.mes1='';
      this.viewCaseDetails( this.caseNo );
    }, error => {
      console.log( error );
    } );*/

  }

  getFormattedDate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;

  }



}