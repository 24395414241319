<div class="content-wrapper">
  <div *ngIf="loader" class="spinnerbox">  
    <div class="spinner"></div>
  </div>
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">fact_check</i>  </div>
          <h3 class="box-title main-header-top">Fee Agreement</h3>
        </div>

        <div class="box-body">
          <div class="box">
            <!--<div class="box-header with-border">
                <h5 *ngIf="viewMode2">{{clientName}}</h5>
                 <h3 class="box-title card-text">Client & Case Specific :</h3>
                <div class="box-tools pull-right">
                  <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                </div>
            </div> >-->
          <!-- <form [formGroup]="myform3">   -->
            <div class="box-body" *ngIf="viewMode" > 
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Select Client</label>
                    <select class="form-control"  (change)="onSelectClient($event.target.value)" [value]='selectedId'>
                    <option [value]="">--Select--</option>
                    <option *ngFor="let data of mes" [value]="data.userId" [selected]="data.userId == selectedId">{{data.clientShowName}}</option> 
                    </select>
                </div>
                </div>
                
            </div>
            <!-- <div class="box-footer">              
              <input type="button" class="btn btn-primary pull-right" (click)="scheduleofFeesSubmit()" value="Continue">
            </div> -->
          <!-- </form> -->
            <div class="box-body" *ngIf="viewMode1">
              <div class="row">
                <div class="col-sm-12">
                  <input type="button" class="btn btn-primary pull-right" (click)="scheduleofFeesSubmit()" value="Continue">
                </div>
              </div>
                <!-- <form [formGroup]="myform" (ngSubmit)="scheduleofFeesSubmit()"> -->
                <div class="box"  class="">
                    <div class="box-body">
                      <form [formGroup]="myform" #testForm (ngSubmit)="scheduleofFeesSubmitChecked()" *ngIf="hasCheckedList">
                        <div class="table-responsive form-group">  
                            <h4 *ngIf="sfa">Services selected for this client</h4>        
                            <table class="table">
                            <thead>
                                <tr>
                                    <th width="5%">#</th>
                                    <th width="30%">Category </th>
                                    <th width="30%">Particulars Of Services</th>
                                    <th width="20%">Agreed By <br> Client</th>
                                    <th width="15%">Rate <small>(£)</small></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fees of checkedList let i=index">   
                                    <td><input type="checkbox"   id={{i}}  (click)="feesId(i)" [checked]="checkAgreed(fees.agreedByClient,i)"  disabled></td>
                                    <td><input type="text" class="form-control tribunals_{{i}}" formControlName="tribunals_{{i}}"  readonly></td>
                                    <td><input type="text" class="form-control particularsOfServices_{{i}}" formControlName="particularsOfServices_{{i}}" [readonly]="checkRevise(fees.agreedByClient)"> </td>
                                    <td>{{fees.agreedByClient}}</td>
                                    <td><input type="text" class="form-control amount_{{i}}" formControlName="amount_{{i}}" (keyup)="amountChecked($event.target.value,i)" [readonly]="checkRevise(fees.agreedByClient)"> </td>
                                    <td *ngIf="!checkRevise(fees.agreedByClient)"><button type="button" class="btn btn-primary pull-right" (click)="finalAgreedID(fees.id)" >Agreed By Client </button></td>
                                    <td><button type="button" class="btn btn-primary" (click)="revise(i)" >Revise</button></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>  
                      </form>
                      <form [formGroup]="myform1" *ngIf="hasUncheckedList">
                          <div class="table-responsive form-group">  
                            <h4 *ngIf="sfa">Services available for selection</h4>        
                            <table class="table">
                            <thead>
                                <tr>
                                    <th width="5%">#</th>
                                    <th width="40%">Category </th>
                                    <th width="40%">Particulars Of <br> Services</th>
                                    <th width="15%">Rate <small>(£)</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fees1 of uncheckedList let j=index">   
                                    <td><input type="checkbox" (click)="UncheckfeesId(j)"></td>
                                    <td><input type="text" class="form-control tribunalsUncheck_{{j}}" formControlName="tribunalsUncheck_{{j}}"  readonly></td>
                                    <td><input type="text" class="form-control particularsOfServicesUncheck_{{j}}" formControlName="particularsOfServicesUncheck_{{j}}"> </td>
                                    <td><input type="text" class="form-control amountUncheck_{{j}}" formControlName="amountUncheck_{{j}}"> </td>
                                </tr>
                            </tbody>
                            </table>
                          </div>  
                        </form>    
                    </div>
                    <div class="box-footer">
                        <!-- <button class="btn btn-primary pull-left"> Back </button> -->
                        
                        <input type="button" class="btn btn-primary pull-right" (click)="scheduleofFeesSubmit()" value="Continue">
                        <!-- <button type="submit" class="btn btn-primary pull-right" (Click)="scheduleofFeesSubmit()">Submit</button> -->
                    </div>
                </div>
                <!-- </form> -->
            </div>  
          </div>
        </div>
        
        <div class="box-body" *ngIf="viewMode2" id="finalcontent">
        <div class="box">
            <div class="box-header with-border">
                <h3 class="box-title card-text">{{serviceMsg}}</h3>
                <div class="box-tools pull-right">
                <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                </div>
            </div> 
            <div class="box-body termsbox">
                <div class="table-responsive">          
                    <table class="table">
                    <thead>
                        <tr>
                        <!-- <th></th> -->
                        <th>Category </th>
                        <th>PARTICULARS OF SERVICES <br> (£)</th>
                        <th>Rate <small>(£)</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of this.finalArray let p=index">
                           <!--  <td><input type="checkbox" (click)="finalAgreedID(data.id)" [disabled]="!stc"></td> -->
                            <td >{{data.tribunals}}</td>
                            <td >{{data.particularsOfServices}}</td>
                            <td >{{data.amount}}/-</td>
                        </tr>     
                    </tbody>
                    </table>
                </div>
            </div>

        </div>
        </div>
        
        <div class="box-body" *ngIf="viewMode2">
          <div class="box">
            <div class="box-footer">
                <button class="btn btn-primary pull-left" (click)="backofViewMode2()"> Back </button>
                <button type="submit" class="btn btn-primary stcbtn" (click)="finalSubmit()" [disabled]="stc">Send To Client</button>
                
                <!-- <button type="button" class="btn btn-primary pull-right" (click)="finalAgreed()" [disabled]="!stc">Agreed By Client</button> -->
            </div>
          </div>
        </div>
        <!-- <div class="box-body" *ngIf="showMsg">
            <div class="box">
              <div class="box-body">
                <div>
                  <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="message!=''">&nbsp; {{message}}</span>
                  <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
                </div>
              </div>
            </div>
            
          </div> -->
        
      </div>
    </div>
</div>