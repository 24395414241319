import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskSchema } from '../models/task.model';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addtask',
  templateUrl: './addtask.component.html',
  styleUrls: ['./addtask.component.css']
})
export class AddtaskComponent implements OnInit {

  dataArr: TaskSchema;
  myform: FormGroup;
  isSubmitted = false;
  modalDisplay: boolean = false;
  fareaDisplay: boolean = false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  btnClciked: any;
  isDisabled: boolean = false;
  replyCheck: boolean = false
  userdataArr: any[] = [];
  userlist: any[] = [];
  mode: string;
  clientType: string;
  userId: string;
  usertype : string;
  superUserId : string;
  superUserType : string;
  viewMode: boolean;
  caseSelectArr: any[] = ['With case number', 'Without case number'];
  caseNoDiv: boolean;
  taskId: number;
  courtCaseNo:string;
  caseNo : string;
  taskpermission: string;
  // tasktype : string = 'INSTRUCTION'
  taskTrail : any[] = [];
  caselist : any[] = [];
  constructor(private legalbellService: LegalbellService, private router: Router, private toastr: ToastrService, private route: ActivatedRoute, public fb: FormBuilder, private location: Location) {

  }
  createForm() {
    this.myform = this.fb.group({
      //functionalAreaName: ['',Validators.required],
      //mainUtterance: new FormControl('',Validators.pattern('^[a-zA-Z0-9 ]+$')),
      id: new FormControl(this.taskId ? this.taskId : null),

      taskBrief: new FormControl(this.dataArr && this.dataArr.taskBrief ? this.dataArr.taskBrief : null, [Validators.required]),
      startDateStr: new FormControl(this.dataArr && this.dataArr.startDateStr ? this.dataArr.startDateStr : null, [Validators.required]),
      endDateStr: new FormControl(this.dataArr && this.dataArr.endDateStr ? this.dataArr.endDateStr : null, [Validators.required]),
      taskPriority: new FormControl(this.dataArr && this.dataArr.taskPriority ? this.dataArr.taskPriority : null, [Validators.required]),
      taskType: new FormControl(this.dataArr && this.dataArr.taskType ? this.dataArr.taskType : null,[Validators.required]),
      taskStatus: new FormControl(this.dataArr && this.dataArr.taskStatus ? this.dataArr.taskStatus : null, [Validators.required]),
      ifCaseSpecificTask: new FormControl(this.dataArr && this.dataArr.ifCaseSpecificTask ? this.dataArr.ifCaseSpecificTask : 'Without case number'),
      ifStarred: new FormControl(this.dataArr && this.dataArr.ifStarred ? this.dataArr.ifStarred : 'N'),
      ifImportant: new FormControl(this.dataArr && this.dataArr.ifImportant ? this.dataArr.ifImportant : 'N'),
      // createdBy: new FormControl(userEmail),
      // userEmail: new FormControl(userEmail),
      //userId: new FormControl(this.userId ? this.userId : null),
      //caseNo: new FormControl(this.dataArr && this.dataArr.caseNo ? this.dataArr.caseNo : this.caseNo),
      caseNo: new FormControl(this.dataArr && this.dataArr.caseNo ? this.dataArr.caseNo : this.caseNo),
      reminder: new FormControl(this.dataArr && this.dataArr.reminder ? this.dataArr.reminder : null),
      causeTitle: new FormControl(this.dataArr && this.dataArr.causeTitle ? this.dataArr.causeTitle : null),
      toUserIds: new FormControl(this.dataArr && this.dataArr.toUserIds ? this.dataArr.toUserIds : null, [Validators.required]),
      //toUserIds: [''],
      createdBy: new FormControl(this.dataArr && this.dataArr.createdBy ? this.dataArr.createdBy : this.userId),
      updatedBy: new FormControl(this.userId),
      relatedTaskId: new FormControl(this.dataArr && this.dataArr.relatedTaskId ? this.dataArr.relatedTaskId : this.taskId),
      replyMsg: new FormControl()
    })
  }
  ngOnInit() {
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.userId = userData.userIdlogin;
    console.log("userId in localstorage::",this.userId); 
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    this.taskpermission = localStorage.getItem('taskmanagementpermission')?localStorage.getItem('taskmanagementpermission'): null;
    console.log("servicemasterpermission:::",this.taskpermission);
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    //Get function area on page loading
    this.route.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.taskId = Number(params.get('id'));
      }
      if (params.get('mode') != null) {
        this.mode = params.get('mode');
      }

      console.log("taskId in oninit::", this.taskId, 'mode:', this.mode);
      //this.myform.get('userEmail').setValue(this.userEmail);
      if (this.taskId && this.mode === 'edit') {
        this.getTaskData(this.taskId);
      } else if (this.taskId && this.mode === 'view') {
        this.viewMode = true;
        this.getTaskData(this.taskId);
        console.log("viewMode:", this.viewMode);
      } else {
        this.createForm();
      }
    });
    this.ngForm();
    //this.getUserList();
    this.superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;

    if(this.usertype=='advocate'||this.usertype=='counsel'||this.usertype=='directorlegal'||this.usertype=='managingpartner'
    ||this.usertype=='zonalmanager'||this.usertype=='seniorpartner'||this.usertype=='associatepartner'){
        this.getUserList(this.usertype,this.userId);
 }else{
   this.getUserList(this.superUserType,this.superUserId);
 }
    this.getAllCaseList();

    //this.getProfileData();
  }
  // convenience getter for easy access to form fields
  get f() { return this.myform.controls; }


  //get Task data
  getTaskData(id: number) {
    console.log("id in getTaskData--" + id);
    // const startDateStr = <FormControl>this.myform.get('startDateStr');
    this.legalbellService.getTaskData(id).
      subscribe(
        data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          this.dataArr = JSON.parse(this.legalbellService.userData._body).respData;

          if (this.dataArr.ifCaseSpecificTask == 'Y') {
            this.dataArr.ifCaseSpecificTask = 'With case number'
            console.log("CASE NO :::>>> "+this.dataArr.caseNo);
            this.getCaseUserList(this.dataArr.caseNo);
          } else {
            this.dataArr.ifCaseSpecificTask = 'Without case number'
          }
          
          this.caseNoDiv = (this.dataArr.ifCaseSpecificTask == 'With case number') ? true : false;
          console.log("startdate in taskdata res--" + this.dataArr.startDate);

          if (res.status === 200 && res.searchCount === 1) {
            this.createForm();
            this.myform.controls['startDateStr'].setValue(new Date(this.dataArr.startDate));
            this.myform.controls['endDateStr'].setValue(new Date(this.dataArr.endDate));
          } else {
            this.createForm();
          }
          //console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
  }

  getUserList(type, id) {
    this.legalbellService.getTeamList(type,id).
      subscribe(
        data => {
          this.userlist = JSON.parse(this.legalbellService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
        }
      );
  }

  getCaseUserList(caseNo) {
    this.legalbellService.getTaskCaseTeamList(caseNo).
      subscribe(
        data => {
          this.userlist = JSON.parse(this.legalbellService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
        }
      );
  }

  saveTaskData() {

    this.resultShow = 'Executing function...';
    const ifCaseSpecificTask = <FormControl>this.myform.get('ifCaseSpecificTask');
    console.log('ifCaseSpecificTask : ', ifCaseSpecificTask.value)
    const startDate = <FormControl>this.myform.get('startDateStr');
    console.log('startDateStr value: ', this.getFormattedDate(startDate.value));
    this.myform.controls['startDateStr'].setValue(this.getFormattedDate(startDate.value));
    this.myform.controls['endDateStr'].setValue(this.getFormattedDate(<FormControl>this.myform.get('endDateStr').value));
    if (ifCaseSpecificTask.value == 'With case number') {
      this.myform.controls['ifCaseSpecificTask'].setValue('Y');
    } else {
      this.myform.controls['ifCaseSpecificTask'].setValue('N');
    }
    
    this.legalbellService.saveTaskData(JSON.stringify(this.myform.value)).
      subscribe(
        (data) => {
          this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
          // console.log("saveArr comp res--" + this.saveArr);
          console.log("statusCode comp res--" + this.mydata + 'btn clk--' + this.btnClciked);
          //this.logResult = 'The questions and answer for the Intent PrinterEdit are saved in to the database successfuly.The same has been mailed to the adminstrator for modification in skills.';
          if (this.mydata.status == '200') {
            this.resultShow = 'Execution Result : ';
            this.btnShow = '(Details)';
            this.statusCode = "Success";
            this.toastr.success('Task is saved successfully', '');

            if (this.userId == null) {
              //this.userId = this.mydata.respData.userId;
              console.log("user id after save client", this.userId);
            }
            //console.log("task id after save::",this.mydata.respData.id);
            this.getTaskData(this.mydata.respData.id);
            setTimeout( () => {
              this.router.navigate(['/tasklist']);
            }, 1000 );

          }
          else {
            this.statusCode = "Fail";
            this.toastr.error('Task can not be saved', '');
          }
        }, (err: any) => {
          console.log(err);
          this.toastr.error('Task can not be saved', '');
        }, () => {
          // this.modalDisplay = !this.modalDisplay;
        }
      );
    this.modalDisplay = false;

  }
  ngForm() {
    new FormGroup({

    })
  }

  //Show output
  resultBtnShow: boolean = false;
  showOutput() {
    this.resultBtnShow = !this.resultBtnShow;
  }

  ngSubmit() {
    console.log("form value", this.myform.value);
  }

  saveConfirmation() {
    console.log('abc', this.myform);
    this.isSubmitted = true;
    this.markFormGroupTouched(this.myform);
    if(this.myform.get('taskType').value == 'REPLY' && this.myform.get('replyMsg').value == null){
        console.log("reply msg block");
        //this.toastr.error('Task can not be saved, please enter a message', '');
        //alert("Please put the reply message.");
      
    }
    else if (!this.myform.valid) {
      // alert("Please put the required details");
      // this.myform.get('phrasalArr')['controls'].forEach(element => {
      //   element.get('value').markAsTouched()
      // });
    } else {
      this.modalDisplay = true;
      console.log("modalDisplay::", this.modalDisplay);
    }
  }


  abc() {
    console.log("my task form::",this.myform);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  // Choose to id using select dropdown
  changeTo(e) {
    console.log("to id::", e.target.value);
    this.myform.controls['toUserIds'].setValue(e.target.value, {
      onlySelf: true
    })

  }

  ngChangeCaseSpecificTask() {
    // console.log("to ngChangeCaseSpecificTask::",e.target.value);
    const ifCaseSpecificTask = <FormControl>this.myform.get('ifCaseSpecificTask');
    console.log('ifCaseSpecificTask : ', ifCaseSpecificTask.value)
    this.caseNoDiv = (ifCaseSpecificTask.value == 'With case number') ? true : false;
    if(this.usertype=='advocate'||this.usertype=='counsel'||this.usertype=='directorlegal'||this.usertype=='managingpartner'
    ||this.usertype=='zonalmanager'||this.usertype=='seniorpartner'||this.usertype=='associatepartner'){
        this.getUserList(this.usertype,this.userId);
 }else{
   this.getUserList(this.superUserType,this.superUserId);
 }
  }

  getFormattedDate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;

  }

  getTrailMsg() {
    console.log('called=================', this.taskId);
    this.legalbellService.getTaskTrail(this.taskId).
      subscribe(
        data => {
          this.taskTrail = JSON.parse(this.legalbellService.userData._body).respData;
          console.log("getTaskTrail res--" + JSON.stringify(this.taskTrail));
        }
      );
  }

  modalclose(){
    //this.viewMode = false;
    this.taskTrail = [];
    //console.log("view mode::",this.viewMode);
  }
//this method is used to get all the cases
  getAllCaseList() {
    this.legalbellService.getAllCaseList(this.userId).
      subscribe(
        data => {
          this.caselist=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "caselist comp res--"+JSON.stringify( this.caselist ) );
        }
      );
  }
//this method is used to get all the information depend on case
  onSelectCase(causeTitle) { 
    console.log("onSelectCase--",causeTitle);
     let caseNewList : any[] = [];
     caseNewList = this.caselist.filter((item) => item.caseTitle == causeTitle);
     this.caseNo = caseNewList[0].caseNo;
     this.courtCaseNo = caseNewList[0].courtCaseNo
    //  console.log('onSelectCase ====== ',this.caseNo);
     this.myform.controls['caseNo'].setValue(this.caseNo);
     this.getCaseUserList(this.caseNo)
  }
  selecTtaskType(taskType){
    console.log("selecTtaskType--",taskType);
    if(taskType=='REPLY'||taskType=='QUERY'){
     this.replyCheck = true;
    }
  }
  
}
