import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-contractclientexpenses',
  templateUrl: './contractclientexpenses.component.html',
  styleUrls: ['./contractclientexpenses.component.css']
})
export class ContractClientexpensesComponent implements OnInit {

  myform: FormGroup;
  modalDisplay: boolean = false;
  fareaDisplay: boolean = false;
  contractNoShow : string;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  resultBtnShow: boolean = false;
  contractlist : any[] = [];
  contractNo : string;
  clientUserId : string;
  clientName : string;
  particulars : string;
  particularsId : string;
  amount : number;
  expenselistcasespecific: FormArray;
  loginUserId : string;
  showMsg: boolean = false;
  mes = '';
  mes1 = '';
  contractValid: boolean = false;
  userId : string;
  caseList:any[]=[];
  clientList:any[]=[];
  superUserId : string;
  loader : boolean;



  constructor(private legalbellService: LegalbellService,private router: Router,private route: ActivatedRoute,public fb: FormBuilder,private toastr:ToastrService) {
   
    this.myform = this.fb.group({
      expensesArr: new FormArray([])
    })}
   
  ngOnInit(): void {
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.userId = userData.userIdlogin;
    //this.clientExpenseMaster();
    this.getAllClientsOfSuperUser();
  }
  getAllClientsOfSuperUser(){
    this.legalbellService.getUserList(this.superUserId).subscribe((res: any) => {
      this.clientList = JSON.parse(this.legalbellService.userData._body).respData;
      //this.clientData=this.mes;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
 
    });
  }
  //this method is used fetch all the saved client master expenses first time,before chossing any case
  clientExpenseMaster() {
    
    let requestdata = {};

    this.legalbellService.clientExpenseMaster(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('clientExpenseMaster response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);

        const res = data.respData;
        console.log('res in clientExpenseMaster', res);

        if (res.length > 0) {
           res.map((chrd) => {
            const expenselist = <FormArray>this.myform.get('expensesArr');
            expenselist.push(new FormGroup({
              id:new FormControl(chrd.id),
              orgId : new FormControl("ORG0001"),
              createdBy: new FormControl(this.userId),
              updatedBy: new FormControl(this.userId), 
              particularsId: new FormControl(chrd.particularsId),
              clientUserId: new FormControl(this.clientUserId),
              contractNo : new FormControl(this.contractNo,[Validators.required]),
              amount : new FormControl(0),
              particularName : new FormControl(chrd.particularName),
            }))
            
          });
         
          console.log("my form",this.myform.get('expensesArr'));


        }

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  clientExpenseContractSpecific() {
    this.loader = true;
    let requestdata = {"orgId" :  "ORG0001",
    "clientUserId" : this.clientUserId,
    "contractNo" : this.contractNo}

    this.legalbellService.clientExpenseContractSpecific(JSON.stringify(requestdata)).subscribe( (data) => {
      console.log('clientExpenseContractSpecific response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);

        const res = data.respData;
        console.log('res length in clientExpenseContractSpecific', res.length);
        this.myform = this.fb.group({
          expensesArr: new FormArray([])
       });
        if (res.length > 0) {
  
          res.map((chrd) => {
            
            this.expenselistcasespecific = <FormArray>this.myform.get('expensesArr');
            this.expenselistcasespecific.push(new FormGroup({
              id: new FormControl(chrd.id),
              orgId : new FormControl("ORG0001"),
              createdBy: new FormControl(this.userId),
              updatedBy: new FormControl(this.userId), 
              particularsId: new FormControl(chrd.particularsId),
              clientUserId: new FormControl(this.clientUserId),
              contractNo : new FormControl(this.contractNo),
              amount : new FormControl(chrd.amount),
              particularName : new FormControl(chrd.particularName),
            }))
            
          });
         
          console.log("my form in clientExpenseContractSpecific",this.myform.get('expensesArr'));
          this.loader = false;
        }else{
          this.clientExpenseMaster();
          this.loader = false;
        }

        

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
     
    }
    );
  }

  saveIntentCorfirmation() {
    console.log('this.caseNo', this.contractNo);
    if(typeof this.contractNo=="undefined"){
      this.contractValid = true;
      console.log('abc', this.myform);
    } else {
      this.modalDisplay = true;
      console.log("modalDisplay::", this.modalDisplay);
    }
  }
  saveAllData() {
    
     this.legalbellService.saveClientExpensesContractData(JSON.stringify(this.myform.get('expensesArr').value)).
        subscribe(
          (data) => {
            this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
            if (this.mydata.status == '200') {
              this.showMsg = true;
              this.toastr.success("Expenses Updated Succssfully")
              this.mes = this.mydata.responseMessage;
              this.mes1 = '';
              this.resultShow = 'Execution Result : ';
              this.btnShow = '(Details)';
              this.statusCode = "Success";
            }
            else {
              this.statusCode = "Fail";
            }
            // setTimeout( () => {
            //   location.reload();
            // }, 1000 );
          }, (err: any) => {
            console.log(err);
            this.showMsg = true;
            this.mes1 = this.mydata.responseMessage;
          }, () => {
            // this.modalDisplay = !this.modalDisplay;
          }
        );
        //this.modalDisplay = false;

  }
 //Show output
 showOutput() {
   this.resultBtnShow = !this.resultBtnShow;
 }
  abc() {
    console.log(this.myform);
  }
  onSelectClient(clientUserId){
    this.showMsg=false;
      this.mes1="";
      this.contractNo="";
      this.contractNoShow = "";
      this.contractlist = [];
    if(clientUserId !== '--Select--'){
      this.legalbellService.getContractListUser(clientUserId).subscribe((res: any) => {
        this.contractlist = JSON.parse(this.legalbellService.userData._body).respData;
        console.log( "contractlist comp res--"+JSON.stringify( this.contractlist ) );
      }, (err: any) => {
        
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        
      });
    }
    
  }
  //this method is used to get all the contract,not used now
  getAllContractList() {
    this.loginUserId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
     console.log("loginUserId in client ::profile", this.loginUserId);
    this.legalbellService.getAllContractList(this.loginUserId ).
      subscribe(
        data => {
          this.contractlist=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "contractlist comp res--"+JSON.stringify( this.contractlist ) );
        }
      );
  }
//this method is used to get all the information depend on contract and set case specific form value
  onSelectCase(contractTitle) {
    console.log("onSelectContract--",contractTitle);
     let contractNewList : any[] = [];
     contractNewList = this.contractlist.filter((item) => item.contractTitle == contractTitle);
     this.contractNo = contractNewList[0].contractNo;
     this.contractNoShow = contractNewList[0].contactNumberAsString
     console.log('onSelectCase ====== ',this.contractNo+"amount"+this.amount + "particulars"+this.particulars);
     this.clientName = contractNewList[0].clientUserName;
     this.clientUserId = contractNewList[0].clientUserId;
     console.log("client userID::",this.clientUserId);
     
    
     this.myform.get('expensesArr')['controls'].forEach(element => {
      console.log("expensesArr form control",element);
      element.controls['clientUserId'].setValue(this.clientUserId);
      element.controls['contractNo'].setValue(this.contractNo);
    });

    //this form value is set only when the user select a contract and fetch the data saved for this contract
    this.clientExpenseContractSpecific();
    
  }

  addNewClientExpense(){
    this.expenselistcasespecific = <FormArray>this.myform.get('expensesArr');
    this.expenselistcasespecific.insert(0,new FormGroup({
      orgId : new FormControl("ORG0001"),
      createdBy: new FormControl(this.userId),
      updatedBy: new FormControl(this.userId), 
      particularsId: new FormControl(""),
      clientUserId: new FormControl(this.clientUserId),
      contractNo : new FormControl(this.contractNo),
      amount : new FormControl(0),
      particularName : new FormControl(""),
    }))
  }
}