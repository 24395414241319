<select
  (change)="onChnage($event)"
  class="form-control without-border"
  [(value)]="currentSelection"
  [ngClass]="{'with-border':withBorder}"
>
  <option
    *ngFor="let opt of countryCodeList"
    [value]="opt.dial_code"
    [selected]="isThisDefaultCountry(opt.dial_code)"
  >
    ({{ opt.code }}) {{ opt.dial_code }}
  </option>
</select>
