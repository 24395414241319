
   <!-- <section class="headersection">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
  
          <div class="logobox2">
            <a href="http://legalbell.in/" target="_blank"><img src="assets/images/logo.png" alt=""></a>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <h1>E-LEGXIAM</h1>
  
        </div>
  
      </div>
    </div>
  </section> -->
 <!--  <div class="content-wrapper"> -->
    <!-- <section class="content-header">
    <h2>Payment Details</h2>
   </section>
   <section class="content">
  <div>
    {{respmsg}}
</div>
<button (click)="successPayment()" type="submit" class="btn btn-danger">OK</button>
</section> -->
<!-- </div> -->

<div>
  {{respmsg}}
</div>
