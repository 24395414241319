
<div class="content-wrapper">
  <section class="content">
     <div class="bottom-height">
     <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">date_range</i></div>
        <h3 class="box-title main-header-top">Contract Creation </h3>
     </div>

     <form [formGroup]="contractForm">
        <div class="box-body">
           <div class="box">
              <div class="box-body box-header">
                 <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2">
                       <h3 class="box-title">Client Name: <span class="text-danger">*</span></h3>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                       <select name="selectedQuantity" id="aantal" [attr.disabled]="viewMode ? '' : null || editMode ? '' : null" class="form-control" formControlName="clientUserId"
                           required>
                          <option value=""><strong>Select client name</strong></option>
                          <option *ngFor="let opt of clientName" [value]="opt.userId">
                          <!-- <strong>{{opt.firstName}} {{opt.lastName}}</strong> -->
                          <strong>{{opt.clientShowName}}</strong>
                          </option>
                       </select>
                       <small class="text-danger" *ngIf="contractForm.controls.clientUserId.hasError('required') && (
                          contractForm.controls.clientUserId.dirty || contractForm.controls.clientUserId.touched ||
                          (!contractForm.controls.clientUserId.valid && submittedForm))">This
                       field is required.</small>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
                    </div>
                 </div>
              </div>
              <div class="box-header with-border">
                 <h3 class="box-title">GENERAL INFORMATION </h3>
              </div>
              <div class="box-body">
                 <div class="table-responsive">
                    <table class="table">
                       <tbody>
                          <tr>
                            <!-- <td>
                              <label>Contract No.</label>
                              <input type="text" readonly class="form-control" formControlName ="contractNo" placeholder="Auto generated" > 
                           </td> -->
                           <td><label>Contract No <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" placeholder="Enter contract no..." formControlName="contactNumberAsString"
                                 disabled>
                              
                           </td>
                             <td><label>Contract Title <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter contract title..." formControlName="contractTitle"
                                   [attr.disabled]="viewMode ? '' : null">
                                <small class="text-danger" *ngIf="contractForm.controls.contractTitle.hasError('required') && (
                                   contractForm.controls.contractTitle.dirty || contractForm.controls.contractTitle.touched ||
                                   (!contractForm.controls.contractTitle.valid && submittedForm))">This
                                field is required.</small>
                             </td>
                             <td>
                                <label>Contract Type <span class="text-danger">*</span></label>
                                
                                <select  id="aantal" [attr.disabled]="viewMode ? '' : null" class="form-control" formControlName="contractTypeId"
                                            [attr.disabled]="viewMode ? '' : null">
                                            <option value=""><strong>Select Contract Type</strong></option>
                                            <option *ngFor="let contractType of contractTypelist" [value]="contractType.id">
                                            <strong>{{contractType.typeName}}</strong>
                                            </option>
                               </select>
                                <small class="text-danger" *ngIf="contractForm.controls.contractTypeId.hasError('required') && (
                                   contractForm.controls.contractTypeId.dirty || contractForm.controls.contractTypeId.touched ||
                                   (!contractForm.controls.contractTypeId.valid && submittedForm))">This
                                field is required.</small>
                             </td>
                          </tr>
                          <tr>
                             <td colspan="2">
                                <label>Contract Description <span class="text-danger">*</span> </label>
                                <textarea class="form-control" formControlName="contractDesc" rows="" placeholder="Enter contract Description..."></textarea>
                                <small class="text-danger" *ngIf="contractForm.controls.contractDesc.hasError('required') && (
                                   contractForm.controls.contractDesc.dirty || contractForm.controls.contractDesc.touched ||
                                   (!contractForm.controls.contractDesc.valid && submittedForm))">This
                                field is required.</small>
                             </td>
                          </tr>
                       </tbody>
                    </table>
                 </div>
              </div>

               

              <div class="box-footer" *ngIf="createMode === false">
                  <!-- <span class="pull-left" style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
                  <span class="pull-left" style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span> -->

                 <button type="submit" class="btn btn-primary pull-right" data-toggle="modal" (click)="openDialog()" [attr.disabled]="viewMode ? '' : null">Save contract</button>
              </div>

              <div class="box-footer" *ngIf="createMode === true ">

                  <!-- <span class="pull-left" style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
                  <span class="pull-left" style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span> -->

                  <button type="submit" class="btn btn-primary pull-right" data-toggle="modal" (click)="openDialog()" [attr.disabled]="viewMode ? '' : null">Create contract</button>
               </div>


           </div>
           <!-- <div class="box-footer">
              <button type="submit" (click)="contractSubmit()" class="btn btn-primary pull-right">Creat contract</button>
              </div> 
              <div class="box box-warning">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <button type="submit" class="btn btn-primary pull-right" (click)='contractConfirmation()' data-toggle="modal"
                      data-target="#saveIntent" [attr.disabled]="viewMode ? '' : null">Create contract</button>
                  </div>
                </div>
              </div>
              </div>-->
        </div>

     </form>
 
     <div class="box-body " *ngIf="viewMode == true || editMode == true">
        <div class="box">
           <div class="box-body">

    <div *ngIf="loader" class="spinnerbox">  
      <div class="spinner"></div>
    </div>

              <!--<div class="nav-tabs-custom">
                 <!-- <ul class="nav nav-tabs">
                    <li class="active"><a href="#tab_1" data-toggle="tab">Contract Details</a></li>
                    <li class="contract_folder_li"><a href="#tab_2" data-toggle="tab">Contract Folder</a></li>
                    <li class="assign_team_li"><a href="#tab_3" data-toggle="tab" (click)="assignTeamEditList()">Assign Team </a></li>
                     <li class="parties_li"><a href="#tab_4" data-toggle="tab">Parties </a></li>
                    <li class="instruction_chatbox_li"><a href="#tab_5" data-toggle="tab" (click)="insChatBoxList()">Instruction Chatbox </a></li> 
                 </ul> -->
                 <!--<div class="tab-content">
                    <div class="tab-pane active" id="tab_1"> -->
                     <mat-vertical-stepper  #stepper>
                        <mat-step [stepControl]="contractDetailForm">
                           <ng-template matStepLabel>Contract Details</ng-template>
                       <form [formGroup]="contractDetailForm">
                          <div>
                             <!-- <div class="box-header">
                                <h3 class="box-title"> Contract Details</h3>
                             </div> -->
                             <div class="table-responsive">
                                <table class="table">
                                   <tbody>    
                                    <tr>
                                          <td style="font-weight: 800; font-size: 13px; color: #000000;" width="150">Stage of Contract:</td>
                                          <td>
                                             <select class="form-control" formControlName="stageId">
                                                <option value="0">--Select--</option>
                                                <option *ngFor="let stage of stages " [value]="stage.id">{{stage.stageName}}</option>
                                             </select>
                                          </td>
                                    </tr>
                                    
                                      
                                      <tr>
                                         <td style="font-weight: 800; font-size: 13px; color: #000000;" width="150">Date of Execution</td>
                                         <td>
                                            <div class="input-group date">
                                               <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                                               </div>
                                               <input [owlDateTimeTrigger]="dt" [owlDateTime]="dt" type="text"
                                               class="form-control pull-right" id="datepicker" placeholder="00/00/0000"
                                               formControlName="dateOfExecutionStr" (keyup.enter)="OnInput($event)"
                                               (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                            </div>
                                            <owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
                                            <!-- <small class="text-danger" *ngIf="contractForm.controls.nextDate.hasError('required') && (
                                               contractForm.controls.nextDate.dirty || contractForm.controls.nextDate.touched ||
                                               (!contractForm.controls.nextDate.valid && submittedForm))">This
                                                   field is required.</small> -->
                                         </td>
                                      </tr>
                                      <tr>
                                       <td style="font-weight: 800; font-size: 13px; color: #000000;" width="150">Date of Expiry</td>
                                       <td>
                                          <div class="input-group date">
                                             <div class="input-group-addon" [owlDateTimeTrigger]="dt1"> <i class="fa fa-calendar"></i>
                                             </div>
                                             <input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" type="text"
                                             class="form-control pull-right" id="datepicker" placeholder="00/00/0000"
                                             formControlName="expiaryDateStr" (keyup.enter)="OnInput($event)"
                                             (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                          </div>
                                          <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
                                          <!-- <small class="text-danger" *ngIf="contractForm.controls.nextDate.hasError('required') && (
                                             contractForm.controls.nextDate.dirty || contractForm.controls.nextDate.touched ||
                                             (!contractForm.controls.nextDate.valid && submittedForm))">This
                                                 field is required.</small> -->
                                       </td>
                                    </tr>
                                      <tr>
                                         <td style="font-weight: 800; font-size: 13px; color: #000000;" width="150">Time Period:</td>
                                         <td>
                                            <input class="form-control" rows="" placeholder="Enter time period..."
                                               formControlName="timePeriod" [attr.disabled]="viewMode ? '' : null">
                                            <!-- <small class="text-danger" *ngIf="contractForm.controls.statussOfcontract.hasError('required') && (
                                               contractForm.controls.statussOfcontract.dirty || contractForm.controls.statussOfcontract.touched ||
                                               (!contractForm.controls.statussOfcontract.valid && submittedForm))">This
                                                   field is required.</small> -->
                                         </td>
                                      </tr>
                                    
                                     <!-- <tr *ngIf="stageId">
                                        <td style="font-weight: 800; font-size: 13px; color: #000000;" width="150">Termsheet Upload:</td>
                                       <td>
                                          <div id="drop-zone" class="btn btn-default">
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                               <input type="file" name="file" id="file"
                                                   (change)="uploadContractInfo($event.target.files,'termsheet')" />
                                             </div>
                                           </div>
                                           <div *ngIf="termUploadDocname!=null">
                                             <div class="list-group">
                                                <div class="row">
                                                <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{termUploadPath}}"
                                                                           target="_blank">{{termUploadDocname}} </a> </div>
                                                <div class="col-md-2">
                                                   <div id="drop-zone" class="btn btn-default" >
                                                      <div id="clickHere"><i class="fa fa-trash"></i>
                                                      <input type="buton" name="delImg" id="file"
                                                         (click)="removeContractInfo(userId, contractNo,'', 'termsheet',termUploadDocname)" />
                                                      </div>
                                                   </div>
                                                </div>
                                                </div>
                                             </div>
                                           </div>
                                       </td>
                                 </tr> -->
                                 
                                   </tbody>
                                </table>
                             </div>
                          </div>
                          
                          <div class="box-footer" style="text-align: center;">
                              <!-- <span class="pull-left" style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes2!=''">&nbsp; {{mes2}}</span>
                              <span class="pull-left" style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes3!=''">&nbsp; {{mes3}}</span> -->
                              <button type="submit" class="btn btn-primary pull-right" *ngIf="contractNo" (click)="contractDetailSubmit()" [attr.disabled]="viewMode ? '' : null">Save Contract Details</button>
                              
                                
                                 <button  class="btn btn-primary" style="margin-top: 3%;"   matStepperNext>Next</button>
                                 
                              
                          </div>
                       </form>
                     </mat-step>
                     
                     <mat-step [stepControl]="contractDetailForm">
                        <ng-template matStepLabel>Contract Folder</ng-template>
                       <div class="treefolder">
                          <div class="table-responsive">


                           <ul id="myUL">
                              <div class="">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                  <li> <span class="caret">Financial</span>
                                    <ul class="nested active">
                                     
                                      <li> <span class="caret">Paid Invoices</span>
                                        <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[0].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                          <div id="clickHere"><i class="fa fa-upload"></i>
                                            <input type="file" name="file" id="file"
                                                                      (change)="handleFileInput($event.target.files,'contractPaidInvoices')" />
                                          </div>
                                        </div>
                                        <!-- <div *ngIf="(((contractFolderPermission[0].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div class="list-group" *ngFor="let opt of contractDocList['contractpaidinvoices']">
                                             <div class="row">
                                             <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                        target="_blank">{{opt.docName}} </a> </div>
                                             <div class="col-md-2" *ngIf="!viewMode">
                                                <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[0].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                   <div id="drop-zone" class="btn btn-default" >
                                                   <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                   <input type="buton" name="delImg" id="file"
                                                                                 (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                                   </div>
                                                </div>
                                             </div>
                                             </div>
                                          </div> 
                                        <!-- </div> -->
                                      </li>

                                      <li> <span class="caret">Pending Invoices</span>
                                        <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[0].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                          <div id="clickHere"><i class="fa fa-upload"></i>
                                            <input type="file" name="file" id="file"
                                                                      (change)="handleFileInput($event.target.files,'contractPendingInvoices')" />
                                          </div>
                                        </div>
                                        <!-- {{contractDocList|json}} -->
                                        <!-- <div *ngIf="(((contractFolderPermission[0].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div class="list-group" *ngFor="let opt of contractDocList['contractpendinginvoices']">
                                             <div class="row">
                                             <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}" target="_blank">{{opt.docName}} </a> </div>
                                             <div class="col-md-2">
                                                <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[0].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                   <div id="drop-zone" class="btn btn-default" >
                                                   <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                   <input type="buton" name="delImg" id="file" (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                                   </div>
                                                </div>
                                             </div>

                                             </div>

                                          <!-- </div> -->
                                       </div>

                                      </li>

                                    </ul>
                                  </li>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                 <li> <span class="caret">Contract Documents</span>
                                    <ul class="nested active">
                                       <li> <span class="caret">Termsheet</span>
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                               <input type="file" name="file" id="file"
                                                   (change)="uploadContractInfo($event.target.files,'termsheet')" />
                                             </div>
                                           </div>
                                           <div *ngIf="termUploadDocname!=null">
                                             <div class="list-group">
                                                <div class="row">
                                                <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{termUploadPath}}"
                                                                           target="_blank">{{termUploadDocname}} </a> </div>
                                                <div class="col-md-2">
                                                   <div id="drop-zone" class="btn btn-default" >
                                                      <div id="clickHere"><i class="fa fa-trash"></i>
                                                      <input type="buton" name="delImg" id="file"
                                                         (click)="removeContractInfo(userId, contractNo,'', 'termsheet',termUploadDocname)" />
                                                      </div>
                                                   </div>
                                                </div>
                                                </div>
                                             </div>
                                           </div>
                                        </li>
                                      <li> <span class="caret">Contract Draft</span>
                                        <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                          <div id="clickHere"><i class="fa fa-upload"></i>
                                            <input type="file" name="file" id="file"
                                                                        (change)="handleFileInput($event.target.files,'contractDraft')" />
                                          </div>
                                        </div>
                                        <!-- {{contractDocList|json}} -->
                                        <!-- <div *ngIf="(((contractFolderPermission[1].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                        <div class="list-group" *ngFor="let opt of contractDocList['contractdraft']">
                                          <div class="row">
                                            <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                        target="_blank">{{opt.docName}} </a> </div>
                                            <div class="col-md-2">
                                              <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                <div id="drop-zone" class="btn btn-default" >
                                                <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                  <input type="buton" name="delImg" id="file"
                                                                                 (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        <!-- </div> -->
                                        </div>
                                      </li>
                                      <li> <span class="caret">Contract Review</span>
                                        <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                          <div id="clickHere"><i class="fa fa-upload"></i>
                                            <input type="file" name="file" id="file"
                                                                        (change)="handleFileInput($event.target.files,'contractReview')" />
                                          </div>
                                        </div>
                                        <!-- <div *ngIf="(((contractFolderPermission[1].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div class="list-group"
                                                                     *ngFor="let opt of contractDocList['contractreview']">
                                             <div class="row">
                                             <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                           target="_blank">{{opt.docName}} </a> </div>
                                             <div class="col-md-2">
                                                <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                   <div id="drop-zone" class="btn btn-default" >
                                                   <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                   <input type="buton" name="delImg" id="file"
                                                                                    (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                                   </div>
                                                </div>
                                             </div>
                                             </div>
                                          <!-- </div> -->
                                        </div>
                                      </li>
                                      <li> <span class="caret">Contract Final</span>
                                       <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                         <div id="clickHere"><i class="fa fa-upload"></i>
                                           <input type="file" name="file" id="file"
                                                                       (change)="handleFileInput($event.target.files,'contractFinal')" />
                                         </div>
                                       </div>
                                       <!-- <div *ngIf="(((contractFolderPermission[1].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                       <div class="list-group"
                                                                 *ngFor="let opt of contractDocList['contractfinal']">
                                         <div class="row">
                                           <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                       target="_blank">{{opt.docName}} </a> </div>
                                           <div class="col-md-2">
                                             <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                <div id="drop-zone" class="btn btn-default" >
                                               <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                 <input type="buton" name="delImg" id="file"
                                                                                (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                               </div>
                                             </div>
                                           </div>
                                         </div>
                                       <!-- </div> -->
                                       </div>
                                     </li>
                                    
                                      <li> <span class="caret">Research</span>
                                        <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].upload ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                          <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                          <div id="clickHere"><i class="fa fa-upload"></i>
                                            <input type="file" name="file" id="file"
                                                                        (change)="handleFileInput($event.target.files,'contractResearch')" />
                                          </div>
                                        </div>
                                        <!-- <div *ngIf="(((contractFolderPermission[1].view ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                        <div class="list-group"
                                                                  *ngFor="let opt of contractDocList['contractresearch']">
                                          <div class="row">
                                            <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                        target="_blank">{{opt.docName}} </a> </div>
                                            <div class="col-md-2">
                                              <!-- <div id="drop-zone" class="btn btn-default" *ngIf="(((contractFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))"> -->
                                                <div id="drop-zone" class="btn btn-default" >
                                                <div id="clickHere" *ngIf="!viewMode"><i class="fa fa-trash"></i>
                                                  <input type="buton" name="delImg" id="file"
                                                                                 (click)="removeDoc(opt.clientUserId, opt.contractNo, opt.docId, opt.docType,opt.docName)" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        <!-- </div> -->
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </div>
                              </div>
                            </ul>




                             <!-- <ul id="myUL">
                                <li>
                                   <span class="caret">Client Account (Client File)<b> **Click to open</b></span>
                                   <ul class="nested">
                                      <li>
                                         <span class="caret">Financial</span>
                                         <ul class="nested">
                                            <li>
                                               <span class="caret">Fee Agreement</span>
                                               <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                  <div id="clickHere"><i class="fa fa-upload"></i>
                                                     <input type="file" name="file" id="file"
                                                        (change)="handleFileInput($event.target.files,'financialfeeagreement',null)" />
                                                  </div>
                                               </div>
                                               <div class="list-group" *ngFor="let opt of contractDocList['financialfeeagreement']">
                                                  <div class="row">
                                                     <div class="col-md-6">
                                                        <a class="list-group-item" href="{{opt.pathInS3}}"
                                                           target="_blank">{{opt.docName}}
                                                        </a>
                                                     </div>
                                                     <div class="col-md-1">
                                                        <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                           <div id="clickHere"><i class="fa fa-trash"></i>
                                                              <input type="buton" name="delImg" id="file"
                                                                 (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </li>
                                            <li>
                                               <span class="caret">Paid Invoices</span>
                                               <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                  <div id="clickHere"><i class="fa fa-upload"></i>
                                                     <input type="file" name="file" id="file"
                                                        (change)="handleFileInput($event.target.files,'financialpaidinvoices', null)" />
                                                  </div>
                                               </div>
                                               <div class="list-group" *ngFor="let opt of contractDocList['financialpaidinvoices']">
                                                  <div class="row">
                                                     <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                        target="_blank">{{opt.docName}}
                                                        </a>
                                                     </div>
                                                     <div class="col-md-1">
                                                        <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                           <div id="clickHere"><i class="fa fa-trash"></i>
                                                              <input type="buton" name="delImg" id="file"
                                                                 (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </li>
                                            <li>
                                               <span class="caret">Pending Invoices</span>
                                               <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                  <div id="clickHere"><i class="fa fa-upload"></i>
                                                     <input type="file" name="file" id="file"
                                                        (change)="handleFileInput($event.target.files,'financialpendinginvoices', null)" />
                                                  </div>
                                               </div>
                                               <div class="list-group" *ngFor="let opt of contractDocList['financialpendinginvoices']">
                                                  <div class="row">
                                                     <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                        target="_blank">{{opt.docName}}
                                                        </a>
                                                     </div>
                                                     <div class="col-md-1">
                                                        <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                           <div id="clickHere"><i class="fa fa-trash"></i>
                                                              <input type="buton" name="delImg" id="file"
                                                                 (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </li>
                                         </ul>
                                      </li>
                                      <li>
                                         <span class="caret">contract Folder</span>
                                         <ul class="nested">
                                            <li>
                                               <span class="caret">contract Documents</span>
                                               <ul class="nested">
                                                  <li>
                                                     <span class="caret">Documents</span>
                                                     <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                        <div id="clickHere"><i class="fa fa-upload"></i>
                                                           <input type="file" name="file" id="file"
                                                              (change)="handleFileInput($event.target.files,'rostercontractdocuments', null)" />
                                                        </div>
                                                     </div>
                                                     <div class="list-group" *ngFor="let opt of contractDocList['rostercontractdocuments']">
                                                        <div class="row">
                                                           <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                              target="_blank">{{opt.docName}}
                                                              </a>
                                                           </div>
                                                           <div class="col-md-1">
                                                              <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                                 <div id="clickHere"><i class="fa fa-trash"></i>
                                                                    <input type="buton" name="delImg" id="file"
                                                                       (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </li>
                                                  <li>
                                                     <span class="caret">Pleadings</span>
                                                     <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                        <div id="clickHere"><i class="fa fa-upload"></i>
                                                           <input type="file" name="file" id="file"
                                                              (change)="handleFileInput($event.target.files,'rostercontractdocumentspleadings', null)" />
                                                        </div>
                                                     </div>
                                                     <div class="list-group"
                                                        *ngFor="let opt of contractDocList['rostercontractdocumentspleadings']">
                                                        <div class="row">
                                                           <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                              target="_blank">{{opt.docName}}
                                                              </a>
                                                           </div>
                                                           <div class="col-md-1">
                                                              <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                                 <div id="clickHere"><i class="fa fa-trash"></i>
                                                                    <input type="buton" name="delImg" id="file"
                                                                       (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </li>
                                                  <li>
                                                     <span class="caret">Research</span>
                                                     <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                        <div id="clickHere"><i class="fa fa-upload"></i>
                                                           <input type="file" name="file" id="file"
                                                              (change)="handleFileInput($event.target.files,'rostercontractdocumentsresearch', null)" />
                                                        </div>
                                                     </div>
                                                     <div class="list-group"
                                                        *ngFor="let opt of contractDocList['rostercontractdocumentsresearch']">
                                                        <div class="row">
                                                           <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                              target="_blank">{{opt.docName}}
                                                              </a>
                                                           </div>
                                                           <div class="col-md-1">
                                                              <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                                 <div id="clickHere"><i class="fa fa-trash"></i>
                                                                    <input type="buton" name="delImg" id="file"
                                                                       (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </li>
                                               </ul>
                                            </li>
                                            <li>
                                               <span class="caret">contract Details </span>
                                               <div id="drop-zone" class="btn btn-default" *ngIf="!viewMode">
                                                  <div id="clickHere"><i class="fa fa-upload"></i>
                                                     <input type="file" name="file" id="file"
                                                        (change)="handleFileInput($event.target.files,'rostercontractdetails', null)" />
                                                  </div>
                                               </div>
                                               <div class="list-group" *ngFor="let opt of contractDocList['rostercontractdetails']">
                                                  <div class="row">
                                                     <div class="col-md-6"> <a class="list-group-item" href="{{opt.pathInS3}}"
                                                        target="_blank">{{opt.docName}}
                                                        </a>
                                                     </div>
                                                     <div class="col-md-1">
                                                        <div id="drop-zone" class="btn btn-default" *ngIf="editMode">
                                                           <div id="clickHere"><i class="fa fa-trash"></i>
                                                              <input type="buton" name="delImg" id="file"
                                                                 (click)="removeDoc(opt.userId, opt.contractNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </li>
                                         </ul>
                                      </li>
                                   </ul>
                                </li>
                             </ul>
                             <div class="clearfix"></div> -->
                          </div>
                       </div>
                    <!-- </div> -->
                    <div class="box-footer" style="border-top: none;text-align: center;">
                     <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                     <button  class="btn btn-primary" style="margin-right: 18%;"   matStepperNext>Next</button>
                     
                      </div>
                    </mat-step>
                    
                    <!-- <div class="tab-pane" id="tab_3"> -->
                     <mat-step [stepControl]="contractDetailForm">
                        <ng-template matStepLabel>Assign Team</ng-template>
                           <app-contract-role-team-assignment></app-contract-role-team-assignment>
                           <div class="box-footer" style="border-top: none;text-align: center;">
                              <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                              <button  class="btn btn-primary" style="margin-right: 18%;"   matStepperNext>Next</button>
                              
                           </div>
                     </mat-step>
                    <!-- </div> -->
                    <!-- <div class="tab-pane" id="tab_4">
                    <app-partydetails></app-partydetails>
                   </div> -->
                    <!-- <div class="tab-pane" id="tab_4">
                       <form [formGroup]="partyForm">
                          <div class="table-responsive" *ngIf="!viewMode">
                             <table class="table">
                                <thead>
                                   <tr>
                                      <th>Type</th>
                                      <th>Name</th>
                                      <th>Phone</th>
                                      <th>E-mail</th>
                                      <th align="center" width="130">Action</th>
                                   </tr>
                                </thead>
                                <tbody formArrayName="partyDtlVo">
                                   <tr *ngFor="let party of partyDtlVo().controls; let partyIndex=index"
                                   [formGroupName]="partyIndex">
                                   <td>
                                      <select class="form-control" formControlName="partyType">
                                         <option>--Select--</option>
                                         <option>option 1</option>
                                         <option>option 2</option>
                                         <option>option 3</option>
                                         <option>option 4</option>
                                         <option>option 5</option>
                                      </select>
                                   </td>
                                   <td><input type="text" class="form-control" formControlName="partyName" placeholder="Enter Name ..."></td>
                                   <td><input type="tel" class="form-control" formControlName="partyPhoneNo" id="inputEmail3" placeholder="Enter Phone ...">
                                   </td>
                                   <td><input type="email" class="form-control" formControlName="partyEmailId" id="inputEmail3" placeholder="Enter Email ...">
                                   </td>
                                   <td>
                                      <button *ngIf="partyIndex==(partyDtlVo().length-1)" type="button" class="btn btn-success"
                                      (click)="addParty()">
                                      <i class="fa fa-plus" aria-hidden="true"></i>
                                      </button>
                                      <button *ngIf="partyIndex>0" type="button" class="btn btn-danger"
                                      (click)="removeParty(partyIndex)">
                                      <i class="fa fa-minus" aria-hidden="true"></i>
                                      </button>
                                   </td>
                                   </tr>
                                </tbody>
                             </table>
                          </div>
                          <div class="box-footer">
                             <button type="submit" class="btn btn-primary" (click)='partyUpdate()'>Save Partner</button>
                          </div>
                       </form>
                    </div> -->
                    

                    <!--################ Instraction ChatBot Start Here #################-->
                    <mat-step>
                     
                     <ng-template matStepLabel><p style="margin-top: 3.6%;" (click)="insChatBoxList()">Instruction Chatbox</p></ng-template>
                       <div class="row">
                          <div *ngIf="!viewMode" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                             <!-- <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal" data-target="#myInstructionModal" (click)="displayInsFormModal()">Add Instruction</button> -->
                             <button type="button" class="btn btn-primary pull-right Role" (click)="displayInsFormModal()">Add Instruction</button>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                             <div class="">
                                <table class="table" datatable="ng" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                   <tr>
                                      <th>#</th>
                                      <th>Date</th>
                                      <th>From</th>
                                      <th>To</th>
                                      <th>Instruction</th>
                                      <!-- <th>Reply</th> -->
                                   </tr>
                                </thead>
                                <tbody>
                                   <tr *ngFor="let val of conversationList; let i=index">
                                      <td>
                                         {{val.sl}}
                                      </td>
                                      <td>
                                         {{val.msgDateTime | date: 'dd/MM/yyyy'}}
                                      </td>
                                      <td>
                                         {{val.fromUserName}}
                                      </td>
                                      <td>
                                         {{val.toUserName}}
                                      </td>
                                      <td>
                                         {{val.msgContent}}
                                      </td>
                                      
                                   </tr>
                                </tbody>
                                </table>
                             </div>
                          </div>
                       </div>
                       <div class="box-footer" style="border-top: none;text-align: center;">
                        <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                        <!-- <button  class="btn btn-primary" style="margin-right: 18%;"  matStepperNext>Next</button> -->
                        
                     </div>
                     
                    </mat-step>
                    <!--################ Instraction ChatBot Ends Here #################-->
                  </mat-vertical-stepper>
                 <!--</div>-->
              <!--</div> -->
           </div>
        </div>
     </div>





    <!-- <div class="box-body" *ngIf="showMsg">
      <div class="box">

        <div class="box-body">
          
        
         <div>
            <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
            <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
         </div>
        
        
        
        </div>

      </div>
    </div>    -->















     <!-- Modal start -->
     <div class="modal fade in" id="saveIntent" role="dialog" >
        <div class="modal-dialog">
           <!-- Modal content-->
           <div class="modal-content">
              <div class="modal-header">
                 <h4 class="modal-title">Confirm to Save contract</h4>
              </div>
              <div class="modal-body">
                 <p>Are you sure want to save this contract?</p>
              </div>
              <div class="modal-footer">
                 <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="contractSubmit()">Yes</button>
                 <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
              </div>
           </div>
        </div>
     </div>


     <div class="modal fade in" id="saveIntentSave" role="dialog">
        <div class="modal-dialog">
           <!-- Modal content-->
           <div class="modal-content">
              <div class="modal-header">
                 <h4 class="modal-title">Confirm to Save contract</h4>
              </div>
              <div class="modal-body">
                 <p>Are you sure want to save this contract?</p>
              </div>
              <div class="modal-footer">
                 <button type="button" class="btn btn-default" data-dismiss="modal" (click)='contractConfirmation()'>Yes</button>
                 <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
              </div>
           </div>
        </div>
     </div>



     <!-- Modal end -->
     <!-- Modal -->
     <!-- <div id="myInstructionModal" class="modal fade" role="dialog" *ngIf="insFormModal"> -->
      <div id="myInstructionModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
           <!-- Modal content-->
           <form [formGroup]="insChatForm">
              <div class="modal-content">
                 <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">Instruction Chatbox</h4>
                 </div>
                 <div class="modal-body">
                    <div class="row">
                       <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                             <label>To <span class="text-danger">*</span></label>
                             <!-- formControlName="userId" -->
                             <select name="selectedQuantity" id="aantal" class="form-control" formControlName="toUser">
                                <option value=""><strong>SELECT NAME</strong></option>
                                <option *ngFor="let opt of instructionToList let i=index;" [value]="i">
                                <strong>{{opt.firstName}} {{opt.lastName}}</strong>
                                </option>
                             </select>
                             <small class="text-danger" *ngIf="insChatForm.controls.toUser.hasError('required') && (
                                insChatForm.controls.toUser.dirty || insChatForm.controls.toUser.touched ||
                                (!insChatForm.controls.toUser.valid && subInsChatForm))">This
                             field is required.</small>
                          </div>
                       </div>
                       <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                             <label>Type <span class="text-danger">*</span></label>
                             <select name="selectedQuantity" id="aantal" class="form-control" formControlName="msgType">
                                <option value=""><strong>Enter ...</strong></option>
                                <option *ngFor="let opt of insMsgChatType" [value]="opt.msgTypeId">
                                <strong>{{opt.contractType}}</strong>
                                </option>
                             </select>
                             <small class="text-danger" *ngIf="insChatForm.controls.msgType.hasError('required') && (
                                insChatForm.controls.msgType.dirty || insChatForm.controls.msgType.touched ||
                                (!insChatForm.controls.msgType.valid && subInsChatForm))">This
                             field is required.</small>
                          </div>
                       </div>
                       <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="form-group">
                             <label>Message <span class="text-danger">*</span></label>
                             <textarea class="form-control" rows="" placeholder="Enter ..."
                                formControlName="msgContent"></textarea>
                             <small class="text-danger" *ngIf="insChatForm.controls.msgContent.hasError('required') && (
                                insChatForm.controls.msgContent.dirty || insChatForm.controls.msgContent.touched ||
                                (!insChatForm.controls.msgContent.valid && subInsChatForm))">This
                             field is required.</small>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="modal-footer">
                    
                     <button type="submit" class="btn btn-primary pull-right" (click)="postInsChat()">Send </button>
                     <button type="button" class="btn btn-default pull-right" style="margin-right:5px;" data-dismiss="modal">Close</button>
                    

                 </div>
              </div>
           </form>
        </div>
     </div>
     <!-- Modal -->
   </div>
  </section>
</div>