<!-- <div class="content-wrapper">
    <section class="content ">
      <div class="bottom-height">
      <div class="box-header with-border">
        <div class="box-header-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
        <h3 class="box-title">Addresses</h3>
      </div>
  
      <div class="box-body" *ngIf="usertype=='individualclient'||usertype=='corporate'">
        <div class="breadcrumbbox">
            <ol class="breadcrumb">
              <li class="active"><a [routerLink]="['/clientprofile',userId,usertype]"><i class="fa fa-users" aria-hidden="true"></i> Client Profile</a></li>
            <li [ngClass]="userId!='undefined'?'active':'disabled'"><a [routerLink]="['/addresses',userId,usertype]"><i class="fa fa-map-marker" aria-hidden="true"></i> Client Addresses</a></li>
               <li><a routerLink="/clientprofile"><i class="fa fa-users" aria-hidden="true"></i> Client Profile</a></li>
              <li class="active"><a routerLink="/clientaddresses"><i class="fa fa-map-marker" aria-hidden="true"></i> Client Addresses</a></li> 
            </ol>
  
        </div>
      </div>
  
      <div class="box-body" *ngIf="usertype=='advocate'||usertype=='counsel'">
        <div class="breadcrumbbox">
            <ol class="breadcrumb">
              <li class="active"><a [routerLink]="['/lawyerprofile',userId,usertype]"><i class="fa fa-users" aria-hidden="true"></i> Lawyer Profile</a></li>
            <li [ngClass]="userId!='undefined'?'active':'disabled'"><a [routerLink]="['/addresses',userId,usertype]"><i class="fa fa-map-marker" aria-hidden="true"></i> Lawyer Addresses</a></li>
               <li><a routerLink="/clientprofile"><i class="fa fa-users" aria-hidden="true"></i> Client Profile</a></li>
              <li class="active"><a routerLink="/clientaddresses"><i class="fa fa-map-marker" aria-hidden="true"></i> Client Addresses</a></li>
            </ol>
  
        </div>
      </div>
   
        <div class="box-body">
          <div class="box">
            <div class="box-body"> -->
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1" *ngIf="saveMode" >
                    <button type="button" class="btn btn-primary pull-right Role"  data-toggle="modal" data-target="#myModal" (click)="reinit()" >Add Address</button>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>Address Type</th>
                          <th align="center" width="100" >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of dataArrList">
                          <td> <span *ngIf="item.addressLine1 != '' && item.addressLine1 != null ">{{item.addressLine1}},</span><span *ngIf="item.addressLine2 != '' && item.addressLine2 != null ">{{item.addressLine2}},</span><span *ngIf="item.addressLine3 != '' && item.addressLine3 != null ">{{item.addressLine3}},</span><span *ngIf="item.addressCity != '' && item.addressCity != null">{{item.addressCity}},</span><span *ngIf="item.addressCountry != '' && item.addressCountry != null">{{item.addressCountry}},</span><span *ngIf="item.landMark != '' && item.landMark != null">{{item.landMark}},</span><span *ngIf="item.addressPin != '' && item.addressPin != null">{{item.addressPin}}</span> </td>
                          <td> {{item.addressType}} </td>
                          <td>
                            <!-- <button type="button" class="btn btn-success" data-toggle="modal" (click)="getSingleAdress(item.id)" data-target="#myModal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button> -->
                            <button type="button" class="btn btn-success" (click)="getSingleAdress(item.id)"><i class="fa fa-pencil-square-o" aria-hidden="true" *ngIf="saveMode" ></i></button>
                            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteIntent" (click)="deleteAddressId(item.id)" *ngIf="saveMode" ><i class="fa fa-trash" aria-hidden="true"></i></button>
                          </td>
                        </tr>                              
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          <!-- </div>

          <div class="box-footer">
           
            <a [routerLink]="['/lawyerprofile']" routerLinkActive="router-link-active">
              <button class="btn btn-primary pull-left"> Back </button>
            </a> 
          

           <button type="button" class="btn btn-primary pull-right">Save</button>

          </div>

        </div>
      </div>


  </div>
  </section>
</div> -->


<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)='modalclose()'>&times;</button>
        <h4 class="modal-title">{{addModalTxt}}</h4>
      </div>
      <div class="modal-body">
        <form #f="ngForm" [formGroup]="myform" *ngIf="myform" ngNativeValidate>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Address Type <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="addressType" required>
                  <option value="null" selected>Select an option</option>
                  <option *ngFor="let data of userdataArr" [value]="data">{{data}}</option>
                </select>
                <small class="text-danger"
                  *ngIf="myform.get('addressType').invalid && myform.get('addressType').touched">Please enter a type.</small>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Postcode<span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="addressPin" placeholder="Enter ..." (change)="getAddressAPI($event.target.value)" required>
                <!-- <input type="number" class="form-control" pattern='^[1-9][0-9]{5}$' formControlName="addressPin" placeholder="Enter ..." required> -->
                <small class="text-danger"
              *ngIf="myform.get('addressPin').invalid && myform.get('addressPin').touched">Please Postcode.</small>
              </div>
            </div> 
            <div class="form-group">
              <label>Select Address <span class="text-danger">*</span></label>
              <select class="form-control" formControlName="" (change)="onSelectAddress($event.target.value)" required>
                <option value="null" selected>Select an option</option>
                <option *ngFor="let data of addressList" [ngValue]="data.line_1+'/'+data.line_2+'/'+data.line_3+'/'+data.post_town">{{data.line_1}}, {{data.line_2}}, {{data.line_3}}</option>
              </select>
              <!-- <small class="text-danger"
                *ngIf="myform.get('addressType').invalid && myform.get('addressType').touched">Please enter a type.</small> -->
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Address Line 1</label>
                <input type="text" class="form-control" formControlName="addressLine1" placeholder="Enter ...">
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Address Line 2</label>
                <input type="text" class="form-control" formControlName="addressLine2" placeholder="Enter ...">
              </div>
            </div> 
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Address Line 3</label>
                <input type="text" class="form-control" formControlName="addressLine3" placeholder="Enter ...">
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType != 'mhdhd'">
              <div class="form-group">
                 <label>Town/City<span class="text-danger"></span></label>
                 <input type="text" class="form-control" formControlName="addressCity" placeholder="Enter ..."/>
                    <!-- <select (change)="onSelectDistrict($event.target.value, 1)" [(ngModel)]="districtId" class="form-control" formControlName="districtId" required>
                          <option value="null">--Select--</option>
                          <option *ngFor="let district of districtlist" [value] = "district.id">{{district.districtName}}</option>
                    </select> -->
                    <small class="text-danger"
                *ngIf="myform.get('districtId').invalid && myform.get('districtId').touched">Please Enter Town/City Name.</small>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"  *ngIf="loginUserType != 'mhgfkghf'">
              <div class="form-group">
                <label>Country<span class="text-danger"></span></label>
                <input type="text" class="form-control" formControlName="addressCountry" placeholder="United Kingdom"/>
                <!-- <select class="form-control" formControlName="addressCountry" required>
                  <option value="null">Select Country</option>
                  <option *ngFor="let country of countrylist" [value] = "country.name">{{country.name}}</option>
            </select>
            <small class="text-danger"
        *ngIf="myform.get('addressCountry').invalid && myform.get('addressCountry').touched">Please select Country.</small> -->
              </div>
            </div>
            
            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>City</label>
                    <select (change)="onSelectState($event.target.value,0)" class="form-control" formControlName="addressCity">
                      <option value="0">--Select--</option>
                      <option *ngFor="let city of cityList " [value]="city.id">{{city.stateName}}</option>
                  </select>
              </div>
            </div> -->
            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType != 'hfdnd'">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" formControlName="" placeholder="Enter ...">
              </div>
            </div> -->  
              
             
            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Landmark</label>
                <input type="text" class="form-control" formControlName="landMark" placeholder="Enter ...">
              </div>
            </div> -->                 
              <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Town/City</label>
                  <input type="text" class="form-control" formControlName="addressCity" placeholder="Enter ...">
                </div>
              </div>   
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group"> 
                  <label>State</label>
                  <input type="text" class="form-control" formControlName="addressState" placeholder="Enter ...">
                </div>
              </div>                    -->
                                

            </div>
            </form>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary pull-left" [disabled]="!myform.valid" (click)="saveAllData();" >Save Address</button>
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal" #closebutton (click)='modalclose()'>Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade in" id="deleteIntent" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm to Delete</h4>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="deleteAddress('yes')">Yes</button>
        <button type="button" class="btn btn-default" data-dismiss="modal"  (click)="deleteAddress('no')">No</button>
      </div>
    </div>
  </div>
</div>