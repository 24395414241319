<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon"><i class="fa fa-map-signs" aria-hidden="true"></i> </div>
          <h3 class="box-title">Parties</h3>
        </div>
       
        <div class="box-body">
          <div class="box">
            <div class="box-body">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" placeholder="Enter ...">
                          </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="tel" class="form-control" placeholder="Enter ...">
                          </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>E-mail</label>
                            <input type="email" class="form-control" placeholder="Enter ...">
                          </div>
                    </div>                    

                </div>
            </div>
          </div>
          <div class="box">
            <div class="box-body">

                <div class="table-responsive">          
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Address Type</th>
                          <th>Police Station</th>
                          <th>Post Office </th>
                          <th>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td><select class="form-control">
                                <option>--select--</option>
                                <option>Branch Office</option>
                                <option>Residential</option>
                              </select> 
                            </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><input type="text" class="form-control" placeholder="Enter ..."> </td>
                            <td><button type="button" class="btn btn-success">
                                <i aria-hidden="true" class="fa fa-plus"></i>
                              </button>
                           </td>
                        </tr>


                        
                      </tbody>
                    </table>
                </div>


            </div>
            <div class="box-footer">
              <button class="btn btn-primary pull-left"> Back </button>
              <button type="submit" class="btn btn-primary pull-right">Submit</button>
            </div>
          </div>

          
        </div>
      </div>
    </div>
    

    
  </div>
