import { Component, ViewChild,ElementRef, OnInit } from '@angular/core';
import { Router,NavigationStart, NavigationEnd,
  NavigationCancel, NavigationError, Event } from '@angular/router';
  import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
    // the loading indicator
    showLoadingIndicator = true;
    checkrouter : boolean = false;
    footer : boolean = true;
    sidebar : boolean = true;
    currentURL :string = '';
    landing:boolean=false;
    constructor(public router: Router,dateTimeAdapter: DateTimeAdapter<any>){
      dateTimeAdapter.setLocale('en-IN');
    }
    ngOnInit() {
      this.router.events
      .subscribe((event) => {
        
        if (event instanceof NavigationEnd) {
          console.log('****'+event.url);

          this.currentURL = event.url;
          
          if(event.url == '/login'){
          this.footer = false;
          this.sidebar = false; 
          }else{
            this.footer = true;
            this.sidebar = true;
          }
          if(event.url.indexOf('/login')>-1||event.url.indexOf('/setpassword')>-1||event.url.indexOf('/forgotPassword')>-1){
            this.sidebar = false;
            this.footer = false;
          }else{
            this.footer = true;
            this.sidebar = true;
          }
          if(event.url == '/' || event.url == '/home' || (event.url == '/home#about' || event.url == '/home#team' || event.url == '/home#services' || event.url == '/home#pricing' || event.url == '/home#contact')){
            console.log("URL :::::: "+event.url);
            this.landing = true;
            this.sidebar = false;
            this.footer = false;
          }
            
        }
      });
    }
}
