<div class="content-wrapper">
  <div *ngIf="loader" class="spinnerbox">  
    <div class="spinner"></div>
  </div>
  <div class="content">
    <div class="bottom-height">
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-24 md-dark">account_balance</i>
        </div>
        <h3 class="box-title main-header-top">Contract Specific Client Expenses</h3>
      </div>
      <div class="box-body">
        <div class="box">
          <div class="box-body">


            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Select Client</label>
                <select class="form-control" (change)="onSelectClient($event.target.value)">
                  <option [value]="">--Select--</option>
                  <option *ngFor="let data of clientList" [value]="data.userId">{{data.clientShowName}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Contract No</label>
                <input type="text" class="form-control" [(ngModel)]="contractNoShow" placeholder="Enter ..." readonly>
              </div>
              
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <label>Contract Title</label>
                <select class="form-control" (change)="onSelectCase($event.target.value)">
                  <option value="">--Select--</option>
                  <option *ngFor="let contract of contractlist " value={{contract.contractTitle}}>
                    {{contract.contractTitle}}</option>
                </select>
              </div>
              <small class="text-danger" *ngIf="contractValid">Please select contract title
              </small>
            </div>
            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="form-group">
          <label>Client Name</label>
          <input type="text" class="form-control" [(ngModel)]="clientName" placeholder="Enter ..." readonly>
        </div>
      </div> -->
          </div>
        </div>
        <!-- <div class="box-body" *ngIf="showMsg">
          <div class="box">
            <div class="box-body">
              <div>
                <span style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; Client Expenses
                  saved successfully.</span>
                <span style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="box-body" *ngIf="contractNo">

          <div class="box">
            <div class="box-body">
              <form [formGroup]="myform" *ngIf="myform">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th width="50">SL NO </th>
                        <th width="200">PARTICULARS</th>
                        <th width="200">AMOUNT</th>
                        <th *ngIf="contractNo!=null" width="50"><button type="button" class="btn btn-primary"
                            (click)="addNewClientExpense()">Add</button></th>
                      </tr>
                    </thead>
                    <tbody>
                    <tbody formArrayName='expensesArr' *ngIf="myform.get('expensesArr')">

                      <tr *ngFor="let item of myform.get('expensesArr')['controls']; let i=index;">
                        <ng-container [formGroup]="item">
                          <td item-width="100%">
                            {{i+1}}
                          </td>
                          <td item-width="20%">
                            <input type="text" formControlName="particularName">
                          </td>
                          <td item-width="20%">
                            <input type="text" formControlName="amount">
                          </td>

                        </ng-container>
                      </tr>
                      <!-- <tr>
                      <td>#</td>
                      <td><strong>Total</strong></td>
                      <td>
                        <div class="form-control"> </div>
                      </td>
                    </tr> -->
                    </tbody>

                  </table>
                </div>
              </form>
            </div>
            <div class="box-footer">
              <button class="btn btn-primary pull-left"> Back </button>
              <button type="submit" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                data-toggle="modal" data-target="#saveIntent">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Resullt section start -->
  <div class="row" id="resultsection" *ngIf="resultShow!=undefined">
    <div class="col-sm-12">
      <div class="form-group">

        <div class="card-header1" for="staticEmail">
          <b>Result Section :</b>
        </div>
        <div class="card-header2">
          <i class="mdi mdi-check-circle"></i>
          <b> {{resultShow}} {{statusCode}}
            <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
          </b>
        </div>
        <ng-container *ngIf="resultBtnShow">
          <div class="card-body2">
            <h5>Output</h5>
            <p>{{mydata.responseMessage}} </p>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
  <!-- Result section end -->
  <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm to Submit</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure to submit?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="saveAllData()">Yes</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>