import { Component, Input, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { ClientProfileSchema } from '../models/profile.model';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';

const DEFAULT_USER_TYPE = '-1';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
  selectedCountry1:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"
public countryCodeList: Array<any> = countryCodeList;

  dataArr: ClientProfileSchema;
  myform: FormGroup;
  isSubmitted = false;
  modalDisplay: boolean = false;
  fareaDisplay: boolean = false;
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  btnClciked: any;
  isDisabled: boolean = false;
  userdataArr: any[] = [];
  usertype: string;
  clientType: string;
  userId: string;
  superUserId : string;
  viewMode: boolean;
  mode: string;

  todayDate:any = new Date();

  showMsg: boolean = false;
  mes = '';
  mes1 = '';
  loginUserType = JSON.parse(localStorage.getItem('profileInfo')).usertype;
  errorOnImgType: boolean = false;
  addressList:number;
  profilestatus:string;
  uploadFile : boolean = false;
  countryCodeMobilePh: string = environment.defaultCountryCode;
  countryCodeOfficePh: string = environment.defaultCountryCode;

  dob : string;
  updatedDOB : string;

  constructor(private legalbellService: LegalbellService, private router: Router, private route: ActivatedRoute, public fb: FormBuilder, private toastr: ToastrService, private location: Location) {

  }
  createForm() {
     this.superUserId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
     console.log("superUserId in client ::profile", this.superUserId);
    this.myform = this.fb.group({
      emailId: new FormControl(this.dataArr && this.dataArr.emailId ? this.dataArr.emailId : null),
      phoneNo: new FormControl(this.dataArr && this.dataArr.phoneNo ? this.dataArr.phoneNo : null,[Validators.required]),
      userTitle: new FormControl(this.dataArr && this.dataArr.userTitle ? this.dataArr.userTitle : null),
      middleName      : new FormControl( this.dataArr&&this.dataArr.middleName? this.dataArr.middleName:null),
      firstName: new FormControl(this.dataArr && this.dataArr.firstName ? this.dataArr.firstName : null,[Validators.required]),
      lastName: new FormControl(this.dataArr && this.dataArr.lastName ? this.dataArr.lastName : null),
      officePhoneNo   : new FormControl( this.dataArr&&this.dataArr.officePhoneNo? this.dataArr.officePhoneNo:null),
      addressOffice: new FormControl(this.dataArr && this.dataArr.addressOffice ? this.dataArr.addressOffice : null),
      addressResidence: new FormControl(this.dataArr && this.dataArr.addressResidence ? this.dataArr.addressResidence : null),
      // createdBy: new FormControl(userEmail),
      // userEmail: new FormControl(userEmail),
      loginUserId: new FormControl(this.superUserId ? this.superUserId : null),
      userId : new FormControl(this.userId ? this.userId : null),
      userType: new FormControl(this.usertype ? this.usertype : DEFAULT_USER_TYPE),
      profession: new FormControl(this.dataArr && this.dataArr.profession ? this.dataArr.profession : null),
      panNo: new FormControl(this.dataArr && this.dataArr.panNo ? this.dataArr.panNo : null),
      aadharNo: new FormControl(this.dataArr && this.dataArr.aadharNo ? this.dataArr.aadharNo : null),
      panNoSrc : new FormControl( this.dataArr&&this.dataArr.panNoSrc? this.dataArr.panNoSrc:null ),
      aadharNoSrc : new FormControl( this.dataArr&&this.dataArr.aadharNoSrc? this.dataArr.aadharNoSrc:null ),
      linkedinProfileSrc: new FormControl(this.dataArr && this.dataArr.linkedinProfileSrc ? this.dataArr.linkedinProfileSrc : null),
      corporateClientLinkedinProfile: new FormControl(this.dataArr && this.dataArr.corporateClientLinkedinProfile ? this.dataArr.corporateClientLinkedinProfile : null),
      corporateClientDesignation: new FormControl(this.dataArr && this.dataArr.corporateClientDesignation ? this.dataArr.corporateClientDesignation : null),
      corporateClientWebsite: new FormControl(this.dataArr && this.dataArr.corporateClientWebsite ? this.dataArr.corporateClientWebsite : null),
      profilePicSrc : new FormControl( this.dataArr&&this.dataArr.profilePicSrc? this.dataArr.profilePicSrc:null ),
      clientProfession: new FormControl( this.dataArr&&this.dataArr.clientProfession? this.dataArr.clientProfession:null ),
      corporateClientProfilePathInS3: new FormControl( this.dataArr&&this.dataArr.corporateClientProfilePathInS3? this.dataArr.corporateClientProfilePathInS3:null ),
      corporateClientCompanyName: new FormControl( this.dataArr&&this.dataArr.corporateClientCompanyName? this.dataArr.corporateClientCompanyName:null ),
      corporateClientNatureOfBusiness   : new FormControl( this.dataArr&&this.dataArr.corporateClientNatureOfBusiness? this.dataArr.corporateClientNatureOfBusiness:null ),
      corporateEmailId   : new FormControl( this.dataArr&&this.dataArr.corporateEmailId? this.dataArr.corporateEmailId:null ),
      corporateClientCin: new FormControl( this.dataArr&&this.dataArr.corporateClientCin? this.dataArr.corporateClientCin:null ),
      corporateClientPan: new FormControl( this.dataArr&&this.dataArr.corporateClientPan? this.dataArr.corporateClientPan:null ),

      //New Added Fields 
      dob: new FormControl(this.dataArr && this.dataArr.dob ? new Date(this.dataArr.dob) : null),
      websiteIndividual: new FormControl(this.dataArr && this.dataArr.websiteIndividual ? this.dataArr.websiteIndividual : null),
      linkedinIndividual: new FormControl(this.dataArr && this.dataArr.linkedinIndividual ? this.dataArr.linkedinIndividual : null),

    })

    if (this.dataArr && this.dataArr.countryCode) {
      this.countryCodeMobilePh = this.dataArr['countryCode'];
     
      let country 
       country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.countryCode)
       this.country = country[0].name
       this.selectedCountry = CountryISO[this.country.toString()] 
       this.countryCodeMobilePh = this.dataArr.countryCode;
    }
    if(this.dataArr && this.dataArr.officeCountryCode){
      this.countryCodeOfficePh = this.dataArr['officeCountryCode'];
      let country 
      country =  this.countryCodeList.filter(x => x.dial_code == this.dataArr.officeCountryCode)
      //this.country = country[0].name
      this.selectedCountry1 = CountryISO[country[0].name.toString()] 
      this.countryCodeOfficePh = this.dataArr.officeCountryCode;
    }
  }
  ngOnInit() {
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.selectedCountry1 = CountryISO[this.country.toString()] 
    this.profilestatus = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).profilestatus: null;
    //Get function area on page loading
    //this.addressList = localStorage.getItem("adresslistsize")?Number(localStorage.getItem("adresslistsize")):null;
    //console.log("addresslist",this.addressList);
    this.route.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.userId = params.get('id');
        this.mode = 'edit';
        console.log("mode client profile::", this.mode);
      }
      if (params.get('id1') != null) {
        this.usertype = params.get('id1');
      }

      if (params.get('id2') != null) {
        this.mode = params.get('id2');
      }

      

      console.log("userId in route client profile::", this.userId);
      console.log("usertype in route client profile::", this.usertype);
      //this.myform.get('userEmail').setValue(this.userEmail);
      if (this.userId) {
        this.getProfileData(this.userId);
      }
      if (this.mode === 'view') {
        this.viewMode = true;
        console.log("viewMode:", this.viewMode);
      } else {

        console.log(this.dataArr);
        this.createForm();
      }
    });
    this.ngForm();

    //this.getProfileData();
  }
  // convenience getter for easy access to form fields
  get f() { return this.myform.controls; }


  //get profile data
  getProfileData(userid: string) {
    console.log("userid in getProfileData--" + userid);
    this.legalbellService.getProfileData(userid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          this.dataArr = JSON.parse(this.legalbellService.userData._body).respData;
          this.dataArr.dob = this.getFormattedDate(this.dataArr.dob);

          
//
/* var date = new Date(this.dataArr.dob);
this.dataArr.dob = new Date(((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  +  '-' + date.getFullYear());
console.log("Extracted DOB ==>> " + this.dataArr.dob); */

//
          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
            this.createForm();
          } else {
            this.createForm();
          }
          console.log("getProfileData comp res DOB--" + JSON.stringify(this.dataArr.dob));
        }
      );
  }

  saveAllData() {

    this.resultShow = 'Executing function...';
    console.log("usertype in route client profile::", this.usertype);
    console.log(this.myform.value.phoneNo.dialCode)
    let phno
    let officePhNO
    let dob
    let formData = this.myform.value;
    
    formData.countryCode = this.myform.value.phoneNo.dialCode;

    phno = this.myform.value.phoneNo.number.replace(/\s/g, "");
    phno = phno.replace(/-/g, "");
    formData.phoneNo = phno
    if(this.myform.value.officePhoneNo){
    officePhNO = this.myform.value.officePhoneNo.number.replace(/\s/g, "");
    formData.officeCountryCode = this.myform.value.officePhoneNo.dialCode;
    
    officePhNO = officePhNO.replace(/-/g, "");
    formData.officePhoneNo = officePhNO
    /* dob = this.getFormattedDate(formData.dob)
    formData.dob = dob; */

    }
    console.log("From Data")
    console.log(formData)
    this.legalbellService.saveAllData(JSON.stringify(formData)).
      subscribe(
        (data) => {
          this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
          // console.log("saveArr comp res--" + this.saveArr);
          console.log("statusCode comp res--" + this.mydata + 'btn clk--' + this.mydata.status);
          //this.logResult = 'The questions and answer for the Intent PrinterEdit are saved in to the database successfuly.The same has been mailed to the adminstrator for modification in skills.';
          if (this.mydata.status == '200') {
            this.resultShow = 'Execution Result : '; 
            this.btnShow = '(Details)';
            this.statusCode = "Success";
            
            this.toastr.success('Client profile saved successfully', '');
            var existing=localStorage.getItem( 'profileInfo' );

            // If no existing data, create an array
            // Otherwise, convert the localStorage string to an array
            existing=existing? JSON.parse( existing ):{};

            // Add new data to localStorage Array
            existing['profilestatus']='Y';

            // Save back to localStorage
            localStorage.setItem( 'profileInfo', JSON.stringify( existing ) );

            if (this.userId == null) {
              this.userId = this.mydata.respData.userId;
              console.log("user id after save client", this.userId);
              
            }
            this.getProfileData(this.userId);
            setTimeout( () => {
              if(JSON.parse(localStorage.getItem('profileInfo')).usertype != 'individualclient' && JSON.parse(localStorage.getItem('profileInfo')).usertype != 'corporateclient'){
                this.router.navigate(['/userlist']);
              }
              else {
                console.log("before reload==========");
                location.reload();
              }
              
            }, 2000 );
          }
          else {
            this.resultShow = 'Execution Result : '; 
            this.btnShow = '(Details)';
            this.statusCode = "Fail";
            this.toastr.error('Client profile can not be saved', '');
          }
        }, (err: any) => {
            console.log(err);
            this.mydata = JSON.parse(err._body);
            this.resultShow = 'Execution Result : '; 
            this.btnShow = '(Details)';
            this.statusCode = "Fail";
            this.toastr.error('Client profile can not be saved', '');
        }, () => {
          // this.modalDisplay = !this.modalDisplay;
        }
      );
    this.modalDisplay = false;

  }
  ngForm() {
    new FormGroup({

    })
  }

  OnInput(event: any) {
    var dob = this.getFormattedDate(this.myform.value.dob);
    
    // console.log("next date in oninput::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  getFormattedDate(fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;

  }

  onChange(data) {
    // alert( "Triggered"+data );
    console.log("Triggered", data);
    var dob = this.getFormattedDate(this.myform.value.dob);
    
    
    // console.log("next date in onChange::",nextDate);
    //this.caseDetailForm.patchValue({ 'nextDate': nextDate });
  }

  //Show output
  resultBtnShow: boolean = false;
  showOutput() {
    this.resultBtnShow = !this.resultBtnShow;
  }

  ngSubmit() {
    console.log("form value", this.myform.value);
  }

  saveIntentCorfirmation() {
    console.log('abc', this.myform);
    this.isSubmitted = true;
    this.markFormGroupTouched(this.myform);
    if (!this.myform.valid) {
      //alert("Please put the required details");
      // this.myform.get('phrasalArr')['controls'].forEach(element => {
      //   element.get('value').markAsTouched()
      // });
    } else {
      this.modalDisplay = true;
      console.log("modalDisplay::", this.modalDisplay);
    }
  }


  abc() {
    console.log(this.myform);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  isValidTypeOfUser() {
    return this.myform.get('userType').value === 'individualclient' || this.myform.get('userType').value === 'corporateclient';
  }
  
  changeUsertype() {
    this.usertype = this.f.userType.value;
    console.log("usertype after selection:", this.f.userType.value);
  }

  handleFileInput(files: FileList, type) {
    let data = { docType: type, userId: this.userId };
    this.errorOnImgType = false;
    if(files.item(0).type != "image/jpeg"  && files.item(0).type != "image/jpg" && files.item(0).type != "image/gif" && files.item(0).type != "image/png" && files.item(0).type != "application/pdf"){
        this.errorOnImgType = true;
        return false;
    }
    console.log('data', data);
    this.legalbellService.postLawyerFile(files.item(0), data).subscribe((fData) => {
      if (this.userId != null) {
        this.uploadFile = true;
        console.log("upload file flag::",this.uploadFile);
        this.getProfileData(this.userId);
        
      } else {
        this.uploadFile = true;
        console.log("upload file flag::",this.uploadFile);
        let userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
        this.userId = userId;
        this.getProfileData(userId);
      }
      
    }, error => {
      console.log(error);
    });

  }

  removeDoc(docType) {
    let formVal = {
      "userId": this.userId,
      "docType": docType,
    };
    console.log('removeDoc', formVal);
    this.legalbellService.removeDoc(JSON.stringify(formVal)).subscribe((data) => {
      console.log('postCaseData response', data);
      alert( data.responseMessage );
      this.showMsg = true;
      this.mes = data.responseMessage;
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      setTimeout( () => {
        location.reload();
      }, 1000 );
      //this.getProfileData(this.userId);

    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes1 = 'Failed to delete file.';
      this.toastr.error(this.mes1, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }

  onCountryCodeMobilePhChange(selectedCountryCode) {
    this.countryCodeMobilePh = selectedCountryCode;
  }

  onCountryCodeOfficePhChange(selectedCountryCode) {
    this.countryCodeOfficePh = selectedCountryCode;
  }
}
