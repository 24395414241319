import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatStepperModule } from '@angular/material/stepper';

import { Injectable } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FooterComponent } from './components/footer/footer.component';
import { Routes, RouterModule, Router } from '@angular/router';
import { MainsidebarComponent } from './components/mainsidebar/mainsidebar.component';
import { UserListComponent } from './userlist/userlist.component';
import { CaseListComponent } from './caselist/caselist.component';
import {DataTablesModule} from 'angular-datatables';
import { FeedbackComponent } from './feedback/feedback.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { LawyerProfileComponent } from './lawyer-profile/lawyer-profile.component';
import { CasecreationComponent } from './casecreation/casecreation.component';
import { CaseListAllComponent } from './caselistAll/caselistAll.component';
import { TeamcreationComponent } from './teamcreation/teamcreation.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TeamsetupComponent } from './teamsetup/teamsetup.component';
import { AddressesComponent } from './addresses/addresses.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TasklistComponent } from './tasklist/tasklist.component';
import { AddtaskComponent } from './addtask/addtask.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FinancialsComponent } from './financials/financials.component';
import { ClientexpensesComponent } from './clientexpenses/clientexpenses.component';
import { PartydetailsComponent } from './partydetails/partydetails.component';
import { RoleTeamAssignmentComponent } from './role-team-assignment/role-team-assignment.component';
import { ContactRoleTeamAssignmentComponent } from './contract-role-team-assignment/contract-role-team-assignment.component';
import { ServiceComponent } from './feeservice/service.component';
import { ClientcasedetailsComponent } from './clientcasedetails/clientcasedetails.component';
import { FeeagreementComponent } from './feeagreement/feeagreement.component';
import { OverheadexpensesComponent } from './overheadexpenses/overheadexpenses.component';
import { SchedulefeesComponent } from './schedulefees/schedulefees.component';
import { PartiesComponent } from './parties/parties.component';
import { InviteMemberComponent } from './invitemember/invitemember.component';
import { OrganizationProfileComponent } from './organization-profile/organization-profile.component';
import { OrgAddressesComponent } from './orgaddresses/orgaddresses.component';
import { ContractClientexpensesComponent } from './contractclientexpenses/contractclientexpenses.component';
import { ContractcreationComponent } from './contractcreation/contractcreation.component';
import { InvoicegenerationComponent } from './invoicegeneration/invoicegeneration.component';
import { ContractListComponent } from './contractlist/contractlist.component';
import { FeeAgreementListComponent } from './feeagreementlist/feeagreement.component';
import {MatIconModule} from '@angular/material/icon'
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafePipe } from './safe.pipe';
import { ToastrModule } from 'ngx-toastr';
import { PaymentComponent } from './payment/payment.component';
import { CountryCodeDropdownComponent } from './shared/components/country-code-dropdown/country-code-dropdown.component';
import { NotificationComponent } from './notification/notification.component';
import { ContractinvoicegenerationComponent } from './contractinvoicegeneration/contractinvoicegeneration.component';
import { TimeKeeperComponent } from './timeKeeper/timeKeeper.component';
import { InvoiceListComponent } from './invoiceList/invoiceList.component';
import { InvoicePaymentListComponent } from './invoicePaymentList/invoicePaymentList.component';
import { LandingComponent } from './landing/landing.component';
import { LandingLayoutComponent } from './landing-layout/landing-layout.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { InvoiceListContractComponent } from './invoiceListContract/invoiceListContract.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    HeaderComponent,
    FooterComponent,
    MainsidebarComponent,
    UserListComponent,
    FeedbackComponent,
    SafeHtmlPipe,
    FileNamePipe,
    AddressesComponent,
    SetPasswordComponent,
    DashboardComponent,
    ClientProfileComponent,
    LawyerProfileComponent,
    CasecreationComponent,
    CaseListComponent,
    CaseListAllComponent,
    TeamcreationComponent,
    TeamsetupComponent,
    TasklistComponent,
    AddtaskComponent,
    InvoiceComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    FinancialsComponent,
    ClientexpensesComponent,
    PartydetailsComponent,
    RoleTeamAssignmentComponent,
    ContactRoleTeamAssignmentComponent,
    ServiceComponent,
    ClientcasedetailsComponent,
    FeeagreementComponent,
    OverheadexpensesComponent,
    SchedulefeesComponent,
    PartiesComponent,
    InviteMemberComponent,
    OrganizationProfileComponent,
    OrgAddressesComponent,
    ContractClientexpensesComponent,
    ContractcreationComponent,
    InvoicegenerationComponent,
    ContractListComponent,
    SafePipe,
    FeeAgreementListComponent,
    PaymentComponent,
    CountryCodeDropdownComponent,
    NotificationComponent,
    ContractinvoicegenerationComponent,
    TimeKeeperComponent,
    InvoiceListComponent,
    InvoicePaymentListComponent,
    LandingComponent,
    LandingLayoutComponent,
    DefaultLayoutComponent,
    InvoiceListContractComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,HttpModule,
    FormsModule, HttpClientModule, ReactiveFormsModule,DataTablesModule,
    BrowserAnimationsModule,
   	OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    MatStepperModule,
    MatIconModule,
    BsDropdownModule.forRoot(),
		NgxIntlTelInputModule,
    ToastrModule.forRoot({timeOut: 4000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation:"decreasing",
      preventDuplicates: true}),
    MatStepperModule
    // PdfViewerModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

/* const MINUTES_UNITL_AUTO_LOGOUT = 1 // in mins
const CHECK_INTERVAL = 1500 // in ms
const STORE_KEY =  'lastAction';
@Injectable()
export class AutoLogoutService {
 public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
      localStorage.clear();
      this.router.navigate(['./login']);
    }
  }
} */