<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">receipt_long</i> </div>
          <h3 class="box-title main-header-top">Contract Invoice Generation</h3>
        </div>

        <div class="box-body">
          <div class="box">
            <div class="box-header with-border">
                <h5 *ngIf="viewMode2">{{clientName}}</h5>
               
            </div> 
            <div class="box-body" *ngIf="viewMode" > 
                
                <div class="form-group">
                    <label>Select Client</label>
                    <select class="form-control"  (change)="onSelectClient($event.target.value)" >
                    <option [value]="">--Select--</option>
                    <option *ngFor="let data of mes" [value]="data.userId">{{data.clientShowName}}</option> 
                    </select>
                </div>
                <div class="form-group">
                  <label>Select Contract</label>
                  <select class="form-control" (change)="onSelectCase($event.target.value)">
                  <option [value]="">--Select--</option>
                  <option *ngFor="let data of case" [value]="data.contactNumber" >{{data.contractTitle}}</option> 
                  </select>
              </div>
            </div>
            <div class="box-body" *ngIf="viewMode1">
              <form [formGroup]="myform" (ngSubmit)="scheduleofFeesSubmit()" *ngIf="hasCheckedList">
                <div class="box"  class="">
                    <div class="box-body">
                        <div class="table-responsive form-group">  
                            <h4 *ngIf="sfa">Select services for the invoice</h4>        
                            <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th width="300">COURTS/<br>TRIBUNALS </th>
                                    <th width="305">Particulars Of Services( from system )</th>
                                    <th width="305">invoice Particulars</th>
                                    <th width="100">Amount <br> (£)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fees of checkedList let i=index">   
                                    <td><input type="checkbox" (click)="feesId(i)" ></td>
                                    <td><input type="text" class="form-control tribunals_{{i}}" formControlName="tribunals_{{i}}"  readonly></td>
                                    <td><input type="text" class="form-control particularsOfServices_{{i}}" formControlName="particularsOfServices_{{i}}" [readonly]="checkRevise(fees.agreedByClient)"> </td>
                                    <td><input type="text" class="form-control particularNamePrint_{{i}}" formControlName="particularNamePrint_{{i}}"> </td>
                                    <td><input type="text" class="form-control amount_{{i}}" formControlName="amount_{{i}}" > </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>  
                    </div>
                    <div class="box-footer">
                        
                         <button type="submit" class="btn btn-primary pull-left" data-toggle="modal" data-target="#Preview"  [disabled]="!stc" >Preview Invoice</button>
                        
                    </div>
                </div>
                </form>
            </div>  
          </div>
        </div>
        
        
      </div>
    </div>
</div>

<!--Modal: Name-->
<div class="modal fade" id="Preview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body mb-0 p-0">

        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          
          <iframe class="embed-responsive-item" [src]="src" allowfullscreen></iframe>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">

        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="generateFinalInvoice()">Generate final invoice and send to client.</button>

        <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Close</button>

      </div>

    </div>
    <!--/.Content-->

  </div>
</div>


