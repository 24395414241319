<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-18 md-dark">receipt_long</i> </div>
          <h3 class="box-title main-header-top">Invoice Generation </h3>
        </div>

        <div class="box-body">
          <div class="box">

            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                        <label>Client</label>
                        <select class="form-control">
                          <option>option 1</option>
                          <option>option 2</option>
                          <option>option 3</option>
                          <option>option 4</option>
                          <option>option 5</option>
                        </select>
                      </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                        <label>Case</label>
                        <select class="form-control">
                          <option>option 1</option>
                          <option>option 2</option>
                          <option>option 3</option>
                          <option>option 4</option>
                          <option>option 5</option>
                        </select>
                      </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                        <label>Source Amount</label>
                        <select class="form-control">
                          <option>option 1</option>
                          <option>option 2</option>
                          <option>option 3</option>
                          <option>option 4</option>
                          <option>option 5</option>
                        </select>
                      </div>
                </div>                
              </div>
            <br>  
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 

                    <div class="table-responsive">          
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <th>Text</th>
                              <td  width="200"><div class="form-control">  </div></td>
                              <th style="text-align: right;">Discounted Price </th>
                              <td><input type="text" class="form-control" placeholder="Enter ..."></td>
                            </tr>
                            <tr>
                              <th>Text</th>
                              <td><div class="form-control">  </div></td>
                              <th style="text-align: right;">Discounted Price </th>
                              <td><input type="text" class="form-control" placeholder="Enter ..."></td>
                            </tr>
                            <tr>
                              <th>Appearance</th>
                              <td><div class="form-control">  </div></td>
                              <th style="text-align: right;">Discounted Price </th>
                              <td><input type="text" class="form-control" placeholder="Enter ..."></td>
                            </tr>
                          </tbody>
                        </table>
                        </div>




                </div>
              </div>
            <br>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1"></div>  
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10">

                    <div class="table-responsive box">          
                        <table class="table table-hover">
                          <tbody>
                            <tr>
                              <th>Legal Notice:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Reply To Legal Notice:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Conference:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Client Experience:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Legal Advisory:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Due Diligence:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Contract Drafting:</th>
                              <th></th><th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Contract Rating:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            <tr>
                              <th>Registration:</th>
                              <th></th>
                              <th></th>
                              <td><div class="form-control">  </div></td>
                            </tr>
                            
                            

                            <tr style="border-top: 2px solid #999999;">
                              <th  width="100"></th>
                              <th  width="100"></th>
                              <th  width="110" style="text-align: right;">Total:</th>
                              <td  width="100"><div class="form-control">  </div></td>
                            </tr>

                            <tr>
                              <th></th>
                              <th  style="text-align: right;">Total Amount in Words:</th>
                              <th colspan="2" ><div class="form-control">  </div></th>
                              
                            </tr>                            

                            
                          </tbody>
                        </table>
                    </div>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1"></div> 

              </div>

            </div>

            <div class="box-footer">
              <button class="btn btn-primary pull-left"> Back </button>
              <button type="submit" class="btn btn-primary pull-right">Submit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
</div>