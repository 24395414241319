<div *ngIf="loader" class="loaderbox3">
  <div class="loader3"></div>
</div>

<section class="successfullysection">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">


        <div *ngIf="verified=='y'" class="verifiedbox">This email is already verified.</div>
        <div *ngIf="verified===undefined" class="verifiedbox">You have successfully verified your mail. Now you can set
          your user name and password.</div>



      </div>
    </div>
  </div>
</section>

<section class="login-page" *ngIf="verified===undefined">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

        <div class="login_box">
          <div class="login-box-body">
            <div class="login-logo"> <a href="#"><img src="../../assets/images/logo.png" alt=""></a> </div>
            <div class="reTx">SET USERNAME AND PASSWORD</div>
            <form [formGroup]="resetForm">
              <div class="form-group has-feedback">
                <label>User Name*</label>
                <input type="text" class="form-control" placeholder="UserName" formControlName="userName">
                <span class="glyphicon glyphicon-user form-control-feedback"></span>
                <small class="text-danger"
                  *ngIf="resetForm.controls.userName.hasError('required') && ( resetForm.controls.userName.dirty || resetForm.controls.userName.touched || (!resetForm.controls.userName.valid && submittedForResetpassword))">User Name is required.</small>
              </div>
              <div class="form-group has-feedback">
                <label>Password*</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="userPwd">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                <small class="text-danger"
                  *ngIf="resetForm.controls.userPwd.hasError('required') && ( resetForm.controls.userPwd.dirty || resetForm.controls.userPwd.touched || (!resetForm.controls.userPwd.valid && submittedForResetpassword))">Password is required.</small>

                <small class="text-danger" *ngIf="resetForm.controls.userPwd.hasError('pattern')">Password should
                  contain at least one lower case and upper case,one special character, one number and should be 8
                  characters or more.</small>

              </div>


              <div class="form-group has-feedback">
                <label>Confirm password*</label>
                <input type="password" class="form-control" placeholder="Confirm password" formControlName="cpassword">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                <small class="text-danger"
                  *ngIf="resetForm.controls.cpassword.hasError('required') && ( resetForm.controls.cpassword.dirty || resetForm.controls.cpassword.touched || (!resetForm.controls.cpassword.valid && submittedForResetpassword))">Confirm password is required. </small>

                <small class="text-danger"
                  *ngIf="resetForm.controls.cpassword.value!='' && resetForm.controls.cpassword.value!=resetForm.controls.userPwd.value &&  resetForm.controls.cpassword.errors">Password and Confirm password must match</small>
              </div>


              <span class="text-danger" *ngIf="loginError"></span>

              <div class="row">

                <div class="col-xs-4">
                  <button type="button" (click)="formSubmit()" class="btn btn-primary btn-block btn-flat">SET</button>
                </div>

              </div>


              <!-- <div class="card-body box-info" *ngIf="mes !='' || mes1!=''" style="margin-top: 15px;">

                <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">{{mes}}</span>
                <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">{{mes1}}</span>


              </div> -->

            </form>
            <br>
          </div>
          <!-- /.login-box-body -->
        </div>
      </div>
    </div>
  </div>
</section>
