import { Component, OnInit } from '@angular/core';
import { LogDataService } from '../service/log-data.service';
import { CsvExportService } from '../service/csv-export.service';
import { Subject } from 'rxjs';

declare var $;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  showLoader: boolean = false;
  constructor(private logService: LogDataService, private csvgenerator: CsvExportService) { }
  logData = [];
  logDataSpe: any[] = [];
  allData: any[] = [];
  feedbackTypeArr: any[] = ['Positive feedback', 'Negative feedback'];
  fromDate: string;
  feedbackType: string = 'Positive feedback';
  toDate: string;
  csvName: string;

  questionSearchInput: any = '';
  answerSearchInput: any = '';
  timestampSearchInput: any = '';

  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();


  ngOnInit() {
   
    this.csvName = 'PositiveFeedbackData'
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      dom: 'Bfrtip'
    };
    this.fetchFeedbackData(true);
  }



  changeByColumn(evt, section) {
    console.log(evt.target.value);
    let srch_keyword: any = evt.target.value;

    if (section == "userQuestion") {
      this.answerSearchInput = '';
      this.timestampSearchInput = '';

      this.logData = this.logDataSpe.filter(item => {
        return (item.userQuestion.toLowerCase().includes(srch_keyword.toLowerCase()))
      })
    } else if (section == "botAnswer") {
      this.questionSearchInput = '';
      this.timestampSearchInput = '';

      this.logData = this.logDataSpe.filter(item => {
        return (item.botAnswer.toLowerCase().includes(srch_keyword.toLowerCase()))
      })
    } else if (section == "date") {

      this.questionSearchInput = '';
      this.answerSearchInput = '';

      this.logData = this.logDataSpe.filter(item => {
        return (item.date.toLowerCase().includes(srch_keyword.toLowerCase()))
      })
    }


    let _allData = this.logData;
    this.allData = [];

    _allData.map(item => {

      let botAnswer: any = this.strip_html_tags(item.botAnswer.toString().replace(/(\r\n|\n|\r)/gm, " "))
      botAnswer = botAnswer.replace(/"/g, '""');
      let userQuestion: any = this.strip_html_tags(item.userQuestion.toString().replace(/(\r\n|\n|\r)/gm, " "));
      userQuestion = userQuestion.replace(/"/g, '""');
      let timeStamp: any = this.strip_html_tags(item.date.toString().replace(/(\r\n|\n|\r)/gm, " "));
      timeStamp = timeStamp.replace(/"/g, '""');

      this.allData.push({
        'Question': '"' + userQuestion + '"',
        'Answer': '"' + botAnswer + '"',
        'TimeStamp': '"' + timeStamp + '"',
      })
    })



  }

  search() {
    this.fetchFeedbackData(false);
  }
  fetchFeedbackData(feedback: boolean) {
    this.showLoader = true;
    this.logService.fetchFeedbackData(feedback).
      subscribe(
        data => {
          this.logData = JSON.parse(this.logService.userData._body).respData;
          //let _allData: any = JSON.parse(this.logService.userData._body).respData;
          // Calling the DT trigger to manually render the table
          this.dtTrigger.next();
          this.logData.map(item => {
            if (item.userQuestion == null) {
              item.userQuestion = '';
            }

            if (item.botAnswer == null) {
              item.botAnswer = '';
            }

            if (item.date == null) {
              item.date = '';
            }
          })
          let _allData = this.logData;
          this.allData = [];

          _allData.map(item => {

            let botAnswer: any = this.strip_html_tags(item.botAnswer.toString().replace(/(\r\n|\n|\r)/gm, " "))
            botAnswer = botAnswer.replace(/"/g, '""');
            let userQuestion: any = this.strip_html_tags(item.userQuestion.toString().replace(/(\r\n|\n|\r)/gm, " "));
            userQuestion = userQuestion.replace(/"/g, '""');
            let timeStamp: any = this.strip_html_tags(item.date.toString().replace(/(\r\n|\n|\r)/gm, " "));
            timeStamp = timeStamp.replace(/"/g, '""');

            this.allData.push({
              'Question': '"' + userQuestion + '"',
              'Answer': '"' + botAnswer + '"',
              'TimeStamp': '"' + timeStamp + '"',
            })
          })
          this.logDataSpe = this.logData;
          this.showLoader = false;
          console.log("getfeedbackData in search--" + JSON.stringify(this.logData));
          // this.dtTrigger.next();
        }
      );

  }

  ngChangeAnsType(feedbackType) {
    console.log("Change feedback type::", feedbackType);
    let feedback = false;
    if (feedbackType == 'Positive feedback') {
      feedback = true;
      this.csvName = 'PositiveFeedbackData'
    } else {
      feedback = false;
      this.csvName = 'NegativeFeedbackData'
    }

    this.fetchFeedbackData(feedback);
  }

  downloadCSV() {
    console.log("download file name",this.csvName);
    let fieldname = ['Question', 'Answer', 'TimeStamp']
    this.csvgenerator.downloadFile(fieldname, this.allData, this.csvName)

  }


  strip_html_tags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<\/?[^>]+>/gi, '');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}