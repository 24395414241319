import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators , NgForm} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service';
import { ClientProfileSchema } from '../models/profile.model';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';

declare var $: any;


@Component({
  selector: 'app-invitemember',
  templateUrl: './invitemember.component.html',
  styleUrls: ['./invitemember.component.css']
})
export class InviteMemberComponent implements OnInit {
  
SearchCountryField = SearchCountryField;
TooltipLabel = TooltipLabel;
CountryISO = CountryISO;
selectedCountry:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
country = "UnitedKingdom"
public countryCodeList: Array<any> = countryCodeList;
  myform: FormGroup;
  mes = '';
  mes1 = '';
  mailid: string;
  userType : string;
  firstname : string;
  lastname : string;
  phoneno: string;
  viewMode: boolean = false;
  userlist = [];
  memberTypelist = [];
  usertype : string;
  superUserId : string;
  loginUserId : string;
  DesignnationList = [];
  orgDesignation:any;
  pageTitle='Invite Partner';
  dataArr: ClientProfileSchema;
  saveMode: boolean = true;
  submitted:boolean
  countryCodeMobilePh: string = environment.defaultCountryCode;
  
  constructor(private legalService: LegalbellService,public fb: FormBuilder,private toastr: ToastrService,private navigationLinkParameterService: NavigationLinkParameterService) { }

  createForm() {
    let userId = localStorage.getItem('UserID')?JSON.parse(localStorage.getItem('UserID')).userId: null;
    console.log("userId in address::",userId); 
    this.myform = this.fb.group({
      orgDesignation: new FormControl(''),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      mailid: new FormControl(''),
      phoneno: new FormControl(''),
      userId: new FormControl(userId)
      });
  }

  ngOnInit(): void {
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.orgDesignation='';
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    if(this.usertype === 'managingpartner'){
      this.pageTitle='Partner';
    }else{
      this.pageTitle='Manager';
    }
    console.log("this.usertype in teamsetup:::",this.usertype);
    if(this.usertype=='managingpartner'){
      this.DesignnationList=[{name:'Senior Partner',value:'seniorpartner'},{name:'Associate Partner',value:'associatepartner'}];
    }else{
      this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'}];
      // this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'},{name:'Legal Manager',value:'legalmanager'},{name:'Assistant Legal Manager',value:'assistantlegalmanager'}];
    }
    
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    if(this.usertype=='advocate'||this.usertype=='counsel'||this.usertype=='directorlegal'||this.usertype=='managingpartner'){
      this.getUserList(this.usertype,this.loginUserId);
    }else{
      this.getUserList(this.usertype,this.superUserId);
    }
    this.getTeamMemberType(this.usertype);
    this.createForm();
    this.legalService.getProfileData(this.loginUserId).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
      setTimeout( () => {
        this.saveMode=this.navigationLinkParameterService.saveMode;
        console.log(this.saveMode);
      }, 500 );
  }

  onCountryCodeMobilePhChange (currentSelection: string) {
    this.countryCodeMobilePh = currentSelection;
  }

  teamRegistration(){
    
    let body:any;
    let countryCode='+44';
    this.submitted = true;
    if (this.myform.invalid) {
      return
    }
    console.log("user type in team registration::", this.userType);
    if(this.mailid==''||this.mailid==undefined) {
      // window.alert("Please give a valid mail id.")
    }else if(this.firstname==''||this.firstname==undefined){
      // window.alert("Please give a valid name.")
    }
    else{
      let phno = this.myform.value.phoneno.number.replace(/\s/g, "")
      phno =  phno.replace(/-/g, "");
      body = {
              "emailId": this.mailid,
              "countryCode":this.myform.value.phoneno.dialCode,
              "phoneNo": phno,
              "userTitle" : null,
              "firstName": this.firstname,
              "middleName": null,
              "lastName" : this.lastname,
              // "userType": this.userType,
              "loginUserId" : this.superUserId,
              // "userId":this.superUserId,
              "subscriptionPlanId": null,
              'userType':this.orgDesignation,
              'superUserType':this.usertype,
              'orgId':this.dataArr.orgId,
              'calltype':'invite'
            }
            console.log(body);
            // return false;
    // this.legalService.registration(this.mailid,this.phoneno,'',this.firstname,'','',this.userType).subscribe((res: any) => {
      this.legalService.registrationNew(body).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      let ress: any = JSON.parse(res._body);
      console.log(ress.responseMessage);
      let userId = { 'userId': ress.respData };
      this.mes = ress.responseMessage;
      this.mes1 = '';
      this.viewMode = true;

      // $("#myModal").modal("hide");
      // setTimeout( () => {
      //   location.reload();
      // }, 2000 );

      this.getUserList(this.usertype,this.superUserId);
      this.toastr.success(this.mes, '');
      this.myform.reset();
      $('#myModal').modal('hide');
      
      //this.mailsent = false;
      //window.alert(ress.responseMessage);
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.myform.reset();
      this.toastr.error(this.mes1, '');
      $('#myModal').modal('hide');
      this.mes = '';
      // this.mailsent = false;
      //window.alert(erro.responseMessage);
    });
   }
  }
  getUserList( userType : string,superUserId : string) {
    this.legalService.getInviteMemberList(userType,superUserId).
      subscribe(
        data => {
          this.userlist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist)); 
        }
      );
  }

  getTeamMemberType( userType : string) {
    this.legalService.getTeamMemberType(userType).
      subscribe(
        data => {
          this.memberTypelist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamMemberType comp res--" + JSON.stringify(this.memberTypelist)); 
        }
      );
  }

  modalclose(){
    //this.viewMode = false;
    this.mailid = '';
    this.mes = '';
    this.mes1 = '';
    this.firstname = '',
    this.phoneno = ''
    this.myform.reset();
    console.log("view mode::",this.viewMode);
  }

  openModel(){
    this.submitted = false;
  }
}