<header class="main-header"  *ngIf="showMenu"> 
  <!-- Logo --> 
  <!-- <a href="#" class="logo"> <span class="logo-mini"></span> <span class="logo-lg"> </span> </a> -->
  <nav class="navbar navbar-static-top top-menu"> 
    <!-- Sidebar toggle button--> 
    <a href="#" class="topsidemenu" data-toggle="push-menu" role="button"> <i class="material-icons md-18 md-dark">menu</i> </a>
   
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li><a>{{userTitle}}{{firstName}}{{lastName}}{{orgDesignation}}</a></li>
        <li> <a [routerLink]="['/lawyerprofile']" routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom" title={{usertype}}> <i class="material-icons md-18 md-dark">person</i> </a></li>
        <li> <a [routerLink]="['/resetPassword']" routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom" title="Reset password">  <i class="material-icons md-18 md-dark">vpn_key</i> </a></li>
        <li> <a [routerLink]="['/notifaction']" routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom" title="Notification">  <i class="material-icons md-18 md-dark">notifications</i> <span class="badge badge-light">{{notificationCount}}</span></a></li>
        <li> <a (click)="logout()"  data-toggle="tooltip" data-placement="bottom" title="Sign out"> <i class="material-icons md-18 md-dark">exit_to_app</i> </a></li>
        
      </ul>
    </div>
  </nav>
</header>

<app-mainsidebar *ngIf="showMenu"></app-mainsidebar>
