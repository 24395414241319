<div class="content-wrapper">

  <section class="content-header">
    <h1> Feedback <small> </small> </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-commenting"></i> Feedback </li>
    </ol>
  </section>


  <div class="content">
    <div class="card-body box-info">
      <div class="row">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <h4>Feedback Type: </h4>

          <div class="check10">
            <span class="check1" *ngFor="let feedbkT of feedbackTypeArr">
              <input type="radio" style="width: unset;" [checked]="feedbackType===feedbkT"
                (change)="ngChangeAnsType(feedbkT)" name="feedbackType" [value]="feedbkT">&nbsp;{{feedbkT}}&nbsp;&nbsp;
            </span>
          </div>
        </div>
        <!-- <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">

         
        </div> -->
      </div>
      <br>
      <div class="row">


        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

          <div class="card-body box-info">
            <div style="text-align: right" class="downloadbox">
              <button class="btn" (click)="downloadCSV()" data-toggle="tooltip" data-placement="bottom"
                title="CSV Download"><i class="fa fa-download" aria-hidden="true"></i></button>
            </div>

            <div class="table-responsive">

              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                <!-- <table #dataTable class="display" style="width:100%"> -->
                <!-- <table class="table table-bordered box-primary">  -->
                <thead>


                  <tr>
                    <!-- <th><label> Serial No</label></th> -->
                    <th style="width:400px">
                      <span>Available Questions</span>

                      
                    </th>

                    <th width="100px"><label>
                        <span>Answer</span>
                      </label></th>

                    <th width="100px"><label>
                        <span by="date"> TimeStamp</span>
                      </label></th>
                  </tr>

                  <!-- <tr>
                    <td>
                      <input type="text" class="col-search-input form-control" placeholder="Search..."
                        (input)="changeByColumn($event, 'userQuestion')" [(ngModel)]="questionSearchInput" />
                    </td>

                    <td>
                      <input type="text" class="col-search-input form-control" placeholder="Search..."
                        (input)="changeByColumn($event, 'botAnswer')" [(ngModel)]="answerSearchInput" />
                    </td>

                    <td>
                      <input type="text" class="col-search-input form-control" placeholder="Search..."
                        (input)="changeByColumn($event, 'date')" [(ngModel)]="timestampSearchInput" />
                    </td>
                  </tr> -->


                </thead>
                <tbody>
                  <tr *ngFor="let item of logData;let i=index">
                    <!-- <td align="center"> {{i + 1}}</td> -->
                    <td> {{item.userQuestion}}</td>
                    <td [innerHTML]="item.botAnswer  | safeHTML:'html'"></td>
                    <td> {{item.date}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a [routerLink]="['/search']" routerLinkActive="router-link-active">
            <button type="submit" class="btn btn-primary">Back</button>
          </a>
        </div>
      </div>

    </div>

  </div>


</div>