import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notificationlist = [];
  loginUserId:string;
  constructor(private legalbellService: LegalbellService) { }

  ngOnInit(): void {
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.loginUserId = userData.userIdlogin;
    this.legalbellService.updateMessage(0);
    this.getAllNotification(this.loginUserId);
  }

  getAllNotification(userId){
  
    this.legalbellService.getNotificationDetails(userId).
    subscribe(
      data => {
        this.notificationlist = JSON.parse(this.legalbellService.userData._body).respData;
        if(this.notificationlist.length != 0){
        this.updateAllNotiFication(this.notificationlist)
        }
      
      }
    );
    
  
  }

  updateAllNotiFication(list){
    this.legalbellService.updateNotification(list).subscribe((res: any) => {
               
      
      setTimeout( () => {
        //location.reload();
       
      }, 2000 );
    }, (err: any) => { 
     
    });




  }

}
