import { Component, OnInit, ViewChild } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'

import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild( DataTableDirective, { static: false } )
  dtElement: DataTableDirective;

  profilestatus : string;
  eventArr: any[] = [];
  userlist: any[] = [];
  userId: string;
  clientcount:number;
  taskcount:number;
  // eventData: any[] = [];

  // DT Config Define Here
  dtOptions     : DataTables.Settings={};
  dtTriggerCob  : Subject<any>=new Subject();
  clientOnbInfo : any[] = [];
  isDtInitialized:boolean = false;

  noOfUnpaidBills:any;
  outstandingBills:any;
  noOfPaidBills:any;
  totalRevenue:any;
  dataUser:any
  caseDetails:any = []
  overHeadDetails:any = []
  constructor(private legalbellService: LegalbellService) { 
    
  }

  ngOnInit(): void {
    this.noOfUnpaidBills = "0"
    this.noOfPaidBills = "0"
    //this.noOfUnpaidBills = "1"
    this.outstandingBills = "0"
    this.totalRevenue = "0"
    //this.totalRevenue = "4000"
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.dataUser = JSON.parse(localStorage.getItem('profileInfo'));
    console.log(userData)
    this.userId = userData.userIdlogin;
    let superUserId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
     console.log("superUserId in dashboard", superUserId);
    // console.log("userId in localstorage::",this.userId); 
    if(userData.usertype == "corporateclient" || userData.usertype == "individualclient"){
      this.getCaseDetails(this.userId,userData.usertype);
    }else{
    if(userData.superUserId){
      this.getCaseDetails(userData.superUserId,null);
    }else{
    this.getCaseDetails(this.userId,null);
    }
  }
    //this.getOverHeadExpense()
    this.getUserList(superUserId);
    this.getTaskList(this.userId);
    this.getBillDetails();
    // Init Datatable Start Here
    this.dtOptions  = {
              lengthMenu : [[10, 25, 50], [10, 25, 50]],
              pagingType : 'full_numbers',
              pageLength : 25,
              dom        : 'lfBrtip',
    };
    // Init Datatable Start Here

    // Intially Call the APi with default value of 10
    this.getClientOnboardedInfo(10);
  }



  //get Event data
  getEventData(id: string) {
    
    // Call The Service for the Show Events In calendar Start here
    this.legalbellService.getTasksByCriteria(this.userId).
      subscribe(
        data => {
          // let res = JSON.parse(this.legalbellService.userData._body);
          this.eventArr = [...JSON.parse(this.legalbellService.userData._body).respData,...this.caseDetails,...this.overHeadDetails];
          let eventData = [];
          let days='day ';

          this.eventArr.forEach(element => {
              //  console.log(element.startDate);
              //  element.endDate='2020-07-17T00:00:00.000+0000';
              //   console.log(element.endDate);
              //  console.log('----------');
              let today = new Date();
              // Start date
              let startDatecase 
              let start_d1
              let start_m1
              let start_y1
              let start_h1
              let start_i1
              let caseendDate 
              let end_d1
              let end_m1
              let end_y1
              let end_h1
              let end_i1

              let startReminderDate 
              let start_d2
              let start_m2
              let start_y2
              let start_h2
              let start_i2
              let endReminderDate 
              let end_d2
              let end_m2
              let end_y2
              let end_h2
              let end_i2
              let startDate = new Date(element.startDate);
              
              let start_d    = startDate.getDate(),
                  start_m    = startDate.getMonth(),
                  start_y    = startDate.getFullYear(),
                  start_h    = startDate.getHours(),
                  start_i   = startDate.getMinutes()
                 
                  if(element.caseObj){
                   startDatecase = new Date(element.caseObj.nextDateDt);
                  
                      start_d1    = startDatecase.getDate(),
                      start_m1    = startDatecase.getMonth(),
                      start_y1    = startDatecase.getFullYear(),
                      start_h1    = startDatecase.getHours(),
                      start_i1   = startDatecase.getMinutes() 
                      
                  }
                  if(element.particulars && element.reminderDate !=null){
                    startReminderDate = new Date(element.reminderDate);

                    start_d2    = startReminderDate.getDate(),
                      start_m2    = startReminderDate.getMonth(),
                      console.log("MOnth")
                      console.log(typeof start_m2)
                      if(start_m2 == 11 || start_m2 == 0 ||  start_m2 == 2 ||  start_m2 == 4 ||  start_m2 == 6 ||  start_m2 == 7 ||  start_m2 == 9){
                        start_d2 - 1;
                      }
                      start_y2    = startReminderDate.getFullYear(),
                      start_h2    = startReminderDate.getHours(),
                      start_i2   = startReminderDate.getMinutes() 
                    
                  }
              // End Date
              let endDate = new Date(element.endDate);
              let end_d       = endDate.getDate(),
                  end_m       = endDate.getMonth(),
                  end_y       = endDate.getFullYear(),
                  end_h     = endDate.getHours(),
                  end_i   = endDate.getMinutes()
                  if(element.caseObj){
                   caseendDate = new Date(element.caseObj.nextDateDt);
                      end_d1       = caseendDate.getDate(),
                      end_m1       = caseendDate.getMonth(),
                      end_y1       = caseendDate.getFullYear(),
                      end_h1    = caseendDate.getHours(),
                      end_i1   = caseendDate.getMinutes()
                  }
                  if(element.particulars && element.reminderDate !=null){
                    endReminderDate = new Date(element.reminderDate);
                    
                       end_d2       = endReminderDate.getDate(),
                       end_m2       = endReminderDate.getMonth(),
                       console.log("MONTH")
                       console.log(end_m2)
                       end_y2       = endReminderDate.getFullYear(),
                       end_h2    = endReminderDate.getHours(),
                       end_i2   = endReminderDate.getMinutes()
                   }
                  // console.log(end_h +'  '+end_i);
                  // console.log(today);
                  // console.log(endDate);
                  if ( startDate <= today && endDate > today){
                    // console.log('CHeck DATE');
                    let dateDiffEndDay : number= Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) ) /(1000 * 60 * 60 * 24));
                    // console.log(dateDiffEndDay);
                    if(dateDiffEndDay>0){
                        if(dateDiffEndDay>1)
                        {
                           days='days ';
                        }
                        
                        element.taskBrief='('+dateDiffEndDay+' '+days+'left) '+element.taskBrief;
                       // element.caseObj.caseNo='('+dateDiffEndDay+' '+days+'left) '+element.caseObj.caseNo;
                    }
                  }
                 /* if(element.caseObj){
                  let dateDiffEndDayCase : number= Math.floor((Date.UTC(caseendDate.getFullYear(), caseendDate.getMonth(), caseendDate.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) ) /(1000 * 60 * 60 * 24));
                  
                  if(dateDiffEndDayCase>=0){
                      if(dateDiffEndDayCase>1)
                      {
                         days='days ';
                      }
                      
                      element.caseObj.caseNo='('+dateDiffEndDayCase+' '+days+'left) '+element.caseObj.caseNo;
                     
                  }
                }*/
                  
                  let taskColor = "";
                  if(element.taskPriority == "URGENT"){
                    taskColor = "#f56954";
                  }else{
                    taskColor = "#00c0ef";
                  }
                 
                  // eventData.push({title:element.taskBrief, start : new Date(start_y, start_m, start_d, start_h, start_i), end : new Date(end_y, end_m, end_d, end_h, end_i), backgroundColor: taskColor, borderColor : taskColor, description:"<b>Assigned To : </b>"+element.userName+'<br><b>Task Name:</b> '+element.taskBrief+",<br><b> Start Date:</b> "+startDate.toLocaleString()+",<br><b> End Date: </b>"+endDate.toLocaleString()});
                  if(!element.caseNo){
                  eventData.push({title:element.taskBrief,allDay: true, start : new Date(start_y, start_m, start_d, start_h, start_i), end : new Date(end_y, end_m, end_d+1, 23, end_i), backgroundColor: taskColor, borderColor : taskColor, description:"<b>Assigned To : </b>"+element.userName+'<br><b>Task Name:</b> '+element.taskBrief+",<br><b> Start Date:</b> "+startDate.toLocaleString()+",<br><b> End Date: </b>"+endDate.toLocaleString()});
                  }
             if(element.caseNo){
              eventData.push({title:element.taskBrief,allDay: true, start : new Date(start_y, start_m, start_d, start_h, start_i), end : new Date(end_y, end_m, end_d+1, 23, end_i), backgroundColor: taskColor, borderColor : taskColor, description:"<b>Assigned To : </b>"+element.userName+'<br><b>Task Name:</b> '+element.taskBrief+",<br><b> Start Date:</b> "+startDate.toLocaleString()+",<br><b> End Date: </b>"+endDate.toLocaleString()+",<br><b> Case No: </b>"+element.courtCaseNo+",<br><b> Case Title: </b>"+element.causeTitle+",<br><b> Client Name: </b>"+element.clientName});
              }
              if(element.caseObj){
                
              eventData.push({title:element.caseObj.courtCaseNo,allDay: true, start : new Date(start_y1, start_m1, start_d1, start_h1, start_i1), end : new Date(end_y1, end_m1, end_d1+1, 23, end_i1), backgroundColor: taskColor, borderColor : taskColor, description:"<b>Case No : </b>"+element.caseObj.courtCaseNo+'<br>'+'<b>Case Title : </b>'+element.caseObj.caseTitle+'<br><b>Next Date:</b> '+new Date(element.caseObj.nextDateDt).toLocaleDateString()+'<br><b>Client Name:</b> '+element.clientName});
              }
              if(element.particulars && element.reminderDate !=null){
                eventData.push({title:element.particulars,allDay: true, start : new Date(start_y2, start_m2, start_d2 , start_h2, start_i2), backgroundColor: '#FF8040', borderColor : '#FF8040',description:"<b>Particulars : </b>"+element.particulars});

              }
              
          });
          // Init calendar after 100ms
          setTimeout(() => {
            $("#calendar").fullCalendar({
                          // views: { week: { allDay: false } },
                          // dayMaxEvents: !0,
                          height: 439,
                          timeZone: "UTC",
                          header    : {
                            left  : 'prev,next today',
                            center: 'title',
                            right : 'month,Week'
                            // right : 'month,agendaWeek,agendaDay'
                          },
                          buttonText: {
                            today: 'today',
                            month: 'month',
                            week : 'week',
                            day  : 'day'
                          },
                            navLinks   : true,
                            editable   : false,
                            eventLimit : true,
 
                            eventMouseover: function(calEvent, jsEvent) {
                              var tooltip = '<div class="tooltipevent" style="width:300px;height:auto;background:#bfbfbf; border:1px solid #ccc;position:absolute;z-index:10001;padding:7px;border-radius:13px;">' + calEvent.description + '</div>';
                              var $tooltip = $(tooltip).appendTo('body');
                          
                              $(this).mouseover(function(e) {
                                  $(this).css('z-index', 10000);
                                  $tooltip.fadeIn('500');
                                  $tooltip.fadeTo('10', 1.9);
                              }).mousemove(function(e) {
                                  $tooltip.css('top', e.pageY + 10);
                                  $tooltip.css('left', e.pageX + 20);
                              });
                          },
                          
                          eventMouseout: function(calEvent, jsEvent) {
                              $(this).css('z-index', 8);
                              $('.tooltipevent').remove();
                          },
                            nextDayThreshold: '10:00:00',
                            events: eventData,  // request to load current events
                            droppable : true, // this allows things to be dropped onto the calendar !!!
                            drop      : function (date, allDay) { // this function is called when something is dropped

                              // retrieve the dropped element's stored Event Object
                              var originalEventObject = $(this).data('eventObject')

                              // we need to copy it, so that multiple events don't have a reference to the same object
                              var copiedEventObject = $.extend({}, originalEventObject)

                              // assign it the date that was reported
                              copiedEventObject.start           = date
                              copiedEventObject.allDay          = allDay
                              copiedEventObject.backgroundColor = $(this).css('background-color')
                              copiedEventObject.borderColor     = $(this).css('border-color')

                              // render the event on the calendar
                              // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)
                              $('#calendar').fullCalendar('renderEvent', copiedEventObject, true)

                              // is the "remove after drop" checkbox checked?
                              if ($('#drop-remove').is(':checked')) {
                                // if so, remove the element from the "Draggable Events" list
                                $(this).remove()
                              }

                            }
            });

            // console.log(eventData);
    
         }, 100);

          
        }
      );
  }
  // On Select change the date range
  onOptionsSelected(value:string){
        // console.info(value);
        this.getClientOnboardedInfo(parseInt(value));
  }


  // Call the Client Onboarded Info Start here
  async getClientOnboardedInfo(daysTo:any) {
        
        // console.log("I am inside");
        let today     = new Date();
      
        let startDate = today.getFullYear()+'-'+(today.getMonth() + 1)+'-'+today.getDate();
        today.setDate(today.getDate() + daysTo);
        let endDate   = today.getFullYear()+'-'+(today.getMonth() + 1)+'-'+today.getDate();

        console.log(today+"||"+startDate+"||"+endDate);
        
        // Call service for Generate list 
        this.legalbellService.getUserListByCriteria(startDate, endDate).
        subscribe(
                async data => {
                    // let res = JSON.parse(this.legalbellService.userData._body);

                    // this.clientOnbInfo = JSON.parse(this.legalbellService.userData._body).respData;
                    // console.log(this.clientOnbInfo);

                    if(this.isDtInitialized) {
                        this.dtElement.dtInstance.then( (dtInstance: DataTables.Api ) => {
                          // Destroy the table first
                          dtInstance.destroy();
                          this.clientOnbInfo = JSON.parse(this.legalbellService.userData._body).respData;
                          // Call the dtTrigger to rerender again
                          this.dtTriggerCob.next();
                      } );
                      
                    }else{
                      this.isDtInitialized = true;
                      this.clientOnbInfo = JSON.parse(this.legalbellService.userData._body).respData;
                      this.dtTriggerCob.next();
                    }
                    
        });

          
  }
  // Call the Client Onboarded Info Ends  here

  getUserList(superUserId : string) {
    this.legalbellService.getUserListRoster(superUserId).
      subscribe(
        data => {
          this.userlist = JSON.parse(this.legalbellService.userData._body).respData;
          this.clientcount = this.userlist.length;
          console.log("getUserList comp res--" + JSON.stringify(this.userlist.length)); 
        }
      );
  }

  getTaskList(userId : string) {
    this.legalbellService.getTaskCount(userId).
      subscribe(
        data => {
          let tasklist = JSON.parse(this.legalbellService.userData._body).respData;
          this.taskcount = tasklist.length;
          console.log("taskcount comp res--" + JSON.stringify(tasklist.length)); 
        }
      );
  }

  // Destroy the datatable in ng destroy start
  ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTriggerCob.unsubscribe();
  }
  // Destroy the datatable in ng destroy Ends 

  getBillDetails() {
   
    let userData = JSON.parse(localStorage.getItem('profileInfo'));
   
    console.log(userData)
    if(userData.usertype != "corporateclient" || userData.usertype != "individualclient"){
      let type
      if(userData.superaUserType){
        type = userData.superaUserType
      }else{
        type = userData.usertype
      }
      this.legalbellService.getBillDetails({"superUserId":userData.superUserId,"userType":type,"status":null}).subscribe((data) => {
       if(data.respData.invCount){
        this.noOfPaidBills = data.respData.invCount
        this.totalRevenue = data.respData.invSumAmount
       }
        if(data.respData.unpaidBillCount){
        this.noOfUnpaidBills = data.respData.unpaidBillCount
       this.outstandingBills = data.respData.unpaidBillAmount
        }
        });
    
  }
   /*  this.legalbellService.getBillDetails().subscribe((data) => {
      JSON.parse(this.legalbellService.userData._body).respData
      }); */
   
  }

  getCaseDetails(userId, type){
   /* this.legalbellService.getCaseDetailsDashboard(userId).subscribe(async(data) => {
      this.caseDetails = data.respData
     
    }); */

    this.legalbellService.getCaseDetailsDashboard(userId,type).
    subscribe(
      data => {
        this.caseDetails = JSON.parse(this.legalbellService.userData._body).respData;
        
        this.getOverHeadExpense(userId)
        
         
      }
    );
  }
  

  getOverHeadExpense(userId){
  
    this.legalbellService.getOverhaidDetailsDashboard(userId).
    subscribe(
      data => {
        this.overHeadDetails = JSON.parse(this.legalbellService.userData._body).respData;
        this.getEventData(this.userId);
      }
    );
    
  
  }

}