
import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LegalbellService } from '../service/legalbell.service'
import { ClientProfileSchema } from '../models/profile.model';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
interface TestObject {
  userId : string;
  userTitle : string;
  firstName : string;
  middleName : string;
  lastName : string;
  emailId : string;
  countryCode : string;
  phoneNo : string;
  token : string;
  registrationDate : string;
  subscriptionPlanId : string;
  profileCompletionPercentage : string;
  createdAt : string;
  createdBy : string;
  updatedAt : string;
  updatedBy : string;
  userType : string;
  userRegistrationType : string;
  loginPwd : string;
  loginPwdNew : string;
  pwdUpdatedAt : string;
  loginUserName : string;
 
  // profileUpdate : "N"
  // activeAccount : null
  // superUserId : "U-9745785091064228"
  // superUserType : null
  // loginUserId : null
  // orgId : null
  // orgName : null
  // orgDesignation : null
  // invitationSent : null
  // aadharNo : null
  // aadharNoSrc : null
  // panNo : null
  // panNoSrc : null
  // profilePicSrc : null
  // linkedinProfileSrc : null
  // enrollmentNo : null
  // resumePathToS3 : null
  // certificateOfIncorporation : null
  // certificateOfIncorporationPathToS3 : null
  // officePhoneNo : null
  // stateBarCouncilId : null
  // districtId : null
  // lawyerPracticeAreas : null
  // courtsOfPractice : null
  // barCouncilOfIndiaId : null
  // lawyerBankName : null
  // lawyerBankAccount : null
  // lawyerBankBranch : null
  // lawyerBankIfscCode : null
  // clientProfession : null
  // icaiRegistrationNo : null
  // userQualification : null
  // userUniversity : null
  // corporateClientCompanyName : null
  // corporateClientNatureOfBusiness : null
  // corporateClientWebsite : null
  // corporateClientLinkedinProfile : null
  // corporateClientCin : null
  // corporateClientPan : null
  // corporateClientDesignation : null
  // corporateClientProfilePathInS3 : null
  // startDateStr : null
  // endDateStr : null
  // startDate : null
  // endDate : null
  // callType : null
  }
@Component({
  selector: 'app-contractinvoicegeneration',
  templateUrl: './contractinvoicegeneration.component.html',
  styleUrls: ['./contractinvoicegeneration.component.css']
})
export class ContractinvoicegenerationComponent implements OnInit {

  myform: FormGroup;
  superUserId : string;
  mes:TestObject[] = [];
  case:any[]=[];
  clientData:TestObject[] = [];
  mes1 = '';
  viewMode: boolean = true;
  viewModeCheckedList: boolean = true;
  viewModeUncheckedList: boolean = true
  viewMode1: boolean = false;
  viewMode2: boolean = false;
  scheduleofFees: any;
  checkedList: any;
  uncheckedList: any;
  userId : any;
  allStateLists : any = [];
  unallStateLists : any = [];
  myGroup: FormGroup;
  selectedFeesIndex=new Array();
  selectedFeesIndexUncheck=new Array();
  usertype: string;
  emailId: string;
  finalArray=new Array();
  clientUserId: string;
  viewMode3=false;
  clientDetails:any;
  dataArr: ClientProfileSchema;
  finalcontent: any;
  stc:boolean = false;
  gfI:boolean = true;
  selectedIDs:any = [];
  sfa:boolean = false;
  serviceMsg:string;
  showMsg:boolean=false;
  message:string;
  k:any;
  feeagreementList:any=[];
  selectedId:any;
  hasCheckedList=false;
  hasUncheckedList=false;
  clientName:string;
  caseNo:string;
  src:SafeUrl = '';
  invoiceNo:string;
  showModalBox:boolean=false;
  // constructor(private legalService: LegalbellService, public fb: FormBuilder,private router: Router,private navigationLinkParameterService: NavigationLinkParameterService,location: Location,pdfViewerModule:PdfViewerModule, private sanitizer: DomSanitizer) {  }
  constructor(private legalService: LegalbellService,private toastr: ToastrService, public fb: FormBuilder,private router: Router,private navigationLinkParameterService: NavigationLinkParameterService,location: Location, private sanitizer: DomSanitizer) {  }

  ngOnInit(): void {
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.emailId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).emailId: null;
    this.getAllClientsOfSuperUser();
  }
  ngOnDestroy(): void {
  }
  
  getAllClientsOfSuperUser(){
    this.legalService.getUserList(this.superUserId).subscribe((res: any) => {
      this.mes = JSON.parse(this.legalService.userData._body).respData;
      this.clientData=this.mes;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.toastr.error(this.mes1, '');
      this.mes = [];
    });
  }
  onSelectClient(clientUserId){
    this.showMsg=false;
      this.mes1="";
      this.gfI=true;
    this.userId=clientUserId;
    this.selectedFeesIndex=[];
    this.case=[];
    this.checkedList=[];
    this.caseNo='';
    this.viewMode1 = false;
    this.previewInvoiceButtonCheck();
    if(clientUserId !== '--Select--'){
      this.getAgreedFeesAgreementList(clientUserId);
      this.legalService.getContractList(clientUserId).subscribe((res: any) => {
        this.case = JSON.parse(this.legalService.userData._body).respData;
        // this.stc=false;
        
      }, (err: any) => {
        // this.sfa=false;
        // this.stc=false;
        // this.scheduleofFees=[];
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.toastr.error(this.mes1, '');
        this.mes = [];
      });
    }
    
  }
  previewInvoiceButtonCheck(){
    console.log(this.stc);
    let clientID = this.userId;
    let caseNO = this.caseNo;
    console.log(clientID+' '+caseNO+' '+this.selectedFeesIndex.length);
    if(clientID !=='--Select--' && caseNO !=='' && caseNO !==undefined && caseNO !=='--Select--' && this.selectedFeesIndex.length !==0){
      this.stc = true;
    }else{
      this.stc = false;
    }
  }
  getAgreedFeesAgreementList(clientUserId){
    this.hasCheckedList=false;
    this.sfa=false;
    this.viewMode1 = false;
    this.checkedList=[];
    let body = {"clientUserId" : clientUserId,"superUserId":this.superUserId};
      
      console.log(body);
      this.legalService.getAgreedFeesAgreementList(body).subscribe((res: any) => {
        // this.case = JSON.parse(this.legalService.userData._body).respData;
        console.log(res);
        if(res.respData.length !== 0){
          this.hasCheckedList=true;
          this.checkedList=res.respData;
          console.log(this.checkedList);
          this.createForm();
          this.viewMode1 = true;
          this.sfa=true;
        }else{

        }
        

      }, (err: any) => {
        this.sfa=false;
        this.checkedList=[];
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.toastr.error(this.mes1, '');
        this.mes = [];
      });
  }
  getServicesScheduleOfFees(){
    let body : any;
    body = {"superUserId" : this.superUserId}
    console.log(body);
    this.legalService.getServicesScheduleOfFees(body).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      // let ress: any = JSON.parse(res._body);
      console.log(res.respData);
     
        this.scheduleofFees=res.respData;
        this.viewMode1 = true;
        this.createForm();
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.toastr.error(this.mes1, '');
      this.mes = [];
    });
  }
  
  scheduleofFeesSubmitChecked(){
    console.log('called');
    alert('called');
    return false;
  }
  amountChecked(event,index){
    let key= 'amount_'+index;
    this.myform.patchValue({ key : parseInt(event) });
    // console.log(this.myform.value);
  }
  particularsOfServiceChecked(event,index){
    let key= 'particularsOfServices_'+index;
    this.myform.patchValue({ key : event });
    // console.log(this.myform.value);
  }
  onSelectCase(caseNo){
    this.gfI=true;
    this.caseNo=caseNo;
    this.showMsg=false;
      this.mes1="";
      this.previewInvoiceButtonCheck();
  }
  scheduleofFeesSubmit(){
    this.src= '';
    this.showMsg=false;
      this.mes1="";
    console.log('calling----------');
    // this.stc=false;
    // console.log(this.myform.value);
    this.serviceMsg="Review the services before you sent to client :";
    const superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    const orgId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).orgId: null;
    const emailId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).emailId: null;
    let formVal=this.myform.value;
    console.log(formVal);

    
    const distinctArray = this.selectedFeesIndex.filter((n, i) => this.selectedFeesIndex.indexOf(n) === i);
    if(distinctArray.length==0){
      this.showMsg=true;
      this.mes1="Please select at aleast a service.";
      return false;
    }
    if(this.caseNo==undefined){
      this.showMsg=true;
      this.mes1="Please select case."
      return false;
    }
   
    console.log(distinctArray);
    distinctArray.forEach((val, eachIndexFees) => {
      let particularDtl={
        particularName: formVal['particularsOfServices_'+val],
        amount: parseInt(formVal['amount_'+val]),
        particularNamePrint:formVal['particularNamePrint_'+val]
      }
      this.finalArray[eachIndexFees]= particularDtl;
    });
  
      let eachscheduleFees={
        clientUserId:this.userId,
        superUserId: superUserId,
        loginUserId:superUserId,
        userType: this.clientData[0].userType,
        contactNumber:this.caseNo,
        status:"pending",
        orgId: orgId,
        particularDtl: this.finalArray
      }
      console.log(eachscheduleFees);

    this.legalService.generateInvoice(eachscheduleFees).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      // let ress: any = JSON.parse(res._body);
      console.log(res.respData);
      if(res.status==200)
      {
        console.log(res);
        this.invoiceNo=res.respData;
        // this.src=res.respData.localfilePath +'/' + res.respData.docName;
        //this.src=res.respData.localfilePath;
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(res.respData.localfilePath);
        console.log(this.src);
        //window.open(this.src, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1200,height=900");
        this.stc=true;
        this.gfI=false;
      }
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.toastr.error(this.mes1, '');
      this.mes = [];
    });
  
  }
  backofViewMode2(){
    this.finalArray=[];
    this.allStateLists=[];
    this.unallStateLists=[];
    this.uncheckedList=[];
    this.checkedList=[];
    this.selectedFeesIndex=[];
    this.selectedFeesIndexUncheck=[];
    this.feeagreementList=[];
    this.selectedIDs=[];
    this.viewMode = true;
    this.viewMode1 = true;
    this.viewMode2 = false;
    this.selectedId=this.userId;
      this.onSelectClient(this.selectedId);
  }
  checkRevise(val){
    if(val==="Y")
    return true;
    else
    return false;
  }
  feesId(indexID){
    this.gfI=true;
    this.showMsg=false;
      this.mes1="";
    const index: number = this.selectedFeesIndex.indexOf(indexID);
    if(index<0){
      this.selectedFeesIndex.push(indexID);
    }else{
          this.selectedFeesIndex.splice(index, 1);
    }
    console.log(this.selectedFeesIndex);
    this.previewInvoiceButtonCheck();
  }
  checkAgreed(val,indexID){
    // console.log(indexID);
    if(val==="Y")
    {
      return true;
    }
    else
    {
    return false;
    }
  }

  generateFinalInvoice(){
    // this.serviceMsg="Service Particulars Are Sent To Client For Agreement :";
    this.showMsg=true;
    this.message="Invoice generated successfully";
    console.log('final call');
    this.legalService.generateFinalInvoice(this.invoiceNo).subscribe((res: any) => {
        // this.mes= res.responseMessage;
        this.toastr.success('Invoice generated successfully', '');
        console.log(res);
        // let ress: any = JSON.parse(res._body);
        // this.finalArray=res.respData.feeagreementList;
        console.log(res.responseMessage);
        this.message=res.responseMessage;
        this.showModalBox=true;
        setTimeout( () => {
          this.close();
        }, 2500 );
        // this.stc=true;
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.toastr.error('Invoice generated unsuccessfull', '');
        this.mes1 = erro.responseMessage;
        this.mes = [];
      });
  }
  close(){
    this.showMsg=false;
    window.location.reload();
  }
  

  createForm() {
    let formGroupStructure = {
      tribunals             : new FormControl(''),
      particularsOfServices : new FormControl(''),
      amount                : new FormControl(''),
    }
 
   

    this.allStateLists = this.checkedList;
    this.allStateLists.forEach((eachList, eachIndex) => {
      // console.log(eachList.tribunals);
      formGroupStructure['tribunals_' + eachIndex]              =  new FormControl(eachList.tribunals);
      formGroupStructure['particularsOfServices_' + eachIndex]  =  new FormControl(eachList.particularsOfServices);
      formGroupStructure['particularNamePrint_' + eachIndex]  =  new FormControl(eachList.particularsOfServices);
      formGroupStructure['amount_' + eachIndex]                 =  new FormControl(eachList.amount);
      formGroupStructure['id_' + eachIndex]                     =  new FormControl(eachList.id);
      // this.selectedFeesIndex.push(eachIndex);
    })
    this.myform = this.fb.group(formGroupStructure);
    console.log('test', this.myform.value);

   
  }
  getProfileData(userid: string) {
    console.log("userid in getProfileData--" + userid);
    this.legalService.getProfileData(userid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
  }

}
