<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon"><i class="fa fa-asterisk" aria-hidden="true"></i></div>
          <h3 class="box-title">Client Case Details</h3>
        </div>

        <div class="box-body">
            <div class="breadcrumbbox">
                <ol class="breadcrumb">
                  <li class="active"><a routerLink="/clientcasedetails"><i class="fa fa-asterisk" aria-hidden="true"></i> Client Case Details </a></li>
                  <li><a routerLink="/feeagreement"><i class="fa fa-files-o" aria-hidden="true"></i> Fee Agreement</a></li>
                  <li><a routerLink="/feeservice"><i class="fa fa-server" aria-hidden="true"></i> fee Service</a></li>
                </ol>
            </div>
          </div>

        <div class="box-body">
          <div class="box">
            <div class="box-body">
              
				<div class="form-group">
                    <label>Select</label>
                    <select class="form-control">
                      <option>--Select--</option>
                      <option>Client Name</option>
                      <option>Lawyer Name</option>
                      <option>Case No</option>
                    </select>
                  </div>
		
				
				
				
            </div>
            <div class="box-footer">
              <button class="btn btn-primary pull-left"> Back </button>
              <button type="submit" class="btn btn-primary pull-right">Submit</button>
            </div>
          </div>
        </div>        
      </div>
    </div>
</div>

