import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service'

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
import { ClientProfileSchema } from '../models/profile.model';
declare var $: any;
@Component({
  selector: 'app-teamsetup',
  templateUrl: './teamsetup.component.html',
  styleUrls: ['./teamsetup.component.css']
})

export class TeamsetupComponent implements OnInit {
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"
public countryCodeList: Array<any> = countryCodeList;
  myform: FormGroup;
  mes = '';
  mes1 = '';
  mailid: string;
  userType : string;
  firstname : string;
  lastname : string;
  phoneno: string;
  viewMode: boolean = false;
  userlist = [];
  memberTypelist = [];
  teamUserType : string;
  superUserId : string;
  superUserType : string;
  loginUserId : string;
  teamsetupPermission : string;
  userlistLawFarm = [];
  mergeUserList = []
  inviteNewMemberForm  : FormGroup;
  submittedForinviteMember : boolean;
  countryCodeMobilePh: string = environment.defaultCountryCode;
  orgDesignation:any;
  dataArr: ClientProfileSchema;
  saveMode: boolean = true;
  submitted:boolean;
  usertype : string;
  pageTitle='Invite Partner';
  DesignnationList = [];
  
  constructor(private legalService: LegalbellService, private toastr: ToastrService, private fb: FormBuilder,private navigationLinkParameterService: NavigationLinkParameterService) { }

  createForm() {
    let userId = localStorage.getItem('UserID')?JSON.parse(localStorage.getItem('UserID')).userId: null;
    console.log("userId in address::",userId); 
    this.myform = this.fb.group({
      orgDesignation: new FormControl(''),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      mailid: new FormControl(''),
      phoneno: new FormControl(''),
      userId: new FormControl(userId)
      });
  }

  ngOnInit(): void {
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.userType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;
    this.teamsetupPermission = localStorage.getItem('menupermission')?JSON.parse(localStorage.getItem('menupermission')).teamsetup: null;
    
    console.log("this.usertype in teamsetup:::",this.userType);
    console.log("this.teamsetupPermission:::",this.teamsetupPermission);
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    if(this.userType=='advocate'||this.userType=='counsel'||this.userType=='directorlegal'||this.userType=='managingpartner'
       ||this.userType=='zonalmanager'){
      this.getUserList(this.userType,this.loginUserId);
    }else{
      this.getUserList(this.superUserType,this.superUserId);
    }
    this.getTeamMemberType(this.userType);

    this.selectedCountry = CountryISO[this.country.toString()] 
    this.orgDesignation='';
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    if(this.usertype === 'managingpartner'){
      this.pageTitle='Partner';
    }else{
      this.pageTitle='Manager';
    }
    console.log("this.usertype in teamsetup:::",this.usertype);
    if(this.usertype=='managingpartner'){
      this.DesignnationList=[{name:'Senior Partner',value:'seniorpartner'},{name:'Associate Partner',value:'associatepartner'}];
    }else{
      this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'}];
      // this.DesignnationList=[{name:'Zonal Manager',value:'zonalmanager'},{name:'Legal Manager',value:'legalmanager'},{name:'Assistant Legal Manager',value:'assistantlegalmanager'}];
    }
    
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.superUserId in teamsetup:::",this.superUserId);
    /* if(this.usertype=='advocate'||this.usertype=='counsel'||this.usertype=='directorlegal'||this.usertype=='managingpartner'){
      this.getUserList(this.usertype,this.loginUserId);
    }else{
      this.getUserList(this.usertype,this.superUserId);
    } */
    this.getTeamMemberType(this.usertype);
    this.createForm();

    this.legalService.getProfileData(this.loginUserId).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
      setTimeout( () => {
        this.saveMode=this.navigationLinkParameterService.saveMode;
        console.log(this.saveMode);
      }, 500 );


      this.inviteNewMemberForm = this.fb.group({
            teamUserType        : new FormControl(''),
            firstname       : new FormControl('', [Validators.required]),
            lastname       : new FormControl('', [Validators.required]),
            mailid          : new FormControl('', [Validators.required]),
            phoneno         : new FormControl('', [Validators.required]),


      });

      //this.submittedForinviteMember = false;


  }

  teamRegistration2(){
    
    let body:any;
    let countryCode='+44';
    this.submitted = true;
    if (this.myform.invalid) {
      return
    }
    console.log("user type in team registration::", this.userType);
    if(this.mailid==''||this.mailid==undefined) {
      // window.alert("Please give a valid mail id.")
    }else if(this.firstname==''||this.firstname==undefined){
      // window.alert("Please give a valid name.")
    }
    else{
      let phno = this.myform.value.phoneno.number.replace(/\s/g, "")
      phno =  phno.replace(/-/g, "");
      body = {
              "emailId": this.mailid,
              "countryCode":this.myform.value.phoneno.dialCode,
              "phoneNo": phno,
              "userTitle" : null,
              "firstName": this.firstname,
              "middleName": null,
              "lastName" : this.lastname,
              // "userType": this.userType,
              "loginUserId" : this.superUserId,
              // "userId":this.superUserId,
              "subscriptionPlanId": null,
              'userType':this.orgDesignation,
              'superUserType':this.usertype,
              'orgId':this.dataArr.orgId,
              'calltype':'invite'
            }
            console.log(body);
            // return false;
    // this.legalService.registration(this.mailid,this.phoneno,'',this.firstname,'','',this.userType).subscribe((res: any) => {
      this.legalService.registrationNew(body).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      let ress: any = JSON.parse(res._body);
      console.log(ress.responseMessage);
      let userId = { 'userId': ress.respData };
      this.mes = ress.responseMessage;
      this.mes1 = '';
      this.viewMode = true;

      // $("#myModal").modal("hide");
      // setTimeout( () => {
      //   location.reload();
      // }, 2000 );

      this.getUserList(this.usertype,this.superUserId);
      this.toastr.success(this.mes, '');
      this.myform.reset();
      $('#myModal1').modal('hide');
      
      //this.mailsent = false;
      //window.alert(ress.responseMessage);
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.myform.reset();
      this.toastr.error(this.mes1, '');
      $('#myModal1').modal('hide');
      this.mes = '';
      // this.mailsent = false;
      //window.alert(erro.responseMessage);
    });
   }
  }

  

  teamRegistration(){
      
      // if(this.mailid==''||this.mailid===undefined) {
      //     window.alert("Please give a valid mail id.")
      // }else if(this.firstname==''||this.firstname===undefined){
      //     window.alert("Please give a valid name.")
      // }
      // // else{

          // return false;
          this.markFormGroupTouched(this.inviteNewMemberForm);
          if(this.inviteNewMemberForm.valid) {
              this.submittedForinviteMember = true;
              this.mailid       = this.inviteNewMemberForm.value.mailid;
              this.phoneno      = this.inviteNewMemberForm.value.phoneno.number.replace(/\s/g, "");
             
              this.phoneno =  this.phoneno.replace(/-/g, "");
              this.teamUserType     = this.inviteNewMemberForm.value.teamUserType;
              this.firstname    = this.inviteNewMemberForm.value.firstname;
              this.lastname    = this.inviteNewMemberForm.value.lastname;
              this.countryCodeMobilePh = this.inviteNewMemberForm.value.phoneno.dialCode
                //when user type is clerk/accountant,no provision for team seatup,only for super user save method is called and login usertype is passes as a super usertype.
                this.legalService.registration(this.mailid,this.phoneno,'',this.firstname,'',this.lastname,this.teamUserType,"invite",this.userType,null, this.countryCodeMobilePh).subscribe((res: any) => {

                // this.mes= res.responseMessage;
                let ress: any = JSON.parse(res._body);
                console.log("registration response message",ress.responseMessage);
                let userId = { "userId": ress.respData };
                this.mes = ress.responseMessage;
                this.toastr.success('TEAM MEMBER INVITED SUCCESSFULLY', '');
                this.mes1 = '';
                this.viewMode = true;
                //this.getUserList(this.teamUserType,this.superUserId);
                //this.mailsent = false;
                //window.alert(ress.responseMessage);
                setTimeout( () => {
                  location.reload();
                }, 2000 );
              }, (err: any) => {
                let erro: any = JSON.parse(err._body);
                this.mes1 = erro.responseMessage;
                this.toastr.error(this.mes1, '');
                this.mes = '';
                //this.mailsent = false;
                //window.alert(erro.responseMessage);
              });

        }else{
          this.submittedForinviteMember = false;
        }
      // }
  }
  getUserList( userType : string,superUserId : string) { 
    this.legalService.getTeamList(userType,superUserId).
      subscribe(
        data => {
          let userlistarray = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
          for(var user of userlistarray){
            if(user.activeAccount=='Y'){
              user.activeAccount ='Yes';
            }else if(user.activeAccount=='N'){
              user.activeAccount ='No';
            }
            if(user.invitationSent=='Y'){
              user.invitationSent ='Yes';
            }else if(user.invitationSent=='N'){
              user.invitationSent ='No';
            }
            
            if(this.userType == 'managingpartner'){
              this.mergeUserList.push(user);
              
            }else{
              this.userlist.push(user);
            }
            console.log("get user comp res--" + JSON.stringify(user.activeAccount));
          }
          if(this.userType == 'managingpartner'){
          this.getUserListLawFarm();
          }

          
          //let invitationSent = 
           
        }
      );
  }

  getUserListLawFarm(){
    this.legalService.getInviteMemberList(this.userType, this.loginUserId).
    subscribe(
      data => {
        let userlistarray = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamList comp res--" + JSON.stringify(this.userlist));
          for(var user of userlistarray){
            if(user.activeAccount=='Y'){
              user.activeAccount ='Yes';
            }else if(user.activeAccount=='N'){
              user.activeAccount ='No';
            }
            if(user.invitationSent=='Y'){
              user.invitationSent ='Yes';
            }else if(user.invitationSent=='N'){
              user.invitationSent ='No';
            }
            this.userlistLawFarm.push(user)
          }
          
        //this.userlistLawFarm = JSON.parse(this.legalService.userData._body).respData;
        this.userlist = [ ...this.userlistLawFarm, ...this.mergeUserList];
        console.log("getTeamList comp res--" + JSON.stringify(this.userlist)); 
      }
    );
  }

  getTeamMemberType( userType : string) {
    this.legalService.getTeamMemberType(userType).
      subscribe(
        data => {
          this.memberTypelist = JSON.parse(this.legalService.userData._body).respData;
          console.log("getTeamMemberType comp res--" + JSON.stringify(this.memberTypelist)); 
        }
      );
  }

  modalclose(){
    //this.viewMode = false;
    this.inviteNewMemberForm.reset();
    console.log("view mode::",this.viewMode);
  }

  onCountryCodeMobilePhChange(selectedCountryCode) {
    this.countryCodeMobilePh = selectedCountryCode;
  }

  private markFormGroupTouched( formGroup: FormGroup ) {
    ( <any>Object ).values( formGroup.controls ).forEach( control => {
      control.markAsTouched();

      if ( control.controls ) {
        this.markFormGroupTouched( control );
      }
    } );
  }

  checkUserCount(flag){
    var userType
    console.log("FLAG => "+flag);
    if(this.superUserType){
      userType = this.superUserType;
    }else{
      userType = this.userType;
    }
    this.legalService.checkNoOfUser(userType,this.superUserId,"N").
      subscribe(
        data => {
          console.log(data)
          
         
           if(data.searchCount  == 2){
            this.toastr.error('To add more user please upgrade your plan', '');
           }else{
             if(flag == 1) {
              $('#myModal').modal('show');   
             }
             if(flag == 2) {
              $('#myModal1').modal('show');   
             }
            
           }
          
        }
      );
  }
}