import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LegalbellService } from '../service/legalbell.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  public payuform: any = {};
  dataArr: {};
  userId : string;
  selectedPlan :string;
  useremail: string;
  contactNo: string;
  firstName: string;
  planAmount : string;
  planLists : any = [];
  planType : string   = "";
  userType : string;
  planName : string;
  selectedPlanType:string;
  productinfo : string = 'elegxiam';
  disablePaymentButton: boolean = true;
  mydata: any;
  payuUrl:string = environment.payuUrl;
  planId :string;

  constructor(private http: HttpClient,private legalbellService: LegalbellService,) { 
    this.selectedPlanType = localStorage.getItem('isSubscriptionValid');
  }

  confirmPayment() {
    const paymentPayload = {
      email: this.useremail,
      name: this.firstName,
      phone: this.contactNo,
      productInfo: this.productinfo,
      amount: this.planAmount,
      txnId:'',
      hash:'',
      sUrl:'',
      fUrl:'',
      key:'',
      userId:this.userId,
      subscriptionPlanId:this.planId
    }
    this.legalbellService.savePaymentData(paymentPayload).subscribe(
    //return this.http.post<any>('http://localhost:8083/payment/payment-details', paymentPayload).subscribe(
      data => {
      console.log(data);
      this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
      this.payuform.txnid = this.mydata.txnId;
      this.payuform.surl = this.mydata.sUrl;
      this.payuform.furl = this.mydata.fUrl;
      this.payuform.key = this.mydata.key;
      this.payuform.hash = this.mydata.hash;
      this.payuform.txnid = this.mydata.txnId;
      this.payuform.service_provider = "payu_paisa";

      this.disablePaymentButton = false;
    }, error1 => {
        console.log(error1);
      })
  }

  ngOnInit() {
    this.userType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    let userId = localStorage.getItem( 'profileInfo' ) ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
    this.userId   = userId;
    this.getProfileData( userId );
    this.getSubscriptionPlan();
  }

  //get profile data
  getProfileData(userid: string) {
    console.log("userid in getProfileData--" + userid);
    this.legalbellService.getProfileData(userid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalbellService.userData._body);
          this.dataArr = JSON.parse(this.legalbellService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
            this.firstName = this.dataArr['firstName'];
            this.useremail = this.dataArr['emailId'];
            this.contactNo = this.dataArr['phoneNo'];
            //this.createForm();
          } else {
            //this.createForm();
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr['firstName']));
        }
      );
  }
  getSubscriptionPlan(){
    { 
      
        
          this.legalbellService.getSubscriptionPlanList(this.userType,this.userId).
          subscribe(
            data => {
                // let res = JSON.parse( this.legalService.userData._body );
               // this.planLists  = JSON.parse( this.legalbellService.userData._body ).respData;
                
                  this.selectedPlan   = JSON.parse( this.legalbellService.userData._body ).respData[0].planId;
                  //this.selectedPlanType = JSON.parse( this.legalbellService.userData._body ).respData[0].planType;
                  //this.planAmount = JSON.parse( this.legalbellService.userData._body ).respData[0].price;
                
                
            })
        
      
      this.legalbellService.getSubscriptionPlan(this.userType).
      subscribe(
        data => {
            // let res = JSON.parse( this.legalService.userData._body );
            this.planLists  = JSON.parse( this.legalbellService.userData._body ).respData;
            if(this.planLists.length > 0){
              this.planId   = this.planLists[0].planId;
              this.planType = this.planLists[0].planType;
              this.planAmount = this.planLists[0].price;
            }else{
              this.planId   = "";
            }
            
        })
    }
  }
  //########### On Select Plan Start Here #############
  onSelectPlanType(selectedPlan:string,selectedPlanType:string,selectedPlanAmount:string){
    this.planId = selectedPlan;
    this.planType = selectedPlanType;
    this.planAmount = selectedPlanAmount;
    console.log(this.planType);
    }
    //########### On Select Plan Ends  Here #############
    
}


