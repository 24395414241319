import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
//import { config } from './../config';
//import { config } from '../';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

const httpOptions={
  headers: new Headers( {
    'Content-Type': 'application/json',
    'x-tag': '1'

  } )
};


@Injectable( {
  providedIn: 'root'
} )

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor( private http: Http ) { }

  userData: any;

  getUserData(superUserId) {
    return this.http.get( environment.api+'user/getAllClients?superUserId='+superUserId, {} ).pipe( map( res => {
      let resData=res.json();
      return resData;
    } ) );
  }
  
  getMemberListbyType( membertype : string, superUserId :string) {
    return this.http.post( environment.api+'user/getTeamByType', {
      userType    : membertype,
      superUserId : superUserId
    } ).pipe( map( res => {
      let resData=res.json()
      return resData;
    } ) );

    // return this.http.post(environment.api + 'master/getDistricts', {
    //   "stateId": stateId
    // }).
    //   pipe(map(res => { res.json(), this.userData = res }));
  }

  getStageofcontract() {
    return this.http.get( environment.api+'master/getContractManagementStages', {} ).pipe( map( res => {
      let resData=res.json();
      return resData;
    } ) );
  }

  savecontractData( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveContract',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  updatecontractData( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveContract',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  postUploadcontractFile( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/uploadDoc',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  postFile( fileToUpload: File, jsonData ): Observable<boolean> {

    var headers=new Headers();
    const formData: FormData=new FormData();
    formData.append( 'uploadFile', fileToUpload, fileToUpload.name );
    formData.append( 'clientUserId', jsonData.userId );
    formData.append( 'contractNo', jsonData.contractNo );
    formData.append( 'docType', jsonData.docType );
    formData.append( 'loginUserId', jsonData.loginUserId );
    
    return this.http.post( environment.api+'contract/uploadContractDoc', formData, {
      headers: headers
    } ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  uploadContractInfo( fileToUpload: File, jsonData ): Observable<boolean> {

    var headers=new Headers();
    const formData: FormData=new FormData();
    formData.append( 'uploadFile', fileToUpload, fileToUpload.name );
    formData.append( 'clientUserId', jsonData.userId );
    formData.append( 'contractNo', jsonData.contractNo );
    formData.append( 'docType', jsonData.docType );
    formData.append( 'loginUserId', jsonData.loginUserId );
    
    return this.http.post( environment.api+'contract/uploadContractInfo', formData, {
      headers: headers
    } ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  contractDetails( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/getContractData',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  partyDetails( payload: any ) {
    console.log( "savepartyDetails service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/PartiesList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  groupByArrayKey( array, key ) {
    // Accepts the array and key
    const groupBy=( array, key ) => {
      // Return the end result
      return array.reduce( ( result, currentValue ) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        ( result[currentValue[key]]=result[currentValue[key]]||[] ).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {} ); // empty object is the initial value for result object
    };

    // Group by color as key to the person array
    return groupBy( array, key );
  }

  saveConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  conversationList( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/conversationList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  //############ Get Conversation List Start Here
  getConversationList( payload: any ) {

        console.log( "saveAllData service", payload );
        var json  = payload;
        console.log( payload );
        var headers=new Headers();
        headers.append( 'Content-type', 'application/json' );
        return this.http.post( environment.api+'contract/getMyConversationList',
          json, {
            headers: headers
        }
        ).pipe( map( res => {
            console.log( "Serv response--"+res );
            return res.json();
        } ) );
  }
  //############ Get Conversation List Ends Here

  //############ Get Add Conversation To Lists Start Here
  getcontractToUserList( payload: any ) {

    console.log( "saveAllData service", payload );
    var json  = payload;
    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/toUserList',
      json, {
        headers: headers
    }
    ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
    } ) );
}
//############ Get Add Conversation To Lists Ends Here

  getSingleConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/getSingleConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  deleteSingleConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/deleteSingleConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  removeDoc( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/removeContractDoc',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  removeContractInfo( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/removeContractInfo',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveMemberPermission( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveMemberPermission',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  memberList( payload: any ) {
    console.log( "memberList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/memberList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  updatePartyData( payload: any ) {
    console.log( "updatePartyData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveParties',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  //get state list 
  getSateList() {

    return this.http.get(environment.api + 'master/getStates', {
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }
 //get state list 
 getContractTypeList() {

  return this.http.get(environment.api + 'master/getContractManagementTypes', {
  }).
    pipe(map(res => { res.json(), this.userData = res }));
}

  
  //get district list 
  getDistrictList(stateId: string) {

    return this.http.post(environment.api + 'master/getDistricts', {
      "stateId": stateId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get court list 
  getCourtList(stateId: string,districtId: string = "") {

    return this.http.post(environment.api + 'master/getDistrictCourts', {
      "districtId" : districtId,
      "stateId" : stateId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  memberPermissionList( payload: any ) {
    console.log( "memberPermissionList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/getMemberPermissionIncontract',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  //########### Remove Member Permissinn Start Here
  removeMemberPermission(payload : any){
      var json  = payload;
      // console.log(payload);
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api+'contract/deleteSingleMemberPermission',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
  }
  //########### Remove Member Permissinn Ends Here

 /*  getContractNumber() {

    return this.http.get(environment.api + 'contract/getContactManagementNo', {
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  } */

  getContractNumber() {
    return this.http.get( environment.api+'contract/getContactManagementNo', {} ).pipe( map( res => {
      let resData=res.json();
      return resData;
    } ) );
  }
  
}
