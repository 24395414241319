import { Component, OnInit, NgModule, PipeTransform, Pipe } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { LegalbellService } from '../service/legalbell.service';
import { DatePipe } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';

import { ToastrService } from 'ngx-toastr';


// @Pipe({
//     name: 'dateFormat'
//   })
  // export class DateFormatPipe extends DatePipe implements PipeTransform {
  //   transform(value: any, format: any): any {
  //      return super.transform(value, format);
  //   }
  // }
@Component({
    selector: 'app-overheadexpenses',
    templateUrl: './overheadexpenses.component.html',
    styleUrls: ['./overheadexpenses.component.css'],
  })
  
export class OverheadexpensesComponent implements OnInit {

  myform: FormGroup;
  modalDisplay: boolean = true;
  fareaDisplay: boolean = false;
  loader:boolean = false
  resultShow: any;
  statusCode: any;
  btnShow: any;
  logResult: any;
  mydata: any;
  resultBtnShow: boolean = false;
  caselist : any[] = [];
  caseNo : string;
  clientUserId : string;
  clientName : string;
  particulars : string;
  particularsId : string;
  amount : number;
  show = true;
  particularList:any[]=[];
  mes='';
  mes1='';
  public todayDate:any = new Date();
  // reminderDateTimeString: number;

  pipe = new DatePipe('en-US');
  now = Date.now();
  expenselist:FormArray;
    
  // mySimpleFormat = this.pipe.transform(this.now, 'MM/dd/yyyy');
  // myShortFormat = this.pipe.transform(this.now, 'short');


  constructor(private legalbellService: LegalbellService,private router: Router,private route: ActivatedRoute,public fb: FormBuilder, private toastr: ToastrService,) {
   
    this.myform = this.fb.group({
      expensesArr: new FormArray([])
   })}
   userId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).userIdlogin : null;
   emailId = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).emailId : null;
   profilestatus = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).profilestatus : null;
   usertype = localStorage.getItem('profileInfo') ? JSON.parse(localStorage.getItem('profileInfo')).usertype : null;
    ngOnInit(): void {
    //console.log('calling-------------------');
    // this.clientExpenseMaster();
    this.loader = true;
    this.mes1='';
    this.mes='';
    this.getAllCaseList();
    this.getfinanceOverheadExpense();
    if(this.usertype==='advocate' || this.usertype==='counsel' || this.usertype==='managingpartner' ||this.usertype==='directorlegal'){
      this.show=true;
    }else{
      this.show=false;
    }
  }
  
  //this method is used fetch all the saved client master expenses first time,before chossing any case
  getMasterOverheadExpense() {
    
    let requestdata = {};

    this.legalbellService.getMasterOverheadExpense(JSON.stringify(requestdata)).subscribe( (data) => {
      //console.log('clientExpenseMaster response', data);
      if (data.responseMessage == 'success') {
        //console.log(data.respData);

        const res = data.respData;
        //console.log('res in clientExpenseMaster', res);
      //   this.myform = this.fb.group({
      //     expensesArr: new FormArray([])
      //  });
      //   this.expenselist = new FormArray([
      //       new FormControl(),
      //       new FormControl()
      //    ]);
        if (res.length > 0) {
           res.map((chrd) => {
            // this.expenselist = <FormArray>this.myform.get('expensesArr');
            this.expenselist.push(new FormGroup({
              id : new FormControl(chrd.id),
              createdBy: new FormControl(this.emailId),
              updatedBy: new FormControl(this.emailId), 
              particularsId: new FormControl(chrd.id),
              superUserId: new FormControl(this.userId),
              userType: new FormControl(this.usertype),
              // caseNo : new FormControl(this.caseNo),
              amount : new FormControl(0),
              particularName : new FormControl(chrd.particulars),
              reminderDateTimeString : new FormControl('')
            }))
            
          });
         
           //console.log("my form",this.myform.get('expensesArr'));


        }

      }
      this.loader = false
    }, (err: any) => {
      this.loader = false
      //console.log(err);
    }, () => {
     
    }
    );
  }

  clientExpenseCasespecific() {
    let requestdata = {"orgId" :  "ORG0001",
    "clientUserId" : this.clientUserId,
    "caseNo" : this.caseNo}

    this.legalbellService.clientExpenseCaseSpecific(JSON.stringify(requestdata)).subscribe( (data) => {
      //console.log('clientExpenseCasespecific response', data);
      if (data.responseMessage == 'success') {
        //console.log(data.respData);

        const res = data.respData;
        //console.log('res length in clientExpenseCasespecific', res.length);
        this.myform = this.fb.group({
          expensesArr: new FormArray([])
       });
       
        if (res.length > 0) {
  
          res.map((chrd) => {
            
            const expenselistcasespecific = <FormArray>this.myform.get('expensesArr');
            expenselistcasespecific.push(new FormGroup({
              id : new FormControl(chrd.id),
              createdBy: new FormControl(chrd.createdBy),
              updatedBy: new FormControl(chrd.updatedAt), 
              particularsId: new FormControl(chrd.id),
              superUserId: new FormControl(this.clientUserId),
              caseNo : new FormControl(this.caseNo),
              amount : new FormControl(chrd.amount),
              particularName : new FormControl(chrd.particulars),
            }))
            
          });
         
          //console.log("my form in clientExpenseCasespecific",this.myform.get('expensesArr'));

        }else{
          // this.clientExpenseMaster();
        }

        

      }
    }, (err: any) => {
      //console.log(err);
    }, () => {
     
    }
    );
  }

  saveAllData() {
    this.particularList=[];
    this.mes1='';
    this.mes='';
    //console.log('SAVEALLDATA');
    //console.log(JSON.stringify(this.myform.get('expensesArr').value));
    //console.log(this.myform.get('expensesArr').value);
   
    
    for(let i = 0; i <(this.myform.get('expensesArr').value).length; i++){
    if(this.myform.get('expensesArr').value[i]['reminderDateTimeString']!='null' && this.myform.get('expensesArr').value[i]['reminderDateTimeString']!='' && this.myform.get('expensesArr').value[i]['reminderDateTimeString']!=null){
      this.myform.get('expensesArr').value[i]['reminderDateTimeString']=this.pipe.transform(this.myform.get('expensesArr').value[i]['reminderDateTimeString'], 'yyyy-MM-dd H:m:s');
    }else{
      this.myform.get('expensesArr').value[i]['reminderDateTimeString']='';
    }
  }
  console.log(this.myform.get('expensesArr').value);
  for(var j=0;j<(this.myform.get('expensesArr').value).length;j++){
    console.log(j);
    console.log(this.myform.get('expensesArr').value[j]['particularName']);
    if(this.myform.get('expensesArr').value[j]['particularName']!=''){
      if(this.particularList.includes(this.myform.get('expensesArr').value[j]['particularName'])== false){
        this.particularList.push(this.myform.get('expensesArr').value[j]['particularName']);
      }else{
        //  alert('Particulars name can not be duplicate.');
        this.mes="Particulars name can not be duplicate.";
        this.toastr.error(this.mes)
        return false;
      }
    }else{
      this.mes="Particulars name can not be blank.";
      this.toastr.error(this.mes)
        return false;
    }
  }
  this.loader = true
    //  //console.log(convert("Fri Aug 21 2020 02:33:20 GMT+0530 (India Standard Time"));
     //console.log(this.myform.get('expensesArr').value);
    this.legalbellService.saveOverheadExpensesData(JSON.stringify(this.myform.get('expensesArr').value)).
        subscribe(
          (data) => {
            this.mydata = JSON.parse(this.legalbellService.saveResponse._body);
            if (this.mydata.status == '200') {
              this.getfinanceOverheadExpense();
              this.resultShow = 'Execution Result : ';
              this.btnShow = '(Details)';
              this.statusCode = "Success";
              this.mes1="Overhead Expense data updated successfully";
              this.toastr.success(this.mes1, '');
              this.particularList=[];
              // window.location.reload();
            }
            else {
              this.statusCode = "Fail";
              this.toastr.error("Overhead Expense data updated unsuccessfull", '');
            }
          }, (err: any) => {
            //console.log(err);
            this.toastr.error("Overhead Expense data updated unsuccessfull", '');
          }, () => {
            // this.modalDisplay = !this.modalDisplay;
          }
        );
    // this.modalDisplay = false;

  }
 //Show output
 showOutput() {
   this.resultBtnShow = !this.resultBtnShow;
 }
  abc() {
    //console.log(this.myform);
  }

  getfinanceOverheadExpense() {
    
    //console.log(this.userId);
    let requestdata ={
      "superUserId" : this.userId
      }

    this.legalbellService.getfinanceOverheadExpense(JSON.stringify(requestdata)).
      subscribe(
        data => {
          if (data.responseMessage == 'success') {
            //console.log(data.respData);
    
            const res = data.respData;
            //console.log('res in finance', res);
                this.myform = this.fb.group({
          expensesArr: new FormArray([])
          
            });
            this.expenselist = <FormArray>this.myform.get('expensesArr');
            
            if (res.length > 0) {
               res.map((chrd) => {
                 console.log('chrd',chrd);
                 //console.log(chrd.orgId);

                 let org = chrd.reminderDateTime;
                 console.log('org', org);
                 let newstr;
                 let re ='Z';
                 if(org!= null && org!='' && org!="null") {
                  newstr = org.slice(0,22);  
                 }
                 else {
                  newstr = '';
                 }
                 console.log(newstr);
               this.expenselist.push(new FormGroup({
                  id : new FormControl(chrd.id),
                  createdBy: new FormControl(this.emailId),
                  updatedBy: new FormControl(this.emailId), 
                  particularsId: new FormControl(chrd.particularsId),
                  superUserId: new FormControl(this.userId),
                  userType: new FormControl(this.usertype),
                  amount : new FormControl(chrd.amount),
                  particularName : new FormControl(chrd.particularName),
                  reminderDateTimeString : new FormControl(newstr) 
                  
                }))
                this.loader = false
              });
                  //console.log("my form",this.myform.get('expensesArr'));
            }
            else{
              this.getMasterOverheadExpense();
            }
    
          }
        }
      );
  }
 
addNewExpense(){
  this.expenselist.insert(0,new FormGroup({
    // id : new FormControl(''),
    createdBy: new FormControl(this.emailId),
    updatedBy: new FormControl(this.emailId), 
    particularsId: new FormControl(0),
    superUserId: new FormControl(this.userId),
    userType: new FormControl(this.usertype),
    amount : new FormControl(0),
    particularName : new FormControl(''),
    reminderDateTimeString : new FormControl('') 
  }))
}
sendit(val){
  //console.log(val);
  //console.log(this.myform.get('expensesArr'));
}
  //this method is used to get all the cases
getAllCaseList() {
    this.legalbellService.getAllCaseList(this.userId).
      subscribe(
        data => {
          this.caselist=JSON.parse( this.legalbellService.userData._body ).respData;
          // //console.log( "caselist comp res--"+JSON.stringify( this.caselist ) );
        }
      );
  }
//this method is used to get all the information depend on case and set case specific form value
onSelectCase(causeTitle) {
    //console.log("onSelectCase--",causeTitle);
    let caseNewList : any[] = [];
    caseNewList = this.caselist.filter((item) => item.caseTitle == causeTitle);
    this.caseNo = caseNewList[0].caseNo;
    //console.log('onSelectCase ====== ',this.caseNo+"amount"+this.amount + "particulars"+this.particulars);
    this.clientName = caseNewList[0].userName;
    this.clientUserId = caseNewList[0].userId;
     
    
    this.myform.get('expensesArr')['controls'].forEach(element => {
      //console.log("expensesArr form control",element);
      element.controls['clientUserId'].setValue(this.clientUserId);
      element.controls['caseNo'].setValue(this.caseNo);
    });

    //this form value is set only when the user select a case and fetch the data saved for this case
    this.clientExpenseCasespecific();
    
  }
}
