import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { Router,NavigationEnd } from '@angular/router';
//import {PharasalsearchComponent} from '../../pharasalsearch/pharasalsearch.component'
import { LegalbellService } from '../../service/legalbell.service';
import { MessageService } from '../../service/message.service';
import { NavigationLinkParameterService } from '../../service/navigation-link-parameters-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mainsidebar',
  templateUrl: './mainsidebar.component.html',
  styleUrls: ['./mainsidebar.component.css']
})
export class MainsidebarComponent implements OnInit {

  showMenu: boolean = true;
  subscription: Subscription;
  
  isDashboard : boolean = false;
  
  downloadBaseUrl: any;
  profilestatus : string;
  usertype : string;
  loginuserid : string;
  menuList:any[]=[];
  menuDetails:any[]=[];
  saveMode: boolean = true;
  isSubscriptionValid:string;
  constructor(private router: Router,private LegalbellService: LegalbellService, private dataService: MessageService, private NavigationLinkParameterService: NavigationLinkParameterService ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
      //////console.log("router: ", event.url);
      
      if(event.url == event.urlAfterRedirects){
		
			if(event.url == '/' || event.url == '/login'){
				this.showMenu = false;
			} else {
				this.showMenu = true;
			}
		} else {
			this.showMenu = false;
		}
      
      });
   }
  

  ngOnInit() {
    
    this.downloadBaseUrl = environment.api;

    // this.subscription = this.dataService.getMessage().subscribe(message => {
    //   ////console.log("privilegeuser in sidebar")
    //   if (message.event == 'privilegeuser') {
    //     this.getPrivilegeUser('');
    //   }
    // });
    // this.getPrivilegeUser('');
    console.log(localStorage.getItem('isSubscriptionValid'))
    this.isSubscriptionValid = localStorage.getItem('isSubscriptionValid')
    this.profilestatus = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).profilestatus: null;
    console.log(this.profilestatus)
    ////console.log("Profile status in dashboard::",this.profilestatus);
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    
    this.loginuserid = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    this.getDynamicMenu();
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      trees.tree();
    });
  }
  getDynamicMenu(){
    const requestdata = {
      'userType': this.usertype,
       'profileStatus': this.profilestatus
      }

    this.LegalbellService.getDynamicMenu(JSON.stringify(requestdata)).subscribe( (data) => {
      ////console.log(data);
      if (data.responseMessage == 'success') {
        ////console.log(data.respData);

        const res = data.respData;
        this.menuDetails=data.respData;
        // res.forEach(function(item){  
        //   //console.log(item);
        //   this.menuDetails.push(item);
        // });
        //console.log(this.menuDetails);
        for(let i=0;i<res.length;i++){
          console.log(res[i].routerLink);
          if(res[i].routerLink ==null){
              res[i].routerLink="[/]";
          }
          res[i].routerLink = res[i].routerLink.substr(2,(res[i].routerLink.length - 4));
          if(this.menuList.includes(res[i])== false && res[i]['slNoSub'] ==  null){
              // this.menuList.push(res[i]['menuName']);
              res[i].subMenuList = [];
              this.menuList.push(res[i]);
          }
          // if(this.menuList.includes(res[i]['subMenuName'])== false && res[i]['subMenuName']!='' && res[i]['subMenuName']!=null){
          //   this.menuList.push(res[i]['subMenuName']);
          // }
          if(this.menuList.includes(res[i]['subMenuName'])== false && res[i]['subMenuName']!='' && res[i]['subMenuName']!=null){

            let isParentExists = 0;
            // //console.log(this.menuList.length);
            for(let menuIndex = 0; menuIndex < this.menuList.length; menuIndex++){
                  let menuItem = this.menuList[menuIndex];
                  if(menuItem.menuName == res[i]['menuName']){
                        // //console.log(menuItem,menuIndex);
                        // //console.log(res[i]);
                        this.menuList[menuIndex].subMenuList.push(res[i]);
                        // //console.log(this.menuList[menuIndex]);
                        isParentExists = 1;
                  }
            }

            if(isParentExists == 0){
                  let subMenuArr = {menuName:res[i]['menuName'], slNo:res[i]['slNo'], subMenuList: [res[i]]};
                  this.menuList.push(subMenuArr);
            }
            
          }
          if(res[i].menuName=='MY TEAM'){
            //console.log("privilege for team setup::",res[i].privilege);
            let permission = { "teamsetup": res[i].privilege};
            localStorage.setItem('menupermission',JSON.stringify(permission));
          }

          if(res[i].subMenuName=='Service Master'){
            localStorage.setItem('servicemasterpermission',res[i].privilege);
          }
          if(res[i].subMenuName=='Client Expenses'){
            localStorage.setItem('clientexpensepermission',res[i].privilege);
          }
          if(res[i].menuName=='Task Management'){
            localStorage.setItem('taskmanagementpermission',res[i].privilege);
          }
          if(res[i].menuName=='CLIENT ROSTER'){
            localStorage.setItem('clientrosterpermission',res[i].privilege);
          }
          if(res[i].menuName=='CASE VIEW'){
            localStorage.setItem('caseviewpermission',res[i].privilege);
            console.log("case view menu");
          }
          if(res[i].menuName=='CONTRACT VIEW'){
            localStorage.setItem('contractpermission',res[i].privilege);
            console.log("contract view menu");
          }

        }
         console.log(this.menuList);
      }
    });
  }
  check(val){
    if(this.menuList.includes(val)==true){
      return true;
    }else{
      return false;
    }

  }
  logout(){
    ////console.log("remove::");
    localStorage.removeItem("profileInfo");
    window.location.href='/login';
    //window.open('/login');
  }
  priviledge(status, link){

    console.log("link=============", link);
    localStorage.setItem("isSetContractNO","")
    //console.log(status);
    if(status === 'save'){
      this.NavigationLinkParameterService.saveMode = true;
    }else{
      this.NavigationLinkParameterService.saveMode = false;
    }
    if(link == '/lawyerprofile' && (this.usertype == 'individualclient' || this.usertype == 'corporateclient')){    
      this.router.navigate(['/clientprofile',this.loginuserid,this.usertype]);
    }
    if (link == "/tasklist',loginuseri") {
      this.router.navigate(['/tasklist']);
    }
  }
  
}


