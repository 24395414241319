import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { Http, Headers } from '@angular/http';

import { ContractService } from '../service/contract.service';

import { Subject } from 'rxjs';

declare var $: any;
declare var jQuery: any;

import { HttpClient, HttpResponse } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';

import { ToastrService } from 'ngx-toastr';





@Component({
  selector: 'app-contractcreation',
  templateUrl: './contractcreation.component.html',
  styleUrls: ['./contractcreation.component.css']
})
export class ContractcreationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  assignTeamForm: FormGroup;
  //partyForm: FormGroup;
  //TeamForm: FormArray;

  assignTeamEditForm: FormGroup;
  TeamEditForm: FormArray;
  clientUserId: string;

  //empForm: FormGroup;



  insChatForm: FormGroup;
  contractForm: FormGroup;
  contractDetailForm: FormGroup;
  hearingDtlVo: FormArray;
  //partyDtlVo: FormArray ;
  submittedForm: boolean;
  subInsChatForm: boolean = false;
  subAssignTeamForm: boolean = false;
  modalDisplay: boolean = false;
  insFormModal: boolean = false;
  stages: any = [];
  statelist: any = [];
  districtlist: any = [];
  courtlist: any = [];
  contractTypelist: any = [];
  contractdetailedit: boolean;
  stageId: string;
  memberNameList: any = [];
  timePeriod : string;
  nextDate : string;
  contractCourtUpdated: string;
  loader : boolean;
  contractTitleUpdated: string;
  contractDescUpdated: string;
  contractTypeUpdated: string;
  courtcontractNoUpdated : string;
  superUserId : string;
  termUploadPath :string;
  loginUserType     : string;
  termUploadDocname: string;
  contractNumber: string;

  contractFolderPermission :any = [{upload:false, view:false, delete: false},{upload:false, view:false, delete: false}];

 

  clientName: any = [
  ];

  instructionToList : any = [];

  contractType: any = [
  ];

  insMsgChatType: any = [
    { 'msgTypeId': 'query', 'contractType': 'Query' },
    { 'msgTypeId': 'reply', 'contractType': 'Reply' },
    { 'msgTypeId': 'instruction', 'contractType': 'Instruction' }
  ];

  memberName: any = [
    // { 'clientUserId': '1', 'clientName': 'User1' },
    // { 'clientUserId': '2', 'clientName': 'User2' }
  ];

  memberUser: any = [
    // { 'userId': '1', 'userName': 'User1' },
    // { 'userId': '2', 'userName': 'User2' }
  ];


  fileToUpload: File = null;
  contractNo: any;
  userId: any;
  docType: any;
  displaycontractDocument = 'none';

  showMsg: boolean = false;
  contractDtlSubmit : boolean = false;
  mes = '';
  mes1 = '';
  mes2 = '';
  mes3 = '';

  insChatId: any;

  url: any;
  btnSppiner: boolean = false;
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;
  disabled: boolean = false;
  contractDocList: any = [];
  contractHearingList: any = [];
  conversationList: any = [];
  memberList: any = [];
  assignTeamList: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  teamDtOptions: DataTables.Settings = {};
  teamTrigger: Subject<any> = new Subject();

  memberUserfn: any;
  memberUserln: any;
  memberUserType: any;


  

  constructor(private fb: FormBuilder,
    private contractService: ContractService,
    private toastr: ToastrService,
    private router: Router,
    
    private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    //this.contractNo="";
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip',
    };
    this.teamDtOptions = {
      ordering: false,
      searching: false,

      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lfBrtip'
    };

    localStorage.setItem("dtTrigger", "dtTrigger");
    localStorage.setItem("teamTrigger", "teamTrigger");

    if (localStorage.getItem('refresh')) {
      localStorage.removeItem("refresh");
      location.reload();
    };


    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }


    this.loginUserType    = localStorage.getItem('profileInfo') ? JSON.parse( localStorage.getItem( 'profileInfo' ) ).usertype:null;

    this.load();

    console.log("Route", this.router);
    // this.router.navigate(['/dashboard']);

  }
  //ng on init end
  ngAfterViewInit(): void {this.dtTrigger.next();}
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    localStorage.removeItem('currentcontractNo');
    this.dtTrigger.unsubscribe();
    this.teamTrigger.unsubscribe();
  }


  //load start
  async load() {

    this.getUserData();
    this.getStageofcontract();
    this.getContractType();
    this.url = this.router.url;
    const contractView = this.url.search("contract-view");
    const contractEdit = this.url.search("contract-edit");
    const contractcreation = this.url.search("createcontract");

    if (contractView == 1) {
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }

    if (contractEdit == 1) {
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }

    if (contractcreation == 1) {
      this.getContractNo();
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
    }

    console.log(this.url, this.viewMode, this.editMode, this.createMode);
    let userData: any = JSON.parse(localStorage.getItem('profileInfo'));
    this.userId = userData.userIdlogin;
    this.contractForm = this.fb.group({
      clientUserId: new FormControl(),
      contactNumberAsString: new FormControl(this.contractNumber),
      contractNo: new FormControl(this.contractNo?this.contractNo:null),
     // caseContractNo : new FormControl('', [Validators.required]),
      contractTitle: new FormControl('', [Validators.required]),
      contractTypeId: new FormControl('', [Validators.required]),
      contractDesc: new FormControl('', [Validators.required]),
      createdBy: new FormControl(this.userId),
      updatedBy: new FormControl(this.userId),
      superUserId:new FormControl(this.userId)
    });

    this.contractDetailForm = this.fb.group({
     
      contractNo: new FormControl(this.contractNo),
      stageId: new FormControl(''),
      dateOfExecutionStr: new FormControl(''),
      timePeriod: new FormControl(''),
      expiaryDateStr: new FormControl('')

    });
    this.insChatForm = this.fb.group({
      toUser: new FormControl('', [Validators.required]),
      msgType: new FormControl('', [Validators.required]),
      msgContent: new FormControl('', [Validators.required])
    });


    // this form is used to submit the data first time
    this.assignTeamForm = this.fb.group({
      TeamForms: this.fb.array([])
    })
    
    // this form is used to submit the data after edit
    this.assignTeamEditForm = this.fb.group({
      TeamEditForms: this.fb.array([]),
    })

    if (this.viewMode == true || this.editMode == true) {
      console.log("editMode in load::",this.editMode);
      this.displaycontractDocument = 'block';

      this.route.params.subscribe(params => {
        this.clientUserId = params['clientUserId'];
        this.contractNo = params['contractNo'];
      });
      
      await this.viewcontractDetails(this.contractNo);
      
      await this.memberPermission()

    }

    this.TeamForms().push(this.newTeams());
    //this.partyDtlVo().push(this.newParty());
    // this.teamPermissions( 0 ).push( this.newPermission() );
    // this.teamPermissions( 0 ).push( this.newPermission() );
    this.prmissionVal(0);
    //this.assignTeamList();

    // this.assignTeamEditList();


    if (this.createMode === true) {
      // $('.nav li').not('.active').addClass('disabled');
      // $('.nav li').not('.active').find('a').removeAttr("data-toggle");
      // $('.nav li').not('.active').find('a').removeAttr("href");

    } else {

      // $('.nav li').not('.active').addClass('disabled');
      // $('.nav li').not('.active').find('a').removeAttr("data-toggle");
      // $('.nav li').not('.active').find('a').removeAttr("href");


      // $(".nav-tabs a").click(function () {
      //   $(this).tab('show');
      // });

      // $('.nav-tabs a').on('shown.bs.tab', function (event) {
      //   var x = $(event.target).text(); // active tab
      //   localStorage.setItem('activeTab', x.toLowercontract());
      //   console.log(x)
      // });

      // var tabName = localStorage.getItem('activeTab');
      // if (tabName) {
      //   $('.nav-tabs a[href="#' + tabName + '"]').tab('show')
      // } else {
      //   $('.nav-tabs a:first').tab('show')
      // }
    }


    if(localStorage.getItem('contractCreateMsg')) {
        this.mes = localStorage.getItem('contractCreateMsg');
        localStorage.removeItem("contractCreateMsg");
    };

  }
  //load end

  openDialog(){
    this.submittedForm = true;
    if (this.contractForm.valid) {
    $('#saveIntent').modal('show');
    }
  }

  openNextTab(tab) {
    if(tab == 'tab_1'||tab =='tab_2'||tab == 'tab_3'){
    $('.nav li.active').next('li').removeClass('disabled');
    $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");

    $('.nav li.active').next('li').find('a').attr("href", "#" + tab);
    }

    $( '.nav-tabs a[href="#'+tab+'"]' ).tab( 'show' );

  }

  // convenience getters for easy access to form fields
  get f() { return this.contractForm.controls; }
  get d() { return this.contractDetailForm.controls; }
  get t() { return this.d.hearingDtlVo as FormArray; }

  
  contractConfirmation() {
        this.submittedForm = true;
        //if ( this.contractForm.invalid && this.hearingDtlVo.invalid )
        if (this.contractForm.invalid) {
          this.modalDisplay = false;
          // console.log("modalDisplay:::", this.modalDisplay);
          return;
        } else {
          this.modalDisplay = true;
          // console.log("modalDisplay:::", this.modalDisplay);
        }

        let formVal = this.contractForm.value;
        if (this.editMode) {
          formVal.contractNo = this.contractNo;
          this.contractUpdate(formVal);
        } else {
          this.contractCreate(formVal);
        }
  }

//this method is called for first part of contract creation
  contractSubmit() {
        this.submittedForm = true;
        let formVal = this.contractForm.value;
        console.log('contractSubmit', formVal);

        if (this.contractForm.invalid) {
          this.modalDisplay = false;
          return;
        } else {
          this.modalDisplay = true;
        }

        //this.userId=formVal.userId;


        console.log('contractsubmit form value::', formVal);

        if (this.editMode) {
          formVal.contractNo = this.contractNo;
          this.contractUpdate(formVal);
        } else {
          this.contractCreate(formVal);
        }

  }
 //this is called by clicking save contract details
  contractDetailSubmit() {
          this.submittedForm = true;
          let formVal = this.contractDetailForm.value;
          console.log('contractSubmit', formVal);
         
          formVal.userId = this.clientUserId;
          console.log("")
          formVal.dateOfExecutionStr = this.getFormattedDate(formVal.dateOfExecutionStr);
          formVal.expiaryDateStr = this.getFormattedDate(formVal.expiaryDateStr);
          formVal.stageId = formVal.stageId;
          formVal.timePeriod = formVal.timePeriod;
         
          formVal.contactNumberAsString 
          // This part start is for first part contract creation
          formVal.contractNo = this.contractNo;
          formVal.contractTitle = this.contractTitleUpdated;
          formVal.contractType = this.contractTypeUpdated;
          formVal.contractDesc = this.contractDescUpdated;
          
          formVal.contactNumberAsString = this.contractNumber;
          // end for first part contract cration
          console.log('after map', formVal);
          this.contractUpdate(formVal,1);

  }


  contractCreate(formVal) {
    this.contractService.savecontractData(JSON.stringify(formVal)).subscribe((data) => {
      console.log('savecontractData response', data);
      if (data.responseMessage == 'success') {
        this.displaycontractDocument = 'block';
        this.contractNo = data.respData.contractNo;
        this.clientUserId = data.respData.clientUserId;
        localStorage.setItem('currentcontractNo', this.contractNo); // Set contract number time of add contract
        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Contract created successfully. Please fill in contract details in the next section.';
        this.toastr.success(this.mes, '');
        localStorage.setItem("contractCreateMsg", "Contract created successfully. Please fill in contract details in the next section.");
        // this.router.navigate(['/dashboard']);
        
        this.contractdetailedit = true;
        this.createMode = false;
        this.editMode   = true;
        console.log("contractdetailedit::", this.contractdetailedit);
        this.viewcontractDetails(this.contractNo);

        setTimeout(()=>{
          this.router.navigate(['/contract-edit', this.clientUserId, this.contractNo]);
        },2000);

      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Contract Creation failed.';
      this.toastr.error(this.mes1, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }
  contractUpdate(formVal, isDetails = 0) {
    this.contractService.updatecontractData(JSON.stringify(formVal)).subscribe((data) => {
      console.log('updatecontractData response', data);
      if (data.responseMessage == 'success') {
        this.displaycontractDocument = 'block';
        this.contractNo = data.respData.contractNo;
        if(this.contractNo){
        // alert( 'contract updated successfully.' );
        }
        this.showMsg = true;
        if(isDetails == 1){
          this.mes2 = 'Contract updated successfully.';
          this.toastr.success(this.mes2, '');
        }else{  
          this.mes = 'Contract updated successfully.';
          this.toastr.success(this.mes, '');
        }
        
        this.mes3 = '';
        this.contractDtlSubmit = true;
        
        // setTimeout(()=>{
        //   location.reload();
        // },2000);
        this.viewcontractDetails(this.contractNo);

        
        // $('.nav li.active').next('li').removeClass('disabled');
        // $('.nav li.active').next('li').find('a').attr("data-toggle", "tab");
        // $('.nav li.active').next('li').find('a').attr("href", "#tab_3");
        // this.openNextTab('tab_3');

        // this.openNextTab('tab_2');
        // $('.assign_team_li').removeClass('disabled');
        // $('.assign_team_li').find('a').attr("data-toggle", "tab");
        // $('.assign_team_li').find('a').attr("href", "#tab_3");
      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes2 = '';
      this.mes3 = 'Contract Update failed.';
      this.toastr.error(this.mes3, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }
  
  onReset() {
    // reset whole form back to initial state
    this.submittedForm = false;
    this.contractForm.reset();
    this.t.clear();
  }

  onClear() {
    // clear errors and reset ticket fields
    this.submittedForm = false;
    this.t.reset();
  }

  OnInput(event: any) {
    var nextDate = this.getFormattedDate(this.contractDetailForm.value.nextDate);
    // console.log("next date in oninput::",nextDate);
    //this.contractDetailForm.patchValue({ 'nextDate': nextDate });
  }

  onChange(data) {
    // alert( "Triggered"+data );
    console.log("Triggered", data);
    var nextDate = this.getFormattedDate(this.contractDetailForm.value.nextDate);
    // console.log("next date in onChange::",nextDate);
    //this.contractDetailForm.patchValue({ 'nextDate': nextDate });
  }

  async getUserData() {
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    // console.log("this.superUserId in teamsetup:::",this.superUserId);
    this.contractService.getUserData(this.superUserId).
      subscribe(
        async data => {
          // console.log('getUserData', this.clientName,);
          this.clientName = await data.respData;
        }
      );
  }
  onselectmemberType(membertype) {
    // console.log('membertype', membertype);
    this.contractService.getMemberListbyType(membertype, this.userId).
      subscribe(
        async data => {
          this.memberNameList = await data.respData;
          // console.log('getMemberData', this.memberNameList);
        }
      );

  }

  async getStageofcontract() {
    this.contractService.getStageofcontract().
      subscribe(
        async data => {
          console.log('getStageofcontract', this.stages);
          this.stages = await data.respData;
        }
      );
  }

  getFormattedDate(fromDate) {
    if (fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + month + '-' + day;
    }

  }

  nextDateFormate(fromDate) {
    if (fromDate) {
    var date = new Date(fromDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return month + '-' + day + '-' + year;
    }

  }

//this method is called after first part of contract creation,as for contract detail updation need to send first part value also
//this method is also called first time loading
  async viewcontractDetails(contractno) {
    let data = { contractNo: contractno }

    this.contractService.contractDetails(JSON.stringify(data)).subscribe(async (data) => {
      // console.log('contractDetails response', data);
      if (data.responseMessage == 'success') {
        // console.log(data.respData);

        this.disabled = true;
        const res = data.respData;
        console.log("clientUserId")
        console.log(res.contractDtl.clientUserId)
        this.clientUserId = res.contractDtl.clientUserId
        this.contractForm.patchValue({ 'clientUserId': this.clientUserId });
        console.log("contract no in view contractdetails::",contractno);
        this.contractForm.patchValue({ 'contractNo': contractno });
        //first part of contract creation set values start
        this.contractTitleUpdated = res.contractDtl.contractTitle
        this.contractForm.patchValue({ 'contractTitle': this.contractTitleUpdated });

        this.contractDescUpdated = res.contractDtl.contractDesc
        this.contractForm.patchValue({ 'contractDesc': this.contractDescUpdated });

        this.contractTypeUpdated = res.contractDtl.contractTypeId
        this.contractForm.patchValue({ 'contractTypeId': this.contractTypeUpdated });

         this.contractNumber = res.contractDtl.contactNumberAsString
         console.log("Enter contactNumberAsString")
        this.contractForm.patchValue({ 'contactNumberAsString': this.contractNumber });
        //first part of contract creation set values end

        this.stageId = res.contractDtl.stageId
        // console.log("stateid in get::",this.stateId);
        this.contractDetailForm.patchValue({ 'stageId': this.stageId });

        if(res.contractDtl.dateOfExecution!= null){
        this.contractDetailForm.patchValue({ 'dateOfExecutionStr': new Date(res.contractDtl.dateOfExecution) });
        }

        if(res.contractDtl.expiaryDate!= null){
          this.contractDetailForm.patchValue({ 'expiaryDateStr': new Date(res.contractDtl.expiaryDate) });
          }

        let timePeriod = res.contractDtl.timePeriod;
        this.contractDetailForm.patchValue({ 'timePeriod': timePeriod });

        this.termUploadPath = res.contractDtl.termSheetUploadPathIns3;
        this.termUploadDocname = res.contractDtl.termSheetDocName;
        //this.terDocName = res.contractDtl.

        /***:--doc type:***/

        console.log('contractDocList', this.contractDocList);
        this.contractDocList = await res.contractDocList;

        //console.log( 'docType', this.groupByArrayKey( res.contractDocList, 'docType' ) );

      }
    }, (err: any) => {
      console.log(err);
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }

 
  removeDoc(clientUserId, contractNo, docId, docType, docName) {
    let formVal = {
      "clientUserId": this.clientUserId,
      "contractNo": this.contractNo,
      "docType": docType,
      "docId": docId,
      "docName": docName
    };

    console.log('removeDoc', formVal);

    this.contractService.removeDoc(JSON.stringify(formVal)).subscribe((data) => {
      console.log('postcontractData response', data);
      // alert( data.responseMessage );
      this.showMsg = true;
      this.mes = data.responseMessage;
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      if(this.editMode == true){
      setTimeout(()=>{
      //   // location.reload();
      this.mes = "";
      },5000);
      this.load();
     }else{
      this.viewcontractDetails(this.contractNo);
     }


    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes1 = 'Failed to delete file.';
      this.toastr.success(this.mes1, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }


  handleFileInput(files: FileList, type) {
    console.log("handleFileInput type:::",type);
    //this.openNextTab('tab_3');
    this.loader = true;
    this.fileToUpload = files.item(0);
    let data = { docType: type, contractNo: this.contractNo, userId: this.clientUserId,loginUserId:this.userId};
    // console.log('handleFileInput data *******************'+JSON.stringify(data));
    this.contractService.postFile(this.fileToUpload, data).subscribe(data => {
      // location.reload();
      this.loader = false;
      this.showMsg = true;
      this.mes = 'File uploaded succcessfully.';
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      //this.viewcontractDetails(this.contractNo);
      // alert( 'File upload success.' );
      if(this.editMode == true){
      setTimeout(()=>{
      //   // location.reload();
      this.mes ="";
      },5000);
      this.load();
      }else{
        this.viewcontractDetails(this.contractNo);
      }

      // console.log( 'file upload response', data );
      // do something, if upload success
    }, error => {
      console.log(error);
      this.toastr.error("File could not be uploaded. ", '');
    });

  }


  removeContractInfo(clientUserId, contractNo, docId, docType, docName ) {
    let formVal = {
      "userId": this.clientUserId,
      "contractNo": this.contractNo,
      "docType": docType,
      "docName": docName
    };

    console.log('removeDoc', formVal);

    this.contractService.removeContractInfo(JSON.stringify(formVal)).subscribe((data) => {
      console.log('postcontractData response', data);
      // alert( data.responseMessage );
      this.showMsg = true;
      this.mes = data.responseMessage;
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      setTimeout(()=>{
        this.mes = "";
      },5000);
      if(this.editMode == true){
      setTimeout(()=>{
        //location.reload();
        this.load();
      },2000);
     }else{
      this.viewcontractDetails(this.contractNo);
     }


    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes1 = 'Failed to delete file.';
      this.toastr.error(this.mes1, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }


  uploadContractInfo(files: FileList, type) {
    console.log("handleFileInput type:::",type);
    //this.openNextTab('tab_3');
    this.loader = true;
    this.fileToUpload = files.item(0);
    let data = { docType: type, contractNo: this.contractNo, userId: this.clientUserId,loginUserId:this.userId};
    // console.log('handleFileInput data *******************'+JSON.stringify(data));
    this.contractService.uploadContractInfo(this.fileToUpload, data).subscribe(data => {
      // location.reload();
      this.loader = false;
      this.showMsg = true;
      this.mes = 'File uploaded succcessfully.';
      this.toastr.success(this.mes, '');
      this.mes1 = '';
      setTimeout(()=>{
        this.mes = "";
      },5000);

      //this.viewcontractDetails(this.contractNo);
      // alert( 'File upload success.' );
      if(this.editMode == true){
      setTimeout(()=>{
        this.load();
      },1000);
      }else{
        this.viewcontractDetails(this.contractNo);
      }

      // console.log( 'file upload response', data );
      // do something, if upload success
    }, error => {
      console.log(error);
      this.toastr.error("File could not be uploaded. ", '');
    });

  }

  postInsChat() {
          this.subInsChatForm = true;
          let formVal         = this.insChatForm.value;
          let userData        = JSON.parse(localStorage.getItem('profileInfo'));


          console.log('insChatForm', formVal,userData);

          if(this.insChatForm.invalid) {
              return false;
          }else{

              let requestPayload = {fromUserEmailId:userData.emailId, fromUserId:userData.userIdlogin, toUserEmailId: this.instructionToList[formVal.toUser].emailId,  toUserId: this.instructionToList[formVal.toUser].userId, contractNo: this.contractNo, msgType: formVal.msgType, msgContent: formVal.msgContent};
              console.log(requestPayload);
              // return false;
              //this.insChatBoxTriggerRender();
              this.contractService.saveConversation(requestPayload).subscribe((data) => {
                console.log('savecontractData response', data);
                if(data.responseMessage == 'success'){
                    // this.insFormModal = false;
                    $("#myInstructionModal").modal("hide");
      
                    // this.mes1 = '';
                    // this.showMsg = true;
                    // this.mes = 'Conversation added successfully.';
      
                    this.insChatBoxList();
                }
              }, (err: any) => {
                console.log(err);
                  // this.showMsg = true;
                  // this.mes = '';
                  // this.mes1 = 'contract Creation failed.';
    
              }, () => {
                // this.modalDisplay = !this.modalDisplay;
              }
              );

          }

          
  }

  deleteSingleConversation(ins_id) {
    this.contractService.deleteSingleConversation(JSON.stringify({ id: ins_id })).subscribe((data) => {
      console.log('savecontractData response', data);
      if (data.responseMessage == 'success') {

        //$( "#myModal" ).modal( "hide" );

        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Conversation deleted successfully.';
        this.toastr.success(this.mes, '');
        this.insChatBoxList();
      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Contract Creation failed.';
      this.toastr.error(this.mes1, '');

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );


  }


  

  //########## Instraction ChatBot Functionality Start Here 

  displayInsFormModal(ins_id = null) {

    
    this.insChatForm.reset();
    this.insChatForm.patchValue({ 'toUser': "" });
    this.insChatForm.patchValue({ 'msgType': "" });
    
    this.subInsChatForm = false
      this.contractService.getcontractToUserList({contractNo:this.contractNo}).subscribe(async data => {

            console.log('To List', data.respData);
            this.instructionToList = await data.respData.map((currentValue, index, arr) => {
                currentValue.sl = index + 1;
                return currentValue;
            });

      });

        // this.insFormModal = true;
        $("#myInstructionModal").modal("show");

    // // $( "#myModal" ).modal( "show" );
    // this.insChatId = ins_id;

    // if (ins_id != null) {
    //   let contract_no = this.contractNo;
    //   this.contractService.getSingleConversation({ id: ins_id }).
    //     subscribe(
    //       async data => {

    //         let res = await data.respData;
    //         console.log('getSingleConversation', res,);

    //         let toUserId = res.toUserId;
    //         var index = this.clientName.findIndex(p => p.userId == toUserId);
    //         this.insChatForm.patchValue({ 'toUser': index });

    //         let msgType = res.msgType;
    //         this.insChatForm.patchValue({ 'msgType': msgType });

    //         let msgContent = res.msgContent;
    //         this.insChatForm.patchValue({ 'msgContent': msgContent });

    //       }
    //     );
    // } else {
    //   this.insChatForm.patchValue({ 'toUser': null });
    //   this.insChatForm.patchValue({ 'msgType': null });
    //   this.insChatForm.patchValue({ 'msgContent': null });
    // }
  }

  async insChatBoxList() {

      let contract_no = this.contractNo;
      this.contractService.getConversationList({contractNo:contract_no,loginUserId: this.userId }).subscribe(async data => {

        console.log('conversationList', data.respData);
        this.conversationList = await data.respData.map((currentValue, index, arr) => {
          currentValue.sl = index + 1;
          return currentValue;
        });

       /*  if (localStorage.getItem('dtTrigger')) {
          this.dtTrigger.next();
          localStorage.removeItem('dtTrigger')
        }; */

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();     
      });


      }
      );
  }
  //########## Instraction ChatBot Functionality Ends Here 

  privilegemyModal(index, status = "show") {
    if (status == 'hide') {
      $('#privilegemyModal' + index).modal('hide');
    } else {
      $('#privilegemyModal' + index).modal('show');
    }
  }
  privilegemyEditModal(index, status = "show") {
    if (status == 'hide') {
      $('#privilegemyEditModal' + index).modal('hide');
    } else {
      $('#privilegemyEditModal' + index).modal('show');
    }
  }

  // TeamForm first time creation start

  TeamForms(): FormArray {
    return this.assignTeamForm.get("TeamForms") as FormArray
  }

  // partyDtlVo(): FormArray {
  //   return this.partyForm.get("partyDtlVo") as FormArray
  // }
  //newteams added in addTeam
  newTeams(): FormGroup {
    return this.fb.group({
      memberUserId: [''],
      clientUserId: [''],
      contractNo: this.contractNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }


  addTeam(teamIndex) {
    console.log("Adding a team *********", teamIndex);
    this.TeamForms().push(this.newTeams());
    this.prmissionVal(teamIndex + 1);
  }

  removeTeam(teamIndex: number) {
    console.log("removing a team", teamIndex);
    this.TeamForms().removeAt(teamIndex);
  }

  teamPermissions(teamIndex: number): FormArray {
    return this.TeamForms().at(teamIndex).get("permission") as FormArray
  }

  newPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }


  async prmissionVal(empIndex = 0) {
    let per_val: any = [{
      id: null,
      folderName: 'financial',
      delete: false,
      view: false,
      upload: false,
    }, {
      id: null,
      folderName: 'contractfolder',
      delete: false,
      view: false,
      upload: false,
    }];
    let permis: any = [];

    await per_val.map((chrd, index) => {
      console.log('chrd ------------: ', chrd);
      permis.push(chrd);
      this.teamPermissions(empIndex).push(this.newPermission());
    });

    (this.assignTeamForm.get('TeamForms') as FormArray).at(empIndex).get('permission').patchValue(permis);
  }

  addTeamPermission(empIndex: number) {
    console.log('this.newPermission**************', this.newPermission());
    this.teamPermissions(empIndex).push(this.newPermission());
  }


  removeTeamPermission(empIndex: number, permissionIndex: number) {
    this.teamPermissions(empIndex).removeAt(permissionIndex);
  }

  async editTeam(teamIndex) {
    console.log("editTeam", teamIndex);

    let tl: any = this.assignTeamList;
    this.assignTeamList = await tl.map((p, i) => {
      if (i == teamIndex) {
        p.disabled = false;
      }
      // else {
      //   p.disabled=true;
      // }
      return p;
    });
    // this.TeamForms().push( this.newTeams() );
  }

  /****:- listing edit section for team assignment-:****/

  TeamListingEditForms(): FormArray {
    return this.assignTeamEditForm.get("TeamEditForms") as FormArray
  }
  //in edit mode set form value
  newTeamListingEditForm(): FormGroup {
    return this.fb.group({
      memberUserId: ['', Validators.required],
      clientUserId: ['', Validators.required],
      contractNo: this.contractNo,
      permission: this.fb.array([]),
      memberUserName: [''],
      memberUserType: ['']
    })
  }

  teamListingEditFormPermissions(teamIndex: number): FormArray {
    return this.TeamListingEditForms().at(teamIndex).get("permission") as FormArray
  }
  //this is for modal permission
  newListingEditFormPermission(): FormGroup {
    return this.fb.group({
      id: null,
      folderName: '',
      delete: false,
      view: false,
      upload: false,
    })
  }

  assignTeamSubmit() {
    this.subAssignTeamForm = true;

    let formVal = this.assignTeamForm.value;
    let formValEdit = this.assignTeamEditForm.value;
    console.log('assignTeamSubmit formVal------ ', formVal);
    console.log('assignTeamForm ------ ', formVal.TeamForms);
    console.log('TeamEditForms', formValEdit.TeamEditForms);

    let mergeData: any = [...formVal.TeamForms, ...formValEdit.TeamEditForms,];
    console.log('mergeData', mergeData);
    if (this.assignTeamForm.invalid) {
      return;
    }
    this.btnSppiner = true;

    this.contractService.saveMemberPermission(JSON.stringify(mergeData)).subscribe((data) => {
      console.log('saveMemberPermission response', data);
      if (data.responseMessage == 'success') {
        this.displaycontractDocument = 'block';
        this.assignTeamEditList();
        this.mes1 = '';
        this.showMsg = true;
        this.mes = 'Team assigned successfully.';
        this.toastr.success(this.mes, '');
        this.btnSppiner = false;
      }
    }, (err: any) => {
      console.log(err);
      this.showMsg = true;
      this.mes = '';
      this.mes1 = 'Team assignment failed.';
      this.toastr.success(this.mes1, '');
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    });

  }

  async assignTeamEditList() {
    // const arr = <FormArray>this.assignTeamEditForm.controls.TeamEditForms;
    // arr.controls = [];
    // let contract_no = this.contractNo;
    // this.contractService.memberList({ contractNo: contract_no }).subscribe(async data => {
    //   console.log('assignTeamList', data.respData);
    //   let list = await data.respData;
    //   if (list.length > 0) {
    //     await list.map((currentValue, index, arr) => {
    //       console.log("currentValue::", currentValue);
    //       currentValue.disabled = true;
    //       this.TeamListingEditForms().push(this.newTeamListingEditForm());
    //       currentValue.permission.map(async () => {
    //         await this.teamListingEditFormPermissions(index).push(this.newListingEditFormPermission())
    //       });
    //       return currentValue;
    //     });
    //     setTimeout(() => {
    //       (this.assignTeamEditForm.get('TeamEditForms') as FormArray).patchValue(list)
    //     }, 1000);
    //   }
    //   this.assignTeamList = list;
    //   console.log('this.assignTeamList', this.assignTeamList);

    //   // ( this.assignTeamForm.get( 'TeamForms' ) as FormArray ).at( index ).get( 'permission' ).patchValue( permis );

    //   // this.teamPermissions( teamIndex+1 ).push( this.newPermission() );

    //   if (localStorage.getItem('teamTrigger')) {
    //     setTimeout(() => {
    //       this.teamTrigger.next();
    //     }, 1000);
    //     localStorage.removeItem('teamTrigger')
    //   };
    // }
    // );
  }
  teamTriggerRender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again

      this.dtTrigger.next();
    });
  }
  clickmemberName(memberId) {
    console.log("clickmemberId::", memberId);
    let memberNewList: any[] = [];
    memberNewList = this.memberNameList.filter((item) => item.userId == memberId);
    console.log('memberNewList ====== ', memberNewList[0].firstName);
    this.newTeams().patchValue({
      memberUserName: memberNewList[0].firstName,
    });
    console.log('this.newTeams() ====== ', this.newTeams().value);
  }
  //assign team edit end

  getStateList() {
    this.contractService.getSateList().
      subscribe(
        data => {
          this.statelist = JSON.parse(this.contractService.userData._body).respData;
          // console.log("getStateList comp res--" + JSON.stringify(this.statelist));
        }
      );
  }

  getContractType() {
    this.contractService.getContractTypeList().
      subscribe(
        data => {
          this.contractTypelist = JSON.parse(this.contractService.userData._body).respData;
          console.log("contractTypelist comp res--" + JSON.stringify(this.contractTypelist));
        }
      );
  }

  
  async memberPermission() {

    //const arr=<FormArray>this.assignTeamEditForm.controls.TeamEditForms;
    //arr.controls=[];
    // console.log("memberPermission");
    let contract_no = this.contractNo;
    this.contractService.memberPermissionList({ contractNo: contract_no, memberUserId: this.userId }).subscribe(async data => {

      console.log('assignpermissionListResponse', data.respData);
      let permissionlist        = await data.respData;
      //this.contractFolderPermission = permissionlist.permission;

      console.log('contractFolderPermission', this.contractFolderPermission);

      if (permissionlist.length > 0) {

        await permissionlist.map((currentValue, index) => {
          // console.log("currentValue::", currentValue);
          // currentValue.disabled=true;
          // this.TeamListingEditForms().push( this.newTeamListingEditForm() );

          // currentValue.permission.map( async () => {
          //   await this.teamListingEditFormPermissions( index ).push( this.newListingEditFormPermission() )
          // } );

          return currentValue;
        });

        // console.log('permissionList', permissionlist);


      }


      // ( this.assignTeamForm.get( 'TeamForms' ) as FormArray ).at( index ).get( 'permission' ).patchValue( permis );

      // this.teamPermissions( teamIndex+1 ).push( this.newPermission() );


    }, (err: any) => {
      console.log('memberPermission', err);

    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }


  getContractNo() {
    this.contractService.getContractNumber().
      subscribe(
         data => {
         
          this.contractNumber =  data.respData;

          this.contractForm.patchValue({ 'contactNumberAsString': this.contractNumber });
        }
      );
  }




  













}


