
 
<div class="content-wrapper">
  <!--<section class="content-header">
    
    
    <h1 *ngIf="profilestatus=='N'"> Please update your profile by clicking Update profile from menu for 
      further process<small> </small> </h1> -->
    <!-- <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i> Dashboard </li>
    </ol>
  </section>-->
    <section class="content">
      <div class="bottom-height">  
            <div class="box-header with-border">
              <div class="box-header-icon topbox-header-icon "><i class="material-icons md-24 md-dark">local_mall</i> </div>
              <h3 class="box-title main-header-top ">Dashboard</h3>
            </div>
            <div class="box-body" *ngIf = "dataUser.usertype == 'managingpartner' || dataUser.usertype == 'advocate' ||  dataUser.usertype == 'seniorpartner' || dataUser.usertype == 'associatepartner'"> 
              <div class="row">
                  <!-- <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                      <div class="box">
                          <div class="Billablebox">
                              <h6>Billable Hours Target</h6>
                              <p> You haven't set up your billing target.</p>
                              <button type="button" class="btn btn-primary">Set up your target</button>
                          </div>
                      </div>
                  </div> -->
  
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      
                      <div class="row">
                          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="info-box"> <span class="info-box-icon bg-redflat scale"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                              <div class="info-box-content"> <span class="info-box-text"> No Of Unpaid Bills: <strong style="font-size: 13px;">{{noOfUnpaidBills}}</strong></span> </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="info-box"> <span class="info-box-icon bg-yellowflat scale"><i class="fa fa-file-text" aria-hidden="true"></i></span>
                              <div class="info-box-content"> <span class="info-box-text">Outstanding Revenue: <strong style="font-size: 13px;">{{outstandingBills}}</strong></span></div>
                            </div>
                          </div>
                          <div class="clearfix visible-sm-block"></div>
                          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="info-box"> <span class="info-box-icon bg-navyflat scale"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                              <div class="info-box-content"> <span class="info-box-text">No Of Paid Bills: <strong style="font-size: 13px;">{{noOfPaidBills}}</strong></span></div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="info-box"> <span class="info-box-icon bg-greenflat scale"><i class="fa fa-money" aria-hidden="true"></i></span>
                              <div class="info-box-content"> <span class="info-box-text">Total Revenue: <strong style="font-size: 13px;">{{totalRevenue}}</strong></span></div>
                            </div>
                          </div>
                          
                          
                        </div>
  
          
                  </div>
  
                  
              </div>
  
  
          </div>

            <!-- <div class="box-body"  *ngIf = "dataUser.usertype != 'individualclient' && dataUser.usertype != 'corporatecient'"> 
              <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box Tasksbox">
                          <div class="row">
                              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="Tasks1 new-taskbox"><span>{{taskcount}}</span> <label><strong>Tasks due today</strong><br> <font>Updated an hour ago. </font> </label> 
                          <a [routerLink]="['/addtask']">
                              <button type="button" class="plusbox"> <i class="fa fa-plus" aria-hidden="true"></i>
                              </button> 
                            </a> 
                          </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                               <div class="Tasks2" *ngIf="taskcount==0"><label>You have no tasks due today</label></div>
                               <div class="Tasks2" *ngIf="taskcount>0"><label>You have {{taskcount}} tasks due today</label></div>
                              </div>
                            </div>

                      </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box Tasksbox">
                          <div class="row">
                              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                              <div class="Tasks1 new-taskbox  "><span>{{clientcount}}</span> <label><strong>Client onboarded</strong> <br> <font>Updated an hour ago. </font> </label> 
                              <a [routerLink]="['/clientprofile']">
                              <button type="button" class="plusbox" > <i class="fa fa-plus" aria-hidden="true"></i>
                              </button>
                              </a>
                              </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                  <div *ngIf="clientcount==0" class="Tasks2"><label>You have no client onboarded.</label></div>
                                  <div *ngIf="clientcount>0" class="Tasks2"><label>You have {{clientcount}} client onboarded.</label></div>
                              </div>
                            </div>
                      </div>
                  </div>
                  
              </div>
           </div> -->
          
            <div class="box-body">
              <div class="box">
                <div class="box-header with-border">
                  <h3 class="box-title box-heading card-text">Task Calendar</h3>
                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                  </div>
                </div>
                <div class="box-body">
                  
                  <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs">
                      <li class="active"><a href="#tab_1" data-toggle="tab">Calendar</a></li>
                      <!-- <li><a href="#tab_2" data-toggle="tab">Tasks </a></li> -->

                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane active" id="tab_1">
                        
                        <div class="table-responsive">
                        <div id="calendar"></div>
                        </div>

                        
                        </div>
                      <!-- <div class="tab-pane" id="tab_2">
                        
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th width="100">Tasks Id</th>
                                <th width="100">Name</th>
                                <th>Description</th>
                                <th width="100">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Sumit Rudra</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</td>
                                <td class="btn-success">Not Pending</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>abc</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </td>
                                <td class="btn-danger">Pending</td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>xyz</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </td>
                                <td class="btn-warning">In Progress</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        
                        <div class="box-footer">
                          <ul class="pagination">
                            <li><a href="#">Previous </a></li>
                            <li class="disabled"><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">Next</a></li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="box-footer">
                  <!-- <ul class="pagination">
                    <li><a href="#">Previous </a></li>
                    <li class="disabled"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">Next</a></li>
                  </ul> -->
                </div>
              </div>
            </div>

           <!-- <div class="box-body" *ngIf = "dataUser.usertype == 'advocate'">
              <div class="box">
                <div class="box-header with-border">
                  <h3 class="box-title  box-heading  card-text">Client Onboarded  Information</h3>
                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                  </div>
                </div>
                <div class="box-body">
                  <div class="row">

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8"></div> 
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 rolebox1"> 
                      <div class="form-group Role"> 
                      <label> How Many Last Days You Have The List</label>
                      <select class="form-control " #mySelect (change)='onOptionsSelected(mySelect.value)'>
                        
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                    </div>

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                      
                  <div class="table-responsive">          
                    <table class="table table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTriggerCob">
                      <thead class="thead-dark">
                        <tr>
                          <th>#</th>
                          <th>Client Name</th>
                          <th>Client Phone No.</th>
                          <th>Client Email</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                       
                        <tr *ngFor="let eachInfo of clientOnbInfo; let i=index">
                            <td>
                                {{i + 1}}
                            </td>
                            <td>
                                {{eachInfo.firstName}} {{eachInfo.lastName}}
                            </td>
                            <td>
                                {{eachInfo.phoneNo}}
                            </td>
                            <td>
                                {{eachInfo.emailId}}
                            </td>
                            <td>
                                {{eachInfo.userType}}
                            </td>
                        </tr>
                        
                        
                        
                      </tbody>
                    </table>
                  </div>
                      
                    </div>
                  </div> 

                  
                  



                </div>
                <div class="box-footer">
                   <ul class="pagination">
                    <li><a href="#">Previous </a></li>
                    <li class="disabled"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">Next</a></li>
                  </ul> 
                </div>
              </div>
            </div> -->
            
            <!-- <div class="box-body Hourlybox"> 
                <div class="box">
                    <h1>Hourly Metrics for <span>xyz </span>  Billing Metrics for Firm </h1>
                </div>
            </div> -->

           
    </div>
  </section>
</div>