<!-- <router-outlet *ngIf="currentURL = '/' && currentURL = '/landing'"></router-outlet> -->
<router-outlet *ngIf="landing"></router-outlet> 

<body *ngIf="currentURL != '/' && currentURL != '/home' && currentURL != '/home#about' && currentURL != '/home#team' && currentURL != '/home#services' && currentURL != '/home#pricing' && currentURL != '/home#contact'"  class="hold-transition skin-blue sidebar-mini">
  <div class="wrapper">
    <!-- <p>{{currentURL}}</p> -->
<!-- header -->
<!-- <app-header *ngIf="sidebar"></app-header>  -->

<!-- routes will be rendered here -->
<router-outlet></router-outlet> 
  <!-- Bind to showLoadingIndicator property in the component class -->
  <!-- <div *ngIf="showLoadingIndicator" class="spinner"></div> -->
  
<!-- footer -->
<!-- <app-footer *ngIf="footer"></app-footer> -->

</div>
