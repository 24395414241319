<div class="content-wrapper">
    <div class="content">
      <div class="bottom-height">
        <div class="box-header with-border">
          <div class="box-header-icon"><i class="fa fa-files-o" aria-hidden="true"></i></div>
          <h3 class="box-title">Free Agreement</h3>
        </div>

        <div class="box-body">
            <div class="breadcrumbbox">
                <ol class="breadcrumb">
                    <li><a routerLink="/clientcasedetails"><i class="fa fa-asterisk" aria-hidden="true"></i> Client Case Details </a></li>
                    <li class="active"><a routerLink="/feeagreement"><i class="fa fa-files-o" aria-hidden="true"></i> Fee Agreement</a></li>
                    <li><a routerLink="/feeservice"><i class="fa fa-server" aria-hidden="true"></i> Fee Service</a></li>
                </ol>
            </div>
          </div>

          <div class="box-body">
            <div class="box">
              <div class="box-header with-border">
                  <h3 class="box-title card-text">Client & Case Specific :</h3>
                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                  </div>
              </div> 
              <div class="box-body">
                
                 
                  <strong>FEE AGREEMENT </strong>                  
                  <p> The following terms and conditions outlined on this page serve as the legal fee agreement between 
                  the Client <br> 
                  ( NAME, DESIGNATION (IN CASE OF COMPANY) RESIDENTIAL ADDRESS/REGSITERED OFFICE/CORPORATE OFFICE <br> 
                  (hereafter referred to as “You” ,”I” or “User” ) and <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                  NAME OF LAWYER/LAW FIRM ( hereafter referred to as “We,” “Us,” “Our,” or similar ), ( an Advocate registered with the Bar Council of India, having registration ID - 
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> ) that offers a wide range of legal services which aims to help clients avail quality 
                  legal services on a digital as well as physical platform.
                  </p>
  
                  <p>In view of the credentials and experience your lawyer/law firm, <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> 
                      hold, I/we are pleased to engage <input type="text" class="form-control financialsbox1" placeholder="Enter ...">LAWYER/LAWFIRM 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">  as our LAWYER/Legal Service Provider, on a monthly 
                      retainership basis in respect of the scope of services which are mutually agreed upon by both the parties and/or also on case to 
                      case basis as per the Schedule of Fees enclosed. You are requested to read through the scope of activity along with terms and 
                      conditions mentioned below:
                  </p>
                  <br>
                  <strong>SCOPE OF ACTIVITY AND TERMS & CONDITIONS:</strong>
                  <p>
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ...">LAWYER/LAW FIRM will represent the
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT 
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> in the Civil, Criminal and any other legal matters 
                  in any Court of Law, Tribunal, Forum, including the Appellate Authorities located anywhere in India in accordance with 
                  specific written instructions of the management of <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT 
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> or the undersigned for and on behalf of the 
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT
                  <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> ,on the terms and conditions specified herein below:  
                  </p>
                  <br>
                  <strong>SCOPE OF ACTIVITY AND TERMS & CONDITIONS:</strong>
                  
                  <div class="termsbox">
                  <p><strong>1) </strong> Monthly Retainership Services – Legal Advisory, Conferences, Drafting & Issuance of Legal Notices 
                      and Replies to Legal Notices, Legal Strategy, Vetting of Legal Documents which includes contracts 
                      and correspondences in the course of the company’s business. Reliance has to be placed on the 
                      latest developments in law and services while such advises and opinions are given. This will 
                      include oral opinions and written opinions and overall legal governance. </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>2) </strong> To act, appear, plead and represent
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      as and when so directed by the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      for the Cases, Complaints, Application, Notices & any other legal documents filed by any party 
                      including ex-employees against 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      or its current Employees or Staff, on a case to case basis. 
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>3) </strong> To defend 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      and/or its Employees or Staff against any Police Case or Complaint filed by any Party including 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      Client/s & ex-employees, or any such person acting detrimental to the interest of the Company, 
                      by filing necessary Application/s. This activity would include preparation of Applications, Undertakings, Reply to Notices, 
                      or any other legal documentation/s that may be necessary. You shall be present before any Investigation Authority like Police, 
                      Central Bureau of Investigation, Central Investigation Function, and the like during the process of Investigation Inquiry in 
                      addition to appearance before the Judicial Authorities, in this regard, on a case to case basis. 
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>4) </strong> 
                      To sign, file, verify and present Pleadings, Appeals, Cross-Objections, Petitions, 
                      Affidavits or other documentation/s as may be deemed necessary or proper, in all its stages, 
                      for the prosecution under the instructions of the
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">CLIENT<input type="text" class="form-control financialsbox1" placeholder="Enter ...">. 
                      You shall also withdraw or compromise at any stage during any Case for defending any case/s under the written instructions from the
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">CLIENT<input type="text" class="form-control financialsbox1" placeholder="Enter ...">.   
                  </p> 
                  </div>
  
  
                  <div class="termsbox">
                  <p><strong>5) </strong> 
                      To file Civil Suits for recovery of overdue outstanding amounts from such person/s as directed by
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">CLIENT<input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      under the instructions from the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">CLIENT<input type="text" class="form-control financialsbox1" placeholder="Enter ...">.
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>6) </strong> 
                      To file Criminal Complaints against such persons defrauding 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">CLIENT<input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      or its employees including cases under Sections 406, 420,467,468, 471 or any other applicable section 
                      of Indian Penal Code and Section 120 (b) of the Criminal Procedure Code.  
  
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>7) </strong> 
                      To send Legal Notices under Section 138 of Negotiable Instruments Act, as directed by 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT  <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      and file Complaints and Applications therein including such other documentation as may be essential 
                      for the case, and represent in the Court in such cases, and ensure closure therein.
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>8) </strong> 
                      That Custos Legis LLP shall have con calls/ online conferences/discussions with your offices 
                      regarding legal issues and matters which shall be included in the scope of the monthly retainership fees.
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>9) </strong> 
                      Custos Legis LLP shall provide the details of the legal cases and the status on real time basis as well as on monthly basis to the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> 
                      as may be prescribed from time to time.
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>10) </strong> 
                      Custos Legis LLP shall provide the certified copy of the Roznama/Order Sheet of every matter every six months during the 
                      tenure of each matter. Custos Legis LLP will without any delay provide the copy of any order passed whether against or 
                      favorable which includes that the application for the certified copy to be made immediately. 
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>11) </strong> 
                      Custos Legis LLP shall provide prompt, result oriented services in accordance with the provisions 
                      of the law as per the requirement of the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">. 
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>12) </strong> 
                      PAYMENT TERMS:
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> 
                      shall pay a monthly retainership fee of
                      Rs. <input type="text" class="form-control financialsbox1" placeholder="Enter ...">
                      /- (Rupees <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> Only), 
                      payable monthly within the 7th day of each English Calendar month. 
                      All other legal services/matters which are not included in Clause 1,
                      shall be treated on a case to case basis and on the basis of the approved schedule of 
                      fees enclosed with this retainership letter, for every other legal service not within 
                      the schedule of fees shall be obtained from the company .
  
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>13) </strong> 
                      Custos Legis LLP shall not disclose the Confidential Information provided to 
                      firm in any manner to anyone other than persons specified by the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ...">  CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">  , 
                      who have a need to know for the purpose set forth above and have agreed to abide by the 
                      terms hereof. Custos Legis LLP also undertake not to disclose the Confidential Information 
                      to any third party. Any official Documents or information provided to Custos Legis LLP shall 
                      remain confidential at all times. Custos Legis LLP shall protect as confidential any copies 
                      of the Confidential Information that Custos Legis LLP may have been permitted to make, including 
                      disclosure before any media in printed, visual or any other digital or manual mode at all times.
                  </p> 
  
                  <div>
                      <div class="table-responsive">          
                          <table class="table">
                          <thead>
                              <tr>
                              <th>COURTS/TRIBUNALS </th>
                              <th width="120">DRAFTING <br> (INR)</th>
                              <th width="120">FILING <br> (INR)</th>
                              <th width="127" >APPEARANCE <br> (INR)</th>
                              </tr>
                          </thead>
                          <tbody>
  
                              <tr>
                                  <td >SUPREME COURT OF INDIA (SCI)</td>
                                  <td >50,000/-</td>
                                  <td >50,000/-</td>
                                  <td >50,000/-</td>
                              </tr>
                              <tr>
                                  <td >HIGH COURT AT CALCUTTA</td>
                                  <td >30,000/-</td>
                                  <td >15,000/-</td>
                                  <td >10,000/-</td>
                              </tr>
                              <tr>
                                  <td >OTHER HIGH COURTS</td>
                                  <td >20,000/-</td>
                                  <td >22,000/-</td>
                                  <td >35,000/-</td>
                              </tr>
                              <tr>
                                  <td >DISTRICT COURTS (Barasat, Alipore, City Civil Court)</td>
                                  <td >20,000/-</td>
                                  <td >17,000/-</td>
                                  <td >15,000/-</td>
                              </tr>
                              <tr>
                                  <td >ANY OTHER DISTRICT COURTS</td>
                                  <td >20,000/-</td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                              </tr>
                              <tr>
                                  <td >REVENUE TRIBUNAL</td>
                                  <td >20,000/-</td>
                                  <td >20,000/-</td>
                                  <td >15,000/-</td>
                              </tr>
                              <tr>
                                  <td >TRIBUNALS WITHIN KOLKATA</td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                                  <td >15,000/-</td>
                              </tr>
                              <tr>
                                  <td >APPELLATE TRIBUNALS WITHIN KOLKATA </td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                                  <td >15,000/-</td>
                              </tr>
                              <tr>
                                  <td >TRIBUNAL OUTSIDE KOLKATA</td>
                                  <td >20,000/-</td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                              </tr>
                              <tr>
                                  <td >APPELLATE  TRIBUNALS OUTSIDE KOLKATA</td>
                                  <td >20,000/-</td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                              </tr>
                              <tr>
                                  <td >TRIBUNALS OUTSIDE </td>
                                  <td >20,000/-</td>
                                  <td >30,000/-</td>
                                  <td >25,000/-</td>
                              </tr>
                              <tr>
                                  <td >ARBITRATION</td>
                                  <td >20,000/-</td>
                                  <td >18,000/-</td>
                                  <td >18,000/-</td>
                              </tr>
                              <tr>
                                  <td >ARBITRATION OUTSTATION</td>
                                  <td >20,000/-</td>
                                  <td >25,000/-</td>
                                  <td >25,000/-</td>
                              </tr>
                              <tr>
                                  <td >MONTHLY RETAINERSHIP (includes Legal and    Transactional Advisory , Legal Governance and vetting of third party    contracts)</td>
                                  <td colspan="3">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td >Contract Drafting (Standard)</td>
                                  <td colspan="3">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td >Contract Drafting (Transaction Specific)</td>
                                  <td colspan="3">&nbsp;</td>
                              </tr>
                          </tbody>
                          </table>
                  </div>
  
                  </div>
  
  
  
  
                  </div>
  
                  <div class="termsbox">
                  <p><strong>14) </strong> 
                      No right, privilege or license under any copyrights, trade secrets, or other rights of
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> 
                      is granted by way of this Agreement or any disclosure of Confidential Information hereinabove.
  
                  </p> 
                  </div>
  
                  <div class="termsbox">
                  <p><strong>15) </strong> 
                      This empanelment letter will supersede all earlier agreement/ contract/arrangement.
  
                      The aforementioned arrangement between us does not in any manner create the relationship of 
                      Employer-Employee between Custos Legis LLP and the 
                      <input type="text" class="form-control financialsbox1" placeholder="Enter ..."> CLIENT <input type="text" class="form-control financialsbox1" placeholder="Enter ...">. 
                      It is only on principal to principal basis.
                      
  
                  </p> 
                  </div>
  
  
              </div>
            </div>
          </div>
  
          <div class="box-body">
            <div class="box">
              <div class="box-header with-border">
                  <h3 class="box-title card-text">Schedule of Fees :</h3>
                  <div class="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                  </div>
                </div> 
              <div class="box-body">
              <div> 
               <ol>
                  <li> Advance payment of half of the quoted fee has to be paid by the client at the time of agreeing to the fee agreement and thereafter the contract service request gets confirmed and is activated as an active task in the admin dashboard.</li>
                  <li> All out pocket expenses including fees of Counsels/Consultants that may be engaged in the matter will be payable on actual basis in addition to our fees, in advance.</li>
                  <li> Miscellaneous expenses incurred in conducting a matter such as photocopy, speed post, stamp, court fees, courier, filing, departmental expenses, certified copies, etc., shall be billed on the actual basis and will be payable over and above our fees.</li>
                  <li> Fees for any other activity that is not specified above will be decided on case to case basis. Expenses incurred for conveyance and stay in case of any out station matter will be payable over and above our scheduled fees, as per actual.</li>
                  <li> The fees will be payable immediately upon request for payment/communication for each payment is made.</li>
                  <li> The payment for one time consolidated fees shall be made in three parts. First part payment is to be made at the time of handing over of assignment. Second part payment is to be made at the time of filing of Evidence and Third Part payment is to be made at the time of final hearing of Suit.</li>
                  <li> In case of settlement of any litigation between the parties, 50% of the Consolidated Fees, if opted, shall be paid before the date of signing of Terms of Settlement.</li>
               </ol>
  
               <input type="text" class="form-control financialsbox1 pull-right" placeholder="Enter ..."> 
  
               <div class="clearfix"></div>
               <br>
              </div>   
            
  
           <div class="termsbox">
              <strong>CLIENT EXPENSES:</strong>
              <div>
                  <div class="table-responsive">          
                      <table class="table">
                      <thead>
                          <tr>
                          <th width="30">SL NO</th>
                          <th width="150">CASE NO</th>
                          <th width="150">PARTICULARS</th>
                          <th width="150">AMOUNT</th>
                          </tr>
                      </thead>
                      <tbody>
  
                          <tr>
                              <td></td>
                              <td></td>
                              <td><ol>
                                  <li>PRINTING</li>
                                  <li>XEROX</li>
                                  <li>NOTARY</li>
                                  <li>COURT FEES.</li>
                                  <li>STAMP PAPER.</li>
                                  <li>OATH COMMISSION</li>
                                  <li>TRANSLATION</li>
                                  <li>CERTIFIED COPY.</li>
                                  <li>TRAVEL</li>
                                  <li>MISCELLANEOUS EXPENSES</li>
                                </ol></td>
                              <td></td>
                          </tr>
  
                      </tbody>
                      </table>
              </div>
  
              </div>
  
  
  
  
           </div>
  
           <div class="termsbox">
              <strong>INVOICE: DRAFT </strong>
              <p>LAWYER NAME (BAR COUNCIL ID/LAW FIRM LOGO AND NAME)</p>
              <div class="Invoicebox"> 
              <strong>Invoice</strong>
  
              <div>
                  <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          TO <br>
                          [Name] <br>
                          [Company Name] <br>
                          [Street Address] <br>
                          [City, ST  ZIP Code] <br>
                          Phone [Phone] | [Email] <br><br>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          INVOICE #<br> 
                          ABBREVIATION<br>
                          (LAWYER/LAWFIRM/FINANCIAL YEAR/ INVOICE NO)<br>
                          DATE [Date]<br><br>
                      </div>
                  </div>
                  <div class="row">
  
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                       <strong> &nbsp; </strong><br><br>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <strong>KIND ATTENTION: </strong><br><br>
                      </div>
                  </div>
                  <div class="row">
  
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                       <strong> &nbsp; </strong><br><br>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <strong>FOR </strong> [Case No.    Case Name] <br><br>
                      </div>
                    </div>
              </div>
  
  
  
              <div class="table-responsive">
                  <table class="table">
                  <tr>
                    <td width="60" valign="top" ><strong style="color: #2e74b5;">SL. NO.</strong></td>
                    <td><strong style="color: #2e74b5;">PARTICULARS</strong></td>
                    <td><strong style="color: #2e74b5;">AMOUNT</strong></td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>SCROLLER SELECT FROM </strong></td>
                    <td>To be sourced    from FEE CHART/ or manually entered </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>DRAFTING    PLAINT/PETITION/APPLICATION/COMPLAINT/WRITTEN STATEMENT/OPPOSITION/REPLY</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>FILING PLAINT/PETITION/APPLICATION/COMPLAINT/WRITTEN    STATEMENT/OPPOSITION/REPLY</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>LEGAL NOTICE</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>REPLY TO LEGAL NOTICE</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>CONFERENCE</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>APPEARANCE </strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>CLIENT EXPENSES – SCROLLER VALUES PROVIDED</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>LEGAL ADVISORY</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>DUE DILIGENCE</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>CONTRACT DRAFTING</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>CONTRACT VETTING</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>REGISTRATION</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><strong>&nbsp;</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><strong>&nbsp;</strong></td>
                    <td><strong  style="color: #2e74b5;">TOTAL IN WORDS: </strong> </td>
                    <td><strong>&nbsp;</strong></td>
                  </tr>
                </table><br>
              </div>
  
  
              <div>
                  <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          Thanking You,<br>
                          Yours truly,<br>
                          SIGNATURE DIGITAL SIGNATURE/SCANNED SIGNATURE <br>
                          UPLOAD WHILE SETTING UP VIRTUAL OFFICE <br><br>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <strong>&nbsp; </strong><br><br>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-8 col-lg-9">
                          NAME OF LAWYER/PARTNER OF LAW FIRM,<br>
                          Advocate,<br>
                          DESIGNATION	<br>		
                          ORGANISATION,<br>				
                          Mobile No. - <input type="text" class="form-control financialsbox1" placeholder="Enter ..."><br><br>
                     
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                          <strong>DETAILS: </strong> <br>
                          NAME:<br><input type="text" class="form-control financialsbox1" placeholder="Enter ..."><br>
                          BANK:<br> <input type="text" class="form-control financialsbox1" placeholder="Enter ..."><br>
                          BRANCH:<br> <input type="text" class="form-control financialsbox1" placeholder="Enter ..."><br>
                          Current A/c No.:<br> <input type="text" class="form-control financialsbox1" placeholder="Enter ...">.<br>
                          IFS Code:<br> <input type="text" class="form-control financialsbox1" placeholder="Enter ...">.<br>
                          PAN:<br> <input type="text" class="form-control financialsbox1" placeholder="Enter ...">.<br><br>
                     
                      </div>
                  </div>
  
                  
  
              </div>
  
  
              </div>
  
           </div>
  
          <div class="termsbox">
   
          <strong>PAYMENT LINK</strong>
          <p>INVOICE GENERATION OPTION- AFTER GENERATION – SEND OPTION TO CLIENT EMAIL ID – SENT INVOICE 
              SAVED IN PENDING INVOICE – WITH REMINDER OPTION EVERY 7 DAYS BY EMAIL AND SMS</p>
  
          <p>ONCE INVOICE IS PAID VIA PAYMENT LINK – PENDING INVOICE BECOMES AUTOMATICALLY SAVED IN THE PAID INVOICES – 
              IF PAID BY BANK TRANSFER OR CASH OR E-WALLET – MANUAL UPDATION OF PAYMENT INVOICE.</p>
  
  
          <strong>ADMIN – DASH BOARD</strong> 
          <p>MASTER FINANCIALS – FOR ALL CLIENTS COLLATED FROM CLIENT FOLDERS</p>
  
          <div class="Invoicebox">  
              <div class="table-responsive">
              <table class="table">
              <tr>
              <td width="82" valign="top">CLIENT NAME</td>
              <td width="82" valign="top">PENDING INVOICES (OPTION FOR SENDING REMINDERS)</td>
              <td width="134" valign="top">PAID INVOICES</td>
              <td width="129" valign="top">RECEIVABLES</td>
              <td width="101" valign="top">REVENUE</td>
              </tr>
              <tr>
              <td valign="top">&nbsp;</td>
              <td valign="top">&nbsp;</td>
              <td valign="top">&nbsp;</td>
              <td valign="top">&nbsp;</td>
              <td valign="top">&nbsp;</td>
              </tr>
              </table>
              </div>
          </div> 
          </div>
  
          <div class="termsbox">
   
          <strong> TO DO LIST:</strong> 
          <p>CLIENT & CASE SPECIFIC:</p>
  
          <div class="Invoicebox">  
              <div class="table-responsive">
              <table class="table">
                  <tr>
                      <td width="40" valign="top">&nbsp;</td>
                      <td width="111" valign="top">CASE NO</td>
                      <td width="86" valign="top">LAWYER</td>
                      <td width="112" valign="top">TASK PARTICULARS</td>
                      <td width="96" valign="top">REVERSE TIMER</td>
                      <td width="88" valign="top">REMINDER</td>
                      <td width="67" valign="top">TASK STATUS</td>
                    </tr>
              </table>
              </div>
          </div> 
          </div>
  
          <div class="termsbox">
   
          <strong> OPTION OF ADDING NEW TASK:</strong> 
          <p>ADMIN: MASTER TASK LIST (THINGS TO DO)</p>
  
          <div class="Invoicebox">  
              <div class="table-responsive">
              <table class="table">
                  <tr>
                      <td width="40" valign="top">&nbsp;</td>
                      <td width="111" valign="top">CASE NO</td>
                      <td width="86" valign="top">LAWYER</td>
                      <td width="112" valign="top">TASK PARTICULARS</td>
                      <td width="96" valign="top">REVERSE TIMER</td>
                      <td width="88" valign="top">REMINDER</td>
                      <td width="67" valign="top">TASK STATUS</td>
                    </tr>
                    <tr>
                      <td width="40" valign="top">&nbsp;</td>
                      <td width="111" valign="top">EVENTS</td>
                      <td width="86" valign="top">ONLINE CONFERENCE SLOTS</td>
                      <td width="112" valign="top">&nbsp;</td>
                      <td width="96" valign="top">&nbsp;</td>
                      <td width="88" valign="top">&nbsp;</td>
                      <td width="67" valign="top">&nbsp;</td>
                    </tr>
              </table>
              </div>
          </div> 
  
          <p>CASE DIARY</p>
  
          <p>MONTHLY LIST (COLLATED FOR EACH MONTH from CLIENT FOLDERS)</p>
              
          <p>DAILY LIST (COLLATED from TASKS and MONTHLY LIST) </p>
              
  
  
          </div>
          
              </div>
  
            </div>
          </div>
  
          <div class="box-body">
            <div class="box">
              <div class="box-footer">
                  <button class="btn btn-primary pull-left"> Back </button>
                  <button type="submit" class="btn btn-primary pull-right">Submit</button>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>


