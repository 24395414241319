import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from './service/auth.guard';

import { LoginComponent } from './login/login.component';


import { MainsidebarComponent } from './components/mainsidebar/mainsidebar.component';
import { UserListComponent } from './userlist/userlist.component';
import { CaseListComponent } from './caselist/caselist.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { LawyerProfileComponent } from './lawyer-profile/lawyer-profile.component';
import { CasecreationComponent } from './casecreation/casecreation.component';
import { CaseListAllComponent } from './caselistAll/caselistAll.component';
import { TeamcreationComponent } from './teamcreation/teamcreation.component';
import { TeamsetupComponent } from './teamsetup/teamsetup.component';
import { AddressesComponent } from './addresses/addresses.component';
import { TasklistComponent } from './tasklist/tasklist.component';
import { AddtaskComponent } from './addtask/addtask.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FinancialsComponent } from './financials/financials.component';
import { ClientexpensesComponent } from './clientexpenses/clientexpenses.component';
import { ServiceComponent } from './feeservice/service.component';
import { ClientcasedetailsComponent } from './clientcasedetails/clientcasedetails.component';
import { FeeagreementComponent } from './feeagreement/feeagreement.component';
import { OverheadexpensesComponent } from './overheadexpenses/overheadexpenses.component';
import { SchedulefeesComponent } from './schedulefees/schedulefees.component';
import { PartiesComponent } from './parties/parties.component';
import { InviteMemberComponent } from './invitemember/invitemember.component';
import { OrganizationProfileComponent } from './organization-profile/organization-profile.component';
import { OrgAddressesComponent } from './orgaddresses/orgaddresses.component';
import { ContractClientexpensesComponent } from './contractclientexpenses/contractclientexpenses.component';
import { ContractcreationComponent } from './contractcreation/contractcreation.component';
import { InvoicegenerationComponent } from './invoicegeneration/invoicegeneration.component';
import { ContractListComponent } from './contractlist/contractlist.component';
import { FeeAgreementListComponent } from './feeagreementlist/feeagreement.component';
import { PaymentComponent } from './payment/payment.component';
import { PayresponseComponent } from './payresponse/payresponse.component';
import { NotificationComponent } from './notification/notification.component';
import{ContractinvoicegenerationComponent} from './contractinvoicegeneration/contractinvoicegeneration.component'
import { from } from 'rxjs';
import { TimeKeeperComponent } from './timeKeeper/timeKeeper.component';
import { InvoiceListComponent } from './invoiceList/invoiceList.component';
import { InvoicePaymentListComponent } from './invoicePaymentList/invoicePaymentList.component';
import { LandingComponent } from './landing/landing.component';
import { LandingLayoutComponent } from './landing-layout/landing-layout.component';
import { InvoiceListContractComponent } from './invoiceListContract/invoiceListContract.component';


const routes: Routes = [
  { path: 'mainsidebar', component: MainsidebarComponent},
  
  { path: 'login', component: LoginComponent },
  { path: 'login/:username/:pwd', component: LoginComponent },

  /* { path: '', component: LandingLayoutComponent, 
    children: [
     
    ]
  }, */
  { path: 'home', component: LandingComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  //{ path: '**', redirectTo: '/login' },
  { path: 'userlist', component: UserListComponent},
  { path: 'caselist/:id', component: CaseListComponent},
  { path: 'caselist', component: CaseListComponent},
  { path: 'feedback', component: FeedbackComponent},
  { path: 'addresses', component: AddressesComponent},
  { path: 'addresses/:id/:id1', component: AddressesComponent},
  { path: 'setpassword', component: SetPasswordComponent}, 
  { path: 'dashboard', component: DashboardComponent}, 
  { path: 'lawyerprofile', component: LawyerProfileComponent}, 
  { path: 'teamprofile/:id/:id1', component: LawyerProfileComponent},
  { path: 'teamprofile/:id/:id1/:id2', component: LawyerProfileComponent},
  { path: 'clientprofile', component: ClientProfileComponent},
  { path: 'clientprofile/:id/:id1', component: ClientProfileComponent}, 
  { path: 'clientprofile/:id/:id1/:id2', component: ClientProfileComponent}, 
  { path: 'casecreation', component: CasecreationComponent},
  { path: 'caselistAll', component: CaseListAllComponent},
  { path: 'case-view/:userId/:caseNo', component: CasecreationComponent },
  { path: 'case-edit/:userId/:caseNo', component: CasecreationComponent },
  { path: 'teamsetup', component: TeamsetupComponent},
  { path: 'teamcreation', component: TeamcreationComponent},
  { path: 'tasklist', component: TasklistComponent},
  { path: 'tasklist/:id', component: TasklistComponent},
  { path: 'addtask', component: AddtaskComponent},
  { path: 'addtask/:mode/:id', component: AddtaskComponent},
  { path: 'invoice', component: InvoiceComponent},
  { path: 'resetPassword', component: ResetPasswordComponent},
  { path: 'forgotPassword', component: ForgotPasswordComponent},
  { path: 'financials', component: FinancialsComponent},
  { path: 'clientexpenses', component: ClientexpensesComponent},
  { path: 'feeservice', component: ServiceComponent},
  { path: 'feeagreement', component: FeeagreementComponent},     
  { path: 'clientcasedetails', component: ClientcasedetailsComponent},
  { path: 'schedulefees', component: SchedulefeesComponent},
  { path: 'overheadexpenses', component: OverheadexpensesComponent},
  { path: 'parties', component: PartiesComponent},  
  { path: 'invitemember', component: InviteMemberComponent},
  { path: 'orgprofile', component: OrganizationProfileComponent},
  { path: 'orgaddresses', component: OrgAddressesComponent},
  { path: 'orgaddresses/:id/:id1', component: OrgAddressesComponent},
  { path: 'contractclientexpenses', component: ContractClientexpensesComponent},
  { path: 'invoicegeneration', component: InvoicegenerationComponent},
  { path: 'createcontract', component: ContractcreationComponent},
  { path: 'contract-view/:clientUserId/:contractNo', component: ContractcreationComponent },
  { path: 'contract-edit/:clientUserId/:contractNo', component: ContractcreationComponent },
  { path: 'contractlist/:id', component: ContractListComponent},
  { path: 'contractlist', component: ContractListComponent},
  { path: 'agreementlist', component: FeeAgreementListComponent},
  { path: 'agreementlist/:id', component: FeeAgreementListComponent},
  {path: 'payment', component: PaymentComponent},
  {path: 'payresponse', component: PayresponseComponent},
  {path: 'notifaction', component: NotificationComponent},
  {path: 'contractinvoicegeneration', component: ContractinvoicegenerationComponent},
  {path: 'timeKeeper', component: TimeKeeperComponent},
  {path: 'invoiceList', component: InvoiceListComponent},
  {path: 'invoicePaymentList', component: InvoicePaymentListComponent},
  {path: 'invoiceListContract', component: InvoiceListContractComponent},

]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
