<div class="content-wrapper">

  <div class="content">
    <div class="bottom-height">
      
      <div class="box-header with-border">
        <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">event</i> </div>
        <h3 class="box-title main-header-top">Contract Management</h3>
      </div>

      <div class="box-body">
        <div class="box">
          <div class="box-body">

          <div class="row" *ngIf="contractviewpermission!='view'">
            <!-- <div class="col-xs-6 col-sm-10 col-md-10 col-lg-10 "> </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
              <a [routerLink]="['/createcontract']" routerLinkActive="router-link-active">
                <button type="button" class="btn btn-primary pull-right Role">Create Contract</button>
              </a>

            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <!-- <table class="table">
                  <thead>
                    <tr>
                      <th>Contract Title</th>
                      <th>Advocate/Lawfirm Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody> -->
                    <ul *ngFor="let item of userlist" class="team-view">
                      <li><h4>{{item.contractTitle}}</h4></li>
                      <li> {{item.superUserName}} </li>
                      <li class="act-btn">
                        <div>
                          <a (click)="goToPage('contract-view')" [routerLink]="['/contract-view',item.clientUserId,item.contractNo]"
                            routerLinkActive="router-link-active"   data-toggle="tooltip" data-placement="bottom" title="view">
                            <button type="button" class="btn btn-edit"><i class="fa fa-eye"
                                aria-hidden="true"></i></button>
                          </a>
                          <a *ngIf="contractviewpermission!='view'" (click)="goToPage('contract-edit')" [routerLink]="['/contract-edit',item.clientUserId,item.contractNo]"
                            routerLinkActive="router-link-active" data-toggle="tooltip" data-placement="bottom" title="edit">
                            <button type="button" class="btn btn-edit"><i class="fa fa-pencil-square-o"
                                aria-hidden="true"></i></button>
                          </a>



                          <!-- <button type="button" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"
                              (click)="deleteCorfirmation(item.clientUserId)" data-toggle="modal"  data-placement="bottom" title="delete"
                              data-target="#saveIntent"></i></button> -->
                        </div>
                      </li>
                    </ul>
                  <!-- </tbody>
                </table> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a [routerLink]="['/userlist']" routerLinkActive="router-link-active">
                <button class="btn btn-grey pull-left"> Back to client list</button>
              </a>
            </div>

          </div>

          </div>
        </div>
      </div>

  </div>
  </div>




  <!-- Modal start -->
  <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm to deactivate</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure to deactivate?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="deleteData()">Yes</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal end -->
</div>
