import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { ActivatedRoute, RouteReuseStrategy, Router } from '@angular/router';
@Component( {
  selector: 'app-contractlist',
  templateUrl: './contractlist.component.html',
  styleUrls: ['./contractlist.component.css']
} )
export class ContractListComponent implements OnInit {

  userlist=[];
  dataArr: any[]=[];
  saveDisplay='none';
  mydata: any;
  userId: string;
  modalDisplay: boolean=false;
  contractviewpermission:string;
  loginuserId:string;

  constructor( private legalbellService: LegalbellService, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.contractviewpermission = localStorage.getItem('contractpermission')?localStorage.getItem('contractpermission'): null;
    console.log("contractviewpermission:::",this.contractviewpermission);
    if(this.contractviewpermission=='view'){
      this.loginuserId=localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
      console.log("login userId:::",this.userId);
    } 
    this.route.paramMap.subscribe( params => {
      this.userId=params.get( 'id' );
    } );
    if(this.userId!=null){
      this.getcontractList( this.userId );
      }else{
        this.getcontractList( this.loginuserId );
      }
  }
  getcontractList( userid: string ) {
    this.legalbellService.getContractListUser( userid ).
      subscribe(
        data => {
          this.userlist=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "getcontractList comp res--"+JSON.stringify( this.userlist ) );
        }
      );
  }
  closeSaveModal() {
    console.log( 'closeSaveModal---' );
    this.saveDisplay='none';
  }
  deleteCorfirmation( userid: string ) {
    console.log( "userid to delete::", userid );
    this.userId=userid;
    this.modalDisplay=true;
  }
  deleteData() {
    this.legalbellService.deactivateUser( this.userId ).
      subscribe(
        data => {
          this.mydata=JSON.parse( this.legalbellService.userData._body );
          console.log( "statusCode comp res--"+this.mydata.status );
          if ( this.mydata.status=='200' ) {
            window.alert( "User deactivated successfully." );
            //this.getUserList(); 
            this.router.navigate( ['/userlist'] );
          }
          else {
            window.alert( "User not deactivated successfully." );
          }
        }
      );
    this.closeSaveModal();
  }

  goToPage( pageName: string ) {

    localStorage.setItem( "refresh", 'refresh' );
  }
}
