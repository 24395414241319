<div class="content-wrapper">
   <div class="content">
      <div class="bottom-height">
         <div class="box-header with-border">
            <div class="box-header-icon topbox-header-icon"> <i class="material-icons md-24 md-dark">add_task</i> </div>
            <h3 class="box-title main-header-top">Add Task</h3>
         </div>
         <form [formGroup]="myform" *ngIf="myform" (ngSubmit)="ngSubmit();">
         <div class="box-body">
            <div class="box">
               <!-- <div class="box-header with-border">
                  <h3 class="box-title card-text">Add Task</h3>
                  
                  </div> -->
               <div class="box-body">
                  <div class="row">
                     <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1" *ngIf="mode === 'edit'">
                        <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal"
                           data-placement="bottom" title="view trail" data-target="#myModal" (click)="getTrailMsg()"><i
                           class="fa fa-external-link-square" aria-hidden="true"></i></button>
                        </div> -->
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                           <span class="check1" *ngFor="let type of caseSelectArr">
                           <input type="radio" style="width: unset;" formControlName="ifCaseSpecificTask"
                           (change)="ngChangeCaseSpecificTask()" name="ifCaseSpecificTask" [value]="type">
                           {{type}}
                           </span>
                           <small class="text-danger"
                              *ngIf="myform.get('ifCaseSpecificTask').invalid && myform.get('ifCaseSpecificTask').touched">Please
                           select an option.
                           </small>
                        </div>
                     </div>
                     <div *ngIf="caseNoDiv">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                           <div class="form-group">
                              <label>Case Title</label>
                              <select formControlName="causeTitle" [attr.disabled]="viewMode" class="form-control" (change)="onSelectCase($event.target.value)">
                              <option value="0">--Select--</option>
                              <option *ngFor="let case of caselist " value= {{case.caseTitle}}>{{case.caseTitle}}</option>
                              </select>
                              <!-- <input type="text" formControlName="causeTitle" [attr.disabled]="viewMode" class="form-control"
                                 placeholder="Enter ..."> -->
                              <small class="text-danger"
                                 *ngIf="myform.get('causeTitle').invalid && myform.get('causeTitle').touched">Please select Cause
                              Title.
                              </small>
                           </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                           <div class="form-group">
                              <label>Case No</label>
                              <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="caseNo"
                                 placeholder="Enter ..." readonly>
                           </div>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                           <label>Task Brief <span class="text-danger">*</span></label>
                           <textarea class="form-control" formControlName="taskBrief" [attr.disabled]="viewMode" rows=""
                              placeholder="Enter ..." spellcheck="false"></textarea>
                           <small class="text-danger"
                              *ngIf="myform.get('taskBrief').invalid && myform.get('taskBrief').touched">Please enter task
                           brief.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <label>Start Date <span class="text-danger">*</span></label>
                           <div class="input-group date">
                              <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                              </div>
                              <input [owlDateTimeTrigger]="dt" [owlDateTime]="dt" class="form-control pull-right"
                              id="datepicker" placeholder="00/00/0000" formControlName="startDateStr"
                              [attr.disabled]="viewMode">
                              <owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
                           </div>
                          
                           <small class="text-danger"
                              *ngIf="myform.get('startDateStr').invalid && myform.get('startDateStr').touched">Please enter
                           start date.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <label>Due Date <span class="text-danger">*</span></label>
                           <div class="input-group date">
                              <div class="input-group-addon" [owlDateTimeTrigger]="dt1"> <i class="fa fa-calendar"></i>
                              </div>
                              <input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" type="text" class="form-control pull-right"
                              id="datepicker" placeholder="00/00/0000" formControlName="endDateStr"
                              [attr.disabled]="viewMode">
                           </div>
                           <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
                           <small class="text-danger"
                              *ngIf="myform.get('endDateStr').invalid && myform.get('endDateStr').touched">Please enter due
                           date.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <label>Task Type <span class="text-danger">*</span></label>
                           <select class="form-control" formControlName="taskType" [attr.disabled]="viewMode" (change)="selecTtaskType($event.target.value)">
                              <option>--Select--</option>
                              <option value='INSTRUCTION'>Instruction</option>
                              <option value="REPLY">Reply</option>
                              <option value="QUERY">Query</option>
                           </select>
                           <small class="text-danger"
                              *ngIf="myform.get('taskType').invalid && myform.get('taskType').touched">Please select task type.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <label>Task Status <span class="text-danger">*</span></label>
                           <select class="form-control" formControlName="taskStatus" [attr.disabled]="viewMode">
                              <option>--Select--</option>
                              <option value="PENDING">Pending</option>
                              <option value="DONE">Done</option>
                           </select>
                           <small class="text-danger"
                              *ngIf="myform.get('taskStatus').invalid && myform.get('taskStatus').touched">Please select task
                           status.
                           </small>
                        </div>
                     </div>
                    
                     <div *ngIf="(mode === 'edit' && replyCheck) || myform.get('taskType').value==='REPLY' " class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                        <div class="form-group">
                           <label>Reply <span class="text-danger">*</span></label>
                           <button type="button" style="margin-left:8px;" class="btn btn-primary Role" data-toggle="modal"
                              data-placement="bottom" title="view trail" data-target="#myModal" (click)="getTrailMsg()"><i
                              class="fa fa-external-link-square" aria-hidden="true"></i></button>
                           <textarea class="form-control" required formControlName="replyMsg" rows="" placeholder="Enter your reply..."
                              spellcheck="false" [attr.disabled]="viewMode"></textarea>
                              <small class="text-danger"
                              *ngIf="myform.get('replyMsg').invalid && myform.get('replyMsg').touched">Please enter reply.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="myform.get('ifCaseSpecificTask').value == 'Without case number'">
                        <div class="form-group">
                           <label>Team <span class="text-danger">*</span></label>
                           <select class="form-control" style="height: 95px;" [attr.disabled]="viewMode"
                              formControlName="toUserIds" multiple>
                              <!-- <option selected="selected" value="">None</option> -->
                              <option *ngFor="let tolist of userlist" [ngValue]="tolist.userId" (change)="changeTo($event)"
                              required>
                              {{tolist.firstName}} {{tolist.lastName}}</option>
                           </select>
                           <small class="text-danger"
                              *ngIf="myform.controls.toUserIds.hasError('required') && myform.controls.toUserIds.touched">Please select a member. If you don't find add to your team first.</small>
                           <!-- <small class="text-danger" *ngIf="myform.get('toUserIds').invalid && myform.get('toUserIds').touched">Please select Lawyer.
                              </small> -->
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="myform.get('ifCaseSpecificTask').value == 'With case number'">
                        <div class="form-group">
                           <label>Team <span class="text-danger">*</span></label>
                           <select class="form-control" style="height: 95px;" [attr.disabled]="viewMode"
                              formControlName="toUserIds" multiple>
                              <!-- <option selected="selected" value="">None</option> -->
                              <option *ngFor="let tolist of userlist" [ngValue]="tolist.userId" (change)="changeTo($event)"
                              required>
                              {{tolist.memberUserName}}</option>
                           </select>
                           <small class="text-danger"
                              *ngIf="myform.controls.toUserIds.hasError('required') && myform.controls.toUserIds.touched">Please select a member. If you don't find add to your team first.</small>
                           <!-- <small class="text-danger" *ngIf="myform.get('toUserIds').invalid && myform.get('toUserIds').touched">Please select Lawyer.
                              </small> -->
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <label>Priority <span class="text-danger">*</span></label>
                           <select class="form-control" formControlName="taskPriority" [attr.disabled]="viewMode">
                              <option value="">--Select--</option>
                              <option value="URGENT">Urgent</option>
                              <option value="NORMAL">Normal</option>
                           </select>
                           <small class="text-danger"
                              *ngIf="myform.get('taskPriority').invalid && myform.get('taskPriority').touched">Please select
                           Task Priority.
                           </small>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                           <label>Reminder</label>
                           <textarea class="form-control" formControlName="reminder" rows="" placeholder="Enter ..."
                              spellcheck="false" [attr.disabled]="viewMode"></textarea>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="box-footer">
                  <a [routerLink]="['/tasklist']" routerLinkActive="router-link-active">
                  <button class="btn btn-primary pull-left"> Back </button>
                  </a>
                  <button *ngIf="!viewMode" type="button" (click)="saveConfirmation()" data-toggle="modal"
                  data-target="#saveIntent" class="btn btn-primary pull-right">Save</button>
               </div>
            </div>
         </div>
         </form>
         <!-- <div class="box-body" id="resultsection" *ngIf="resultShow!=undefined">
            <div class="box">
               <div class="box-body">
                  <div class="row">
                     <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                           <div class="card-header1" for="staticEmail">
                              <b>Result Section :</b>
                           </div>
                           <div class="card-header2">
                              <i class="mdi mdi-check-circle"></i>
                              <b> {{resultShow}} {{statusCode}}
                              <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
                              </b>
                           </div>
                           <ng-container *ngIf="resultBtnShow">
                              <div class="card-body2">
                                 <h5>Output</h5>
                                 <p>{{mydata.responseMessage}} </p>
                              </div>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
      </div>
   </div>
   <!-- Resullt section start -->
   <!-- <div class="box-body" id="resultsection" *ngIf="resultShow!=undefined">
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
      
                <div class="card-header1" for="staticEmail">
                  <b>Result Section :</b>
                </div>
                <div class="card-header2">
                  <i class="mdi mdi-check-circle"></i>
                  <b> {{resultShow}} {{statusCode}}
                    <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
                  </b>
                </div>
                <ng-container *ngIf="resultBtnShow">
                  <div class="card-body2">
                    <h5>Output</h5>
                    <p>{{mydata.responseMessage}} </p>
                  </div>
                </ng-container>
      
              </div>
            </div>
          </div>
        </div>
      </div>
      </div> -->
   <!-- Result section end -->
   <!-- Modal section end -->
   <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay">
      <div class="modal-dialog">
         <!-- Modal content-->
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title">Confirm to Submit</h4>
            </div>
            <div class="modal-body">
               <p>Are you sure to submit?</p>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-default" data-dismiss="modal" (click)="saveTaskData()">Yes</button>
               <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
         </div>
      </div>
   </div>
   <!-- Modal section end -->
   <!-- Modal -->
   <div id="myModal" class="modal fade Trailbox1" role="dialog">
      <div class="modal-dialog">
         <!-- Modal content-->
         <div class="modal-content">
            <div class="modal-header">
               <button type="button" class="close" (click)="modalclose()" data-dismiss="modal">&times;</button>
               <h4 class="modal-title">View Trail</h4>
            </div>
            <div class="modal-body">
               <div class="modal-scroll">
                  <div class="table-responsive">
                     <table class="table">
                        <thead>
                           <tr>
                              <th width="20">#</th>
                              <th width="50">DateTime</th>
                              <th width="50">From</th>
                              <th width="50">Type</th>
                              <th width="150">Reply</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let task of taskTrail let i=index;">
                              <td>{{i+1}}</td>
                              <td>{{task.updatedAtStr}}</td>
                              <td>{{task.userName}}</td>
                              <td>{{task.taskType}}</td>
                              <td><textarea class="form-control" rows="3" placeholder="Enter ...">{{task.replyMsg}}</textarea></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-default" (click)="modalclose()" data-dismiss="modal">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>