<aside class="main-sidebar">
  <section class="sidebar">
    <div class="user-panel">
     
      <div class="pull-left image">

             <img src="assets/images/logo.png" class="img-circle" alt="User Image"> 
          
      </div>
      <div class="pull-left info">
        <p>WpcLawyer Portal</p>
      </div>
    
    </div>
    <ul class="sidebar-menu" data-widget="tree">

        <!-- <li ><a [routerLink]="['/dashboard']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-tachometer" aria-hidden="true"></i> <span> Dashboard </span></a></li> -->

        <li *ngFor="let eachMenu of menuList" [ngClass]="{'treeview': eachMenu.subMenuList.length > 0}">

              <!--######### For Sub Menu List Start Here #############-->
              <a *ngIf="eachMenu.subMenuList.length > 0 && isSubscriptionValid == 'Y'"> 
                <i class="material-icons md-24 md-light">subdirectory_arrow_right</i> <span>{{eachMenu.menuName}}</span> 
                <!-- <span class="pull-right-container"> <i class="fa fa-angle-left pull-right"></i> </span>  -->
              </a>

              <ul class="treeview-menu" *ngIf="eachMenu.subMenuList.length > 0 && isSubscriptionValid == 'Y'">
                    <li *ngFor="let eachSubMenu of eachMenu.subMenuList" (click)="priviledge(eachSubMenu.privilege,eachSubMenu.routerLink)">
                      <a [routerLink]="[eachSubMenu.routerLink]" routerLinkActive="router-link-active" href="#"> 
                        <i class="material-icons md-18 md-light">{{eachSubMenu.imageName}}</i><span> &nbsp;{{eachSubMenu.subMenuName.toUpperCase()}}</span> 
                      </a>
                    </li> 

              </ul>
              <!--######### For Sub Menu List Ends Here #############-->

              <!--######### For Main Menu List Start Here #############-->
              <a *ngIf="eachMenu.subMenuList.length == 0 && isSubscriptionValid == 'Y'" [routerLink]="[eachMenu.routerLink]" routerLinkActive="router-link-active" href="#" (click)="priviledge(eachMenu.privilege,eachMenu.routerLink)"> 
                  <i class="material-icons md-24 md-light">{{eachMenu.imageName}}</i> <span> {{eachMenu.menuName}} </span>
                  
              </a>
              
              
              <!--######### For Main Menu List Ends  Here #############-->
            
        </li>
        <!-- <li *ngIf="usertype =='managingpartner' || usertype =='advocate'">
          <a [routerLink]="'payment'" routerLinkActive="router-link-active" href="#"> 
            <i class="material-icons md-24 md-light">payments</i> <span> BILLING DETAILS</span>
            
        </a>
        </li> -->

        <!-- <li *ngIf="check('Dashboard')"><a [routerLink]="['/dashboard']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-tachometer" aria-hidden="true"></i> <span> Dashboard </span></a></li>

        <li *ngIf="check('Update Profile')"><a [routerLink]="['/lawyerprofile']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-users" aria-hidden="true"></i><span>Update Profile</span> </a></li> 
        
        <li *ngIf="usertype=='managingpartner' || usertype=='directorlegal'"><a [routerLink]="['/orgprofile']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-users" aria-hidden="true"></i><span>Organization Profile Update</span> </a></li> 

        <li *ngIf="check('Client Roster')"><a [routerLink]="['/userlist']" routerLinkActive="router-link-active" href="#">
        <i class="fa fa-pie-chart" aria-hidden="true"></i><span>Client Roster</span> </a></li>

        <li *ngIf="check('Case Creation')"><a [routerLink]="['/casecreation']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-calendar-check-o" aria-hidden="true"></i><span>Case Creation</span> </a></li> 

        <li *ngIf="check('Case List')"><a [routerLink]="['/caselist',loginuserid]" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-user" aria-hidden="true"></i><span>Case List</span> </a></li> 

        

        <li *ngIf="check('Team setup')"><a [routerLink]="['/teamsetup']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-linode" aria-hidden="true"></i><span>Team Setup</span> </a></li> 

         <li *ngIf="usertype=='managingpartner'"><a [routerLink]="['/invitemember']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-linode" aria-hidden="true"></i><span>Invite Partner</span> </a></li> 
         <li *ngIf="usertype=='directorlegal'"><a [routerLink]="['/invitemember']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-linode" aria-hidden="true"></i><span>Invite Manager</span> </a></li> 

        <li *ngIf="check('Task Management') && usertype=='advocate'" ><a [routerLink]="['/tasklist']" routerLinkActive="router-link-active" href="#"> 
        <i class="fa fa-tasks" aria-hidden="true"></i><span>Task Management</span> </a></li>

        <li *ngIf="check('Task Management')  && (usertype=='lawyeroffcouncil'||usertype=='lawyerassociate')" ><a [routerLink]="['/tasklist',loginuserid]" routerLinkActive="router-link-active" href="#"> 
          <i class="fa fa-tasks" aria-hidden="true"></i><span>Task Management</span> </a></li>

        
        <li *ngIf="check('Finance Management')" class=" treeview"> <a> <i class="fa fa-signal" aria-hidden="true"></i> <span>Finance Management</span> <span class="pull-right-container"> <i class="fa fa-angle-left pull-right"></i> </span> </a>
          <ul class="treeview-menu">
            <li *ngIf="check('Service Master')"><a [routerLink]="['/schedulefees']" routerLinkActive="router-link-active" href="#"> 
              <i class="fa fa-sitemap" aria-hidden="true"></i><span>Service Master</span> </a></li> 

            <li *ngIf="check('Client Expenses')"><a [routerLink]="['/clientexpenses']" routerLinkActive="router-link-active" href="#"> 
              <i class="fa fa-university" aria-hidden="true"></i><span>Client Expenses </span> </a></li> 

            <li *ngIf="check('Overhead Expenses')"><a [routerLink]="['/overheadexpenses']" routerLinkActive="router-link-active" href="#"> 
              <i class="fa fa-university" aria-hidden="true"></i><span>Overhead Expenses </span> </a></li> 
            
              <li *ngIf="check('Fee Agreement')"><a [routerLink]="['/financials']" routerLinkActive="router-link-active" href="#"> 
                <i class="fa fa-briefcase" aria-hidden="true"></i><span>Fee Agreement</span> </a></li> 
              
                <li *ngIf="check('Organization Profile')"><a [routerLink]="['/']" routerLinkActive="router-link-active" href="#"> 
                  <i class="fa fa-briefcase" aria-hidden="true"></i><span>Organization Profile</span> </a></li>
                  
                  <li *ngIf="check('Invite Managers')"><a [routerLink]="['/']" routerLinkActive="router-link-active" href="#"> 
                    <i class="fa fa-briefcase" aria-hidden="true"></i><span>Invite Managers</span> </a></li>  
              
           

           
          </ul>
        </li> -->

       


        
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>