import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LegalbellService } from '../service/legalbell.service'
import { ClientProfileSchema } from '../models/profile.model';
import { ToastrService } from 'ngx-toastr';
import { NavigationLinkParameterService } from '../service/navigation-link-parameters-service';
interface TestObject {
  userId : string;
  userTitle : string;
  firstName : string;
  middleName : string;
  lastName : string;
  emailId : string;
  countryCode : string;
  phoneNo : string;
  token : string;
  registrationDate : string;
  subscriptionPlanId : string;
  profileCompletionPercentage : string;
  createdAt : string;
  createdBy : string;
  updatedAt : string;
  updatedBy : string;
  userType : string;
  userRegistrationType : string;
  loginPwd : string;
  loginPwdNew : string;
  pwdUpdatedAt : string;
  loginUserName : string;
  
  // profileUpdate : "N"
  // activeAccount : null
  // superUserId : "U-9745785091064228"
  // superUserType : null
  // loginUserId : null
  // orgId : null
  // orgName : null
  // orgDesignation : null
  // invitationSent : null
  // aadharNo : null
  // aadharNoSrc : null
  // panNo : null
  // panNoSrc : null
  // profilePicSrc : null
  // linkedinProfileSrc : null
  // enrollmentNo : null
  // resumePathToS3 : null
  // certificateOfIncorporation : null
  // certificateOfIncorporationPathToS3 : null
  // officePhoneNo : null
  // stateBarCouncilId : null
  // districtId : null
  // lawyerPracticeAreas : null
  // courtsOfPractice : null
  // barCouncilOfIndiaId : null
  // lawyerBankName : null
  // lawyerBankAccount : null
  // lawyerBankBranch : null
  // lawyerBankIfscCode : null
  // clientProfession : null
  // icaiRegistrationNo : null
  // userQualification : null
  // userUniversity : null
  // corporateClientCompanyName : null
  // corporateClientNatureOfBusiness : null
  // corporateClientWebsite : null
  // corporateClientLinkedinProfile : null
  // corporateClientCin : null
  // corporateClientPan : null
  // corporateClientDesignation : null
  // corporateClientProfilePathInS3 : null
  // startDateStr : null
  // endDateStr : null
  // startDate : null
  // endDate : null
  // callType : null
  }

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.css']
})
// @Directive({
//   selector:'.finalcontent'
// })
// export class QuestionDirective{
//    constructor(public elementRef:ElementRef){}
// }


export class FinancialsComponent implements OnInit {
  @ViewChild('testForm') testFormElement;
  myform: FormGroup;
  myform1: FormGroup;
  superUserId : string;
  mes:TestObject[] = [];
  clientData:TestObject[] = [];
  chkBoxDisabled : boolean = true
  mes1 = '';
  viewMode: boolean = true;
  viewModeCheckedList: boolean = true;
  viewModeUncheckedList: boolean = true
  viewMode1: boolean = false;
  viewMode2: boolean = false;
  scheduleofFees: any;
  checkedList: any;
  uncheckedList: any;
  userId : any;
  allStateLists : any = [];
  unallStateLists : any = [];
  myGroup: FormGroup;
  selectedFeesIndex=new Array();
  selectedFeesIndexUncheck=new Array();
  usertype: string;
  emailId: string;
  finalArray=new Array();
  clientUserId: string;
  viewMode3=false;
  clientDetails:any;
  dataArr: ClientProfileSchema;
  finalcontent: any;
  stc:boolean = false;
  selectedIDs:any = [];
  sfa:boolean = false;
  serviceMsg:string;
  showMsg:boolean=false;
  message:string;
  k:any;
  feeagreementList:any=[];
  selectedId:any;
  hasCheckedList=false;
  hasUncheckedList=false;
  clientName:string;
  loader : boolean;
  constructor(private legalService: LegalbellService,private toastr: ToastrService, public fb: FormBuilder,private router: Router,private navigationLinkParameterService: NavigationLinkParameterService,location: Location) {  }

  ngOnInit(): void {

    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.emailId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).emailId: null;
    this.getAllClientsOfSuperUser();
  }
  ngOnDestroy(): void {
  }
  
  getAllClientsOfSuperUser(){
    let body: any;
    body = {"superUserId" : this.superUserId}
            console.log(body);
    
    this.legalService.getAllClientsOfSuperUser(body).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      // let ress: any = JSON.parse(res._body);
      console.log(res.respData);
      this.mes = res.respData;
      this.clientData=res.respData;
      this.mes1 = '';
    
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.toastr.error(this.mes1, '');
      this.mes = [];
    });
  }
  onSelectClient(clientUserId){
    this.hasCheckedList=false;
    this.hasUncheckedList=false;
    this.k=0;
    console.log(clientUserId);
    this.clientData=this.mes;
    this.clientData = this.clientData.filter(obj => obj.userId === clientUserId);
    console.log(this.clientData);
    this.clientName=this.clientData[0].firstName;
    // console.log(this.clientData[0].userType);
    this.userId=clientUserId;
    let body : any;
    this.scheduleofFees=[];
    this.checkedList=[];
    this.uncheckedList=[];
    this.sfa=false;
    if(clientUserId !== '--Select--'){
      this.loader = true;
      body = {"superUserId" : this.superUserId,"clientUserId" : clientUserId,"userType":this.usertype};
      
      console.log(body);
      this.legalService.getFessAgreementPrice(body).subscribe((res: any) => {
        // this.mes= res.responseMessage;
        // let ress: any = JSON.parse(res._body);
        console.log(res.respData);
        // if(res.respData==''){
        //   this.getServicesScheduleOfFees();
        // }else{
          this.scheduleofFees=res.respData;
          if(res.respData.checkedList.length !== 0){
            this.hasCheckedList=true;
            this.checkedList=res.respData.checkedList;
            console.log("Checked List==========");
            console.log(this.checkedList);
          }
          if(res.respData.unCheckedList.length !== 0){
            this.hasUncheckedList=true;
            this.uncheckedList=res.respData.unCheckedList;
          }
          this.viewMode1 = true;
          this.sfa=true;
          this.createForm();
          this.loader = false;
        // }
        
  
      }, (err: any) => {
        this.sfa=false;
        this.scheduleofFees=[];
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.toastr.error(this.mes1, '');
        this.mes = [];
      });
    }
    
  }
  getServicesScheduleOfFees(){
    let body : any;
    body = {"superUserId" : this.superUserId}
    console.log(body);
    this.legalService.getServicesScheduleOfFees(body).subscribe((res: any) => {
      // this.mes= res.responseMessage;
      // let ress: any = JSON.parse(res._body);
      console.log(res.respData);
     
        this.scheduleofFees=res.respData;
        this.viewMode1 = true;
        this.createForm();
    }, (err: any) => {
      let erro: any = JSON.parse(err._body);
      this.mes1 = erro.responseMessage;
      this.toastr.error(this.mes1, '');
      this.mes = [];
    });
  }
  feesId(indexID){
    console.log("Index ID ++++++++++")
    console.log(indexID)
    this.mes1="";
    this.showMsg=false;
    const index: number = this.selectedFeesIndex.indexOf(indexID);
    if(index<0){
      this.selectedFeesIndex.push(indexID);
    }else{
          this.selectedFeesIndex.splice(index, 1);
    }
    // console.log(this.selectedFeesIndex);
  }
  UncheckfeesId(indexID){
    console.log(indexID);
    this.mes1="";
    this.showMsg=false;
    const index: number = this.selectedFeesIndexUncheck.indexOf(indexID);
    if(index<0){
      this.selectedFeesIndexUncheck.push(indexID);
    }else{
          this.selectedFeesIndexUncheck.splice(index, 1);
    }
    console.log(this.selectedFeesIndexUncheck);
  }
  scheduleofFeesSubmitChecked(){
    console.log('called');
    alert('called');
    return false;
  }
  amountChecked(event,index){
    let key= 'amount_'+index;
    this.myform.patchValue({ key : parseInt(event) });
    // console.log(this.myform.value);
  }
  particularsOfServiceChecked(event,index){
    let key= 'particularsOfServices_'+index;
    this.myform.patchValue({ key : event });
    // console.log(this.myform.value);
  }
  scheduleofFeesSubmit(){
    console.log('calling----------');
    
    // this.testFormElement.nativeElement.submit();
    // this.testFormElement.ngSubmit.emit();
    // console.log(this.selectedFeesIndex);
    // console.log(this.myform.value);
    
    const superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    // const usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    const emailId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).emailId: null;
    let formVal=this.myform.value;
    console.log(formVal);
    let formValUncheck=this.myform1.value;
    const distinctArray = this.selectedFeesIndex.filter((n, i) => this.selectedFeesIndex.indexOf(n) === i);
    const distinctArrayUncheck = this.selectedFeesIndexUncheck.filter((m, j) => this.selectedFeesIndexUncheck.indexOf(m) === j);
    // console.log(distinctArray);
    distinctArray.forEach((val, eachIndexFees) => {
      let eachscheduleFees={
        superUserId: superUserId,
        userType: this.clientData[0].userType,
        tribunals: formVal['tribunals_'+val],
        particularsOfServices: formVal['particularsOfServices_'+val],
        amount: formVal['amount_'+val],
        clientUserId:this.userId,
        createdBy : superUserId,
        updatedBy : superUserId,
        id:formVal['id_'+val]
      }
      console.log(eachscheduleFees);
      this.finalArray[eachIndexFees]= eachscheduleFees;
      this.k=eachIndexFees;
      this.k++;
    });
    distinctArrayUncheck.forEach((val1, eachIndexFeesUncheck) => {
      let eachscheduleFeesUncheck={
        superUserId: superUserId,
        userType: this.clientData[0].userType,
        tribunals: formValUncheck['tribunalsUncheck_'+val1],
        particularsOfServices: formValUncheck['particularsOfServicesUncheck_'+val1],
        amount: formValUncheck['amountUncheck_'+val1],
        clientUserId:this.userId,
        createdBy : superUserId,
        updatedBy : superUserId,
        id:formValUncheck['id_'+val1]
      }
      console.log(eachscheduleFeesUncheck);
      this.finalArray[this.k]= eachscheduleFeesUncheck;
      this.k++;

    });
    console.log(this.finalArray);
    if(this.finalArray.length==0){
      this.showMsg=true;
      this.mes1="Please select a service.";
      this.toastr.error(this.mes1)
      return false;
    }
    this.stc=false;
    this.serviceMsg="Review the services before you send to client :";

    // this.legalService.saveFessAgreementPrice(this.finalArray).subscribe((res: any) => {
    //   // this.mes= res.responseMessage;
    //   // let ress: any = JSON.parse(res._body);
    //   console.log(res.respData);
    // }, (err: any) => {
    //   let erro: any = JSON.parse(err._body);
    //   this.mes1 = erro.responseMessage;
    //   this.mes = [];
    // });
    this.legalService.getProfileData(this.userId).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;
          console.log(this.dataArr);
          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );

    
    this.viewMode = false;
    this.viewMode1 = false;
    this.viewMode2 = true;
  }

  //call this
  backofViewMode2(){
    this.finalArray=[];
    this.allStateLists=[];
    this.unallStateLists=[];
    this.uncheckedList=[];
    this.checkedList=[];
    this.selectedFeesIndex=[];
    this.selectedFeesIndexUncheck=[];
    this.feeagreementList=[];
    this.selectedIDs=[];
    this.viewMode = true;
    this.viewMode1 = true;
    this.viewMode2 = false;
    this.selectedId=this.userId;
    this.mes1="";
    this.showMsg=false;
    
      this.onSelectClient(this.selectedId);
  }

  
  checkRevise(val){
    if(val==="Y")
    return true;
    else
    return false;
  }
  checkAgreed(val,indexID){
    // console.log(indexID);
    if(val==="Y")
    {
        // this.selectedFeesIndex.push(indexID);
      return true;
    }
    else
    {
    return false;
    }
  }
  revise(index){
    
    this.chkBoxDisabled = false;
    // let pos=document.getElementsByClassName('particularsOfServices_'+index);
    let amnt=document.getElementsByClassName('amount_'+index);
    
    // (<HTMLElement>pos[0]).removeAttribute("readonly");
    (<HTMLElement>amnt[0]).removeAttribute("readonly");
    let element = document.getElementById(index);
    console.log(element.getAttribute)
    
    element.removeAttribute('disabled');
    const ele = document.getElementById(index) as HTMLInputElement;
    ele.checked = true
    this.selectedFeesIndex.push(index);
    this.mes1="";
    this.showMsg=false;
  }
  //change here'//
  finalAgreedID(id){
    console.log(id);
    this.showMsg=false;
    this.mes1="";
    let indOfID=this.selectedIDs.indexOf(id);
    if( indOfID === -1){
      this.selectedIDs.push(id);
    }else{
      this.selectedIDs.splice(indOfID,1);
    }
    if(!this.viewMode2){
        this.finalAgreed()
    }
    console.log(this.selectedIDs);
  }
  finalAgreed(){
    console.log(this.selectedIDs)
    if(this.selectedIDs!=''){
      let selectedIds=this.selectedIDs;
      this.legalService.agreedByClient(selectedIds).subscribe((res: any) => {
        // this.mes= res.responseMessage;
        // let ress: any = JSON.parse(res._body);
        this.showMsg=false;
        console.log(res);
        
        
        // this.router.navigate(['/financials']);
        // window.location.href='/financials';

        //change by debashis
        this.backofViewMode2();
        this.toastr.success('Request Process Successfull', '');
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.toastr.error(this.mes1, '');
        this.mes = [];
      });
    }else{
      this.showMsg=true;
      this.mes1="please select at least a service.";
      this.message="";
    }
    
    
  }
  finalSubmit(){
    this.serviceMsg="Service Particulars Are Sent To Client For Agreement :";
    this.showMsg=true;
    this.message="You can now select services which are agreed by client.";
    console.log('final call');
    const superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    const usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    const emailId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).emailId: null;
    let finance={
      "clientUserId":this.userId,
      "superUserId":superUserId,
      "clientUserType":this.clientData[0].userType,
      "content" : null,
      "clientFirstName":this.dataArr.firstName,
      "createdBy":superUserId,
      "updatedBy":superUserId,
      "feeagreementList":this.finalArray
      }
      console.log(finance);
    this.legalService.finalSaveFessAgreementPrice(finance).subscribe((res: any) => {
      this.toastr.success(this.message, '');
        // this.mes= res.responseMessage;
        console.log(res);
        // let ress: any = JSON.parse(res._body);
        this.finalArray=res.respData.feeagreementList;
        console.log(res.respData);
        this.backofViewMode2();
        this.stc=true;
        //this.backofViewMode2()
      }, (err: any) => {
        let erro: any = JSON.parse(err._body);
        this.mes1 = erro.responseMessage;
        this.toastr.error(this.mes1, '');
        this.mes = [];
      });
  }
  

  createForm() {
    let formGroupStructure = {
      tribunals             : new FormControl(''),
      particularsOfServices : new FormControl(''),
      amount                : new FormControl(''),
    }
 
   

    this.allStateLists = this.checkedList;
    this.allStateLists.forEach((eachList, eachIndex) => {
      // console.log(eachList.tribunals);
      formGroupStructure['tribunals_' + eachIndex]              =  new FormControl(eachList.tribunals);
      formGroupStructure['particularsOfServices_' + eachIndex]  =  new FormControl(eachList.particularsOfServices);
      formGroupStructure['amount_' + eachIndex]                 =  new FormControl(eachList.amount);
      formGroupStructure['id_' + eachIndex]                     =  new FormControl(eachList.id);
    })
    this.myform = this.fb.group(formGroupStructure);
    console.log('test', this.myform.value);
    console.log(this.uncheckedList);
    this.unallStateLists = this.uncheckedList;
    this.unallStateLists.forEach((uneachList, uneachIndex) => {
      // console.log(eachList.tribunals);
      formGroupStructure['tribunalsUncheck_' + uneachIndex]              =  new FormControl(uneachList.tribunals);
      formGroupStructure['particularsOfServicesUncheck_' + uneachIndex]  =  new FormControl(uneachList.particularsOfServices);
      formGroupStructure['amountUncheck_' + uneachIndex]                 =  new FormControl(uneachList.amount);
      formGroupStructure['id_' + uneachIndex]                     =  new FormControl(null);
    })
    this.myform1 = this.fb.group(formGroupStructure);
    // console.log('test', this.myform1.value);
   
  }
  getProfileData(userid: string) {
    console.log("userid in getProfileData--" + userid);
    this.legalService.getProfileData(userid).
      subscribe(
        data => {
          let res = JSON.parse(this.legalService.userData._body);
          this.dataArr = JSON.parse(this.legalService.userData._body).respData;

          console.log("getProfileData comp res--" + res.status);
          if (res.status === 200 && res.searchCount === 1) {
              
          } else {
            
          }
          console.log("getProfileData comp res--" + JSON.stringify(this.dataArr.emailId));
        }
      );
  }
  
}
