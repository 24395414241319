import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
//import { config } from './../config';
//import { config } from '../';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { profile } from 'console';

const httpOptions={
  headers: new Headers( {
    'Content-Type': 'application/json',
    'x-tag': '1'

  } )
};


@Injectable( {
  providedIn: 'root'
} )
export class CaseService {

  constructor( private http: Http ) { }

  userData: any;

  getUserData(superUserId) {
    return this.http.get( environment.api+'user/getAllClients?superUserId='+superUserId, {} ).pipe( map( res => {
      let resData=res.json();
      return resData;
    } ) );
  }
  
  getMemberListbyType( membertype : string, superUserId :string) {
    console.log('getmemberlistbytype========', superUserId);
    if (membertype == 'advocate' || membertype == 'counsel' || membertype == 'managingpartner' || membertype == 'directorlegal') {
      superUserId = superUserId;
    }
    else {
      superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    }
    console.log('getmemberlistbytype========', superUserId);

    return this.http.post( environment.api+'user/getTeamByType', {
      userType    : membertype,
      superUserId : superUserId
    } ).pipe( map( res => {
      let resData=res.json()
      return resData;
    } ) );

    // return this.http.post(environment.api + 'master/getDistricts', {
    //   "stateId": stateId
    // }).
    //   pipe(map(res => { res.json(), this.userData = res }));
  }

  // //###### get Contract Management Types Start here
  // getContractManagementTypes( membertype : string, superUserId :string) {
  //   return this.http.post( environment.api+'master/getContractManagementTypes', {
  //     userType    : membertype,
  //     superUserId : superUserId
  //   } ).pipe( map( res => {
  //     let resData=res.json()
  //     return resData;
  //   } ) );

  //   // return this.http.post(environment.api + 'master/getDistricts', {
  //   //   "stateId": stateId
  //   // }).
  //   //   pipe(map(res => { res.json(), this.userData = res }));
  // }

  getStageofCase() {
    return this.http.get( environment.api+'master/getStageOfCases', {} ).pipe( map( res => {
      let resData=res.json();
      return resData;
    } ) );
  }

  saveCaseData( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/createCase',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  updateCaseData( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/updateCase',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  postUploadCaseFile( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/uploadDoc',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  postFile( fileList, jsonData ): Observable<boolean> {

    var headers=new Headers();
    const formData: FormData=new FormData();
    for (var i = 0; i < fileList.length; i++) { 
      formData.append("uploadFile", fileList[i],fileList[i].name);
    }
   // formData.append( 'uploadFile', fileToUpload, fileToUpload.name );
    formData.append( 'userId', jsonData.userId );
    formData.append( 'caseNo', jsonData.caseNo );
    formData.append( 'docType', jsonData.docType );
    formData.append( 'hId', jsonData.hId );
    formData.append('loginUserId',jsonData.userId);
    console.log("from data")
    console.log(formData)
   // return this.http.post( environment.api+'user/uploadDoc', formData, {
    return this.http.post( environment.api+'user/uploadMultiDoc', formData, {
      headers: headers
    } ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }



  caseDetails( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/caseDetails',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  partyDetails( payload: any ) {
    console.log( "savepartyDetails service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/PartiesList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  groupByArrayKey( array, key ) {
    // Accepts the array and key
    const groupBy=( array, key ) => {
      // Return the end result
      return array.reduce( ( result, currentValue ) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        ( result[currentValue[key]]=result[currentValue[key]]||[] ).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {} ); // empty object is the initial value for result object
    };

    // Group by color as key to the person array
    return groupBy( array, key );
  }

  saveConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/saveConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  conversationList( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/conversationList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  //############ Get Conversation List Start Here
  getConversationList( payload: any ) {

        console.log( "saveAllData service", payload );
        var json  = payload;
        console.log( payload );
        var headers=new Headers();
        headers.append( 'Content-type', 'application/json' );
        return this.http.post( environment.api+'case/getMyConversationList',
          json, {
            headers: headers
        }
        ).pipe( map( res => {
            console.log( "Serv response--"+res );
            return res.json();
        } ) );
  }
  //############ Get Conversation List Ends Here

  //############ Get Add Conversation To Lists Start Here
  getCaseToUserList( payload: any ) {

    console.log( "saveAllData service", payload );
    var json  = payload;
    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/toUserList',
      json, {
        headers: headers
    }
    ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
    } ) );
}
//############ Get Add Conversation To Lists Ends Here

  getSingleConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/getSingleConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
  deleteSingleConversation( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/deleteSingleConversation',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  removeDoc( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/removeDoc',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  removeHearing( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/deleteHearingDtl',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveMemberPermission( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/saveMemberPermission',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  // Save Contract Member Permission
  saveContractMemberPermission( payload: any ) {
    console.log( "saveAllData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/saveContractMemberPermission',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }



  memberList( payload: any ) {
    console.log( "memberList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/memberList',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  //########### Contract Member List
  memberListContract( payload: any ) {
    console.log( "memberList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/memberListContract',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  updatePartyData( payload: any ) {
    console.log( "updatePartyData service", payload );
    var json=payload;
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/saveParties',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  //get state list 
  getSateList() {

    return this.http.get(environment.api + 'master/getStates', {
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  
  
  //get district list 
  getDistrictList(stateId: string) {

    return this.http.post(environment.api + 'master/getDistricts', {
      "stateId": stateId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  getCountryList() {

    return this.http.post(environment.api + 'master/getCountries', {
      
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  //get court list 
  getCourtList(stateId: string,districtId: string = "") {

    return this.http.post(environment.api + 'master/getDistrictCourts', {
      "districtId" : districtId,
      "stateId" : stateId
    }).
      pipe(map(res => { res.json(), this.userData = res }));
  }

  memberPermissionList( payload: any ) {
    console.log( "memberPermissionList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'case/getMemberPermissionInCase',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  //Member permission Contract List
  memberPermissionContractList( payload: any ) {
    // console.log( "memberPermissionContractList service", payload );
    var json=payload;

    console.log( payload );
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'contract/getMemberPermissionIncontract',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }


  //########### Remove Member Permissinn Start Here
  removeMemberPermission(payload : any){
      var json  = payload;
      // console.log(payload);
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api+'case/deleteSingleMemberPermission',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
  }
  //########### Remove Member Permissinn Ends Here

  //########### Remove Contract Member Permissinn Start Here
  removeContractMemberPermission(payload : any){
      var json  = payload;
      // console.log(payload);
      var headers=new Headers();
      headers.append( 'Content-type', 'application/json' );
      return this.http.post( environment.api+'contract/deleteSingleContractMemberPermission',
        json, {
        headers: headers
      }
      ).pipe( map( res => {
        console.log( "Serv response--"+res );
        return res.json();
      } ) );
  }
  //########### Remove Contract Member Permissinn Ends Here


  saveNewInsuranceData( fileList, jsonData ): Observable<boolean> {

    var headers=new Headers();
    
    const formData: FormData=new FormData();
    //formData.append("uploadFile", fileList,fileList.name);
    if(fileList){
    formData.append("uploadFile", fileList,fileList.name);
  }else{
    formData.append("uploadFile",new Blob(),null);
  }
   // formData.append( 'uploadFile', fileToUpload, fileToUpload.name );
    formData.append( 'clientUserId', jsonData.userId);
    formData.append( 'caseNo', jsonData.userId );
    formData.append( 'hearingId', jsonData.hearingId );
    formData.append( 'hearingJudje', jsonData.hearingJudje );
    formData.append('loginUserId',jsonData.userId);
    formData.append('hearingDate',jsonData.hearingDate);
    console.log("from data")
    console.log(formData)
   
   
    return this.http.post( environment.api+'user/saveInsuranceHistory', formData, {
      headers: headers
    } ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  saveNewHearingData( fileList, jsonData ): Observable<boolean> {

    var headers=new Headers();
    
    const formData: FormData=new FormData();
    //formData.append("uploadFile", fileList,fileList.name);
    if(fileList){
    formData.append("uploadFile", fileList,fileList.name);
  }else{
    formData.append("uploadFile",new Blob(),null);
  }
   // formData.append( 'uploadFile', fileToUpload, fileToUpload.name );
    formData.append( 'clientUserId', localStorage.getItem("CIDVALUE") );
    formData.append( 'caseNo', jsonData.caseNo );
    formData.append( 'hearingId', jsonData.hearingId );
    formData.append( 'hearingJudje', jsonData.hearingJudje );
    formData.append('loginUserId',jsonData.userId);
    formData.append('hearingDate',jsonData.hearingDate);
    console.log("from data")
    console.log(formData)
   
   
    return this.http.post( environment.api+'user/saveHearingHistory', formData, {
      headers: headers
    } ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  getHiringList(payload : any){
    var json  = payload;
    // console.log(payload);
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/getCaseHearingHistory',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }

  deleteHiringList(payload : any){
    var json  = payload;
    // console.log(payload);
    var headers=new Headers();
    headers.append( 'Content-type', 'application/json' );
    return this.http.post( environment.api+'user/deleteHearingDtl',
      json, {
      headers: headers
    }
    ).pipe( map( res => {
      console.log( "Serv response--"+res );
      return res.json();
    } ) );
  }
}