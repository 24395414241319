import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { ActivatedRoute, RouteReuseStrategy, Router } from '@angular/router';

@Component({
  selector: 'app-tasklist',
  templateUrl: './tasklist.component.html',
  styleUrls: ['./tasklist.component.css']
})
export class TasklistComponent implements OnInit {
  tasklist=[];
  userId : string;
  usertype : string;
  taskpermission : string;

  constructor(private legalbellService: LegalbellService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem( 'profileInfo' )? JSON.parse( localStorage.getItem( 'profileInfo' ) ).userIdlogin:null;
    this.usertype = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    this.taskpermission = localStorage.getItem('taskmanagementpermission')?localStorage.getItem('taskmanagementpermission'): null;
    console.log("servicemasterpermission:::",this.taskpermission);
    // this.route.paramMap.subscribe(params => {
    //   if (params.get('id') != null) {
    //     this.userId = params.get('id');
    //     this.getTaskList(this.userId);
    //   }else{
    //     this.getTaskList(null);
    //   }
    //   console.log("userId in oninit::", this.userId);
      
    // });
    this.getTaskList(this.userId);
    
  }

  getTaskList(userId : string) {
    this.legalbellService.getTaskList(userId).
      subscribe(
        data => {
          this.tasklist=JSON.parse( this.legalbellService.userData._body ).respData;
          console.log( "tasklist comp res--"+JSON.stringify( this.tasklist ) );
        }
      );
  }
  
}
