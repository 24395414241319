import { Component, OnInit } from '@angular/core';
import { LegalbellService } from '../service/legalbell.service'
import { ActivatedRoute, RouteReuseStrategy, Router } from '@angular/router';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';  
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import countryCodeList from '../shared/static/phone-country-code.list';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserListComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
  dataUser:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "UnitedKingdom"
public countryCodeList: Array<any> = countryCodeList;
 
  userlist = [];
  dataArr: any[] = [];
  saveDisplay = 'none';
  mydata: any;
  userId:string;
  modalDisplay: boolean = false;
  userTypeArr: any[] = [['Individual', 'individualclient'], ['Corporate', 'corporateclient']];
  emaiid: string;
  mailsent: boolean = true;
  mailid: string;
  phoneno: string;
  firstname: string;
  companyname: string;
  mes = '';
  mes1 = '';
  viewMode: boolean = false;
  userType : string = 'individualclient';
  loginUserType : string;
  superUserId : string;
  loginUserId : string;
  superUserType : string;
  clientaddpermission : string;

  inviteNewClient  : FormGroup;
  countryCodeMobilePh: string = environment.defaultCountryCode;
  
  constructor(private legalbellService: LegalbellService,private router: Router, private toastr: ToastrService,  private fb: FormBuilder,) { } 

  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem('profileInfo'));
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.loginUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).usertype: null;
    console.log("this.loginUserType in clientroster:::",this.loginUserType);
    this.superUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserId: null;
    console.log("this.superUserId in clientroster:::",this.superUserId);
    this.loginUserId = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).userIdlogin: null;
    console.log("this.loginUserId in clientroster:::",this.superUserId);
    this.clientaddpermission = localStorage.getItem('clientrosterpermission')?localStorage.getItem('clientrosterpermission'): null;
    console.log("clientaddpermission:::",this.clientaddpermission);
    //this.superUserType = localStorage.getItem('profileInfo')?JSON.parse(localStorage.getItem('profileInfo')).superUserType: null;
    if(this.loginUserType=='advocate'||this.loginUserType=='counsel'||this.loginUserType=='directorlegal'||this.loginUserType=='managingpartner'){
      this.getUserList(this.loginUserId);
    }else{
      this.getUserList(this.superUserId);
    }



    this.inviteNewClient = this.fb.group({
      title           : new FormControl(''),
      firstname       : new FormControl('', [Validators.required]),
      companyname       : new FormControl(''),
      middlename      : new FormControl(''),
      surname         : new FormControl('', [Validators.required]),
      mailid          : new FormControl('', [Validators.required]),
      phoneno         : new FormControl('', [Validators.required]),
      // orgName         : new FormControl('', [Validators.required]),

      


});



  }
  getUserList(superUserId : string) {
    this.legalbellService.getUserListRoster(superUserId).
      subscribe(
        data => {
          this.userlist = JSON.parse(this.legalbellService.userData._body).respData;
          console.log("getUserList comp res--" + JSON.stringify(this.userlist)); 
        }
      );
  }
  closeSaveModal() {
    console.log('closeSaveModal---');
    this.saveDisplay = 'none';
  }
  deleteCorfirmation(userid:string){
    console.log("userid to delete::",userid);
    this.userId = userid;
    this.modalDisplay = true;
  }
  deleteData(){
    this.legalbellService.deactivateUser(this.userId).
        subscribe(
          data => {
            this.mydata = JSON.parse(this.legalbellService.userData._body);
            console.log("statusCode comp res--" + this.mydata.status);
            if (this.mydata.status == '200') {
              window.alert("User deactivated successfully.");
              this.getUserList(this.superUserId); 
              this.router.navigate(['/userlist']);
            }
            else {
              window.alert("User not deactivated successfully.");
            }
          }
        );
    this.closeSaveModal();
  }

  registration(){
    console.log("mail id to sent::", this.inviteNewClient);
    this.markFormGroupTouched(this.inviteNewClient);
    if(this.inviteNewClient.valid) {
      // if(this.mailid==''||this.mailid===undefined) {
      //   window.alert("Please give a valid mail id.")
      // }else if(this.firstname==''||this.firstname===undefined){
      //   window.alert("Please give a valid name.")
      // }
      // else{
              // return false;
              let phoneno = this.inviteNewClient.value.phoneno.number.replace(/\s/g, "");
              phoneno =  phoneno.replace(/-/g, "");
              this.countryCodeMobilePh = this.inviteNewClient.value.phoneno.dialCode
              this.legalbellService.registration(this.inviteNewClient.value.mailid, phoneno, this.inviteNewClient.value.title, this.inviteNewClient.value.firstname, this.inviteNewClient.value.middlename,this.inviteNewClient.value.surname, this.userType,"invite",this.loginUserType,this.inviteNewClient.value.companyname, this.countryCodeMobilePh).subscribe((res: any) => {
               
                // this.mes= res.responseMessage;
                this.ngOnInit();
                let ress: any = JSON.parse(res._body);
                console.log(ress.responseMessage);
                let userId = { "userId": ress.respData };
                //this.mes = ress.responseMessage;
                this.mes = "Invititation sent successfully.";
                this.toastr.success(this.mes, '');
                this.mes1 = '';
                //this.viewMode = true;
                this.viewMode = false;
                $('#myModa2').modal('hide');
                //this.modalclose();
                //this.mailsent = false;
                //window.alert(ress.responseMessage);
                setTimeout( () => {
                  //location.reload();
                 
                }, 2000 );
              }, (err: any) => { 
                let erro: any = JSON.parse(err._body);
                this.mes1 = erro.responseMessage;
                this.toastr.error(this.mes1, '');
                this.mes = '';
                $('#myModa2').modal('hide');
                //this.mailsent = false;
                //window.alert(erro.responseMessage);
              });
      // }

    }


  }

  onCountryCodeMobilePhChange (currentSelection: string) {
    this.countryCodeMobilePh = currentSelection;
  }

  modalclose(){
    this.inviteNewClient.reset();
    this.viewMode = false;
    console.log("view mode::",this.viewMode);
  }
  chageuser(usertype){
    console.log("userType::",usertype);
    this.userType = usertype;
  }

  changeToReset(){
    this.inviteNewClient.reset();
  }

  private markFormGroupTouched( formGroup: FormGroup ) {
    ( <any>Object ).values( formGroup.controls ).forEach( control => {
      control.markAsTouched();

      if ( control.controls ) {
        this.markFormGroupTouched( control );
      }
    } );
  }

  goFeeAgreementList(firstname,lastname){
    localStorage.setItem("NameForDispalye","")
    if(lastname){
      var dispalyname = firstname + lastname;
      localStorage.setItem("NameForDispalye",dispalyname)
    }else{
      localStorage.setItem("NameForDispalye",firstname)
    }

  }
}
