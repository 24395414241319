<div class="tab-pane" id="tab_4">
   <div *ngIf="loader" class="spinnerbox">  
      <div class="spinner"></div>
   </div>
    <form [formGroup]="partyForm" >
       <div class="table-responsive" style="overflow-x: initial">
          <table class="table">
             <thead>
                <tr>
                   <th>Type <span class="text-danger">*</span></th>
                   <th>Name <span class="text-danger">*</span></th>
                   <th  >Mobile <span class="text-danger">*</span></th>
                   <th>E-mail</th>
                   <th *ngIf="!viewMode" align="center" width="100">Action</th>
                </tr>
             </thead>
             <tbody formArrayName="partyDtlVo" *ngIf="partyForm.get('partyDtlVo')">
                <tr *ngFor="let party of partyDtlVo().controls; let partyIndex=index">
                <ng-container [formGroup]="party">
                <td>
                   <select class="form-control" formControlName="partyType" required>
                      <option value="">--Select--</option>
                      <option value="PLAINTIFFS">PLAINTIFFS</option>
                      <option value="PETITIONERS">PETITIONERS</option>
                      <option value="COMPLAINANTS">COMPLAINANTS</option>
                      <option value="DEFENDANTS">DEFENDANTS</option>
                      <option value="RESPONDENTS">RESPONDENTS</option>
                      <option value="OPPOSITE PARTIES">OPPOSITE PARTIES</option>
                   </select>
                   <small class="text-danger" *ngIf="party.controls.partyType.hasError('required') && ( party.controls.partyType.dirty || party.controls.partyType.touched || (!party.controls.partyType.valid)) && submitted">Please select a type.</small>
                </td>
                <td>
                     <input type="text" class="form-control" formControlName="partyName" placeholder="Enter Name ..." required>
                     <small class="text-danger" *ngIf="party.controls.partyName.hasError('required') && ( party.controls.partyName.dirty || party.controls.partyName.touched || (!party.controls.partyName.valid)) && submitted">Please enter a name.</small>
               </td>
                <td>
                  <div style="display: flex">
                     <!--<app-country-code-dropdown style="border-top: 1px solid #e4dcdc;border-left: 1px solid #e4dcdc;width:56%"
                     [countryCode]="countryCodeMobilePh" 
                     (onCountryChange)="onCountryCodeMobilePhChange(partyIndex,$event)"
                  >
                   </app-country-code-dropdown> -->
                   <!-- <select class="form-control" formControlName="countryCode" required>
                     <option *ngFor="let opt of countryCodeList" [value]="opt.dial_code">
                        ({{ opt.code }}) {{ opt.dial_code }}
                     </option>
                     
                  </select>
                   <input type="tel" class="form-control" formControlName="partyPhoneNo" id="partyPhoneNo" placeholder="Enter Mobile ..." pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$" required> -->
                   <ngx-intl-tel-input 
                                  [cssClass]="'form-control'" 
                                  [attr.disabled]="viewMode"
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="party.controls.countryCode.value"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="partyPhoneNo" required>
                              </ngx-intl-tel-input>
                   </div>
                  <div>
                   <small class="text-danger" *ngIf="party.controls.partyPhoneNo.hasError('required') && ( party.controls.partyPhoneNo.dirty || party.controls.partyPhoneNo.touched || (!party.controls.partyPhoneNo.valid)) && submitted">Please enter a mobile number.</small>
                   <small class="text-danger" *ngIf="!party.controls.partyPhoneNo.hasError('required') && party.get('partyPhoneNo').invalid && party.get('partyPhoneNo').touched && submitted">Please provide correct mobile number.</small>
                  </div>
                </td>
                <td>
                  <!-- <input type="email" class="form-control" formControlName="partyEmailId" id="partyEmailId" placeholder="Enter Email ..." pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                  <small class="text-danger" *ngIf="party.controls.partyEmailId.hasError('required') && ( party.controls.partyEmailId.dirty || party.controls.partyEmailId.touched || (!party.controls.partyEmailId.valid)) && submitted">Please enter a email.</small> -->
                  <input type="email" class="form-control" formControlName="partyEmailId" id="partyEmailId" placeholder="Enter Email ..." pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                  <small class="text-danger" *ngIf="party.get('partyEmailId').errors?.pattern">Please provide correct email.</small>

                </td>
                <td *ngIf="!viewMode">
                   <button *ngIf="partyIndex == (partyDtlVo().length-1)" type="button" class="btn btn-success"
                   (click)="addParty()">
                   <i class="fa fa-plus" aria-hidden="true"></i>
                   </button>
                   <button *ngIf="partyIndex < (partyDtlVo().length-1)" type="button" class="btn btn-danger"
                   (click)="removeParty(partyIndex)">
                   <i class="fa fa-minus" aria-hidden="true"></i>
                   </button>
                </td>
            </ng-container>
                </tr>
             </tbody>
          </table>
       </div>
       <div class="box-footer" >
          <button type="submit" class="btn btn-primary pull-right" (click)='partyUpdate()' [attr.disabled]="viewMode ? '' : null">Save Representation</button>
       </div>
    </form>
    <!-- <div class="card-body box-info" *ngIf="showMsg" style="margin-top: 15px;">
        <span style="color: green;font-weight: bold;font-size: 18px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
        <span style="color: red;font-weight: bold;font-size: 18px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span>
     </div> -->
 </div>
