<div class="tab-pane" id="tab_3" >

   <div *ngIf="loader" class="spinnerbox">  
      <div class="spinner"></div>
   </div>
   <!-- <div class="alert alert-info" role="alert">

   <i class="fa fa-info-circle" style="font-size:16px"> <strong> To assign a team, select "Member Type", select "Member Name", assign privilege and click the "Save Team" button.
      You can assign as many members as available in your "Member Name". Assign by clicking the "Add" button.  </strong></i></div> -->
      <!-- <i class="fa fa-info-circle text-info" style="font-size:16px"> <strong class="text-info"> To assign a team, select "Member Type", select "Member Name", assign privilege and click the "Save Team" button.
         You can assign as many members as available in your "Member Name". Assign by clicking the "Add" button.  </strong></i> -->
   <div class="box-footer" style="border-top: none;">
      <!-- <p class="text-success">{{mes}}</p>
      <p class="text-danger">{{mes1}}</p> -->
      <!-- <button [attr.disabled]="(btnSppiner) ? '' : null" type="submit" class="btn bg-primary pull-right"
         (click)="assignTeamSubmitNew()">
         <i class="fa fa-spinner fa-spin" *ngIf="btnSppiner"></i>
         Submit
      </button> -->
     
      <button  type="button" class="btn bg-primary pull-right add-more add-team-btn" (click)="addTeam(1)" [attr.disabled]="((addTeamIndex+editTeamIndex +1) == memberTypelist.length) ?'' : (viewMode ? '' : null)" *ngIf="createMode ==  true || editMode == true">
         <i class="fa fa-plus" aria-hidden="true"></i> Add
      </button>
   </div>

   
   
   <!--################# Assign Team Add Form Start here #################-->
   <form [formGroup]="assignTeamForm">
      <div>
         <div class="table-responsive">
            <table *ngIf="!viewMode" class="table">
               <thead>
                  <tr>
                     <th>Member Type</th>
                     <th>Member Name</th>
                     <th>Privilege</th>
                     <th width="100">Action</th>
                  </tr>
               </thead>
               <tbody *ngIf="loginUserType != 'juniorassociate'" formArrayName="TeamForms">
                  <tr *ngFor="let team of TeamForms().controls; let teamIndex=index" [formGroupName]="teamIndex">

                     <td>
                        <select name="memberUserType" class="form-control" formControlName="memberUserType"
                           (change)="onselectmemberType($event.target.value,teamIndex,'A')"
                           id="memberUserType{{teamIndex}}">
                           <option value=""><strong>--Select--</strong></option>
                           <option *ngFor="let eachMembertype of memberTypelist" [value]="eachMembertype.teamUserType">
                              {{eachMembertype.teamUserTypeName}}
                           </option>
                           <!-- <option value="seniorassociate">Senior Associate</option> -->

                        </select>
                        <small class="text-danger" id="infoType{{teamIndex}}"></small>
                     </td>
                     <td>
                        <input type="hidden" name="caseNo" formControlName="caseNo">
                        <input type="hidden" name="memberUserName" formControlName="memberUserName" id="memberUserName_{{teamIndex}}">

                        <select name="memberUserId" class="form-control" formControlName="memberUserId"
                           (change)="clickmemberName($event.target.value,teamIndex, 'A')"
                           id="memberUserId{{teamIndex}}">
                           <option value=""><strong>--Select--</strong></option>
                           <option *ngFor="let opt of memberNameListA[teamIndex]" [value]="opt.userId">
                              <strong>{{opt.firstName}} {{opt.lastName}}</strong>
                           </option>
                        </select>
                        <small class="text-danger" id="infoID{{teamIndex}}"></small>
                     </td>
                     <td>
                        <button *ngIf="!viewMode" type="button" class="btn btn-primary" data-toggle="modal"
                           data-target="#privilegemyModal" (click)="privilegemyModal(teamIndex)">Assign
                           Privilege</button>
                        <!-- Modal -->
                        <div id="privilegemyModal{{teamIndex}}" class="modal fade" role="dialog">
                           <div class="modal-dialog">
                              <!-- Modal content-->
                              <div class="modal-content">
                                 <div class="modal-header">
                                    <button *ngIf="!viewMode" type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">Assign Privilege {{memberName}}</h4>
                                 </div>
                                 <div class="modal-body">
                                    <div class="table-responsive">
                                       <table class="table">
                                          <thead>
                                             <tr>
                                                <th>Folder</th>
                                                <th>Upload</th>
                                               <!-- <th>Delete</th> -->
                                                <th>View</th>
                                             </tr>
                                          </thead>
                                          <tbody formArrayName="permission">
                                             <tr *ngFor="let perimission of teamPermissions(teamIndex).controls; let
                                           permissionIndex=index" [formGroupName]="permissionIndex">
                                                <td>

                                                   <input class="form-control" type="hidden" placeholder="folder name"
                                                      formControlName="id">
                                                   <input class="form-control" style="text-transform: uppercase" type="text" placeholder="folder name"
                                                      formControlName="folderName" readonly>
                                                </td>
                                                <td>
                                                   <input type="checkbox" value="upload" formControlName="upload" required>
                                                </td>

                                            <!---   <td>
                                                   <input type="checkbox" value="delete" formControlName="delete">
                                                </td> -->

                                                <!--<td>
                                                   <input type="checkbox" value="delete" formControlName="delete" required>
                                                </td> -->

                                                <td>
                                                   <input type="checkbox" value="view" formControlName="view" required>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div class="modal-footer">
                                    <button type="button" class="btn btn-primary pull-left"
                                       (click)="privilegemyModal(teamIndex,'hide')">Submit</button>
                                    <button type="button" class="btn btn-default pull-right"
                                       data-dismiss="modal">Close</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </td>
                     <td>
                        <!-- <button *ngIf="teamIndex==(TeamForms().length-1)" type="button" class="btn btn-success"
                      (click)="addTeam(teamIndex)">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      </button> -->
                        <button *ngIf="teamIndex>0" type="button" class="btn btn-danger"
                           (click)="removeTeam(teamIndex, 'A')">
                           <i class="fa fa-minus" aria-hidden="true"></i>
                        </button>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <!-- <div class="box-footer">
             <button type="submit" class="btn btn-primary" (click)="assignTeamSubmit()">Assign Team </button>
             </div> -->
      </div>
   </form>
   <!--################# Assign Team Add Form Ends here #################-->

   <!----/Edit and listing section/------->
   <form [formGroup]="assignTeamEditForm">
      <div [hidden]="assignTeamList.length == 0">
         <div class="table-responsive">
            <table class="table" datatable [dtOptions]="teamDtOptions" [dtTrigger]="teamTrigger">
               <thead>
                  <tr>
                     <th>Added Members List:</th>
                     <th></th>
                     <th></th>
                     <th width="100"></th>
                  </tr>
               </thead>
               <tbody formArrayName="TeamEditForms" class="text-left">

                  <tr *ngFor="let team of TeamListingEditForms().controls; let teamIndex=index"
                     [formGroupName]="teamIndex">
                     <td>
                        <select name="memberUserType" class="form-control" formControlName="memberUserType"
                           [attr.disabled]="(assignTeamList[teamIndex].disabled) ?'' : null"
                           (change)="onselectmemberType($event.target.value,teamIndex,'E')"
                           id="editmemberUserType{{teamIndex}}">
                           <option value=""><strong>--Select--</strong></option>
                           <option *ngFor="let eachMembertype of memberTypelist" [value]="eachMembertype.teamUserType">
                              {{eachMembertype.teamUserTypeName}}
                           </option>

                        </select>
                        <small class="text-danger" id="infoTypeEdit{{teamIndex}}"></small>
                     </td>
                     <td>

                        <input type="hidden" name="caseNo" formControlName="caseNo">
                        <input type="hidden" name="memberUserName" formControlName="memberUserName" id="memberUserName_{{teamIndex}}">


                        <select name="memberUserId" class="form-control" formControlName="memberUserId"
                           [attr.disabled]="(assignTeamList[teamIndex].disabled) ?'' : null"
                           (change)="clickmemberName($event.target.value,teamIndex, 'E')"
                           id="editmemberUserId{{teamIndex}}">
                           <option value=""><strong>--Select--</strong></option>
                           <option *ngFor="let opt of memberNameListE[teamIndex]" [value]="opt.userId">
                              <strong>{{opt.firstName}} {{opt.lastName}}</strong>
                           </option>
                        </select>
                        <small class="text-danger" id="infoIDEdit{{teamIndex}}"></small>
                     </td>
                     <td>
                        <button *ngIf="!viewMode" type="button" class="btn btn-primary" data-toggle="modal"
                           data-target="#privilegemyEditModal" (click)="privilegemyEditModal(teamIndex)"
                           [attr.disabled]="(assignTeamList[teamIndex].disabled) ?'' : null">Assign
                           Privilege</button>
                        <!-- Modal -->
                        <div id="privilegemyEditModal{{teamIndex}}" class="modal fade" role="dialog">
                           <div class="modal-dialog">
                              <!-- Modal content-->
                              <div class="modal-content">
                                 <div class="modal-header">
                                    <button *ngIf="!viewMode" type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">Assign Privilege</h4>
                                 </div>
                                 <div class="modal-body">
                                    <div class="table-responsive">
                                       <table class="table">
                                          <thead>
                                             <tr>
                                                <th>Folder</th>
                                                <th>Upload</th>
                                             <!--   <th>Delete</th> -->
                                                <th>View</th>
                                             </tr>
                                          </thead>
                                          <tbody formArrayName="permission">
                                             <tr *ngFor="let perimission of teamListingEditFormPermissions(teamIndex).controls; let
                                                       permissionIndex=index" [formGroupName]="permissionIndex">
                                                <td> 
                                                   <input class="form-control" type="hidden" placeholder="folder name"
                                                      formControlName="id">
                                                   <input class="form-control" style="text-transform: uppercase" type="text" placeholder="folder name"
                                                      formControlName="folderName" readonly>
                                                </td>
                                                <td>
                                                   <input type="checkbox" value="upload" formControlName="upload">
                                                </td>
                                             <!--   <td>
                                                   <input type="checkbox" value="delete" formControlName="delete">
                                                </td> -->
                                                <td>
                                                   <input type="checkbox" value="view" formControlName="view">
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div class="modal-footer">
                                    <button *ngIf="!viewMode" type="button" class="btn btn-primary pull-left"
                                       (click)="privilegemyEditModal(teamIndex,'hide')">Submit</button>
                                    <button *ngIf="!viewMode" type="button" class="btn btn-default pull-right"
                                       data-dismiss="modal">Close</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </td>
                     <td>
                        <button *ngIf="!viewMode" type="button" class="btn btn-success" (click)="editTeam(teamIndex)">
                           <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-danger" (click)="removeTeam(teamIndex, 'E')" *ngIf="createMode ==  true || editMode == true">
                           <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </form>


   <div class="box-footer" *ngIf="createMode ==  true || editMode == true">
      <button *ngIf="!viewMode" [disabled]="(btnSppiner) ? '' : null || btnDisabled" type="submit" class="btn bg-primary pull-right" 
            (click)="assignTeamSubmitNew()" x>
            <i class="fa fa-spinner fa-spin" *ngIf="btnSppiner"></i>
            Save Team
      </button>
   </div>


</div>



<div class="modal fade in" id="removeConfirm" role="dialog">
   <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header">
            <h4 class="modal-title">Confirm to remove</h4>
         </div>
         <div class="modal-body">
            <p>Are you sure want to remove?</p>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="removeConfirm(removeConfirmIndex)">Yes</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
         </div>
      </div>
   </div>
</div>