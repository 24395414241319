
<div class="content-wrapper">
   
   <section class="content">
    <div *ngIf="loaderEditCase" class="spinnerbox">  
       <div class="spinner"></div>
    </div> 

      <div class="bottom-height">
      <div class="box-header with-border">
         <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">event</i> </div>
         <h3 class="box-title main-header-top" *ngIf="createMode === true">Case Creation </h3>
         <h3 class="box-title main-header-top" *ngIf="createMode === false">Case Information </h3>
      </div>
 
      <form [formGroup]="caseForm">
       <!-- <div *ngIf="loaderEditCase" class="spinnerbox">  
          <div class="spinner"></div>
       </div> -->
         <div class="box-body">
            <div class="box">
               <div class="box-body box-header">
                  <div class="row">
                     <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2">
                        <h3 class="box-title">Client Name: <span class="text-danger">*</span> </h3>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <select name="selectedQuantity"  #mySelect id="aantal" class="form-control" (change)="getClientId(mySelect.value)" formControlName="userId"
                           [attr.disabled]="viewMode ? '' : null || editMode ? '' : null" required>
                           <option [value]="">--Select--</option>
                           <option *ngFor="let opt of clientName" [value]="opt.userId">
                           <strong>{{opt.clientShowName}}</strong>
                           </option>
                        </select>
                        <small class="text-danger" *ngIf="caseForm.controls.userId.hasError('required') && (
                           caseForm.controls.userId.dirty || caseForm.controls.userId.touched ||
                           (!caseForm.controls.userId.valid && submittedForm))">Please select Client Name.</small>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
                     </div>
                  </div>
               </div>
               <div class="box-header with-border">
                  <h3 class="box-title">GENERAL INFORMATION </h3>
               </div>
               <div class="box-body">
                  <div class="table-responsive">
                     <table class="table">
                        <tbody>
                           <tr>
                              <!-- <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4"><label>Our Ref Number </label>
                                 <input type="text" class="form-control"   formControlName="caseNo" placeholder="Enter Court Case number..." readonly>
                               
                              </td> -->
                              <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4"><label>Other Org Ref Number </label>
                                 <input type="text" class="form-control"   formControlName="otherRefNo" placeholder="Enter Other Org Ref number...">
                               
                              </td>
                              <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4"><label>Referred By </label>
                                 <input type="text" class="form-control"   formControlName="refferedBy" placeholder="Enter Reffered By...">
                               
                              </td>
                           </tr>
                           <tr>
                              
                              <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4"><label>Court Ref Number </label>
                                 <input type="text" class="form-control"   formControlName="courtCaseNo" placeholder="Enter Court Case number...">
                               
                              </td>
                              <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4"><label>Case Title <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control" placeholder="Enter Case title..." formControlName="caseTitle"
                                    [attr.disabled]="viewMode ? '' : null">
                                 <small class="text-danger" *ngIf="caseForm.controls.caseTitle.hasError('required') && (
                                    caseForm.controls.caseTitle.dirty || caseForm.controls.caseTitle.touched ||
                                    (!caseForm.controls.caseTitle.valid && submittedForm))">Please enter Case Title.</small>
                              </td>
                              <td class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                 <label>Type of Case<span class="text-danger">*</span> </label>
                                 <select class="form-control" formControlName="caseType"> 
                                    <option value="">--Select--</option>
                                    <option value="Administrative">Administrative</option>
                                    <option value="Animal Law">Animal Law</option>
                                    <option value="Auto-Accident">Auto-Accident</option>
                                    <option value="Agriculture & rural issues">Agriculture & rural issues</option>
                                    <option value="Aviation/aerospace">Aviation/aerospace</option>
                                    <option value="Banking & Finance">Banking & Finance</option>
                                    <option value="Bankruptcy">Bankruptcy</option>
                                    <option value="Bankruptcy-Debtor Insolvency-Consumer">Bankruptcy-Debtor Insolvency-Consumer</option>
                                    <option value="Business Formation and Compilance">Business Formation and Compilance</option>
                                    <option value="Capital Markets">Capital Markets</option>
                                    <option value="Charities">Charities</option>
                                    <option value="Civil Litigation">Civil Litigation</option>
                                    <option value="Conveyance">Conveyance</option>
                                    <option value="Civil liberties & human rights">Civil liberties & human rights</option>
                                    <option value="Clinical negligence">Clinical negligence</option>
                                    <option value="Company & Commercial">Company & Commercial</option>
                                    <option value="Competition & EU">Competition & EU</option>
                                    <option value="Construction & Engineering">Construction & Engineering</option>
                                    <option value="Corporate Finance/ Mergers  & acquisitions">Corporate Finance/ Mergers & acquisitions</option>
                                    <option value="Crime">Crime</option>
                                    <option value="Corporate tax">Corporate tax</option>
                                    <option value="Defamation/reputation management">Defamation/reputation management</option>
                                    <option value="Dispute resolution">Dispute resolution</option>
                                    <option value="Elder">Elder</option>
                                    <option value="Employment,pensions & incentives">Employment,pensions & incentives</option>
                                    <option value="Energy & Natural resources ">Energy & Natural resources </option>
                                    <option value="Family/Matrimonial">Family/Matrimonial</option>
                                    <option value="Financial services">Financial services</option>
                                    <option value="Fraud">Fraud</option>
                                    <option value="Housing/Landlord & tenant">Housing/Landlord & tenant</option>
                                    <option value="Immigration">Immigration</option>
                                    <option value="Insolvency/restructuring">Insolvency/restructuring</option>
                                    <option value="TAX">Insurance/reinsurance</option>
                                    <option value="In-house Consuel">In-house Consuel</option>
                                    <option value="Intellectual property">Intellectual property</option>
                                    <option value="Media & entertainment">Media & entertainment</option>
                                    <option value="Personal Injury">Personal Injury</option>
                                    <option value="Wills and Estate">Wills and Estate</option>
                                    <option value="Private client">Private client</option>
                                    
                                 </select>
                                 <small class="text-danger" *ngIf="caseForm.controls.caseType.hasError('required') && (
                                    caseForm.controls.caseType.dirty || caseForm.controls.caseType.touched ||
                                    (!caseForm.controls.caseType.valid && submittedForm))">Please select Case Type.</small>
                              </td>
                           </tr>
                           <tr>
                              <td colspan="4">
                                 <label>Case Description <span class="text-danger">*</span> </label>
                                 <textarea class="form-control" formControlName="caseDesc" rows="" placeholder="Enter Case Description..."></textarea>
                                 <small class="text-danger" *ngIf="caseForm.controls.caseDesc.hasError('required') && (
                                    caseForm.controls.caseDesc.dirty || caseForm.controls.caseDesc.touched ||
                                    (!caseForm.controls.caseDesc.valid && submittedForm))">Please enter Case Description.</small>
                              </td>
                           </tr>
                           <tr>
                              <td><label>Principal Lawyer <span class="text-danger">*</span></label>
                                   <input type="text" class="form-control"   formControlName="responsibleAttorney" placeholder="Enter Responsible Attorney...">
                                 
                              </td>
                              <td><label>Supervisory Lawyer <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control"   formControlName="originatingAttorney" placeholder="Enter Originating Attorney...">
                              </td>
                              <td>
                                   <label>Status<span class="text-danger">*</span> </label>
                                   <input type="text" class="form-control" value="Open" formControlName="status" placeholder="Open" readonly>
                                   
                                   <small class="text-danger" *ngIf="caseForm.controls.caseType.hasError('required') && (
                                      caseForm.controls.caseType.dirty || caseForm.controls.caseType.touched ||
                                      (!caseForm.controls.caseType.valid && submittedForm))">Please select Case Status.</small>
                              </td>
                           </tr>
                           <tr>
                              <td><label>Open Date </label>
                                 <div class="input-group date">
                                    <div class="input-group-addon" [owlDateTimeTrigger]="odt"> <i class="fa fa-calendar"></i>
                                    </div>
                                    <input [owlDateTimeTrigger]="odt" [owlDateTime]="odt" type="text"
                                    class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                    formControlName="openDate" (keyup.enter)="OnInput($event)"
                                    (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                 </div>
                                 <owl-date-time #odt [pickerType]="'calendar'"></owl-date-time>
                                 
                              </td>
                              <td><label>Permission </label>
                                 <select class="form-control" formControlName="permission"> 
                                    <option value="">--Select--</option>
                                    <option value="Me">Me</option>
                                    <option value="Everyone">Everyone</option>
                                  </select>
                                 
                              </td>
                              <td><label>Location <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control"   formControlName="location" placeholder="Enter location...">
                              </td>
                              <!-- <td><label>Pending Date  </label>
                                 <div class="input-group date">
                                    <div class="input-group-addon" [owlDateTimeTrigger]="pdt"> <i class="fa fa-calendar"></i>
                                    </div>
                                    <input [owlDateTimeTrigger]="pdt" [owlDateTime]="pdt" type="text"
                                    class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                    formControlName="pendingDate" (keyup.enter)="OnInput($event)"
                                    (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                 </div>
                                 <owl-date-time #pdt [pickerType]="'calendar'"></owl-date-time>
                              </td>
                              <td><label>Closed Date  </label>
                                 <div class="input-group date">
                                    <div class="input-group-addon" [owlDateTimeTrigger]="cdt"> <i class="fa fa-calendar"></i>
                                    </div>
                                    <input [owlDateTimeTrigger]="cdt" [owlDateTime]="cdt" type="text"
                                    class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                    formControlName="closedDate" (keyup.enter)="OnInput($event)"
                                    (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                 </div>
                                 <owl-date-time #cdt [pickerType]="'calendar'"></owl-date-time>
                              </td> -->
                           </tr>
                           <tr>
                              
                              <!-- <td><label>Notification <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control"   formControlName="" placeholder="">
                              </td> -->
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
 
                
 
               <div class="box-footer" *ngIf="createMode === false ">
                  <!--  <span class="pull-left" style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
                   <span class="pull-left" style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span> -->
 
                  <button type="submit" class="btn btn-primary pull-right" data-toggle="modal" data-target="#saveIntentSave" [attr.disabled]="viewMode ? '' : null">Save Case</button>
               </div>
 
               <div class="box-footer" *ngIf="createMode === true ">
 
                   <!-- <span class="pull-left" style="color: green;font-weight: bold;font-size: 15px;" *ngIf="mes!=''">&nbsp; {{mes}}</span>
                   <span class="pull-left" style="color: red;font-weight: bold;font-size: 15px;" *ngIf="mes1!=''">&nbsp; {{mes1}}</span> -->
 
                   <button type="submit" class="btn btn-primary pull-right" (click)="openDialog()" [attr.disabled]="viewMode ? '' : null">Create Case</button>
                </div>
 
 
            </div>
            
         </div>
 
      </form>
  
      <div class="box-body" *ngIf="viewMode == true || editMode == true">
         <div class="box">
            <div class="box-body">
             
             
 
             <!--First Step-->
               
                  <!-- <ul class="nav nav-tabs">
                     <li class="active"><a href="#tab_1" data-toggle="tab">Case Details</a></li>
                     <li class="case_folder_li"><a href="#tab_2" data-toggle="tab">Case Folder</a></li>
                     <li class="assign_team_li"><a href="#tab_3" data-toggle="tab" (click)="assignTeamEditList()">Assign Team </a></li>
                     <li class="parties_li"><a href="#tab_4" data-toggle="tab">Representation </a></li>
                     <li class="instruction_chatbox_li"><a href="#tab_5" data-toggle="tab" (click)="insChatBoxList()">Instruction Chatbox </a></li>
                  </ul> -->
                  
                  <mat-vertical-stepper  #stepper>
                   <mat-step [stepControl]="caseDetailForm">
                      <div *ngIf="loader" class="spinnerbox">  
                         <div class="spinner"></div>
                      </div>
                      <ng-template matStepLabel>Case Details</ng-template>
                        <form [formGroup]="caseDetailForm">
                           <div>
                              <!-- <div class="box-header">
                                 <h3 class="box-title"> Case Details</h3>
                              </div> -->
                              <div class="table-responsive">
                                 <table class="table">
                                    <tbody>    
                                     <tr >
                                           <td width="150" style="border-top: none;">State:</td>
                                           <td style="border-top: none;">
                                              <select (change)="onSelectState($event.target.value)" class="form-control" formControlName="stateId">
                                                 <option value="">--Select--</option>
                                                 <option *ngFor="let state of statelist " [value]="state.id">{{state.stateName}}</option>
                                              </select>
                                           </td>
                                     </tr>
                                    
                                       <tr>
                                          <td width="150" style="border-top: none;">Court</td>
                                          <td style="border-top: none;">
                                            
                                              <select  id="aantal" [attr.disabled]="viewMode ? '' : null" class="form-control" formControlName="caseCourt"
                                             [attr.disabled]="viewMode ? '' : null">
                                             <option value=""><strong>Select court</strong></option>
                                             <option *ngFor="let opt of courtlist" [value]="opt.id">
                                             <strong>{{opt.courtName}}</strong>
                                             </option>
                                             </select>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="150" style="border-top: none;">Next Date</td>
                                          <td style="border-top: none;">
                                            
                                             <div class="input-group date">
                                                <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                                                </div>
                                                <input [owlDateTimeTrigger]="dt" [owlDateTime]="dt" type="text"
                                                class="form-control pull-right"  id="datepicker" placeholder="00/00/0000"
                                                formControlName="nextDate" (keyup.enter)="OnInput($event)"
                                                (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                             </div>
                                             <owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
                                            
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="150" style="border-top: none;">Stage of Case</td>
                                          <td style="border-top: none;">
                                            
                                             <select name="selectedQuantity" id="aantal" [attr.disabled]="viewMode ? '' : null" class="form-control" formControlName="stageOfCase"
                                             [attr.disabled]="viewMode ? '' : null">
                                             <option value=""><strong>Select stage of Case</strong></option>
                                             <option *ngFor="let opt of stages" [value]="opt.id">
                                             <strong>{{opt.stage}}</strong>
                                             </option>
                                             </select>
                                             
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="150" style="border-top: none;">Status</td>
                                          <td style="border-top: none;">
                                             <textarea class="form-control" rows="" placeholder="Enter status..."
                                                formControlName="statussOfCase" [attr.disabled]="viewMode ? '' : null"></textarea>
                                             
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div>
                              <div class="box-header">
                                 <h3 class="box-title" style="margin-left: -1%;font-size: 17px;"> <strong>History of Case hearing</strong></h3>
                              </div>
                              <div class="table-responsive" *ngIf="!viewMode">
                                 <a [routerLink]="" style="text-decoration: underline;font-size: 15px;font-weight: 600;" data-toggle="modal"(click)="addNewHearingDetails()" data-target="#hearingInsertDialog" ><i class="fa fa-plus"></i > Add Hearing Details </a>
                              <!--    <table class="table">
                                    <thead>
                                       <tr>
                                          <th width="50"> Judge</th>
                                          <th width="50"> Dates </th>
                                          <th width="120" *ngIf="hearingDtlSubmit">Upload Documents</th>
                                          <th width="300" *ngIf="hearingDtlSubmit"> Orders  </th>
                                          
                                          <th width="50" style="text-align: center;">Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let hearingDtlVo of t.controls; let i=index" [formGroup]="hearingDtlVo">
                                          
                                       <td>
                                          <input type=" text" class="form-control" placeholder="Enter Judge..."
                                             formControlName="hearingJudje">
                                          
                                       </td>
 
                                       <td >
                                          <div class="input-group date">
                                             <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                                             </div>
                                             <input [owlDateTimeTrigger]="dt" [owlDateTime]="dt" type="text"
                                             class="form-control pull-right" id="datepicker" placeholder="00/00/0000"
                                             formControlName="hearingDate" (keyup.enter)="OnInput($event)"
                                             (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                                          </div>
                                          <owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
                                          
                                       </td>
 
                                      
                                        <td align="center">
                                           
                                           
                                          <div id="drop-zone" class="btn btn-info" *ngIf="!viewMode && hearingDtlVo.controls.hearingId.value">
                                             
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                                <input *ngIf="caseNo" type="file" name="file" id="file"
                                                (change)="handleFileInput($event.target.files,'order', hearingDtlVo.controls.hearingId.value)" />
                                             </div>
                                          </div>
                                          
                                         
                                       </td>
 
                                       
                                       
                                        <td >
                                          <div class="Ordersbox" *ngIf="hearingDtlVo.controls.orderDocName.value && hearingDtlVo.controls.hearingId.value">
                                             
                                                
                                                 <div class="pdfbox pull-left">
                                                   <a  href="{{hearingDtlVo.controls.hearingOrder.value}}"
                                                      target="_blank">{{hearingDtlVo.controls.orderDocName.value}}
                                                   </a>
                                                </div>
                                                
 
                                                 <div id="drop-zone" class="btn btn-danger pull-right" *ngIf="editMode" >
                                                      <div id="clickHere" ><i class="fa fa-trash"></i>
                                                         <input type="buton" name="delImg" id="file"
                                                            (click)="removeDoc('', '', hearingDtlVo.controls.orderDocId.value, 
                                                            'order', hearingDtlVo.controls.orderDocName.value, hearingDtlVo.controls.hearingId.value)" />
                                                      </div>
                                                 </div>
 
                                           <div class="clearfix"></div>     
                                             
                                          </div>
                                          <div class="Ordersbox" *ngIf="!hearingDtlVo.controls.orderDocName.value">
                                                 <span color = 'red'>Document not uploaded yet</span>
                                          </div>
                                       </td>
 
                                       
 
 
                                       <td align="center" *ngIf="!viewMode">
                                          {{ t.value[t.length-1].hearingDate }} 
                                          <button *ngIf="i == (t.length-1)" (click)="addSkills()" type="button"
                                          class="btn btn-success">
                                          <i class="fa fa-plus" aria-hidden="true"></i>
                                          </button>
                                          <button *ngIf="i > 0" (click)="removeSkill(i,hearingDtlVo.controls.hearingId.value)"   type="button" class="btn btn-warning">
                                          <i class="fa fa-minus" aria-hidden="true"></i>
                                          </button>
                                       </td>
 
                                       </tr>
                                    </tbody>
                                 </table> -->
                                 <table class="table" *ngIf="newHearingList.length > 0">
                                    <thead>
                                       <tr>
                                          <th width="50">Judge</th>
                                          <th width="50">Dates </th>
                                          <th width="130">Orders (upload documents)</th>
                                          <th width="20" >Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let val of newHearingList; let i=index">
                                          <td>
                                             {{val.hearingJudje}}
                                          </td>
                                          <td>
                                             {{val.hearingDate | date: 'dd/MM/yyyy'}}
                                          </td>
                                          <td>
                                             
                                             <!-- <div id="drop-zone" class="btn btn-default"> -->
                                             <div id="clickHere" *ngIf="val.hearingOrder !=''">
                                                <span *ngIf="val.orderDocName != 'null'">
                                                <a href="{{val.hearingOrder !=''?val.hearingOrder:'#'}}"
                                                   target="_blank">{{val.orderDocName !=''?val.orderDocName:'NA'}}</a>
                                                </span>
                                                <span *ngIf="val.orderDocName == 'null'">
                                                   <span>N/A</span>
                                                </span>
                                             </div>
                                             <a *ngIf="val.hearingOrder ==''" href="#">NA</a>
                                             <!-- </div> -->
                                          
                                       </td>
                                          <td>
                                             <a  style="color: blue;" [routerLink]=""  (click)="edit(val)"><i class="fa fa-pencil"></i></a>
                                             <a  style="color: red;margin-left:12%;" [routerLink]=""  (click)="deleteHearing(val)"><i class="fa fa-trash"></i></a>
                                             
                                                
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="table-responsive" *ngIf="viewMode==true">
                                 <table class="table">
                                    <thead>
                                       <tr>
                                          <th width="90">Judge</th>
                                          <th width="80">Dates </th>
                                          <th width="130">Orders (upload documents)</th>
                                         <!--  <th width="20" style="text-align: center;">Action</th> -->
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let val of caseHearingList; let i=index">
                                          <td>
                                            {{val.hearingJudje}}
                                          </td>
                                          <td>
                                             {{val.hearingDate | date: 'dd/MM/yyyy'}}
                                          </td>
                                          <td>
                                             
                                             <!-- <div id="drop-zone" class="btn btn-default"> -->
                                             <div id="clickHere" *ngIf="val.hearingOrder !=''">
                                                <span *ngIf="val.orderDocName != 'null'">
                                                <a href="{{val.hearingOrder !=''?val.hearingOrder:'#'}}"
                                                   target="_blank">{{val.orderDocName !=''?val.orderDocName:'NA'}}</a>
                                                   </span>
                                                   <span *ngIf="val.orderDocName == 'null'">
                                                      <span>N/A</span>
                                                   </span>
                                             </div>
                                             <!-- <a *ngIf="val.hearingOrder ==''" href="#">NA</a> -->
                                             <!-- </div> -->
                                         
                                       </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div class="box-footer" style="border-top: none;text-align: center;">
                               
                               <button type="submit" [attr.disabled]="viewMode ? '' : null" class="btn btn-primary pull-right"  *ngIf="caseNo" (click)="caseDetailSubmit()">Save Case Details</button>
                               <button  class="btn btn-primary" style="margin-right: 5%;margin-top: 3%;" *ngIf="caseNo"  matStepperNext>Next</button>
                               
                           </div>
                        </form>
                      </mat-step>
                     
                     <mat-step>
                      <div *ngIf="loader" class="spinnerbox">  
                         <div class="spinner"></div>
                      </div>
                      <ng-template matStepLabel>Case Folder</ng-template>
                        <div class="treefolder">
                           <div class="table-responsive">
 
 
                            <ul id="myUL">
                               <div class="">
                                 <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                   <li> <span class="caret">Financial</span>
                                     <ul class="nested active">
                                      
                                       <li> <span class="caret">Paid Invoices</span>
                                         <div id="drop-zone" class="btn btn-default" *ngIf="(caseFolderPermission[0].upload ===  true || userId == createdByUser) && !viewMode">
                                           <div id="clickHere"><i class="fa fa-upload"></i>
                                             <input type="file" multiple name="file" id="file"
                                                                       (change)="handleFileInput($event.target.files,'financialpaidinvoices', null)" />
                                           </div>
                                         </div>
                                         <div *ngIf="caseFolderPermission[0].view ===  true || userId == createdByUser">
                                          <a style="margin-left: 5%;" *ngIf = "caseDocList['financialpaidinvoices']" [routerLink]="" (click)="displayDocumentsModel('Paid Invoices Document','financialpaidinvoices')"><i class="fa fa-file-text-o"></i> View Documents</a>
                                          
                                         </div>
                                       </li>
 
                                       <li> <span class="caret">Pending Invoices</span>
                                          <div id="drop-zone" class="btn btn-default" *ngIf="(caseFolderPermission[0].upload ===  true || userId == createdByUser) && !viewMode">
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                               <input type="file" multiple name="file" id="file"
                                                                         (change)="handleFileInput($event.target.files,'financialpendinginvoices', null)" />
                                             </div>
                                           </div>
                                           <div *ngIf="caseFolderPermission[0].view ===  true || userId == createdByUser">
                                            <a style="margin-left: 5%;" *ngIf = "caseDocList['financialpendinginvoices']" [routerLink]="" (click)="displayDocumentsModel('Pending Invoices Document','financialpendinginvoices')"><i class="fa fa-file-text-o"></i> View Documents</a>
                                            
                                          </div>
 
                                       </li>
 
                                     </ul>
                                   </li>
                                 </div>
                                 <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <li> <span class="caret">Case Documents</span>
                                       <ul class="nested active">
                                         <li> <span class="caret">Documents</span>
                                           <div id="drop-zone" class="btn btn-default" *ngIf="(caseFolderPermission[1].upload ===  true || userId == createdByUser) && !viewMode">
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                               <input type="file" multiple name="file" id="file"
                                                                           (change)="handleFileInput($event.target.files,'rostercasedocuments', null)" />
                                             </div>
                                           </div>
                                           <div *ngIf="caseFolderPermission[1].view ===  true || userId == createdByUser || userId == clientUserId">
                                            <a style="margin-left: 5%;" *ngIf = "caseDocList['rostercasedocuments']" [routerLink]="" (click)="displayDocumentsModel('Case Documents','rostercasedocuments')"><i class="fa fa-file-text-o"></i> View Documents</a>
                                            <!-- <div class="list-group" *ngFor="let opt of caseDocList['rostercasedocuments']">
                                             <div class="row">
                                               <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                           target="_blank">{{opt.docName}} </a> </div>
                                               <div class="col-md-2">
                                                 <div id="drop-zone" class="btn btn-default" *ngIf="(((caseFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))">
                                                   <div id="clickHere"><i class="fa fa-trash"></i>
                                                     <input type="buton" name="delImg" id="file"
                                                                                    (click)="removeDoc(opt.userId, opt.caseNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                   </div>
                                                 </div>
                                               </div>
                                             </div>
                                           </div> -->
                                           </div>
                                         </li>
                                         <li> <span class="caret">Statement Of Case</span>
                                           <div id="drop-zone" class="btn btn-default" *ngIf="(caseFolderPermission[1].upload ===  true || userId == createdByUser) && !viewMode">
                                             <div id="clickHere"><i class="fa fa-upload"></i>
                                               <input type="file" name="file" id="file" multiple
                                                                           (change)="handleFileInput($event.target.files,'rostercasedocumentspleadings', null)" />
                                             </div>
                                           </div>
                                           <div *ngIf="caseFolderPermission[1].view ===  true || userId == createdByUser">
                                            <a style="margin-left: 5%;" *ngIf = "caseDocList['rostercasedocumentspleadings']" [routerLink]="" (click)="displayDocumentsModel('Case Pleadings Documents','rostercasedocumentspleadings')"><i class="fa fa-file-text-o"></i> View Documents</a>
                                            <!-- <div class="list-group"
                                                                        *ngFor="let opt of caseDocList['rostercasedocumentspleadings']">
                                                <div class="row">
                                                <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                              target="_blank">{{opt.docName}} </a> </div>
                                                <div class="col-md-2">
                                                   <div id="drop-zone" class="btn btn-default" *ngIf="(((caseFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))">
                                                      <div id="clickHere"><i class="fa fa-trash"></i>
                                                      <input type="buton" name="delImg" id="file"
                                                                                       (click)="removeDoc(opt.userId, opt.caseNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                      </div>
                                                   </div>
                                                </div>
                                                </div>
                                             </div> -->
                                           </div>
                                         </li>
                                         <li> <span class="caret">Research</span>
                                           <div id="drop-zone" class="btn btn-default" *ngIf="(caseFolderPermission[1].upload ===  true || userId == createdByUser) && !viewMode">
                                             <div id="clickHere" ><i class="fa fa-upload"></i>
                                               <input type="file" name="file" id="file" multiple
                                                                           (change)="handleFileInput($event.target.files,'rostercasedocumentsresearch', null)" />
                                             </div>
                                           </div>
                                           <div *ngIf="caseFolderPermission[1].view ===  true || userId == createdByUser">
                                            <a style="margin-left: 5%;" *ngIf = "caseDocList['rostercasedocumentsresearch']" [routerLink]="" (click)="displayDocumentsModel('Case Research Documents','rostercasedocumentsresearch')"><i class="fa fa-file-text-o"></i> View Documents</a>
                                            <!-- <div class="list-group"
                                                                     *ngFor="let opt of caseDocList['rostercasedocumentsresearch']">
                                             <div class="row">
                                               <div class="col-md-10"> <a class="list-group-item" style="color: #1570b3;" href="{{opt.pathInS3}}"
                                                                           target="_blank">{{opt.docName}} </a> </div>
                                               <div class="col-md-2">
                                                 <div id="drop-zone" class="btn btn-default" *ngIf="(((caseFolderPermission[1].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))">
                                                   <div id="clickHere"><i class="fa fa-trash"></i>
                                                     <input type="buton" name="delImg" id="file"
                                                                                    (click)="removeDoc(opt.userId, opt.caseNo, opt.docId, opt.docType, opt.Hearing, null)" />
                                                   </div>
                                                 </div>
                                               </div>
                                             </div>
                                           </div> -->
                                           </div>
                                         </li>
                                       </ul>
                                     </li>
                                 </div>
                               </div>
                             </ul>
 
 
 
                           </div>
                        </div>
                         <div class="box-footer" style="border-top: none;text-align: center;">
                            <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                            <button  class="btn btn-primary" style="margin-right: 18%;"   matStepperNext>Next</button>
                            
                         </div>
                      </mat-step>
                     <!--2nd step end-->
                     <!--3rd step start-->
                     <mat-step >
                      <ng-template matStepLabel><p style="margin-top: 9.6%;" (click)="assignTeamEditList()">Assign Team </p></ng-template>
                      <app-role-team-assignment></app-role-team-assignment>
                      <div class="box-footer" style="border-top: none;text-align: center;">
                         <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                         <button  class="btn btn-primary" style="margin-right: 18%;"  matStepperNext>Next</button>
                         
                      </div>
                     </mat-step>
                     <!--3rd step end-->
                     <!--4th step start-->
                     <mat-step>
                      <ng-template matStepLabel>Representation</ng-template>
                     <app-partydetails></app-partydetails>
                     <div class="box-footer" style="border-top: none;text-align: center;">
                      <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                      <button  class="btn btn-primary" style="margin-right: 18%;" (click)="insChatBoxList()"  matStepperNext>Next</button>
                      
                   </div>
                    </mat-step>
                    <!--4th step end-->
 
                     
 
                     <!--################ Instraction ChatBot Start Here #################-->
                     <mat-step>
                      <div *ngIf="loader" class="spinnerbox">  
                         <div class="spinner"></div>
                      </div>
                      <ng-template matStepLabel><p style="margin-top: 3.6%;" (click)="insChatBoxList()">Task Action</p></ng-template>
                        <div class="row">
                           <div *ngIf="!viewMode" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 rolebox1">
                              <!-- <button type="button" class="btn btn-primary pull-right Role" data-toggle="modal" data-target="#myInstructionModal" (click)="displayInsFormModal()">Add Instruction</button> -->
                              <button type="button" class="btn btn-primary pull-right Role" (click)="displayInsFormModal()">Add Task</button>
                           </div>
                           <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="table-responsive">
                                 <table class="table" datatable="ng" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                 <thead>
                                    <tr>
                                       <th  style="width: 3%;">#</th>
                                       <th style="width: 15%;">Date</th>
                                       <th style="width: 21%;">From</th>
                                       <th style="width: 21%;">To</th>
                                       <th style="width: 40%;">Task</th>
                                       <!-- <th>Reply</th> -->
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let val of conversationList; let i=index">
                                       <td style="width: 3%;">
                                          {{val.sl}}
                                       </td>
                                       <td style="width: 15%;">
                                          {{val.msgDateTime | date: 'dd/MM/yyyy'}}
                                       </td>
                                       <td style="width: 21%;">
                                          {{val.fromUserName}}
                                       </td>
                                       <td style="width: 21%;">
                                          {{val.toUserName}}
                                       </td>
                                       <td style="width: 40%;word-break: break-word;">
                                          {{val.msgContent}}
                                       </td>
                                       
                                    </tr>
                                 </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                        <div class="box-footer" style="border-top: none;text-align: center;">
                         <button class="btn btn-primary" style="margin-right: 1%;" matStepperPrevious>Previous</button>
                         <!-- <button  class="btn btn-primary" style="margin-right: 18%;"  matStepperNext>Next</button> -->
                         
                      </div>
                     </mat-step>
                     <!--################ Instraction ChatBot Ends Here #################-->
 
                 
                </mat-vertical-stepper>
               
            </div>
         </div>
      </div>
 
 
 
 
 
      <!-- Modal start -->
      <div class="modal fade in" id="saveIntent" role="dialog" >
         <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title">Confirm to Save Case</h4>
               </div>
               <div class="modal-body">
                  <p>Are you sure want to save this Case?</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="caseSubmit()">Yes</button>
                  <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
               </div>
            </div>
         </div>
      </div>
 
 
      <div class="modal fade in" id="saveIntentSave" role="dialog">
         <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title">Confirm to Save Case</h4>
               </div>
               <div class="modal-body">
                  <p>Are you sure want to save this Case?</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)='caseConfirmation()'>Yes</button>
                  <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
               </div>
            </div>
         </div>
      </div>
 
 
 
      <!-- Modal end -->
      <!-- Modal -->
      <!-- <div id="myInstructionModal" class="modal fade" role="dialog" *ngIf="insFormModal"> -->
       <div id="myInstructionModal" class="modal fade" role="dialog">
         <div class="modal-dialog">
            <!-- Modal content-->
            <form [formGroup]="insChatForm">
               <div class="modal-content">
                  <div class="modal-header">
                     <button type="button" class="close" data-dismiss="modal">&times;</button>
                     <h4 class="modal-title">Instruction Chatbox</h4>
                  </div>
                  <div class="modal-body">
                     <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                           <div class="form-group">
                              <label>To <span class="text-danger">*</span></label>
                              <!-- formControlName="userId" -->
                              <select name="selectedQuantity" id="aantal" class="form-control" formControlName="toUser">
                                 <option value=""><strong>SELECT NAME</strong></option>
                                 <option *ngFor="let opt of instructionToList let i=index;" [value]="i">
                                 <strong>{{opt.firstName}} {{opt.lastName}}</strong>
                                 </option>
                              </select>
                              <small class="text-danger" *ngIf="insChatForm.controls.toUser.hasError('required') && (
                                 insChatForm.controls.toUser.dirty || insChatForm.controls.toUser.touched ||
                                 (!insChatForm.controls.toUser.valid && subInsChatForm))">Please select name.</small>
                           </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                           <div class="form-group">
                              <label>Type <span class="text-danger">*</span></label>
                              <select name="selectedQuantity" id="aantal" class="form-control" formControlName="msgType">
                                 <option value=""><strong>Enter ...</strong></option>
                                 <option *ngFor="let opt of insMsgChatType" [value]="opt.msgTypeId">
                                 <strong>{{opt.caseType}}</strong>
                                 </option>
                              </select>
                              <small class="text-danger" *ngIf="insChatForm.controls.msgType.hasError('required') && (
                                 insChatForm.controls.msgType.dirty || insChatForm.controls.msgType.touched ||
                                 (!insChatForm.controls.msgType.valid && subInsChatForm))">Please select Type.</small>
                           </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                           <div class="form-group">
                              <label>Message <span class="text-danger">*</span></label>
                              <textarea class="form-control" rows="" placeholder="Enter ..."
                                 formControlName="msgContent"></textarea>
                              <small class="text-danger" *ngIf="insChatForm.controls.msgContent.hasError('required') && (
                                 insChatForm.controls.msgContent.dirty || insChatForm.controls.msgContent.touched ||
                                 (!insChatForm.controls.msgContent.valid && subInsChatForm))">Please enter message.</small>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     
                      <button type="submit" class="btn btn-primary pull-right" (click)="postInsChat()">Send </button>
                      <button type="button" class="btn btn-default pull-right" style="margin-right:5px;" data-dismiss="modal">Close</button>
                     
 
                  </div>
               </div>
            </form>
         </div>
      </div>
      <!-- Modal -->

      <div id="fileuploadDialog" class="modal fade" role="dialog" >
         <div class="modal-dialog">
            <div class="modal-content" style="width: 150%;">
               <div class="modal-header">
                  <h4 class="modal-title">{{uploadedDocumentModelName}}</h4>
               </div>
               <div class="modal-body">
                  <table class="table-headed table-striped" style="min-width:100%">
                     <thead>
                        <tr>
                           <th>Document</th>
                           <th>Uploaded Date</th>
                           <th *ngIf="!viewMode">Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let opt of modelDocumentList">
                           <td style="width: 50%;"><a style="color: #1570b3;"  href="{{opt.pathInS3}}" target="_blank"><span *ngIf="uploadedDocumentModelName != 'Pending Invoices Document'">{{opt.docName}}</span><span *ngIf="uploadedDocumentModelName == 'Pending Invoices Document'">{{opt.invoice}}</span> </a></td>
                           <td >{{ opt.updatedAt | date: 'dd/MM/yyyy'}}</td>
                           <td *ngIf="!viewMode"> <div id="drop-zone"  *ngIf="(((caseFolderPermission[0].delete ===  true) && (loginUserType != 'advocate' && loginUserType != 'counsel' && loginUserType != 'directorlegal' && loginUserType != 'managingpartner')) || (loginUserType == 'advocate' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'managingpartner'))">
                              <div id="clickHere" style="padding-left: 15%;color:red"><i class="fa fa-trash"></i>
                              <input type="buton" name="delImg" id="file" (click)="removeDoc(opt.userId, opt.caseNo, opt.docId, opt.docType, opt.Hearing, null)" />
                              </div>
                           </div></td>
                        </tr>
                     </tbody>
                  </table>
                  <!-- <span style="padding-left:35%;font-size: 18px;font-weight: 600;color:red;" *ngIf="modelDocumentList.length == 0">No Data Found</span> -->
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-default pull-right" style="margin-right:5px;" data-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
      </div>

      <!--Hearing Details Model-->
      <div id="hearingInsertDialog" class="modal fade" role="dialog">
         <div class="modal-dialog">
            <div class="modal-content" >
               <div class="modal-header">
                  <h4 class="modal-title" *ngIf="!editNewHearingForm">Insert Hearing Details</h4>
                  <h4 class="modal-title" *ngIf="editNewHearingForm">Update Hearing Details</h4>
               </div>
               <div class="modal-body">
                  <form [formGroup]="newHearingForm">
                     
                        <div class="form-group">
                           <label>Judge <span class="text-danger">*</span></label>
                           <input type=" text" class="form-control" placeholder="Enter Judge..."
                                             formControlName="hearingJudje"/>
                           <small class="text-danger" *ngIf="newHearingForm.controls.hearingJudje.hasError('required') && (
                              newHearingForm.controls.hearingJudje.dirty || newHearingForm.controls.hearingJudje.touched ||
                                                (!newHearingForm.controls.hearingJudje.valid && subNewHearingForm))">Please Enter
                                             the judge name.</small>
                           </div>
                     

                     
                        <div class="form-group">
                           <label>Date <span class="text-danger">*</span></label>
                           <div class="input-group date">
                              <div class="input-group-addon" [owlDateTimeTrigger]="dt"> <i class="fa fa-calendar"></i>
                              </div>
                           <input [owlDateTimeTrigger]="dt"  [owlDateTime]="dt" type="text"
                                             class="form-control pull-right" id="datepicker" placeholder="00/00/0000"
                                             formControlName="hearingDate" (keyup.enter)="OnInput($event)"
                                             (focusout)="OnInput($event)" (ngModelChange)="onChange($event)">
                           </div>           
                                          <owl-date-time style="z-index: 999999999 !important;" #dt [pickerType]="'calendar'"></owl-date-time>

                                          <small class="text-danger" *ngIf="newHearingForm.controls.hearingDate.hasError('required') && (
                              newHearingForm.controls.hearingDate.dirty || newHearingForm.controls.hearingDate.touched ||
                                                (!newHearingForm.controls.hearingDate.valid && subNewHearingForm))">Please 
                                             enter the hearing date.</small>
                              
                           </div>
                     
                           <div class="form-group" style="margin-top:3%" *ngIf = "editNewHearingForm">
                              <label>Previous Uploaded File</label>
                                 <br>
                                 <a *ngIf="newHearingOrderDocName != 'null'" href="{{newHearingOrderDocValue}}"
                                    target="_blank">{{newHearingOrderDocName}}
                                 </a>
                                 <span *ngIf="newHearingOrderDocName == 'null'">N/A</span>
                              
                           </div>
                        <div class="form-group">
                           <label>File Upload</label>
                           
                           <input type="file" #variable name="file" id="file" (change)="newHearingFIleUpload($event.target.files)" />
                           
                        </div>
                     
                  </form>
               </div>
               <div class="modal-footer">
                  <button type="submit" class="btn btn-primary pull-right" (click)="newHearingSave()">Submit </button>
                  <button type="button" class="btn btn-default pull-right" style="margin-right:5px;" data-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
      </div>
      <!-- Confirm Hearing Delete Details-->
      <div class="modal fade in" id="deleteHiringNew" role="dialog">
         <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title">Confirm to Delete Hearing</h4>
               </div>
               <div class="modal-body">
                  <p>Are you sure want to delete this hearing?</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)='deleteNewHearingConfirmation()'>Yes</button>
                  <button type="button" class="btn btn-grey" data-dismiss="modal">No</button>
               </div>
            </div>
         </div>
      </div>

    </div>
   </section>
 </div>