import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { Http, Headers } from '@angular/http';

import { CaseService } from '../service/case.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CountryCodeDropdownComponent } from '../shared/components/country-code-dropdown/country-code-dropdown.component';
declare var $: any;
declare var jQuery: any;
import countryCodeList from '../shared/static/phone-country-code.list';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

import { HttpClient, HttpResponse } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-partydetails',
  templateUrl: './partydetails.component.html',
  styleUrls: ['./partydetails.component.css']
})
export class PartydetailsComponent implements OnInit {

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountry:any;
preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  country = "United Kingdom"

  public countryCodeList: Array<any> = countryCodeList;
  partyForm: FormGroup;
  countryCodeMobilePh: string = '+44';
  countryCodeOfficePh: string = '+44';
  caseNo: any;
  userId: any;
  docType: any;
  displayCaseDocument = 'none';

  showMsg: boolean = false;
  mes = '';
  mes1 = '';

  insChatId: any;

  url: any;
  btnSppiner: boolean = false;
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;
  disabled: boolean = false;
  clientUserId: string;
  submitted: boolean = false;
  loader : boolean = false;
  constructor(private fb: FormBuilder,
    private CaseService: CaseService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute, private http: HttpClient) { 

    
  }

  ngOnInit(): void {
    console.log(this.countryCodeList)
    this.selectedCountry = CountryISO[this.country.toString()] 
    this.load();
  }

  async load(){
    this.url = this.router.url;
    const caseView = this.url.search("case-view");
    const caseEdit = this.url.search("case-edit");
    const casecreation = this.url.search("casecreation");

    if (caseView == 1) {
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }

    if (caseEdit == 1) {
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }

    if (casecreation == 1) {
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
    }
    this.partyForm = this.fb.group({
      partyDtlVo: this.fb.array([]),
    })
    
    if (this.viewMode == true || this.editMode == true) {
      this.displayCaseDocument = 'block';

      this.route.params.subscribe(params => {
        this.clientUserId = params['userId'];
        this.caseNo = params['caseNo'];
      });
      // this.caseForm.patchValue( { 'userId': this.userId } );

      await this.viewPartyDetails(this.caseNo);  

    }

    this.partyDtlVo().push(this.newParty());

  }
  //load end
//fields of partyDtlVo
  newParty(): FormGroup {
    return this.fb.group({
      partyType: ['', Validators.required],
      clientUserId: [this.clientUserId, Validators.required],
      caseNo: [this.caseNo, Validators.required],
      id: [''],
      partyName: ['', Validators.required],
      partyEmailId: [''],
      // partyEmailId: new FormControl(""),
      partyPhoneNo: ['', Validators.required],
      countryCode:[ CountryISO[this.country.toString()] ]
    })
  }
  partyDtlVo(): FormArray {
    return this.partyForm.get("partyDtlVo") as FormArray
  }

  addParty() {
    this.partyDtlVo().push(this.newParty());
  }

  removeParty(teamIndex: number) {
    this.partyDtlVo().removeAt(teamIndex);

  }

  partyUpdate() {

    let formVal = this.partyForm.value;
    this.submitted = true;
   
    // console.log('partyUpdate', formVal);

    // console.log('partyDtlVo in partyUpdate', this.partyForm.get("partyDtlVo"));
    //remove the object which not contains id as to seperate update and add vo.
    // formVal.partyDtlVo = formVal.partyDtlVo.filter(function (obj) {
    //   return obj.field !== 'id';
    // });
    // let mergeData: any = [...formVal.partyDtlVo];
    // console.log('mergeData', mergeData);



    if (this.partyForm.valid) {
      // console.log("valid");
      this.loader = true;
      console.log(formVal.partyDtlVo)
      for (var i = 0; i < formVal.partyDtlVo.length; i++) {
        
        formVal.partyDtlVo[i].countryCode =  formVal.partyDtlVo[i].partyPhoneNo.dialCode;
        formVal.partyDtlVo[i].partyPhoneNo = formVal.partyDtlVo[i].partyPhoneNo.number;
      }
      this.CaseService.updatePartyData(JSON.stringify(formVal.partyDtlVo)).subscribe((data) => 
          {
                console.log('updateCaseData response', data);
                if (data.responseMessage == 'success') {
                  this.displayCaseDocument = 'block';
                  //this.caseNo = data.respData;
                  // this.partyForm = this.fb.group({
                  //   partyDtlVo: this.fb.array([]),
                  // })
                  // this.partyDtlVo().push(this.newParty());
                  this.submitted = false;
                  this.showMsg = true;
                  this.mes = 'Representation updated successfully.';
                  this.toastr.success(this.mes, '');
                  this.loader = false
                  this.mes1 = '';
                  //this.viewPartyDetails(this.caseNo);
                }
          }, (err: any) => {
                console.log(err);
                this.showMsg = true;
                this.mes = '';
                this.mes1 = 'Representation update failed.';
                this.toastr.error(this.mes1, '');
                this.loader = false
          }, () => {
            // this.modalDisplay = !this.modalDisplay;
          }
      );


    }else{
      console.log("Invalid");
    }

    
  }

  async viewPartyDetails(formVal) {
    let data = { caseNo: formVal }
    this.loader = true;
    this.CaseService.partyDetails(JSON.stringify(data)).subscribe(async (data) => {
      console.log('caseDetails response', data);
      if (data.responseMessage == 'success') {
        console.log(data.respData);

        this.disabled = true;
        const res = data.respData;

        this.loader = false
        console.log('res in viewPartyDetails', res);


        let partydetails: any = [];

        if (res.length > 0) {
          //to delete the blank first row, as there is no data first blank row is required
          this.partyDtlVo().removeAt(0);

          await res.map((chrd) => {
            let country 
            console.log(chrd.countryCode)
            country =  this.countryCodeList.filter(x => x.dial_code == chrd.countryCode)
            this.country = country[0].name;
            this.selectedCountry = CountryISO[this.country.toString()] 
            let dd = {
              id: chrd.id,
              caseNo: chrd.caseNo,
              clientUserId: chrd.clientUserId,
              partyType: chrd.partyType,
              partyEmailId: chrd.partyEmailId,
              partyName: chrd.partyName,
              partyPhoneNo: chrd.partyPhoneNo,
              countryCode: this.selectedCountry
              
            };
            partydetails.push(dd);
            this.partyDtlVo().push(this.newParty());

          });
          console.log('partyDtlVo', this.partyDtlVo);
          this.partyForm.patchValue({ 'partyDtlVo': partydetails });

        }

      }
    }, (err: any) => {
      this.loader = false
      console.log(err);
    }, () => {
      // this.modalDisplay = !this.modalDisplay;
    }
    );
  }

 /*  onCountryCodeMobilePhChange (currentSelection: string) {
    console.log("hit")
    console.log(currentSelection)
    
    this.countryCodeMobilePh = currentSelection;
  } */


  onCountryCodeMobilePhChange(index: number,currentSelection: string) {
    const myForm = (<FormArray>this.partyForm.get("partyDtlVo")).at(index);
    //let currentVal = !myForm.value.toggle;
   
    myForm.patchValue({
      countryCode: currentSelection
    });
    this.countryCodeMobilePh = currentSelection;
    //this.hideArray[index] = currentVal;
    
  }


}
