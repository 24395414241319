<div class="content-wrapper">
  <!-- <section class="content-header">
      <h1> Lawyer Profile <small></small> </h1> 
      <ol class="breadcrumb">
        <li><i class="fa fa-users" aria-hidden="true"></i>  Lawyer Profile</li>
      </ol>
    </section> -->
  <section class="content">

      <div class="row">
        <!-- <div class="col-md-6">
          <div class="box-header-icon topbox-header-icon"><i class="material-icons md-24 md-dark">person</i></div>
          <h3 class="box-title main-header-top">Profile</h3>
        </div> -->

        <!-- this.dataArr&&this.dataArr.emailId -->
        <div class="col-md-6" *ngIf='dataArr&&dataArr.profilePicSrc'>
          <img src="{{dataArr.profilePicSrc}}" alt="" class="img-thumbnail pull-right profile-pic" height="50px"
            width="60px">
        </div>
      </div>
  
    <div class="box-body" *ngIf="profilestatus=='N'">
      <div class="box alert-danger">
        <div class="box-body">
          Welcome to Legal Case Management, please complete and update profile to access E-BRIEFCASE.
        </div>
      </div>
    </div>

    <div class="box-body">
      <div class="breadcrumbbox">
        <ol class="breadcrumb">
          <li class="active"><a [routerLink]="['/lawyerprofile',userId,usertype]"><i class="material-icons md-24 md-dark">person</i>Profile</a></li>
          <!-- <li [ngClass]="{'active':userId !== 'undefined', 'disabled': (userId === 'undefined') || !userId}"><a
              [routerLink]="['/addresses',userId,usertype]"><i class="fa fa-map-marker" aria-hidden="true"></i> Lawyer
              Addresses</a></li> -->
        </ol>

      </div>
    </div>

    <div class="bottom-height">
      <form [formGroup]="myform" *ngIf="myform" (ngSubmit)="ngSubmit();">
        <div class="box-body">
          <div class="box">
            <div class="box-body">
              <!-- <div class="row"> -->
              
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                      <!-- <h4 *ngIf="loginUserType == 'managingpartner' || loginUserType == 'directorlegal'">PROFILE INFO:</h4> -->
                      <!-- <h4>PROFILE INFO</h4> -->
                      <h4 *ngIf="loginUserType == 'corporateclient'">CORPORATE CLIENT:</h4>
                  </div>
                </div>
              </div>


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Law Firm Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmName"
                      placeholder="Enter Law Firm Name" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmName').invalid && myform.get('lawFirmName').touched">Please enter a law firm
                    name</small>
                </div> -->

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf=" loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Company Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateCompanyName"
                      placeholder="Enter Company Name" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateCompanyName').invalid && myform.get('corporateCompanyName').touched">Please enter a Company
                    name</small>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf=" loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Nature Of Business <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateClientNatureOfBusiness"
                      placeholder="Enter Nature of business" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientNatureOfBusiness').invalid && myform.get('corporateClientNatureOfBusiness').touched">Please enter a Nature of business</small>
                </div>
              </div>

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Registered Office Address <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateRegistredAddress"
                      placeholder="Enter registered address" required>
                  
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateRegistredAddress').invalid && myform.get('corporateRegistredAddress').touched">Please enter a Nature of business</small>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Branch Office Address <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateBranchAddress"
                      placeholder="Enter Branch Office address" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateBranchAddress').invalid && myform.get('corporateBranchAddress').touched">Please enter a Nature of business</small>
                </div> -->
                <!-- loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager' || loginUserType == 'legalmanager' -->
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Website <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmWebsite"
                      placeholder="Enter Website" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('lawFirmWebsite').invalid && myform.get('lawFirmWebsite').touched">Please enter a website name
                    name</small>
                </div>
              </div>

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Trademark/Logo</label>
                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'trademarklogo')" />
                    <small *ngIf='dataArr.trademarkLogoSrc'><b>{{dataArr.trademarkLogoSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.trademarkLogoSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.trademarkLogoSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.trademarkLogoSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('trademarklogo')" />
                      </div>
                    </div>
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Law Firm Profile</label>
                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'lawFirmProfile')" required />
                    <small *ngIf='dataArr.lawFirmProfileSrc'><b>{{dataArr.lawFirmProfileSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.lawFirmProfileSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.lawFirmProfileSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.lawFirmProfileSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('lawFirmProfile')" />
                      </div>
                    </div>
                  </div>
                </div> -->
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Corporate Profile</label>

                    <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'corporateProfile')" required />
                    <small *ngIf='dataArr.corporateProfileSrc'><b>{{dataArr.corporateProfileSrc|fileName}}</b></small><br>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.corporateProfileSrc'>
                      <div id="clickHere">
                        <a href="{{dataArr.corporateProfileSrc}}" target="_blank" rel="noopener noreferrer"><i
                            class="fa fa-eye"></i></a>
                      </div>
                    </div>
                    <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.corporateProfileSrc'>
                      <div id="clickHere"><i class="fa fa-trash"></i>
                        <input type="buton" name="delImg" (click)="removeDoc('lawFirmProfile')" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
                
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"   *ngIf=" loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Linkedin</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmLinkedin"
                      placeholder="Enter LinkedIn" >
                  </div>
                  <!-- <small class="text-danger"
                    *ngIf="myform.get('lawFirmName').invalid && myform.get('lawFirmName').touched">Please enter a law firm
                    name</small> -->
                </div>


                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf=" loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>CIN <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateClientCin"
                      placeholder="Enter CIN" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientCin').invalid && myform.get('corporateClientCin').touched">Please enter CIN</small>
                </div>
              

                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                  <div class="form-group">
                    <label>PAN <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateClientPan"
                      placeholder="Enter PAN" required>
                  </div>
                  <small class="text-danger"
                    *ngIf="myform.get('corporateClientPan').invalid && myform.get('corporateClientPan').touched">Please enter PAN number</small>
                </div>-->

              </div> 


                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>TAN</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmTan"
                      placeholder="Enter TAN" required>
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Partners</label>
                    <select class="form-control" formControlName="lawFirmPartners">
                        <option value="0">--Select--</option>
                        <option *ngFor="let partner of partnersList " [value]="partner">{{partner}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerName"
                      placeholder="Enter Partner Name">
                  </div>
                </div> -->

              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Designation<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerDesignation"
                      placeholder="Enter Designation" required>
                  </div>
                  <small class="text-danger" *ngIf="myform.get('lawFirmPartnerDesignation').invalid && myform.get('lawFirmPartnerDesignation').touched">Please enter designation.</small>
                </div>
              </div>
                


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerPhoneNo"
                      placeholder="Enter Partner Mobile Number">
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Legal Managers</label>
                    <select class="form-control" formControlName="corporateManagers">
                        <option value="0">--Select--</option>
                        <option *ngFor="let manager of legalManagers " [value]="manager">{{manager}}</option>
                    </select>
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerName"
                      placeholder="Enter Manager Name">
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Designation</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerDesignation"
                      placeholder="Enter Manager Designation">
                  </div>
                </div> -->

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerEmail"
                      placeholder="Enter Manager Email">
                  </div>
                </div> -->


                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'corporateclient'">
                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="corporateManagerPhoneNo"
                      placeholder="Enter Manager Mobile Number">
                  </div>
                  
                </div> -->
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <mat-vertical-stepper  #myformstepper>
                    <mat-step [stepControl]="myform">
                      <form [formGroup]="myform">
                        <ng-template matStepLabel><h4>PERSONAL INFO</h4></ng-template>
                        <div class="stepper-body">
                          <div class="row">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>First Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="firstName" placeholder="Enter name" pattern="^[a-zA-Z\s]+$" required>
                              </div>
                              <small class="text-danger" *ngIf="myform.get('firstName').invalid && myform.get('firstName').touched">Please enter a first name. </small>
                              <!-- <small class="text-danger" *ngIf="myform.controls.firstName.hasError('required') && ( myform.controls.firstName.dirty || myform.controls.firstName.touched || (!myform.controls.firstName.valid))">Please enter a firstname</small> -->
                              <small class="text-danger" *ngIf="myform.get('firstName').errors?.pattern">Please provide only text for firstname.</small>
                            </div>
            
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Middle Name <!--span>*</span--></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="middleName" placeholder="Enter name" pattern="^[a-zA-Z\s]+$">
                              </div>
                              <!-- <small class="text-danger" *ngIf="myform.get('middleName').invalid && myform.get('middleName').touched">Please enter a middle name</small> -->
                                <small class="text-danger" *ngIf="myform.get('middleName').errors?.pattern">Please provide only text for middle name. </small>
                            </div>
            
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Surname <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lastName" placeholder="Enter name" pattern="^[a-zA-Z\s]+$" required>
                              </div>
                                <small class="text-danger" *ngIf="myform.get('lastName').invalid && myform.get('lastName').touched">Please enter a surname. </small>
                                <small class="text-danger" *ngIf="myform.get('lastName').errors?.pattern">Please provide only text for surname.</small>
                            </div>
                          </div>
            
                          <div class="row">
                            <!-- emailId -->
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" >
                              <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <input type="email" class="form-control" [attr.disabled]="viewMode" formControlName="lawFirmPartnerEmail"
                                  placeholder="Enter Partner Email" required readonly>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('lawFirmPartnerEmail').invalid && myform.get('lawFirmPartnerEmail').touched">Please enter Email</small>
                            </div>
                            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'corporateclient'">
                              <div class="form-group">
                                <label>Designation</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="orgDesignation"
                                  placeholder="Enter Designation name">
                              </div>
                            </div> -->
            
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Mobile Number <span class="text-danger">*</span></label>
                                <!-- <div style="display: flex">
                                  <app-country-code-dropdown
                                    [countryCode]="countryCodeMobilePh"
                                    (onCountryChange)="onCountryCodeMobilePhChange($event)"
                                  >
                                  </app-country-code-dropdown>
                                  <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="phoneNo" placeholder="Enter a valid phone number. " pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$" required>                    </div>
                              </div> -->
                              <ngx-intl-tel-input 
                                  [cssClass]="'custom'" 
                                  [attr.disabled]="viewMode"
                                  placeholder="Enter a valid phone number. "
                                  [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="false" 
                                  [enablePlaceholder]="true" 
                                  [searchCountryFlag]="true"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="true" 
                                  [selectedCountryISO]="selectedCountry"
                                  [maxLength]="15" 
                                  [tooltipField]="TooltipLabel.Name" 
                                  [phoneValidation]="true" 
                                  [separateDialCode]="true"
                                  name="phone" formControlName="phoneNo" required>
                              </ngx-intl-tel-input>
                                <small class="text-danger" *ngIf="myform.get('phoneNo').invalid && myform.get('phoneNo').touched">Please enter phone number. </small>
                                <small class="text-danger" *ngIf="myform.get('phoneNo').errors?.pattern">Please provide valid phone number.</small>
                            </div>
                          </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Office Phone Number</label>
                                <!-- <div style="display: flex">
                                  <app-country-code-dropdown
                                    [countryCode]="countryCodeOfficePh"
                                    (onCountryChange)="onCountryCodeOfficePhChange($event)"
                                  >
                                  </app-country-code-dropdown> 
                                  <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="officePhoneNo" placeholder="Enter phone no" >
                                </div> -->
                                <div style="display: flex;">
                                  <ngx-intl-tel-input 
                                          [cssClass]="'custom'" 
                                          [attr.disabled]="viewMode"
                                          placeholder="Enter a valid phone number. "
                                          [preferredCountries]="preferredCountries"
                                          [enableAutoCountrySelect]="false" 
                                          [enablePlaceholder]="true" 
                                          [searchCountryFlag]="true"
                                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                          [selectFirstCountry]="true" 
                                          [selectedCountryISO]="selectedCountry1" 
                                          [maxLength]="15" 
                                          [tooltipField]="TooltipLabel.Name" 
                                          [phoneValidation]="true" 
                                          [separateDialCode]="true"
                                          name="phone" formControlName="officePhoneNo" >
                                      </ngx-intl-tel-input>
                                    </div>
                                </div>
                                <small class="text-danger" *ngIf="myform.get('officePhoneNo').invalid && myform.get('officePhoneNo').touched">Please enter valid phone number. </small>
                              <!-- <small class="text-danger" *ngIf="myform.get('officePhoneNo').errors?.pattern">Please provide only number.</small>pattern="^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$" -->
                            </div>
            
                          </div>
            
                          <!-- <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Qualification</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="qualification"
                                  placeholder="Enter Qualification" required>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('qualification').invalid && myform.get('qualification').touched">Please enter a
                                surname</small>
                            </div>
            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>University</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="university"
                                  placeholder="Enter University" required>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('university').invalid && myform.get('university').touched">Please enter a
                                University</small>
                            </div>
            
                          </div> -->
            
                            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Address<span class="text-danger">*</span></label>
                                <textarea class="form-control" [attr.disabled]="viewMode" formControlName="address"
                                  rows="3" placeholder="Enter address"></textarea>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('address').invalid && myform.get('address').touched">Please
                                enter address.</small>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm'">
                              <div class="form-group">
                                <label>Chember Address<span class="text-danger">*</span></label>
                                <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                                  formControlName="chamberAddress" placeholder="Enter chamber address"></textarea>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('chamberAddress').invalid && myform.get('chamberAddress').touched">Please
                                enter chamber office.</small>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Office Address<span class="text-danger">*</span></label>
                                <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                                  formControlName="officeAddress" placeholder="Enter office address"></textarea>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('officeAddress').invalid && myform.get('officeAddress').touched">Please
                                enter office address.</small>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Residencial Address<span class="text-danger">*</span></label>
                                <textarea class="form-control" [attr.disabled]="viewMode" rows="3"
                                  formControlName="residencialAddress" placeholder="Enter recidential address"></textarea>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('residencialAddress').invalid && myform.get('residencialAddress').touched">Please
                                enter residential address.</small>
                            </div>
                            
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>State</label>
                                  <select (change)="onSelectState($event.target.value, 1)" class="form-control" formControlName="stateId">
                                    <option value="0">--Select--</option>
                                    <option *ngFor="let state of statelist " [value]="state.id">{{state.stateName}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>District</label>
                                  <select (change)="onSelectDistrict($event.target.value, 1)" class="form-control" formControlName="districtId">
                                        <option value="0">--Select--</option>
                                        <option *ngFor="let district of districtlist" [value] = "district.id">{{district.districtName}}</option>
                                  </select>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>City</label>
                                  <select (change)="onSelectState($event.target.value,0)" class="form-control" formControlName="cityId">
                                    <option value="0">--Select--</option>
                                    <option *ngFor="let city of cityList " [value]="city.id">{{city.stateName}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Flat/House No/Building/Floor/Room No</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="houseNo"
                                  placeholder="Enter house no">
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Street/Road</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="streetName"
                                  placeholder="Enter street name">
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Landmark</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="landMark"
                                  placeholder="Enter Landmark">
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'lawfirm' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>Pincode</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="pinCode"
                                  placeholder="Enter pincode">
                              </div>
                            </div> -->
            
                          <div class="row">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                                <div class="form-group">
                                  <label>Upload Profile Photo (JPG/JPEG/PNG)</label>
            
                                  <input type="file" name="profilepic" (change)="handleFileInput($event.target.files,'profilepic')" accept="image/*"/>
            
                                  <small *ngIf='dataArr.profilePicSrc'><b>{{dataArr.profilePicSrc|fileName}}</b><br></small>
                                  <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.profilePicSrc'>
                                    <div id="clickHere">
                                      <a href="{{dataArr.profilePicSrc}}" target="_blank" rel="noopener noreferrer"><i
                                          class="fa fa-eye"></i></a>
                                    </div>
                                  </div>
                                  <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.profilePicSrc'>
                                    <div id="clickHere"><i class="fa fa-trash"></i>
                                      <input type="buton" name="delImg" (click)="removeDoc('profilepic')" />
                                    </div>
                                  </div>
            
                                  <small class="text-danger" *ngIf="errorOnImgType">Only image file can upload here.</small>
                                </div>
                            </div>
                            <!-- <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Linkedin Profile Link</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode"
                                  formControlName="linkedinProfileSrc" placeholder="Enter linkedin profile link" pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$">
            
                              </div>
                              <small class="text-danger" *ngIf="myform.get('linkedinProfileSrc').errors?.pattern">Please provide correct link.</small>
                            </div> -->
                          </div>
                          <div class="row">
                            <div class="col-xs-12 text-center">
                              <button class="btn btn-primary" mat-button matStepperNext>Next</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </mat-step>
                    <mat-step [stepControl]="myform">
                      
                      <form [formGroup]="myform">
                        <ng-template matStepLabel>
                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                <!-- <label>Upload Profile Photo</label> -->
            
                                  <h4 *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">PROFESSIONAL INFO</h4>
                                 
                                <!-- <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                                  data-toggle="modal" data-target="#saveIntent">Add</button> -->
                              
            
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <div class="stepper-body">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'clerk'">
                              <button type="button" class="btn bg-primary pull-right add-more add-team-btn" (click)="addStateRow()" *ngIf="saveMode"> <i class="fa fa-plus" aria-hidden="true"></i> Add More </button>
                            </div>
            
                          </div>
                            
                          <div class="row">
                            <div *ngFor="let eachState of allStateLists; let addressIndex = index" id="div_{{addressIndex}}">
                              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'clerk'">
                                <div class="form-group">
                                  <label *ngIf="loginUserType == 'managingpartner' || loginUserType == 'clerk'">County <span class="text-danger">*</span></label>
                                  <label *ngIf="loginUserType != 'managingpartner' && loginUserType != 'clerk'">State Bar Council <span class="text-danger">*</span></label>
                                  <select (change)="onSelectState($event.target.value, 2, addressIndex)" class="form-control" formControlName="stateId_{{addressIndex}}" required>
                                      <option value="null">--Select--</option>
                                      <option *ngFor="let state of statelist[addressIndex]" [value]="state.id">{{state.stateName}}</option>
                                  </select>
                                </div>
                                <!-- <small class="text-danger" *ngIf="myform.get('stateId_{{addressIndex}}').invalid && myform.get('stateId_{{addressIndex}}').touched">Please choose state</small> -->
                              </div>
                              <!-- <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'clerk'">
                                <div class="form-group">
                                  <label>District <span class="text-danger">*</span></label>
                                  <select (change)="onSelectDistrict($event.target.value, 2, addressIndex)" class="form-control" formControlName="districtId_{{addressIndex}}">
                                          <option value="null">--Select--</option>
                                          <option *ngFor="let district of districtbarlist[addressIndex]" [value] = "district.id">{{district.districtName}}</option>
                                    </select>
                                </div>
                              </div> -->
                              <input type="hidden" formControlName="districtId_{{addressIndex}}" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'clerk'">
            
                              <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'clerk'">
                                <div class="form-group">
                                  <label *ngIf="loginUserType == 'clerk'">Courts of Clerkship <span class="text-danger">*</span></label>
                                  <label *ngIf="loginUserType != 'clerk'">Courts of Practice <span class="text-danger">*</span></label>
                                  <select [attr.disabled]="viewMode ? '' : null" (change)="updateCourtsPractice($event.target.value, addressIndex)" class="form-control" formControlName="courtId_{{addressIndex}}" [attr.disabled]="viewMode ? '' : null" required>
                                            <option value=""><strong>Select court</strong></option>
                                            <option *ngIf="courtBarlist.length > 0" value="newCourt"><strong>Add your own court..</strong></option>
                                            <option *ngFor="let opt of courtBarlist[addressIndex]" [value]="opt.id">
                                            <strong>{{opt.courtName.toUpperCase()}}</strong>
                                            </option>
                                    </select>
                                </div>
                              </div>
            
            
                              <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" *ngIf="showCustomCourt[addressIndex] == true">
                                <div class="form-group">
                                  <label>Court Name</label>
                                  <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="customCourtName_{{addressIndex}}"
                                    placeholder="Enter court name">
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1" *ngIf="addressIndex!==0">
                                <div class="form-group">
                                  <label>Delete</label>
                                  <div id="drop-zone" class="btn btn-default" >
                                    <div id="clickToDelete"><i class="fa fa-trash"></i>
                                      <input type="buton" name="delCourt" (click)="removeCourt(addressIndex)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <small class="text-danger" *ngIf="courtsOfPracticeMsg">Please fill all the court details.</small>
                            </div>
                          </div>
            
                          <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner'">
                              <div class="form-group">
                                <label>Enrollment No <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="enrollmentNo"
                                  placeholder="Enter enrollment number" required>
                              </div>
                              <small class="text-danger" *ngIf="myform.get('enrollmentNo').invalid && myform.get('enrollmentNo').touched">Please enter enrollment number.</small>
                              <!-- <small class="text-danger" *ngIf="myform.get('enrollmentNo').errors?.pattern">Please provide only number.</small> -->
                            </div>
                            
            
                            <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner'">
                              <div class="form-group">
                                <label>Practice Areas</label>
                                <ng-select class="form-controll" [virtualScroll]=true [items]="practiceArea" bindLabel="subArea"
                                  groupBy="mainArea" [multiple]="true" [closeOnSelect]="false" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [compareWith]="compareAccounts"
                                  formControlName="lawyerPracticeAreas">
                                  
                                  <ng-template ng-header-tmp>
                                    <div class="btn btn-link"><span class="ng-value-label" (click)="onSelectAll()">Select All</span>
                                    </div>
                                    <div class="btn btn-link"><span class="ng-value-label" (click)="onClearAll()">Clear All</span>
                                    </div>
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items | slice:0:3">
                                      
                                      <span class="ng-value-label">
                                        {{item.subArea || 'Unnamed group'}}</span>
                                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                    </div>
                                    <div class="ng-value" *ngIf="items.length > 3">
                                      <span class="ng-value-label">{{items.length - 3}} more...</span>
                                    </div>
                                  </ng-template>
                                </ng-select>
                               
                              </div>
                            </div> -->
            
            
            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'individualclient'">
                              <div class="form-group">
                                <label>Profession</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode"
                                  formControlName="clientProfession" placeholder="Enter profession">
                              </div>
                            </div>
            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'accountant'">
                              <div class="form-group">
                                <label>ICAI REG. No.</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode"
                                  formControlName="icaiRegNo" placeholder="Enter ICAI Reg Number">
                              </div>
                            </div>
            
            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Linkedin Profile Link</label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode"
                                  formControlName="linkedinProfileSrc" placeholder="Enter linkedin profile link" pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$">
            
                              </div>
                              <small class="text-danger" *ngIf="myform.get('linkedinProfileSrc').errors?.pattern">Please provide correct link.</small>
                            </div>
                          </div>
                          <div class="row" *ngIf="loginUserType != 'managingpartner'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Account Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankName" placeholder="Enter Bank Name" required pattern="^[a-zA-Z\s]+$">
                              </div>
                              <small class="text-danger" *ngIf="myform.get('lawyerBankName').invalid && myform.get('lawyerBankName').touched">Please enter bank name.</small>
                              <small class="text-danger" *ngIf="myform.get('lawyerBankName').errors?.pattern">Please provide only text.</small>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Account Number<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankAccount" placeholder="Enter Account Number" pattern="^\d{6,8}$">
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('lawyerBankAccount').invalid && myform.get('lawyerBankAccount').touched">Please enter bank account number. </small>
                                <small class="text-danger" *ngIf="myform.get('lawyerBankAccount').errors?.pattern">Account number must be between 6 and 8 digits.</small>
                            </div>
                          </div>
                          <div class="row" *ngIf="loginUserType != 'managingpartner'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Reference Number<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankBranch" placeholder="Enter Reference Number" required>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('lawyerBankBranch').invalid && myform.get('lawyerBankBranch').touched">Please enter reference. </small>
                                <!-- <small class="text-danger" *ngIf="myform.get('lawyerBankBranch').errors?.pattern">Please provide only text.</small> -->
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Sort Code <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="lawyerBankIfscCode" placeholder="Enter SORT Code" required pattern="^\d{6}$">
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('lawyerBankIfscCode').invalid && myform.get('lawyerBankIfscCode').touched">Please enter SORT code. </small>
                                <small class="text-danger" *ngIf="myform.get('lawyerBankIfscCode').errors?.pattern">Enter a valid sort code like "309430".</small>
                            </div>
            
                          </div>
            
                            
                          <!-- <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"  *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Aadhaar Card  Number </label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="aadharNo"
                                  placeholder="Enter Aadhaar Number" pattern="^[0-9]*$">
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('aadharNo').invalid && myform.get('aadharNo').touched">Please enter aadhaar number. </small>
                                <small class="text-danger" *ngIf="myform.get('aadharNo').errors?.pattern">Please provide only number.</small>
                            </div>
            
                            
                            
            
            
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6"  *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>PAN  Number <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" [attr.disabled]="viewMode" formControlName="panNo"
                                  placeholder="Enter PAN Number" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" required>
                              </div>
                              <small class="text-danger"
                                *ngIf="myform.get('panNo').invalid && myform.get('panNo').touched">Please enter a valid PAN number. </small>
                                <small class="text-danger" *ngIf="myform.get('panNo').errors?.pattern">Please provide text and number only.</small>
                            </div>
            
                          </div> -->
            
                          <!-- <div class="row">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Upload Resume (DOC/DOCX/PDF/PNG/JPG/JPEG)</label>
            
                                <input type="file" name="resume" accept=".doc, .docx, .png, .jpg, .jpeg, .pdf" (change)="handleFileInput($event.target.files,'resume')" />
                                <small *ngIf='dataArr.resumePathToS3'><b>{{dataArr.resumePathToS3|fileName}}</b><br></small>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.resumePathToS3'>
                                  <div id="clickHere">
                                    <a href="{{dataArr.resumePathToS3}}" target="_blank" rel="noopener noreferrer"><i
                                        class="fa fa-eye"></i></a>
                                  </div>
                                </div>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.resumePathToS3'>
                                  <div id="clickHere"><i class="fa fa-trash"></i>
                                    <input type="buton" name="delImg" (click)="removeDoc('resume')" />
                                  </div>
                                </div>
                                <small class="text-danger" *ngIf="errorresume">Please upload resume with valid file type.</small>
                              </div>
                            </div>
            
            
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Upload Aadhaar Card (JPG/JPEG/PNG/PDF)</label>
            
                                <input type="file" name="aadhar" (change)="handleFileInput($event.target.files,'aadhar')" />
                                <small *ngIf='dataArr.aadharNoSrc'><b>{{dataArr.aadharNoSrc|fileName}}</b></small><br>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.aadharNoSrc'>
                                  <div id="clickHere">
                                    <a href="{{dataArr.aadharNoSrc}}" target="_blank" rel="noopener noreferrer"><i
                                        class="fa fa-eye"></i></a>
                                  </div>
                                </div>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.aadharNoSrc'>
                                  <div id="clickHere"><i class="fa fa-trash"></i>
                                    <input type="buton" name="delImg" (click)="removeDoc('aadhar')" />
                                  </div>
                                </div>
                                <small class="text-danger" *ngIf="errorOnAdhaarType">Only image files can be uploaded here. </small>
                              </div>
                            </div>
            
            
            
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'managingpartner' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'corporateclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">
                              <div class="form-group">
                                <label>Upload PAN Card (JPG/JPEG/PNG/PDF)</label>
            
                                <input type="file" name="pan" (change)="handleFileInput($event.target.files,'pan')" />
                                <small *ngIf='dataArr.panNoSrc'><b>{{dataArr.panNoSrc|fileName}}</b><br></small>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.panNoSrc'>
                                  <div id="clickHere">
                                    <a href="{{dataArr.panNoSrc}}" target="_blank" rel="noopener noreferrer"><i
                                        class="fa fa-eye"></i></a>
                                  </div>
                                </div>
                                <div id="drop-zone" class="btn btn-default" *ngIf='dataArr.panNoSrc'>
                                  <div id="clickHere"><i class="fa fa-trash"></i>
                                    <input type="buton" name="delImg" (click)="removeDoc('pan')" />
                                  </div>
                                </div>
                                <small class="text-danger" *ngIf="errorOnPanType">Only image files can be uploaded here. </small>
                              </div>
                            </div>
            
                          </div> -->                
            
                          <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="loginUserType == 'counsel'">
                              <div class="form-group">
                                <label>Practice Areas</label>
            
                                <ng-select class="form-controll" [virtualScroll]=true [items]="practiceArea" bindLabel="subArea"
                                  groupBy="mainArea" [multiple]="true" [closeOnSelect]="false" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [compareWith]="compareAccounts"
                                  formControlName="lawyerPracticeAreas">
                                  <!-- <ng-template ng-optgroup-tmp let-item="item"> 
                                    {{item.mainArea || 'Unnamed group'}}
                                  </ng-template> -->
                                  <ng-template ng-header-tmp>
                                    <div class="btn btn-link"><span class="ng-value-label" (click)="onSelectAll()">Select All</span>
                                    </div>
                                    <div class="btn btn-link"><span class="ng-value-label" (click)="onClearAll()">Clear All</span>
                                    </div>
                                  </ng-template>
            
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            
                                    <div class="ng-value" *ngFor="let item of items | slice:0:3">
                                      <!-- <div class="ng-value" *ngFor="let item of items"> -->
                                      <span class="ng-value-label">
                                        {{item.subArea || 'Unnamed group'}}</span>
                                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                    </div>
                                    <div class="ng-value" *ngIf="items.length > 3">
                                      <span class="ng-value-label">{{items.length - 3}} more...</span>
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
            
                          </div>
                          <div class="row">
                            <div class="col-xs-12 text-center">
                              <button class="btn btn-primary" style="margin: 5px;" mat-button matStepperPrevious>Back</button>
                              <button class="btn btn-primary" mat-button matStepperNext>Next</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </mat-step>
                    <mat-step [stepControl]="myform">
                      <form [formGroup]="myform">
                        <ng-template matStepLabel>
                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                  <h4 *ngIf="loginUserType == 'managingpartner' || loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager'">INDIVIDUAL ADDRESS INFO</h4>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <div class="stepper-body">
                          <div class="row">
                            <!--ADDRESS BLOCK START-->
                            <div class="" *ngIf="loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType == 'seniorpartner' || loginUserType == 'counsel' || loginUserType == 'directorlegal' || loginUserType == 'zonalmanager' || loginUserType == 'individualclient' || loginUserType == 'clerk' || loginUserType == 'accountant' || loginUserType == 'managingpartner' || loginUserType == 'assistantlegalmanager' || loginUserType == 'legalmanager' || loginUserType == 'corporateclient'">
                              <div class="">
                                <app-addresses></app-addresses>
                              </div>
                            </div> 
                          </div>
                          <div class="row">
                            <div class="col-xs-12 text-center">
                              <button class="btn btn-primary" style="margin: 5px;" mat-button matStepperPrevious>Back</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </mat-step>
                  </mat-vertical-stepper>
                  </div>
              </div>
                  
                <!--ADDRESS BLOCK END-->
                <!-- <h1>OLD</h1>
                loginUserType == 'advocate' ||  loginUserType == 'seniorassociate' ||  loginUserType == 'juniorassociate' ||  loginUserType == 'junioradvocate' || loginUserType =='associatepartner' || loginUserType =='associatepartner'
                
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" [attr.disabled]="viewMode" formControlName="emailId"
                      placeholder="Enter email">
                  </div>
                </div> -->
                

                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Pan Number</label>
                    <input type="text" [attr.disabled]="viewMode" class="form-control" formControlName="panNoSrc"
                      placeholder="Enter pan number">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Aadhar Number</label>
                    <input type="text" [attr.disabled]="viewMode" class="form-control" formControlName="aadharNoSrc"
                      placeholder="Enter aadhar number">
                  </div>
                </div> -->




                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Bar Council of India Id</label>
                    <input type="text" class="form-control" formControlName="barCouncilOfIndiaId"
                      placeholder="Enter bar council of india id">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>State Bar Council Id</label>
                    <input type="text" class="form-control" formControlName="stateBarCouncilId"
                      placeholder="Enter state bar council id">
                  </div>
                </div>
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Courts of Practice</label>
                    <select class="form-control">
                      <option>--select--</option>
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="myform.get('userType').value=='lawyerassociate'||myform.get('userType').value=='lawyeroffcouncil'">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Designation</label>
                      <input type="text" class="form-control" formControlName="memberDesignation"
                        placeholder="Enter designation">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Account Number (to be connected to the financial section)</label>
                      <input type="text" class="form-control" formControlName="lawyerBankAccount"
                        placeholder="Enter bank account number">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Name</label>
                      <input type="text" class="form-control" formControlName="lawyerBankName"
                        placeholder="Enter bank name">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank Branch Name</label>
                      <input type="text" class="form-control" formControlName="lawyerBankBranch"
                        placeholder="Enter bank branch name">
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>Bank IFSC Code</label>
                      <input type="text" class="form-control" formControlName="lawyerBankIfscCode"
                        placeholder="Enter bank ifsc code">
                    </div>
                  </div>
                </div> -->

                
                


              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group top-height"  *ngIf="saveMode">
                    <!-- <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right"
                      data-toggle="modal" data-target="#saveIntent">Save Profile</button> -->
                      <button type="button" (click)="saveIntentCorfirmation()" class="btn btn-primary pull-right">Save</button>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!--Row End-->


            </div>
          </div>
        </div>
      </form>


      <!-- <div class="box-body" id="resultsection" *ngIf="resultShow!=undefined">
        <div class="box">
          <div class="box-body">
           
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">

                  <div class="card-header1" for="staticEmail">
                    <b>Result Section :</b>
                  </div>
                  <div class="card-header2">
                    <i class="mdi mdi-check-circle"></i>
                    <b> {{resultShow}} {{statusCode}}
                      <a class="view-more" (click)="showOutput()">{{btnShow}}</a>
                    </b>
                  </div>
                  <ng-container *ngIf="resultBtnShow">
                    <div class="card-body2">
                      <h5>Output</h5>
                      <p>{{mydata.responseMessage}} </p>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
 -->

    </div>

  </section>
  <!-- Modal section end -->
  <!-- <div class="modal fade in" id="saveIntent" role="dialog" *ngIf="modalDisplay"> -->
    <div class="modal fade in" id="saveIntent" role="dialog" >
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Confirm to Submit</h4>
          </div>
          <div class="modal-body">
            <p>Are you sure to submit?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal"  (click)="saveAllData()">Yes</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
          </div>
        </div>
      </div>
    </div>
  <!-- Modal section end -->
  <!-- <div id="privilegemyModal{{teamIndex}}" class="modal fade" role="dialog">
    <div class="modal-dialog">
       <div class="modal-content">
          <div class="modal-header">
             <button type="button" class="close" data-dismiss="modal">&times;</button>
             <h4 class="modal-title">Assign Privilege {{memberName}}</h4>
          </div>
          <div class="modal-body">
             <div class="table-responsive">
                <table class="table">
                   <thead>
                      <tr>
                         <th>Folder</th>
                         <th>Upload</th>
                         <th>Delete</th>
                         <th>View</th>
                      </tr>
                   </thead>
                   <tbody formArrayName="permission">
                      <tr
                      *ngFor="let perimission of teamPermissions(teamIndex).controls; let
                      permissionIndex=index"
                      [formGroupName]="permissionIndex">
                      <td>
                         
                         <input class="form-control" type="hidden" placeholder="folder name"
                            formControlName="id">
                         <input class="form-control" type="text" placeholder="folder name"
                            formControlName="folderName" readonly>
                      </td>
                      <td>
                         <input type="checkbox" value="upload" formControlName="upload">
                      </td>
                      <td>
                         <input type="checkbox" value="delete" formControlName="delete">
                      </td>
                      <td>
                         <input type="checkbox" value="view" formControlName="view">
                      </td>
                      </tr>
                   </tbody>
                </table>
             </div>
          </div>
          <div class="modal-footer">
             <button type="button" class="btn btn-primary pull-left"
                (click)="privilegemyModal(teamIndex,'hide')">Submit</button>
             <button type="button" class="btn btn-default pull-right"
                data-dismiss="modal">Close</button>
          </div>
       </div>
    </div>
 </div> -->


 <div class="modal fade in" id="removeFileConfirm" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm to Delete</h4>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="removeFileConfirm('Y')">Yes</button>
        <button type="button" class="btn btn-default" data-dismiss="modal"  (click)="removeFileConfirm('N')">No</button>
      </div>
    </div>
  </div>
</div>