import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
//import { config } from './../config';
//import { config } from '../';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
export class NavigationLinkParameterService{

    saveMode: boolean = true;
    selectedId:any;
    constructor(private http: Http) { }
}